import React, { PureComponent } from 'react';
import Tooltip from 'rc-tooltip';
import { formatCreditsText } from '../../core/util';
import Button from '../Button';
import s from './CreditsButton.module.scss';

class CreditsButton extends PureComponent {
  render() {
    const { creditsTooltip, credits, ...other } = this.props;

    const creditsComponent = (
      <div className={s.credits}>{formatCreditsText(credits)} →</div>
    );

    return (
      <div className={s.root}>
        {!!credits &&
          (creditsTooltip ? (
            <Tooltip
              overlay={creditsTooltip}
              trigger={['hover', 'click']}
              overlayStyle={{ zIndex: 2000 }}
              placement="left"
            >
              {creditsComponent}
            </Tooltip>
          ) : (
            creditsComponent
          ))}
        <Button size="medium" {...other} />
      </div>
    );
  }
}

export default CreditsButton;

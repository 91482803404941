import React from 'react';
import SuccessStories from './SuccessStories';
import { fetchCaseStudy } from '../../actions/caseStudy';

const featuredCases = [
  'payroll-lending',
  'solar-energy',
  'renewable-energy',
  'animal-feed-in-nigeria',
  'ict-in-angola',
  'preventative-healthcare',
  'off-grid-electricity',
];

export default {
  path: '/success-stories',

  async action({ store }) {
    const caseStudies = await Promise.all(
      featuredCases.map((id) => store.dispatch(fetchCaseStudy(id)))
    );

    return {
      title: 'Success Stories',
      component: <SuccessStories caseStudies={caseStudies} />,
    };
  },
};

import React, {
  PureComponent,
  useEffect,
  useCallback,
  memo,
  useRef,
} from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import Button from '@mui/material/Button';
import MediaQuery from '../MediaQuery';
import Body from '../Body';
import Link from '../Link';
import { UserContextMenu } from './UserContextMenu';
import MaterialIcon from '../Icon/MaterialIcon';
import Picture from '../Picture';
import { SCREEN_MD } from '../../constants';
import { black } from '../../core/colors';
import { getUserOrGroup, getDefaultUserContext } from '../../core/user';
import { setUserContext } from '../../actions/ui';
import {
  prefetchFirstChannel,
  messagingUnreadCount,
  updateUnreadCount,
} from '../../actions/messaging';
import s from './Nav.module.scss';
import navItemsBuilder from './navItemsBuilder';
import useAppContext from '../useAppContext';
import Badge from './Badge';

function NavItem({ text, to, selected, promo, icon, isMobileVersion, id }) {
  const classes = cx(s.navItem, { [s.selected]: selected });
  return (
    <div className={classes}>
      <Link to={to} id={id} className={cx({ [s.promo]: promo })}>
        {isMobileVersion ? (
          typeof icon === 'string' ? (
            <MaterialIcon icon={icon} color={selected ? black : undefined} />
          ) : (
            icon
          )
        ) : (
          text
        )}
      </Link>
    </div>
  );
}

class UserContextNav extends PureComponent {
  state = {
    open: false,
    anchorElement: null,
  };

  handleOpen = (event) => {
    this.setState({
      open: true,
      anchorElement: event.currentTarget,
    });
  };

  handleClose = () => this.setState({ open: false });

  render() {
    const { viewer, userContext, userContextOptions } = this.props;

    if (userContextOptions.length < 2) return null;

    const userContextOption = userContextOptions.find(
      (o) => o.value === userContext
    );

    if (!userContextOption) {
      // something went very wrong - user should not have invalid context
      Promise.reject(
        new Error(`User context could not be determined
selected: ${userContext}
options: ${JSON.stringify(userContextOptions.map((o) => o.value))}`)
      );
      this.props.setUserContext(getDefaultUserContext(viewer));
      return null;
    }

    const user = getUserOrGroup(userContext, viewer) || userContextOption;

    return (
      <div className={s.userContextNav}>
        <Button onClick={this.handleOpen} disableFocusRipple>
          <div className={s.userContextNavButton} id="userSelector">
            <Picture
              size={30}
              style={{ marginRight: 10 }}
              user={user}
              link={false}
            />
            {userContextOption.name}
            {userContextOption.description && (
              <span className={s.userContextNavDescription}>
                {' '}
                ({userContextOption.description})
              </span>
            )}
            <MaterialIcon
              style={{
                verticalAlign: 'middle',
                marginLeft: 5,
                color: black,
              }}
              icon="swap_calls"
            />
          </div>
        </Button>

        {(viewer.admin || userContextOptions.length > 1) && (
          <UserContextMenu
            viewer={viewer}
            userContextOptions={userContextOptions}
            userContext={userContext}
            onChange={this.handleClose}
            open={this.state.open}
            anchorEl={this.state.anchorElement}
            onClose={this.handleClose}
          />
        )}
      </div>
    );
  }
}

UserContextNav = connect(
  (state) => ({
    viewer: state.viewer,
    userContext: state.ui.userContext,
    userContextOptions: state.ui.userContextOptions,
  }),
  {
    setUserContext,
  }
)(UserContextNav);

const Nav = memo((props) => {
  const unsubscribeRef = useRef();
  const { apiWebSocket } = useAppContext();
  const { viewer, userContext, selected, unreadCount, loadedChannels } = props;

  useEffect(() => {
    if (loadedChannels) {
      unsubscribeRef.current = apiWebSocket.on(
        {
          service: messagingUnreadCount.service,
          queue: messagingUnreadCount.queue,
          resource: viewer.id,
        },
        handleNewUnreadCount
      );
    }

    return () => {
      if (unsubscribeRef.current) {
        unsubscribeRef.current();
      }
    };
  }, [loadedChannels]);

  useEffect(() => {
    props.prefetchFirstChannel();
  });

  const handleNewUnreadCount = useCallback((payload) => {
    props.updateUnreadCount(payload.count);
  }, []);

  const unreadMessages = unreadCount > 0 && <Badge>{unreadCount}</Badge>;

  const items = navItemsBuilder({
    profileUrl: viewer.html_url,
    unreadMessages,
    userContext,
    isAdmin: viewer.admin,
    hasGroups: viewer.groups && viewer.groups.length > 0,
    isAccountEnterprise:
      viewer.groups &&
      viewer.groups.find((g) => g.account_type === 'enterprise'),
    hasExpertState: !!viewer.expert_state,
  });

  return (
    <MediaQuery maxWidth={SCREEN_MD}>
      {(isMobileVersion) => (
        <div className={s.root}>
          <Body className={s.container}>
            {items
              .filter((i) => !isMobileVersion || (isMobileVersion && i.icon))
              .map((item) => (
                <NavItem
                  isMobileVersion={isMobileVersion}
                  key={item.key}
                  selected={item.key === selected}
                  {...item}
                />
              ))}
            {!isMobileVersion && <UserContextNav />}
          </Body>
        </div>
      )}
    </MediaQuery>
  );
});

export default connect(
  (state) => ({
    viewer: state.viewer,
    userContext: state.ui.userContext,
    unreadCount: state.messaging.unreadCount,
    loadedChannels: state.messaging.loadedChannels,
  }),
  {
    prefetchFirstChannel,
    updateUnreadCount,
  }
)(Nav);

import React from 'react';
import Layout from '../../components/MarketingLayout';
import Blog from './Blog';
import BlogPost from './BlogPost';
import BlogList from './BlogList';
import MarketingHeader from '../../components/MarketingLayout/MarketingHeader';
import { fetchUser } from '../../actions/user';
import {
  fetchPosts,
  fetchBlogPostBySlug,
  fetchCategories,
  fetchTags,
} from '../../actions/blog';
import { white } from '../../core/colors';

function BlogLayout({ children }) {
  return (
    <Layout>
      <MarketingHeader theme="light" backgroundColor={white} />
      {children}
    </Layout>
  );
}

export default {
  path: '/blog',
  children: [
    {
      path: '',
      async action({ store }) {
        const promises = [store.dispatch(fetchCategories())];

        promises.push(
          store.dispatch(fetchPosts('landing', { per_page: 10, _embed: 1 }))
        );

        const [categories] = await Promise.all(promises);

        return {
          title: 'OnFrontiers Blog',
          meta: {},
          component: <Blog categories={categories} />,
        };
      },
    },
    {
      path: '/:slug',
      async action({ params, store }) {
        const post = await store.dispatch(fetchBlogPostBySlug(params.slug));
        if (!post) return;

        const [categories, ...experts] = await Promise.all(
          [
            fetchCategories(),
            ...post.expertIds.map((id) => fetchUser(id, { experiences: true })),
          ].map(store.dispatch)
        );

        return {
          title: post.title_text,
          meta: {
            url: post.link,
            imageUrl: post.featured_media && post.featured_media.source_url,
            description: post.excerpt_text,
            article: post,
            type: 'article',
          },
          component: (
            <BlogLayout>
              <BlogPost
                post={post}
                categories={categories}
                experts={experts.filter(Boolean)}
              />
            </BlogLayout>
          ),
        };
      },
    },
    {
      path: '/category/:slug',
      async action({ params: { slug }, store }) {
        const [cat] = await store.dispatch(fetchCategories({ slug }));
        if (!cat) return;

        const collection = `cat_${slug}`;
        const query = { categories: cat.id };
        await store.dispatch(
          fetchPosts(collection, { per_page: 9, _embed: 1, ...query })
        );
        return {
          title: cat.name,
          meta: {},
          component: (
            <BlogLayout>
              <BlogList
                title={cat.name}
                collection={collection}
                query={query}
              />
            </BlogLayout>
          ),
        };
      },
    },
    {
      path: '/tag/:slug',
      async action({ params: { slug }, store }) {
        const [tag] = await store.dispatch(fetchTags({ slug }));
        if (!tag) return;

        const collection = `tag_${slug}`;
        const query = { tags: tag.id };
        await store.dispatch(
          fetchPosts(collection, { per_page: 9, _embed: 1, ...query })
        );
        return {
          title: tag.name,
          meta: {},
          component: (
            <BlogLayout>
              <BlogList
                title={tag.name}
                collection={collection}
                query={query}
              />
            </BlogLayout>
          ),
        };
      },
    },
  ],
};

import Lokka from 'lokka';
import { print } from 'graphql';
import Transport, { UnauthorizedError, APIError } from './apiTransport';

export { UnauthorizedError, APIError };

export function basicAuth(username, password) {
  const token = btoa(`${username}:${password}`);
  return `Basic ${token}`;
}

export function bearerAuth(token) {
  return `Bearer ${token}`;
}

export class GraphQLClient extends Lokka {
  constructor(url, auth, { otp, extraHeaders = {} } = {}) {
    const headers = { ...extraHeaders };
    delete headers.host;

    if (auth) {
      headers.Authorization = auth;
    }

    if (otp) {
      headers['x-onfrontiers-auth-otp'] = otp;
    }

    const transport = new Transport(`${url}/graphql`, {
      headers,
    });

    super({ transport });

    this.url = url;
  }

  send(rawQuery, vars) {
    if (typeof rawQuery !== 'string') {
      rawQuery = print(rawQuery);
    }

    return super.send(rawQuery, vars);
  }

  fromAuth = (auth, options) => new GraphQLClient(this.url, auth, options);
}

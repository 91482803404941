import React, { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { TextField } from '../FormAdapters';
import Dialog from '../Dialog';
import { updateConsultationReview } from '../../actions/consultation';
import { notify } from '../../actions/ui';

function EditReviewForm({
  // Props
  open,
  onClose,
  review,

  // Redux Actions
  updateConsultationReview,
  notify,
}) {
  const validate = useCallback((values) => {
    const errors = {};
    if (!values.review) {
      errors.review = 'Review can not be blank';
    }
    return errors;
  }, []);

  const handleSubmit = useCallback(async (values) => {
    const { id } = values;
    const { review } = values;

    try {
      await updateConsultationReview({
        id,
        review,
      });

      notify('Review updated.');
      onClose();
    } catch (err) {
      notify(err.message, 'error');
      return err;
    }
  }, []);

  const initialValues = useMemo(
    () => ({
      id: review.id,
      review: review.review,
    }),
    [review]
  );

  return (
    <Form
      validate={validate}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      component={EditReviewDialog}
      open={open}
      onClose={onClose}
    />
  );
}

EditReviewForm = connect(undefined, {
  notify,
  updateConsultationReview,
})(EditReviewForm);

function EditReviewDialog({
  // Props
  open,
  onClose,

  // Final Form
  form,
  submitting,
  handleSubmit,
}) {
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const handleConfirm = (event) => {
    handleSubmit(event);
    setConfirmDialogOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        confirmLabel="Save"
        title="Edit Review"
        onConfirm={() => setConfirmDialogOpen(true)}
        onReset={() => form.reset()}
        onClose={onClose}
        onCancel={onClose}
        disableSubmit={submitting}
      >
        <Field
          name="review"
          component={TextField}
          multiline
          rows={10}
          label="Review Content"
        />
      </Dialog>
      <Dialog
        open={confirmDialogOpen}
        title="Are you sure?"
        maxWidth="sm"
        onConfirm={handleConfirm}
        onCancel={() => setConfirmDialogOpen(false)}
      >
        This text can NOT be edited once saved.
      </Dialog>
    </React.Fragment>
  );
}

export default EditReviewForm;

import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import EditDialog from '../../../components/EditDialog';
import { notify } from '../../../actions/ui';
import { TextField } from '../../../components/FormAdapters/FormAdapters';

function EditMessage({
  messageTitle,
  messageTitleCapitalized,
  onSubmit,
  notify,
  handleSubmit: handleSubmitProp,
  reset,
  field,
  placeholder,
  fieldValue,
  ...props
}) {
  const handleSubmit = (values) => {
    onSubmit(values)
      .then(() => {
        notify(`${messageTitleCapitalized} updated.`, 'success');
      })
      .catch((err) => {
        notify(`Error when updating ${messageTitle}.`, 'error');
        throw err;
      });
  };

  return (
    <EditDialog
      {...props}
      onSubmit={handleSubmitProp(handleSubmit)}
      onReset={() => reset()}
      // bogus attribute (force dialog position update on text change)
      fieldValue={fieldValue}
    >
      <Field
        id={field}
        component={TextField}
        fullWidth
        multiline
        name={field}
        label={messageTitleCapitalized}
        placeholder={placeholder}
        maxRows={15}
      />
    </EditDialog>
  );
}

EditMessage = reduxForm({
  enableReinitialize: true,
})(EditMessage);

export default connect(
  (state, ownProps) => {
    const formName = `message-${ownProps.field}-${ownProps.initialValues.id}`;
    return {
      form: formName,
      fieldValue: formValueSelector(formName)(state, ownProps.field),
    };
  },
  {
    notify,
  }
)(EditMessage);

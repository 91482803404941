import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Divider as DividerMaterial } from '@mui/material';

class Divider extends PureComponent {
  static propTypes = {
    spacing: PropTypes.number,
    color: PropTypes.string,
    style: PropTypes.object,
  };

  static defaultProps = {
    spacing: 20,
  };

  render() {
    const { spacing, margin, color, style, height = 1, ...other } = this.props;

    const defaultStyle = {
      marginTop: spacing,
      marginBottom: spacing,
      marginLeft: margin,
      marginRight: margin,
      height,
      width: '100%',
    };

    if (color) {
      defaultStyle.borderColor = color;
      defaultStyle.backgroundColor = color;
    }

    return <DividerMaterial style={{ ...defaultStyle, ...style }} {...other} />;
  }
}

export default Divider;

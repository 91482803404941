import React, { useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import setFieldData from 'final-form-set-field-data';
import moment from 'moment-timezone';
import TeamAccountPromoDialog from '../TeamAccountPromo/TeamAccountPromoDialog';
import RequestConsultationDialog from './Dialog';
import {
  requestConsultation,
  engagementTypes,
} from '../../actions/consultation';
import { notify, popup } from '../../actions/ui';
import { erTypes } from '../../actions/expertRequest';

import {
  getSuggestedTimes,
  validate,
  minimumTimeNotice,
  calculateExpertCredits,
  isDurationEmpty,
} from '../../core/consultation';
import history from '../../core/history';
import minimumTimeNoticePopup from './minimumTimeNoticePopup';
import { money } from '../../core/money';
import purchasePromoPopup from './purchasePromoPopup';
import {
  ERR_NOT_ENOUGH_CREDITS,
  ERR_NO_BILLING_ACCOUNT,
  ERR_OUT_OF_MARKETPLACE,
  MSG_OUT_OF_MARKETPLACE,
  ERR_NOT_AVAILABLE_SELF_SERVICE,
} from './constants';

const RequestConsultation = ({
  // Props
  profile,
  returnTo,
  expertId,
  onClose,
  open,
  expertRequest,
  groupId,

  // Redux State
  viewer,
  group,

  // Redux Actions
  requestConsultation,
  notify,
  popup,
}) => {
  const handleClose = useCallback(() => {
    if (returnTo) history.push(returnTo);
    if (onClose) onClose();
  }, []);

  const handleSubmit = useCallback(
    (values) =>
      new Promise((resolve, reject) => {
        const dates = values.dates.filter(Boolean);

        (async () => {
          try {
            const {
              duration,
              engagement_type: engagementType,
              booking_fee: bookingFee,
              marketplace_error: marketplaceError,
              ...other
            } = values;

            const expertCredits = calculateExpertCredits(
              profile.credit_rate,
              duration,
              engagementType
            );
            const cents = expertCredits + bookingFee;

            const consultation = await requestConsultation({
              ...other,
              duration,
              engagement_type: engagementType,
              expert_id: expertId,
              credits: money({ cents, currency: 'OFC' }),
              dates,
            });

            notify('Your request has been submitted.', 'success');

            history.push(`/consultation/${consultation.id}`);

            resolve();
          } catch (err) {
            if (
              err.message === ERR_NOT_ENOUGH_CREDITS ||
              err.message === ERR_NO_BILLING_ACCOUNT
            ) {
              // if (viewer.admin) {
              //   return resolve({
              //     group_id: 'Team does not have enough credits',
              //   });
              // }
              let purchaseLink = group ? `/team/${group.slug}` : '';
              purchaseLink += `/settings/credits/purchase`;
              purchasePromoPopup(popup, purchaseLink, !!group);
              return resolve();
            }

            if (err.message === ERR_OUT_OF_MARKETPLACE) {
              notify(`${MSG_OUT_OF_MARKETPLACE}.`, 'error');
              return resolve();
            }

            if (err.message === ERR_NOT_AVAILABLE_SELF_SERVICE) {
              notify(
                'Expert is not available for self service (must be added to the expert request).',
                'error'
              );
              return resolve();
            }

            if (err.message === 'User not logged in') {
              notify('User not logged in.', 'error');
            } else {
              notify(
                'An error occurred when trying to submit your request.',
                'error'
              );
            }
            reject(err);
          }
        })();
      }),
    []
  );

  const handleSubmitWithCheck = useCallback((values) => {
    const dates = values.dates.filter(Boolean);
    if (minimumTimeNotice(dates, viewer.timezone)) {
      handleSubmit(values);
    } else {
      minimumTimeNoticePopup(popup, false, () => handleSubmit(values));
    }
  }, []);

  // if (!viewer.groups || viewer.groups.length === 0) {
  //   return (
  //     <TeamAccountPromoDialog
  //       open={open}
  //       onClose={handleClose}
  //       showEventTrack="promo.show.team_account.consultation_request"
  //       clickEventTrack="promo.click.team_account.consultation_request"
  //     />
  //   );
  // }
  const initialValues = useMemo(
    () =>
      expertRequest && expertRequest.er_type === erTypes.writtenReview
        ? {
            dates: [moment().add(7, 'd').startOf('day')],
            expert_request_id: expertRequest.id || '',
            group_id: groupId || '',
            requester_id: '',
            disclosure: 'full',
            engagement_type: engagementTypes.writtenResponse,
            booking_fee: 0,
            duration:
              (!isDurationEmpty(expertRequest.expected_duration) &&
                expertRequest.expected_duration) ||
              '1h',
          }
        : {
            dates: getSuggestedTimes(viewer.timezone, profile.timezone, 12, 1),
            expert_request_id: expertRequest?.id || '',
            group_id: groupId || '',
            requester_id: '',
            duration: '1h',
            disclosure: 'full',
            engagement_type: engagementTypes.consultation,
            booking_fee: 0,
          },
    [expertRequest]
  );

  const getEngagementType = useCallback(
    (er = expertRequest) => {
      if (er?.er_type === erTypes.writtenReview) {
        return engagementTypes.writtenResponse;
      }
      if (
        er?.er_type === erTypes.newHire ||
        er?.er_type === erTypes.consultingProject
      ) {
        return engagementTypes.opportunity;
      }
      return engagementTypes.consultation;
    },
    [expertRequest]
  );

  return (
    <Form
      // Final Form
      initialValues={initialValues}
      onSubmit={handleSubmitWithCheck}
      validate={validate}
      mutators={{ setFieldData, ...arrayMutators }}
      component={RequestConsultationDialog}
      // Only re-render if these parts of the form state change
      subscription={{
        submitting: true,
        values: true,
        initialValues: true,
      }}
      // RequestConsultationDialog props
      expertId={expertId}
      profile={profile}
      expertRequest={expertRequest}
      getEngagementType={getEngagementType}
      open={open}
      onClose={handleClose}
    />
  );
};

export default connect(
  (state, props) => {
    const group = state.groups.all.edges.find(
      (g) => g.node.id === props.groupId
    )?.node;
    return {
      viewer: state.viewer,
      group,
    };
  },
  {
    requestConsultation,
    notify,
    popup,
  }
)(RequestConsultation);

import React from 'react';
import NotFound from './NotFound';
import config from '../../../config';
import { fetchDomain } from '../../actions/domain';

export default {
  path: '(.*)',
  ignoreDomain: true,

  async action({ store, path }, params, { subdomain, customTLD }) {
    if (
      customTLD !== null ||
      (subdomain && (await store.dispatch(fetchDomain(subdomain))))
    ) {
      // fallback to base domain
      return { redirect: config.webUrl + path };
    }

    return {
      title: 'Page Not Found',
      component: <NotFound path={path} />,
      status: 404,
    };
  },
};

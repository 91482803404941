import React, { useState, useCallback } from 'react';
import { Form } from 'react-final-form';
import { Field, RadioGroup, TextField } from '../FormAdapters';
import Dialog from '../Dialog';

const CloseExpertRequestDialog = ({
  expertRequestId,
  onClose,
  onConfirm,
  ...other
}) => {
  const [disableSubmit, setDisableSubmit] = useState(true);

  const handleSubmit = useCallback((values) => {
    const closeReason =
      values.keyreasonForClosing !== 'other'
        ? options.find((v) => v.value === values.keyreasonForClosing).label
        : values.keyspecifyReason;
    if (onConfirm) {
      onConfirm(closeReason);
    }
    onClose();
  }, []);

  const options = [
    { label: 'Call Completed', value: 'callCompleted' },
    {
      label: '1st Call Completed',
      value: 'firstCallCompleted',
    },
    { label: 'Call Deferred', value: 'callDeferred' },
    { label: 'No Call', value: 'noCall' },
    { label: 'Canceled', value: 'canceled' },
    { label: 'Test', value: 'test' },
    { label: 'Other:', value: 'other' },
  ];

  return (
    <Form onSubmit={handleSubmit}>
      {({ handleSubmit, submitting, values }) => {
        if (
          !values.keyreasonForClosing ||
          (values.keyreasonForClosing === 'other' && !values.keyspecifyReason)
        ) {
          setDisableSubmit(true);
        } else {
          setDisableSubmit(false);
        }
        return (
          <Dialog
            {...other}
            onClose={onClose}
            onCancel={onClose}
            onConfirm={handleSubmit}
            disableSubmit={disableSubmit || submitting}
            title="Reason For Closing ER"
            submitting={submitting}
          >
            <form onSubmit={(e) => handleSubmit(e)}>
              <Field
                name="reasonForClosing"
                component={RadioGroup}
                options={options}
              />
              <Field
                name="specifyReason"
                component={TextField}
                placeholder="Reason"
                disabled={values.keyreasonForClosing !== 'other'}
                changeOnBlur={false}
              />
            </form>
          </Dialog>
        );
      }}
    </Form>
  );
};

export default CloseExpertRequestDialog;

import history from '../core/history';

import ActionTypes from '../core/ActionTypes';

export function setLoadingProgress(value) {
  return { type: ActionTypes.LOADING__SET_PROGRESS, value };
}

export function navigate(...params) {
  return (dispatch) => {
    dispatch(setLoadingProgress(10));
    history.push(...params);
  };
}

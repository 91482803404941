import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector from '@mui/material/StepConnector';
import Divider from '@mui/material/Divider';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { borderColor, teal500 } from '../../core/colors';

const getStyles = makeStyles((theme) => ({
  divider: {
    height: 1,
    backgroundColor: borderColor,
    margin: '20px 0',
    [theme.breakpoints.down('md')]: {
      margin: '10px 0',
    },
  },
  root: {
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
}));

const getIconStyle = makeStyles(() => ({
  root: {
    fontSize: 36,
    color: borderColor,
    '&$active': {
      color: teal500,
    },
    '&$completed': {
      color: teal500,
      cursor: 'pointer',
    },
  },
  active: {},
  completed: {},
}));

const getConnectorStyle = makeStyles(() => ({
  root: {
    top: 19,
    padding: '0 6px',
  },
}));

const getLabelStyle = makeStyles((theme) => ({
  label: {
    fontSize: 16,
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      marginBottom: 10,
    },
  },
  completed: {
    cursor: 'pointer',
  },
}));

function Steps({ current = 0, steps, onStepClick }) {
  const s = getStyles();
  const iconStyle = getIconStyle();
  const connectorStyle = getConnectorStyle();
  const labelStyle = getLabelStyle();

  const theme = useTheme();

  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const onClick = useCallback(
    (step, index) => {
      if (index >= current) return;
      onStepClick(step);
    },
    [current]
  );

  const isLastStep = useMemo(
    () => current === steps.length - 1,
    [current, steps]
  );

  return (
    <Grid container justifyContent="center">
      <Grid item md={8} sm={12} xs={12}>
        <Stepper
          activeStep={current}
          alternativeLabel
          className={s.root}
          connector={<StepConnector classes={connectorStyle} />}
        >
          {steps.map((step, index) => (
            <Step key={step.title} onClick={() => onClick(step, index)}>
              <StepLabel
                classes={labelStyle}
                StepIconProps={{ classes: iconStyle }}
              >
                {(!mobile || current === index) && step.title}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        {mobile && !isLastStep && <Divider className={s.divider} />}
      </Grid>
    </Grid>
  );
}

Steps.propTypes = {
  current: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Steps;

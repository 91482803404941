import React, { useState, useEffect, memo } from 'react';
import { IconButton } from '@mui/material';
import { white } from '../../core/colors';
import useDetectVoice from '../DeviceSettings/useDetectVoice';
import ToolTipUnmute from './TooltipUnmute';
import MaterialIcon from '../Icon/MaterialIcon';

const MicButton = memo(({ mute, onClick, detectTalking }) => {
  const [talking] = useDetectVoice(0.25);
  const [unmuteAlert, setUnmuteAlert] = useState(false);

  useEffect(() => {
    if (detectTalking && mute && talking) {
      setUnmuteAlert(true);
    }
  }, [talking]);

  useEffect(() => {
    if (unmuteAlert && !mute) {
      setUnmuteAlert(false);
    }
  }, [mute]);

  const onUnmute = () => {
    setUnmuteAlert(false);
    onClick();
  };

  return (
    <ToolTipUnmute visible={unmuteAlert} onUnmute={onUnmute}>
      <IconButton onClick={onClick}>
        <MaterialIcon icon={mute ? 'mic_off' : 'mic'} color={white} />
      </IconButton>
    </ToolTipUnmute>
  );
});

export default MicButton;

import actionTypes from '../core/ActionTypes';

const {
  USER__UPDATE_INFORMATION,
  ADDRESS__SET_ADDRESS,
  ADDRESS__REMOVE_ADDRESS,
} = actionTypes;

export function setAddress(profileId, transport, address, primary = false) {
  return async (dispatch, getState, { graphql }) => {
    const data = await graphql.mutate(
      `(
      $profileId: String!
      $address: String!
      $transport: String!
      $primary: Boolean!
    ) {
      setAddress(
        profile_id: $profileId
        address: $address,
        transport: $transport
        primary: $primary
      ) {
        address
        display
        primary
        verified
        primary
      }
    }`,
      { profileId, address, transport, primary }
    );

    dispatch({
      type: ADDRESS__SET_ADDRESS,
      profileId,
      transport,
      address: data.setAddress,
    });
  };
}

export function requestEmailValidation(profileId, email, action = 'verify') {
  return (dispatch, getState, { graphql }) =>
    graphql.mutate(
      `(
    $profileId: String!
    $email: String!
    $action: EmailValidationAction!
  ) {
    requestEmailValidation(
      profile_id: $profileId
      email: $email
      action: $action
    ) {
      address
      display
      primary
      verified
      primary
    }
  }`,
      { profileId, email, action }
    );
}

export function validateEmail(token) {
  return async (dispatch, getState, { graphql }) => {
    const data = await graphql.mutate(
      `($token: String!) {
      validateEmail(token: $token) {
        address
        display
        primary
        verified
        primary
      }
    }`,
      { token }
    );

    const { viewer } = getState();

    if (viewer.id) {
      dispatch({
        type: ADDRESS__SET_ADDRESS,
        profileId: viewer.profile.id,
        transport: 'email',
        address: data.validateEmail,
      });

      if (data.validateEmail.primary) {
        dispatch({
          type: USER__UPDATE_INFORMATION,
          user: {
            id: viewer.id,
            email: {
              ...viewer.email,
              ...data.validateEmail,
              accepted: data.validateEmail.verified,
            },
          },
        });
      }
    }
  };
}

export function removeAddress(profileId, transport, address) {
  return async (dispatch, getState, { graphql }) => {
    const { removeAddress: addresses } = await graphql.mutate(
      `(
      $profileId: String!
      $transport: String!
      $address: String!
    ) {
      removeAddress(
        profile_id: $profileId,
        transport: $transport
        address: $address
      ) {
        address
        display
        primary
        verified
        primary
        confirmed
      }
    }`,
      { profileId, transport, address }
    );

    dispatch({
      type: ADDRESS__REMOVE_ADDRESS,
      profileId,
      transport,
      addresses,
    });
  };
}

import React, { useCallback } from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import makeStyles from '@mui/styles/makeStyles';
import langs from 'langs';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import FormLabel from '@mui/material/FormLabel';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { Select } from '../../FormAdapters';
import FAIcon from '../../Icon/FAIcon';
import { red500 } from '../../../core/colors';

const useStyles = makeStyles({
  root: {
    marginTop: 16,
  },

  label: {
    fontSize: 12,
  },

  column: {
    width: '50%',
    border: 0,
    padding: '0 16px 0 0',
  },

  remove: {
    border: 0,
  },

  add: {
    marginTop: 10,
  },
});

const fluencyOptions = [
  { value: null, label: '' },
  { value: 'elementary', label: 'Elementary proficiency' },
  { value: 'limited_working', label: 'Limited working proficiency' },
  { value: 'professional_working', label: 'Professional working proficiency' },
  { value: 'full_professional', label: 'Full professional proficiency' },
  { value: 'native_or_bilingual', label: 'Native or bilingual proficiency' },
];

function Languages({ onAdd, fields }) {
  const required = useCallback((value) => (value ? undefined : 'Required'), []);
  const codes = (fields.value || []).map((v) => v.code);
  const s = useStyles();

  return (
    <TableContainer classes={{ root: s.root }}>
      <FormLabel classes={{ root: s.label }}>Languages</FormLabel>
      <Table classes={{ root: s.table }} size="small">
        <TableBody>
          <FieldArray name="languages">
            {({ fields }) =>
              fields.map((name, index) => {
                const code = codes[index];
                const languages = langs
                  .all()
                  .filter((l) => code === l['3'] || !codes.includes(l['3']))
                  .map((l) => ({ value: l['3'], label: l.name }));

                return (
                  <TableRow key={name}>
                    <TableCell className={s.column}>
                      <Field
                        autocomplete
                        validate={required}
                        component={Select}
                        name={`${name}.code`}
                        options={languages}
                        limit={null}
                        placeholder="Language"
                      />
                    </TableCell>
                    <TableCell className={s.column}>
                      <Field
                        validate={required}
                        component={Select}
                        name={`${name}.fluency`}
                        options={fluencyOptions}
                        placeholder="Fluency"
                      />
                    </TableCell>
                    <TableCell className={s.remove}>
                      <IconButton
                        color="inherit"
                        onClick={() => fields.remove(index)}
                      >
                        <FAIcon size={16} color={red500} icon="trash" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })
            }
          </FieldArray>
        </TableBody>
      </Table>

      <div className={s.add}>
        <Fab type="button" size="small" color="secondary" onClick={onAdd}>
          <AddIcon />
        </Fab>
      </div>
    </TableContainer>
  );
}

export default Languages;

import React from 'react';
import cx from 'classnames';
import ConsultationPreview from '../ConsultationPreview';
import LongList from '../LongList';
import s from './ConsultationList.module.scss';

function ConsultationList({
  title,
  handleMore = null,
  consultations,
  loading,
  pageInfo,
  grid,
}) {
  if (consultations.length === 0 && !loading) return <div />;

  return (
    <LongList
      className={s.consultations}
      containerClassName={cx({ [s.gridList]: grid })}
      title={title}
      loading={loading}
      hasNextPage={!!pageInfo && pageInfo.hasNextPage}
      onMore={handleMore}
    >
      {consultations.map((c) => (
        <div key={c.id} className={cx(s.listItem, { [s.gridItem]: grid })}>
          <ConsultationPreview consultation={c} compact={grid} />
        </div>
      ))}
    </LongList>
  );
}

export default ConsultationList;

import React, { useMemo, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Filter from './Filter';
import FilterChips from './FilterChips';
import FilterButton from './FilterButton';
import SelectInput from './SelectInput';

const useStyles = makeStyles({
  action: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

export default function ChipFilter({
  title,
  hideDivider,
  value,
  options,
  onRemoveValue,
  onSubmit,
  placeholder,
  selectInputProps,
}) {
  const s = useStyles();

  const [selected, setSelected] = useState([]);

  const handleSubmit = () => {
    onSubmit([...value, ...selected.map((l) => l.value)]);
    setSelected([]);
  };

  const selectedOptions = useMemo(
    () => options.filter((opt) => value.includes(opt.value)),
    [options, value]
  );

  const availableOptions = useMemo(
    () => options.filter((opt) => !value.includes(opt.value)),
    [options, value]
  );

  return (
    <Filter title={title} hideDivider={hideDivider}>
      <SelectInput
        selected={selected}
        setSelected={setSelected}
        placeholder={placeholder}
        options={availableOptions || []}
        input={{ value: selected }}
        {...selectInputProps}
      />
      <div className={s.action}>
        <FilterButton disabled={!selected.length} onClick={handleSubmit} />
      </div>

      <FilterChips options={selectedOptions} onRemoveValue={onRemoveValue} />
    </Filter>
  );
}

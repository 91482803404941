import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { dialOutMe } from '../../actions/consultation';
import { notify } from '../../actions/ui';
import { isPhoneValid, normalizePhone } from '../../core/util';
import PhoneInput from '../PhoneInput/PhoneInput';
import Form from '../Form';
import Button from '../Button';
import s from './DialMe.module.scss';

function validate(values) {
  const errors = {};

  if (!values.phone) {
    errors.phone = 'Required';
  } else if (!isPhoneValid(values.phone)) {
    errors.phone = 'Phone must be valid.';
  }

  return errors;
}

function DialMe(props) {
  const { call, dialOutMe, notify, showDialingMe, closeDialingMe } = props;

  const handleSubmit = (values) => {
    dialOutMe({
      consultationId: call.consultationId,
      phoneNumber: values.phone,
    })
      .then((result) => {
        if (result.dialOutMe) {
          showDialingMe();
          setTimeout(closeDialingMe, 60000);
        } else {
          closeDialingMe();
          notify(`Could not dial to ${values.phone}.`, 'error');
        }
      })
      .catch(() => {
        closeDialingMe();
        notify('Consultation is invalid to use dial out.', 'error');
      });
  };

  return (
    <Form onSubmit={props.handleSubmit(handleSubmit)}>
      <div className={s.content}>
        <div className={s.field}>
          <Field
            component={PhoneInput}
            type="tel"
            name="phone"
            normalize={normalizePhone}
            helperText="+ 1 201 555 1234"
          />
        </div>
        <Button color="white" type="submit" size="small">
          Dial Me
        </Button>
      </div>
    </Form>
  );
}

DialMe = reduxForm({
  form: 'dialMe',
  validate,
})(DialMe);

export default connect(
  (state) => ({
    call: state.call,
    initialValues: {
      phone: state.viewer.phone,
    },
  }),
  {
    dialOutMe,
    notify,
  }
)(DialMe);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import ColumnSection from '../ColumnSection/ColumnSection';
import FAIcon from '../Icon/FAIcon';
import Button from '../Button';
import activityConfig from './activityConfig';
import { fetchActivities } from '../../actions/activity';
import { activitiesFor } from '../../reducers/activities';
import { dateFormat } from '../../core/time';
import { darkGreen } from '../../core/colors';
import s from './ActivityLog.module.scss';

function acceptedActivity(activity) {
  const configPresent = !!activityConfig(activity);
  if (!configPresent) {
    // eslint-disable-next-line no-console
    console.warn(
      'no activity log config for action',
      activity.object_type,
      activity.action
    );
    return false;
  }
  return configPresent;
}

function filterOlderNotes(activities) {
  const seenNotes = {};
  return activities
    .sort((a, b) => -moment(a.timestamp).diff(b.timestamp)) // most recent first
    .map((actvt) => {
      // if activity is not a candidate update, preserve it
      if (actvt.action !== 'candidate_update') return actvt;

      const {
        request_id: rid,
        client_note: cnote,
        rm_note: rnote,
        expert_note: enote,
      } = actvt.context || {};

      // if activity has no note, preserve it
      if (!cnote && !rnote && !enote) return actvt;

      // otherwise, keep just the most recent one for each request x author pair
      const notes = [cnote, rnote, enote].filter(Boolean);
      const noteKey = (note) => `${rid}#${note.author_id}`;
      const shouldPreserve = notes.some((n) => !seenNotes[noteKey(n)]); // check
      notes.forEach((n) => (seenNotes[noteKey(n)] = true)); // record seen
      return shouldPreserve ? actvt : null;
    })
    .filter(Boolean);
}

function groupByDate(activities) {
  return Object.entries(
    activities.reduce(
      // group activities by date
      (groups, actvt) => {
        const groupKey = moment(actvt.timestamp).format('YYYY-MM-DD');
        return {
          ...groups,
          [groupKey]: [...(groups[groupKey] || []), actvt],
        };
      },
      {}
    )
  )
    .map(
      // format date for each date group and sort activities
      ([date, activities]) => ({
        date,
        formatedDate: moment(date).format(dateFormat),
        activities:
          // activities sorted by timestamp, most recent first
          activities.sort((a, b) => -moment(a.timestamp).diff(b.timestamp)),
      })
    )
    .sort(
      // date groups sorted by date, most recent first
      (a, b) => -moment(a.date).diff(b.date)
    );
}

class ActivityItem extends PureComponent {
  render() {
    const { activity, loading } = this.props;
    const config = activityConfig(activity);
    if (!config) {
      return (
        <div className={`${s.item} ${s.missing}`}>
          Missing action config for {activity.object_type}_{activity.action}
        </div>
      );
    }
    const { renderer: ActivityActionRenderer } = config;

    return (
      <div className={s.item}>
        <FAIcon
          size={16}
          icon={config.icon}
          style={{ color: darkGreen, marginRight: 5 }}
        />
        <div>
          <ActivityActionRenderer activity={activity} loading={loading} />
        </div>
        <hr />
      </div>
    );
  }
}

function ActivityGroup({ dateGroup, loading }) {
  return (
    <div className={s.activityGroup}>
      <div className={s.date}>{dateGroup.formatedDate}</div>
      {dateGroup.activities.map((actvt) => (
        <ActivityItem key={actvt.id} activity={actvt} loading={loading} />
      ))}
    </div>
  );
}

class ActivityLog extends PureComponent {
  static contextTypes = {
    store: PropTypes.shape({
      dispatch: PropTypes.func.isRequired,
    }).isRequired,
  };

  state = {
    loading: true,
  };

  async componentDidMount() {
    const { activities, fetchActivities, objectType, objectId, actions } =
      this.props;
    if (activities) return;
    await fetchActivities(objectType, objectId, actions);

    await this.loadDependentData();
  }

  onMore = async () => {
    const { objectType, objectId, actions, activities, fetchActivities } =
      this.props;
    await fetchActivities(objectType, objectId, actions, activities.nextCursor);
    await this.loadDependentData();
  };

  loadDependentData = async () => {
    const { store } = this.context;
    this.setState({ loading: true });
    for (const activity of this.activities()) {
      const config = activityConfig(activity);
      if (!config || !config.fetchActions) continue;
      for (const fetchAction of config.fetchActions(activity).filter(Boolean)) {
        await store.dispatch(fetchAction);
      }
    }
    this.setState({ loading: false });
  };

  activities() {
    const { activities } = this.props;
    const acceptedActivities = filterOlderNotes(
      (activities && activities.data.filter(acceptedActivity)) || []
    );
    return acceptedActivities;
  }

  render() {
    const { loading } = this.state;
    const activities = this.activities();
    if (!activities || !activities.length) return null;

    const dateGroups = groupByDate(activities);

    return (
      <ColumnSection title="Activity">
        {!dateGroups.length && (
          <div className={s.noActivities}>No activity</div>
        )}
        {dateGroups.map((group) => (
          <ActivityGroup key={group.date} dateGroup={group} loading={loading} />
        ))}
        {this.props.activities.nextCursor && (
          <div className={s.loadMore}>
            <Button onClick={this.onMore}>Load More</Button>
          </div>
        )}
      </ColumnSection>
    );
  }
}

ActivityLog = ActivityLog;

ActivityLog = connect(
  (state, { objectType, objectId }) => ({
    activities: activitiesFor(state.activities, objectType, objectId),
  }),
  {
    fetchActivities,
  }
)(ActivityLog);

export default ActivityLog;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LongText from '../LongText';
import s from './WizardDone.module.scss';

class WizardDone extends Component {
  render() {
    const { title, text, children } = this.props;

    return (
      <div className={s.root}>
        <h2 className={s.title}>{title}</h2>
        <LongText className={s.text} text={text} />
        {children}
      </div>
    );
  }
}

WizardDone.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default WizardDone;

import ActionTypes from '../core/ActionTypes';

const { APP_NOTIFICATION__LIST, APP_NOTIFICATION__REMOVE } = ActionTypes;

export default function appNotificationsReducer(state = {}, action) {
  switch (action.type) {
    case APP_NOTIFICATION__LIST:
      return {
        ...state,
        [action.collection]: action.appNotifications,
      };
    case APP_NOTIFICATION__REMOVE:
      return Object.keys(state).reduce(
        (acc, col) => ({
          ...acc,
          [col]: collectionReducer(state[col], action),
        }),
        {}
      );
    default:
      return state;
  }
}

function collectionReducer(state = [], action) {
  switch (action.type) {
    case APP_NOTIFICATION__REMOVE:
      return state.filter((n) => n.id !== action.notificationId);
    default:
      return state;
  }
}

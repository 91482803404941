import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import { Field, reduxForm } from 'redux-form';
import { TextField } from '../FormAdapters';
import Form from '../Form';
import ShareableLink from '../ShareableLink/ShareableLink';
import MediaQuery from '../MediaQuery';
import { buildRefUrl } from '../../core/expertRequest';
import { isEmailValid, queryPart } from '../../core/util';
import { SCREEN_MD } from '../../constants';
import { inviteExpert, isOpportunityType } from '../../actions/expertRequest';
import { notify } from '../../actions/ui';
import s from './ReferFriend.module.scss';
import Button from '../Button/Button';

class ReferFriend extends React.Component {
  static contextTypes = {
    location: PropTypes.object,
  };

  inviteExpert = (values) => {
    const { expertRequest, notify, reset, inviteExpert } = this.props;

    if (!values.email) {
      notify('Please enter an email address.', 'error');
      return;
    }

    if (!isEmailValid(values.email)) {
      notify('Invalid email address.', 'error');
      return;
    }

    inviteExpert(expertRequest.slug, values.email)
      .then(() => {
        notify('Invite sent!', 'success');
        reset();
      })
      .catch((err) => {
        notify('An error occurred when sending invite.', 'error');
        throw err;
      });
  };

  render() {
    const { location } = this.context;
    const { expertRequest, viewer, action, handleSubmit, tags } = this.props;
    const url = buildRefUrl(location, expertRequest, viewer.id, 'linkedin');
    const isSignedIn = viewer && viewer.id;

    const signupQuery = queryPart({ t: tags });
    const objectName = isOpportunityType(expertRequest.er_type)
      ? 'opportunity'
      : 'project';
    const title = `Refer this ${objectName} to a friend`;

    return (
      <MediaQuery maxWidth={SCREEN_MD}>
        {(isMobileVersion) => (
          <div
            className={cx(s.action, {
              [s.sticky]: isMobileVersion && action === 'refer',
            })}
          >
            <div className={s.actionTitle}>{title}</div>

            <p className={s.actionSubTitle}>
              Receive a $100 bonus if they do a call
            </p>

            <div>
              {isSignedIn && (
                <Form
                  onSubmit={handleSubmit(this.inviteExpert)}
                  style={{ display: 'flex', 'align-items': 'flex-end' }}
                >
                  <Field
                    id="inviteExpertEmail"
                    name="email"
                    component={TextField}
                    fullWidth={isMobileVersion}
                    style={{
                      width: 'calc(100% - 110px)',
                      marginRight: 10,
                      textAlign: 'left',
                    }}
                    placeholder="Enter email address"
                  />
                  <Button type="submit" size="small" style={{ width: 100 }}>
                    Send
                  </Button>
                </Form>
              )}
              {isSignedIn ? (
                <ShareableLink
                  url={url}
                  enableShorten={false}
                  inputStyle={{ width: 'calc(100% - 111px)' }}
                  buttonStyle={{ width: 100 }}
                />
              ) : (
                <Button
                  href={`${expertRequest.public_html_url}/refer/signup${signupQuery}`}
                  size="small"
                >
                  Log in to refer a friend
                </Button>
              )}
            </div>
          </div>
        )}
      </MediaQuery>
    );
  }
}

ReferFriend = reduxForm({
  form: 'inviteExpert',
})(ReferFriend);

ReferFriend = ReferFriend;

ReferFriend = connect(undefined, {
  inviteExpert,
  notify,
})(ReferFriend);

export default ReferFriend;

import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import EditDialog from '../../../components/EditDialog';
import { notify } from '../../../actions/ui';
import {
  TextField,
  Checkbox,
} from '../../../components/FormAdapters/FormAdapters';

class EditMatchNote extends React.Component {
  handleSubmit = (values) => {
    const { onSubmit, notify } = this.props;
    onSubmit(values)
      .then(() => {
        notify('Research Manager note updated.', 'success');
      })
      .catch((err) => {
        notify('Error while updating Research Manager note.', 'error');
        throw err;
      });
  };

  render() {
    const { handleSubmit, reset, noteValue, profile, ...props } = this.props;
    return (
      <EditDialog
        {...props}
        onSubmit={handleSubmit(this.handleSubmit)}
        onReset={() => reset()}
        // bogus attribute (force dialog position update on text change)
        fieldValue={noteValue}
      >
        <Field
          id="match_note.note"
          component={TextField}
          fullWidth
          multiline
          name="match_note.note"
          label="Research Manager note"
          placeholder={
            'Example: Based on my vetting calls, I think ' +
            `${profile.first_name} is a good fit for your needs based on ` +
            'the following...'
          }
          maxRows={15}
        />
        <Field
          type="checkbox"
          component={Checkbox}
          name="match_note.recommended"
          label="Add my recommendation"
          style={{ margin: '15px 0' }}
        />
      </EditDialog>
    );
  }
}

EditMatchNote = reduxForm({ enableReinitialize: true })(EditMatchNote);
export default connect(
  (state, ownProps) => {
    const formName = `candidateMatchNote-${ownProps.initialValues.id}`;
    return {
      form: formName,
      noteValue: formValueSelector(formName)(state, 'match_note.note'),
    };
  },
  {
    notify,
  }
)(EditMatchNote);

import ActionTypes from '../core/ActionTypes';

const { CALL__UPDATE } = ActionTypes;

const initialState = {};

export default function callReducer(state = initialState, action) {
  switch (action.type) {
    case CALL__UPDATE:
      return {
        ...state,
        ...action.call,
      };
    default:
      return state;
  }
}

import React, { useState } from 'react';
import { connect } from 'react-redux';
import Picture from '../Picture';
import UserMenu from './UserMenu';
import { fetchConflictsCount } from '../../actions/profile';

export default function PictureNav({
  viewer,
  fetchConflictsCount,
  size,
  conflictsCount,
  id,
}) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuAnchorElement, setMenuAnchorElement] = useState(undefined);

  const handleMenuOpen = async (event) => {
    const target = event.currentTarget;
    if (viewer && viewer.admin) fetchConflictsCount();

    setMenuOpen(true);
    setMenuAnchorElement(target);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
    setMenuAnchorElement(null);
  };

  return (
    <div style={{ cursor: 'pointer' }}>
      <Picture
        size={size}
        user={viewer}
        onClick={handleMenuOpen}
        link={false}
        id={id}
      />

      <UserMenu
        open={menuOpen}
        anchorEl={menuAnchorElement}
        onRequestClose={handleMenuClose}
        conflictsCount={conflictsCount}
      />
    </div>
  );
}

PictureNav = connect(
  (state) => ({
    conflictsCount: state.profiles.counts.conflicts,
  }),
  {
    fetchConflictsCount,
  }
)(PictureNav);

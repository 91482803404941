import 'whatwg-fetch';
import gql from 'graphql-tag';
import React from 'react';
import { createRoot } from 'react-dom/client';
import StyleContext from 'isomorphic-style-loader/StyleContext';
import deepForceUpdate from 'react-deep-force-update';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import queryString from 'query-string';
import moment from 'moment-timezone';
import * as Sentry from '@sentry/react';

import './core/compat';
import Layout from './components/Layout';
import App from './App';
import configureStore from './store/configureStore';
import { setRuntimeVariable } from './actions/runtime';
import { setLoadingProgress } from './actions/loading';
import { updateProfile } from './actions/profile';
import { createToken, updateCall } from './actions/call';
import { notify, setUserContext } from './actions/ui';
import { track } from './actions/tracking';
import { createEmotionCache, createV5Theme } from './core/muiTheme';
import { saveTracking } from './core/tracking';
import { saveInvitationToken } from './core/invite';
import { GraphQLClient, bearerAuth } from './core/api';
import ActionTypes from './core/ActionTypes';
import { createSendBirdHelper } from './core/sendBird';
import createPermissionService from './core/permissions';
import voipCarrier from './core/telephony/voipCarrier';
import TwilioCarrier from './core/telephony/twilioCarrier';
import PlivoCarrier from './core/telephony/plivoCarrier';
import ZoomCarrier from './core/telephony/zoomCarrier';
import ApiWebSocket from './core/apiWebSocket';
import config, { hiddenConfig } from '../config';
import router from './router';
import { updateMeta } from './DOMUtils';
import history from './core/history';
import {
  getUserContextOptions,
  isGroupContext,
  getDefaultUserContext,
} from './core/user';
import { initSegmentIdentifier } from './core/segment';
import { getCache } from './core/util';
import { UnauthorizedError } from './core/apiTransport';
import { logout } from './core/login';
import './index.css';

const accessToken = getCache('access_token') || {};
const token = accessToken.token;
let graphql = new GraphQLClient(
  config.apiUrl,
  token ? bearerAuth(token) : undefined
);
let viewer = {};
if (token) {
  try {
    viewer = await fetchUserInfo(graphql);
  } catch (err) {
    if (!(err instanceof UnauthorizedError)) {
      throw err;
    }
    logout();
    graphql = new GraphQLClient(config.apiUrl);
  }
}

// KT: setup sentry user as soon as possible to give more context
if (viewer.id) {
  const segment = viewer.admin ? 'admin' : viewer.signup_type;
  Sentry.configureScope((scope) => {
    scope.setUser({
      id: viewer.id,
      username: viewer.username,
      email: viewer.email.address,
      segment,
    });
  });
}

viewer.intercomHash = getCache('intercom_hash');

const apiWebSocket = new ApiWebSocket(config.webSocketUrl, token);
const sendBird = createSendBirdHelper(viewer, graphql);

const initialState = {
  viewer,
  users: {
    currentId: viewer.id,
    [viewer.id]: viewer,
  },
  runtime: {
    referrer: document.referrer,
    token,
    login: accessToken.loginMeta || undefined,
  },
  ui: {
    notifications: [],
  },
};
const store = configureStore(initialState, {
  graphql,
  history,
  sendBird,
  voipCarrier,
  apiWebSocket,
});
const cache = createEmotionCache();

// Infer user context
let userContext = 'client';
let userContextOptions = [];
if (viewer.id) {
  userContext = getCache('user_context');
  userContextOptions = getUserContextOptions(viewer);
  const userContextOptionExists =
    userContext && userContextOptions.some((o) => o.value === userContext);

  if (!userContextOptionExists) {
    if (isGroupContext(userContext) && viewer.admin) {
      const data = await graphql.query(
        'query ($id: String) { group(id: $id) { id, name } }',
        {
          id: userContext,
        }
      );
      userContextOptions = getUserContextOptions(viewer, userContext, [
        data.group,
      ]);
    } else {
      userContext = getDefaultUserContext(viewer);
    }
  }

  store.dispatch(setUserContext(userContext));
}

store.dispatch({ type: ActionTypes.LOAD_FROM_LOCAL_STORAGE });

// Enables critical path CSS rendering
// https://github.com/kriasoft/isomorphic-style-loader
const insertCss = (...styles) => {
  const removeCss = styles.map((style) => style._insertCss());
  return () => removeCss.forEach((dispose) => dispose());
};

// Global (context) variables that can be easily accessed from any React
// component https://facebook.github.io/react/docs/context.html
const context = {
  // Integrate Redux
  // http://redux.js.org/docs/basics/UsageWithReact.html
  store,

  // GraphQL client (Lokka)
  // https://github.com/kadirahq/lokka
  graphql,

  location: window.location,
  sendBird,
  voipCarrier,
  permissions: createPermissionService(store),
  apiWebSocket,
};

// Always returns false at the first call on useMediaQuery
// https://github.com/mui-org/material-ui/issues/21142
const muiV5Theme = createV5Theme({
  MuiUseMediaQuery: { ssrMatchMedia: window.matchMedia },
});

// Telephony
voipCarrier.registerCarrier('twilio', TwilioCarrier);
voipCarrier.registerCarrier('plivo', PlivoCarrier);
voipCarrier.registerCarrier('zoom', ZoomCarrier);
voipCarrier.setTokenFactory((identifier) =>
  store.dispatch(createToken(identifier))
);
voipCarrier.on('callStateChange', (state) => store.dispatch(updateCall(state)));
voipCarrier.on('error', (err) => {
  store.dispatch(notify(err.message, 'error'));
  const { viewer, call } = store.getState();
  err.userId = viewer.id;
  err.consultationId = call.consultationId;
  err.sid = call.sid;
  trackConsultationJoin('error');
  Promise.reject(err);
});
voipCarrier.on('connect', () => {
  trackConsultationJoin('success');
});

async function fetchUserInfo(client, userId) {
  const data = await client.send(
    gql`
      query getUser($userId: String) {
        user(id: $userId) {
          id
          billing_account_id
          admin
          expert_state
          compliance_completed_at
          signup_type
          has_password
          locked
          signup_subdomain
          password_expiry {
            expiry
            expired
            expiring
          }
          agreements {
            policy
            updated_at
            accepted
          }
          groups {
            id
            name
            slug
            html_url
            branding_logo_url
            branding_show_poweredbyof
            billing_account {
              id
              type
              state
              credit_balance {
                cents
                currency
              }
            }
            about
            account_type
            internal
            internal_network {
              id
              name
            }
            default_anonymous_messaging
          }
          profile {
            id
            first_name
            last_name
            name
            html_url
            url_endpoint
            linkedin_username
            linkedin_url
            title
            summary
            skype
            timezone
            city
            country
            picture_url
            questions
            cv_url
            available_long_term
            available_marketplace
            hide_profile
            bill_rate
            credit_rate
            languages {
              code
              fluency
            }
            keywords
            tier
          }
          # deprecated fields
          first_name
          last_name
          name
          picture_url
          country_code
          timezone
          username
          phone
          html_url
          email {
            address
            accepted
            confirmed
          }
        }
      }
    `,
    { userId }
  );

  return data.user;
}

function trackConsultationJoin(action) {
  const { call } = store.getState();
  store.dispatch(
    track(
      `consultation.join.web.${action}`,
      call.consultationId,
      {
        ua: navigator && navigator.userAgent,
      },
      false,
      true
    )
  );
}

function gtag(...args) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(args);
}

function hsq(...args) {
  window._hsq = window._hsq || [];
  window._hsq.push(args);
}

function fbq(...args) {
  window._fbq = window._fbq || [];
  window._fbq.push(args);
}

function trackPageView(path, title) {
  // Segment
  if (window.analytics?.page) {
    window.analytics.page(path);
  }

  // Google analytics
  gtag('event', 'page_view', {
    page_title: title,
    page_location: path,
  });

  // HubSpot
  hsq('setPath', path);
  hsq('trackPageView');

  // Facebook
  // disablePushState needs to be true
  fbq('track', 'PageView');
}

function renderReactApp(root, domNode, reactNode) {
  if (!root) {
    root = createRoot(domNode);
  }
  root.render(reactNode);
  return root;
}

const container = document.getElementById('root');
let currentLocation = history.location;
let appInstance;

const scrollPositionsHistory = {};
let currentRoute;

// Re-render the app when window.location changes
async function onLocationChange(location, action) {
  console.log('navigating', location, action);
  // Update referrer on push navigation
  context.store.dispatch(
    setRuntimeVariable({
      name: 'referrer',
      value: `${window.location.origin}${currentLocation.pathname}`,
    })
  );

  // Remember the latest scroll position for the previous location
  scrollPositionsHistory[currentLocation.key] = {
    scrollX: window.pageXOffset,
    scrollY: window.pageYOffset,
  };
  // Delete stored scroll position for next page if any
  if (action === 'PUSH') {
    delete scrollPositionsHistory[location.key];
  }
  currentLocation = location;

  const isInitialRender = !action;
  try {
    context.pathname = location.pathname;
    context.query = queryString.parse(location.search);

    context.store.dispatch(setLoadingProgress(10));

    if (isInitialRender) {
      // Prerender to show page sooner
      appInstance = renderReactApp(
        appInstance,
        container,
        <CacheProvider value={cache}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={muiV5Theme}>
              <App context={context}>
                <Layout />
              </App>
            </ThemeProvider>
          </StyledEngineProvider>
        </CacheProvider>
      );
    }

    // Traverses the list of routes in the order they are defined until
    // it finds the first route that matches provided URL path string
    // and whose action method returns anything other than `undefined`.
    const route = await router.resolve(context);

    // Prevent multiple page renders during the routing process
    if (currentLocation.key !== location.key) {
      return;
    }

    if (route.redirect && route.redirect.startsWith('http')) {
      // history.replace does not accept full URL while in browser,
      // only the path, query string, hash part. If a full url redirect is
      // requested, falling back to window.location.replace method.
      window.location.replace(route.redirect);
      return;
    }

    if (route.redirect) {
      console.log('redirecting', route.redirect);
      history.replace(route.redirect);
      return;
    }

    // Send page view
    trackPageView(location.pathname, route.title);

    const onRenderDone = () => {
      if (isInitialRender) {
        currentRoute = route;

        // Switch off the native scroll restoration behavior and handle it
        // manually
        // https://developers.google.com/web/updates/2015/09/history-api-scroll-restoration
        if (window.history && 'scrollRestoration' in window.history) {
          window.history.scrollRestoration = 'manual';
        }
      }

      context.store.dispatch(setLoadingProgress(100));

      document.title = route.title;
      updateMeta('description', route.description);

      const isSameRoute =
        route.id && currentRoute.id && route.id === currentRoute.id;
      const disableScrollRestoration =
        isSameRoute && route.disableSamePathScrollRestoration;
      currentRoute = route;
      if (disableScrollRestoration) return;

      let scrollX = 0;
      let scrollY = 0;
      const pos = scrollPositionsHistory[location.key];
      if (pos) {
        scrollX = pos.scrollX;
        scrollY = pos.scrollY;
      } else {
        const targetHash = location.hash.substr(1);
        if (targetHash) {
          const target = document.getElementById(targetHash);
          if (target) {
            scrollY = window.scrollY + target.getBoundingClientRect().top;
          }
        }
      }

      // Restore the scroll position if it was saved into the state
      // or scroll to the given #hash anchor
      // or scroll to top of the page
      window.scrollTo(scrollX, scrollY);
    };

    appInstance = renderReactApp(
      appInstance,
      container,
      <CacheProvider value={cache}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={muiV5Theme}>
            <App context={context} onRenderDone={onRenderDone}>
              {route.component}
            </App>
          </ThemeProvider>
        </StyledEngineProvider>
      </CacheProvider>
    );
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      throw error;
    }
    console.error(error);
    Sentry.captureException(error);
  }
}

// Helpers
function initHubspot(user = {}) {
  if (user.id) {
    hsq('identify', { id: user.id, email: user.email.address });
  }
}

function initIntercom(appId, user = {}) {
  // Disable for now
  window.Intercom = function () {};
  if (!appId) return;

  const settings = {
    app_id: appId,
  };

  if (user.id) {
    Object.assign(settings, {
      user_id: user.id,
      user_hash: user.intercomHash,
    });
  }

  const group = user.groups && user.groups[0];
  if (group) {
    settings.company = {
      id: group.id,
      name: group.name,
    };
  }

  window.Intercom('boot', settings);
}

function initGoogleTagManager(user = {}, loginMeta = {}) {
  const { dataLayer } = window;
  if (!dataLayer) return;

  if (loginMeta.signup) {
    dataLayer.push({
      event: 'signup',
      signupType: loginMeta.signupType || 'unknown',
      loginMethod: loginMeta.method,
      userId: user.id,
    });
  }
}

function saveTimezone(user) {
  if (user && user.id && user.profile && !user.timezone) {
    store.dispatch(
      updateProfile({
        id: user.profile.id,
        timezone: moment.tz.guess(),
      })
    );
  }
}

// Init

initGoogleTagManager(viewer, accessToken.login_meta || undefined);

initSegmentIdentifier(viewer);

saveTracking();

saveInvitationToken();

saveTimezone(viewer);

initIntercom(hiddenConfig.intercomAppId, viewer);

initHubspot(viewer);

// Handle client-side navigation by using HTML5 History API
// For more information visit https://github.com/mjackson/history#readme
history.listen(onLocationChange);
onLocationChange(currentLocation);

// Enable Hot Module Replacement (HMR)
if (module.hot) {
  module.hot.accept('./router', () => {
    if (appInstance && appInstance.updater.isMounted(appInstance)) {
      // Force-update the whole tree, including components that refuse to update
      deepForceUpdate(appInstance);
    }

    onLocationChange(currentLocation, 'HMR');
  });
}

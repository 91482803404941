import React from 'react';
import { Field } from 'react-final-form';
import Checkbox from '../../Checkbox';

class Disclosure extends React.PureComponent {
  static getValue = (checked) => {
    if (checked) {
      return 'private';
    }
    return 'full';
  };

  getChecked = () => {
    const value = this.props.formValues.disclosure;
    if (value === 'private') {
      return true;
    }
    return false;
  };

  handleChange = (_, checked) => {
    this.props.change('disclosure', Disclosure.getValue(checked));
  };

  render = () => {
    const { formValues, change, ...other } = this.props;
    return (
      <Field
        id="expertRequestDisclosure"
        component={Checkbox}
        name="disclosure"
        label="Keep My Company Hidden"
        onChange={this.handleChange}
        checked={this.getChecked()}
        {...other}
      />
    );
  };
}

export default Disclosure;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import FAIcon from '../Icon/FAIcon';
import Button from '../Button';
import { popup, openFileDialog } from '../../actions/ui';

class CvButton extends Component {
  static defaultProps = {
    editable: true,
    label: 'Attach CV',
  };

  upload = async () => {
    const { openFileDialog, onUpload } = this.props;
    const file = await openFileDialog({
      accept: ['.doc', '.docx', '.pdf', '.rtf'],
      maxSize: 3 * 1024 * 1024, // 3 MB
      fromSources: [
        'local_file_system',
        'url',
        'googledrive',
        'dropbox',
        'box',
        'onedrive',
        'onedriveforbusiness',
      ],
    });
    onUpload(file);
  };

  remove = () => {
    const { popup, onRemove } = this.props;
    popup({
      buttonAlignment: 'space-between',
      title: 'Do you want to remove the attached CV from the profile?',
      buttons: [
        { flat: true, label: 'Cancel' },
        {
          label: 'Remove',
          callback: onRemove,
        },
      ],
    });
  };

  render() {
    const { url, label, editable } = this.props;

    if (url) {
      return (
        <>
          <Button href={url} target="_blank" size="medium">
            Open CV
          </Button>
          {editable && (
            <IconButton
              onClick={this.remove}
              size="small"
              style={{ marginLeft: 10 }}
            >
              <FAIcon icon="trash" />
            </IconButton>
          )}
        </>
      );
    }

    if (!editable) return null;

    return (
      <Button size="medium" onClick={this.upload}>
        {label}
      </Button>
    );
  }
}

export default connect(undefined, {
  openFileDialog,
  popup,
})(CvButton);

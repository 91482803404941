import React from 'react';
import cx from 'classnames';
import SvgIcon from '../Icon/SvgIcon';
import Picture from '../Picture';
import { darkGreen } from '../../core/colors';
import s from './Testimonial.module.scss';

function Testimonial({
  dark,
  vertical,
  name,
  title,
  company,
  children,
  quoteDirection = 'left',
  className,
  contentStyle,
  quoteStyle,
  authorStyle,
  iconSize = 32,
  iconColor = darkGreen,
  pictureNextToName = false,
  picture,
  pictureSize = 90,
  ...other
}) {
  const classNames = cx(s.root, {
    [className]: !!className,
    [s.dark]: dark,
    [s.vertical]: vertical,
  });
  return (
    <div className={classNames} {...other}>
      {!pictureNextToName && picture && (
        <Picture
          src={picture}
          size={pictureSize}
          style={{
            marginRight: vertical ? 0 : 20,
            marginBottom: vertical ? 10 : 0,
            flex: '0 0 auto',
            backgroundColor: 'transparent',
          }}
        />
      )}
      <div className={s.content} style={contentStyle}>
        {quoteDirection === 'left' && (
          <div className={s.leftQuote}>
            <SvgIcon
              fill={iconColor}
              width={iconSize}
              height={iconSize}
              icon="quote"
            />
          </div>
        )}

        <div>
          <div className={s.quote} style={quoteStyle}>
            {children}
          </div>

          <div className={s.author}>
            {pictureNextToName && picture && (
              <Picture
                src={picture}
                size={pictureSize}
                style={{
                  marginRight: 15,
                  flex: '0 0 auto',
                  backgroundColor: 'transparent',
                }}
              />
            )}
            <div className={s.authorName} style={authorStyle}>
              {name}
              {name && <br />}
              {title}
              <br />
              {company}
            </div>
          </div>
        </div>

        {quoteDirection === 'right' && (
          <div className={s.rightQuote}>
            <SvgIcon
              fill={iconColor}
              width={iconSize}
              height={iconSize}
              icon="quote"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Testimonial;

export function fetchAboutPageSections() {
  return async (dispatch, getState, { graphql }) => {
    const result = await graphql.query(`query aboutPageSections {
      aboutPageSections {
        name
        members {
          name
          title
          profile_slug
          picture_url
        }
      }
    }`);

    return result.aboutPageSections;
  };
}

import ActionTypes from '../core/ActionTypes';

export default function countriesReducer(state = [], action) {
  switch (action.type) {
    case ActionTypes.COUNTRIES__FETCH_SUCCESS:
      return action.countries;
    default:
      return state;
  }
}

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { fetchUser } from '../../actions/user';
import Button from '../Button';
import InternalNetworkShareMultipleProfiles from './InternalNetworkShareMultipleProfiles';

class InternalNetworkShareButton extends PureComponent {
  state = {
    dialogOpen: false,
  };

  handleOpen = async () => {
    const { viewer, fetchUser } = this.props;

    if (!viewer.admin) {
      await fetchUser(viewer.username, {
        groups: true,
        sharedInternalNetworks: true,
      });
    }

    this.setState({ dialogOpen: true });
  };

  handleClose = () => {
    this.setState({ dialogOpen: false });
  };

  render() {
    const {
      profile,
      internalNetworks,
      onExpertShare,
      style,
      fetchUser,
      ...other
    } = this.props;

    const profiles = profile ? [profile] : this.props.profiles || [];
    if (profiles.length === 0) return null;

    return (
      <div>
        <Button
          size="small"
          {...other}
          style={{
            fontWeight: 'bold',
            letterSpacing: '-0.5px',
            ...style,
          }}
          onClick={this.handleOpen}
        >
          Share
        </Button>
        <InternalNetworkShareMultipleProfiles
          open={this.state.dialogOpen}
          profiles={profiles}
          onClose={this.handleClose}
          onExpertShare={onExpertShare}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    viewer: state.viewer,
  }),
  {
    fetchUser,
  }
)(InternalNetworkShareButton);

import React from 'react';
import { connect } from 'react-redux';
import { addGroupMember } from '../../actions/group';
import { inviteUser } from '../../actions/invitation';
import { notify } from '../../actions/ui';
import AddUserDialog from '../../components/AddUserDialog';

function AddTeamMemberDialog({
  groupId,
  addGroupMember,
  inviteUser,
  notify,
  onRequestDone,
  ...rest
}) {
  const done = (ref) => {
    if (onRequestDone) {
      onRequestDone(ref);
    }
  };

  const handleRequestAdd = (values) => {
    const usersAdded = values
      .filter((v) => v.type === 'user')
      .map((u) => ({ userId: u.value.id, name: u.value.name, role: 'member' }))
      .map((member) => ({
        ref: member.name,
        promise: addGroupMember(groupId, member),
      }));

    const usersInvited = values
      .filter((v) => v.type === 'email')
      .map((v) => ({
        email: v.value.email,
        collectionType: 'group',
        collectionId: groupId,
        role: 'member',
      }))
      .map((member) => ({
        ref: member.email,
        promise: inviteUser(member),
      }));

    [...usersAdded, ...usersInvited].forEach(({ ref, promise }) => {
      promise.then(
        () => {
          done(ref);

          notify(`${ref} added successfully.`, 'success', 3000);
        },
        (e) => {
          done(ref);

          if (e.isPermissionError) {
            notify(`You don't have permission to add ${ref}}.`, 'error', 3000);
          } else if (e.message === 'GraphQL Error: member already exists') {
            notify(`${ref} was already a member`, 'warning', 3000);
          } else if (
            e.message &&
            e.message.startsWith('GraphQL Error: user with email')
          ) {
            notify(`${ref} already a user, no invitation sent`, 'error', 3000);
          } else {
            notify(`An error occurred when adding ${ref}.`, 'error', 3000);
          }
        }
      );
    });
  };

  return (
    <AddUserDialog
      title="Add team member"
      allowEmails
      onRequestAdd={handleRequestAdd}
      {...rest}
    />
  );
}

AddTeamMemberDialog = connect(undefined, {
  inviteUser,
  addGroupMember,
  notify,
})(AddTeamMemberDialog);

export default AddTeamMemberDialog;

import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Field as FinalField } from 'react-final-form';
import isEqual from 'lodash.isequal';
import Divider from '@mui/material/Divider';
import { fetchCountries } from '../../../actions/country';
import { fetchSectors } from '../../../actions/sector';
import { TextField, Select } from '../../FormAdapters/FormAdapters';
import Companies from './Companies';
import Disclosure from './Disclosure';
import FocusAreas from './FocusAreas';
import Project from './Project';
import ExpertQueries from './ExpertQueries';
import Attachments from './Attachments';
import SelectLocation from '../../SelectLocation';
import SelectSector from '../../SelectSector';
import DurationField from '../../DurationField';
import LabelledOutline from '../../LabelledOutline';
import { required } from '../../../core/util';
import { getTimezoneDescription } from '../../../core/time';
import s from './DetailsNew.module.scss';
import {
  isWorkType,
  isAttachmentType,
  isSetExpectedDurationType,
  erTypes,
  erTypeLabels,
} from '../../../actions/expertRequest';
import KeywordInput from '../../KeywordInput/KeywordInput';
import DateTimePicker from '../../DateTimePicker/DateTimePicker';
import Link from '../../Link';
import config from '../../../../config';

class FormSection extends React.PureComponent {
  render = () => {
    const { title, subtitle, showDivider, children } = this.props;

    return (
      <div className={s.section}>
        {showDivider && <Divider />}
        <div className={s.sectionHeader}>
          <p className={s.sectionTitle}>{title}</p>
          <p className={s.sectionSubtitle}>{subtitle}</p>
        </div>
        <div className={s.sectionFields}>{children}</div>
      </div>
    );
  };
}

class FormSubSection extends React.PureComponent {
  render = () => {
    const { title, subtitle, children } = this.props;

    return (
      <div className={s.subsection}>
        <div className={s.subsectionHeader}>
          <p className={s.subsectionTitle}>{title}</p>
          <p className={s.subsectionSubtitle}>{subtitle}</p>
        </div>
        <div className={s.subsectionFields}>{children}</div>
      </div>
    );
  };
}

const FieldIf = memo(({ name, showFields, hideFields, ...other }) => {
  return showFields.includes(name) && !hideFields.includes(name) ? (
    <FinalField name={name} {...other} />
  ) : null;
});

const workTypeFields = ['job_scope', 'opportunity_location'];

const maxAttachmentFiles = 5;
const maxAttachmentSize = 10;
const attachmentsAddStyle = { marginLeft: 40 };

function DetailsNew({
  values,
  groups,
  projects,
  change,
  canEdit = true,
  canEditQueries = true,
  canAddAttachment = true,
  allCountries,
  allSectors,
  isMobileVersion,
  isViewerExpert,
  viewer,
  showFields = [
    'er_type',
    'group_about',
    'project',
    'name',
    'description',
    'qualifications',
    'questions',
    'companies',
    'disclosure',
    'sectors',
    'regions',
    'focusAreas',
    'instructions_research',
    'attachments',
    'tags',
    'expected_duration',
    'time_done_scoping_call',
    ...workTypeFields,
  ],
  hideFields = [],
}) {
  useEffect(() => {
    const project = projects?.edges?.find(
      (p) => p.node.id === values.project_id
    )?.node;
    const groupId = values.group_id || project?.group?.id;

    if (groupId) {
      const group = groups?.edges?.find((g) => g.node.id === groupId)?.node;
      change('group_about', group?.about);
    }
  }, [values?.group_id, projects, groups, change]);

  const isViewerAdmin = viewer.admin;
  const workType = isWorkType(values.er_type);
  const hasAttachments = isAttachmentType(values.er_type);
  const isSetExpectedDuration = isSetExpectedDurationType(values.er_type);
  const { profile } = viewer;

  const showFieldsFiltered = showFields.filter((f) => {
    if (workTypeFields.includes(f)) return workType;
    if (f === 'attachments') return hasAttachments;
    if (f === 'expected_duration') return isSetExpectedDuration;
    return true;
  });

  const doShowField = (field) => {
    return showFieldsFiltered.includes(field) && !hideFields.includes(field);
  };

  const Field = useCallback(
    (props) => (
      // see https://github.com/final-form/react-final-form/issues/130 for
      // context on the parse attribute, by providing it empty string text
      // fields are explicitly included in the submit values allowing full
      // deletion of already set fields
      <FieldIf
        parse={(v) => v}
        showFields={showFieldsFiltered}
        hideFields={hideFields}
        disabled={!canEdit}
        {...props}
      />
    ),
    [showFieldsFiltered.join(','), canEdit, workType]
  );

  const erTypeOptions = useMemo(
    () =>
      Object.values(erTypes).map((v) => ({
        value: v,
        label: erTypeLabels[v],
      })),
    [erTypes]
  );

  return (
    <div className={s.formQuestions}>
      <FormSection
        title={
          isViewerExpert ? 'Basic Information' : 'Public Opportunity Fields'
        }
        subtitle={
          isViewerExpert
            ? ''
            : 'These fields will appear on the public opportunity page that our Research Managers share with expert candidates.'
        }
      >
        {viewer.admin && !config.disableChangeExpertRequestType ? (
          <Field
            TextFieldProps={{
              variant: 'outlined',
              inputProps: {
                readOnly: true,
              },
            }}
            autocomplete
            component={Select}
            disableClearable
            format={(v) => {
              if (typeof v === 'string') {
                return {
                  label: erTypeLabels[v],
                  value: v,
                };
              }
              return v;
            }}
            fullWidth
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option}
            isOptionEqualToValue={(option, value) => isEqual(option, value)}
            getOptionDisabled={({ value }) =>
              value === erTypes.proBonoConsultation
            }
            id="expertRequestType"
            label="Request Type"
            limit={null}
            name="er_type"
            openOnFocus
            options={erTypeOptions}
            parse={(v) => v.value}
          />
        ) : (
          <Field
            id="expertRequestType"
            component={TextField}
            disabled
            name="er_type"
            label="Request Type"
            variant="outlined"
            format={(v) => erTypeLabels[v]}
            fullWidth
            inputProps={{
              style: {
                textTransform: 'capitalize',
              },
            }}
          />
        )}
        {doShowField('project') && (
          <Project
            formValues={values}
            change={change}
            field={FinalField}
            disabled={!canEdit}
            TextFieldProps={{
              variant: 'outlined',
            }}
          />
        )}
        <Field
          id="expertRequestGroupAbout"
          component={TextField}
          name="group_about"
          label="Describe Your Company"
          variant="outlined"
          fullWidth
          multiline
          minRows={2}
          maxRows={50}
          inputProps={{ maxLength: 2048 }}
        />
        <Field
          id="expertRequestName"
          component={TextField}
          validate={required}
          name="name"
          label="Expert Request Title"
          variant="outlined"
          required
          fullWidth
        />
        <Field
          id="expertRequestOpportunityLocation"
          component={TextField}
          name="opportunity_location"
          label="Opportunity Location (optional)"
          placeholder="New York, NY"
          variant="outlined"
          fullWidth
        />
        <Field
          id="expertRequestJobScope"
          component={TextField}
          name="job_scope"
          label="Job Scope"
          variant="outlined"
          placeholder="Please describe the scope of this job"
          fullWidth
          multiline
          minRows={2}
          maxRows={50}
        />
        <Field
          id="expertRequestDescription"
          component={TextField}
          name="description"
          label="Ideal Expert Profile"
          placeholder="example: “Decision maker for setting up a manufacturing facility in West Africa”"
          variant="outlined"
          fullWidth
          multiline
          minRows={2}
          maxRows={50}
          inputProps={{ maxLength: 2048 }}
        />
        {doShowField('expected_duration') && (
          <FormSubSection title="Expected Duration (Optional)">
            <DurationField
              id="expertRequestExpectedDuration"
              name="expected_duration"
              label="How many hours do you expect the written review to take?"
              minHours={0}
              maxHours={isViewerAdmin ? undefined : 10}
              variant="outlined"
            />
          </FormSubSection>
        )}
        {doShowField('qualifications') && (
          <FormSubSection
            title={
              isViewerExpert
                ? 'Specific Questions'
                : 'Specific Questions for Expert(s)'
            }
            subtitle={
              isViewerExpert
                ? ''
                : "We'll use these questions to screen experts for your project."
            }
          >
            <ExpertQueries
              name="questions"
              label={canEdit && canEditQueries ? 'Add Question' : 'Question'}
              placeholder='example: "Does education software in India require government approval?"'
              addButtonLabel="Question"
              disabled={!canEdit || !canEditQueries}
              isMobileVersion={isMobileVersion}
              isViewerExpert={isViewerExpert}
              isViewerAdmin={isViewerAdmin}
            />
          </FormSubSection>
        )}
        {doShowField('questions') && (
          <FormSubSection
            title={
              isViewerExpert
                ? 'Desired Qualifications'
                : 'Desired Expert Qualifications'
            }
          >
            <ExpertQueries
              name="qualifications"
              label={
                canEdit && canEditQueries
                  ? 'Add Qualification'
                  : 'Qualification'
              }
              placeholder='example: "Have you previously been involved in a merger/acquisition?"'
              addButtonLabel="Qualification"
              disabled={!canEdit || !canEditQueries}
              isMobileVersion={isMobileVersion}
              isViewerExpert={isViewerExpert}
              isViewerAdmin={isViewerAdmin}
            />
          </FormSubSection>
        )}
        {doShowField('attachments') && (
          <FormSubSection
            title={
              isViewerExpert
                ? 'Attachments for Review'
                : 'Attachments to be Reviewed by Expert(s)'
            }
            subtitle={
              isViewerExpert
                ? ''
                : `Upload files below (File limit: ${maxAttachmentFiles}, File size limit: ${maxAttachmentSize}MB)`
            }
          >
            <Attachments
              name="attachments"
              label="Upload Files"
              addButtonLabel="Files"
              disabled={!canEdit || !canAddAttachment}
              isMobileVersion={isMobileVersion}
              maxAttachmentFiles={maxAttachmentFiles}
              maxAttachmentSize={maxAttachmentSize}
              addActionStyle={attachmentsAddStyle}
            />
          </FormSubSection>
        )}
      </FormSection>
      <FormSection
        title={isViewerExpert ? 'Additional Information' : 'Search Notes'}
        subtitle={
          isViewerExpert
            ? ''
            : 'Use the following fields to add information you would like to share with our Research Managers. It will not be shared with expert candidates on the Public Opportunity Page.'
        }
      >
        {doShowField('companies') && (
          <Companies isMobileVersion={isMobileVersion} disabled={!canEdit} />
        )}
        {doShowField('disclosure') && (
          <Disclosure formValues={values} change={change} disabled={!canEdit} />
        )}
        {doShowField('sectors') && (
          <Field
            id="expertRequestSectors"
            component={SelectSector}
            name="sectors"
            label="Sector(s) of Expertise"
            sectors={canEdit ? allSectors : values?.sectors}
            TextFieldProps={{
              variant: 'outlined',
            }}
          />
        )}
        <Field
          id="expertRequestRegions"
          multiple
          component={SelectLocation}
          name="regions"
          label="Region(s) of Expertise"
          countries={canEdit ? allCountries : values?.regions}
          TextFieldProps={{
            variant: 'outlined',
          }}
          format={(values) => {
            if (!values) {
              return;
            }
            if (canEdit) {
              return values.map((v) =>
                allCountries.find((c) => c.id.toString() === v.id)
              );
            }
            return values;
          }}
        />
        {isViewerAdmin && (
          <Field
            component={KeywordInput}
            freeSolo
            getOptionLabel={(o) => o}
            autoSelect
            variant="outlined"
            TextFieldProps={{
              variant: 'outlined',
              inputProps: { maxLength: 25 },
            }}
            options={[]}
            id="expertRequestTags"
            name="tags"
            label="Tags"
          />
        )}
        {doShowField('focusAreas') && (
          <LabelledOutline id="expertRequestFocusAreas" label="Focus Area(s)">
            <FocusAreas disabled={!canEdit} />
          </LabelledOutline>
        )}
        <Field
          id="expertRequestInstructionsResearch"
          component={TextField}
          name="instructions_research"
          label="Instructions for Researchers"
          placeholder="Add an optional note with specific recruiting instructions for our research team"
          variant="outlined"
          fullWidth
          multiline
          minRows={2}
          maxRows={50}
          inputProps={{ maxLength: 2048 }}
        />
        {isViewerAdmin && (
          <LabelledOutline
            id="timeDoneScopingCallLabel"
            label="Time Done Scoping Call"
          >
            <Field
              id="timeDoneScopingCall"
              component={DateTimePicker}
              name="time_done_scoping_call"
              placeholder="Time Done Scoping Call"
              fullWidth
              inline
              timezone={profile.timezone}
              displayWarning={false}
              maxDate={new Date()}
            />
            <Link
              newTab
              className={s.changeTimezoneLink}
              to="/settings/communication"
            >
              Change My Timezone {getTimezoneDescription(profile.timezone)}
            </Link>
          </LabelledOutline>
        )}
      </FormSection>
    </div>
  );
}

DetailsNew = connect((state) => ({
  allCountries: state.countries,
  allSectors: state.sectors.all,
  projects: state.projects.names,
  groups: state.groups.all,
  viewer: state.viewer,
}))(memo(DetailsNew));

DetailsNew.fetch = Project.fetch.concat([fetchSectors, fetchCountries]);

DetailsNew = DetailsNew;

export default DetailsNew;

import React from 'react';
import TimeButton from '../../TimeButton';
import Divider from '../../../../components/Divider/Divider';

function RejectedTimes({ viewer, rejectedTimes, expectedDuration }) {
  return (
    <div>
      <Divider />

      <div style={{ marginTop: 30, marginBottom: 30 }}>
        These times did not work:
      </div>

      {rejectedTimes
        .sort((a, b) => new Date(a) - new Date(b))
        .map((date) => (
          <TimeButton
            disabled
            key={date}
            date={date}
            timezone={viewer.timezone}
            expectedDuration={expectedDuration}
            style={{ opacity: 0.5 }}
          />
        ))}
    </div>
  );
}

export default RejectedTimes;

import React, { useMemo, memo } from 'react';
import moment from 'moment-timezone';
import ExpertPicture from '../ExpertPicture';
import s from './ProfileTitle.module.scss';

function ProfileTitle({ profile, requestLabel = 'Request Call' }) {
  const expertTimezone = useMemo(
    () =>
      profile.timezone &&
      `(GMT${moment
        .tz(profile.timezone)
        .format('Z')}) ${profile.timezone.substring(
          profile.timezone.lastIndexOf('/') + 1
        )}`,
    [profile.timezone]
  );

  return (
    <div className={s.root}>
      <div className={s.photoContainer}>
        <ExpertPicture user={profile} size={50} />
      </div>

      <div className={s.titleContainer}>
        <div className={s.title}>{profile.name}</div>
        <div className={s.subTitle}>
          <span className={s.request}>{requestLabel}</span>
          <span className={s.timezone}>{expertTimezone}</span>
        </div>
      </div>
    </div>
  );
}

ProfileTitle = ProfileTitle;

export default memo(ProfileTitle);

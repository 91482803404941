import React from 'react';
import { fetchProjects } from '../../actions/project';
import { fetchStarting } from '../../actions/consultation';
import LayoutPage from '../../components/Layout/LayoutPage';
import ExpertRequests from './ExpertRequests';
import { isGroupContext } from '../../core/user';

export default {
  path: '/expert_requests',

  action({ store }) {
    const { ui } = store.getState();

    const groupId = isGroupContext(ui.userContext) ? ui.userContext : undefined;

    const promise = Promise.all([
      store.dispatch(
        fetchProjects({
          groupId,
          openOnly: false,
          memberOnly: false,
        })
      ),
      store.dispatch(fetchStarting()),
    ]).catch((e) => {
      if (!e.isPermissionError) throw e;
    });

    return {
      title: 'Expert Requests',

      promise,

      component: (
        <LayoutPage showNav selected="expert_requests">
          <ExpertRequests />
        </LayoutPage>
      ),
    };
  },
};

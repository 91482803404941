import React from 'react';
import Landing from './Landing';
import { fetchPagesByPrefix } from '../../actions/blog';

export default {
  path: '/',
  customTLD: 'org',
  async action({ store }) {
    const page = (
      await store.dispatch(fetchPagesByPrefix('/org/landing-page'))
    ).find(Boolean);
    if (!page) return null;

    return {
      title: 'OnFrontiers.org',
      component: <Landing article={page} />,
    };
  },
};

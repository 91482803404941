import React from 'react';
import UserActivity from './UserActivity';
import NetworkAddActivity from './NetworkAddActivity';
import ConsultationRequestedActivity from './ConsultationRequestedActivity';
import ConsultationActivity from './ConsultationActivity';
import CandidateActivity from './CandidateActivity';
import CandidateAnswersActivity from './CandidateAnswersActivity';
import ExpertRequestActivity from './ExpertRequestActivity';

const config = {
  user_joined_platform: {
    icon: 'user-plus',
    renderer: (rProps) => (
      <UserActivity event="joined OnFrontiers" {...rProps} />
    ),
  },
  user_inetexpert_add: {
    icon: 'chart-network',
    renderer: NetworkAddActivity,
  },
  user_consultation_requested: {
    icon: 'phone',
    renderer: ConsultationRequestedActivity,
  },
  user_consultation_confirmed: {
    icon: 'phone',
    renderer: (rProps) => (
      <ConsultationActivity state="confirmed" {...rProps} />
    ),
  },
  user_consultation_closed: {
    icon: 'phone',
    renderer: (rProps) => (
      <ConsultationActivity
        state={rProps.activity.context.raw.closing_state}
        {...rProps}
      />
    ),
  },
  user_candidate_add: {
    icon: 'clipboard-list',
    renderer: CandidateActivity,
  },
  user_candidate_update: {
    icon: 'clipboard-list',
    renderer: CandidateActivity,
  },
  user_candidate_answers_set: {
    icon: 'clipboard-list',
    renderer: CandidateAnswersActivity,
  },
  expert_request_created: {
    icon: 'clipboard-list',
    renderer: ExpertRequestActivity,
  },
  expert_request_closed: {
    icon: 'lock',
    renderer: ExpertRequestActivity,
  },
  expert_request_open: {
    icon: 'unlock',
    renderer: ExpertRequestActivity,
  },
};

export default function activityConfig(activity) {
  return config[`${activity.object_type}_${activity.action}`];
}

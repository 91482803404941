import React from 'react';
import { connect } from 'react-redux';
import { hideMessage } from '../../actions/ui';
import Snackbar from './Snackbar';

function SnackbarNotification({ ui, notification, hideMessage }) {
  return (
    <Snackbar
      open={ui.notifications.length > 0}
      message={notification ? notification.message : ''}
      messageType={notification && notification.messageType}
      autoHideDuration={notification && notification.duration}
      onClose={() => hideMessage()}
    />
  );
}

export default connect(
  (state) => {
    const notification = state.ui.notifications[0] || state.ui.lastNotification;

    return {
      ui: state.ui,
      notification,
    };
  },
  {
    hideMessage,
  }
)(SnackbarNotification);

import queryString from 'query-string';
import { getCache, setCache } from './util';

const key = 't';

export function saveTracking() {
  const query = queryString.parse(window.location.search);
  const val = getCache(key);

  if (val && !query.ref && !query.utm_campaign) return;

  const tracking = {
    referrer: document.referrer,
    landing: window.location.href,
  };

  setCache(key, tracking);
}

export function clearTracking() {
  setCache(key, undefined);
}

export function getTracking() {
  return getCache(key);
}

import ActionTypes from '../core/ActionTypes';

export function answerQuestion(questionId, answerId) {
  return {
    type: ActionTypes.COMPLIANCE_TRAINING__ANSWER_QUESTION,
    questionId,
    answerId,
  };
}

export function completeStep(step, userId) {
  return {
    type: ActionTypes.COMPLIANCE_TRAINING__COMPLETE_STEP,
    step,
    userId,
  };
}

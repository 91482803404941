import React from 'react';
import qs from 'query-string';
import Experts from './Experts';
import SearchPromo from './SearchPromo';
import LayoutPage from '../../components/Layout/LayoutPage';
import ConsultationsStarting from '../../components/ConsultationsStarting';
import { search, setSearchResults, Query } from '../../actions/search';
import { fetchStarting } from '../../actions/consultation';
import { track } from '../../actions/tracking';
import { fetchAllGroups } from '../../actions/group';
import { isEnterpriseUser } from '../../core/group';
import { toDateBoundString } from '../../core/search';
import { fetchUser } from '../../actions/user';
import { fetchCountries } from '../../actions/country';

function toBool(value) {
  if (value === undefined || value === null) return value;
  return (
    value === '1' || value === 1 || value.toString().toLowerCase() === 'true'
  );
}

function toArray(value) {
  if (!value) return [];
  return Array.isArray(value) ? value : [value];
}

export default {
  path: '/search',

  async action({ store, location }) {
    const { viewer } = store.getState();

    if (!isEnterpriseUser(viewer)) {
      store.dispatch(track('promo.show.search'));
      return {
        title: 'Upgrade to Enterprise',
        component: (
          <LayoutPage showNav verticalCenter>
            <SearchPromo />
          </LayoutPage>
        ),
      };
    }

    const queryRaw = qs.parse(location.search, { arrayFormat: 'bracket' });

    const totalFiltersApplied = Object.keys(queryRaw).filter(
      (k) => !['q', 'query', 'ascending'].includes(k)
    ).length;

    const query = new Query({
      text: queryRaw.query || queryRaw.q,
      sort: queryRaw.sort,
      ascending: toBool(queryRaw.asc),
      mustHaveEmail: toBool(queryRaw.email),
      profileType: queryRaw.profile_type,
      expertStates: toArray(queryRaw.expert_states),
      expertHistory: toArray(queryRaw.expert_history),
      languages: toArray(queryRaw.languages),
      countries: toArray(queryRaw.countries),
      industries: toArray(queryRaw.industries),
      agentIds: toArray(queryRaw.agents),
      networkIds: toArray(queryRaw.networks),
      organizationsCurrentRestriction: queryRaw.organizations_restrict,
      locationsCurrentRestriction: queryRaw.locations_restrict,
      jobTitlesCurrentRestriction: queryRaw.job_titles_restrict,
      organizations:
        queryRaw.organizations &&
        queryRaw.organizations.map(toDateBoundString).filter(Boolean),
      locations:
        queryRaw.locations &&
        queryRaw.locations.map(toDateBoundString).filter(Boolean),
      jobTitles:
        queryRaw.job_titles &&
        queryRaw.job_titles.map(toDateBoundString).filter(Boolean),
      consultationsWith: toArray(queryRaw.consultations_with),
      educationDegrees: toArray(queryRaw.education_degrees),
      educationFos: toArray(queryRaw.education_fos),
      groupKeywordIds: toArray(queryRaw.group_keyword_ids),
      keywords: toArray(queryRaw.keywords),
      marketplacePreference: queryRaw.marketplace_preference,
    });

    try {
      await Promise.all(
        [
          setSearchResults({
            query,
          }),
          fetchStarting(),
        ].map(store.dispatch)
      );
    } catch (err) {
      if (!err.query) throw err;
    }

    [
      search(query),
      fetchCountries(),
      fetchAllGroups({ collection: 'networks', internalNetwork: true }),
      fetchUser(viewer.username, {
        groups: true,
        profileKeywordsDefinition: true,
      }),
    ].forEach(store.dispatch);

    return {
      title: 'My Network',
      disableSamePathScrollRestoration: true,
      id: 'search',
      component: (
        <LayoutPage showNav selected="my-network">
          <ConsultationsStarting />
          <Experts totalFiltersApplied={totalFiltersApplied} />
        </LayoutPage>
      ),
    };
  },
};

import React from 'react';
import Link from '../Link';
import s from './ActivityLog.module.scss';

function ConsultationRef({ consultation }) {
  if (!consultation) return <span>consultation</span>;
  return <Link to={consultation.html_url}>consultation</Link>;
}

ConsultationRef = ConsultationRef;

export default ConsultationRef;

import React from 'react';
import LayoutPage from '../../components/Layout/LayoutPage';
import ConsultationsStarting from '../../components/ConsultationsStarting';
import Settings from '../../components/Settings';
import BillingSettings from '../../components/BillingSettings';
import CreditSettings from '../../components/CreditSettings';
import Preferences from './Preferences';
import FAIcon from '../../components/Icon/FAIcon';
import Link from '../../components/Link';
import AboutPage from './AboutPage';
import Subdomain from './Subdomain';
import { fetchPrices } from '../../actions/billing';
import { fetchGroup } from '../../actions/group';
import { fetchStarting } from '../../actions/consultation';
import { fetchPermissions } from '../../actions/permission';
import { darkGreen } from '../../core/colors';
import { formatAccountType } from '../../core/billing';
import SavedSearches from './SavedSearches';
import Branding from './Branding';
import CreditPurchaseForm from '../../components/CreditPurchaseForm';

export default {
  path: '/team/:teamSlug/settings/:section?/:subsection?',

  async action({ store, params }) {
    try {
      const path = !params.section
        ? null
        : !params.subsection
        ? params.section
        : `${params.section}/${params.subsection}`;
      const { viewer } = store.getState();

      const { teamSlug } = params;

      const [group] = await Promise.all(
        [
          fetchGroup(teamSlug, {
            transactions: true,
            aboutPage: true,
            profileKeywordsDefinition: true,
            internalNetwork: true,
            domain: true,
            savedSearches: true,
          }),
          fetchStarting(),
        ].map(store.dispatch)
      );
      if (!group) return;

      await store.dispatch(fetchPrices(group.billing_account?.id));

      const [
        canViewSettings,
        canUpdateGroup,
        canUpdateHigherTierSettings,
        canUpdateAdminSettings,
      ] = await store.dispatch(
        fetchPermissions(
          viewer.id,
          [
            { service: 'group', action: 'view_settings', resource: group.id },
            { service: 'group', action: 'update', resource: group.id },
            {
              service: 'group',
              action: 'update_higher_tier_settings',
              resource: group.id,
            },
            {
              service: 'group',
              action: 'update_admin_settings',
              resource: group.id,
            },
          ].filter(Boolean)
        )
      );

      if (
        !canViewSettings.allowed ||
        (!canUpdateGroup.allowed && !group.billing_account)
      ) {
        return null;
      }
      const sections = [];

      if (group.billing_account) {
        sections.push({
          title: 'Credits',
          path: 'credits',
          component: CreditSettings,
          componentProps: {
            account: group.billing_account,
            purchasePath: `/team/${teamSlug}/settings/credits/purchase`,
            // memberNotOwner: !memberOwner,
            accountType: formatAccountType(group.account_type),
            seatCount: group.billing_account?.seat_count,
          },
        });

        sections.push({
          title: 'Billing',
          path: 'billing',
          url: true,
          component: BillingSettings,
          componentProps: {
            account: group.billing_account,
          },
        });
      }

      if (canUpdateGroup.allowed) {
        sections.push({
          title: 'Purchase Credits',
          path: 'credits/purchase',
          component: CreditPurchaseForm,
          componentProps: {
            account: group.billing_account,
            purchaseDonePath: `/team/${teamSlug}/settings/credits`,
            groupId: group.id,
          },
        });
        sections.push({
          title: 'Preferences',
          path: 'preferences',
          component: Preferences,
          componentProps: { group },
        });
      }

      if (canUpdateHigherTierSettings.allowed) {
        sections.push({
          title: 'About Page',
          path: 'about_page',
          component: AboutPage,
          componentProps: { groupId: group.id, isViewerAdmin: viewer.admin },
        });
      }

      const adminSections = [];

      if (
        group.account_type === 'enterprise' &&
        canUpdateAdminSettings.allowed
      ) {
        adminSections.push({
          title: 'Subdomain',
          path: 'subdomain',
          component: Subdomain,
          componentProps: { groupId: group.id, isViewerAdmin: viewer.admin },
        });

        adminSections.push({
          title: 'Saved Searches',
          path: 'saved_searches',
          component: SavedSearches,
          componentProps: { groupId: group.id },
        });

        adminSections.push({
          title: 'Branding',
          path: 'branding',
          component: Branding,
          componentProps: { groupId: group.id, isViewerAdmin: viewer.admin },
        });
      }

      const section = path
        ? sections.find((s) => s.path === path) ||
          adminSections.find((s) => s.path === path)
        : sections[0];
      if (!section) return;

      return {
        title: `${group.name} Settings`,
        component: (
          <LayoutPage showNav selected="team">
            <ConsultationsStarting />
            <Settings
              pathPrefix={`/team/${teamSlug}/settings`}
              subTitle={
                <Link
                  to={`/team/${teamSlug}`}
                  style={{
                    display: 'inline-block',
                    marginBottom: 25,
                    fontWeight: 500,
                  }}
                >
                  <FAIcon
                    iconSet="fal"
                    color={darkGreen}
                    icon="long-arrow-left"
                  />{' '}
                  {group.name}
                </Link>
              }
              nav={[
                { title: 'Team Settings', items: sections },
                adminSections.length > 0 && {
                  title: 'Admin Only',
                  items: adminSections,
                },
              ].filter(Boolean)}
              {...section}
            />
          </LayoutPage>
        ),
      };
    } catch (e) {
      if (e.isPermissionError) return null;
      throw e;
    }
  },
};

import React, { PureComponent } from 'react';
import LocationForm from '../../components/Forms/Profile/LocationForm';
import SaveForm from './DeprecatedSaveForm';

class Location extends PureComponent {
  render() {
    const { profileId } = this.props;

    return (
      <LocationForm
        component={SaveForm}
        profileId={profileId}
        style={{ width: 500 }}
        saveMessage="Location updated."
      />
    );
  }
}

export default Location;

import React, { useState } from 'react';
import { connect } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Button from '../../../../components/Button/Button';
import Link from '../../../../components/Link';
import Divider from '../../../../components/Divider/Divider';
import { darkBrown, teal500 } from '../../../../core/colors';
import {
  fetchConsultation,
  updateConsultation,
  ALREADY_CANCELED,
  ALREADY_CONFIRMED,
  OUTDATED_ERROR,
  Trigger,
  ALREADY_STARTED,
  DEADLINE_BEFORE_CURRENT,
  DEADLINE_WRONG_STATE,
} from '../../../../actions/consultation';
import { notify, hideMessage } from '../../../../actions/ui';
import { formatDate } from '../../../../core/util';
import { policies } from '../../../../core/agreements';
import CancelConsultation from '../../CancelConsultation';
import Reschedule from '../../Reschedule';
import Duration from '../../../../core/duration';
import RestartNegotiationDialog from './RestartNegotiationDialog';

const useButtonStyles = makeStyles(() => ({
  root: {
    paddingLeft: 12,
    paddingRight: 12,
    marginLeft: 6,
  },
}));

function StyledButton({ children, color, ...other }) {
  const classes = useButtonStyles();
  return (
    <Button {...other} classes={classes} variant="text" fontColor={color}>
      {children}
    </Button>
  );
}

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  legalNotice: {
    width: '100%',
    color: darkBrown,
    fontSize: 12,
    lineHeight: '18px',
    marginBottom: 20,
  },
  acceptButton: {
    marginRight: 15,
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
      marginBottom: 15,
    },
  },
  ctaButton: {
    marginLeft: 15,
  },
}));

function Actions({
  consultation,
  viewer,
  userTimezone,
  user,
  isViewerExpert,
  updateConsultation,
  notify,
  hideMessage,
  canConfirm,
  canCancel,
  canExtendDeadline,
  canCompleteWithAttachment,
  onFinalizeWithAttachment,
  showCompleteTraining,
  newDuration,
}) {
  const s = useStyles();

  const [openDenyDialog, setOpenDenyDialog] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openExtendDialog, setOpenExtendDialog] = useState(false);
  const [openRestartDialog, setOpenRestartDialog] = useState(false);

  const tosPol = policies['terms-of-service'];
  const privacyPol = policies.privacy;

  const { completion_adds_expert_to_network: completionAddsToNetwork } =
    consultation;

  const showDivider = canConfirm || canCancel || canExtendDeadline;

  const isNegotiating = !Duration.parse(
    consultation.expected_duration
  ).isEqualTo(Duration.parse(newDuration));

  const handleUpdate = (state, duration) => {
    const { id, proposed_times: proposedTimes } = consultation;
    const deadline =
      proposedTimes && proposedTimes.length > 0 && proposedTimes[0];

    hideMessage();

    updateConsultation({
      id,
      starts_at: deadline,
      state,
      trigger: Trigger.consultationPage,
      ...(duration && { duration }),
    }).then(
      () => {
        let message = 'Submitted successfully';
        if (state === `confirmed`) {
          message = `You accepted proposed deadline of ${formatDate(
            deadline,
            viewer.timezone
          )}.`;
        } else if (state === 'negotiating_client_time') {
          message = `Your proposed change has been submitted`;
        }
        notify(message, 'success');
      },
      (err) => {
        if (err.message === OUTDATED_ERROR) {
          notify('Cannot schedule the event for a past date.', 'error');
          fetchConsultation(consultation.id);
        } else if (err.message === ALREADY_CONFIRMED) {
          notify('Consultation already confirmed.', 'error');
          fetchConsultation(consultation.id);
        } else if (err.message === ALREADY_CANCELED) {
          notify('Consultation already canceled.', 'error');
          fetchConsultation(consultation.id);
        } else {
          notify('An error occurred when accepting the consultation.', 'error');
        }
      }
    );
  };

  const handleAccept = () => {
    const { expert } = consultation;
    const hasCompletedComplianceTraining =
      expert && !!expert.compliance_completed_at;

    if (isViewerExpert && !hasCompletedComplianceTraining) {
      return showCompleteTraining();
    }

    return handleUpdate('confirmed', null);
  };

  const handleNewDuration = () => {
    return handleUpdate('negotiating_client_time', newDuration);
  };

  const handleCancel = (values, state) => {
    hideMessage();

    if (state === 'denied') {
      setOpenDenyDialog(false);
    } else {
      setOpenCancelDialog(false);
    }

    updateConsultation({
      id: consultation.id,
      state,
      cancel_reason: values.cancel_reason || '',
      trigger: Trigger.consultationPage,
    }).catch((err) => {
      const message =
        err && err.message === ALREADY_STARTED
          ? 'Cannot cancel ongoing consultation.'
          : 'An error occurred when canceling consultation.';
      notify(message, 'error');
    });
  };

  const handleExtendDeadline = (values) => {
    if (!values || !values.dates) return;

    setOpenExtendDialog(false);

    updateConsultation({
      id: consultation.id,
      proposed_times: values.dates.filter(Boolean),
      trigger: Trigger.consultationPage,
    })
      .then(() => {
        notify(`Consultation now has a new deadline.`, 'success');
      })
      .catch((err) => {
        let { message = '' } = err;

        if (message === DEADLINE_BEFORE_CURRENT) {
          message = 'Cannot set new deadline before the current deadline';
        } else if (message === DEADLINE_WRONG_STATE) {
          message =
            'Review is no longer available to extend deadline, please refresh the page.';
        } else {
          message = 'An error occurred when rescheduling consultation.';
        }
        notify(message, 'error');
      });
  };

  return (
    <div>
      {showDivider && <Divider />}

      {canConfirm && isViewerExpert && (
        <div className={s.legalNotice}>
          By clicking “Accept Consultation,” you agree that the consultation,
          including any personal data, will be stored by OnFrontiers.
          <br />
          Such data shall be used and maintained pursuant to the terms of
          OnFrontiers’{' '}
          <Link newTab href={tosPol.url}>
            {tosPol.name}
          </Link>{' '}
          and{' '}
          <Link newTab href={privacyPol.url}>
            {privacyPol.name}
          </Link>
          .
        </div>
      )}

      {canConfirm && isViewerExpert && completionAddsToNetwork && (
        <div className={s.legalNotice}>
          You will be added to the client&apos;s network after the consultation
          is completed. Clients are able to view your contact information and
          connect with you more easily when you are part of their network. You
          may remove the connection at the any time.
        </div>
      )}

      <div className={s.actions}>
        {canConfirm && (
          <div className={s.acceptButton}>
            <StyledButton
              color={teal500}
              onClick={handleAccept}
              disabled={isNegotiating}
            >
              Accept Consultation
            </StyledButton>
          </div>
        )}
        {canConfirm && isViewerExpert && (
          <StyledButton
            color={teal500}
            onClick={handleNewDuration}
            disabled={!isNegotiating}
          >
            Suggest Alternative
          </StyledButton>
        )}
        {canConfirm && isViewerExpert && (
          <>
            <StyledButton onClick={() => setOpenDenyDialog(true)}>
              I&apos;m not available
            </StyledButton>
            <CancelConsultation
              open={openDenyDialog}
              onCancel={() => setOpenDenyDialog(false)}
              onConfirm={(values) => handleCancel(values, 'denied')}
              title="Deny Consultation?"
              confirmLabel="Yes, Deny"
              label="What is your reason for denying? (optional)"
            />
          </>
        )}

        {canExtendDeadline && (
          <>
            <StyledButton onClick={() => setOpenExtendDialog(true)}>
              Extend deadline
            </StyledButton>
            <Reschedule
              open={openExtendDialog}
              isWrittenConsultation
              user={user}
              userTimezone={userTimezone}
              duration={consultation.expected_duration}
              proposedTimes={consultation.proposed_times}
              onCancel={() => setOpenExtendDialog(false)}
              onConfirm={handleExtendDeadline}
              label="Select a new deadline"
              description=""
            />
          </>
        )}

        {canCancel && (
          <>
            <StyledButton onClick={() => setOpenCancelDialog(true)}>
              Cancel consultation
            </StyledButton>
            <CancelConsultation
              open={openCancelDialog}
              onCancel={() => setOpenCancelDialog(false)}
              onConfirm={(values) =>
                handleCancel(
                  values,
                  consultation.state === 'negotiating_client_time'
                    ? 'client_rejected'
                    : 'canceled'
                )
              }
              title="Cancel Consultation?"
            />
          </>
        )}

        {canCompleteWithAttachment && (
          <div className={s.ctaButton}>
            <StyledButton color={teal500} onClick={onFinalizeWithAttachment}>
              Complete
            </StyledButton>
          </div>
        )}

        {viewer.admin && consultation.state === 'client_rejected' && (
          <>
            <div className={s.ctaButton}>
              <StyledButton
                color={teal500}
                onClick={() => setOpenRestartDialog(true)}
              >
                Restart Negotiation
              </StyledButton>
            </div>
            <RestartNegotiationDialog
              open={openRestartDialog}
              onClose={() => setOpenRestartDialog(false)}
              consultation={consultation}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    viewer: state.viewer,
  }),
  {
    notify,
    updateConsultation,
    hideMessage,
    fetchConsultation,
  }
)(Actions);

import React, { PureComponent } from 'react';
import moment from 'moment-timezone';
import Hero from '../../components/MarketingLayout/MarketingHero';
import s from './BlogCover.module.scss';
import { safeHtml } from '../../core/util';

class BlogCover extends PureComponent {
  static defaultProps = {};

  render() {
    const { post } = this.props;

    return (
      <Hero
        dark
        vignette
        backgroundImage={post.featured_media.source_url}
        contentClassName={s.body}
        style={{ minHeight: 550 }}
      >
        <div className={s.post}>
          <div className={s.author}>
            By {post.author.name} | {moment(post.date).format('D MMMM YYYY')}
          </div>
          <a
            href={post.link}
            className={s.title}
            dangerouslySetInnerHTML={{ __html: safeHtml(post.title.rendered) }}
          />
          <a href={post.link} className={s.preview}>
            {post.excerpt_text}
          </a>
        </div>
      </Hero>
    );
  }
}

export default BlogCover;

import React from 'react';
import PropTypes from 'prop-types';

function Form({ children, ...props }, { store }) {
  const { disableSubmitOnEnter, ...other } = props;
  const { csrfToken } = store.getState().runtime;
  const isPost = (props.method || '').toLowerCase() === 'post';
  const csrfField =
    csrfToken && isPost ? (
      <input type="hidden" name="authenticity_token" value={csrfToken} />
    ) : undefined;

  const onKeyPress = disableSubmitOnEnter
    ? (e) => {
        if (
          (e.target.tagName || e.target.nodeName || '').toLowerCase() ===
          'textarea'
        ) {
          // keep textarea behavior
          return;
        }

        if ((e.charCode || e.keyCode || 0) === 13) {
          e.preventDefault();
        }
      }
    : undefined;

  return (
    <form acceptCharset="UTF-8" {...other} onKeyPress={onKeyPress}>
      {csrfField}
      {children}
    </form>
  );
}

Form.contextTypes = {
  store: PropTypes.shape({
    getState: PropTypes.func.isRequired,
  }).isRequired,
};

export default Form;

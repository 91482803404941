import React from 'react';
import { fetchGroups } from '../../actions/group';
import { fetchStarting } from '../../actions/consultation';
import TeamList from './TeamList';
import LayoutPage from '../../components/Layout/LayoutPage';
import ConsultationsStarting from '../../components/ConsultationsStarting';

export default {
  path: '/teams',

  async action({ store }) {
    const { viewer } = store.getState();
    const promise = Promise.all([
      store.dispatch(
        fetchGroups({
          force: true,
          collection: 'default',
          memberOnly: !viewer.admin,
          memberLimit: 5,
          billingAccount: true,
          stats: true,
        })
      ),
      store.dispatch(fetchStarting()),
    ]).catch((e) => {
      if (!e.isPermissionError) throw e;
    });

    return {
      title: 'Teams',

      promise,

      component: (
        <LayoutPage showNav selected="teams">
          <ConsultationsStarting />
          <TeamList />
        </LayoutPage>
      ),
    };
  },
};

import React from 'react';
import {
  fetchGroup,
  approveGroupMember,
  denyGroupMember,
} from '../../actions/group';
import { fetchStarting } from '../../actions/consultation';
import LayoutPage from '../../components/Layout/LayoutPage';
import ConsultationsStarting from '../../components/ConsultationsStarting';
import Team from './Team';
import { isGroupContext } from '../../core/user';
import { notify } from '../../actions/ui';
import history from '../../core/history';

async function action({ store, params }) {
  let { slug } = params;
  const { userContext, userContextOptions } = store.getState().ui;
  const groupContexts = userContextOptions.filter((o) =>
    isGroupContext(o.value)
  );

  let redirectSlug;
  if (!slug || slug === 'default') {
    if (isGroupContext(userContext)) {
      redirectSlug = userContext;
    } else {
      if (!groupContexts.length) return;
      redirectSlug = groupContexts[0].value;
    }
    slug = redirectSlug;
  }

  const [group] = await Promise.all([
    store.dispatch(
      fetchGroup(slug, {
        awaitingMembers: true,
        internalNetwork: true,
      })
    ),
    store.dispatch(fetchStarting()),
  ]);

  if (!group) return;

  if (redirectSlug) {
    return {
      redirect: `/team/${group.slug}`,
    };
  }

  return {
    title: `${group.name} Team`,
    component: (
      <LayoutPage showNav selected="teams">
        <ConsultationsStarting />
        <Team groupId={group.id} />
      </LayoutPage>
    ),
  };
}

export default [
  {
    path: '/team/:slug?',
    action,
  },
  {
    path: '/team/:slug/member/:memberId/:op',
    async action({ store, params }) {
      const { slug, memberId, op } = params;

      const group = await store.dispatch(
        fetchGroup(slug, {
          awaitingMembers: true,
        })
      );
      if (!group.members_awaiting || !group.members_awaiting.edges) return null;

      const member = group.members_awaiting.edges
        .map((e) => e.node)
        .find((m) => m.id === memberId);

      if (!member) {
        // get rid of URL action part
        history.replace(`/team/${slug}`);
        return action({ store, params });
      }

      switch (op) {
        case 'approve':
          await store.dispatch(approveGroupMember(member));
          store.dispatch(notify(`${member.user.name} approved`));
          break;
        case 'deny':
          await store.dispatch(denyGroupMember(member));
          store.dispatch(notify(`${member.user.name} denied`));
          break;
        default:
          return null;
      }

      return action({ store, params });
    },
  },
];

import React from 'react';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { connect } from 'react-redux';

const getStyles = makeStyles((theme) => ({
  root: {
    fontSize: 14,
    marginLeft: 10,
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    },
  },
}));

function RateAdvice({
  className,
  user: { available_self_service: availableSelfService },
}) {
  const s = getStyles();

  return (
    <div className={cx(s.root, className)}>
      <p>Rate changes will apply only to future opportunities.</p>

      {!availableSelfService && (
        <p>
          If you participate in an expert pool, changing your rate may trigger a
          re-approval by the pool curator.
        </p>
      )}
    </div>
  );
}

RateAdvice = connect((state, ownProps) => {
  const profile = state.profiles.fullProfiles[ownProps.profileId];
  const user = state.users[ownProps.userId] || {};
  return {
    profile,
    user,
  };
})(RateAdvice);

export default RateAdvice;

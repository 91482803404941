import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import Layout from '../../components/Layout';
import Body from '../../components/Body';
import MediaQuery from '../../components/MediaQuery';
import TeamAccountPromo from '../../components/TeamAccountPromo/TeamAccountPromo';
import AddMembers from './AddMembers';
import history from '../../core/history';
import {
  fetchExpertRequest,
  fetchBaseExpertRequest,
  saveExpertRequest,
} from '../../actions/expertRequest';
import {
  fetchProject,
  saveProject,
  invalidateFetchedProjectsCache,
} from '../../actions/project';
import { notify, popup } from '../../actions/ui';
import { setAddress } from '../../actions/address';
import { setCache, getCache, queryPart, clearCache } from '../../core/util';
import { SCREEN_SM } from '../../constants';
import { getDefaultValues, copyExpertRequest } from './util';
import ExpertRequestSpy from './ExpertRequestSpy';

/* eslint-disable global-require */

const baseUrl = '/request_expert';
const cacheValueKey = 'newExpertRequest';

const steps = [
  {
    path: 'type',
    component: require('./SelectType').default,
  },
  {
    path: 'account',
    component: require('./Account').default,
  },
  {
    path: 'details',
    component: require('./Details').default,
    LayoutParams: {
      showNav: true,
    },
  },
];

export default {
  path: `${baseUrl}/:stepPath?`,

  async action({ store, params, query }) {
    const { viewer } = store.getState();

    if (!viewer.groups || viewer.groups.length === 0) {
      return {
        title: 'Upgrade your account',
        component: (
          <Layout verticalCenter hideSearch showNewRequest={false}>
            <TeamAccountPromo />
          </Layout>
        ),
      };
    }

    const { stepPath } = params;

    if (!stepPath) {
      return {
        redirect: `${baseUrl}/${steps[0].path}${queryPart(query)}`,
      };
    }

    const step = steps.find((s) => s.path === stepPath);
    if (!step) return null;

    const stepNumber = steps.indexOf(step);
    const nextStepURL =
      steps[stepNumber + 1] &&
      `${baseUrl}/${steps[stepNumber + 1].path}${queryPart(query)}`;

    // skip login step if user is already authenticated
    if (stepPath === 'account' && viewer.id) {
      return { redirect: nextStepURL };
    }

    const { component } = step;
    if (component.fetch && Array.isArray(component.fetch)) {
      await Promise.all(
        component.fetch.map((f) => store.dispatch(f({ viewer })))
      );
    } else if (component.fetch) {
      await store.dispatch(component.fetch({ viewer }));
    }

    // if initial project_id is set, fetch the project so it can be preselected
    if (query.project_id) await store.dispatch(fetchProject(query.project_id));

    return {
      title: component.title,
      component: <ExpertRequestNew query={query} step={step} />,
    };
  },
};

const PRIVATE_DISCLOSURE_ERROR =
  "GraphQL Error: You don't have permission to perform this action. Permission group#private_disclosure";
const ADDRESS_TAKEN_ERROR = 'GraphQL Error: address already taken';

class ExpertRequestNew extends React.Component {
  constructor(props) {
    super(props);

    const { viewer, query } = props;
    const { project_id: initialProjectId } = query;

    this.state = {
      initialValues: getDefaultValues(viewer, initialProjectId),
      submitting: false,
    };
  }

  async componentDidMount() {
    const { viewer, query, fetchBaseExpertRequest, popup, step } = this.props;

    const { project_id: initialProjectId, copy_from: copyFrom } = query;

    if (copyFrom) {
      try {
        const copyData = await fetchBaseExpertRequest(copyFrom);
        if (copyData) {
          this.setState({
            initialValues: {
              ...copyExpertRequest(viewer, copyData),
              formInitialized: true,
            },
          });
          return;
        }
      } catch (err) {
        Promise.reject(err);
      }
    }

    const defaultValues = getDefaultValues(viewer, initialProjectId);

    const cachedValue = getCache(cacheValueKey);
    const hasCachedValue = Boolean(cachedValue);

    const initDefaultValues = () => {
      const initialValues = defaultValues;
      if (defaultValues.project_id) {
        initialValues.project_id = defaultValues.project_id;
      }
      this.setState({
        initialValues: { ...initialValues, formInitialized: true },
      });
    };

    if (hasCachedValue) {
      popup({
        buttonAlignment: 'space-between',
        title: 'A previous Expert Request was found.',
        contents: 'Do you want to recover the previous expert request?',
        buttons: [
          {
            flat: true,
            label: 'No',
            callback: () => {
              clearCache(cacheValueKey);
              initDefaultValues();
            },
          },
          {
            label: 'Yes',
            callback: () => {
              cachedValue.currentVisit += 1;
              this.setState({
                initialValues: { ...cachedValue, formInitialized: true },
              });
              if (steps.indexOf(step) === 0) {
                this.onNext();
              }
            },
          },
        ],
      });
    } else {
      initDefaultValues();
    }
  }

  handleSubmit = async (values, form, callback) => {
    if (this.state.submitting) return;

    const {
      viewer,
      projects,
      destroy,
      saveProject,
      saveExpertRequest,
      invalidateFetchedProjectsCache,
      notify,
      setAddress,
    } = this.props;

    if (!viewer.phone && values.phone) {
      try {
        setAddress(viewer.profile.id, 'phone', values.phone, true);
      } catch (err) {
        if (err.message.startsWith(ADDRESS_TAKEN_ERROR)) {
          callback({
            phone: 'Already in use, please use another',
          });
          return;
        }
        notify('Error saving phone number.', 'error');
      }
    }

    const {
      project_name: projectName,
      tracking_code: trackingCode,
      // eslint-disable-next-line camelcase
      project_add_new,
      ...expertRequestValues
    } = values;

    if (!expertRequestValues.name) {
      notify('Expert request must have a name.', 'error');
      return;
    }

    const groupId = values.group_id;
    let projectId = values.project_id;
    const projectEdges = (projects && projects.edges) || [];
    const shouldCreateProject =
      !projectId || !projectEdges.find((e) => e.node.id === projectId);

    this.setState({ submitting: true });

    try {
      if (shouldCreateProject) {
        const createdProject = await saveProject({
          name: projectName || values.name,
          tracking_code: trackingCode,
          group_id: groupId,
        });
        projectId = createdProject.id;
        form.change('project_id', projectId);
        setCache(cacheValueKey, { ...values, project_id: projectId });
      }

      const expertRequest = await saveExpertRequest({
        ...expertRequestValues,
        project_id: projectId,
      });

      await invalidateFetchedProjectsCache();

      clearCache(cacheValueKey);

      // TODO page reload for now to avoid missing permission issues
      // Restore history.push when permission loading on project creation is fixed
      window.location = `/expert_request/${expertRequest.id}/add_members`;

      // history.push(`/expert_request/${expertRequest.id}/add_members`);

      setTimeout(() => destroy(), 1000);
    } catch (err) {
      if (err.message.startsWith(PRIVATE_DISCLOSURE_ERROR)) {
        notify(
          'User is not authorized to choose private disclosure (premium).',
          'error'
        );
        return;
      }

      if (err.rawError) {
        const errors = Array.isArray(err.rawError) ? err.rawError : [err.rawError];
        errors.forEach((error) => {
          notify(
            error.message,
            'error'
          );
        });
        return;
      }

      notify('Error when creating expert request.', 'error');
      Promise.reject(err);
    } finally {
      this.setState({ submitting: false });
    }
  };

  onNext = () => {
    const { step, query } = this.props;
    const stepNumber = steps.indexOf(step);
    const nextUrl =
      steps[stepNumber + 1] &&
      `${baseUrl}/${steps[stepNumber + 1].path}${queryPart(query)}`;
    history.push(nextUrl);
  };

  renderStep = (isMobileVersion, { form, handleSubmit }) => {
    const { step } = this.props;
    const { component } = step;

    return React.createElement(component, {
      onNext: this.onNext,
      isMobileVersion,
      change: form.change,
      values: form.getState().values,
      handleSubmit,
    });
  };

  render = () => {
    const { initialValues } = this.state;
    const { step } = this.props;
    const { LayoutParams } = step;
    const stepNumber = steps.indexOf(step);

    return (
      <Layout
        {...LayoutParams}
        hideSearch
        showNewRequest={false}
        selected="expert_requests"
      >
        <Body style={{ paddingTop: 30, paddingBottom: 30 }}>
          <MediaQuery maxWidth={SCREEN_SM}>
            {(isMobileVersion) => (
              <Form
                onSubmit={this.handleSubmit}
                initialValues={initialValues}
                mutators={{
                  ...arrayMutators,
                }}
                validate={(values) =>
                  step &&
                  step.component &&
                  step.component.validate &&
                  step.component.validate(values, this.props)
                }
              >
                {(formRenderProps) => (
                  <>
                    <ExpertRequestSpy
                      cacheKey={cacheValueKey}
                      formValuesInitialized={
                        initialValues.formInitialized && stepNumber > 1
                      }
                    />
                    {this.renderStep(isMobileVersion, formRenderProps)}
                  </>
                )}
              </Form>
            )}
          </MediaQuery>
        </Body>
      </Layout>
    );
  };
}

ExpertRequestNew = connect(
  (state) => ({
    viewer: state.viewer,
    projects: state.projects.names || [],
    groups: state.groups.all || [],
  }),
  {
    saveProject,
    invalidateFetchedProjectsCache,
    saveExpertRequest,
    fetchBaseExpertRequest,
    notify,
    setAddress,
    popup,
  }
)(ExpertRequestNew);

export const addMembers = {
  path: /^\/expert_request\/([0-9]+)\/add_members$/,

  async action({ store, params: { 0: id } = {} }) {
    const expertRequest = await store.dispatch(fetchExpertRequest(id));
    if (!expertRequest) return null;

    return {
      title: 'Invite Members',
      component: (
        <Layout hideSearch>
          <Body style={{ paddingTop: 30, paddingBottom: 30 }}>
            <AddMembers
              project={expertRequest.project}
              expertRequestId={expertRequest.id}
            />
          </Body>
        </Layout>
      ),
    };
  },
};

import React from 'react';
import { connect } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import MaterialIcon from '../../components/Icon/MaterialIcon';
import Link from '../../components/Link/Link';
import Module from '../../components/Module/Module';
import { darkGreen } from '../../core/colors';

const useStyles = makeStyles(() => ({
  module: {
    padding: 15,
  },
  item: {
    display: 'flex',
    alignItems: 'flex-start',
    color: darkGreen,
    wordBreak: 'break-all',
    fontWeight: '500',
    marginBottom: 15,
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  name: {
    marginLeft: 5,
    marginTop: 2,
  },
}));

function SavedSearchesModule({ savedSearches = [] }) {
  const s = useStyles();

  return (
    <Module title="Saved Expert Searches">
      {savedSearches.map((search) => (
        <Link href={search.url} className={s.item}>
          <MaterialIcon size="21px" icon="search" />
          <span className={s.name}>{search.name}</span>
        </Link>
      ))}
    </Module>
  );
}

export default connect((state) => ({
  savedSearches: state.savedSearches,
}))(SavedSearchesModule);

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Chip } from '@mui/material';
import ChipList from '../ChipList';
import Link from '../Link';
import { highlight } from '../../core/util';

class RegionList extends PureComponent {
  render() {
    const {
      regions,
      children,
      allRegions,
      lookup,
      icon = 'globe',
      ...other
    } = this.props;

    return (
      <ChipList {...other} icon={icon}>
        {regions.map((key) => {
          let name = key;
          if (lookup) {
            const region = allRegions.find((r) => r.id === key);
            if (region) name = region.name;
          }

          return (
            <Link key={key} href={`/search?q=${encodeURIComponent(name)}`}>
              <Chip
                style={{
                  cursor: 'pointer',
                  width: 'initial',
                  label: { overflow: 'hidden', textOverflow: 'ellipsis' },
                }}
                label={
                  <span dangerouslySetInnerHTML={{ __html: highlight(name) }} />
                }
              />
            </Link>
          );
        })}
      </ChipList>
    );
  }
}

RegionList = connect((state) => ({
  allRegions: state.countries,
}))(RegionList);

export default RegionList;

import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import SettingsSection from '../../SettingsSection/SettingsSection';
import Checkbox from '../../Checkbox/Checkbox';
import { createOtpAuthConfiguration } from '../../../actions/auth';
import DisableOtpAuth from './SecurityDisableOtpAuth';
import { EnableOtpAuth, FAQ_URL } from './SecurityEnableOtpAuth';
import FaqLink from './FaqLink';

const useStyles = makeStyles({
  section: {
    margin: '0 !important',
  },
  faq: {
    paddingTop: 20,
  },
});

function Security({ onDisableOtpAuth, user }) {
  const s = useStyles();

  const [enableOtpAuthOpen, setEnableOtpAuthOpen] = useState(false);
  const [disableOtpAuthOpen, setDisableOtpAuthOpen] = useState(false);

  const { otp_auth_enabled: otpAuthEnabled } = user;

  const handleEnabled = useCallback(async () => {
    if (otpAuthEnabled) {
      setDisableOtpAuthOpen(true);
    } else {
      setEnableOtpAuthOpen(true);
    }
  }, [otpAuthEnabled]);

  const handleEnableOtpAuthClose = useCallback(() => {
    setEnableOtpAuthOpen(false);
  }, []);

  const handleDisableOtpAuthClose = useCallback(() => {
    setDisableOtpAuthOpen(false);
  }, []);

  return (
    <div>
      <SettingsSection
        className={s.section}
        box
        title="Two-factor verification"
      >
        <Checkbox
          label="Enabled"
          checked={otpAuthEnabled}
          onClick={handleEnabled}
          FormControlProps={{ margin: 'none' }}
        />
      </SettingsSection>

      <FaqLink url={FAQ_URL} className={s.faq} />

      <DisableOtpAuth
        open={disableOtpAuthOpen}
        onClose={handleDisableOtpAuthClose}
        user={user}
        onDisable={onDisableOtpAuth}
      />
      <EnableOtpAuth
        open={enableOtpAuthOpen}
        onClose={handleEnableOtpAuthClose}
        user={user}
        email={user.email.address}
      />
    </div>
  );
}

Security = connect(
  (state, ownProps) => ({
    user: state.users[ownProps.userId],
  }),
  {
    createOtpAuthConfiguration,
  }
)(Security);

export default Security;

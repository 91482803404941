import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { changeCarrier } from '../../actions/consultation';
import Dialog from '../../components/Dialog/Dialog';
import { notify } from '../../actions/ui';
import { capitalize } from '../../core/util';
import { RadioGroup } from '../../components/FormAdapters';

function ChangeCarrierDialog({
  consultation,
  changeCarrier,
  initialValues,
  notify,
  onClose,
  ...other
}) {
  const handleSubmit = useCallback(async (values) => {
    try {
      const carrier = values?.carrier;
      if (consultation && carrier) {
        await changeCarrier({ consultation, carrier });
        notify('Carrier updated.', 'success');
      }
    } catch (err) {
      notify('An error occurred when changing carrier.', 'error');
      throw err;
    }
    onClose();
  }, []);

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      subscription={{ submitting: true }}
    >
      {({ handleSubmit, submitting }) => {
        return (
          <Dialog
            {...other}
            title="Change Carrier"
            maxWidth="xs"
            onConfirm={handleSubmit}
            onClose={onClose}
            onCancel={onClose}
            disableSubmit={submitting}
            cancelLabel="Cancel"
            confirmLabel="Save"
          >
            <form onSubmit={(e) => handleSubmit(e)}>
              <Field
                component={RadioGroup}
                name="carrier"
                options={['twilio', 'zoom'].map((c) => ({
                  value: c,
                  label: `${capitalize(c)}`,
                }))}
              />
            </form>
          </Dialog>
        );
      }}
    </Form>
  );
}

ChangeCarrierDialog = connect(undefined, {
  changeCarrier,
  notify,
})(ChangeCarrierDialog);

export default ChangeCarrierDialog;

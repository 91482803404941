import React, { useMemo, useCallback } from 'react';
import { Form, Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { connect } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '../../components/Button';
import { TextField } from '../../components/FormAdapters/FormAdapters';
import { notify } from '../../actions/ui';
import { updateGroupSavedSearches } from '../../actions/group';
import FAIcon from '../../components/Icon/FAIcon';
import { red500 } from '../../core/colors';
import { interceptEnter, normalizeSpace } from '../../core/util';
import { isValidURL } from '../../reducers/utils';

function SavedSearches({
  groupId,
  group,
  internalNetwork,
  updateGroupSavedSearches,
  notify,
}) {
  const initialValues = useMemo(() => {
    return { saved_searches: group.saved_searches };
  }, [group, internalNetwork]);

  const required = useCallback((value) => (value ? undefined : 'Required'), []);

  const validUrl = useCallback(
    (url) => (!isValidURL(url) ? 'Must be a valid URL' : null),
    []
  );

  const handleSubmit = async (values) => {
    try {
      await updateGroupSavedSearches(groupId, {
        ...values,
      });
      notify('Saved searches updated.', 'success');
    } catch (err) {
      notify('Error when updating saved searches.', 'error');
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      mutators={{ ...arrayMutators }}
    >
      {({ form, submitting, pristine, handleSubmit }) => {
        const { push } = form.mutators;
        return (
          <form onSubmit={(e) => handleSubmit(e)}>
            <Button
              color="secondary"
              style={{ marginBottom: 20 }}
              onClick={() => push('saved_searches', {})}
              size="medium"
            >
              Add search
            </Button>

            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>URL</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <FieldArray name="saved_searches">
                    {({ fields }) =>
                      fields.map((name, index) => (
                        <TableRow key={name}>
                          <TableCell>
                            <Field
                              component={TextField}
                              inputProps={{ maxLength: 100 }}
                              name={`${name}.name`}
                              onKeyPress={interceptEnter}
                              validate={required}
                              parse={(v) => normalizeSpace(v)}
                              changeOnBlur={false}
                            />

                            <Field
                              component={TextField}
                              style={{ display: 'none' }}
                              name={`${name}.id`}
                            />
                          </TableCell>
                          <TableCell>
                            <Field
                              component={TextField}
                              fullWidth
                              inputProps={{ maxLength: 800 }}
                              name={`${name}.url`}
                              onKeyPress={interceptEnter}
                              validate={validUrl}
                              parse={(v) => v}
                              changeOnBlur={false}
                            />
                          </TableCell>
                          <TableCell>
                            <IconButton
                              color="inherit"
                              size="small"
                              onClick={() => fields.remove(index)}
                            >
                              <FAIcon color={red500} icon="trash" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                    }
                  </FieldArray>
                </TableBody>
              </Table>
            </TableContainer>

            <Button
              type="submit"
              disabled={submitting || pristine}
              style={{ marginTop: 40 }}
              size="medium"
            >
              Save
            </Button>
          </form>
        );
      }}
    </Form>
  );
}

SavedSearches = connect(
  (state, { groupId }) => {
    const group = state.groups.default.edges.find(
      (e) => e.node.id === groupId
    ).node;
    return {
      group,
    };
  },
  {
    updateGroupSavedSearches,
    notify,
  }
)(SavedSearches);

export default SavedSearches;

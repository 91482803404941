// Copied from https://github.com/icholy/Duration.js/blob/golang_compatible/duration.js
const Duration = function () {
  const millisecond = 1;
  const second = 1000 * millisecond;
  const minute = 60 * second;
  const hour = 60 * minute;
  const day = 24 * hour;
  const week = 7 * day;

  const microsecond = millisecond / 1000;
  const nanosecond = microsecond / 1000;

  const unitMap = {
    ns: nanosecond,
    us: microsecond,
    µs: microsecond,
    μs: microsecond,
    ms: millisecond,
    s: second,
    m: minute,
    h: hour,
    d: day,
    w: week,
  };

  var Duration = function (value) {
    if (value instanceof Duration) {
      return value;
    }
    switch (typeof value) {
      case 'number':
        if (!isFinite(value)) {
          throw new Error(`invalid duration: ${value}`);
        }
        this._milliseconds = value;
        break;
      case 'string':
        this._milliseconds = Duration.parse(value).valueOf();
        break;
      case 'undefined':
        this._milliseconds = 0;
        break;
      default:
        throw new Error(`invalid duration: ${value}`);
    }
  };

  Duration.millisecond = new Duration(millisecond);
  Duration.second = new Duration(second);
  Duration.minute = new Duration(minute);
  Duration.hour = new Duration(hour);
  Duration.day = new Duration(day);
  Duration.week = new Duration(week);

  Duration.milliseconds = function (milliseconds) {
    return new Duration(milliseconds * millisecond);
  };

  Duration.seconds = function (seconds) {
    return new Duration(seconds * second);
  };

  Duration.minutes = function (minutes) {
    return new Duration(minutes * minute);
  };

  Duration.hours = function (hours) {
    return new Duration(hours * hour);
  };

  Duration.days = function (days) {
    return new Duration(days * day);
  };

  Duration.weeks = function (weeks) {
    return new Duration(weeks * week);
  };

  Duration.prototype.nanoseconds = function () {
    return Math.floor(this._milliseconds / nanosecond);
  };

  Duration.prototype.microseconds = function () {
    return Math.floor(this._milliseconds / microsecond);
  };

  Duration.prototype.milliseconds = function () {
    return this._milliseconds;
  };

  Duration.prototype.seconds = function () {
    return Math.floor(this._milliseconds / second);
  };

  Duration.prototype.minutes = function () {
    return Math.floor(this._milliseconds / minute);
  };

  Duration.prototype.hours = function () {
    return Math.floor(this._milliseconds / hour);
  };

  Duration.prototype.days = function () {
    return Math.floor(this._milliseconds / day);
  };

  Duration.prototype.weeks = function () {
    return Math.floor(this._milliseconds / week);
  };

  Duration.prototype.toString = function () {
    let str = '';
    let milliseconds = Math.abs(this._milliseconds);
    const sign = this._milliseconds < 0 ? '-' : '';

    // no units for 0 duration
    if (milliseconds === 0) {
      return '0';
    }

    // hours
    const hours = Math.floor(milliseconds / hour);
    if (hours !== 0) {
      milliseconds -= hour * hours;
      str += `${hours.toString()}h`;
    }

    // minutes
    const minutes = Math.floor(milliseconds / minute);
    if (minutes !== 0) {
      milliseconds -= minute * minutes;
      str += `${minutes.toString()}m`;
    }

    // seconds
    const seconds = Math.floor(milliseconds / second);
    if (seconds !== 0) {
      milliseconds -= second * seconds;
      str += `${seconds.toString()}s`;
    }

    // milliseconds
    if (milliseconds !== 0) {
      str += `${milliseconds.toString()}ms`;
    }

    return sign + str;
  };

  Duration.prototype.valueOf = function () {
    return this._milliseconds;
  };

  Duration.parse = function (duration) {
    if (
      !duration ||
      duration === '0' ||
      duration === '+0' ||
      duration === '-0'
    ) {
      return new Duration(0);
    }

    const regex = /([\-\+\d\.]+)([a-zµμ]+)/g;
    let total = 0;
    let count = 0;
    const sign = duration[0] === '-' ? -1 : 1;
    let unit;
    let value;
    let match;

    while ((match = regex.exec(duration))) {
      unit = match[2];
      value = Math.abs(parseFloat(match[1]));
      count++;

      if (isNaN(value)) {
        throw new Error('invalid duration');
      }

      if (typeof unitMap[unit] === 'undefined') {
        throw new Error(`invalid unit: ${unit}`);
      }

      total += value * unitMap[unit];
    }

    if (count === 0) {
      throw new Error('invalid duration');
    }

    return new Duration(Math.floor(total) * sign);
  };

  Duration.prototype.roundTo = function (duration) {
    const ms = new Duration(duration).valueOf();
    this._milliseconds = ms * Math.round(this._milliseconds / ms);
  };

  Duration.prototype.isGreaterThan = function (duration) {
    return this.valueOf() > new Duration(duration).valueOf();
  };

  Duration.prototype.isLessThan = function (duration) {
    return this.valueOf() < new Duration(duration).valueOf();
  };

  Duration.prototype.isEqualTo = function (duration) {
    return this.valueOf() === new Duration(duration).valueOf();
  };

  Duration.prototype.after = function (date) {
    return new Date(date.valueOf() + this._milliseconds);
  };

  Duration.since = function (date) {
    return new Duration(new Date().valueOf() - date.valueOf());
  };

  Duration.until = function (date) {
    return new Duration(date.valueOf() - new Date().valueOf());
  };

  Duration.fromMicroseconds = function (us) {
    const ms = Math.floor(us / 1000);
    return new Duration(ms);
  };

  Duration.fromNanoseconds = function (ns) {
    const ms = Math.floor(ns / 1000000);
    return new Duration(ms);
  };

  Duration.between = function (a, b) {
    return new Duration(b.valueOf() - a.valueOf());
  };

  Duration.add = function (a, b) {
    return new Duration(a + b);
  };

  Duration.subtract = function (a, b) {
    return new Duration(a - b);
  };

  Duration.multiply = function (a, b) {
    return new Duration(a * b);
  };

  Duration.divide = function (a, b) {
    return a / b;
  };

  return Duration;
}.call(this);

// module definition
(function (root) {
  if (typeof define === 'function' && define.amd) {
    define([], function () {
      return Duration;
    });
  } else if (typeof module !== 'undefined') {
    module.exports = Duration;
  } else {
    root.Duration = Duration;
  }
})(this);

if (typeof module !== 'undefined') {
  module.exports = Duration;
}

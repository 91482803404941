import React, { useCallback } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Card from '../Card';
import Actions from './Actions';
import AddToExpertRequestButton from '../AddToExpertRequestButton/AddToExpertRequestButton';
import RequestConsultationButton from '../Button/RequestConsultationButton';
import SendMessageButton from '../SendMessageButton/SendMessageButton';
import Divider from '../Divider';
import { darkGray } from '../../core/colors';

const getStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('sm')]: {
      minWidth: 230,
    },
  },
  imageContainer: {
    [theme.breakpoints.down('md')]: {
      marginTop: -125,
      marginBottom: 10,
    },
  },
}));

const getPreviewStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  buttonInfo: {
    color: darkGray,
    fontSize: 12,
    textAlign: 'center',
    marginTop: 8,
  },
  overlay: {
    opacity: 0.2,
    pointerEvents: 'none',
  },
  buttonConsultation: {
    marginTop: 20,
  },
  buttonProject: {
    marginTop: 20,
    marginBottom: 10,
  },
}));

function ActionsPreview({ profile }) {
  const s = getPreviewStyles();

  const theme = useTheme();

  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const emptyClick = useCallback(() => {}, []);

  return (
    !mobile && (
      <div className={s.content}>
        <Divider spacing={0} />

        <div className={s.overlay}>
          <RequestConsultationButton
            fullWidth
            onClick={emptyClick}
            className={s.buttonConsultation}
          />

          <div className={s.buttonInfo}>
            You won&apos;t be charged until confirming
          </div>

          <AddToExpertRequestButton
            label="Add to project"
            fullWidth
            profile={profile}
            disabled
            onClick={emptyClick}
            className={s.buttonProject}
          />

          <SendMessageButton onClick={emptyClick} />
        </div>
      </div>
    )
  );
}

export default function ({ profile, user, preview, children, ...other }) {
  const s = getStyles();

  return (
    <Card
      className={s.container}
      imageContainerClassname={s.imageContainer}
      profile={profile}
      preview={preview}
      {...other}
    >
      {preview ? (
        <ActionsPreview profile={profile} />
      ) : (
        <Actions user={user} profile={profile} {...other} />
      )}
    </Card>
  );
}

import gql from 'graphql-tag';

function formatPath(path, domain, signupType) {
  const domainPart = domain ? `/${domain.subdomain}` : '';
  const signupTypePart = signupType
    ? `/${signupType === 'client' ? 'member' : signupType}`
    : '';
  return `${domainPart}${path}${signupTypePart}`;
}

function addSearchParam(urlStr, field, value) {
  if (!value) return urlStr;
  const querySign = urlStr.indexOf('?') === -1 ? '?' : '&';
  return `${urlStr}${querySign}${field}=${value}`;
}

export function formatAuthPath({ to, domain, signupType, invite, next }) {
  to = formatPath(to, domain, signupType);
  to = addSearchParam(to, 'invite', invite);
  to = addSearchParam(to, 'next', encodeURIComponent(next || ''));
  return to;
}

// At least 8 characters long
// At least 1 uppercase letter, 1 lowercase letter, 1 digit and 1 special char
// List of special chart https://owasp.org/www-community/password-special-characters
const regex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\s"'^`~!#%&,-/:;<=>@_[\]\\$.|?*+(){}])[A-Za-z\d\s"'^`~!#%&,-/:;<=>@_[\]\\$.|?*+(){}]{8,16}/;
export function isValidPassword(password) {
  if (!password) return false;
  return regex.test(password);
}

export async function createToken(client, userId, loginMeta, recaptchaToken) {
  const { inviteToken } = loginMeta || {};
  const data = await client.send(
    gql`
      mutation _(
        $userId: String
        $inviteToken: String
        $loginMeta: Json
        $recaptchaToken: String
      ) {
        createToken(
          user_id: $userId
          invite_token: $inviteToken
          login_meta: $loginMeta
          recaptcha_token: $recaptchaToken
          client_id: "node"
        ) {
          user_id
          invite_token
          login_meta
          token
        }
      }
    `,
    { userId, inviteToken, loginMeta, recaptchaToken }
  );

  return data.createToken;
}

import React from 'react';
import Layout from '../../components/Layout';
import Questions from './Questions';
import ComplianceWizard from './ComplianceWizard';
import ComplianceTrainingStep from './ComplianceTrainingStep';
import Done from './Done';
import Confirm from './Confirm';
import questions from './questions.json';
import { darkBlue } from '../../core/colors';
import { fetchConsultation } from '../../actions/consultation';

const title = 'Compliance Training';

const baseUrl = '/compliance_training';

const steps = [
  { path: 'welcome' },
  { path: 'introduction' },
  { path: 'overview' },
  { path: 'conflicts-of-interest-1' },
  { path: 'conflicts-of-interest-2' },
  {
    path: 'questions-set-1',
    component: (
      <Questions
        questions={questions.slice(0, 3)}
        subTitle="CONFLICTS OF INTEREST (QUIZ)"
      />
    ),
  },
  { path: 'current-employer-1' },
  { path: 'current-employer-2' },
  {
    path: 'questions-set-2',
    component: (
      <Questions
        questions={questions.slice(3, 5)}
        subTitle="CURRENT EMPLOYER (QUIZ)"
      />
    ),
  },
  { path: 'confidentiality' },
  {
    path: 'questions-set-3',
    component: (
      <Questions
        questions={questions.slice(5, 9)}
        subTitle="CONFIDENTIAL INFORMATION (QUIZ)"
      />
    ),
  },
  { path: 'anti-corruption-1' },
  { path: 'anti-corruption-2' },
  {
    path: 'questions-set-4',
    component: (
      <Questions
        questions={questions.slice(9, 11)}
        subTitle="ANTI-CORRUPTION (QUIZ)"
      />
    ),
  },
  { path: 'contractual-obligations' },
  { path: 'conditions-for-specific-professions' },
  { path: 'almost-done', component: <Confirm /> },
  { path: 'done' },
];

export default {
  path: [
    `${baseUrl}`,
    `${baseUrl}/:step`,
    `${baseUrl}/consultation/:consultationId?/:confirmTime?`,
    `${baseUrl}/:step/consultation/:consultationId?/:confirmTime?`,
  ],

  async action({ store, graphql, params }) {
    const step = steps.find((s) => s.path === params.step);
    const { consultationId, confirmTime } = params;
    const confirmTimeSafe = confirmTime || '';
    const stepIndex = steps.indexOf(step);
    const { viewer, expertComplianceTraining } = store.getState();
    const consultation = consultationId
      ? await store.dispatch(fetchConsultation(consultationId))
      : null;

    if (viewer.compliance_completed_at) {
      return {
        redirect: consultationId
          ? `/consultation/${consultationId}?confirm_time=${confirmTimeSafe}`
          : '/dashboard',
      };
    }

    const urlSuffix = consultationId
      ? `/consultation/${consultationId}/${confirmTimeSafe}`
      : '';

    if (!step) {
      return {
        redirect: `${baseUrl}/welcome${urlSuffix}`,
      };
    }

    // We should not redirect on the server as we don't have the user training state to check if
    // particular step has not been completed
    const notCompletedStep = steps.slice(0, stepIndex).find((s) => {
      const userSteps = expertComplianceTraining.steps[viewer.id];
      const stepNotCompleted = !userSteps || !userSteps[s.path];
      return stepNotCompleted;
    });

    if (notCompletedStep) {
      return {
        redirect: `${baseUrl}/${notCompletedStep.path}${urlSuffix}`,
      };
    }

    const hasNext = stepIndex < steps.length - 1;
    const nextPath = hasNext && steps[stepIndex + 1].path;
    const nextUrl = hasNext && `${baseUrl}/${nextPath}${urlSuffix}`;

    if (params.step === 'done') {
      await graphql.mutate('{ completeComplianceTraining { name }}');
    }

    let component;
    if (step.path === 'done') {
      component = (
        <Done consultation={consultation} confirmTime={confirmTime} />
      );
    } else if (step.component) {
      component = step.component;
    } else {
      const content = await new Promise((resolve) =>
        require.ensure(
          [],
          (require) => resolve(require(`./content/${params.step}.md`)),
          'compliance'
        )
      );
      component = <ComplianceTrainingStep html={content.html} />;
    }

    const backgroundStyle =
      step.path === 'done' ? { backgroundColor: darkBlue } : undefined;

    return {
      title,
      component: (
        <Layout style={backgroundStyle} hideSearch>
          <ComplianceWizard
            completed={stepIndex / steps.length}
            nextUrl={nextUrl}
            path={step.path}
            user={viewer}
          >
            {component}
          </ComplianceWizard>
        </Layout>
      ),
    };
  },
};

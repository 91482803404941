import React, { useEffect, useState } from 'react';
import { FormSpy } from 'react-final-form';
import { diff } from 'deep-object-diff';
import { setCache } from '../../core/util';
import { segmentTracking } from '../../core/segment';

const ExpertRequestSpy = ({
  cacheKey,
  debounce = 2000,
  formValuesInitialized = false,
  values,
}) => {
  const [saveTimeout, setSaveTimeout] = useState(null);
  const [savedValues, setSavedValues] = useState();

  const clearSaveTimeout = () => {
    clearTimeout(saveTimeout);
    setSaveTimeout(null);
  };

  useEffect(() => {
    const newValues = { ...values };
    if (formValuesInitialized && values.formInitialized && cacheKey) {
      if (!savedValues) {
        setSavedValues(newValues);
        clearSaveTimeout();
      } else {
        const { currentVisit, ...difference } = diff(savedValues, newValues);
        if (Object.keys(difference).length) {
          if (saveTimeout) {
            clearSaveTimeout();
          }
          setSaveTimeout(
            setTimeout(() => {
              setCache(cacheKey, newValues);
              segmentTracking('expert-request-client-save', {
                fields: difference,
                currentVisit: newValues.currentVisit,
              });
              setSavedValues(newValues);
              clearSaveTimeout();
            }, debounce)
          );
        }
      }
    }
  }, [formValuesInitialized, values]);

  return null;
};

export default (props) => (
  <FormSpy
    {...props}
    subscription={{ values: true }}
    component={ExpertRequestSpy}
  />
);

import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import cx from 'classnames';
import { TextField } from '../../FormAdapters';
import RemoveButton from './buttons/RemoveButton';
import AddButton from './buttons/AddButton';
import YearPicker from '../../YearPicker';
import { red500 } from '../../../core/colors';
import s from './Education.module.scss';

function Col({ className, children, ...other }) {
  return (
    <div className={cx(s.col, { [className]: !!className })} {...other}>
      <div className={s.colContainer}>{children}</div>
    </div>
  );
}

function Education({ field, onRemove, showRemove, showAdd, onAdd }) {
  return (
    <>
      <div className={s.root}>
        <div className={s.removeAction}>
          {showRemove && (
            <RemoveButton backgroundColor={red500} onClick={onRemove} />
          )}
        </div>

        <div className={s.content}>
          <div className={s.row}>
            <Col>
              <Field
                component={TextField}
                fullWidth
                name={`${field}.degree`}
                label="Degree"
              />
            </Col>

            <Col>
              <Field
                component={TextField}
                fullWidth
                name={`${field}.field_of_study`}
                label="Field of study"
              />
            </Col>
          </div>

          <div className={s.row}>
            <Col>
              <Field
                component={TextField}
                fullWidth
                name={`${field}.school`}
                label="School"
              />
            </Col>
          </div>

          <div className={s.row}>
            <Col>
              <YearPicker
                current
                offset={0}
                name={`${field}.start_date`}
                label="Start year"
              />
            </Col>

            <Col>
              <YearPicker
                current={false}
                offset={8}
                name={`${field}.end_date`}
                label="End year (or expected)"
              />
            </Col>
          </div>

          <div className={s.row}>
            <Col>
              <Field
                name={`${field}.description`}
                component={TextField}
                fullWidth
                multiline
                minRows={2}
                maxRows={10}
                label="Describe what you learned here"
              />
            </Col>
          </div>
        </div>
      </div>

      <div className={s.addAction}>
        {showAdd && <AddButton label="Education" onClick={onAdd} />}
      </div>
    </>
  );
}

Education = Education;

Education = connect((state) => ({
  allCountries: state.countries,
}))(Education);

export default Education;

/* eslint-disable class-methods-use-this */
import { detect as detectBrowser } from 'detect-browser';
import { FeatureSupport, NetworkWarnings } from './voipCarrier';

const carrierNetworkWarnings = Object.freeze({
  high_rtt: NetworkWarnings.highRtt,
  low_mos: NetworkWarnings.lowMos,
  high_jitter: NetworkWarnings.highJitter,
  high_packetloss: NetworkWarnings.highPacketLoss,
});

const defaultOptions = {
  debug: 'INFO',
  permOnClick: false,
  codecs: ['OPUS', 'PCMU'],
  enableIPV6: false,
  audioConstraints: {
    optional: [{ googAutoGainControl: false }],
  },
  dscp: true,
  enableTracking: true,
  closeProtection: false,
  maxAverageBitrate: 48000,
  dialType: 'conference',
  allowMultipleIncomingCalls: false,
};

class PlivoCarrier {
  register(events) {
    this._events = events;
  }

  _getClient() {
    if (this._plivo) return this._plivo.client;
    throw new Error('plivo not available');
  }

  // support only enabled for Chrome for now.
  _syncFeatureSupport() {
    let support;
    const browser = detectBrowser();
    if (!browser) {
      support = FeatureSupport.unknown;
    } else if (browser.name === 'chrome') {
      support = FeatureSupport.supported;
    } else {
      support = FeatureSupport.unsupported;
    }

    this._events.onFeatureSupport({
      selectInputDevice: support,
      selectOutputDevice: support,
    });
  }

  // -- METHODS

  inputDeviceOptions = () => this._getClient().audio.availableDevices('input');

  inputDeviceSelected = () => this._getClient().audio.microphoneDevices.get();

  inputDeviceSelect = (deviceId) =>
    this._getClient().audio.microphoneDevices.set(deviceId);

  outputDeviceOptions = () =>
    this._getClient().audio.availableDevices('output');

  outputDeviceSelected = () => this._getClient().audio.speakerDevices.get();

  outputDeviceSelect = (deviceId) =>
    this._getClient().audio.speakerDevices.set(deviceId);

  async connect(token, pin) {
    if (!this._plivo) {
      const Plivo = (
        await import(
          /* webpackChunkName: "plivo" */
          'plivo-browser-sdk'
        )
      ).default;

      // Only one Plivo instance is allowed
      this._plivo = new Plivo(defaultOptions);

      // https://www.plivo.com/docs/sdk/client/web/reference#events
      const client = this._getClient();
      client.on('onCallFailed', this._handleError);
      client.on('onCallAnswered', this._handleConnected);
      client.on('onCallTerminated', this._handleDisconnected);
      client.on('mediaMetrics', this._handleMetrics);
      client.on('onMediaPermission', this._handleDeviceChange);
      client.on('audioDeviceChange', this._handleDeviceChange);
      client.on('volume', this._handleVolume);
    }

    this._syncFeatureSupport();

    const client = this._getClient();

    const config = JSON.parse(atob(token));

    if (!client.isLoggedIn) {
      let onLogin;
      let onLoginFailed;
      await new Promise((resolve, reject) => {
        onLogin = () => resolve();
        onLoginFailed = (err) => reject(err);
        client.on('onLogin', onLogin);
        client.on('onLoginFailed', onLoginFailed);
        client.login(config.username, config.password);
      });
      client.removeListener('onLogin', onLogin);
      client.removeListener('onLoginFailed', onLoginFailed);
    }

    client.call(config.sip, { 'X-PH-PIN': pin });
  }

  disconnect = () => this._getClient().logout();

  mute(mute) {
    if (mute) this._getClient().mute();
    else this._getClient().unmute();
  }

  // -- EVENTS

  _handleDeviceChange = () => this._events.onDeviceChange();

  _handleConnected = (callInfo) => this._events.onConnected(callInfo.callUUID);

  _handleDisconnected = () => this._events.onDisconnected();

  _handleMetrics = (warning) => {
    const warningName = carrierNetworkWarnings[warning.type];
    if (!warningName) return;
    this._events.onWarning(warningName, warning.active);
  };

  _handleError = (cause) =>
    this._events.onError(
      new Error(`Error when connecting on conferece: ${cause}`)
    );

  _handleVolume = ({ inputVolume, outputVolume }) =>
    this._events.onVolume(inputVolume, outputVolume);
}

export default new PlivoCarrier();

import moment from 'moment';
import actionTypes from '../core/ActionTypes';
import { removeAttribute } from './utils';

const { USER__UPDATE_INFORMATION, USER__DELETE } = actionTypes;

const INITIAL_STATE = {
  $$query_meta$$: {},
};

function reduceUser(state = {}, action) {
  switch (action.type) {
    case USER__UPDATE_INFORMATION:
      return {
        ...state,
        ...action.user,
      };
    default:
      return state;
  }
}

export default function (state = INITIAL_STATE, action) {
  const userId =
    action.type === USER__UPDATE_INFORMATION
      ? action.user.id || state.currentId
      : action.userId || state.currentId;

  if (!userId) return state;

  if (action.type === USER__DELETE) {
    return removeAttribute(state, action.id);
  }

  const newUserState = reduceUser(state[userId], action);
  const newState = {
    ...state,
    [userId]: newUserState,
  };

  if (action.user && action.user.username) {
    newState[action.user.username] = newUserState;
  }

  if (action.type === USER__UPDATE_INFORMATION && action.queryKey) {
    newState.$$query_meta$$ = {
      ...newState.$$query_meta$$,
      [action.queryKey]: {
        expiresAt: moment().add(1, 'minute').toISOString(),
        result: newUserState,
      },
    };
  }

  return newState;
}

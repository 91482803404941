import actionTypes from '../core/ActionTypes';
import { add, removeAt } from './utils';

const { SEARCH, SEARCH_SELECT_PROFILE, SEARCH_CLEAR_PROFILE_SELECTION } =
  actionTypes;

const initialState = {
  queries: {},
  query: {},
  selectedProfiles: [],
  error: undefined,
};

const initialSearchState = {
  fetching: false,
  edges: [],
};

function reduceSearch(state = initialSearchState, action) {
  switch (action.type) {
    case SEARCH:
      return {
        ...state,
        edges: [...state.edges, ...(action.edges || [])],
        pageInfo: { ...state.pageInfo, ...action.pageInfo },
        fetching: action.fetching,
      };
    default:
      return state;
  }
}

export default function searches(state = initialState, action) {
  switch (action.type) {
    case SEARCH:
      const { queries } = state;
      return {
        ...state,
        error: action.error,
        query: {
          ...state.query,
          ...action.query,
        },
        queries: {
          ...queries,
          [action.hash]: reduceSearch(queries[action.hash], action),
        },
      };
    case SEARCH_SELECT_PROFILE:
      if (action.selected) {
        return {
          ...state,
          selectedProfiles: add(
            state.selectedProfiles,
            action.profile,
            (p) => p.id === action.profile.id
          ),
        };
      }

      return {
        ...state,
        selectedProfiles: removeAt(
          state.selectedProfiles,
          state.selectedProfiles.findIndex((p) => p.id === action.profile.id)
        ),
      };
    case SEARCH_CLEAR_PROFILE_SELECTION:
      return {
        ...state,
        selectedProfiles: [],
      };
    default:
      return state;
  }
}

import React from 'react';
import moment from 'moment';

import { TimeRange } from '../../../core/time';
import s from './TimelineNetworkEvent.module.scss';

function attemptRangeSeconds(start, end) {
  try {
    return new TimeRange(start, end).duration().seconds();
  } catch (e) {
    // ignore ranges where end is before start
  }
}

function TimelineNetworkEvent({
  event,
  participant,
  range,
  conferenceDuration,
  onClick,
}) {
  const events = participant.events();

  const cleared = events.find(
    (c) =>
      c.group === 'network-quality-warning-cleared' &&
      c.name === event.name &&
      new Date(c.timestamp) > new Date(event.timestamp)
  );

  const percentPerSecond = 100 / conferenceDuration;

  const secondsUntilEvent = attemptRangeSeconds(
    moment(range.start),
    moment(event.timestamp)
  );

  const eventEnd = cleared
    ? moment(cleared.timestamp)
    : participant.callEnd()
      ? moment(participant.callEnd())
      : range.end;

  const secondsUntilEnd = attemptRangeSeconds(
    moment(event.timestamp),
    eventEnd
  );

  if (!secondsUntilEvent || !secondsUntilEnd) return null;

  const style = {
    left: `${secondsUntilEvent * percentPerSecond}%`,
    width: `${secondsUntilEnd * percentPerSecond}%`,
  };

  return (
    <div
      key={event.sid}
      className={s.networkEvent}
      style={style}
      onClick={onClick}
    />
  );
}

TimelineNetworkEvent = TimelineNetworkEvent;

export default TimelineNetworkEvent;

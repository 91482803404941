import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import EditDialog from '../../../components/EditDialog';
import { black } from '../../../core/colors';
import {
  TextField,
  Checkbox,
} from '../../../components/FormAdapters/FormAdapters';

const useStyles = makeStyles(() => ({
  confirmPolishTitle: {
    color: black,
    fontSize: 20,
    marginBottom: 11,
    fontWeight: 'bold',
  },
}));

export default function PolishRequestForm({
  onSubmit,
  handleSubmit,
  reset,
  fieldValue,
  ...other
}) {
  const s = useStyles();

  return (
    <EditDialog
      saveLabel="Send"
      {...other}
      onSubmit={handleSubmit(onSubmit)}
      onReset={reset}
      // bogus attribute (force dialog position update on text change)
      fieldValue={fieldValue}
    >
      <div className={s.confirmPolishTitle}>
        Send this Expert profile out for improvement
      </div>
      <div>
        A data operator will receive a link to the Expert’s profile and this
        request so they can tailor it to this request.
      </div>
      <Field
        type="checkbox"
        component={Checkbox}
        name="priority"
        label="Priority"
        style={{ margin: '15px 0' }}
      />
      <Field
        id="note"
        component={TextField}
        fullWidth
        multiline
        name="note"
        placeholder="Add note"
        maxRows={15}
      />
    </EditDialog>
  );
}

PolishRequestForm = connect((state) => ({
  fieldValue: formValueSelector('candidatePolishRequest')(state, 'note'),
}))(PolishRequestForm);

PolishRequestForm = reduxForm({
  form: 'candidatePolishRequest',
  enableReinitialize: true,
})(PolishRequestForm);

export function validateToken(token, singleUse = true) {
  return async (dispatch, getState, { graphql }) => {
    const { validateToken } = await graphql.query(
      `query ($token: String!, $singleUse: Boolean) {
        validateToken(token: $token, single_use: $singleUse)
      }`,
      { token, singleUse }
    );
    return validateToken;
  };
}

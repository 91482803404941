import React from 'react';
import { connect } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Link from '../Link';
import Button from '../Button';
import Form from '../Form';
import { formatAuthPath } from '../../core/auth';
import { popup } from '../../actions/ui';

const useStyles = makeStyles({
  root: {
    maxWidth: 350,
    margin: '0 auto',
  },
  linkedin: {
    cursor: 'pointer',
  },
  actions: {
    margin: '30px 0 40px',
  },
  login: {
    marginRight: 40,
    minWidth: 100,
  },
});

function Auth({
  domain,
  invite,
  next = '',
  altNext = '',
  signupType,
  signinLink = '/login',
  signupLabel = 'Sign up',
  signinLabel = 'Log in',
  children,
  popup,
  ...other
}) {
  const label = signupLabel;
  const altLabel = signinLabel;
  let altTo = signinLink;

  altTo = formatAuthPath({
    to: altTo,
    domain,
    signupType,
    invite,
    next: altNext || next,
  });

  const s = useStyles();

  return (
    <div className={s.root}>
      <Form {...other}>
        {children}

        <div className={s.actions}>
          <Button type="submit" classes={{ root: s.login }}>
            {label}
          </Button>
          <Link href={altTo}>
            <Button variant="text">{altLabel} →</Button>
          </Link>
        </div>
      </Form>
    </div>
  );
}

Auth = connect(undefined, {
  popup,
})(Auth);

export default Auth;

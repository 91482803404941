import React, { PureComponent } from 'react';
import MaterialButton from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import ExpertWidgetHorizontal from '../../components/ExpertWidgetHorizontal';
import Footer from '../../components/Footer';
import Button from '../../components/Button/Button';
import MediaQuery from '../../components/MediaQuery';
import MarketingSection from '../../components/MarketingSection';
import MarketingHero from '../../components/MarketingLayout/MarketingHero';
import HowItWorks from '../../components/HowItWorks';
import Customers from '../../components/Customers';
import { postToProps } from '../../components/Blog/BlogPostPreview';
import BlogPostPreviewList from '../../components/Blog/BlogPostPreviewList';
import CaseStudyList from '../../components/CaseStudyList';
import SearchPromo from '../../components/SearchPromo';
import Link from '../../components/Link';
import Divider from '../../components/Divider';
import Body from '../../components/Body';
import { SCREEN_MD } from '../../constants';
import { white } from '../../core/colors';
import s from './Browse.module.scss';
import { Select } from '../../components/Select/Select';

function ExpertSection({ name, experts }) {
  return (
    <div className={s.expertList}>
      {experts.map((e) => (
        <ExpertWidgetHorizontal
          key={e.id}
          profile={e.profile}
          style={{ marginBottom: 30 }}
        />
      ))}
      <SearchPromo
        title={`Want to browse more ${name.toLowerCase()} experts?`}
        label="Sign Up For Free"
        href="/signup/member"
      />
    </div>
  );
}

class FeaturedExperts extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selected: props.sections[0].key,
    };
  }

  handleNavChange = (e, selected) => this.setState({ selected });

  render() {
    const { sections } = this.props;
    const { selected } = this.state;

    const section = sections.find((s) => s.key === selected);

    return (
      <MarketingSection style={{ padding: '10px 0 0' }}>
        <Nav
          sections={sections}
          value={section.key}
          onChange={this.handleNavChange}
        />
        <ExpertSection {...section} />
      </MarketingSection>
    );
  }
}

function Hero({ title, imgSrc, description }) {
  return (
    <MarketingHero vignette backgroundImage={imgSrc}>
      <div className={s.hero}>
        <h1 className={s.heroTitle}>
          Access world-class {title.toLowerCase()} experts
        </h1>

        <p className={s.heroMarketing}>
          {description} OnFrontiers allows you to schedule consultations with
          pre-vetted practitioners and consultants spanning hundreds of
          specializations around the world.
        </p>

        <div>
          <Button
            href="/signup/member"
            size="large"
            style={{ marginRight: 10, marginBottom: 15 }}
          >
            Sign up for free
          </Button>

          <Link href="/platform">
            <MaterialButton style={{ color: white }}>Learn More</MaterialButton>
          </Link>
        </div>
      </div>
    </MarketingHero>
  );
}

function Nav({ sections, value, onChange }) {
  return (
    <div className={s.nav}>
      <MediaQuery maxWidth={SCREEN_MD}>
        {(isMobile) =>
          isMobile ? (
            <Select
              name="industry"
              label="Select industry"
              value={value}
              onChange={({ target: { value } }) => onChange(value)}
              options={sections.map((section) => ({
                label: section.name,
                value: section.key,
              }))}
            />
          ) : (
            <Tabs value={value} onChange={onChange} variant="fullWidth">
              {sections.map((section) => (
                <Tab
                  key={section.key}
                  value={section.key}
                  label={section.name}
                />
              ))}
            </Tabs>
          )
        }
      </MediaQuery>
    </div>
  );
}

function Browse({
  icon,
  title,
  description,
  imgSrc,
  sections,
  blogPosts,
  caseStudies,
}) {
  return (
    <div className={s.root}>
      <Hero
        title={title}
        icon={icon}
        description={description}
        imgSrc={imgSrc}
      />

      <FeaturedExperts sections={sections} />

      <MarketingSection padding={60}>
        <CaseStudyList
          title="Case Studies"
          cases={caseStudies}
          caseTitleStyle={{ fontSize: 20 }}
        />
      </MarketingSection>

      <MarketingSection style={{ padding: '20px 0 60px' }}>
        <BlogPostPreviewList
          center
          title={`Read blog posts from our ${title.toLowerCase()} thought leaders`}
          posts={blogPosts.map(postToProps)}
        />
      </MarketingSection>

      <Body>
        <Divider spacing={0} />
      </Body>

      <HowItWorks showSignup />

      <Customers />

      <Footer />
    </div>
  );
}

export default Browse;

import React, { Component } from 'react';

const icons = {
  globe: require('./svgs/globe.svg').default,
  factory: require('./svgs/factory.svg').default,
  tag: require('./svgs/tag.svg').default,
  quote: require('./svgs/quote.svg').default,
  incognito: require('./svgs/incognito.svg').default,
  private: require('./svgs/private.svg').default,
  bookmark: require('./svgs/bookmark.svg').default,
  briefcase: require('./svgs/briefcase.svg').default,
  cogbrain: require('./svgs/cogbrain.svg').default,
  globepin: require('./svgs/globepin.svg').default,
  handshake: require('./svgs/handshake.svg').default,
  microscope: require('./svgs/microscope.svg').default,
  moneycog: require('./svgs/moneycog.svg').default,
  personexpand: require('./svgs/personexpand.svg').default,
  target: require('./svgs/target.svg').default,
  timer: require('./svgs/timer.svg').default,
  chat: require('./svgs/chat.svg').default,
  checklist: require('./svgs/checklist.svg').default,
  profilepuzzle: require('./svgs/profilepuzzle.svg').default,
  profiledisplay: require('./svgs/profiledisplay.svg').default,
  support: require('./svgs/support.svg').default,
  phone: require('./svgs/phone.svg').default,
  clock: require('./svgs/clock.svg').default,
  lightbulb: require('./svgs/lightbulb.svg').default,
  money: require('./svgs/money.svg').default,
  cogs: require('./svgs/cogs.svg').default,
  calendarcheck: require('./svgs/calendarcheck.svg').default,
  personquestion: require('./svgs/personquestion.svg').default,
  coffee: require('./svgs/coffee.svg').default,
  moneyplant: require('./svgs/moneyplant.svg').default,
  presentation: require('./svgs/presentation.svg').default,
  handsglobe: require('./svgs/handsglobe.svg').default,
  onfrontiers: require('./svgs/onfrontiers.svg').default,
};

class SvgIcon extends Component {
  render() {
    const { style, icon, fill, stroke, ...other } = this.props;

    const mergedStyles = Object.assign(
      {
        display: 'inline-block',
        fill,
        stroke,
        userSelect: 'none',
      },
      style
    );

    const Svg = icons[icon];

    return (
      <Svg
        {...other}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        style={mergedStyles}
      />
    );
  }
}

export default SvgIcon;

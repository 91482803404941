import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import Header from './Header';
import Footer from '../Footer';
import SnackbarNotification from '../Snackbar/SnackbarNotification';
import MediaQuery from '../MediaQuery';
import ReviewConsultation from '../ReviewConsultation';
import Popup from './Popup';
import { SCREEN_MD } from '../../constants';
import { hidePopup } from '../../actions/ui';
import { dismissConsultationReview } from '../../actions/consultation';
import s from './Layout.module.scss';
import Newsletter from '../Newsletter/Newsletter';
import config from '../../../config';

function updateIntercomLauncher() {
  if (window.Intercom) {
    const width = window.document.body.clientWidth;
    window.Intercom('update', { hide_default_launcher: width <= SCREEN_MD });
  }
}

class Layout extends Component {
  static contextTypes = {
    query: PropTypes.object.isRequired,
  };

  static defaultProps = {
    showFooter: true,
    showReviewConsultation: true,
    showNewRequest: true,
    showNewsletter: false,
  };

  componentDidMount() {
    window.addEventListener('resize', updateIntercomLauncher);
    updateIntercomLauncher();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', updateIntercomLauncher);
  }

  render() {
    const {
      viewer,
      showNav,
      call,
      selected,
      verticalCenter,
      children,
      ui,
      footerStyle,
      showFooter,
      showNewsletter,
      contentClassName,
      hideSearch,
      unreviewedConsultations,
      dismissedConsultationReviews,
      dismissConsultationReview,
      showReviewConsultation,
      showNewRequest,
      headerBorder,
      marketingLinks,
      style,
    } = this.props;
    const { query } = this.context;

    const shouldShowNav = showNav && viewer.id;
    const shouldShowNewRequest = showNewRequest && ui.userContext !== 'expert';

    const unreviewed = unreviewedConsultations.edges.find(
      (e) => !dismissedConsultationReviews.includes(e.node.id)
    );
    const expert = unreviewed && unreviewed.node.expert;
    const requester = unreviewed && unreviewed.node.requester;
    const isViewerExpert = expert && expert.id === viewer.id;
    const userToReview = unreviewed && (isViewerExpert ? requester : expert);

    return (
      <MediaQuery maxWidth={SCREEN_MD}>
        {(isMobileVersion) => {
          let contentPadding = 0;
          if (isMobileVersion) {
            contentPadding = 60;
            if (shouldShowNav) contentPadding += 53;
            if (call.connected) contentPadding += 53;
          }

          return (
            <div className={s.root} style={style}>
              <Header
                border={headerBorder}
                hideSearch={hideSearch}
                showNav={shouldShowNav}
                showNewRequest={shouldShowNewRequest}
                selected={selected}
                marketingLinks={marketingLinks}
              />

              <div
                className={cx(s.content, {
                  [s.verticalCenter]: verticalCenter,
                  [contentClassName]: !!contentClassName,
                })}
                style={{ paddingTop: contentPadding }}
              >
                {children}
              </div>

              {showNewsletter && <Newsletter />}

              {showFooter && <Footer style={footerStyle} />}

              {ui.popup && <Popup open {...ui.popup} />}

              {showReviewConsultation && unreviewed && (
                <ReviewConsultation
                  open
                  consultationLink={!userToReview}
                  form="globalReviewConsultation"
                  consultation={unreviewed.node}
                  user={userToReview}
                  onCancel={() =>
                    dismissConsultationReview(unreviewed.node.id, false)
                  }
                  isViewerExpert={isViewerExpert}
                />
              )}

              <SnackbarNotification />
            </div>
          );
        }}
      </MediaQuery>
    );
  }
}

Layout.propTypes = {
  user: PropTypes.object,
  showNav: PropTypes.bool,
  showFooter: PropTypes.bool,
  showNewsletter: PropTypes.bool,
  showReviewConsultation: PropTypes.bool,
  secondary: PropTypes.bool,
  selected: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  verticalCenter: PropTypes.bool,
  viewer: PropTypes.object,
  showNewRequest: PropTypes.bool,
};

export default connect(
  (state) => ({
    viewer: state.viewer,
    ui: state.ui,
    call: state.call,
    dismissedConsultationReviews: state.consultationReviews.dismissed,
    unreviewedConsultations: state.consultations.unreviewed,
  }),
  {
    hidePopup,
    dismissConsultationReview,
  }
)(Layout);

import { DOMParser, XMLSerializer } from 'xmldom';
import BlogApi from '../core/BlogApi';
import config from '../../config';
import actionTypes from '../core/ActionTypes';
import { htmlToText, capitalize } from '../core/util';

const blogBaseUrl = 'https://onfrontiers.com';

export function fetchBlogPostBySlug(slug) {
  return async (dispatch, getState) => {
    const collection = `post_${slug}`;
    const cached = getState().blog.posts[collection];
    if (cached && cached.posts.length) {
      return cached.posts[0];
    }

    const [post] = await dispatch(fetchPosts(collection, { slug, _embed: 1 }));

    if (post) {
      const [tags, categories] = await Promise.all(
        [fetchTagsById(post.tags), fetchCategoriesById(post.categories)].map(
          dispatch
        )
      );
      post.tags = tags;
      post.categories = categories;
    }

    return post;
  };
}

function fetchPages(options) {
  return async () => {
    const pages = await new BlogApi(config.blogApiUrl).listPages(options);
    return pages && pages.map(fixPage);
  };
}

export function fetchPagesByPrefix(prefix) {
  return async (dispatch) => {
    const perPage = 100;
    let page = 1;
    let pages;
    let allPages = [];
    do {
      try {
        pages = await dispatch(fetchPages({ page, per_page: perPage }));
      } catch (err) {
        if (err.code === 'rest_post_invalid_page_number') {
          pages = [];
        } else {
          throw err;
        }
      }

      if (pages) {
        allPages = allPages.concat(
          pages.filter((p) =>
            p.link.replace(blogBaseUrl, '').startsWith(prefix)
          )
        );
        page++;
      }
    } while (pages && pages.length === perPage);
    return allPages;
  };
}

export function fetchLegalPages() {
  return async (dispatch) => {
    let pages = await dispatch(fetchPagesByPrefix('/'));
    pages = pages.filter((p) => p.slug !== 'legal');
    dispatch({ type: actionTypes.LEGAL__FETCH, pages });
    return pages;
  };
}

function fixPage(page) {
  const titleText = htmlToText(page.title.rendered).toUpperCase().trim();
  page.title_text = titleText;
  if (page.content.rendered) {
    page.content.rendered = fixBlogPageLinks(page.content.rendered);
  }
  return page;
}

export function fetchPosts(collection, options) {
  return async (dispatch, getState) => {
    const cached = getState().blog.posts[collection];
    if (cached && cached.posts.length && !options.offset) {
      return cached.posts;
    }

    dispatch({ type: actionTypes.BLOG__FETCH, collection });

    const api = new BlogApi(config.blogApiUrl);
    const posts = await api.listPosts(options);

    posts.forEach(fixPost);

    dispatch({
      type: actionTypes.BLOG__BATCH_ADD,
      collection,
      posts,
      hasMorePosts: posts.length === options.per_page,
    });

    return posts;
  };
}

function fixPost(post) {
  const rawExcerpt = `${post.excerpt.rendered.slice(
    0,
    post.excerpt.rendered.indexOf('<a ')
  )}`;
  post.excerpt_raw = rawExcerpt;
  post.excerpt_text = htmlToText(
    rawExcerpt.replace(new RegExp('&hellip;', 'g'), '…')
  );
  post.title_text = htmlToText(post.title.rendered);
  if (post._embedded && post._embedded['wp:featuredmedia']) {
    post.featured_media = post._embedded['wp:featuredmedia'][0];
  }
  if (post._embedded && post._embedded.author) {
    post.author = post._embedded.author[0];
  }
  post.link = `/blog/${post.slug}`;

  if (post.content && post.content.rendered) {
    ({ content: post.content.rendered, expertIds: post.expertIds } =
      parseExperts(post.content.rendered));
  }
  return post;
}

function fetchTagsById(ids) {
  return async (dispatch) => {
    if (!ids || !ids.length) return [];
    const options = { include: ids.join(',') };
    return dispatch(fetchTags(options));
  };
}

export function fetchTags(options) {
  return async () => {
    const tags = await new BlogApi(config.blogApiUrl).tags(options);
    return tags.map(fixTag);
  };
}

function fixTag(tag) {
  tag.link = `/blog/tag/${tag.slug}/`;
  tag.name = capitalize(htmlToText(tag.name));
  return tag;
}

function fetchCategoriesById(ids) {
  return (dispatch) => {
    if (!ids || !ids.length) return [];
    const options = { include: ids.join(',') };
    return dispatch(fetchCategories(options));
  };
}

export function fetchCategories(options) {
  return async () => {
    const categories = await new BlogApi(config.blogApiUrl).categories(options);
    return categories.map(fixCategory);
  };
}

function fixCategory(category) {
  category.link = `/blog/category/${category.slug}/`;
  category.name = htmlToText(category.name);
  return category;
}

const expertLinkPattern = new RegExp(
  '^https?:\\/\\/(?:blogcdn\\.)?onfrontiers\\.com\\/' +
    '(expert\\/profile|expert|profile)\\/(.*?)\\/?$'
);
const blogcdnLinkPattern = new RegExp(
  '^https?:\\/\\/blogcdn\\.onfrontiers\\.com(\\/.*)$'
);
function parseExperts(content) {
  // many legacy posts on our blog use relative URLs to refer to experts,
  // which are rendered as full URLs by Wordpress.
  // beyond detecting experts, this function also adjusts the contents so this
  // legacy expert URLs become relative URLs once again.

  // parse content
  const doc = new DOMParser().parseFromString(content, 'text/html');
  if (!doc) return { content, expertIds: [] };

  // process link (a tags)
  const expertIds = [];
  const tags = doc.getElementsByTagName('a');
  for (let i = 0; i < tags.length; i++) {
    const link = tags[i];
    const href = link.getAttribute('href');
    const expertMatch = href && href.match(expertLinkPattern);
    if (expertMatch) {
      expertIds.push(expertMatch[2]);
      link.setAttribute('href', `/${expertMatch[1]}/${expertMatch[2]}`);
      continue;
    }
    const blogcdnMatch = href && href.match(blogcdnLinkPattern);
    if (blogcdnMatch && !blogcdnMatch[1].startsWith('/wp-content')) {
      link.setAttribute('href', blogcdnMatch[1]);
    }
  }

  // serialize new content and filter unique experts
  const serializer = new XMLSerializer();
  return {
    content: serializer.serializeToString(doc),
    expertIds: expertIds.filter(
      (value, index, self) => self.indexOf(value) === index
    ),
  };
}

function fixBlogPageLinks(content) {
  // many legacy blog pages refer to other blog pages.
  // visitors that click these links would leave onfrontiers.com and go to
  // blogcdn.onfroniters.com, which should never happen.
  // this function adjusts the contents of the page so that these links become
  // relative links, assuring the visitor remains in the right domain.

  // parse content
  const doc = new DOMParser().parseFromString(content, 'text/html');
  if (!doc) return content;

  // process links (a tags)
  const tags = doc.getElementsByTagName('a');
  for (let i = 0; i < tags.length; i++) {
    const link = tags[i];
    const href = link.getAttribute('href');
    if (!href) continue; // not a valid link

    const blogcdnMatch = href.match(blogcdnLinkPattern);
    if (!blogcdnMatch || blogcdnMatch[1].startsWith('/wp-content')) {
      // links that are not to blog cdn...
      // ... or links to direct cdn artifacts (downloads) ...
      // ... should not be treated.
      continue;
    }

    link.setAttribute('href', blogcdnMatch[1]); // link should be relative

    const contentMatch = link.textContent.trim().match(blogcdnLinkPattern);
    if (contentMatch) {
      // the content of the link also refers to a cdn url, let's fix it
      link.textContent = `https://onfrontiers.com${contentMatch[1]}`;
    }
  }

  // serialize new content
  const serializer = new XMLSerializer();
  return serializer.serializeToString(doc);
}

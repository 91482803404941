import React from 'react';
import LayoutPage from '../../components/Layout/LayoutPage';
import Login from '../../components/Login';
import { fetchSignupDomain } from '../../actions/domain';
import { marketingLinks } from '../../components/MarketingLayout/marketingLinks';
import { rewriteSubdomainUrl } from '../../core/domain';

const title = 'Log In | OnFrontiers';

function loginMeta(query) {
  if (!query) return;

  try {
    const consultationPath = '/consultation/';
    const next = query.next && decodeURIComponent(query.next);
    if (
      next &&
      (next.startsWith(consultationPath) ||
        new URL(next).pathname.startsWith(consultationPath))
    ) {
      return {
        title: 'Consultation | OnFrontiers',
      };
    }
  } catch (err) {
    // ignore
  }
}

export default {
  subdomain: '*',
  path: '/:subdomain?/login/:type?',

  async action({ store, query, path }, params, { subdomain }) {
    if (subdomain) {
      return { redirect: rewriteSubdomainUrl(path, subdomain, query) };
    }

    const domain = await store.dispatch(fetchSignupDomain(params.subdomain));
    if (params.subdomain && !domain) return null;

    const { viewer } = store.getState();

    // login from brand page should join network by default
    const defaultNext =
      params.type === 'expert' && domain
        ? `/${params.subdomain}/signup/expert`
        : '/dashboard';
    const next = query.next || defaultNext;

    if (viewer.id) {
      return { redirect: next };
    }

    return {
      title,
      component: (
        <LayoutPage
          marketingLinks={marketingLinks({
            showLogin: !domain,
            domain: params.subdomain,
          })}
          headerBorder={false}
        >
          <Login
            title={title}
            nextUrl={next}
            invite={query.invite}
            signupType={params.type}
            domain={domain}
          />
        </LayoutPage>
      ),
      meta: loginMeta(query),
    };
  },
};

import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import FilterChip from './FilterChip';

const useStyles = makeStyles({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 10,
  },
});

export default function ({
  options,
  onRemoveValue,
  getLabel = (o) => o.label || o,
  getColor = () => {},
}) {
  const classes = useStyles();

  return (
    <div className={classes.chips}>
      {options.map((o) => (
        <FilterChip
          color={getColor(o)}
          key={JSON.stringify(o)}
          onDelete={() => onRemoveValue(o)}
          label={getLabel(o)}
        />
      ))}
    </div>
  );
}

import React from 'react';
import MarketingSection from '../MarketingSection';
import s from './Customers.module.scss';
import { sand, black } from '../../core/colors';

const defaultCustomers = [
  'bose',
  'deloitte',
  'opic',
  'rockefeller',
  'dhl',
  'worldbank',
];

const logos = new Map(
  defaultCustomers.map((c) => [
    c,
    [require(`./logos/${c}.png`), require(`./logos/${c}@2x.png`)],
  ])
);

function Img({ src, ...other }) {
  let source;
  let set = [];
  if (typeof src === 'string') {
    source = src;
  } else {
    source = src[0];
    set = src.slice(1).map((s, i) => `${s} ${i + 2}x`);
  }

  return (
    <img
      alt=""
      src={source}
      srcSet={(set.length > 0 && set.join(', ')) || undefined}
      {...other}
    />
  );
}

function Customers({ ...other }) {
  return (
    <MarketingSection
      style={{ backgroundColor: sand }}
      title="Trusted by Government Contractors, NGOs, Consulting Firms, and Investors"
      titleStyle={{
        fontSize: 24,
        textAlign: 'center',
        fontWeight: 'normal',
        color: black,
      }}
      titleMargin={10}
      {...other}
    >
      <div className={s.container}>
        {defaultCustomers.map((c) => (
          <Img
            key={c}
            id={c}
            src={logos.get(c)}
            draggable="false"
            alt={c}
            className={s.img}
          />
        ))}
      </div>
    </MarketingSection>
  );
}

export default Customers;

import React from 'react';
import ColumnSection from '../../../components/ColumnSection';
import Divider from '../../../components/Divider';

export default function Notes({ notes }) {
  return (
    <div>
      <Divider spacing={30} />
      <ColumnSection title="Request Notes">{notes}</ColumnSection>
    </div>
  );
}

import React, { useMemo, useCallback, useEffect } from 'react';
import { Button as MaterialButton } from '@mui/material';

import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import setFieldData from 'final-form-set-field-data';
import ConsultationDates from '../../components/ConsultationDates';
import ExpertPicture from '../../components/ExpertPicture';
import DurationPicker from '../../components/DurationPicker';
import Dialog from '../../components/Dialog';
import Button from '../../components/Button/Button';
import minimumTimeNoticePopup from '../../components/RequestConsultation/minimumTimeNoticePopup';
import {
  getSuggestedTimes,
  validate,
  minimumTimeNotice,
} from '../../core/consultation';
import { popup } from '../../actions/ui';
import Duration from '../../core/duration';
import { TimeRange } from '../../core/time';
import s from './Reschedule.module.scss';

function Title({ user, isViewerExpert }) {
  const userName =
    isViewerExpert && user == null
      ? 'Confidential client'
      : user
        ? user.name
        : 'User not available';

  const title = (
    <div>
      {userName}
      {user && (
        <span className={s.userType}>
          &nbsp;({isViewerExpert ? 'Client' : 'Expert'})
        </span>
      )}
    </div>
  );

  return (
    <div className={s.dialogTitle}>
      <div className={s.photoContainer}>
        <ExpertPicture user={user} size={50} />
      </div>

      <div className={s.titleContainer}>
        <div className={s.title}>{title}</div>
        <div className={s.subTitle}>Rescheduling Request</div>
      </div>
    </div>
  );
}

function RescheduleDialog({
  // Props
  viewer,
  open,
  user,
  userTimezone,
  creditRate,
  isFixedRate,
  isViewerExpert,
  rejectedTimes,
  proposedTimes,
  showDuration,
  onCancel,
  timesSuggested,
  label = 'Suggested new time(s)',
  description = 'If the change is accepted, your call will be confirmed.',

  // Final Form
  form,
  values,
  submitting,
  handleSubmit,
  dirty,
  pristine,
}) {
  const handleCancel = useCallback(() => {
    form.reset();
    if (onCancel) onCancel();
  }, []);

  useEffect(() => {
    const { dates } = values;
    const duration = Duration.parse(values.duration);

    dates.forEach((date, i) => {
      let warning;
      if (date) {
        const end = moment(date).add(duration.milliseconds(), 'ms');
        const range = new TimeRange(moment(date), end);

        const alreadySuggested = proposedTimes
          .concat(rejectedTimes || [])
          .find((r) => {
            const rejectedStart = moment(r);
            const rejectedEnd = rejectedStart.add(
              duration.milliseconds(),
              'ms'
            );
            const rejectedRange = new TimeRange(rejectedStart, rejectedEnd);
            return range.overlaps(rejectedRange);
          });

        if (alreadySuggested) warning = 'This time was already suggested.';
      }

      form.mutators.setFieldData(`dates[${i}]`, { warning });
    });
  }, [values.dates]);

  return (
    <Dialog open={open} scroll="body">
      <Title user={user} isViewerExpert={isViewerExpert} />

      {description && <div className={s.message}>{description}</div>}

      <form onSubmit={handleSubmit}>
        {showDuration && (
          <Field
            component={DurationPicker}
            name="duration"
            showCredits={!isFixedRate}
            creditRate={creditRate}
          />
        )}

        <ConsultationDates
          name="dates"
          displayWarning={dirty}
          viewer={viewer}
          userName={user ? user.first_name : 'the client'}
          userTimezone={userTimezone}
          label={label}
          style={{ marginTop: 20 }}
        />

        <div className={s.actions}>
          <MaterialButton onClick={handleCancel}>Cancel</MaterialButton>

          <Button
            disabled={(pristine && !timesSuggested) || submitting}
            size="small"
            type="submit"
          >
            Request Change
          </Button>
        </div>
      </form>
    </Dialog>
  );
}

function Reschedule({
  // Props
  open,
  user,
  userTimezone,
  creditRate,
  unpaid,
  isViewerExpert,
  rejectedTimes,
  proposedTimes,
  duration,
  showDuration,
  onCancel,
  onConfirm,
  description,
  label,
  isWrittenConsultation,

  // Redux Actions
  popup,

  // Redux State
  viewer,
}) {
  const handleSubmit = useCallback((values) => {
    const dates = values.dates.filter(Boolean);

    const doReschedule = () => onConfirm(values);

    if (minimumTimeNotice(dates, viewer.timezone)) {
      doReschedule();
    } else {
      minimumTimeNoticePopup(popup, isViewerExpert, doReschedule);
    }
  });

  let newProposedTimes = null;
  const suggestTimes = proposedTimes.length === 0;

  if (isWrittenConsultation) {
    newProposedTimes = [moment().add(7, 'd').startOf('day')];
  } else {
    newProposedTimes = proposedTimes
      .map((p) => moment(p))
      .filter((p) => p.isSameOrAfter(moment()));
    if (suggestTimes) {
      newProposedTimes = getSuggestedTimes(
        viewer.timezone,
        userTimezone,
        12,
        1
      );
    }
    newProposedTimes = newProposedTimes.concat(
      Array.from({ length: 3 - newProposedTimes.length })
    );
  }

  const initialValues = useMemo(
    () => ({
      dates: newProposedTimes,
      duration: Duration.parse(duration).toString(),
    }),
    []
  );

  return (
    <Form
      // Final Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validate}
      mutators={{ setFieldData, ...arrayMutators }}
      component={RescheduleDialog}
      // Only re-render if these parts of the form state change
      subscription={{
        submitting: true,
        values: true,
        dirty: true,
        pristine: true,
      }}
      // Dialog props
      viewer={viewer}
      open={open}
      user={user}
      userTimezone={userTimezone}
      creditRate={creditRate}
      unpaid={unpaid}
      isViewerExpert={isViewerExpert}
      rejectedTimes={rejectedTimes}
      proposedTimes={newProposedTimes}
      showDuration={showDuration}
      onCancel={onCancel}
      timesSuggested={suggestTimes}
      label={label}
      description={description}
    />
  );
}

Reschedule = connect(
  (state) => ({
    viewer: state.viewer,
  }),
  {
    popup,
  }
)(Reschedule);

Reschedule = Reschedule;

export default Reschedule;

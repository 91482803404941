import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { concatDeep } from '../../core/util';

export default function ({
  FormControlProps,
  FormHelperTextProps,
  label,
  labelPlacement,
  labelClasses = {},
  labelStyles = {},
  labelCheckedClasses = {},
  InputProps,
  helperText,
  error,
  ...other
}) {
  if (other.checked) {
    labelClasses = concatDeep(labelClasses, labelCheckedClasses);
  }

  return (
    <FormControl error={error} {...FormControlProps}>
      <FormControlLabel
        label={label}
        labelPlacement={labelPlacement}
        classes={labelClasses}
        style={labelStyles}
        control={<Checkbox {...other} />}
      />
      {helperText && (
        <FormHelperText {...FormHelperTextProps}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
}

import React from 'react';
import cx from 'classnames';
import { Icon } from '@mui/material';

function MaterialIcon({ icon, size, style, className, color, ...other }) {
  const defaultStyle = {
    fontSize: size,
  };

  if (color) {
    defaultStyle.color = color;
  }

  return (
    <Icon
      className={cx('material-icons', { [className]: !!className })}
      style={{ ...defaultStyle, ...style }}
      {...other}
    >
      {icon}
    </Icon>
  );
}

export default MaterialIcon;

import React from 'react';
import ProfileUploader from './ProfileUploader';
import { fetchExpertRequests } from '../../actions/expertRequest';
import { fetchAllGroups } from '../../actions/group';

export default {
  path: '/profile-uploader',
  async action({ store }) {
    await Promise.all(
      [
        fetchExpertRequests({ state: 'open' }),
        fetchAllGroups({
          collection: 'internal',
          internal: true,
          memberOnly: false,
          memberLimit: 100,
        }),
        fetchAllGroups({ collection: 'networks', internalNetwork: true }),
      ].map(store.dispatch)
    );

    return {
      title: 'Profile Uploader',
      component: <ProfileUploader />,
    };
  },
};

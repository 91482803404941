import React, { memo, useState, useEffect, useCallback } from 'react';
import MuiDialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import makeStyles from '@mui/styles/makeStyles';
import Button from '../Button';
import DeviceSettings from './DeviceSettings';
import useAppContext from '../useAppContext';

const dialogTitleStyle = makeStyles({
  root: {
    paddingBottom: 0,
  },
});

const dialogActionStyle = makeStyles({
  root: {
    justifyContent: 'space-between',
    padding: '0 16px 16px',
  },
});

const defaultSelected = 'default';
function DeviceSettingsDialog({ open, onClose, ...rest }) {
  const { voipCarrier } = useAppContext();

  // Last configuration to restore on cancel
  const [previousInputSelected, setPreviousInputSelected] = useState();
  const [previousOutputSelected, setPreviousOutputSelected] = useState();

  // Reset configuration
  const onCancel = useCallback(() => {
    selectInput(previousInputSelected || defaultSelected);
    selectOutput(previousOutputSelected || defaultSelected);
    onClose();
  }, [previousInputSelected, previousOutputSelected]);

  const selectInput = (deviceId) => {
    voipCarrier.inputDeviceSelect(deviceId);
  };

  const selectOutput = (deviceId) => {
    voipCarrier.outputDeviceSelect(deviceId);
  };

  useEffect(() => {
    if (open) {
      setPreviousInputSelected(voipCarrier.inputDeviceSelected());
      setPreviousOutputSelected(voipCarrier.outputDeviceSelected());
    }
  }, [open]);

  return (
    <MuiDialog
      open={open}
      title="Device Settings"
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle classes={dialogTitleStyle()}>Device Settings</DialogTitle>
      <DialogContent>
        <DeviceSettings
          {...rest}
          onDone={onClose}
          onCancel={onCancel}
          selectInput={selectInput}
          selectOutput={selectOutput}
        />
      </DialogContent>
      <DialogActions classes={dialogActionStyle()}>
        <Button variant="text" onClick={onCancel}>
          Cancel
        </Button>
        <Button primary onClick={onClose}>
          Done
        </Button>
      </DialogActions>
    </MuiDialog>
  );
}

export default DeviceSettingsDialog = memo(DeviceSettingsDialog);

import React from 'react';
import config from '../../../config';

function graphQLFetcher(token, graphQLParams) {
  const headers = {
    'Content-Type': 'application/json',
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return fetch(`${config.apiUrl}/graphql`, {
    method: 'post',
    headers,
    body: JSON.stringify(graphQLParams),
  }).then((response) => response.json());
}

export default {
  path: '/graphiql',

  async action({ store }) {
    const { viewer } = store.getState();
    if (!process.env.NODE_ENV === 'development' && !viewer.admin) return;

    const { token } = store.getState().runtime;

    const GraphiQL = (
      await import(
        /* webpackChunkName: "graphiql" */
        'graphiql'
      )
    ).default;

    return {
      title: 'GraphiQL',
      component: (
        <div style={{ height: '100vh' }}>
          <GraphiQL fetcher={(params) => graphQLFetcher(token, params)} />
        </div>
      ),
    };
  },
};

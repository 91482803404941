import React from 'react';

import Link from '../../../components/Link';
import { formatSeconds } from '../../../core/time';
import s from './PlivoPopoverContent.module.scss';

function PlivoPopoverContent({ participant, range }) {
  const selectedCallDuration =
    participant.callDuration() || range.duration().seconds();

  return (
    <React.Fragment>
      <Field
        label="Duration"
        value={`${formatSeconds(
          selectedCallDuration
        )} (${participant.callTypeLabel()})`}
        style={{ marginTop: 0 }}
      />

      {participant.callFrom() && (
        <Field label="From" value={participant.callFrom()} />
      )}

      {participant.callTo() && (
        <Field label="To" value={participant.callTo()} />
      )}
    </React.Fragment>
  );
}

function Field({ label, value, children, style, to }) {
  const content = to ? (
    <Link newTab to={to}>
      {value}
    </Link>
  ) : (
    value
  );
  return (
    <p style={style}>
      {label}: {children || <b>{content}</b>}
    </p>
  );
}

PlivoPopoverContent = PlivoPopoverContent;

export default PlivoPopoverContent;

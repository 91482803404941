import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import InputLabel from '@mui/material/InputLabel';
import { borderColor, white } from '../../core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    border: `2px dashed ${borderColor}`,
    cursor: 'pointer',
    position: 'relative',
    display: 'flex',
    minHeight: 317,
    [theme.breakpoints.down('md')]: {
      minHeight: 285,
    },
  },
  content: {
    width: '100%',
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      padding: 15,
    },
  },
  floating: {
    position: 'absolute',
    top: -7,
    left: 5,
    fontSize: 12,
    background: white,
    display: 'inline',
    padding: '0 5px',
  },
}));

export default function ImageOutline({ label, children, onClick }) {
  const s = useStyles();

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div className={s.root} onClick={onClick}>
      {label && <InputLabel className={s.floating}>{label}</InputLabel>}
      <div className={s.content}>{children}</div>
    </div>
  );
}

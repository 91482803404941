import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment-timezone';
import { Field } from 'redux-form';
import { Select } from '../FormAdapters/FormAdapters';

const useStyles = makeStyles(() => ({
  paper: {
    maxHeight: 300,
  },
}));

const parseFormat = 'YYYY-MM-DD';

function dateStringToYear(d) {
  return d && moment(d).format('YYYY');
}

function yearToDateString(year, start) {
  if (!year) return;
  if (start) return moment(new Date(year, 0, 1)).format(parseFormat);
  return moment(new Date(Number(year) + 1, 0, 1, 0, 0, 0, -1)).format(
    parseFormat
  );
}

function getYears(offset) {
  const year = new Date().getFullYear();
  const start = 1900;
  const end = year + offset;
  return Array.from({ length: end - start }, (v, i) => (end - i).toString());
}

function YearPicker({ offset, start, ...other }) {
  const s = useStyles();

  return (
    <Field
      {...other}
      component={Select}
      MenuProps={{ classes: s }}
      fullWidth
      sort={false}
      format={(value) => dateStringToYear(value)}
      parse={(value) => yearToDateString(value, start)}
      options={getYears(offset).map((y) => ({ label: y, value: y }))}
    />
  );
}

export default YearPicker;

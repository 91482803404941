import React from 'react';
import Messaging from './Messaging';
import {
  fetchChannel,
  fetchChannels,
  markChannelAsRead,
  fetchUnreadCount,
  updateTextInput,
} from '../../actions/messaging';
import { fetchTemplate } from '../../actions/messageTemplate';
import { getChannelUrl, getChannelId } from '../../core/messaging';

async function loadChannel(store, channelUrl) {
  const channel = await store.dispatch(fetchChannel(channelUrl));

  if (channel && channel.raw.unreadMessageCount > 0) {
    await store.dispatch(markChannelAsRead(channelUrl));
    store.dispatch(fetchUnreadCount());
  }

  return channel;
}

async function prefillWithTemplate(
  store,
  channelUrl,
  { channel, expert, request, template }
) {
  const { viewer } = store.getState();
  if (!channel || !expert || !request || !template) return;
  const t = await store.dispatch(
    fetchTemplate(template, {
      senderId: viewer.id,
      requestId: request,
      expertId: expert,
    })
  );
  if (t) {
    store.dispatch(updateTextInput(channelUrl, t.rendered));
  }
  return t;
}

export default {
  path: '/messaging/:channelId?',

  async action({ params, store, query }) {
    const { channelId } = params;

    const channelUrl = channelId && getChannelUrl(channelId);

    // Async load and set channel text input with template's content
    const t = await prefillWithTemplate(store, channelUrl, query);

    if (channelUrl) {
      const channel = await loadChannel(store, channelUrl);
      if (!channel) {
        return {
          redirect: '/messaging',
        };
      }
    } else if (t) {
      const queryParams = query.channel ? `?channel=${query.channel}` : '';
      return { redirect: `/messaging/${channelId}${queryParams}` };
    } else {
      const channels = await store.dispatch(fetchChannels());
      const channel = channels[0];
      if (channel) {
        return {
          redirect: `/messaging/${getChannelId(channel.raw.url)}`,
        };
      }
    }

    return {
      title: 'Messaging',
      component: (
        <Messaging
          channelUrl={channelUrl}
          mobileChannelSelected={query.channel === 'true'}
        />
      ),
    };
  },
};

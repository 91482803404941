import ActionTypes from '../core/ActionTypes';
import { add, updateAt, removeAt } from './utils';

const {
  MESSAGING__CHANNELS_LOADING,
  MESSAGING__CHANNELS_LOADED,
  MESSAGING__FETCH_CHANNELS,
  MESSAGING__FETCH_UNREAD_CHANNELS,
  MESSAGING__MESSAGES_LOADING,
  MESSAGING__MESSAGES_LOADED,
  MESSAGING__FETCH_PREVIOUS_MESSAGES,
  MESSAGING__FETCH_NEW_MESSAGES,
  MESSAGING__FETCH_UNREAD_COUNT,
  MESSAGING__EVENT_UNREAD_COUNT,
  MESSAGING__FETCH_CHANNEL,
  MESSAGING__ADD_MESSAGE,
  MESSAGING__MESSAGE_SENDING,
  MESSAGING__MESSAGE_SENT,
  MESSAGING__OFFLINE,
  MESSAGING__UPDATE_TEXT_INPUT,
  MESSAGING__CHANNEL_CREATING,
  MESSAGING__CHANNEL_CREATED,
  MESSAGING__CHANNEL_FETCHING,
  MESSAGING__CHANNEL_FETCHED,
  MESSAGING__DELETE_CHANNEL,
} = ActionTypes;

const messagesInitialState = {
  loading: false,
  messages: [],
  messageIndex: {},
  query: null,
  textInput: '',
};

const initialState = {
  offline: false,
  sendingMessage: false,
  creatingChannel: false,
  fetchingChannel: false,
  loadingChannels: false,
  loadedChannels: false,
  channels: [],
  channelIndex: {},
  query: null,
  messages: {},
  unreadCount: 0,
};

function messagesReducer(state = messagesInitialState, action) {
  switch (action.type) {
    case MESSAGING__MESSAGES_LOADING:
      return { ...state, loading: true };
    case MESSAGING__MESSAGES_LOADED:
      return { ...state, loading: false };
    case MESSAGING__UPDATE_TEXT_INPUT:
      return { ...state, textInput: action.textInput };
    case MESSAGING__FETCH_PREVIOUS_MESSAGES:
      // prevent message duplication
      const previousMessages = action.messages.filter(
        (m) => !state.messageIndex[m.messageId]
      );
      const previousMessageIndex = {};
      previousMessages.forEach(
        (m) => (previousMessageIndex[m.messageId] = true)
      );
      return {
        ...state,
        messages: [...previousMessages, ...state.messages],
        messageIndex: { ...state.messageIndex, ...previousMessageIndex },
        query: action.query,
      };
    case MESSAGING__FETCH_NEW_MESSAGES:
      // prevent message duplication
      const newMessages = action.messages.filter(
        (m) => !state.messageIndex[m.messageId]
      );
      const newMessageIndex = {};
      newMessages.forEach((m) => (newMessageIndex[m.messageId] = true));
      return {
        ...state,
        messages: [...state.messages, ...newMessages].sort(
          (m1, m2) => m1.createdAt - m2.createdAt
        ),
        messageIndex: { ...state.messageIndex, ...newMessageIndex },
      };
    case MESSAGING__ADD_MESSAGE:
      // prevent message duplication
      if (state.messageIndex[action.message.messageId]) return state;
      return {
        ...state,
        messages: [...state.messages, action.message],
        messageIndex: {
          ...state.messageIndex,
          [action.message.messageId]: true,
        },
      };
    default:
      return state;
  }
}

function mergeChannels(action, state) {
  // prevent channel duplication
  const newChannels = action.channels;
  const newChannelIndex = {};
  newChannels.forEach((c) => (newChannelIndex[c.raw.url] = c));

  const oldChannelIndex = state.channelIndex;
  const oldChannels = state.channels.filter((c) => !newChannelIndex[c.raw.url]);

  return {
    channels: [...oldChannels, ...newChannels],
    channelIndex: { ...oldChannelIndex, ...newChannelIndex },
  };
}

export default function (state = initialState, action) {
  switch (action.type) {
    case MESSAGING__MESSAGES_LOADING:
    case MESSAGING__MESSAGES_LOADED:
    case MESSAGING__UPDATE_TEXT_INPUT:
    case MESSAGING__FETCH_PREVIOUS_MESSAGES:
    case MESSAGING__FETCH_NEW_MESSAGES:
      return (() => {
        const { channelUrl } = action;
        const index = state.channels.findIndex((c) => c.raw.url === channelUrl);
        const channel = index !== -1 ? state.channels[index] : null;
        const { messages } = state.messages[channelUrl] || {};
        if (channel && messages?.length > 0) {
          channel.raw.lastMessage = messages[messages.length - 1];
        }
        return {
          ...state,
          messages: {
            ...state.messages,
            [channelUrl]: messagesReducer(state.messages[channelUrl], action),
          },
        };
      })();
    case MESSAGING__ADD_MESSAGE:
      return (() => {
        const { channelUrl } = action;
        const index = state.channels.findIndex((c) => c.raw.url === channelUrl);
        const newChannel = index !== -1 && { ...state.channels[index] };
        const channels =
          action.keepChannelPosition || !newChannel
            ? state.channels
            : [
                newChannel,
                ...state.channels.slice(0, index),
                ...state.channels.slice(index + 1, state.channels.length),
              ];
        return {
          ...state,
          channels,
          messages: {
            ...state.messages,
            [channelUrl]: messagesReducer(state.messages[channelUrl], action),
          },
        };
      })();
    case MESSAGING__FETCH_UNREAD_COUNT:
    case MESSAGING__EVENT_UNREAD_COUNT:
      return { ...state, unreadCount: action.unreadCount };
    case MESSAGING__CHANNEL_CREATING:
      return { ...state, creatingChannel: true };
    case MESSAGING__CHANNEL_CREATED:
      return { ...state, creatingChannel: false };
    case MESSAGING__CHANNEL_FETCHING:
      return { ...state, fetchingChannel: true };
    case MESSAGING__CHANNEL_FETCHED:
      return { ...state, fetchingChannel: false };
    case MESSAGING__MESSAGE_SENDING:
      return { ...state, sendingMessage: true };
    case MESSAGING__MESSAGE_SENT:
      return { ...state, sendingMessage: false };
    case MESSAGING__CHANNELS_LOADING:
      return { ...state, loadingChannels: true };
    case MESSAGING__CHANNELS_LOADED:
      return { ...state, loadingChannels: false, loadedChannels: true };
    case MESSAGING__FETCH_CHANNELS:
      return {
        ...state,
        ...mergeChannels(action, state),
        query: action.query,
      };
    case MESSAGING__FETCH_UNREAD_CHANNELS:
      return {
        ...state,
        ...mergeChannels(action, state),
      };
    case MESSAGING__FETCH_CHANNEL:
      return (() => {
        const rawChannel = action.channel.raw;
        const index = state.channels.findIndex(
          (c) => c.raw.url === rawChannel.url
        );
        const channel = { ...action.channel };
        return {
          ...state,
          channels:
            index < 0
              ? add(state.channels, channel)
              : updateAt(state.channels, index, channel),
          channelIndex: {
            ...state.channelIndex,
            [channel.raw.url]: channel,
          },
        };
      })();
    case MESSAGING__DELETE_CHANNEL:
      return (() => {
        const { channelUrl } = action;
        const index = state.channels.findIndex((c) => c.raw.url === channelUrl);

        const newState = {
          ...state,
          channels: removeAt(state.channels, index),
          channelIndex: { ...state.channelIndex },
          messages: { ...state.messages },
        };

        delete newState.channelIndex[channelUrl];
        delete newState.messages[channelUrl];

        return newState;
      })();
    case MESSAGING__OFFLINE: {
      return { ...state, offline: true };
    }
    default:
      return state;
  }
}

import moment from 'moment-timezone';
import ActionTypes from '../core/ActionTypes';
import { shouldResetCollection } from '../core/util';
import { gqlParam, gqlVar } from './util';

const {
  PROFILE__SET_CSV_PREVIEW,
  PROFILE__LIST_LOADING,
  PROFILE__LIST,
  PROFILE__LIST_RESET,
  PROFILE__LIST_LOADED,
  PROFILE__DELETE,
  PROFILE__COUNT_LOADING,
  PROFILE__COUNT,
  PROFILE__UPDATE,
  PROFILE__UPDATE_EXPERIENCE,
  PROFILE__REMOVE_EXPERIENCE,
  PROFILE__UPDATE_EDUCATION,
  PROFILE__REMOVE_EDUCATION,
} = ActionTypes;

export function fetchProfile(
  id,
  {
    force = false,
    leadOnly = false,
    audit = false,
    experiences = false,
    addresses = false,
    expertise = false,
    groupKeywords = false,
    education = false,
    sources = false,
    internalNetworks = false,
    expertRequestCandidates = false,
    groupAboutPage = false,
    internalNetworkConsultations = false,
    connectionStates = ['active'],
    rawPictureUrl = false,
  } = {}
) {
  return async (dispatch, getState, { graphql }) => {
    if (!id) throw new Error('fetchProfile must be given an id');

    const queryKey = JSON.stringify({
      id,
      audit,
      experiences,
      addresses,
      expertise,
      education,
      internalNetworks,
      expertRequestCandidates,
      groupAboutPage,
      sources,
    });
    // use cache when possible - avoid unnecessary round trips
    const queryStatus = (getState().profiles.$$query_meta$$ || {})[queryKey];
    if (
      !force &&
      queryStatus &&
      queryStatus.result &&
      moment().isBefore(queryStatus.expiresAt)
    ) {
      return queryStatus.result;
    }

    const params = { id, leadOnly };

    if (internalNetworks) {
      params.connectionStates = connectionStates;
    }

    const { profile } = await graphql.query(
      `
      query fetchProfile(
        $id: String!
        $leadOnly: Boolean
        ${
          internalNetworks
            ? '$connectionStates: [NetworkExpertConnectionState]'
            : ''
        }
      ) {
        profile(id: $id, lead_only: $leadOnly) {
          id
          user { id }
          expert_state
          conflicted
          first_name
          last_name
          name
          html_url
          url_endpoint
          linkedin_username
          linkedin_url
          title
          summary
          skype
          timezone
          city
          country
          picture_url
          cover_url
          questions
          cv_url
          available_long_term
          available_marketplace
          hide_profile
          bill_rate
          credit_rate
          languages { code, fluency }
          keywords
          tier

          ${rawPictureUrl ? 'raw_picture_url' : ''}

          ${
            expertise
              ? `
          sectors { id, name }
          regions { id, name, country_iso2_code }
          `
              : ''
          }

          ${
            groupKeywords
              ? `
          group_keywords { 
            group { id, name }
            keywords { id, name }
          }`
              : ''
          }

          ${
            addresses
              ? `
          emails {
            address
            display
            primary
            verified
            accepted
            confirmed
          }
          phones {
            address
            display
            primary
            verified
            accepted
          }
          `
              : ''
          }

          ${
            experiences
              ? `
          experiences {
            id
            title
            start_date
            end_date
            description
            organization
            linkedin_url
            location
            current
            role
          }
          `
              : ''
          }

          ${
            education
              ? `
          education {
            id
            degree
            field_of_study
            school
            start_date
            end_date
            description
          }
          `
              : ''
          }

          ${
            expertRequestCandidates
              ? `
          expert_request_id
          expert_request_candidates {
            id
            request_id
            state
          }
          `
              : ''
          }

          ${
            internalNetworks
              ? `
          expert_internal_networks(
            connection_states: $connectionStates
          ) {
            id
            network {
              id
              name
              group {
                city
                country
                ${
                  groupAboutPage
                    ? `
                about_page {
                  html_url
                }`
                    : ''
                }
                domain {
                  agreements(active_only: true) {
                    policy_code
                    policy_label
                    policy_url
                  }
                }
              }
            }
            add_method
            participation_level
            connection_state
            from_group { id name }
            ${
              internalNetworkConsultations
                ? `
            consultations {
              id
              html_url
              requester {
                name
              }
            }
            `
                : ''
            }
          }
          `
              : ''
          }

          ${
            sources
              ? `
          sources {
            agent_id
            agent_profile_id
            source_id
            source_profile_id
            created_by { name, html_url }
            created_at
            network_id
          }
          `
              : ''
          }

          ${
            audit
              ? `
          created_at
          created_by { id, name, html_url }
          updated_at
          updated_by { id, name, html_url }
          `
              : ''
          }
        }
      }
    `,
      params
    );

    if (profile) {
      dispatch({ type: PROFILE__UPDATE, profile, queryKey });
      return profile;
    }
  };
}

export function fetchConflicts(cursor, pageSize = 20) {
  return async (dispatch, getState, { graphql }) => {
    const collection = 'conflicts';
    const reset = !cursor;
    const conflicts = getState().profiles.collections[collection];
    if (reset && !shouldResetCollection(conflicts, pageSize)) return conflicts;

    dispatch({ type: PROFILE__LIST_LOADING, collection });
    try {
      const { profiles: page } = await graphql.query(
        `
        query fetchProfiles(
          $cursor: String
          $pageSize: Int!
        ) {
          profiles(
            after: $cursor
            conflicts: true
            first: $pageSize
          ) {
            pageInfo { hasNextPage }
            edges {
              cursor
              node {
                id
                user {
                  id
                }
                sources(only_last: true) {
                  agent_id
                  agent_profile_id
                  source_id
                  source_profile_id
                  created_at
                  created_by { name }
                }
                created_at
                name
                html_url
                url_endpoint
                expert_request_id
                conflicting {
                  conflicting_ids
                  profile {
                    id
                    user {
                      id
                    }
                    sources(only_last: true) {
                      agent_id
                      agent_profile_id
                      source_id
                      source_profile_id
                      created_at
                      created_by { name }
                    }
                    created_at
                    name
                    html_url
                    url_endpoint
                  }
                }
              }
            }
          }
        }
      `,
        { cursor, pageSize }
      );
      dispatch({ type: PROFILE__LIST, collection, ...page, reset });
    } finally {
      dispatch({ type: PROFILE__LIST_LOADED, collection });
    }
  };
}

export function fetchConflictsCount() {
  return async (dispatch, getState, { graphql }) => {
    const count = 'conflicts';
    const cache = getState().profiles.counts[count];
    if (cache && cache.value && moment().isBefore(cache.expire)) {
      return cache.value;
    }

    dispatch({ type: PROFILE__COUNT_LOADING, count: 'conflicts' });
    try {
      const { profilesCount: conflicts } = await graphql.query(`
        query fetchProfilesCount {
          profilesCount (
            conflicts: true
          )
        }
      `);
      dispatch({ type: PROFILE__COUNT, count, value: conflicts });
    } catch (e) {
      dispatch({ type: PROFILE__COUNT, count });
      throw e;
    }
  };
}

export function updateProfile(profile) {
  return async (dispatch, getState, { graphql }) => {
    const data = await graphql.mutate(
      `
      (
        $id: String!
        ${gqlVar('first_name', 'String', profile)}
        ${gqlVar('last_name', 'String', profile)}
        ${gqlVar('linkedin_username', 'String', profile)}
        ${gqlVar('linkedin_url', 'String', profile)}
        ${gqlVar('title', 'String', profile)}
        ${gqlVar('summary', 'String', profile)}
        ${gqlVar('skype', 'String', profile)}
        ${gqlVar('timezone', 'String', profile)}
        ${gqlVar('city', 'String', profile)}
        ${gqlVar('country', 'String', profile)}
        ${gqlVar('picture_url', 'String', profile)}
        ${gqlVar('cover_url', 'String', profile)}
        ${gqlVar('keywords', '[String]', profile)}
        ${gqlVar('keywords_to_add', '[String]', profile)}
        ${gqlVar('sector_ids', '[String]', profile)}
        ${gqlVar('region_ids', '[String]', profile)}
        ${gqlVar('languages', '[LanguageInput]', profile)}
        ${gqlVar('questions', '[String]', profile)}
        ${gqlVar('cv_url', 'String', profile)}
        ${gqlVar('bill_rate', 'Int', profile)}
        ${gqlVar('available_long_term', 'Boolean', profile)}
        ${gqlVar('available_marketplace', 'Boolean', profile)}
        ${gqlVar('hide_profile', 'Boolean', profile)}
        ${gqlVar('tier', 'Int', profile)}
        ${gqlVar('additional_information', 'String', profile)}
      ) {
        updateProfile (
          id: $id
          ${gqlParam('first_name', profile)}
          ${gqlParam('last_name', profile)}
          ${gqlParam('linkedin_username', profile)}
          ${gqlParam('linkedin_url', profile)}
          ${gqlParam('title', profile)}
          ${gqlParam('summary', profile)}
          ${gqlParam('skype', profile)}
          ${gqlParam('timezone', profile)}
          ${gqlParam('city', profile)}
          ${gqlParam('country', profile)}
          ${gqlParam('picture_url', profile)}
          ${gqlParam('cover_url', profile)}
          ${gqlParam('keywords', profile)}
          ${gqlParam('keywords_to_add', profile)}
          ${gqlParam('sector_ids', profile)}
          ${gqlParam('region_ids', profile)}
          ${gqlParam('languages', profile)}
          ${gqlParam('questions', profile)}
          ${gqlParam('cv_url', profile)}
          ${gqlParam('bill_rate', profile)}
          ${gqlParam('available_long_term', profile)}
          ${gqlParam('available_marketplace', profile)}
          ${gqlParam('hide_profile', profile)}
          ${gqlParam('tier', profile)}
          ${gqlParam('additional_information', profile)}
        ) {
          id
          sectors { id, name }
          regions { id, name, country_iso2_code }
          first_name
          last_name
          name
          html_url
          url_endpoint
          linkedin_username
          linkedin_url
          title
          summary
          skype
          timezone
          city
          country
          picture_url
          cover_url
          questions
          cv_url
          available_long_term
          available_marketplace
          hide_profile
          bill_rate
          credit_rate
          languages { code, fluency }
          keywords
          tier
          additional_information
        }
      }
    `,
      profile
    );

    dispatch({ type: PROFILE__UPDATE, profile: data.updateProfile });
  };
}

export function mergeProfiles(merge) {
  return async (dispatch, getState, { graphql }) => {
    await graphql.mutate(
      `
      (
        $destination_id: String!
        $source_id: String!
        ${gqlVar('first_name', 'String', merge)}
        ${gqlVar('last_name', 'String', merge)}
        ${gqlVar('linkedin_username', 'String', merge)}
        ${gqlVar('linkedin_url', 'String', merge)}
        ${gqlVar('title', 'String', merge)}
        ${gqlVar('summary', 'String', merge)}
        ${gqlVar('skype', 'String', merge)}
        ${gqlVar('timezone', 'String', merge)}
        ${gqlVar('city', 'String', merge)}
        ${gqlVar('country', 'String', merge)}
        ${gqlVar('picture_url', 'String', merge)}
        ${gqlVar('emails', '[String]', merge)}
        ${gqlVar('phones', '[String]', merge)}
        ${gqlVar('keywords', '[String]', merge)}
        ${gqlVar('sector_ids', '[String]', merge)}
        ${gqlVar('region_ids', '[String]', merge)}
        ${gqlVar('languages', '[LanguageInput]', merge)}
        ${gqlVar('experiences', '[ExperienceInput]', merge)}
        ${gqlVar('education', '[EducationInput]', merge)}
        ${gqlVar('questions', '[String]', merge)}
        ${gqlVar('cv_url', 'String', merge)}
        ${gqlVar('available_marketplace', 'Boolean', merge)}
        ${gqlVar('internal_networks_ids', '[String]', merge)}
        ${gqlVar('networks_ids', '[String]', merge)}
        ${gqlVar('expert_request_candidate_ids', '[String]', merge)}
        ${gqlVar('expert_request_id', 'String', merge)}
      ) {
        mergeProfiles (
          destination_id: $destination_id
          source_id: $source_id
          ${gqlParam('first_name', merge)}
          ${gqlParam('last_name', merge)}
          ${gqlParam('linkedin_username', merge)}
          ${gqlParam('linkedin_url', merge)}
          ${gqlParam('title', merge)}
          ${gqlParam('summary', merge)}
          ${gqlParam('skype', merge)}
          ${gqlParam('timezone', merge)}
          ${gqlParam('city', merge)}
          ${gqlParam('country', merge)}
          ${gqlParam('picture_url', merge)}
          ${gqlParam('emails', merge)}
          ${gqlParam('phones', merge)}
          ${gqlParam('keywords', merge)}
          ${gqlParam('sector_ids', merge)}
          ${gqlParam('region_ids', merge)}
          ${gqlParam('languages', merge)}
          ${gqlParam('experiences', merge)}
          ${gqlParam('education', merge)}
          ${gqlParam('questions', merge)}
          ${gqlParam('cv_url', merge)}
          ${gqlParam('available_marketplace', merge)}
          ${gqlParam('internal_networks_ids', merge)}
          ${gqlParam('networks_ids', merge)}
          ${gqlParam('expert_request_candidate_ids', merge)}
          ${gqlParam('expert_request_id', merge)}
        ) {
          id
        }
      }
    `,
      merge
    );
  };
}

export function csvImportPreview({
  file: { filename, url },
  networkId,
  availableMarketplace,
}) {
  return async (dispatch, getState, { graphql }) => {
    const cachedPreview = getState().profiles.csvPreviews[url];
    if (cachedPreview) return cachedPreview;

    const { profileCsvImportPreview: preview } = await graphql.query(
      `
      query profileCsvImportPreview(
        $url: String!
        $networkId: String
        $availableMarketplace: Boolean
      ) {
        profileCsvImportPreview (
          url: $url
          network_id: $networkId
          available_marketplace: $availableMarketplace
        ) {
          url
          profiles {
            row
            name
            email
            linkedin_url
            phone
            resolution
            expert_request_id
            warnings
            errors
          }
        }
      }
    `,
      { url, networkId, availableMarketplace }
    );

    dispatch({
      type: PROFILE__SET_CSV_PREVIEW,
      preview: { ...preview, filename, url, valid: !!preview },
    });
  };
}

export function csvImport({
  filename,
  url,
  ignoreRows,
  requestId,
  createdBy,
  networkId,
  availableMarketplace = true,
}) {
  return async (dispatch, getState, { graphql }) => {
    await graphql.mutate(
      `
      (
        $filename: String
        $url: String!
        $ignoreRows: [Int]
        $createdBy: String
        $requestId: String
        $networkId: String
        $availableMarketplace: Boolean
      ) {
        profileCsvImport(
          filename: $filename
          url: $url
          ignore_rows: $ignoreRows
          request_id: $requestId
          created_by: $createdBy
          network_id: $networkId
          available_marketplace: $availableMarketplace
        ) {
          url
          profiles {
            row
            name
            email
            linkedin_url
            phone
            resolution
            warnings
            errors
          }
        }
      }
    `,
      {
        filename,
        url,
        ignoreRows,
        requestId,
        createdBy,
        availableMarketplace,
        networkId,
      }
    );

    dispatch({ type: PROFILE__LIST_RESET, collection: 'conflicts' });
  };
}

export function deleteProfile(profile) {
  return async (dispatch, getState, { graphql }) => {
    await graphql.mutate(
      `
      ($id: String! ) {
        deleteProfile(
          id: $id
        )
      }
    `,
      { id: profile.id }
    );
    dispatch({ type: PROFILE__DELETE, profile });
  };
}

export function autofillProfile({ autofill, cvUrl }) {
  return async (dispatch, getState, { graphql }) => {
    await graphql.mutate(
      `
      (
        $autofill: Boolean!
        $cvUrl: String
      ) {
        autofillProfile(
          autofill: $autofill
          cv_url: $cvUrl
        ) {
          id
        }
      }
    `,
      { autofill, cvUrl }
    );
  };
}

export function saveExperience(profileId, exp) {
  return (dispatch, getState, { graphql }) => {
    const mutation = exp.id ? 'updateExperience' : 'addExperience';
    graphql
      .mutate(
        `(
        ${exp.id ? '$id: String!' : ''}
        ${!exp.id ? '$profile_id: String!' : ''}
        $title: String
        $role: String
        $description: String
        $organization: String
        $location: String
        $start_date: Date
        $end_date: Date
        $current: Boolean
      ) {
        ${mutation} (
          ${exp.id ? 'id: $id' : ''}
          ${!exp.id ? 'profile_id: $profile_id' : ''}
          title: $title
          role: $role
          description: $description
          organization: $organization
          location: $location
          start_date: $start_date
          end_date: $end_date
          current: $current
        ) {
          id
          title
          role
          description
          organization
          location
          start_date
          end_date
          current
        }
      }`,
        {
          profile_id: profileId,
          ...exp,
          current: exp.current === true,
        }
      )
      .then((result) => {
        const experience = result[mutation];
        dispatch({
          type: PROFILE__UPDATE_EXPERIENCE,
          profileId,
          experience,
        });
      });
  };
}

export function removeExperience(profileId, id) {
  return (dispatch, getState, { graphql }) => {
    graphql
      .mutate(
        `($id: String!) {
        removeExperience (id: $id) { id }
      }`,
        { id }
      )
      .then(() => {
        dispatch({
          type: PROFILE__REMOVE_EXPERIENCE,
          profileId,
          id,
        });
      });
  };
}

export function saveEducation(profileId, edu) {
  return (dispatch, getState, { graphql }) => {
    const mutation = edu.id ? 'updateEducation' : 'addEducation';
    graphql
      .mutate(
        `(
        ${edu.id ? '$id: String!' : ''}
        ${!edu.id ? '$profile_id: String!' : ''}
        $degree: String
        $field_of_study: String
        $school: String
        $start_date: Date
        $end_date: Date
        $description: String
      ) {
        ${mutation} (
          ${edu.id ? 'id: $id' : ''}
          ${!edu.id ? 'profile_id: $profile_id' : ''}
          degree: $degree
          field_of_study: $field_of_study
          school: $school
          start_date: $start_date
          end_date: $end_date
          description: $description
        ) {
          id
          degree
          field_of_study
          description
          start_date
          end_date
          school
        }
      }`,
        {
          profile_id: profileId,
          ...edu,
          current: edu.current === true,
        }
      )
      .then((result) => {
        const education = result[mutation];
        dispatch({
          type: PROFILE__UPDATE_EDUCATION,
          profileId,
          education,
        });
      });
  };
}

export function removeEducation(profileId, id) {
  return (dispatch, getState, { graphql }) => {
    graphql
      .mutate(
        `($id: String!) {
        removeEducation (id: $id) { id }
      }`,
        { id }
      )
      .then(() => {
        dispatch({
          type: PROFILE__REMOVE_EDUCATION,
          profileId,
          id,
        });
      });
  };
}

export function updatePicture(id, imageUrl) {
  return async (dispatch) => {
    // optimistic
    dispatch({
      type: PROFILE__UPDATE,
      profile: {
        id,
        picture_url: imageUrl,
      },
    });

    dispatch(
      updateProfile({
        id,
        picture_url: imageUrl,
      })
    );
  };
}

export function updateCover(id, imageUrl) {
  return async (dispatch) => {
    // optimistic
    dispatch({
      type: PROFILE__UPDATE,
      profile: {
        id,
        cover_url: imageUrl,
      },
    });

    dispatch(
      updateProfile({
        id,
        cover_url: imageUrl,
      })
    );
  };
}

import ActionTypes from '../core/ActionTypes';

const { URL__SHORTENED } = ActionTypes;

export function shortenUrl(url) {
  return async (dispatch, getState, { graphql }) => {
    const {
      urls: { shortened: shortenedUrls },
    } = getState();
    if (shortenedUrls[url]) {
      return shortenedUrls[url];
    }

    const { shortenURL: shorten } = await graphql.mutate(
      `
      (
        $url: String!
      ) {
        shortenURL(
          url: $url
        )
      }
    `,
      { url }
    );

    dispatch({
      type: URL__SHORTENED,
      url,
      shorten,
    });

    return shorten;
  };
}

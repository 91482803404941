import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Chip } from '@mui/material';
import ChipList from '../ChipList';
import Link from '../Link';
import { highlight } from '../../core/util';
import { darkGreen, white } from '../../core/colors';

const useStyles = makeStyles((theme) => ({
  root: ({ navigable, darker }) => ({
    cursor: navigable ? 'pointer' : '',
    whiteSpace: 'normal',
    height: 'auto',
    ...(darker
      ? {
          backgroundColor: darkGreen,
          color: white,
        }
      : {}),
  }),
  label: ({ breakLine }) => ({
    padding: '9px 12px',
    [theme.breakpoints.down('md')]: {
      ...(breakLine
        ? {
            whiteSpace: 'normal',
          }
        : {}),
    },
  }),
  deleteIcon: ({ darker }) => ({
    ...(darker
      ? {
          fill: white,
        }
      : {}),
  }),
}));

function KeywordChip({ keywordHtml, navigable, darker, breakLine, ...other }) {
  const s = useStyles({ navigable, breakLine, darker });

  const safeKeywordHtml = highlight(keywordHtml);
  const isHighlighted = keywordHtml.indexOf('<em>') !== -1;
  const keywordText = keywordHtml.replace('<em>', '').replace('</em>', '');

  const label = isHighlighted ? (
    <span dangerouslySetInnerHTML={{ __html: safeKeywordHtml }} />
  ) : keywordText.length > 64 ? (
    `${keywordText.slice(0, 64)}…`
  ) : (
    keywordText
  );

  const chip = (
    <Chip title={keywordText} label={label} classes={s} {...other} />
  );

  return navigable ? (
    <Link href={`/search?q=${encodeURIComponent(keywordText)}`}>{chip}</Link>
  ) : (
    chip
  );
}

function KeywordList({
  keywords,
  highlightedKeywords,
  children,
  icon = 'tag',
  style,
  navigable = true,
  chipProps,
  breakLine,
  ...other
}) {
  return (
    <ChipList {...other} icon={icon} style={{ overflow: 'hidden', ...style }}>
      {(highlightedKeywords || [])
        .map((k) => (
          <KeywordChip
            key={k}
            keywordHtml={k}
            navigable={navigable}
            breakLine={breakLine}
            darker
          />
        ))
        .concat(
          (keywords || []).map((k) => (
            <KeywordChip
              key={k}
              keywordHtml={k}
              breakLine={breakLine}
              navigable={navigable}
              {...chipProps}
            />
          ))
        )}
    </ChipList>
  );
}

KeywordList.defaultProps = {
  keywords: [],
};

export default KeywordList;

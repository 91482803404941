import React from 'react';
import { reduxForm } from 'redux-form';
import Dialog from '../../components/Dialog/Dialog';

class ConfirmCall extends React.Component {
  render() {
    const {
      open,
      handleSubmit,
      onCancel,
      onConfirm,
      confirmText,
      title = 'Confirm Call',
      confirmLabel = 'Yes, Accept Call',
    } = this.props;

    return (
      <Dialog
        open={open}
        title={title}
        subTitle={confirmText}
        subTitleStyle={{ marginTop: '20px' }}
        confirmLabel={confirmLabel}
        onClose={onCancel}
        onCancel={onCancel}
        onConfirm={handleSubmit(onConfirm)}
      />
    );
  }
}

ConfirmCall = reduxForm({
  form: 'confirmCall',
})(ConfirmCall);

export default ConfirmCall;

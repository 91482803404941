import React from 'react';
import { validateEmail } from '../../actions/address';
import AwaitingEmailValidation from './AwaitingEmailValidation';
import EmailValidated from './EmailValidated';
import { fetchSignupDomain } from '../../actions/domain';

const TOKEN_EXPIRED_ERROR = 'GraphQL Error: expired token';

export default [
  {
    path: '/validate_email',
    public: true,

    async action({ store, query }) {
      const { validate_token: token } = query;

      if (!token) return { redirect: '/' };

      try {
        await store.dispatch(validateEmail(token));
      } catch (err) {
        if (err.message === TOKEN_EXPIRED_ERROR) {
          return {
            title: 'OnFrontiers',
            component: <div>Link expired.</div>,
          };
        }
        throw err;
      }

      return { redirect: '/email_validated' };
    },
  },
  {
    subdomain: '*',
    path: '/awaiting_email_validation',
    public: false,

    async action({ store }, params, { subdomain }) {
      const domain = await store.dispatch(fetchSignupDomain(subdomain));
      if (subdomain && !domain) return null;

      const { viewer } = store.getState();
      if (viewer.email && viewer.email.accepted) {
        return { redirect: '/dashboard' };
      }
      return {
        title: 'Validate Email Address',
        component: <AwaitingEmailValidation />,
      };
    },
  },
  {
    path: '/email_validated',
    public: false,

    action() {
      return {
        title: 'Email Validated',
        component: <EmailValidated />,
      };
    },
  },
];

import moment from 'moment-timezone';

export function listEquals(c, e) {
  const conflicting = c || [];
  const existing = e || [];
  if (conflicting.length !== existing.length) return false;
  return conflicting.every((x) => existing.includes(x));
}

export function compareEducations(a, b) {
  if (a.start_date < b.start_date) return 1;
  if (a.start_date > b.start_date) return -1;

  if (a.id > b.id) return 1;
  if (a.id < b.id) return -1;

  return 0;
}

export function compareExperiences(a, b) {
  if (a.current < b.current) return 1;
  if (a.current > b.current) return -1;

  if (a.start_date < b.start_date) return 1;
  if (a.start_date > b.start_date) return -1;

  if (a.id > b.id) return 1;
  if (a.id < b.id) return -1;

  return 0;
}

export function formatDate(date) {
  return date ? moment(date).format('MMM YYYY') : '';
}

export function equalExperience(e, c) {
  if (e.organization !== c.organization) return false;
  if (e.title !== c.title) return false;
  if (formatDate(e.start_date) !== formatDate(c.start_date)) return false;
  if (formatDate(e.end_date) !== formatDate(c.end_date)) return false;
  if (e.description !== c.description) return false;
  if (e.linkedin_url !== c.linkedin_url) return false;
  if (e.location !== c.location) return false;
  return true;
}

export function equalEducation(e, c) {
  if (e.school !== c.school) return false;
  if (e.degree !== c.degree) return false;
  if (e.field_of_study !== c.field_of_study) return false;
  if (e.description !== c.description) return false;
  if (formatDate(e.start_date) !== formatDate(c.start_date)) return false;
  if (formatDate(e.end_date) !== formatDate(c.end_date)) return false;
  return true;
}

const parseFormat = 'YYYY-MM-DD';

export function experienceDateToString(d) {
  return d && moment(d).format(parseFormat);
}

export function experienceStringToDate(d) {
  return d && moment(d, parseFormat).toDate();
}

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Waypoint from 'react-waypoint';
import Button from '../Button/Button';
import CircularProgress from '../CircularProgress';
import s from './LongList.module.scss';

class LongList extends PureComponent {
  static propTypes = {
    entityName: PropTypes.string,
    entityNamePlural: PropTypes.string,
    title: PropTypes.string,
    containerClassName: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.array.isRequired,
    onMore: PropTypes.func,
    total: PropTypes.number,
    hasNextPage: PropTypes.bool,
    infiniteScroll: PropTypes.bool,
  };

  renderTitle = () => {
    const { entityName, entityNamePlural, total, title, children, loading } =
      this.props;
    if (title) return title;
    if (loading || loading === undefined) return '';
    if (!children || !children.length) return `No ${entityNamePlural} Found`;
    if (total === 1) return `1 ${entityName} Found`;
    if (!total) return `${entityNamePlural} Found`;
    return `${total.toLocaleString('en-US')} ${entityNamePlural} Found`;
  };

  render() {
    const {
      children,
      onMore,
      hasNextPage,
      containerClassName,
      className,
      loading,
      action,
      infiniteScroll,
    } = this.props;

    return (
      <div className={cx(s.root, { [className]: !!className })}>
        <div className={s.header}>
          <h3 className={s.title}>{this.renderTitle()}</h3>
          {action}
        </div>

        <div className={cx({ [containerClassName]: !!containerClassName })}>
          {children}
        </div>

        {hasNextPage &&
          !loading &&
          (infiniteScroll ? (
            <Waypoint onEnter={onMore} />
          ) : (
            <div className={s.loadMore}>
              <Button onClick={onMore}>Load More</Button>
            </div>
          ))}

        {loading && (
          <div className={s.loading}>
            <CircularProgress />
          </div>
        )}
      </div>
    );
  }
}

export default LongList;

import React from 'react';
import FAIcon from '../../components/Icon/FAIcon';
import Button from '../../components/Button/Button';
import s from './AttachToExpertRequestBanner.module.scss';
import { darkGreen } from '../../core/colors';

function AttachToExpertRequestBanner({ onRequestExpertRequestAttach }) {
  return (
    <div className={s.root}>
      <div className={s.title}>
        This consultation is not associated with an expert request.
      </div>
      <div className={s.message}>
        Expert requests allow you to keep all of your consultations about a
        specific topic in one place.
      </div>
      <Button
        fontColor={darkGreen}
        style={{ fontSize: 16 }}
        variant="text"
        startIcon={<FAIcon icon="plus" size={14} />}
        onClick={onRequestExpertRequestAttach}
      >
        Add to Expert Request
      </Button>
    </div>
  );
}

AttachToExpertRequestBanner = AttachToExpertRequestBanner;
export default AttachToExpertRequestBanner;

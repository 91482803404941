import React, { useCallback } from 'react';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import Button from '../Button';
import { darkGreen } from '../../core/colors';
import { otpTypes } from '../../actions/auth';

const useStyles = makeStyles({
  root: {
    textAlign: 'center',
  },
  code: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'center',
    marginTop: 25,
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 25,
  },
  submitButton: {
    margin: '20px 0 0 80px',
  },
  cancelButton: {
    margin: '20px 80px 0 0',
  },
  resendButton: {
    marginTop: 25,
    padding: '8px 11px',
  },
  sms: {
    marginTop: 10,
  },
  smsButton: {
    fontSize: 14,
    color: darkGreen,
    textTransform: 'none',
    fontWeight: 400,
  },
  title: {
    fontSize: 36,
  },
  loginErrorLink: {
    '&:hover': {
      textDecoration: 'underline',
    },
    color: darkGreen,
    textDecoration: 'underline',
  },
});

const securityEmail = 'hello@onfrontiers.com';

export default function ({
  onSubmit,
  onChange,
  onCancel,
  onResend,
  code,
  error,
  otpConfiguration,
  buttonResendDisable,
}) {
  const s = useStyles();

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter') onSubmit();
    },
    [onSubmit]
  );

  return (
    <div className={s.root}>
      <h1 className={s.title}>
        Enter the {otpTypes[otpConfiguration.method]} code
      </h1>
      {otpConfiguration.method !== otpTypes.app && (
        <p>
          {' '}
          The {otpConfiguration.method} code sent to{' '}
          {otpConfiguration.maskedAddress}{' '}
        </p>
      )}

      <div className={s.code}>
        <TextField
          label="Verification Code"
          variant="outlined"
          autoFocus
          value={code}
          onChange={onChange}
          error={!!error}
          helperText={error}
          fullWidth={false}
          onKeyPress={handleKeyPress}
        />
        {otpConfiguration.method !== otpTypes.app && (
          <Button
            variant="text"
            classes={{ root: s.resendButton }}
            onClick={onResend}
            disabled={buttonResendDisable}
            size="medium"
          >
            Resend
          </Button>
        )}
      </div>
      <div className={s.button}>
        <Button
          classes={{ root: s.cancelButton }}
          onClick={onCancel}
          fullWidth={false}
          variant="text"
          size="medium"
        >
          Cancel
        </Button>
        <Button
          classes={{ root: s.submitButton }}
          onClick={onSubmit}
          fullWidth={false}
          size="medium"
        >
          Submit
        </Button>
      </div>
      <div className={s.sms}>
        <span>
          If you have issues please contact&nbsp;
          <a className={s.loginErrorLink} href={`mailto:${securityEmail}`}>
            {securityEmail}
          </a>
        </span>
      </div>
    </div>
  );
}

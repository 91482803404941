import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import history from '../../core/history';
import ProfilePreview from '../../components/Profile/ProfilePreview';
import { updateExpertState } from '../../actions/user';

const getStyles = makeStyles((theme) => ({
  form: {
    marginTop: 30,
    [theme.breakpoints.down('md')]: {
      marginTop: 10,
    },
  },
}));

function CompleteProfile({ viewer, updateExpertState, ...other }) {
  const s = getStyles();

  const handleSubmit = useCallback(async () => {
    await updateExpertState({
      id: viewer.id,
      expert_state: 'applied',
    });

    history.push('/dashboard');
  }, []);

  return (
    <Grid container justifyContent="center">
      <Grid item md={12} sm={12}>
        <Form
          component={ProfilePreview}
          editable
          self
          isForm
          className={s.form}
          onSubmit={handleSubmit}
          {...other}
        />
      </Grid>
    </Grid>
  );
}

CompleteProfile.propTypes = {
  profileId: PropTypes.string.isRequired,
};

CompleteProfile = connect(undefined, {
  updateExpertState,
})(CompleteProfile);

export default CompleteProfile;

import agreements from '../core/agreements';
import { fetchUnreviewed } from '../actions/consultation';
import { fetchPermissions } from '../actions/permission';
import { pathAndQuery } from '../core/util';
import { hasAcceptedEmail } from '../core/user';

function loginRequired(route) {
  if (route.public) return route;

  return {
    ...route,

    async action(...args) {
      const [context] = args || [];
      const { viewer } = context.store.getState();

      if (!viewer.id) {
        return { redirect: `/login?next=${pathAndQuery(context.location)}` };
      }

      if (viewer.password_expiry && viewer.password_expiry.expired) {
        return {
          redirect: `/change_password?next=${pathAndQuery(context.location)}`,
        };
      }

      await context.store.dispatch(fetchUnreviewed());

      return route.action(...args);
    },
  };
}

function agreementsRequired(route) {
  return {
    ...route,

    async action(...args) {
      const [context] = args || [];
      const { viewer } = context.store.getState();

      if (viewer.id) {
        const agreement = agreements(viewer.agreements);
        const isExpert = viewer.signup_type === 'expert';

        if (!agreement.hasAccepted('terms-of-use', 'privacy')) {
          return {
            redirect: `/legal_ack/privacy?next=${pathAndQuery(
              context.location
            )}`,
          };
        }

        if (
          isExpert &&
          !agreement.hasAccepted('expert-participation-agreement')
        ) {
          return {
            redirect: `/legal_ack/expert-participation-agreement?next=${pathAndQuery(
              context.location
            )}`,
          };
        }
      }

      return route.action(...args);
    },
  };
}

function verifiedEmailRequired(route) {
  return {
    ...route,

    async action(...args) {
      const [context] = args || [];
      const { viewer } = context.store.getState();

      if (viewer.id && !hasAcceptedEmail(viewer)) {
        return { redirect: '/awaiting_email_validation' };
      }

      return route.action(...args);
    },
  };
}

function superAdminRequired(route) {
  return {
    ...route,

    async action(...args) {
      const [context] = args || [];
      const { viewer } = context.store.getState();

      if (!viewer.id) {
        return { redirect: '/' };
      }

      const [canViewAdminPanel] = await context.store.dispatch(
        fetchPermissions(viewer.id, [
          { service: 'profile', action: 'super_admin', resource: viewer.id },
        ])
      );

      if (!canViewAdminPanel.allowed) {
        return { redirect: '/' };
      }

      return route.action(...args);
    },
  };
}

function allRequired(route) {
  return loginRequired(agreementsRequired(verifiedEmailRequired(route)));
}

// The top-level (parent) route
const routes = {
  path: '',

  // keep in mind, routes are evaluated in order
  children: [
    require('./landing').default,
    require('./dotorg').default,
    require('./login').default,
    loginRequired(require('./loginAs').default),
    require('./logout').default, // should be loginRequired but can't because of OTP
    require('./changePassword').default,
    require('./healthCheck').default,
    require('./about').default,
    require('./becomeAnExpert').default,
    require('./successStories').default,
    allRequired(require('./expertRequestNew').default),
    require('./expertRequestPublic').default,
    require('./jobs').default,
    require('./blog').default,
    require('./profile').default,
    require('./resetPassword').default,
    require('./graphiql').default,
    require('./cases').default,
    require('./platform').default,
    require('./legal').default,
    require('./selectDomain').default,
    require('./teamAboutPage').default,
    allRequired(require('./application').default),
    agreementsRequired(require('./register').default),
    ...require('./validateEmail').default.map((r) => loginRequired(r)),
    ...require('./projectDetails').default.map((r) =>
      agreementsRequired(verifiedEmailRequired(r))
    ),
    agreementsRequired(
      verifiedEmailRequired(require('./expertRequestDetails').default)
    ),
    loginRequired(require('./legalAck').default),
    allRequired(require('./billingPortal').default),
    allRequired(require('./payoutDashboard').default),
    allRequired(require('./lead').default),
    allRequired(require('./complianceTraining').default),
    allRequired(require('./search').default),
    allRequired(require('./teams').default),
    allRequired(require('./expertRequests').default),
    allRequired(require('./expertRequestNew').addMembers),
    ...require('./team').default.map(allRequired),
    allRequired(require('./teamSettings').default),
    allRequired(require('./dashboard').default),
    allRequired(require('./settings').default),
    allRequired(require('./consultation').default),
    allRequired(require('./consultations').default),
    allRequired(require('./messaging').default),
    allRequired(require('./profileUploader').default),
    allRequired(require('./profileConflicts').default),
    allRequired(require('./profileMerge').default),
    superAdminRequired(require('./admin').default),
    ...require('./expertNetwork').default.map(allRequired),
    require('./clientLanding').default,

    // Wildcard routes, e.g. { path: '*', ... } (must go last)
    require('./browse').default,
    require('./notFound').default,
  ],

  async action({ next }) {
    // Execute each child route until one of them return the result
    const route = await next();

    // Provide default values for title, description etc.
    route.title = route.title || 'Untitled Page';
    route.description = route.description || '';

    return route;
  },
};

export default routes;

import config from '../../config';

export class Policy {
  constructor({ id, key, name, slug }) {
    this.id = id;
    this.key = key;
    this.name = name;
    this.slug = slug;
  }

  get url() {
    return `${config.mainSiteUrl}/${this.slug}`;
  }

  get apiKey() {
    return `${this.key}:${this.id}`;
  }
}

export const policies = Object.fromEntries(
  [
    new Policy({
      id: 6848,
      key: 'terms-of-use',
      name: 'Terms of Use',
      slug: 'policies/website-terms-of-use',
    }),
    new Policy({
      id: 6630,
      key: 'terms-of-service',
      name: 'Terms of Service',
      slug: 'policies/onfrontiers-terms-of-service',
    }),
    new Policy({
      id: 6864,
      key: 'privacy',
      name: 'Privacy Policy',
      slug: 'policies/privacy-policy',
    }),
    new Policy({
      id: 6917,
      key: 'expert-participation-agreement',
      name: 'Expert Participation Agreement',
      slug: 'policies/expert-participation-agreement',
    }),
    new Policy({
      id: 6907,
      key: 'marketplace-expert-agreement',
      name: 'Marketplace Expert Agreement',
      slug: 'policies/marketplace-expert-agreement',
    }),
    new Policy({
      id: 6879,
      key: 'customer-charging-policy',
      name: 'Customer Charging Policy',
      slug: 'policies/customer-charging-policy',
    }),
  ].map((p) => [p.key, p])
);

export function matchAgreement(apiKey, policy) {
  const [key, id] = apiKey.split(':');
  if (id) {
    return policy.id.toString() === id;
  }
  return policy.key === key;
}

function isValidAgreement(agreement) {
  const apiKey = agreement.policy;
  const [key, id] = apiKey.split(':');
  return !!id || !policies[key];
}

function hasAcceptedAgreement(key, agreements) {
  const policy = policies[key];
  if (!policy || !agreements) {
    return false;
  }

  const agreement = agreements.find((a) => matchAgreement(a.policy, policy));
  return !!agreement && agreement.accepted;
}

class Agreements {
  constructor(userAgreements) {
    this.userAgreements = userAgreements;
  }

  hasAccepted(...policyKeys) {
    if (!config.requireAgreements) {
      return true;
    }
    const agreements = this.userAgreements.filter(isValidAgreement);
    return policyKeys.reduce((acc, key) => {
      return acc && hasAcceptedAgreement(key, agreements);
    }, true);
  }
}

export default function agreements(userAgreements) {
  return new Agreements(userAgreements);
}

import React from 'react';
import { connect } from 'react-redux';
import LayoutPage from '../../components/Layout/LayoutPage';
import EmptyMessage from '../../components/EmptyMessage';
import Link from '../../components/Link';
import Button from '../../components/Button';
import { teal500 } from '../../core/colors';
import { isUserApplying } from '../../core/util';

class EmailValidated extends React.Component {
  render() {
    const { viewer } = this.props;
    const action =
      viewer.signup_type === 'expert' && isUserApplying(viewer) ? (
        <Link to="/signup/expert">
          <Button
            primary
            style={{ marginTop: 15 }}
            onClick={this.handleResendVerification}
          >
            Continue to Expert Application →
          </Button>
        </Link>
      ) : (
        <Link to="/dashboard">
          <Button
            size="large"
            style={{ marginTop: 15 }}
            onClick={this.handleResendVerification}
          >
            Go to Dashboard →
          </Button>
        </Link>
      );

    return (
      <LayoutPage
        hideSearch
        showReviewConsultation={false}
        showNewRequest={false}
      >
        <EmptyMessage
          border={false}
          iconName="check_circle"
          iconColor={teal500}
          title="Your email has been verified"
          action={action}
        />
      </LayoutPage>
    );
  }
}

EmailValidated = connect((state) => ({
  viewer: state.viewer,
}))(EmailValidated);

export default EmailValidated;

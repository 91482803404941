import React, { memo } from 'react';
import { Button as MaterialButton } from '@mui/material';
import CreditsButton from '../CreditsButton';
import s from './Actions.module.scss';
import { formatCreditsText, roundOffCredits } from '../../core/util';

function Actions({ bookingFee, expertCredits, submitting, onCancel }) {
  expertCredits = roundOffCredits(expertCredits);

  return (
    <div className={s.root}>
      <div>
        <MaterialButton onClick={onCancel}>Cancel</MaterialButton>
      </div>

      <CreditsButton
        credits={expertCredits + bookingFee}
        disabled={submitting}
        type="submit"
        creditsTooltip={
          <div>
            {expertCredits > 0 && (
              <div>Expert time: {formatCreditsText(expertCredits)}</div>
            )}
            <div>Booking fee: {formatCreditsText(bookingFee)}</div>
          </div>
        }
      >
        {submitting ? 'Submitting...' : 'Submit Request'}
      </CreditsButton>
    </div>
  );
}

Actions = Actions;

Actions = memo(Actions);

export default Actions;

import React from 'react';
import { reduxForm, Field } from 'redux-form';
import Dialog from '../../components/Dialog';
import { darkGreen } from '../../core/colors';
import s from './CancelConsultation.module.scss';
import { TextField } from '../../components/FormAdapters/FormAdapters';

class CancelConsultation extends React.Component {
  render() {
    const {
      open,
      showPolicy,
      handleSubmit,
      onCancel,
      onConfirm,
      title = 'Cancel Call',
      confirmLabel = 'Yes, Cancel',
      label = 'What is your reason for cancelling?',
    } = this.props;

    return (
      <Dialog
        open={open}
        title={title}
        confirmLabel={confirmLabel}
        confirmButtonProps={{ color: 'red' }}
        onClose={onCancel}
        onCancel={onCancel}
        onConfirm={handleSubmit(onConfirm)}
      >
        {showPolicy && (
          <p className={s.message}>
            Please review our&nbsp;
            <a
              target="_blank"
              rel="noreferrer"
              style={{ color: darkGreen }}
              href="https://try.onfrontiers.com/knowledge/cancellations-no-shows"
            >
              cancellation policy
            </a>
            .
          </p>
        )}

        <Field
          component={TextField}
          multiline
          fullWidth
          name="cancel_reason"
          label={label}
        />
      </Dialog>
    );
  }
}

CancelConsultation = reduxForm({
  form: 'cancelConsultation',
})(CancelConsultation);

CancelConsultation = CancelConsultation;

export default CancelConsultation;

import React from 'react';
import { Popover, Button, Box } from '@mui/material';
import TwilioPopoverContent from './TwilioPopoverContent';
import PlivoPopoverContent from './PlivoPopoverContent';
import { teal500 } from '../../../core/colors';

const CarriersPopoverContent = {
  twilio: TwilioPopoverContent,
  plivo: PlivoPopoverContent,
};

function TimelinePopover({ carrier, participant, range, ...other }) {
  const Content = CarriersPopoverContent[carrier];

  return (
    <Popover
      {...other}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Box p={2}>
        {Content && <Content participant={participant} range={range} />}
        <div style={{ textAlign: 'center' }}>
          {!participant.hasMetrics() && <p>Metrics are pending</p>}

          <Button
            href={participant.url()}
            target="__blank"
            style={{ width: 120, color: teal500 }}
          >
            View Details
          </Button>
        </div>
      </Box>
    </Popover>
  );
}

export default TimelinePopover;

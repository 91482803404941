import React, { PureComponent } from 'react';
import { Button as MaterialButton } from '@mui/material';

import MaterialIcon from '../../components/Icon/MaterialIcon';
import Dialog from '../../components/Dialog';
import CreditsButton from '../../components/CreditsButton';
import { teal500 } from '../../core/colors';
import s from './RequestTranscript.module.scss';

class RequestTranscript extends PureComponent {
  render() {
    const {
      open,
      onCancel,
      onConfirm,
      transcriptionPrice,
      submittingTranscript,
    } = this.props;

    return (
      <Dialog open={open} onClose={onCancel}>
        <div className={s.icons}>
          <MaterialIcon icon="speaker_notes" size={40} color={teal500} />
        </div>

        <h4 className={s.title}>Request Call Transcript</h4>

        <p className={s.text}>Typical turnaround time is 24 hours.</p>

        <p className={s.text}>
          Call duration, connection quality, and languages spoken factor into
          timing.
        </p>

        <div className={s.actions}>
          <MaterialButton onClick={onCancel}>Cancel</MaterialButton>

          <CreditsButton
            disabled={submittingTranscript}
            credits={transcriptionPrice && transcriptionPrice.cents}
            onClick={onConfirm}
          >
            {submittingTranscript ? 'Requesting...' : 'Request'}
          </CreditsButton>
        </div>
      </Dialog>
    );
  }
}

RequestTranscript = RequestTranscript;

export default RequestTranscript;

import React from 'react';
import Button from '../Button';
import MarketingSection from '../MarketingSection';
import s from './BookCallCta.module.scss';

function BookCallCta() {
  return (
    <MarketingSection
      className={s.cta}
      padding={50}
      bodyStyle={{ maxWidth: 800 }}
    >
      <h2 className={s.title}>Book a call with one of our experts</h2>

      <p className={s.text}>
        We help our customers get rapid, reliable and high quality insights on
        their most critical business challenges.
      </p>

      <Button href="/signup/member" size="huge" style={{ width: 212 }}>
        Get Started
      </Button>
    </MarketingSection>
  );
}

export default BookCallCta;

import React, { PureComponent } from 'react';
import cx from 'classnames';
import langs from 'langs';
import { formatLocation, highlight } from '../../core/util';
import FAIcon from '../Icon/FAIcon';
import Link from '../Link';
import s from './Tidbit.module.scss';

function Dot() {
  return <span className={s.dot}>•</span>;
}

class Tidbit extends PureComponent {
  static defaultProps = {
    languages: [],
    editable: false,
    inline: true,
    showIcon: true,
  };

  render() {
    const {
      city,
      country,
      languages,
      experience,
      className,
      editable,
      inline,
      onEditLocation,
      onEditLanguages,
      showIcon,
      ...other
    } = this.props;
    const location = formatLocation(city, country);

    const items = languages
      .map((l) => {
        const language = langs.where('3', l.code);
        return language ? language.name : l.code;
      })
      .filter((l) => l)
      .sort((a, b) => (a > b ? 1 : b > a ? -1 : 0))
      .join(', ');

    const rootClassName = cx(s.root, {
      [s.inline]: inline,
      [className]: !!className,
    });

    const addLocationLink = onEditLocation ? undefined : '/settings/location';
    const adLanguagesLink = onEditLanguages
      ? undefined
      : '/settings/communication';

    return (
      <div className={rootClassName} {...other}>
        {(location || editable) && (
          <div className={s.location}>
            {showIcon && (
              <FAIcon
                iconSet="fal"
                icon="map-marker"
                style={{ width: 14, marginRight: 5 }}
              />
            )}
            {location ? (
              <span dangerouslySetInnerHTML={{ __html: highlight(location) }} />
            ) : (
              editable && (
                <Link
                  className={s.link}
                  onClick={onEditLocation}
                  to={addLocationLink}
                >
                  Add location
                </Link>
              )
            )}
          </div>
        )}

        {((location && items) || editable) && inline && <Dot />}

        {items ? (
          <div>Speaks {items}</div>
        ) : (
          editable && (
            <Link
              className={s.link}
              onClick={onEditLanguages}
              to={adLanguagesLink}
            >
              Add language
            </Link>
          )
        )}

        {(items || location) && experience && inline && <Dot />}

        {experience && (
          <div>
            {!inline && (
              <FAIcon
                iconSet="fal"
                icon="briefcase"
                style={{ width: 14, marginRight: 5 }}
              />
            )}
            <span
              dangerouslySetInnerHTML={{
                __html: highlight(
                  experience.title
                    ? `${experience.title}, ${experience.organization}`
                    : experience
                ),
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

export default Tidbit;

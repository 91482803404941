import actionTypes from '../core/ActionTypes';
import { gqlParam, gqlVar } from './util';

const { LANDING_PAGE__UPDATE, LANDING_PAGE__REMOVE, LANDING_PAGE__BATCH_ADD } =
  actionTypes;

const landingPageFields = `
  id
  subdomain
  path
  page_title
  title
  subtitle
  background_url
  header_logo_url
  header_logo_link_url
  body_logo_url
  meta_image_url
  description
  experts_engage_text
  google_tag_manager_id
  organization_about_logo_url
  organization_about_logo_link_url
  organization_about_text
  organization_about_title
  onfrontiers_about_text
  show_footer
  ctas {
    id
    label
    url
    order
  }
  engage_ctas {
    id
    label
    url
    order
  }
  engage_details {
    id
    icon
    description
    order
  }
  blog_posts {
    id
    url
    thumbnail_url
    author
    date
    title
    excerpt
  }
  value_propositions {
    id
    icon
    title
    description
  }
`;

export function fetchLandingPage({ subdomain, path, id }) {
  return async (dispatch, getState, { graphql }) => {
    const pages = getState().landing[subdomain];
    const cachedPage = pages && pages[path];
    if (cachedPage) return cachedPage;

    const result = await graphql.query(
      `query getLandingPage(
      $subdomain: String,
      $path: String,
      $id: String
    ) {
      landingPage(subdomain: $subdomain, path: $path, id: $id) {
        ${landingPageFields}
      }
    }`,
      { subdomain, path, id }
    );

    const page = result.landingPage;

    if (page) {
      dispatch({ type: LANDING_PAGE__UPDATE, subdomain, path, ...page, page });
    }

    return page;
  };
}

export function updateLandingPage(page) {
  return async (dispatch, _, { graphql }) => {
    const result = await graphql.mutate(
      `
      (
        ${gqlVar('id', 'String', page)}
        ${gqlVar('subdomain', 'String!', page)}
        ${gqlVar('path', 'String!', page)}
        ${gqlVar('page_title', 'String', page)}
        ${gqlVar('title', 'String', page)}
        ${gqlVar('subtitle', 'String', page)}
        ${gqlVar('background_url', 'String', page)}
        ${gqlVar('header_logo_url', 'String', page)}
        ${gqlVar('header_logo_link_url', 'String', page)}
        ${gqlVar('body_logo_url', 'String', page)}
        ${gqlVar('meta_image_url', 'String', page)}
        ${gqlVar('description', 'String', page)}
        ${gqlVar('experts_engage_text', 'String', page)}
        ${gqlVar('google_tag_manager_id', 'String', page)}
        ${gqlVar('organization_about_logo_url', 'String', page)}
        ${gqlVar('organization_about_logo_link_url', 'String', page)}
        ${gqlVar('organization_about_text', 'String', page)}
        ${gqlVar('organization_about_title', 'String', page)}
        ${gqlVar('onfrontiers_about_text', 'String', page)}
        ${gqlVar('show_footer', 'Boolean', page)}
        ${gqlVar('ctas', '[LandingPageCtaInputType]', page)}
        ${gqlVar('engage_ctas', '[LandingPageEngageCtaInputType]', page)}
        ${gqlVar('engage_details', '[LandingPageEngageDetailInputType]', page)}
        ${gqlVar(
          'value_propositions',
          '[LandingPageValuePropositionInputType]',
          page
        )}
        ${gqlVar('blog_posts', '[LandingPageBlogPostInputType]', page)}
      ) {
        updateLandingPage (
          ${gqlParam('id', page)}
          ${gqlParam('subdomain', page)}
          ${gqlParam('path', page)}
          ${gqlParam('page_title', page)}
          ${gqlParam('title', page)}
          ${gqlParam('subtitle', page)}
          ${gqlParam('background_url', page)}
          ${gqlParam('header_logo_url', page)}
          ${gqlParam('header_logo_link_url', page)}
          ${gqlParam('body_logo_url', page)}
          ${gqlParam('meta_image_url', page)}
          ${gqlParam('description', page)}
          ${gqlParam('experts_engage_text', page)}
          ${gqlParam('google_tag_manager_id', page)}
          ${gqlParam('organization_about_logo_url', page)}
          ${gqlParam('organization_about_logo_link_url', page)}
          ${gqlParam('organization_about_text', page)}
          ${gqlParam('organization_about_title', page)}
          ${gqlParam('onfrontiers_about_text', page)}
          ${gqlParam('show_footer', page)}
          ${gqlParam('ctas', page)}
          ${gqlParam('engage_ctas', page)}
          ${gqlParam('engage_details', page)}
          ${gqlParam('value_propositions', page)}
          ${gqlParam('blog_posts', page)}
        ) {
          ${landingPageFields}
        }
      }
    `,
      page
    );

    page = result.updateLandingPage;

    if (page) {
      dispatch({ type: LANDING_PAGE__UPDATE, ...page, page });
    }

    return page;
  };
}

export function removeLandingPage(id) {
  return async (dispatch, _, { graphql }) => {
    const { removeLandingPage } = await graphql.mutate(
      ` ($id: String!) {
        removeLandingPage (id: $id)
      }
    `,
      { id }
    );

    if (removeLandingPage) {
      dispatch({ type: LANDING_PAGE__REMOVE, id });
    }

    return removeLandingPage;
  };
}

export function fetchLandingPages() {
  return async (dispatch, _, { graphql }) => {
    const result = await graphql.query(`{
      landingPages {
        id
        subdomain
        path
        title
      }
    }`);

    const pages = result.landingPages;

    if (pages) {
      dispatch({ type: LANDING_PAGE__BATCH_ADD, pages });
    }

    return pages;
  };
}

import ActionTypes from '../core/ActionTypes';

const { NETWORK__UPDATE_EXPERT } = ActionTypes;

const initialState = {
  experts: {},
};

export default function networksReducer(state = initialState, action) {
  switch (action.type) {
    case NETWORK__UPDATE_EXPERT:
      return {
        experts: {
          ...state.experts,
          [action.networkExpert.id]: action.networkExpert,
        },
      };
    default:
      return state;
  }
}

import React from 'react';

export default {
  path: '/health-check',
  ignoreDomain: true,

  action() {
    return {
      component: <h1>Ok</h1>,
      status: 200,
    };
  },
};

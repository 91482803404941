import React from 'react';
import LinearProgressMaterial from '@mui/material/LinearProgress';
import makeStyles from '@mui/styles/makeStyles';
import { darkGreen, grey300 } from '../../core/colors';

const useStyles = makeStyles(() => ({
  colorPrimary: {
    backgroundColor: grey300,
  },
  barColorPrimary: {
    backgroundColor: darkGreen,
  },
}));

function LinearProgress(props) {
  const s = useStyles();
  return (
    <LinearProgressMaterial
      {...props}
      classes={{
        barColorPrimary: s.barColorPrimary,
        colorPrimary: s.colorPrimary,
        ...props.classes,
      }}
      style={{
        ...props.style,
      }}
    />
  );
}

export default LinearProgress;

import moment from 'moment-timezone';
import ActionTypes from '../core/ActionTypes';

const { PERMISSION__SET } = ActionTypes;

export default function permissionsReducer(state = {}, action) {
  switch (action.type) {
    case PERMISSION__SET:
      const userPerms = state[action.userId] || {};
      return {
        ...state,
        [action.userId]: {
          ...userPerms,
          [action.key]: {
            expires: moment().add(20, 'minute').toISOString(),
            allowed: action.allowed,
          },
        },
      };
    default:
      return state;
  }
}

import React, { Component } from 'react';

import ConsultationPreview from '../../components/ConsultationPreview';
import CircularProgress from '../../components/CircularProgress';
import s from './Consultations.module.scss';

class Consultations extends Component {
  render() {
    const { loading, awaiting, confirmed } = this.props;

    const upcomingConsultations = awaiting.concat(confirmed);

    if (upcomingConsultations.length === 0 && !loading) return <div />;

    return (
      <div>
        <h3 className={s.title}>Upcoming Calls</h3>

        <div>
          {upcomingConsultations.map((c) => (
            <ConsultationPreview key={c.node.id} consultation={c.node} />
          ))}
        </div>

        {loading && (
          <div className={s.loading}>
            <CircularProgress />
          </div>
        )}
      </div>
    );
  }
}

export default Consultations;

import { isAdvancedUser } from '../../core/group';
import { erTypes } from '../../actions/expertRequest';

export function copyExpertRequest(viewer, expertRequest) {
  return {
    copy_from: expertRequest.id,
    name: `Copy of ${expertRequest.name}`,
    er_type: expertRequest.er_type,
    description: expertRequest.description,
    disclosure:
      isAdvancedUser(viewer) && expertRequest.unpaid
        ? expertRequest.disclosure
        : 'full',
    regions: expertRequest.regions,
    sectors: expertRequest.sectors,
    focus_areas: expertRequest.focus_areas,
    qualifications:
      expertRequest.qualifications && expertRequest.qualifications.length > 0
        ? expertRequest.qualifications.map((q) => ({
            query: q.query,
            response_type: q.response_type,
            required: q.required,
          }))
        : [{ query: '' }],
    questions:
      expertRequest.questions && expertRequest.questions.length > 0
        ? expertRequest.questions.map((q) => ({
            query: q.query,
            response_type: q.response_type,
            required: q.required,
          }))
        : [{ query: '' }],
    companies_avoid: expertRequest.companies_avoid || [],
    companies_pursue: expertRequest.companies_pursue || [],
    project_name: '',
    tracking_code: '',
    group_id: null,
    phone: expertRequest.phone,
    project_id: expertRequest.project ? expertRequest.project.id : null,
    expertRequest: expertRequest.unpaid,
  };
}

export function getDefaultValues(viewer, initialProjectId) {
  const group = viewer.groups && viewer.groups.length === 1 && viewer.groups[0];

  return {
    attachments: [],
    name: '',
    description: '',
    disclosure: 'full',
    er_type: erTypes.consultation,
    regions: [],
    sectors: [],
    focus_areas: [],
    qualifications: [{ query: '' }],
    questions: [{ query: '' }],
    companies_avoid: [],
    companies_pursue: [],
    project_name: '',
    tracking_code: '',
    group_id: group ? group.id : null,
    group_about: '',
    phone: viewer.admin ? '' : viewer.phone,
    skip_phone: viewer.admin,
    project_id: initialProjectId || null,
    unpaid: false,
    currentVisit: 1,
    formInitialized: false,
  };
}

import React, { PureComponent } from 'react';
import SnackbarMaterial from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

class Snackbar extends PureComponent {
  static defaultProps = {
    autoHideDuration: 5000,
  };

  render() {
    const { messageType, bodyStyle, message, ...other } = this.props;

    return (
      <SnackbarMaterial {...other}>
        <Alert
          style={{ minWidth: 288 }}
          variant="filled"
          icon={false}
          severity={messageType}
        >
          {message}
        </Alert>
      </SnackbarMaterial>
    );
  }
}

export default Snackbar;

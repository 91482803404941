import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import makeStyles from '@mui/styles/makeStyles';
import Filter from './Filter';
import { darkGray } from '../../../core/colors';

const useStyles = makeStyles({
  checkbox: {
    padding: 5,
  },
  labelRoot: {
    margin: 0,
  },
  checkboxFilterLabel: {
    color: darkGray,
    fontSize: 14,
    textTransform: 'uppercase',
    lineHeight: 1,
  },
});

export default function ({
  title,
  checked = false,
  label,
  onChange,
  labelPlacement = 'start',
  classes,
}) {
  const defaultClasses = useStyles();

  return (
    <FormControl margin="none">
      <Filter title={title}>
        <FormControlLabel
          label={label}
          labelPlacement={labelPlacement}
          classes={{
            root: defaultClasses.labelRoot,
            label: defaultClasses.checkboxFilterLabel,
            ...classes,
          }}
          control={
            <Checkbox
              checked={checked}
              onChange={onChange}
              classes={{ root: defaultClasses.checkbox }}
            />
          }
        />
      </Filter>
    </FormControl>
  );
}

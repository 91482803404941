import React, { PureComponent } from 'react';
import Dialog from '../Dialog';
import Form from './Form';

class EditMembersDialog extends PureComponent {
  handleClose = (c) => {
    const { onReset, onClose } = this.props;

    if (onReset) {
      onReset();
    }

    if (onClose) {
      onClose(c);
    }
  };

  handleSubmit = (values) => {
    const { onSubmit, onClose } = this.props;

    if (onSubmit) {
      const errors = onSubmit(values);
      if (errors) {
        return;
      }
    }

    if (onClose) {
      onClose(true);
    }
  };

  render() {
    const {
      handleSubmit,
      array,
      users,
      members,
      project,
      viewer,
      saveLabel,
      initialValues,
      usersLocked,
      ...other
    } = this.props;

    return (
      <Dialog
        maxWidth="md"
        title="Project Members"
        subTitle="Members can see this project, add other members, and join any scheduled calls."
        onClose={this.handleClose}
        {...other}
      >
        <Form
          buttonSize="medium"
          onSubmit={this.handleSubmit}
          onReset={this.handleClose}
          resetLabel="Cancel"
          submitLabel="Save"
          searchLabel="Find or invite users"
          searchHint="Start typing the name of a user or an email address"
          project={project}
          initialValues={initialValues}
          usersLocked={usersLocked}
        />
      </Dialog>
    );
  }
}

export default EditMembersDialog;

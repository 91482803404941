import React from 'react';
import queryString from 'query-string';
import ProjectDetails from './ProjectDetails';
import LayoutPage from '../../components/Layout/LayoutPage';
import RequestProjectAccess from '../../components/RequestProjectAccess';
import {
  fetchProject,
  updateProjectMember,
  awaitingProjectMembershipApproval,
} from '../../actions/project';

function requestAdd(viewer, id, accessRequested) {
  return {
    title: 'Expert Request',
    component: (
      <LayoutPage showNav selected="expert_requests">
        <RequestProjectAccess
          path="project"
          projectId={id}
          viewer={viewer}
          accessRequested={accessRequested}
        />
      </LayoutPage>
    ),
  };
}

export default [
  {
    // private project page
    path: /^\/project\/([0-9]*)$/,
    async action({
      store,
      params: { 0: id } = {},
      query: { token, approve_member: approve, deny_member: deny },
    }) {
      const { viewer } = store.getState();

      if (!viewer.id) return requestAdd(viewer, id, false);

      if (token && (approve || deny)) {
        try {
          await store.dispatch(
            updateProjectMember(id, {
              id: approve || deny,
              state: approve ? 'active' : 'denied',
            })
          );
        } catch (err) {
          Promise.reject(err);
        }
        // we don't want to keep the token in the URL since user can share link
        return { redirect: `/project/${id}` };
      }

      let project;
      try {
        project = id && (await store.dispatch(fetchProject(id)));
        if (!project) return;
      } catch (e) {
        if (!e.isPermissionError) throw e;
        const accessRequested = await store.dispatch(
          awaitingProjectMembershipApproval({ projectId: id })
        );
        return requestAdd(viewer, id, accessRequested);
      }

      return {
        title: project.name,
        component: <ProjectDetails projectId={id} />,
      };
    },
  },
  {
    // legacy public project page
    path: /^\/project\/(.+-[0-9]+)$/,
    async action({ params: { 0: slug }, query } = {}) {
      return {
        redirect: `/expert_request/${slug}?${queryString.stringify(query)}`,
      };
    },
  },
  {
    // legacy new project wizard flow
    path: '/projects/new',
    async action() {
      return {
        redirect: '/request_expert',
      };
    },
  },
];

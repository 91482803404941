import React, { PureComponent } from 'react';
import {
  ListSubheader,
  ListItemText,
  List,
  ListItem,
  Divider,
} from '@mui/material';
import Link from '../Link';
import { darkGreen } from '../../core/colors';
import s from './SettingsNav.module.scss';

class Section extends PureComponent {
  render() {
    const { section, pathPrefix, selected } = this.props;

    return (
      <List style={{ padding: '8px 0' }}>
        <ListSubheader
          style={{ textTransform: 'uppercase', paddingRight: 16 }}
          disableSticky
        >
          {section.title}
          <Divider style={{ marginBottom: 10 }} />
        </ListSubheader>
        {section.items
          .filter((e) => e.path.indexOf('/') < 0)
          .map((e) => (
            <Link
              key={e.path}
              href={`${pathPrefix}/${e.path}`}
              id={e.id}
              force={!!e.url}
            >
              <ListItem>
                <ListItemText
                  disableTypography
                  primary={e.title}
                  style={
                    e.path === selected
                      ? {
                        fontWeight: 'bold',
                        color: darkGreen,
                      }
                      : undefined
                  }
                />
              </ListItem>
            </Link>
          ))}
      </List>
    );
  }
}

class SettingsNav extends PureComponent {
  render() {
    const { nav, pathPrefix, selected } = this.props;

    return (
      <div className={s.root}>
        {nav.map((e) => (
          <Section
            selected={selected}
            pathPrefix={pathPrefix}
            key={e.title}
            section={e}
          />
        ))}
      </div>
    );
  }
}

export default SettingsNav;

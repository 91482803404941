// Harness config
export const CLIENT_NAME = (
  process.env.REACT_APP_CLIENT_NAME || 'Harness'
).replace(/\+/g, ' ');
export const CLIENT_ASSETS_NAME =
  process.env.REACT_APP_CLIENT_ASSETS_NAME || 'harness';
export const PRODUCT_NAME = process.env.REACT_APP_PRODUCT_NAME || 'Harness';
export const LOGO = `/assets/logo/${CLIENT_ASSETS_NAME}.png`;
export const ICON = `/assets/favicon/${CLIENT_ASSETS_NAME}.ico`; // this icon is used in the add projects, give preference to client logo

export const ONBOARDING_VIDEO = process.env.REACT_APP_ONBOARDING_VIDEO
  ? +process.env.REACT_APP_ONBOARDING_VIDEO
  : 858772190;

export const CLIENT_NAMES = {
  fhi: 'FHI 360',
  chemonics: 'Harness',
  access: 'Access',
};

// Access config
const baseDomain = process.env.REACT_APP_BASE_DOMAIN || 'localhost';

/*
  Client configuration that is safe to keep in the global scope (window.config).
*/
const config = {
  appEnv: process.env.REACT_APP_ENV || 'development',
  baseDomain,
  legacyBaseDomain: baseDomain.replace('app.', ''),
  baseTLD: process.env.REACT_APP_BASE_TLD || '',
  apiUrl: process.env.REACT_APP_API_URL || `http://${baseDomain}:5000`,
  apiProxy: process.env.REACT_APP_API_PROXY === '1',
  rewriteUrl: process.env.REACT_APP_REWRITE_URL,
  webUrl: process.env.REACT_APP_WEB_URL || `http://${baseDomain}:3000`,
  cdnUrl: process.env.REACT_APP_CDN_URL,
  mainSiteUrl: `https://onfrontiers.com`,
  webSocketUrl:
    process.env.REACT_APP_WEBSOCKET_URL || `ws://${baseDomain}:5000/websocket`,
  blogApiUrl: process.env.REACT_APP_BLOG_API_URL || 'https://onfrontiers.com',
  filestackEngagementAttachmentMimeTypes: process.env
    .REACT_APP_FILESTACK_ENGAGEMENT_ATTACHMENT_MIME_TYPES
    ? process.env.REACT_APP_FILESTACK_ENGAGEMENT_ATTACHMENT_MIME_TYPES.split(
        ','
      )
    : ['text/*', 'image/*', 'application/*'],
  // TODO: Remove temporary feature flag introduced in EKN-138
  // when feature is ready.
  enableInviteParticipant:
    process.env.REACT_APP_ENABLE_INVITE_PARTICIPANT === '1',
  disableWrittenEngagement:
    process.env.REACT_APP_DISABLE_WRITTEN_ENGAGEMENT === '1',
  disableChangeExpertRequestType:
    process.env.REACT_APP_DISABLE_CHANGE_EXPERT_REQUEST_TYPE === '1',
  recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
  // See ACCESS-27, we are disabling these expert request types for all
  // accounts except for the ones listed below.
  googleTagManagerId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
  enableConsultingProjectAccountIds: process.env
    .REACT_APP_ENABLE_CONSULTING_PROJECT_ACCOUNT_IDS
    ? process.env.REACT_APP_ENABLE_CONSULTING_PROJECT_ACCOUNT_IDS.split(',')
    : [],
  enableNewHireAccountIds: process.env.REACT_APP_ENABLE_NEW_HIRE_ACCOUNT_IDS
    ? process.env.REACT_APP_ENABLE_NEW_HIRE_ACCOUNT_IDS.split(',')
    : [],
  // See ACCESS-29, we are disabling these consultation types for all
  // accounts except for the ones listed below.
  enableOpportunityCallAccountIds: process.env
    .REACT_APP_ENABLE_OPPORTUNITY_CALL_ACCOUNT_IDS
    ? process.env.REACT_APP_ENABLE_OPPORTUNITY_CALL_ACCOUNT_IDS.split(',')
    : [],
  // stripe demo test publishable key
  stripePublishableKey:
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ||
    'pk_test_6pRNASCoBOKtIshFeQd4XMUh',
  enableBillingPortal: process.env.REACT_APP_ENABLE_BILLING_PORTAL === '1',
  enablePayoutDashboard: process.env.REACT_APP_ENABLE_PAYOUT_DASHBOARD === '1',
  requireAgreements: process.env.REACT_APP_REQUIRE_AGREEMENTS === '1',
};
const isNode = typeof window === 'undefined';
// KT: fix for node scripts
const location = isNode ? { origin: '' } : window.location;
config.cdnUrl = config.cdnUrl || config.webUrl;
config.apiUrl = config.apiProxy ? `${location.origin}/api` : config.apiUrl;

export default config;

/*
  Client configuration that should NOT be in the global scope.
  These are defined in build time, so they must also be defined in
  webpack.config.js, Dockerfile, and Jenkins.
*/
export const hiddenConfig = {
  filestackApiKey: process.env.REACT_APP_FILESTACK_API_KEY,
  intercomAppId: process.env.REACT_APP_INTERCOM_APP_ID,
  sendBirdAppId: process.env.REACT_APP_SENDBIRD_APP_ID,
  sentryDsn: process.env.REACT_APP_SENTRY_DSN,
};

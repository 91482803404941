import actionTypes from '../core/ActionTypes';

const { AUTH__UPDATE, BASIC_AUTH__UPDATE } = actionTypes;

const INITIAL_STATE = {};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case AUTH__UPDATE:
      return {
        ...state,
        otpAuthUri: action.otpAuthUri,
      };
    case BASIC_AUTH__UPDATE:
      return {
        ...state,
        basicAuthentication: action.basicAuthentication,
      };
    default:
      return state;
  }
}

import React, { useState, memo, useEffect, useRef } from 'react';
import MaterialIcon from '../Icon/MaterialIcon';
import { white } from '../../core/colors';
import s from './CallAlert.module.scss';
import DialMe from './DialMe';
import { NetworkWarnings } from '../../core/telephony/voipCarrier';

const dialOutRecommendationWarnings = Object.freeze([
  NetworkWarnings.highJitter,
  NetworkWarnings.highPacketLoss,
  NetworkWarnings.lowMos,
]);

const CallAlert = memo(({ message, warningName, visible }) => {
  const [dialing, setDialing] = useState(false);
  const [recommendDialOut, setRecommendDialOut] = useState(false);
  const timeoutId = useRef();

  useEffect(() => {
    const newRecommendDialOut =
      dialOutRecommendationWarnings.includes(warningName);
    // Recommend dial out is visible at the moment
    if (!newRecommendDialOut && recommendDialOut) {
      clearInterval(timeoutId.current);
      // Keeps recommend dial out for at least 30s before hiding
      timeoutId.current = setTimeout(
        () => setRecommendDialOut(newRecommendDialOut),
        30 * 1000
      );
    } else {
      setRecommendDialOut(newRecommendDialOut);
    }

    return () => clearTimeout(timeoutId.current);
  }, [warningName]);

  if (dialing) {
    return (
      <div className={s.root}>
        <div className={s.dialing}>We&rsquo;re calling your phone number</div>
      </div>
    );
  }

  if (!visible && !recommendDialOut) {
    return null;
  }

  return (
    <div className={recommendDialOut ? s.rootLarge : s.root}>
      <div className={s.internal}>
        <MaterialIcon
          style={{
            display: 'table-cell',
            verticalAlign: 'middle',
            color: white,
          }}
          icon="error"
        />
        <span className={s.contents}>{message}</span>
        {recommendDialOut && (
          <DialMe
            showDialingMe={() => setDialing(true)}
            closeDialingMe={() => setDialing(false)}
          />
        )}
      </div>
    </div>
  );
});

export default CallAlert;

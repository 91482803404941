import React, { PureComponent } from 'react';
import cx from 'classnames';
import s from './FieldContainer.module.scss';

class FieldContainer extends PureComponent {
  render() {
    const {
      label,
      labelStyle,
      children,
      containerStyle,
      className,
      containerClassName,
      title,
      errorText,
      warningText,
      ...other
    } = this.props;

    const rootClassNames = cx(s.root, {
      [className]: !!className,
    });
    const containerClassNames = cx(s.container, {
      [containerClassName]: !!containerClassName,
      [s.error]: !!errorText,
      [s.warning]: !!warningText && !errorText,
    });

    return (
      <div {...other} className={rootClassNames}>
        <div title={title} className={s.label} style={labelStyle}>
          {label}
        </div>
        <div className={containerClassNames} style={containerStyle}>
          {children}
        </div>
        {errorText && <div className={s.errorText}>{errorText}</div>}
        {warningText && <div className={s.warningText}>{warningText}</div>}
      </div>
    );
  }
}

FieldContainer = FieldContainer;

export default FieldContainer;

import React from 'react';

import Grid from '@mui/material/Grid';
import DetailsNew from '../../components/Forms/ExpertRequest/DetailsNew';
import SubmitContainer from '../../components/Forms/ExpertRequest/SubmitContainer';
import Link from '../../components/Link';
import MaterialIcon from '../../components/Icon/MaterialIcon';
import { darkGreen } from '../../core/colors';
import s from './Details.module.scss';

class Details extends React.PureComponent {
  render = () => {
    const { change, values, handleSubmit, isMobileVersion } = this.props;
    return (
      <React.Fragment>
        <div>
          <Link to="/request_expert" className={s.back}>
            <MaterialIcon color={darkGreen} icon="arrow_back" />
            <div className={s.backText}>Create New Request</div>
          </Link>
        </div>
        <Grid container spacing={4} justifyContent="center">
          <Grid item md={8} sm={12}>
            <DetailsNew
              isViewerExpert={false}
              change={change}
              values={values}
              isMobileVersion={isMobileVersion}
              canEdit
              canEditQueries
              canAddAttachment
            />
          </Grid>
          <Grid item md={4} sm={12} className={s.submitWrapper}>
            <SubmitContainer isNewRequest handleSubmit={handleSubmit} />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };
}

Details.title = 'More Details';
Details.fetch = DetailsNew.fetch.concat([SubmitContainer.fetch]);

export default Details;

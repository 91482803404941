import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import Button from '@mui/material/Button';
import Link from '../../components/Link';
import FAIcon from '../../components/Icon/FAIcon';
import { darkBlue, red500 } from '../../core/colors';

const useStyles = makeStyles(() => ({
  header: {
    marginBottom: 20,
  },
  defaultHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  section: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  icons: {
    color: darkBlue,
    fontSize: 14,
    cursor: 'pointer',
  },
  icon: {
    marginLeft: 20,
    marginRight: 2,
  },
}));

export default function ListItems({
  model,
  items = [],
  onCreate,
  onDelete,
  editEnabled = true,
  additionalHeaders = [],
}) {
  const s = useStyles();
  const renderActions = function (item) {
    const actionComponents = [];

    if (editEnabled) {
      actionComponents.push(
        <Link key={`${item.id}-edit`} to={`/admin/${model.path}/${item.id}`}>
          <FAIcon className={s.icon} icon="pencil" /> Edit
        </Link>
      );
    }

    if (onDelete) {
      actionComponents.push(
        <Link
          key={`${item.id}-delete`}
          style={{ color: red500 }}
          onClick={() => onDelete(item)}
        >
          <FAIcon className={s.icon} icon="trash-o" color={red500} /> Delete
        </Link>
      );
    }
    return actionComponents;
  };

  return (
    <List>
      <div className={s.header}>
        <ListSubheader disableSticky className={s.defaultHeader}>
          {model.name}
          <Button variant="contained" onClick={onCreate}>
            <FAIcon icon="plus" style={{ marginRight: 10 }} /> Add
          </Button>
        </ListSubheader>
        {additionalHeaders}
      </div>
      {items.map((item) => (
        <ListItem key={item.id} classes={{ root: s.section }}>
          <Link to={`/admin/${model.path}/${item.id}`}>{item.name}</Link>
          <div className={s.icons}>{renderActions(item)}</div>
        </ListItem>
      ))}
    </List>
  );
}

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import Link from '../Link';
import Button from '../Button';
import TransactionList from '../TransactionList';
import { money } from '../../core/money';
import { formatDate, formatCreditsText } from '../../core/util';
import history from '../../core/history';
import s from './CreditSettings.module.scss';

class CreditSettings extends PureComponent {
  handlePurchaseCredits = () => {
    const { purchasePath } = this.props;
    history.push(purchasePath);
  };

  render() {
    const { accountType, memberNotOwner, seatCount } = this.props;

    const account = this.props.account || {};
    const transactions = account.transactions || [];
    const balance =
      account.credit_balance || money({ currency: 'OFC', cents: 0 });

    const entityType = account.entity_type || 'organization';

    return (
      <div>
        <div className={s.details}>
          <div className={s.detailsRow}>
            <div className={s.detailsCol1}>Balance</div>
            <div
              className={cx(s.detailsCol2, {
                [s.balanceWarning]: balance.cents <= 0,
              })}
            >
              {money(balance).formatted()}{' '}
              {balance.cents === '100' ? 'Credit' : 'Credits'}
            </div>
            <div className={s.detailsCol3}>
              {memberNotOwner ? (
                <div className={s.balanceNotOwnerInstructions}>
                  Contact Team Owner to purchase credits
                </div>
              ) : (
                <Button
                  secondary
                  size="small"
                  label="Purchase Credits"
                  onClick={this.handlePurchaseCredits}
                />
              )}
            </div>
          </div>
          {accountType && (
            <div className={s.detailsRow}>
              <div className={s.detailsCol1}>Account Type</div>
              <div className={s.detailsCol2}>{accountType}</div>
            </div>
          )}
          {entityType === 'organization' && (
            <div className={s.detailsRow}>
              <div className={s.detailsCol1}>Seat Count</div>
              <div className={s.detailsCol2}>{seatCount || 0}</div>
            </div>
          )}
          <div className={s.detailsRow}>
            <div className={s.detailsCol1}>Credit Expiry</div>
            <div className={s.detailsCol2}>{this.renderExpiries()}</div>
          </div>
        </div>
        <TransactionList
          transactions={transactions}
          amountColumn={{
            header: 'Credits Amount',
            field: 'credits',
          }}
        />
        <div className={s.questions}>
          Questions about credit usage? Contact our{' '}
          <Link to="https://try.onfrontiers.com/knowledge">
            customer care team
          </Link>
          .
        </div>
      </div>
    );
  }

  renderExpiries() {
    const account = this.props.account || {};
    const expiries = (account.purchases || []).filter(
      (p) => money(p.remaining.cents).cents && p.expires_at
    );

    if (!expiries.length) return 'N/A';

    return (
      <div>
        {expiries.map((e) => (
          <div>
            {formatCreditsText(money(e.remaining).cents)} on&nbsp;
            {formatDate(e.expires_at)}
          </div>
        ))}
      </div>
    );
  }
}

CreditSettings = connect((state) => ({
  viewer: state.viewer,
}))(CreditSettings);

export default CreditSettings;

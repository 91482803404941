import React, { useMemo } from 'react';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { connect } from 'react-redux';
import Button from '../../components/Button';
import { notify } from '../../actions/ui';
import { updateGroupBranding } from '../../actions/group';
import Logo from './Logo';
import { presignedFilestackURL } from '../../actions/filestack';
import { Checkbox } from '../../components/FormAdapters/FormAdapters';

function Branding({
  groupId,
  group,
  internalNetwork,
  updateGroupBranding,
  notify,
  presignedFilestackURL,
  isViewerAdmin,
}) {
  const initialValues = useMemo(() => {
    return {
      branding_logo_external_url: group.branding_logo_url,
      branding_show_poweredbyof: group.branding_show_poweredbyof,
    };
  }, [group, internalNetwork]);

  const handleSubmit = async (values) => {
    try {
      await updateGroupBranding(groupId, values);
      notify('Team branding updated.', 'success');
    } catch (err) {
      notify('Error when updating team branding.', 'error');
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      mutators={{ ...arrayMutators }}
    >
      {({ form, values, submitting, handleSubmit }) => {
        return (
          <form onSubmit={(e) => handleSubmit(e)}>
            <Logo
              label="Logo"
              src={values.branding_logo_external_url}
              onChange={async (file) => {
                const signedUrl = await presignedFilestackURL(file.url);
                form.change('branding_logo_external_url', signedUrl);
                form.change('branding_logo_url', file.url);
              }}
              dimensions={{ height: 20, width: 213 }}
              alternateSvgUpload={isViewerAdmin}
              noStretch
            />

            <Field
              type="checkbox"
              component={Checkbox}
              label="Show Powered by OnFrontiers"
              name="branding_show_poweredbyof"
              FormControlProps={{ margin: 'dense' }}
            />

            <Button
              type="submit"
              disabled={submitting}
              style={{ marginTop: 40 }}
              size="medium"
            >
              Save
            </Button>
          </form>
        );
      }}
    </Form>
  );
}

Branding = connect(
  (state, { groupId }) => {
    const group = state.groups.default.edges.find(
      (e) => e.node.id === groupId
    ).node;
    return {
      group,
    };
  },
  {
    updateGroupBranding,
    notify,
    presignedFilestackURL,
  }
)(Branding);

export default Branding;

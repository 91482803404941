import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import DeleteDialog from '.';
import history from '../../core/history';
import { deleteProject } from '../../actions/project';
import { notify } from '../../actions/ui';

const CONSULTATIONS_ERROR =
  'GraphQL Error: cannot delete project with pending/upcoming/completed consultations';

class DeleteProjectDialog extends PureComponent {
  handleDelete = () => {
    const { projectId, deleteProject, notify, onCancel, returnTo } = this.props;
    deleteProject(projectId)
      .then(() => {
        if (returnTo) history.push(returnTo);
      })
      .catch((err) => {
        if (err.message === CONSULTATIONS_ERROR) {
          notify(
            'Cannot delete project with pending, upcoming, or completed consultations.',
            'error'
          );
        } else {
          notify(
            'An unexpected error occurred when trying ' +
              'to delete the project.',
            'error'
          );
        }
        onCancel();
      });
  };

  render() {
    return (
      <DeleteDialog
        {...this.props}
        title="Are you sure you want to delete this project?"
        onConfirm={this.handleDelete}
      >
        This action is permanent and will result in the loss of all expert
        requests and matches.
      </DeleteDialog>
    );
  }
}

DeleteProjectDialog = connect(undefined, {
  deleteProject,
  notify,
})(DeleteProjectDialog);

export default DeleteProjectDialog;

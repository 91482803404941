import queryString from 'query-string';

export function buildRefUrl(location, expertRequest, userId, source) {
  const params = {
    utm_source: source,
    utm_medium: 'referral',
    utm_campaign: `expert_request${expertRequest.id}`,
    ref: userId,
  };

  return `${expertRequest.public_html_url}?${queryString.stringify(params)}`;
}

import React, { PureComponent } from 'react';

import MaterialIcon from '../../components/Icon/MaterialIcon';
import { white, teal500, darkGray, black } from '../../core/colors';
import Picture from '../../components/Picture';
import s from './TranscriptStepper.module.scss';

function Step({ number, text, checked, inProgress, separator }) {
  return (
    <div>
      <div className={s.step}>
        <div className={s.stepIcon}>
          <Picture
            size={20}
            style={{
              color: white,
              fontSize: 12,
              backgroundColor: checked || inProgress ? teal500 : darkGray,
            }}
          >
            {checked ? (
              <MaterialIcon icon="check" size={16} color={white} />
            ) : (
              number
            )}
          </Picture>
        </div>

        <div
          style={{ color: checked || inProgress ? black : darkGray }}
          className={s.stepText}
        >
          {text}
        </div>
      </div>

      {separator && <div className={s.separator} />}
    </div>
  );
}

class TranscriptStepper extends PureComponent {
  render() {
    const { order } = this.props;

    return (
      <div className={s.root}>
        <MaterialIcon icon="speaker_notes" size={40} color={teal500} />

        <div className={s.content}>
          <div className={s.title}>
            We&#39;re working on your consultation transcript...
          </div>
          <div className={s.text}>
            You&#39;ll receive an email when it&#39;s ready
          </div>

          <Step
            number={1}
            text="Order received"
            checked
            inProgress={false}
            separator
          />
          <Step
            number={2}
            text="Preparing transcript (24 hours)"
            checked={false}
            inProgress={order.state === 'transcribing'}
            separator
          />
          <Step
            number={3}
            text="Transcript available"
            checked={false}
            inProgress={false}
          />
        </div>
      </div>
    );
  }
}

export default TranscriptStepper;

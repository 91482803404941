import ActionTypes from '../core/ActionTypes';

const { NETWORK__UPDATE_EXPERT, PROFILE__UPDATE, GROUP__UPDATE } = ActionTypes;

export function fetchNetworkExpert(networkExpertId) {
  return async (dispatch, getState, { graphql }) => {
    const { internalNetworkExpert: networkExpert } = await graphql.query(
      `query getNetworkExpert(
      $id: String!
    ) {
      internalNetworkExpert(id: $id) {
        id
        network {
          group {
            domain {
              logo_url
              agreements(active_only: true) {
                policy_code
                policy_label
                policy_url
              }
            }
          }
        }
      }
    }`,
      { id: networkExpertId }
    );

    if (networkExpert)
      dispatch({ type: NETWORK__UPDATE_EXPERT, networkExpert });

    return networkExpert;
  };
}

export function canJoinNetwork(params) {
  return (dispatch, getState, { graphql }) =>
    graphql.query(
      `
    query canJoinNetwork(
      $email: String
      $profileId: String
      $networkId: String!
    ) {
      canJoinNetwork(
        email: $email
        profile_id: $profileId
        network_id: $networkId
      )
    }`,
      params
    );
}

export function joinNetwork(subdomain) {
  return (dispatch, getState, { graphql }) =>
    graphql.mutate(
      `(
      $subdomain: String!
    ) {
      joinNetwork(
        subdomain: $subdomain
      ) {
        id
      }
    }`,
      { subdomain }
    );
}

export function addNetworkExperts(params) {
  return (dispatch, getState, { graphql }) =>
    graphql.mutate(
      `(
      $profileIds: [String!]
      $networkIds: [String!]
      $addMethod: NetworkExpertAddMethod!
      $teamNote: String
    ) {
      addNetworkExperts(
        profile_ids: $profileIds
        network_ids: $networkIds
        add_method: $addMethod
        team_note: $teamNote
      ) {
        id
      }
    }`,
      params
    );
}

export function removeNetworkExperts(params) {
  return (dispatch, getState, { graphql }) =>
    graphql.mutate(
      `(
      $networkExpertIds: [String!]
      $message: String
      $unshare: Boolean
    ) {
      removeNetworkExperts(
        network_expert_ids: $networkExpertIds
        message: $message
        unshare: $unshare
      )
    }`,
      params
    );
}

export function updateNetworkExpert(networkExpert) {
  return (dispatch, getState, { graphql }) =>
    graphql.mutate(
      `(
      $id: String!
      $connection_state: NetworkExpertConnectionState
    ) {
      updateNetworkExpert(
        id: $id
        connection_state: $connection_state
      ) {
        id
      }
    }`,
      networkExpert
    );
}

export function updateProfileKeywords(profileId, toAdd = [], toDelete = []) {
  return async (dispatch, getState, { graphql }) => {
    const result = await graphql.mutate(
      `(
        $profileId: String!
        $toAdd: [String]!
        $toDelete: [String]!
      ) {
        updateProfileKeywords(
          profile_id: $profileId
          to_add: $toAdd
          to_delete: $toDelete
        ) {
          group { id, name }
          keywords { id, name }
        }
      }`,
      {
        profileId,
        toAdd,
        toDelete,
      }
    );

    if (result) {
      dispatch({
        type: PROFILE__UPDATE,
        profile: {
          id: profileId,
          group_keywords: result.updateProfileKeywords,
        },
      });

      return result.updateProfileKeywords;
    }
  };
}

export function updateNetwork(network, groupId) {
  return async (dispatch, getState, { graphql }) => {
    const { updateNetwork } = await graphql.mutate(
      `(
      $group_id: String!
      ${
        'expert_not_available_self_service' in network
          ? '$expert_not_available_self_service: Boolean'
          : ''
      }
      ${'expert_unpaid' in network ? '$expert_unpaid: Boolean' : ''}
    ) {
      updateNetwork(
        group_id: $group_id
        ${
          'expert_not_available_self_service' in network
            ? 'expert_not_available_self_service: $expert_not_available_self_service'
            : ''
        }
        ${'expert_unpaid' in network ? 'expert_unpaid: $expert_unpaid' : ''}
      ) {
        id
        name
        expert_not_available_self_service
        expert_unpaid
      }
    }`,
      network
    );

    if (updateNetwork && groupId) {
      dispatch({
        type: GROUP__UPDATE,
        group: { id: groupId, internal_network: updateNetwork },
      });
    }

    return updateNetwork;
  };
}

import React from 'react';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';
import { PhoneInput } from '../../FormAdapters';
import { fetchCountries } from '../../../actions/country';
import { isPhoneValid } from '../../../core/util';

function Phone(props) {
  const validate = (value) => {
    const { required } = props;
    if (!value) {
      return required ? 'Phone required' : undefined;
    }
    if (!isPhoneValid(value)) {
      return 'Phone must be valid';
    }
  };

  return (
    <Field
      id="communicationPhone"
      component={PhoneInput}
      validate={validate}
      type="tel"
      name="phone"
      showExampleOnError
      parse={(v) => v}
      {...props}
    />
  );
}

Phone = connect((state) => ({
  allCountries: state.countries,
}))(Phone);

Phone.fetch = fetchCountries;

export default Phone;

export function getUserContextOptions(user, userContext, groups) {
  let options = [];

  options.push({ value: 'client', name: user.first_name });

  if (user.signup_type === 'expert' || user.expert_state) {
    options.push({
      value: 'expert',
      name: user.first_name,
      description: 'Expert',
    });
  }

  if (user.admin) {
    options.push({
      value: 'admin',
      name: user.first_name,
      description: 'Admin',
    });
  }

  if (user.groups) {
    options = options.concat(
      user.groups.map((g) => ({ value: g.id, name: g.name }))
    );
  }

  const group =
    userContext && groups && groups.find((g) => g.id === userContext);
  if (group && !options.some((o) => o.value === group.id)) {
    options.push({ value: group.id, name: group.name });
  }

  return options;
}

export function isGroupContext(userContext) {
  return !!userContext && !['admin', 'client', 'expert'].includes(userContext);
}

export function getDefaultUserContext(viewer) {
  if (viewer.admin) return 'admin';

  const groupId = viewer.groups && viewer.groups[0] && viewer.groups[0].id;

  if (groupId) return groupId;

  if (viewer.signup_type === 'expert' || viewer.expert_state) return 'expert';

  return 'client';
}

export function getUserOrGroup(userContext, user) {
  if (isGroupContext(userContext)) {
    return user.groups.find((g) => g.id === userContext);
  }
  return user;
}

export function hasAcceptedEmail(viewer) {
  return (
    viewer.email.accepted ||
    (viewer.profile.emails && viewer.profile.emails.some((e) => e.accepted))
  );
}

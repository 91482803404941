import ActionTypes from '../core/ActionTypes';

const { CONSULTATION__ADD_REGISTRANT } = ActionTypes;

const consultationCallStatus = Object.freeze({
  service: 'consultation',
  queue: 'expert_call_status',
});

// Setting up a reference to remove listener from anywhere
let unsubscribeQueue;

export function addRegistrant(consultation, registrant) {
  return async (dispatch, getState, { graphql }) => {
    const { addRegistrant } = await graphql.mutate(
      `(
        $conferenceId: String!
        $name: String!
        $email: String!
      ) {
       addRegistrant (
          conference_id: $conferenceId
          name: $name
          email: $email
          phone_number: ""
        )
      }
      `,
      registrant
    );

    const addedRegistrant = {
      ...registrant,
      identifier: addRegistrant,
    };

    dispatch({
      type: CONSULTATION__ADD_REGISTRANT,
      consultation,
      registrant: addedRegistrant,
    });

    return addedRegistrant;
  };
}

export function getJoinInfo(conferenceId) {
  return async (dispatch, getState, { graphql }) => {
    const { joinInfo } = await graphql.query(
      `query getJoinInfo(
      $conferenceId: String!
    ) {
      joinInfo(conference_id: $conferenceId) {
        join_url
        identifier
        meeting_id
        dial_in_numbers_url
        dial_in_number
        dial_in_region
        passcode
        pin
      }
    }`,
      { conferenceId }
    );

    return joinInfo;
  };
}

export function joinCall(call, { receiveEvents } = {}) {
  return async (dispatch, _, { voipCarrier, apiWebSocket }) => {
    await voipCarrier.connect(call.carrier, call.identifier, call.joinURL);

    dispatch(updateCall(call));

    if (!receiveEvents) return;

    const handleCallEvents = (payload) => {
      if (payload.status === 'connected') {
        dispatch(updateCall({ awaitingExpert: false }));
        return;
      }

      if (payload.status === 'disconnected') {
        dispatch(updateCall({ awaitingExpert: true }));
      }
    };

    unsubscribeQueue = apiWebSocket.on(
      {
        service: consultationCallStatus.service,
        queue: consultationCallStatus.queue,
        resource: call.consultationId,
      },
      handleCallEvents
    );
  };
}

export function leaveCall() {
  return async (dispatch, _, { voipCarrier }) => {
    dispatch(updateCall({ consultationId: null }));
    voipCarrier.disconnect();
    if (unsubscribeQueue) {
      unsubscribeQueue();
    }
  };
}

export function toggleCallMute() {
  return async (dispatch, getState, { voipCarrier }) => {
    voipCarrier.toggleMute();
  };
}

export function createToken(identifier) {
  return async (dispatch, getState, { graphql }) => {
    const { createConferenceToken: callToken } = await graphql.mutate(
      `(
      $clientName: String!
      $identifier: String!
    ) {
      createConferenceToken(
        client_name: $clientName
        identifier: $identifier
      )
    }`,
      {
        clientName: `web-${process.env.NODE_ENV}`,
        identifier,
      }
    );

    return callToken;
  };
}

export function updateCall(call) {
  return { type: ActionTypes.CALL__UPDATE, call };
}

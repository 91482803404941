import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import cx from 'classnames';
import { Select } from '../../../components/Select';

const useStyles = makeStyles({
  bold: {
    fontWeight: 500,
  },
  selected: {
    fontWeight: 500,
    backgroundColor: 'transparent !important',
  },
  small: {
    fontSize: 14,
  },
  placeholder: {
    opacity: 0.42,
    color: 'currentColor',
    fontWeight: 'normal',
  },
});

export default function ({
  placeholder,
  disabled,
  multiple,
  options,
  value,
  onChange,
}) {
  const classes = useStyles();

  return (
    <Select
      value={value}
      multiple={multiple}
      options={options}
      onChange={onChange}
      disabled={disabled}
      disableUnderline
      displayEmpty
      displayCheckbox={multiple}
      classes={{ root: cx(classes.small, classes.bold) }}
      margin="none"
      menuItemClasses={{ root: classes.small, selected: classes.selected }}
      // Do not move menu list when selecting items
      MenuProps={{ getContentAnchorEl: null }}
      renderValue={(selected) => {
        if (placeholder && selected.length === 0) {
          return <span className={classes.placeholder}>{placeholder}</span>;
        }
        return (multiple ? selected : [selected])
          .map((v) => options.find((o) => o.value === v)?.label)
          .join(', ');
      }}
    />
  );
}

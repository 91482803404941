import React from 'react';
import Footer from '../Footer';
import s from './MarketingLayout.module.scss';
import SnackbarNotification from '../Snackbar/SnackbarNotification';

function Layout({
  children,
  dispatch,
  footerProps,
  hideMessage,
  showFooter = true,
  ...other
}) {
  return (
    <div {...other}>
      {children}

      <SnackbarNotification />

      {showFooter && <Footer {...footerProps} />}
    </div>
  );
}

export default Layout;

import * as Sentry from '@sentry/react';
import pkg from '../package.json';

declare global {
  interface Window {
    __VERSION__: string;
    __GIT_SHA__?: string;
  }
}

interface CustomError extends Error {
  extra?: Record<string, any>; // eslint-disable-line @typescript-eslint/no-explicit-any
}

const gitSha = process.env.REACT_APP_GIT_SHA;
let buildVersion = '';
if (process.env.REACT_APP_BUILD_LABEL) {
  buildVersion += `-${process.env.REACT_APP_BUILD_LABEL}`;
}
if (process.env.REACT_APP_BUILD_NUMBER) {
  buildVersion += `+${process.env.REACT_APP_BUILD_NUMBER}`;
}

window.__VERSION__ = pkg.version + buildVersion;
window.__GIT_SHA__ = gitSha;

Sentry.init({
  release: process.env.REACT_APP_SENTRY_RELEASE || window.__VERSION__,
  environment:
    process.env.REACT_APP_SENTRY_ENV ||
    process.env.REACT_APP_ENV ||
    'development',
  dsn:
    process.env.REACT_APP_SENTRY_DSN ||
    'https://0ec7e374fab745f6b91e799bbaf2e9fb@o29082.ingest.sentry.io/4504911074099200',
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],

  ignoreErrors: ['AbortError', 'CancelError'],

  beforeSend(event: Sentry.Event, hint: Sentry.EventHint) {
    if (event.exception) {
      // KT: disable this for now.
      // Sentry.showReportDialog({ eventId: event.event_id });
    }
    const error = hint.originalException as CustomError;
    if (error?.extra) {
      event.extra = {
        ...(event.extra || {}),
        ...error.extra,
      };
    }
    return event;
  },

  beforeBreadcrumb(breadcrumb) {
    // too noisy
    if (breadcrumb.category === 'redux.action') {
      return null;
    }

    return breadcrumb;
  },

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

if (process.env.REACT_APP_ACCESS === '1') {
  require('./access/index');
} else {
  require('./indexHarness');
}

export {};

import React from 'react';
import Grid from '@mui/material/Grid';
import CaseStudyPreview from './CaseStudyPreview';
import s from './CaseStudyList.module.scss';

function CaseStudyList({
  title,
  cases,
  titleStyle,
  dim,
  caseTitleStyle,
  ...other
}) {
  return (
    <div className={s.root} {...other}>
      {title && (
        <h2 className={s.title} style={titleStyle}>
          {title}
        </h2>
      )}
      <Grid container spacing={4}>
        {cases.map((c) => (
          <Grid key={c.slug} item sm={12} md={6} lg={4}>
            <CaseStudyPreview dim={dim} titleStyle={caseTitleStyle} {...c} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default CaseStudyList;

import React from 'react';
import { Button as MaterialButton } from '@mui/material';
import s from './SearchPromo.module.scss';

function SearchPromo({ title, children, href, label, ...other }) {
  return (
    <div className={s.root} {...other}>
      <h3 className={s.text}>{title}</h3>
      {children}
      <MaterialButton
        color="secondary"
        href={href}
        style={{
          fontSize: 16,
        }}
      >{`${label} →`}</MaterialButton>
    </div>
  );
}

export default SearchPromo;

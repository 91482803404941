export function generateUploadPolicies() {
  return async (dispatch, getState, { graphql }) => {
    const data = await graphql.mutate(`{ 
          generateFilestackSecurityUpload {
            policy
            signature
          } 
        }`);

    return data.generateFilestackSecurityUpload;
  };
}

function generateReadPolicies(handle) {
  return async (dispatch, getState, { graphql }) => {
    const data = await graphql.mutate(
      `(
          $handle: String!
        ) {
          generateFilestackSecurityRead(
            handle: $handle
          ) {
            policy
            signature
          }
        }`,
      { handle }
    );

    return data.generateFilestackSecurityRead;
  };
}

export function presignedFilestackURL(filestackURL) {
  return async (dispatch) => {
    if (!filestackURL || !filestackURL.includes('filestackcontent.com')) {
      return filestackURL;
    }

    try {
      const url = new URL(filestackURL);

      // get resource id
      const handle = url.pathname.split('/').pop();
      const { policy, signature } = await dispatch(
        generateReadPolicies(handle)
      );

      return `${url.origin}${url.pathname}?policy=${policy}&signature=${signature}`;
    } catch (e) {
      return filestackURL;
    }
  };
}

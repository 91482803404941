import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm, Field } from 'redux-form';
import cx from 'classnames';
import { Button as MaterialButton } from '@mui/material';
import { TextField } from '../FormAdapters';
import Dialog from '../Dialog';
import Form from '../Form';
import LongText from '../LongText';
import CircularProgress from '../CircularProgress';
import {
  saveTemplate,
  deleteTemplate,
  fetchTemplates,
  cachedTemplates,
} from '../../actions/messageTemplate';
import { notify } from '../../actions/ui';
import s from './MessageTemplatesDialog.module.scss';
import Button from '../Button/Button';

const templateExample = {
  title: 'example: “Follow Up',
  body: '[field: expert.full_name], thank you very much for signing up!',
};

class MessageTemplateEditDialog extends Component {
  handleCancel = () => {
    this.props.reset();
    this.props.onClose();
  };

  handleSubmit = (values) => {
    this.props.saveTemplate(values);
    this.props.reset();
    this.props.onClose();
  };

  render() {
    const { onClose, open, template } = this.props;
    const handleSubmit = this.props.handleSubmit(this.handleSubmit);
    return (
      <Dialog
        maxWidth="md"
        open={open}
        onClose={onClose}
        title={!template ? 'Save New Message' : 'Edit Saved Message'}
        bodyStyle={{ overflowY: 'scroll' }}
      >
        <Form onSubmit={handleSubmit} onReset={this.handleCancel}>
          <Field
            id="editMessageTemplateTitle"
            component={TextField}
            name="title"
            label="Title"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            placeholder={templateExample.title}
          />
          <Field
            id="editMessageTemplateBody"
            component={TextField}
            name="body"
            label="Message"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            multiline
            placeholder={templateExample.body}
          />
          <div className={s.actions}>
            <MaterialButton onClick={this.handleCancel}>Cancel</MaterialButton>
            <Button color="secondary" onClick={handleSubmit} size="small">
              Save
            </Button>
          </div>
        </Form>
      </Dialog>
    );
  }
}

MessageTemplateEditDialog = reduxForm({
  form: 'editMessageTemplate',
  validate: (values) => {
    const errors = {};
    if (!values.title || !values.title.trim()) {
      errors.title = 'Title required';
    }
    if (!values.body || !values.body.trim()) {
      errors.body = 'Message required';
    }
    return errors;
  },
  enableReinitialize: true,
})(MessageTemplateEditDialog);

const editFormselector = formValueSelector('editMessageTemplate');
MessageTemplateEditDialog = connect(
  (state, { template }) => ({
    initialValues: template || {},
    templateBody: editFormselector(state, 'body'), // trigger dialog resize
  }),
  {
    saveTemplate,
  }
)(MessageTemplateEditDialog);

function Template({ template, onClick, onEdit, onDelete }) {
  onClick = onClick || null;
  const { body, title } = template || {
    title: 'Empty Message',
    body: 'Type your message',
  };
  return (
    <div
      className={cx(s.template, onClick && s.templateClickable)}
      onClick={onClick}
      onKeyPress={onClick}
    >
      <div className={s.templateTitle}>{title}</div>
      <div>
        <LongText
          className={cx({ [s.templateEmptyBody]: !template }, s.templateBody)}
          text={body}
        />
      </div>
      {onEdit && onDelete && (
        <div>
          <Button
            className={s.templateAction}
            type="submit"
            size="small"
            onClick={onEdit}
          >
            Edit
          </Button>
          <Button
            className={s.templateAction}
            type="submit"
            size="small"
            onClick={onDelete}
          >
            Delete
          </Button>
        </div>
      )}
    </div>
  );
}

class MessageTemplatesDialog extends Component {
  state = {
    editingTemplateOpen: false,
    editingTemplate: null,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { senderId, requestId, expertId } = this.props;
    if (!this.props.open && nextProps.open) {
      this.fetchTemplates({ senderId, requestId, expertId });
    }
  }

  fetchTemplates = async ({ senderId, requestId, expertId }) => {
    const { fetchTemplates, notify } = this.props;
    try {
      await fetchTemplates({ senderId, requestId, expertId });
    } catch (e) {
      notify(`Error loading templates: ${e}`, 'error');
    }
  };

  handleEditTemplate = (template) => {
    this.setState({
      editingTemplate: template,
      editingTemplateOpen: true,
    });
  };

  handleDeleteTemplate = (template) => {
    this.props.deleteTemplate(template.id);
  };

  handleClose = () => {
    this.setState({ listEditMode: false });
    this.props.onClose();
  };

  render() {
    const { onSelectTemplate, open, templates } = this.props;

    const { editingTemplateOpen, editingTemplate, listEditMode } = this.state;

    return (
      <div>
        <Dialog
          maxWidth="md"
          open={open && !editingTemplateOpen}
          title="Choose Message"
          onClose={this.handleClose}
        >
          {!templates || templates.loading ? (
            <CircularProgress />
          ) : !templates.templates || !templates.templates.length ? (
            <div className={s.templatesList}>No templates</div>
          ) : (
            <div className={s.templatesList}>
              <Template onClick={!listEditMode && (() => onSelectTemplate())} />
              {templates.templates.map((template) => (
                <Template
                  key={template.id}
                  template={template}
                  onClick={!listEditMode && (() => onSelectTemplate(template))}
                  onEdit={
                    listEditMode && (() => this.handleEditTemplate(template))
                  }
                  onDelete={
                    listEditMode && (() => this.handleDeleteTemplate(template))
                  }
                />
              ))}
            </div>
          )}
          {templates && !templates.loading && (
            <div>
              {!listEditMode && (
                <Button
                  id="log-in-button"
                  type="submit"
                  size="small"
                  onClick={() => this.handleEditTemplate(null)}
                  fullWidth
                >
                  Save New Message
                </Button>
              )}
              {listEditMode ? (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    type="submit"
                    size="small"
                    onClick={() => this.setState({ listEditMode: false })}
                  >
                    Done
                  </Button>
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: 10,
                  }}
                >
                  <MaterialButton
                    color="secondary"
                    onClick={() => this.setState({ listEditMode: true })}
                  >
                    Edit Saved Messages
                  </MaterialButton>
                  <MaterialButton onClick={this.handleClose}>
                    {'Close'}{' '}
                  </MaterialButton>
                </div>
              )}
            </div>
          )}
        </Dialog>
        <MessageTemplateEditDialog
          open={editingTemplateOpen}
          template={editingTemplate}
          onClose={() => this.setState({ editingTemplateOpen: false })}
        />
      </div>
    );
  }
}

MessageTemplatesDialog = connect(
  (state) => ({
    templates: cachedTemplates(state),
  }),
  {
    deleteTemplate,
    fetchTemplates,
    notify,
  }
)(MessageTemplatesDialog);

MessageTemplatesDialog = MessageTemplatesDialog;

export default MessageTemplatesDialog;

import React from 'react';
import Signup from '../Signup';
import Login from '../Login';
import LayoutPage from '../Layout/LayoutPage';
import s from './AuthPage.module.scss';

class AuthPage extends React.Component {
  render() {
    const { signup, children, tags, ...other } = this.props;

    return (
      <LayoutPage className={s.root}>
        {signup ? (
          <Signup tags={tags} {...other}>
            {children}
          </Signup>
        ) : (
          <Login {...other}>{children}</Login>
        )}
      </LayoutPage>
    );
  }
}

AuthPage = AuthPage;

export default AuthPage;

import { promisify } from 'es6-promisify';
import ActionTypes from '../core/ActionTypes';
import { fetchUser } from './user';
import { isNotAnonymous, getChannelId } from '../core/messaging';

const {
  MESSAGING__CHANNELS_LOADING,
  MESSAGING__CHANNELS_LOADED,
  MESSAGING__FETCH_CHANNELS,
  MESSAGING__FETCH_UNREAD_CHANNELS,
  MESSAGING__MESSAGES_LOADING,
  MESSAGING__MESSAGES_LOADED,
  MESSAGING__FETCH_NEW_MESSAGES,
  MESSAGING__FETCH_PREVIOUS_MESSAGES,
  MESSAGING__FETCH_UNREAD_COUNT,
  MESSAGING__EVENT_UNREAD_COUNT,
  MESSAGING__FETCH_CHANNEL,
  MESSAGING__ADD_MESSAGE,
  MESSAGING__MESSAGE_SENDING,
  MESSAGING__MESSAGE_SENT,
  MESSAGING__OFFLINE,
  MESSAGING__UPDATE_TEXT_INPUT,
  MESSAGING__CHANNEL_CREATING,
  MESSAGING__CHANNEL_CREATED,
  MESSAGING__CHANNEL_FETCHING,
  MESSAGING__CHANNEL_FETCHED,
  MESSAGING__DELETE_CHANNEL,
} = ActionTypes;

const NOT_FOUND = 400201;

export const messagingUnreadCount = Object.freeze({
  service: 'messaging',
  queue: 'unread_messages',
});

export function createChannel(userIds, anonymous) {
  return async (dispatch, getState, { graphql }) => {
    dispatch({ type: MESSAGING__CHANNEL_CREATING });

    try {
      const result = await graphql.mutate(
        `(
        $userIds: [String!]
        $anonymous: Boolean
      ) {
        createMessagingChannel(
          user_ids: $userIds
          anonymous: $anonymous
        ) {
          url
        }
      }`,
        { userIds, anonymous }
      );

      return getChannelId(result.createMessagingChannel.url);
    } finally {
      dispatch({ type: MESSAGING__CHANNEL_CREATED });
    }
  };
}

export function saveMessagingAttachment(channelURL, fileURL) {
  return async (dispatch, getState, { graphql }) => {
    const result = await graphql.mutate(
      `(
        $channel_url: String!
        $file_url: String!
      ) {
        saveMessagingAttachment(
          channel_url: $channel_url
          file_url: $file_url
        )
      }`,
      { channel_url: channelURL, file_url: fileURL }
    );

    return result.saveMessagingAttachment;
  };
}

async function fetchChannelsFromQuery(dispatch, viewer, query, action) {
  const rawChannels = await promisify(query.next.bind(query))();

  await Promise.all(
    rawChannels.map(async (c) => {
      c.metadata = await promisify(c.getAllMetaData.bind(c))();
    })
  );

  const channels = rawChannels.map((raw) => ({ raw }));

  const usernames = rawChannels
    .map((c) => getMemberNicknames(c, viewer))
    .reduce((acc, usernames) => [...acc, ...usernames], []);

  await Promise.all(usernames.map((u) => dispatch(fetchUser(u))));

  dispatch({ ...action, channels });

  rawChannels.forEach((c) => {
    if (c.lastMessage) {
      dispatch({
        type: MESSAGING__ADD_MESSAGE,
        channelUrl: c.url,
        message: c.lastMessage,
        keepChannelPosition: true,
      });
    }
  });

  return channels;
}

export function fetchChannels() {
  return async (dispatch, getState, { sendBird }) => {
    const { messaging, viewer } = getState();

    if (!sendBird || messaging.loadingChannels) return messaging.channels;

    dispatch({ type: MESSAGING__CHANNELS_LOADING });

    try {
      const session = await sendBird.createSession();
      if (!session) {
        dispatch({ type: MESSAGING__OFFLINE });
        return;
      }

      const query =
        messaging.channels.length > 0 && messaging.query
          ? messaging.query
          : session.GroupChannel.createMyGroupChannelListQuery();
      query.includeEmpty = false;
      query.limit = 20;
      query.order = 'latest_last_message';

      if (!query.hasNext) return messaging.channels;

      return await fetchChannelsFromQuery(dispatch, viewer, query, {
        type: MESSAGING__FETCH_CHANNELS,
        query,
      });
    } catch (err) {
      dispatch({ type: MESSAGING__OFFLINE });
      throw err;
    } finally {
      dispatch({ type: MESSAGING__CHANNELS_LOADED });
    }
  };
}

export function deleteChannel(channelUrl) {
  return { type: MESSAGING__DELETE_CHANNEL, channelUrl };
}

export function fetchChannel(channelUrl, refresh = false) {
  return async (dispatch, getState, { sendBird }) => {
    const { messaging, viewer } = getState();

    let channel = messaging.channelIndex[channelUrl];

    if (channel && !refresh) return channel;

    if (!sendBird) return;

    dispatch({ type: MESSAGING__CHANNEL_FETCHING });

    try {
      const session = await sendBird.createSession();
      if (!session) {
        dispatch({ type: MESSAGING__OFFLINE });
        return;
      }

      const rawChannel = channel
        ? await promisify(channel.raw.refresh.bind(channel.raw))()
        : await promisify(
            session.GroupChannel.getChannel.bind(session.GroupChannel)
          )(channelUrl);

      rawChannel.metadata = await promisify(
        rawChannel.getAllMetaData.bind(rawChannel)
      )();

      channel = { raw: rawChannel };

      const usernames = getMemberNicknames(rawChannel, viewer);
      if (usernames.length > 0) {
        await Promise.all(usernames.map((u) => dispatch(fetchUser(u))));
      }

      dispatch({ type: MESSAGING__FETCH_CHANNEL, channel });
      return channel;
    } catch (err) {
      if (err.code === NOT_FOUND) return;
      dispatch({ type: MESSAGING__OFFLINE });
      throw err;
    } finally {
      dispatch({ type: MESSAGING__CHANNEL_FETCHED });
    }
  };
}

export function fetchUnreadCount() {
  return async (dispatch, getState, { sendBird }) => {
    if (!sendBird) return;

    const session = await sendBird.createSession();
    if (!session) {
      dispatch({ type: MESSAGING__OFFLINE });
      return;
    }

    const unreadCount = await promisify(
      session.getTotalUnreadMessageCount.bind(session)
    )();

    dispatch({ type: MESSAGING__FETCH_UNREAD_COUNT, unreadCount });
    return unreadCount;
  };
}

export function fetchUnreadChannels() {
  return async (dispatch, getState, { sendBird }) => {
    const { messaging, viewer } = getState();

    if (!sendBird || messaging.loadingChannels) return messaging.channels;

    try {
      const session = await sendBird.createSession();
      if (!session) {
        dispatch({ type: MESSAGING__OFFLINE });
        return;
      }

      const query = session.GroupChannel.createMyGroupChannelListQuery();
      query.includeEmpty = false;
      query.unreadChannelFilter = 'unread_message';
      query.limit = 20;
      query.order = 'latest_last_message';

      if (!query.hasNext) {
        return messaging.channels;
      }

      return fetchChannelsFromQuery(dispatch, viewer, query, {
        type: MESSAGING__FETCH_UNREAD_CHANNELS,
      });
    } catch (err) {
      dispatch({ type: MESSAGING__OFFLINE });
      throw err;
    }
  };
}

export function sendFileMessage(channelUrl, url, name, type, size) {
  return async (dispatch, getState) => {
    const { messaging } = getState();

    const channel = messaging.channelIndex[channelUrl];
    if (!channel) throw new Error(`channel not found ${channelUrl}`);

    dispatch({ type: MESSAGING__MESSAGE_SENDING });

    try {
      const message = await promisify(
        channel.raw.sendFileMessage.bind(channel.raw)
      )(url, name, type, size, null, null);
      dispatch({ type: MESSAGING__ADD_MESSAGE, channelUrl, message });
      return message;
    } finally {
      dispatch({ type: MESSAGING__MESSAGE_SENT });
    }
  };
}

export function sendMessage(channelUrl, textInput) {
  return async (dispatch, getState) => {
    const { messaging } = getState();

    const channel = messaging.channelIndex[channelUrl];
    if (!channel) throw new Error(`channel not found ${channelUrl}`);

    dispatch({ type: MESSAGING__MESSAGE_SENDING });

    if (!textInput) {
      textInput = messaging.messages[channelUrl].textInput;
    }

    try {
      const message = await promisify(
        channel.raw.sendUserMessage.bind(channel.raw)
      )(textInput);
      dispatch({ type: MESSAGING__ADD_MESSAGE, channelUrl, message });
      return message;
    } finally {
      dispatch({ type: MESSAGING__MESSAGE_SENT });
    }
  };
}

export function markChannelAsRead(channelUrl) {
  return async (dispatch, getState, { sendBird }) => {
    if (!sendBird) return;

    const session = await sendBird.createSession();
    if (!session) {
      dispatch({ type: MESSAGING__OFFLINE });
      return;
    }

    const { messaging } = getState();
    const channel = messaging.channelIndex[channelUrl];
    if (!channel) throw new Error(`channel not found ${channelUrl}`);

    await promisify(session.markAsReadWithChannelUrls.bind(session))([
      channelUrl,
    ]);
    dispatch({ type: MESSAGING__FETCH_CHANNEL, channel });
  };
}

export function addMessage(channelUrl, message) {
  return { type: MESSAGING__ADD_MESSAGE, channelUrl, message };
}

function fetchMessages(channelUrl, reuseQuery, type) {
  return async (dispatch, getState) => {
    const { messaging } = getState();

    const channel = messaging.channelIndex[channelUrl];
    if (!channel) throw new Error(`channel not found ${channelUrl}`);

    const channelMessages = messaging.messages[channelUrl] || {};

    const query =
      (reuseQuery && channelMessages.query) ||
      channel.raw.createPreviousMessageListQuery();
    if (query.isLoading || !query.hasMore) return;

    dispatch({ type: MESSAGING__MESSAGES_LOADING, channelUrl });

    try {
      const messages = await promisify(query.load.bind(query))(20, false);
      dispatch({
        type,
        channelUrl,
        messages,
        query,
      });
      return messages;
    } catch (err) {
      throw err;
    } finally {
      dispatch({ type: MESSAGING__MESSAGES_LOADED, channelUrl });
    }
  };
}

export function fetchPreviousMessages(channelUrl) {
  return fetchMessages(channelUrl, true, MESSAGING__FETCH_PREVIOUS_MESSAGES);
}

export function fetchLastMessages(channelUrl) {
  return fetchMessages(channelUrl, false, MESSAGING__FETCH_NEW_MESSAGES);
}

export function prefetchFirstChannel() {
  return async (dispatch, getState, { sendBird }) => {
    const { messaging } = getState();
    if (
      messaging.loadingChannels === false &&
      messaging.loadedChannels === false
    ) {
      const isActive = await sendBird.isActiveUser();
      if (isActive) {
        const channels = await dispatch(fetchChannels());
        const firstChannel = channels && channels[0];
        if (firstChannel) {
          await dispatch(fetchPreviousMessages(firstChannel.raw.url));
        }
      }
    }
  };
}

export function updateTextInput(channelUrl, textInput) {
  return { type: MESSAGING__UPDATE_TEXT_INPUT, channelUrl, textInput };
}

export function updateUnreadCount(count) {
  return async (dispatch) => {
    dispatch({
      type: MESSAGING__EVENT_UNREAD_COUNT,
      unreadCount: count,
    });
  };
}

function getMemberNicknames(c, viewer) {
  // do not include anonymous users
  return c.members
    .filter((m) => m.userId !== viewer.username && isNotAnonymous(c, m.userId))
    .map((m) => m.nickname);
}

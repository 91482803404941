import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Chip } from '@mui/material';
import ChipList from '../ChipList';
import Link from '../Link';
import { highlight } from '../../core/util';

class SectorList extends PureComponent {
  render() {
    const {
      sectors,
      children,
      allSectors,
      lookup,
      icon = 'factory',
      ...other
    } = this.props;

    return (
      <ChipList {...other} icon={icon}>
        {sectors.map((key) => {
          let name = key;
          if (lookup) {
            const sector = allSectors.find((s) => s.id === key);
            if (sector) name = sector.name;
          }

          return (
            <Link key={key} href={`/search?q=${encodeURIComponent(name)}`}>
              <Chip
                style={{
                  cursor: 'pointer',
                  width: 'initial',
                  label: { overflow: 'hidden', textOverflow: 'ellipsis' },
                }}
                label={
                  <span dangerouslySetInnerHTML={{ __html: highlight(name) }} />
                }
              />
            </Link>
          );
        })}
      </ChipList>
    );
  }
}

SectorList = connect((state) => ({
  allSectors: state.sectors.all,
}))(SectorList);

export default SectorList;

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import Form from '../../Form';
import { updateProfile } from '../../../actions/profile';
import { Checkbox } from '../../FormAdapters/FormAdapters';

class AvailableMarketplaceForm extends PureComponent {
  static defaultProps = {
    component: Form,
  };

  handleSubmit = async (values) => {
    const { profileId, updateProfile, onSubmit } = this.props;
    await updateProfile({ id: profileId, ...values });

    if (onSubmit) {
      onSubmit(values);
    }
  };

  handleReset = () => {
    const { reset, onReset } = this.props;
    reset();

    if (onReset) {
      onReset();
    }
  };

  render() {
    const { component: Container, handleSubmit, ...other } = this.props;

    return (
      <Container
        {...other}
        onSubmit={handleSubmit(this.handleSubmit)}
        onReset={this.handleReset}
      >
        <Field
          type="checkbox"
          component={Checkbox}
          name="available_marketplace"
          label="Available for marketplace projects"
        />
      </Container>
    );
  }
}

AvailableMarketplaceForm = reduxForm({
  form: 'expertAvailableMarketplace',
  enableReinitialize: true,
})(AvailableMarketplaceForm);

AvailableMarketplaceForm = connect(
  (state, ownProps) => {
    const profile = state.profiles.fullProfiles[ownProps.profileId];
    return {
      initialValues: {
        available_marketplace: profile.available_marketplace,
      },
    };
  },
  {
    updateProfile,
  }
)(AvailableMarketplaceForm);

export default AvailableMarketplaceForm;

import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import queryString from 'query-string';
import Button from '../Button';
import Dialog from '../Dialog';

const useStyles = makeStyles({
  root: {
    textAlign: 'center',
  },

  title: {
    fontWeight: 'normal',
    marginTop: 10,
  },

  signin: {
    marginTop: 10,
  },
});

function SignupRoadblock({ nextUrl }) {
  const s = useStyles();

  return (
    <Dialog classes={{ root: s.root }} open>
      <h4 className={s.title}>
        View this expert’s profile and thousands of others.
        <br />
        It’s free to sign up and only takes 15 seconds!
      </h4>
      <div className={s.actions}>
        <Button
          size="huge"
          color="lightTan"
          href={`/signup/member?${queryString.stringify({
            next: nextUrl,
            method: 'email',
          })}`}
        >
          Sign up with email
        </Button>
      </div>
      <div className={s.signin}>
        <Button
          variant="text"
          href={`/login?${queryString.stringify({
            next: nextUrl,
          })}`}
        >
          Log in →
        </Button>
      </div>
    </Dialog>
  );
}

export default SignupRoadblock;

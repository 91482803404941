import React, { Component } from 'react';
import { connect } from 'react-redux';
import Body from '../../components/Body';
import Button from '../../components/Button/Button';
import { postToProps } from '../../components/Blog/BlogPostPreview';
import BlogPostPreviewList from '../../components/Blog/BlogPostPreviewList';
import { fetchPosts } from '../../actions/blog';
import s from './BlogList.module.scss';

class Blog extends Component {
  loadPosts = () => {
    const { fetchPosts, collection, query, posts } = this.props;
    fetchPosts(collection, {
      offset: posts.length,
      per_page: 6,
      _embed: 1,
      ...query,
    });
  };

  render() {
    const { title, posts, fetching, hasMorePosts } = this.props;

    return (
      <div className={s.root}>
        <Body>
          <div className={s.title}>{title}</div>
          <BlogPostPreviewList posts={posts.map(postToProps)} />
        </Body>

        <div className={s.action}>
          {hasMorePosts && (
            <Button onClick={this.loadPosts} disabled={fetching}>
              Load More Posts
            </Button>
          )}
        </div>
      </div>
    );
  }
}

export default connect(
  (state, ownProps) => state.blog.posts[ownProps.collection] || {},
  {
    fetchPosts,
  }
)(Blog);

import React from 'react';
import s from './SplashTitle.module.scss';

function SplashTitle({ children, ...other }) {
  return (
    <h1 className={s.root} {...other}>
      {children}
    </h1>
  );
}

export default SplashTitle;

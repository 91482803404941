import React from 'react';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  root: {
    minWidth: 'auto',
    padding: '4px 8px',
    fontSize: 12,
  },
});

export default function (props) {
  const classes = useStyles();

  return (
    <Button
      classes={classes}
      variant="contained"
      color="secondary"
      disableElevation
      {...props}
    >
      Add
    </Button>
  );
}

import React, { Fragment, useCallback } from 'react';
import FilterChips from './FilterChips';
import FilterAutocomplete from './FilterAutocomplete';
import FilterSelect from './FilterSelect';

export default function ({
  placeholder,
  options,
  value,

  autocomplete,
  onAddValue,
  onRemoveValue,

  disableUnderline,
  multiple,
  onChange,
}) {
  const handleAdd = useCallback((e, newValue) => {
    onAddValue(newValue.value);
  }, []);

  if (autocomplete) {
    const selectedOpts = options.filter((o) => value.includes(o.value));
    const availableOpts = options.filter((o) => !value.includes(o.value));

    return (
      <Fragment>
        <FilterAutocomplete
          onChange={handleAdd}
          placeholder={placeholder}
          options={availableOpts}
          value={value}
          disableUnderline={disableUnderline}
        />
        <FilterChips
          options={selectedOpts}
          onRemoveValue={(o) => onRemoveValue(o.value)}
        />
      </Fragment>
    );
  }

  return (
    <FilterSelect
      multiple={multiple}
      onChange={onChange}
      placeholder={placeholder}
      options={options}
      value={value}
    />
  );
}

export function add(list, item, condition) {
  if (list == null) {
    throw Error('add - list cannot be null');
  }

  return condition && list.some(condition) ? list : [...list, item];
}

export function filter(list, condition) {
  if (list == null) {
    throw Error('filter - list cannot be null');
  }

  const newList = list.filter(condition);
  return list.length === newList.length ? list : newList;
}

export function removeAt(list, index) {
  if (list == null || index == null) {
    throw Error('removeAt - list or index cannot be null');
  }

  if (index < 0) return list;

  return [...list.slice(0, index), ...list.slice(index + 1, list.length)];
}

export function removeAttribute(object, attribute) {
  const newObject = Object.assign({}, object);
  delete newObject[attribute];
  return newObject;
}

export function updateAt(list, index, item) {
  if (list == null || index == null) {
    throw Error('updateAt - list or index cannot be null');
  }

  if (index < 0) return list;

  return [...list.slice(0, index), item, ...list.slice(index + 1, list.length)];
}

export function mergeAt(list, index, item) {
  if (list == null || index == null) {
    throw Error('mergeAt - list or index cannot be null');
  }

  if (index < 0) return list;

  const cur = list[index];
  return updateAt(list, index, { ...cur, ...item });
}

export function isValidURL(rawUrl = '') {
  return (
    rawUrl && (rawUrl.startsWith('http://') || rawUrl.startsWith('https://'))
  );
}

export function isValidLinkedin(linkedinUrl) {
  // URL accepts invalid values such as http:/linkedin.com
  if (!isValidURL(linkedinUrl)) {
    return false;
  }

  try {
    const url = new URL(linkedinUrl);
    return url.hostname.endsWith('linkedin.com');
  } catch (e) {
    return false;
  }
}

export function encodeFilestackURL(url) {
  const { origin } = window.location;
  const filestack = new URL(url);

  return `${origin}/attachment${filestack.pathname}`;
}

export function getJoinTitle(domain) {
  if (domain) {
    return (
      domain.network_join_title ||
      `Join the ${domain.group.name} Knowledge Network`
    );
  }
  return 'Join the OnFrontiers Expert Network';
}

const onlyLettersRegex = /[A-zÀ-ÖØ-öø-ÿ ]+$/;

export function hasOnlyLetters(s) {
  return onlyLettersRegex.test(s);
}

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, SubmissionError } from 'redux-form';
import { Button as MaterialButton } from '@mui/material';
import { requestPasswordReset } from '../../actions/auth';
import { notify } from '../../actions/ui';
import WizardPage from '../../components/WizardPage';
import WizardFooter from '../../components/WizardPage/WizardFooter';
import Link from '../../components/Link';
import Form from '../../components/Form';
import { TextField } from '../../components/FormAdapters/FormAdapters';

function validate(values) {
  const errors = {};

  if (!values.email) {
    errors.email = 'Required';
  }

  return errors;
}

const errDoesNotExist = 'user does not exist';

class RequestPasswordReset extends PureComponent {
  state = {
    done: false,
  };

  handleSubmit = (values) => {
    const { requestPasswordReset, notify } = this.props;
    return requestPasswordReset(values.email)
      .then(() => this.setState({ done: true }))
      .catch((err) => {
        if (
          err &&
          err.rawError &&
          err.rawError[0] &&
          err.rawError[0].message === errDoesNotExist
        ) {
          throw new SubmissionError({
            email: 'There is no user with this email address',
          });
        } else {
          notify('An error occurred when trying to reset password.', 'error');
        }
      });
  };

  render() {
    const { handleSubmit } = this.props;
    const { done } = this.state;

    if (done) {
      return (
        <div>
          <h3>
            Password reset email sent! You should receive it within a few
            minutes.
          </h3>
          <Link href="/login">
            <MaterialButton>← Return to Login</MaterialButton>
          </Link>
        </div>
      );
    }

    return (
      <WizardPage
        title="Reset your password"
        subTitle="We'll email you a link to create a new password."
      >
        <Form onSubmit={handleSubmit(this.handleSubmit)}>
          <Field
            name="email"
            component={TextField}
            autoComplete="email"
            label="Email address"
            type="email"
            style={{ width: 300 }}
          />
          <WizardFooter nextLabel="Reset Password" />
        </Form>
      </WizardPage>
    );
  }
}

RequestPasswordReset = reduxForm({
  form: 'requestPasswordReset',
  validate,
})(RequestPasswordReset);

RequestPasswordReset = connect(undefined, {
  requestPasswordReset,
  notify,
})(RequestPasswordReset);

export default RequestPasswordReset;

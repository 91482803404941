import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import ExperienceFilter from '../ExperienceFilter';
import SelectFilter from '../SelectFilter';

function AdvancedFilters({
  groups,
  query,
  handleFilterAdd,
  handleFilterRemove,
  handleFilterEvent,
  viewer,
}) {
  const consultationHistoryOptions = useMemo(() => {
    const groupOptions = groups
      .filter((g) => viewer.admin || g.internal_network)
      .map((g) => ({
        value: g.id,
        label: `with ${g.name}`,
      }));

    return [{ value: 'me', label: 'with me' }, ...groupOptions];
  }, [groups, viewer]);

  const networkOptions = useMemo(() => {
    return groups
      .map((g) => g.internal_network)
      .filter(Boolean)
      .map((n) => ({ label: n.name, value: n.id }));
  }, [groups]);

  return (
    <>
      <ExperienceFilter
        title="Organizations"
        placeholder="Enter organization"
        currentRestriction={query.organizationsCurrentRestriction}
        values={query.organizations}
        onCurrentRestrictionChange={(e) =>
          handleFilterEvent('organizationsCurrentRestriction', e)
        }
        onAddValue={(v) => handleFilterAdd('organizations', v)}
        onRemoveValue={(v) => handleFilterRemove('organizations', v)}
      />

      <SelectFilter
        title="Consultation History"
        placeholder="Select teams or 'with me'"
        value={query.consultationsWith || []}
        options={consultationHistoryOptions}
        autocomplete={consultationHistoryOptions.length > 5}
        onAddValue={(v) => handleFilterAdd('consultationsWith', v)}
        onRemoveValue={(v) => handleFilterRemove('consultationsWith', v)}
        multiple
        onChange={(e) => handleFilterEvent('consultationsWith', e)}
      />

      <SelectFilter
        title="Expert Registration"
        value={query.profileType}
        onChange={(e) => handleFilterEvent('profileType', e)}
        options={[
          { value: '', label: 'Registered and Unregistered' },
          { value: 'user', label: 'Registered only' },
          { value: 'lead', label: 'Unregistered only' },
        ]}
      />

      <SelectFilter
        title="Network"
        placeholder="Search teams"
        value={query.networkIds}
        options={networkOptions}
        autocomplete
        onAddValue={(v) => handleFilterAdd('networkIds', v)}
        onRemoveValue={(v) => handleFilterRemove('networkIds', v)}
      />
    </>
  );
}

export default connect((state) => ({
  groups: ((state.groups.networks || {}).edges || []).map((g) => g.node),
}))(AdvancedFilters);

import React from 'react';
import PropTypes from 'prop-types';
import WizardPage from '../../components/WizardPage';
import s from './ComplianceTrainingStep.module.scss';
import { safeHtml } from '../../core/util';

class ComplianceTrainingStep extends React.Component {
  static propTypes = {
    html: PropTypes.string.isRequired,
    onNext: PropTypes.func.isRequired,
  };

  render() {
    const { html, onNext } = this.props;

    return (
      <WizardPage title="Expert Compliance Training" onNext={onNext}>
        <div
          className={s.root}
          dangerouslySetInnerHTML={{ __html: safeHtml(html) }}
        />
      </WizardPage>
    );
  }
}

export default ComplianceTrainingStep;

import React from 'react';

import Link from '../../../components/Link';
import FAIcon from '../../../components/Icon/FAIcon';
import Message from '../../../components/Message';
import { highlight } from '../../../core/util';
import { darkGreen } from '../../../core/colors';
import s from './NetworkExperts.module.scss';

function NetworkExpertAddEvent({ expert, networkExpert }) {
  const {
    created_by: creator,
    add_note_snippets: noteSnippets,
    network,
  } = networkExpert;

  return (
    <div className={s.addEvent}>
      <div>
        <FAIcon
          size={16}
          icon="chart-network"
          style={{ color: darkGreen, marginRight: 5 }}
        />
      </div>
      <div>
        <div>
          <Link className={s.expertLink} to={expert && expert.html_url}>
            {expert ? expert.name : 'Expert'}
          </Link>
          &nbsp; added to&nbsp;
          {network && network.group ? (
            <Link to={`/search?networks[]=${network.id}`}>
              {network.group.name}&rsquo;s network
            </Link>
          ) : (
            'a network of experts'
          )}
        </div>
        <Message
          className={s.message}
          bubbleClassName={s.bubble}
          user={creator}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: highlight(`(…) ${noteSnippets.join(' (…) ')}…`, {
                multiline: true,
              }),
            }}
          />
        </Message>
      </div>
    </div>
  );
}

function NetworkExperts({ expert, networkExperts }) {
  if (!networkExperts || !networkExperts.length) return null;

  return (
    <div className={s.networkExperts}>
      {networkExperts.map((ne) => (
        <NetworkExpertAddEvent key={ne.id} expert={expert} networkExpert={ne} />
      ))}
    </div>
  );
}

export default NetworkExperts;

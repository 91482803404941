import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Fab as FloatingActionButton } from '@mui/material';
import Button from '../../components/Button/Button';
import MaterialIcon from '../../components/Icon/MaterialIcon';
import ProjectPreview from '../../components/ProjectPreview';
import EmptyMessage from '../../components/EmptyMessage';
import ExpertApplicationPromo from './ExpertApplicationPromo';
import CircularProgress from '../../components/CircularProgress';
import TeamAccountPromoButton from '../../components/TeamAccountPromo/TeamAccountPromoButton';
import { darkBlue } from '../../core/colors';
import s from './Projects.module.scss';

function ExpertRequestPromo() {
  const body = (
    <span>
      Have a difficult question and no time for research?
      <br />
      Tell us about it and start talking to a local Expert.
    </span>
  );

  return (
    <EmptyMessage
      icon={<MaterialIcon icon="person_add" color={darkBlue} size={60} />}
      title="You don't have any expert requests"
      body={body}
      action={
        <TeamAccountPromoButton
          component={Button}
          label="New Expert Request"
          size="large"
          primary
        />
      }
    />
  );
}

class Projects extends Component {
  render() {
    const { projects, isViewerApplying } = this.props;

    const filteredPrjs = (projects.edges || [])
      // filter out closed exp reqs
      .map((p) => ({
        ...p,
        node: {
          ...p.node,
          expert_requests: (p.node.expert_requests || []).filter(
            (er) => er.state !== 'closed'
          ),
        },
      }))
      // filter out empty projects
      .filter((p) => (p.node.expert_requests || []).length > 0)
      .map((p) => p.node);

    return (
      <div>
        <div className={s.header}>
          <h3 className={s.title}>Expert Requests</h3>
          <TeamAccountPromoButton component={FloatingActionButton}>
            <MaterialIcon icon="add" />
          </TeamAccountPromoButton>
        </div>

        {filteredPrjs.map((p) => (
          <ProjectPreview
            key={p.id}
            project={p}
            showState={false}
            showContextActions
          />
        ))}

        {projects.loading && (
          <div className={s.loading}>
            <CircularProgress />
          </div>
        )}

        {!projects.loading &&
          filteredPrjs.length === 0 &&
          (isViewerApplying ? (
            <ExpertApplicationPromo />
          ) : (
            <ExpertRequestPromo />
          ))}
      </div>
    );
  }
}

export default connect((state) => ({
  projects: state.projects.dashboard,
}))(Projects);

import React from 'react';
import moment from 'moment-timezone';
import MenuItem from '@mui/material/MenuItem';
import IconMenu from '../../components/IconMenu/IconMenu';
import MaterialIcon from '../../components/Icon/MaterialIcon';
import Link from '../../components/Link';
import Duration from '../../core/duration';
import config from '../../../config';
import { formatDuration } from '../../core/util';
import Button from '../../components/Button/Button';
import { darkGreen } from '../../core/colors';

const imgStyle = {
  verticalAlign: 'middle',
  marginRight: 10,
};

const separator = '\n\n--\n\n';

export default function CalendarMenu({
  event,
  expectedDuration,
  consultationId,
}) {
  const duration = Duration.parse(expectedDuration);
  const eventStart =
    event && `${moment(event.start).utc().format('YYYYMMDDTHHmmss')}Z`;
  const eventEnd =
    event && `${moment(event.end).utc().format('YYYYMMDDTHHmmss')}Z`;

  const eventDuration = event && `${formatDuration(duration, '')}`;

  const description = event && encodeURIComponent(event.description);
  const summary = event && encodeURIComponent(event.summary);
  const location = event && encodeURIComponent(event.location);

  // Shorter description for Outlook as it crashes if the URL size is greater than
  // 1369 when the user is not logged in at Microsoft at the time.
  // Also, parenthesis get replace with $.
  let outlookDescription = event && event.description.split(separator)[0];
  if (outlookDescription) {
    outlookDescription = encodeURIComponent(
      `${outlookDescription}${separator}https://try.onfrontiers.com/knowledge/cancellations-no-shows`
    );
  }

  return (
    <IconMenu
      iconElement={
        <Button
          fontColor={darkGreen}
          variant="text"
          startIcon={<MaterialIcon icon="event" />}
        >
          Add to Calendar
        </Button>
      }
    >
      <MenuItem>
        <Link
          target="_blank"
          href={`${config.webUrl}/consultation/${consultationId}/calendar`}
        >
          <img
            style={imgStyle}
            alt=""
            src={require('./calendar/ical.png')}
            srcSet={`${require('./calendar/ical@2x.png')} 2x`}
          />
          iCal
        </Link>
      </MenuItem>

      {event && (
        <MenuItem>
          <Link
            target="_blank"
            href={`http://www.google.com/calendar/event?action=TEMPLATE&dates=${eventStart}%2F${eventEnd}&text=${summary}&location=${location}&details=${description}`}
          >
            <img
              style={imgStyle}
              alt=""
              src={require('./calendar/google.png')}
              srcSet={`${require('./calendar/google@2x.png')} 2x`}
            />
            Google
          </Link>
        </MenuItem>
      )}

      {event && (
        <MenuItem>
          <Link
            target="_blank"
            href={`https://outlook.live.com/owa/?path=/calendar/action/compose&startdt=${eventStart}&enddt=${eventEnd}&subject=${summary}&location=${location}&body=${outlookDescription}`}
          >
            <img
              style={imgStyle}
              alt=""
              src={require('./calendar/outlook.png')}
              srcSet={`${require('./calendar/outlook@2x.png')} 2x`}
            />
            Outlook
          </Link>
        </MenuItem>
      )}

      {event && (
        <MenuItem>
          <Link
            target="_blank"
            href={`https://calendar.yahoo.com/?v=60&VIEW=d&in_loc=${location}&TITLE=${summary}&ST=${eventStart}&DUR=${eventDuration}&DESC=${description}`}
          >
            <img
              style={imgStyle}
              alt=""
              src={require('./calendar/yahoo.png')}
              srcSet={`${require('./calendar/yahoo@2x.png')} 2x`}
            />
            Yahoo!
          </Link>
        </MenuItem>
      )}
    </IconMenu>
  );
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from '../../core/history';
import { completeStep } from '../../actions/expertComplianceTraining';
import Wizard from '../../components/Wizard';

class ComplianceWizard extends Component {
  onNext = () => {
    const { completeStep, nextUrl, user, path } = this.props;

    completeStep(path, user.id);
    history.push(nextUrl);
  };

  render() {
    const { children, ...other } = this.props;
    const page = React.cloneElement(children, { onNext: this.onNext });

    return <Wizard {...other}>{page}</Wizard>;
  }
}

export default connect(null, {
  completeStep,
})(ComplianceWizard);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { openSvgDialog, popup } from '../../actions/ui';
import Button from '../Button';

class SvgUploadButton extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    dimensions: PropTypes.object,
  };

  handleSvgUpload = () => {
    const { dimensions, onChange, openSvgDialog, popup } = this.props;
    const contents = (
      <div>
        SVG files are uploaded without modification to the OnFrontiers platform.
        Additional formatting to image may be necessary to satisfy client and
        OnFrontiers site expectations. Consult OnFrontiers developers and/or
        graphic designer to ensure results are satisfactory.
        {dimensions && (
          <p>
            Expecting aspect ratio for dimensions:{dimensions.width} x{' '}
            {dimensions.height} (Width x Height)
          </p>
        )}
      </div>
    );
    popup({
      title: 'Warning',
      contents,
      buttonAlignment: 'flex-end',
      buttons: [
        {
          label: 'OK',
          callback: () => openSvgDialog().then(onChange),
        },
      ],
    });
  };

  render() {
    return (
      <Button
        variant="text"
        onClick={this.handleSvgUpload}
        style={{ marginTop: 0 }}
        size="small"
      >
        Alternate SVG Upload
      </Button>
    );
  }
}

SvgUploadButton = connect(undefined, {
  openSvgDialog,
  popup,
})(SvgUploadButton);

export default SvgUploadButton;

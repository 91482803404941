import React from 'react';
import IconButton from '@mui/material/IconButton';
import Picture from '../Picture/Picture';
import Link from '../Link';
import FAIcon from '../Icon/FAIcon';
import { red500 } from '../../core/colors';
import s from './MemberRow.module.scss';

function MemberRow({ profile, displayName, onRemove, children }) {
  return (
    <div className={s.root}>
      <Picture size={40} user={profile} openInNewTab />
      {profile ? (
        <Link newTab href={profile.html_url} className={s.name}>
          {displayName}
        </Link>
      ) : (
        <div className={s.name}>{displayName}</div>
      )}
      {children}
      <IconButton onClick={onRemove}>
        <FAIcon color={red500} icon="trash-o" size={20} />
      </IconButton>
    </div>
  );
}

MemberRow = MemberRow;

export default MemberRow;

import ActionTypes from '../core/ActionTypes';
import { getUserContextOptions } from '../core/user';
import { setCache, getFilestackClient } from '../core/util';
import { generateUploadPolicies } from './filestack';

export function notify(message = '', messageType = 'success', duration = 8000) {
  return {
    type: ActionTypes.UI__SHOW_MESSAGE,
    message,
    messageType,
    duration,
  };
}

export function hideMessage() {
  return {
    type: ActionTypes.UI__HIDE_MESSAGE,
  };
}

export function popup({
  layout,
  title,
  contents,
  buttons,
  links,
  contentStyle,
  buttonAlignment,
}) {
  return {
    type: ActionTypes.UI__SHOW_POPUP,
    layout,
    title,
    contents,
    buttons,
    links,
    contentStyle,
    buttonAlignment,
  };
}

export function hidePopup() {
  return {
    type: ActionTypes.UI__HIDE_POPUP,
  };
}

export function setUserContext(userContext) {
  return (dispatch, getState) => {
    if (!userContext) throw new Error('user context cannot be empty');

    const { viewer } = getState();
    const groups = getState().groups.all.edges.map((e) => e.node);
    const userContextOptions = getUserContextOptions(
      viewer,
      userContext,
      groups
    );

    setCache('user_context', userContext);

    dispatch({
      type: ActionTypes.UI__SET_USER_CONTEXT,
      userContext,
      userContextOptions,
    });
  };
}

export function openImageDialog({ dimensions, accept } = {}) {
  const { width, height } = dimensions || {};

  return openFileDialog({
    accept: accept || 'image/*',
    fromSources: [
      'local_file_system',
      'url',
      'imagesearch',
      'facebook',
      'instagram',
      'googledrive',
      'dropbox',
      'box',
      'googlephotos',
      'onedrive',
      'onedriveforbusiness',
      'webcam',
    ],
    transformations: dimensions
      ? {
          crop: {
            aspectRatio: width / height,
            force: true,
          },
        }
      : undefined,
  });
}

export function openPictureDialog() {
  return openImageDialog({ dimensions: { width: 220, height: 220 } });
}

export function openResumeDialog() {
  return openFileDialog({
    accept: ['.doc', '.docx', '.pdf', '.rtf'],
    maxSize: 3 * 1024 * 1024, // 3 MB
    fromSources: [
      'local_file_system',
      'url',
      'googledrive',
      'dropbox',
      'box',
      'onedrive',
      'onedriveforbusiness',
    ],
  });
}

export function openSvgDialog() {
  return openFileDialog({
    accept: ['image/svg+xml'],
    fromSources: [
      'local_file_system',
      'url',
      'googledrive',
      'dropbox',
      'box',
      'onedrive',
      'onedriveforbusiness',
    ],
    transformations: {
      crop: false,
      circle: false,
      rotate: false,
    },
  });
}

export function openFileDialog({
  accept,
  transformations,
  maxSize,
  fromSources,
  notifyError = true,
  ...rest
} = {}) {
  if (!accept) throw new Error('accept must be defined.');

  return async (dispatch) => {
    const data = await dispatch(generateUploadPolicies());

    const client = await getFilestackClient({
      security: data,
    });

    return new Promise((resolve, reject) => {
      client
        .picker({
          accept,
          transformations,
          fromSources: fromSources || [
            'local_file_system',
            'url',
            'googledrive',
            'dropbox',
            'box',
            'onedrive',
            'onedriveforbusiness',
            'audio',
          ],
          maxSize: maxSize || 10 * 1024 * 1024, // defaults to 10 MB
          onUploadDone: (result) => {
            const { filesUploaded: files } = result;
            if (files && files.length > 0) {
              const { maxFiles } = rest;
              if (maxFiles) return resolve(files);
              return resolve(files[0]);
            }
            reject(new Error('File has not been uploaded.'));
          },
          ...rest,
        })
        .open();
    }).catch((err) => {
      if (notifyError) {
        dispatch(notify('An error occurred when uploading the file.', 'error'));
      }
      throw err;
    });
  };
}

import actionTypes from '../core/ActionTypes';

const { SAVED_SEARCHES__FETCH } = actionTypes;

export function fetchGroupSavedSearches({ limit = 10 } = {}) {
  return async (dispatch, __, { graphql }) => {
    const { groupSavedSearches } = await graphql.query(
      `query getGroupSavedSearches(
        $limit: Int!
      ) {
        groupSavedSearches(
          limit: $limit
        ) {
          name
          url
        }
      }`,
      { limit }
    );

    dispatch({
      type: SAVED_SEARCHES__FETCH,
      savedSearches: groupSavedSearches,
    });

    return groupSavedSearches;
  };
}

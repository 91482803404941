import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import Form from '../Form';
import Button from '../Button';
import SelectUser from '../SelectUser';
import ProjectMember from './ProjectMember';
import {
  addProjectMember,
  updateProjectMember,
  removeProjectMember,
} from '../../actions/project';
import { notify } from '../../actions/ui';
import { inviteUser } from '../../actions/invitation';
import { removeAttribute } from '../../reducers/utils';
import s from './Form.module.scss';
import { ErrMemberAlreadyAdded } from '../../core/project';

class EditMembersForm extends PureComponent {
  static defaultProps = {
    userSource: [],
    members: [],
  };

  viewerRole = () => {
    const { viewer, expertRequest } = this.props;
    if (viewer.admin) return 'super-user';

    const members = expertRequest ? expertRequest.members : [];
    const member = members.find((x) => x.user && x.user.id === viewer.id);
    return member && member.role;
  };

  handleSubmit = async (values) => {
    const {
      initialValues,
      onSubmit,
      project,
      inviteUser,
      addProjectMember,
      removeProjectMember,
      updateProjectMember,
      notify,
    } = this.props;
    const { members } = values;
    const initialMembers = initialValues.members;

    this.suggestions = null;

    const toAdd = members
      .filter((m) => m.new && m.user)
      .map((m) => removeAttribute(m, 'new'));
    const toInvite = members
      .filter((m) => m.new && m.email)
      .map((m) => removeAttribute(m, 'new'));
    const toDelete = initialMembers.filter(
      (m) => !members.some((m2) => m.id === m2.id)
    );
    const toUpdate = members
      .filter((m) => !m.new)
      .filter((m) => {
        const original = initialMembers.find((o) => o.id === m.id);
        return m.role !== original.role || m.state !== original.state;
      });

    try {
      await Promise.all([
        ...toInvite.map((m) =>
          inviteUser({
            collectionType: 'project',
            collectionId: project.id,
            email: m.email,
            role: m.role,
          })
        ),
        ...toAdd.map((m) =>
          addProjectMember(project.id, {
            user: m.user,
            role: m.role,
          })
        ),
        ...toDelete.map((m) => removeProjectMember(project.id, m)),
        ...toUpdate.map((m) => updateProjectMember(project.id, m)),
      ]);

      if (onSubmit) {
        const projectAccessible = !!this.viewerRole();
        onSubmit({ toUpdate, toAdd, toDelete, projectAccessible });
      }
    } catch (err) {
      switch (err.message) {
        case ErrMemberAlreadyAdded.message:
          const member = members.find(
            (o) => o.user && o.user.id === err.rawData.addProjectMember.UserID
          );
          notify(
            `Error adding ${member.user.name}, member already added.`,
            'error'
          );
          break;
        default:
          notify('Error when saving project members.', 'error');
          throw err;
      }
    }
  };

  handleReset = () => {
    const { reset, onReset } = this.props;
    reset();

    this.suggestions = null;

    if (onReset) {
      onReset();
    }
  };

  handleAddMember = ({ type, value }) => {
    const { array } = this.props;

    const member = {
      role: 'member',
      new: true,
    };

    if (type === 'email') {
      member.id = `temp-${value.email}`;
      member.email = value.email;
    } else {
      member.id = `temp-${value.id}`;
      member.user = value;
    }

    array.push('members', member);
  };

  handleRemoveMember = (groupId, memberId) => {
    const { array, members } = this.props;
    array.remove(
      'members',
      members.findIndex((m) => m.id === memberId)
    );
  };

  handleUpdateMember = (groupId, memberId, { role, state }) => {
    const { array, members } = this.props;

    const member = members.find((m) => m.id === memberId);
    const update = { ...member, role, state };

    const index = members.findIndex((m) => m.id === memberId);
    array.splice('members', index, 1, update);
  };

  userFilter = (u) => {
    const { members } = this.props;
    return !members.some((m) => {
      if (m.user && u.type === 'user') {
        return m.user.id === u.value.id;
      }

      if (u.type === 'email') {
        if (m.email) {
          return u.value.email === m.email;
        }
        if (m.user) {
          const emails = m.user.emails || [];
          if (emails.includes(u.value.email)) {
            return true;
          }
        }
      }
      return false;
    });
  };

  render() {
    const {
      handleSubmit,
      members: membersProp,
      initialValues = {},
      project,
      usersLocked = [],
    } = this.props;

    const members = membersProp || initialValues.members;
    const viewerRole = this.viewerRole();
    const permissions = (m) =>
      m.user && usersLocked.includes(m.user.id) ? [] : project.permissions;

    const buttonSize = this.props.buttonSize || 'huge';
    return (
      <div className={s.root}>
        <Form
          onSubmit={handleSubmit(this.handleSubmit)}
          onReset={this.props.onReset}
          className={s.form}
        >
          <div style={{ marginTop: -14 }}>
            <SelectUser
              allowEmail
              listStyle={{ width: 408 }}
              style={{ marginBottom: 20 }}
              label={this.props.searchLabel || ''}
              placeholder={
                this.props.searchHint ||
                'Enter email addresses to invite members'
              }
              noOptionsText="No members match current entry"
              userFilter={this.userFilter}
              onChange={this.handleAddMember}
              value={null}
            />
          </div>
          <div className={s.expertRequestMembers}>
            {members.map((m) => (
              <ProjectMember
                key={m.id || `new-member-${m.email || m.user.id}`}
                member={m}
                projectId={project.id}
                permissions={permissions(m)}
                viewerRole={viewerRole}
                removeMember={this.handleRemoveMember}
                updateMember={this.handleUpdateMember}
              />
            ))}
          </div>
          <div className={s.actions}>
            <Button type="reset" variant="text" size={buttonSize}>
              {this.props.resetLabel || 'Skip for Now'}
            </Button>
            <Button color="teal" type="submit" size={buttonSize}>
              {this.props.submitLabel || 'Invite Team Members'}
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

EditMembersForm = reduxForm({
  form: 'editProjectMembers',
  validate: () => ({}),
  enableReinitialize: true,
})(EditMembersForm);

const selector = formValueSelector('editProjectMembers');
EditMembersForm = connect(
  (state, ownProps) => {
    const members = selector(state, 'members');

    return {
      viewer: state.viewer,
      // initialValues are undefined at first render (mismatch)
      // https://github.com/redux-form/redux-form/issues/4344
      members: members?.length ? members : ownProps.initialValues.members,
    };
  },
  {
    inviteUser,
    addProjectMember,
    removeProjectMember,
    updateProjectMember,
    notify,
  }
)(EditMembersForm);

export default EditMembersForm;

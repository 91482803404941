import React, { memo, useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import cx from 'classnames';
import moment from 'moment-timezone';
import { TextField } from '../../FormAdapters';
import { required } from '../../../core/util';
import LabelledOutline from '../../LabelledOutline';
import Link from '../../Link';
import AddButton from '../Profile/buttons/AddButton';
import FAIcon from '../../Icon/FAIcon';
import { openFileDialog, notify } from '../../../actions/ui';
import { presignedFilestackURL } from '../../../actions/filestack';
import { black, darkGreen } from '../../../core/colors';
import config from '../../../../config';

const useStyles = makeStyles(() => ({
  attachment: {
    width: '100%',
    flexDirection: 'column',
    margin: '0 10px',
  },
  author: {
    marginTop: 15,
    textAlign: 'right',
  },
  attachmentInput: {
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'row',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  removeAction: {
    flex: '0 0 auto',
  },
  button: {
    fontSize: '20px',
  },
}));

function Attachment({
  viewer,
  name,
  index,
  placeholder,
  disabled,
  value,
  presignedFilestackURL,
}) {
  const s = useStyles();

  const editDisabled = disabled || (value && value.id);
  const [externalURL, setExternalURL] = useState(undefined);

  useEffect(() => {
    async function getPresignedFileStackURL() {
      const url = await presignedFilestackURL(value.file_url);
      setExternalURL(url);
    }
    if (value.file_url) getPresignedFileStackURL();
  }, [value.file_url]);

  return (
    <div className={s.attachment}>
      <Link href={externalURL} target="_blank">{`${index + 1}. ${
        value.name
      }`}</Link>
      <Field
        name={`${name}.description`}
        component={TextField}
        validate={required}
        required
        disabled={editDisabled}
        label="Description"
        placeholder={placeholder}
        multiline
        minRows={1}
        maxRows={3}
        fullWidth
        style={{ marginTop: 15 }}
        // Disable native required validation
        inputProps={{
          required: true,
          maxLength: 2048,
        }}
      />
      {value.author && (
        <div className={s.author}>
          Uploaded by{' '}
          <Link
            href={`${value.author.html_url}`}
            target="_blank"
          >{`${value.author.name}`}</Link>
          &nbsp;on{' '}
          {`${moment(value.created_at)
            .tz(viewer.timezone)
            .format('YYYY-MM-DD • h:mma')}`}
          .
        </div>
      )}
    </div>
  );
}

Attachment = connect(
  (state) => ({
    viewer: state.viewer,
  }),
  {
    presignedFilestackURL,
  }
)(Attachment);

function AttachmentInput({
  showRemove,
  name,
  index,
  onRemove,
  inputProps,
  inputComponent,
  className,
  removeClassName,
  value,
}) {
  const s = useStyles();

  const InputComponent = inputComponent;

  return (
    <div className={cx(s.attachmentInput, className)}>
      <div className={cx(s.removeAction, removeClassName)}>
        {showRemove ? (
          <FAIcon
            icon="trash"
            className={s.button}
            onClick={onRemove}
            style={{
              color: black,
              cursor: 'pointer',
            }}
          />
        ) : (
          <FAIcon
            icon="check-circle"
            className={s.button}
            style={{
              color: darkGreen,
            }}
          />
        )}
      </div>
      <InputComponent name={name} index={index} value={value} {...inputProps} />
    </div>
  );
}

let AttachmentList = memo(
  ({
    fields,
    inputProps,
    inputComponent,
    minLength = 0,
    addButtonLabel,
    style,
    className,
    itemClassName,
    removeClassName,
    disabled,
    openFileDialog,
    notify,
    maxAttachmentFiles,
    maxAttachmentSize,
    onAddedFiles,
    addActionStyle,
    showRemoveForField = () => true,
  }) => {
    const maxFiles =
      maxAttachmentFiles > fields.length
        ? maxAttachmentFiles - fields.length
        : 0;

    const handleAdd = async () => {
      openFileDialog({
        accept: config.filestackEngagementAttachmentMimeTypes,
        fromSources: [
          'local_file_system',
          'url',
          'googledrive',
          'dropbox',
          'box',
          'onedrive',
          'onedriveforbusiness',
        ],
        // need to be explicitly false on transformation to remove transformations UI
        transformations: {
          crop: false,
          circle: false,
          rotate: false,
        },
        maxFiles,
        maxSize: maxAttachmentSize * 1024 * 1024,
        onFileSelected: (file) => {
          // If you throw any error in this function it will reject the file selection.
          // The error message will be displayed to the user as an alert.
          const hasDuplicate = fields
            .map(function (name, index) {
              const attachment = fields.value[index];
              return !attachment.id && attachment.name === file.filename;
            })
            .some((f) => f);
          if (hasDuplicate) {
            const msg = `File is already selected for submission: ${file.filename}`;
            notify(msg, 'error');
            throw new Error(msg);
          }
        },
      })
        .then(async (files) => {
          files.map(function (file) {
            fields.push({
              name: file.filename,
              file_url: file.url,
              description: undefined,
            });
          });
        })
        .then(onAddedFiles);
    };

    const showRemove = fields.length > minLength;

    return (
      <>
        <div style={style} className={className}>
          {fields.map((name, index) => (
            <LabelledOutline>
              <AttachmentInput
                key={name}
                inputComponent={inputComponent}
                name={name}
                index={index}
                inputProps={inputProps}
                onRemove={() => fields.remove(index)}
                showRemove={
                  !disabled &&
                  showRemove &&
                  showRemoveForField(fields.value[index])
                }
                className={itemClassName}
                removeClassName={removeClassName}
                value={fields.value[index]}
              />
            </LabelledOutline>
          ))}
        </div>
        {!disabled && maxFiles > 0 && (
          <div style={addActionStyle}>
            <AddButton label={addButtonLabel} onClick={handleAdd} />
          </div>
        )}
      </>
    );
  }
);

AttachmentList = connect(undefined, {
  openFileDialog,
  notify,
})(AttachmentList);

class Attachments extends React.PureComponent {
  render = () => {
    const { name, disabled, label, isMobileVersion, ...other } = this.props;
    return (
      <FieldArray
        component={AttachmentList}
        disabled={disabled}
        name={name}
        defaultValue={[]}
        inputComponent={Attachment}
        inputProps={{
          label,
          placeholder: isMobileVersion ? '' : 'Add a file description',
          disabled,
        }}
        showRemoveForField={(v) => !v?.id}
        {...other}
      />
    );
  };
}

export default Attachments;

export function fetchCaseStudy(slug) {
  return () => {
    return new Promise((resolve, reject) => {
      require.ensure(
        [],
        (require) => {
          try {
            const caseStudy = require(`../routes/cases/cases/${slug}.md`);
            caseStudy.slug = slug;
            resolve(caseStudy);
          } catch (err) {
            reject(err);
          }
        },
        'caseStudies'
      );
    });
  };
}

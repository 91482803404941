import React from 'react';
import ProfileConflicts from './ProfileConflicts';
import { fetchConflicts } from '../../actions/profile';

export default {
  path: '/profile-conflicts',
  async action({ store }) {
    const { viewer } = store.getState();
    if (!viewer.admin) return null;

    store.dispatch(fetchConflicts());

    return {
      title: 'Profile Merge Conflicts',
      component: <ProfileConflicts />,
    };
  },
};

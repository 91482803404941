import React, { Component } from 'react';
import { connect } from 'react-redux';
import Picture from '../Picture';
import Button from '../Button';
import Alert from '../Alert';
import {
  readAppNotifications,
  dismissAppNotification,
} from '../../actions/appNotification';
import history from '../../core/history';
import s from './ExpertAcceptedInvitationNotification.module.scss';

class ExpertAcceptedInvitationNotification extends Component {
  componentDidMount() {
    const { notification, readAppNotifications } = this.props;
    readAppNotifications(notification.id);
  }

  handleCloseClick = () => {
    const { dismissAppNotification, notification } = this.props;
    dismissAppNotification(notification.id);
  };

  handleRequestExpert = async () => {
    const { dismissAppNotification, notification } = this.props;
    await dismissAppNotification(notification.id);
    history.push(
      `/profile/${notification.payload.profile.url_endpoint}/request`
    );
  };

  render() {
    const { profile } = this.props.notification.payload;
    if (!profile) return null;

    return (
      <Alert preset="notification" onRequestClose={this.handleCloseClick}>
        <div className={s.notificationContents}>
          <div className={s.notificationPhoto}>
            <Picture user={profile} size={80} />
          </div>
          <div className={s.notificationBody}>
            <div className={s.notificationTitle}>
              <span className={s.expertName}>{profile.name}</span> has joined
              your expert network! You&rsquo;re now able to request calls
              without paying our platform booking fee.
            </div>
            <Button onClick={this.handleRequestExpert} size="medium">
              Request Expert
            </Button>
          </div>
        </div>
      </Alert>
    );
  }
}

ExpertAcceptedInvitationNotification = connect(undefined, {
  readAppNotifications,
  dismissAppNotification,
})(ExpertAcceptedInvitationNotification);

ExpertAcceptedInvitationNotification = ExpertAcceptedInvitationNotification;

ExpertAcceptedInvitationNotification.filterNotifications = (
  state,
  notifications
) => {
  // notification must have accessible expert to be accepted
  return notifications.filter((n) => n.payload.profile);
};

export default ExpertAcceptedInvitationNotification;

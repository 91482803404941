import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import CalendarMenu from '../../CalendarMenu';
import Button from '../../../../components/Button/Button';
import Link from '../../../../components/Link';
import Divider from '../../../../components/Divider/Divider';
import { formatBillRate } from '../../../../core/util';
import { policies } from '../../../../core/agreements';
import s from './Actions.module.scss';
import { teal500 } from '../../../../core/colors';

const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: 12,
    paddingRight: 12,
    marginLeft: 6,
  },
}));

function StyledButton({ children, color, ...other }) {
  const classes = useStyles();
  return (
    <Button {...other} classes={classes} variant="text" fontColor={color}>
      {children}
    </Button>
  );
}

const unpaidDisclaimer =
  'This is an unpaid call request. Confirmation of this call is an acknowledgement of the fee structure.';

class Actions extends React.Component {
  render() {
    const {
      consultation,
      isConfirmed,
      isViewerExpert,
      isExpertFirstAttempt,
      canConfirm,
      canDeny,
      canCancel,
      canReschedule,
      canInviteParticipant,
      onConfirm,
      onCancel,
      onDeny,
      onReschedule,
      onInviteParticipant,
    } = this.props;
    const tosPol = policies['terms-of-service'];
    const privacyPol = policies.privacy;
    const expertPartPol = policies['expert-participation-agreement'];

    const {
      id,
      expected_duration: expectedDuration,
      completion_adds_expert_to_network: completionAddsToNetwork,
      event,
      unpaid,
    } = consultation;

    const showDivider = canConfirm || canCancel || canDeny || canReschedule;

    return (
      <div>
        {showDivider && <Divider />}

        {canConfirm && isViewerExpert && (
          <div className={s.legalNotice}>
            By clicking “Accept Call,” you agree that the consultation,
            including any personal data, will be recorded by OnFrontiers.
            <br />
            Such recording shall be used and maintained pursuant to the terms of
            OnFrontiers’{' '}
            <Link newTab href={tosPol.url}>
              {tosPol.name}
            </Link>{' '}
            and{' '}
            <Link newTab href={privacyPol.url}>
              {privacyPol.name}
            </Link>
            .
          </div>
        )}
        {canConfirm && isViewerExpert && completionAddsToNetwork && (
          <div className={s.legalNotice}>
            You will be added to the client&apos;s network after the
            consultation is completed. Clients are able to view your contact
            information and connect with you more easily when you are part of
            their network. You may remove the connection at the any time.
          </div>
        )}

        <div className={s.actions}>
          {isConfirmed && (
            <CalendarMenu
              event={event}
              consultationId={id}
              expectedDuration={expectedDuration}
            />
          )}

          {canConfirm && (
            <div className={s.acceptButton}>
              <StyledButton color={teal500} onClick={onConfirm}>
                {isExpertFirstAttempt ? 'Accept Call' : 'Confirm Time'}
              </StyledButton>
            </div>
          )}

          {canInviteParticipant && (
            <StyledButton onClick={onInviteParticipant}>
              Invite Participant
            </StyledButton>
          )}

          {canDeny && (
            <StyledButton onClick={onDeny}>I&apos;m not available</StyledButton>
          )}

          {canCancel && (
            <StyledButton onClick={onCancel}>Cancel Call</StyledButton>
          )}

          {canReschedule && (
            <StyledButton onClick={onReschedule}>Reschedule Call</StyledButton>
          )}
        </div>

        {canConfirm && isViewerExpert && !unpaid && (
          <div className={s.expertRate}>
            {consultation.bill_rate > 0 && 'Your rate for this consultation is'}{' '}
            {consultation.bill_rate > 0 &&
              formatBillRate(consultation.bill_rate)}{' '}
            {consultation.bill_rate === 0 && unpaidDisclaimer}{' '}
            <Link newTab href={expertPartPol.url}>
              Learn more.
            </Link>
          </div>
        )}
      </div>
    );
  }
}

export default Actions;

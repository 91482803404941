import React from 'react';
import { connect } from 'react-redux';
import history from '../../core/history';
import TeamAccountPromoDialog from './TeamAccountPromoDialog';

class TeamAccountPromoButton extends React.Component {
  static defaultProps = {
    to: '/request_expert',
  };

  state = {
    open: false,
  };

  handleClick = () => {
    const { viewer, onClick, to } = this.props;
    if (viewer.groups && viewer.groups.length > 0) {
      if (onClick) onClick();
      if (to) history.push(to);
      return;
    }
    this.setState({ open: true });
  };

  render() {
    const {
      component: ClickableComponent,
      viewer,
      to,
      dialogProps,
      dispatch,
      ...other
    } = this.props;
    const { open } = this.state;
    return (
      <React.Fragment>
        <TeamAccountPromoDialog
          dialogProps={dialogProps}
          open={open}
          onClose={() => this.setState({ open: false })}
        />
        <ClickableComponent {...other} onClick={this.handleClick}>
          {other.label}
          {other.children}
        </ClickableComponent>
      </React.Fragment>
    );
  }
}

TeamAccountPromoButton = connect((state) => ({
  viewer: state.viewer,
}))(TeamAccountPromoButton);

export default TeamAccountPromoButton;

import React from 'react';
import Profile from '../../components/Profile';
import Layout from '../../components/Layout';
import MediaQuery from '../../components/MediaQuery';
import { SCREEN_SM } from '../../constants';
import { fetchProfile } from '../../actions/profile';
import { fetchSectors } from '../../actions/sector';
import { fetchCountries } from '../../actions/country';
import { fetchPermissions } from '../../actions/permission';
import { track } from '../../actions/tracking';

export default {
  path: '/unregistered_expert/:url_endpoint',
  async action({ params, store }) {
    const { viewer } = store.getState();

    const { url_endpoint: urlEndpoint } = params;

    const [profile] = await Promise.all(
      [
        fetchProfile(urlEndpoint, {
          leadOnly: true,
          audit: true,
          experiences: true,
          education: true,
          addresses: true,
          expertise: true,
          groupKeywords: true,
          sources: true,
          internalNetworks: true,
        }),
        fetchSectors(),
        fetchCountries(),
      ]
        .filter(Boolean)
        .map(store.dispatch)
    );

    if (!profile) return null;

    const [updatePerm] = await store.dispatch(
      fetchPermissions(viewer.id, [
        { service: 'profile', action: 'update', resource: profile.id },
        {
          service: 'group_member',
          action: 'update_group_keywords',
          resource: profile.id,
        },
      ])
    );

    await store.dispatch(
      track('profile.unregistered.view', profile.id, undefined, true)
    );

    return {
      title: `${profile.name} - Unregistered Expert`,
      component: (
        <Layout showNav>
          <MediaQuery maxWidth={SCREEN_SM}>
            {(mobile) => (
              <Profile
                profileId={profile.id}
                mobile={mobile}
                editable={updatePerm.allowed}
              />
            )}
          </MediaQuery>
        </Layout>
      ),
    };
  },
};

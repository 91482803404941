import React from 'react';
import Button from '../../Button';
import Phone from './Phone';
import s from './SubmitContainer.module.scss';

class SubmitContainer extends React.PureComponent {
  render = () => {
    const { isNewRequest, disabled, handleSubmit } = this.props;
    return (
      <React.Fragment>
        <div className={s.submit}>
          {isNewRequest ? (
            <React.Fragment>
              <h4 className={s.submitTitle}>
                Before submitting, please let us know your preferred contact
                number.
              </h4>

              <div className={s.submitText}>
                We will contact you within a few business hours to finalize
                scope.
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <h4 className={s.submitTitle}>
                Update or set your preferred contact number.
              </h4>

              <div className={s.submitText}>
                If we have not already contacted you, we will reach out within a
                few business hours to finalize scope.
              </div>
            </React.Fragment>
          )}

          <Phone
            containerStyle={{ marginTop: 20, maxWidth: 350 }}
            disabled={disabled}
            required
          />

          <Button
            id="expertRequestsSaveRequestButton"
            onClick={handleSubmit}
            color="teal"
            disabled={disabled}
            style={{ marginTop: 30 }}
            size="medium"
            fullWidth
          >
            Save Request
          </Button>
        </div>
      </React.Fragment>
    );
  };
}

SubmitContainer.fetch = Phone.fetch;
SubmitContainer = SubmitContainer;

export default SubmitContainer;

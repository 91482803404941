import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';

import cx from 'classnames';
import { Form } from 'react-final-form';
import { csvImport, csvImportPreview } from '../../actions/profile';
import LayoutPage from '../../components/Layout/LayoutPage';
import Button from '../../components/Button/Button';
import MaterialIcon from '../../components/Icon/MaterialIcon';
import CircularProgress from '../../components/CircularProgress';
import ProfileFields from './ProfileFields';
import CsvContents from './CsvContents';
import { teal500, red500, white, orange500 } from '../../core/colors';
import history from '../../core/history';
import s from './ProfileUploader.module.scss';

class ProfileUploader extends PureComponent {
  state = { importing: false };

  setImporting = (importing) => {
    this.setState({ importing });
  };

  handleConfirmUpload = async (values) => {
    const { csvImport } = this.props;

    const {
      csv_filename: filename,
      csv_url: url,
      csv_mimetype: mimetype,
      csv_ignore_rows: ignoreRows,
      request_id: requestId,
      created_by: createdBy,
      network_id: networkId,
      not_available_marketplace: notAvailableMarketplace,
    } = values;

    this.setImporting(true);

    try {
      await csvImport({
        filename,
        url,
        mimetype,
        ignoreRows,
        requestId,
        createdBy,
        networkId,
        availableMarketplace: !notAvailableMarketplace,
      });
      history.push('/profile-conflicts');
    } catch (e) {
      this.setState({ error: e });
    } finally {
      this.setImporting(false);
    }
  };

  render() {
    const { csvPreviews } = this.props;
    const { importing, error } = this.state;

    return (
      <LayoutPage showNav>
        <Form
          onSubmit={this.handleConfirmUpload}
          subscription={{
            submitting: true,
            values: true,
            initialValues: true,
          }}
          initialValues={{}}
        >
          {({ form, values, handleSubmit }) => {
            const {
              csv_filename: csvFilename,
              csv_url: csvUrl,
              csv_ignore_rows: csvIgnoreRows,
              request_id: requestId,
            } = values;

            const preview = csvUrl && csvPreviews[csvUrl];

            const profilesWithWarnings =
              preview &&
              preview.profiles.filter(
                (p) => p.warnings && p.warnings.length > 0
              );

            const warnings =
              profilesWithWarnings &&
              profilesWithWarnings.length > 0 &&
              profilesWithWarnings
                .map((p) => p.warnings.map((v) => `Row ${p.row}: ${v}`))
                .reduce((acc, v) => acc.concat(v));

            const profilesWithErrors =
              preview &&
              preview.profiles.filter((p) => p.errors && p.errors.length > 0);

            const errors =
              profilesWithErrors &&
              profilesWithErrors.length > 0 &&
              profilesWithErrors
                .map((p) => p.errors.map((v) => `Row ${p.row}: ${v}`))
                .reduce((acc, v) => acc.concat(v));

            return (
              <Fragment>
                <div className={s.header}>
                  <div className={s.headerText}>Profile Uploader</div>
                  {!!preview && (
                    <div>
                      <Button
                        color="lightTan"
                        size="medium"
                        style={{ marginRight: 15 }}
                        onClick={() => form.reset()}
                      >
                        Go back
                      </Button>
                      <Button
                        backgroundColor={teal500}
                        fontColor={white}
                        onClick={handleSubmit}
                        disabled={!preview || !preview.valid || importing}
                        size="medium"
                      >
                        Confirm Upload
                      </Button>
                    </div>
                  )}
                </div>
                <ProfileFields
                  hasFile={!!csvUrl}
                  setImporting={this.setImporting}
                  disabled={!!preview || importing}
                  form={form}
                  values={values}
                />
                <div>
                  {!!error && (
                    <div className={s.errorPanel}>
                      <div className={s.errorMessage}>
                        {error.toString
                          ? error.toString()
                          : JSON.stringify(error)}
                      </div>
                    </div>
                  )}
                  {importing && (
                    <div className={s.progress}>
                      <CircularProgress
                        size={52}
                        style={{ marginLeft: 10, marginBottom: 20 }}
                      />
                      <div>Processing (this may take a few minutes)...</div>
                    </div>
                  )}
                  {!!csvFilename && !!preview && (
                    <Fragment>
                      <div className={s.csvFile}>
                        <div className={s.csvFileLabel}>
                          <MaterialIcon
                            className={s.inlineIcon}
                            icon="check"
                            color={teal500}
                          />
                          {csvFilename || csvUrl}
                          <div
                            className={cx(s.csvFileFormat, {
                              [s.csvFileFormatInvalid]:
                                preview && !preview.valid,
                            })}
                          >
                            {preview &&
                              (preview.valid
                                ? 'Formatting valid'
                                : 'Formatting invalid')}
                          </div>
                        </div>
                      </div>
                      {preview.valid && (
                        <div>
                          <div className={s.profilesNew}>
                            <MaterialIcon
                              className={s.inlineIcon}
                              icon="person_outline"
                              color={teal500}
                            />
                            {
                              preview.profiles.filter(
                                (p) => p.resolution === 'new'
                              ).length
                            }{' '}
                            New Profiles
                          </div>
                          <div className={s.profilesConflicting}>
                            <MaterialIcon
                              className={s.inlineIcon}
                              icon="clear"
                              color={red500}
                            />
                            {
                              preview.profiles.filter((p) =>
                                p.resolution.startsWith('conflict')
                              ).length
                            }{' '}
                            Conflicting Profiles
                          </div>
                          {warnings && warnings.length > 0 && (
                            <div className={s.warnings}>
                              <MaterialIcon
                                className={s.inlineIcon}
                                icon="warning"
                                color={orange500}
                              />
                              {warnings.length} Warning Profiles
                            </div>
                          )}
                          {errors && errors.length > 0 && (
                            <div className={s.errors}>
                              {errors.map((e) => (
                                <div key={e.row}>
                                  <MaterialIcon
                                    className={s.inlineIcon}
                                    icon="error_outline"
                                    color={red500}
                                  />{' '}
                                  {e}
                                </div>
                              ))}
                            </div>
                          )}
                          <CsvContents
                            preview={preview}
                            ignoredRows={csvIgnoreRows}
                            globalRequestId={requestId}
                            form={form}
                          />
                        </div>
                      )}
                    </Fragment>
                  )}
                </div>
              </Fragment>
            );
          }}
        </Form>
      </LayoutPage>
    );
  }
}

ProfileUploader = connect(
  (state) => ({
    csvPreviews: state.profiles.csvPreviews,
  }),
  {
    csvImport,
    csvImportPreview,
  }
)(ProfileUploader);

ProfileUploader = ProfileUploader;

export default ProfileUploader;

import React from 'react';
import { connect } from 'react-redux';
import TeamAccountPromo from './TeamAccountPromo';
import Dialog from '../Dialog';
import history from '../../core/history';
import { white } from '../../core/colors';
import { track } from '../../actions/tracking';

class TeamAccountPromoDialog extends React.Component {
  static defaultProps = {
    showEventTrack: 'promo.show.team_account.expert_request',
  };

  handleClose = () => {
    const { returnTo, onClose } = this.props;
    if (returnTo) history.push(returnTo);
    if (onClose) onClose();
  };

  componentDidMount() {
    const { open, track, showEventTrack } = this.props;
    if (open && showEventTrack) track(showEventTrack);
  }

  componentDidUpdate(oldProps) {
    const { open, track, showEventTrack } = this.props;
    if (!oldProps.open && open && showEventTrack) track(showEventTrack);
  }

  render() {
    const { returnTo, promoProps, clickEventTrack, showEventTrack, ...other } =
      this.props;
    return (
      <Dialog promo {...other} onClose={this.handleClose}>
        <TeamAccountPromo
          clickEventTrack={clickEventTrack}
          onContactSales={this.handleClose}
          style={{ padding: 20 }}
          titleStyle={{ color: white, fontSize: 20 }}
          bodyStyle={{ color: white, fontSize: 16 }}
          iconProps={{
            color: 'rgba(255, 255, 255, .52)',
            size: 30,
          }}
          buttonProps={{
            primary: false,
            backgroundColor: 'rgba(255, 255, 255, .2)',
            style: { backgroundColor: 'transparent', color: white },
          }}
          {...promoProps}
        />
      </Dialog>
    );
  }
}

TeamAccountPromoDialog = connect(undefined, {
  track,
})(TeamAccountPromoDialog);

export default TeamAccountPromoDialog;

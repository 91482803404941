import actionTypes from '../core/ActionTypes';

const { GROUP__ADD_MEMBER, PROJECT__ADD_MEMBER } = actionTypes;

export function inviteUser(invitation, opts = { reinvite: false }) {
  return async (dispatch, getState, { graphql }) => {
    const { inviteUser: member } = await graphql.mutate(
      `(
      $email: String
      $profileId: String
      $firstName: String
      $lastName: String
      $collectionType: InvitationCollectionType!
      $collectionId: String!
      $role: String!
      $teamNote: String
      $invitationMessage: String
    ) {
      inviteUser(
        email: $email
        profile_id: $profileId
        first_name: $firstName
        last_name: $lastName
        collection_type: $collectionType
        collection_id: $collectionId
        role: $role
        team_note: $teamNote
        invitation_message: $invitationMessage
      ) {
        id
        role
        email
      }
    }`,
      invitation
    );

    if (opts.reinvite) return;

    switch (invitation.collectionType) {
      case 'group':
        dispatch({
          type: GROUP__ADD_MEMBER,
          groupId: invitation.collectionId,
          member,
        });
        break;
      case 'project':
        member.state = 'active';
        dispatch({
          type: PROJECT__ADD_MEMBER,
          projectId: invitation.collectionId,
          member,
        });
        break;
      case 'internal_network_experts':
        // ignore
        break;
      default:
        throw new Error(
          `unknown collection type: '${invitation.collectionType}'`
        );
    }
  };
}

export function acceptInvitation(token) {
  return async (dispatch, getState, { graphql }) => {
    const { acceptInvitation: nextURL } = await graphql.mutate(
      `($token: String!) {
        acceptInvitation(token: $token)
      }`,
      { token }
    );
    return nextURL;
  };
}

export const ErrEmailAlreadyExists = new Error(
  'user with this email already exists'
);

export const ErrPhoneAlreadyExists = new Error(
  'user with this phone already exists'
);

export const ErrAddressAlreadyTaken = new Error(
  'GraphQL Error: address already taken'
);

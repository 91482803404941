import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';
import { Select } from '../FormAdapters/FormAdapters';

const SectorType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

export default class SelectSector extends Component {
  static propTypes = {
    placeholder: PropTypes.string,
    sectors: PropTypes.arrayOf(SectorType),
    onRequestAdd: PropTypes.func,
    onRequestDelete: PropTypes.func,
    value: PropTypes.oneOfType([SectorType, PropTypes.arrayOf(SectorType)]),
    input: PropTypes.object,
    meta: PropTypes.object,
  };

  static defaultProps = {
    sectors: [],
  };

  render() {
    const { input, meta, sectors, ...other } = this.props;

    const value = this.props.value || (input && input.value) || [];

    return (
      <Select
        autocomplete
        input={input}
        meta={meta}
        multiple
        options={sectors}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option}
        isOptionEqualToValue={(option, value) => isEqual(option, value)}
        rawValueOnChange
        value={value}
        label="Sectors or industries"
        limit={null}
        {...other}
      />
    );
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import MediaQueryComponent from 'react-responsive';

function toMediaValues(caps) {
  if (!caps) {
    return;
  }

  const { screen } = caps;

  if (screen) {
    return {
      width: screen.width,
      height: screen.height,
    };
  }
}

const MediaQuery = (props, { capabilities }) => (
  <MediaQueryComponent {...props} device={toMediaValues(capabilities)} />
);

MediaQuery.contextTypes = {
  capabilities: PropTypes.object,
};

export default MediaQuery;

import { loginAs } from '../../core/login';
export default {
  subdomain: '*',
  path: '/loginas',

  async action({ graphql, store, query, path }, params, { subdomain }) {
    const { next, user } = query;

    await loginAs(graphql, user, next);
  },
};

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { reduxForm, FieldArray } from 'redux-form';
import { saveExperience, removeExperience } from '../../../actions/profile';
import Experience from './Experience';
import Button from '../../Button/Button';
import EmptyMessage from '../../EmptyMessage';

const validate = (values) => {
  const errors = {};

  const experienceErrors = [];
  values.experiences.forEach((exp, i) => {
    if (emptyExperience(exp)) {
      // empty experience form, treat it as if it was never included
      return;
    }

    const err = {};

    if (!exp.title) {
      err.title = 'Required';
    }

    if (!exp.organization) {
      err.organization = 'Required';
    }

    if (exp.start_date && !exp.current && !exp.end_date) {
      err.end_date = 'Required';
    }

    if (exp.start_date && !moment(exp.start_date).isValid()) {
      err.start_date = 'Start date must be a valid date';
    }

    if (exp.end_date && !moment(exp.end_date).isValid()) {
      err.end_date = 'End date must be a valid date';
    }

    if (
      exp.start_date &&
      exp.end_date &&
      moment(exp.end_date).isBefore(exp.start_date)
    ) {
      err.end_date = 'End date must be after start date';
    }

    if (Object.keys(err).length > 0) {
      experienceErrors[i] = err;
    }
  });

  if (experienceErrors.length) {
    errors.experiences = experienceErrors;
  }

  return errors;
};

function emptyExperience(exp) {
  if (exp.title) return false;
  if (exp.location) return false;
  if (exp.organization) return false;
  if (exp.role) return false;
  if (exp.start_date) return false;
  if (exp.end_date) return false;
  if (exp.current) return false;
  if (exp.description) return false;

  return true;
}

function Experiences({ fields, onAdd, change }) {
  return (
    <div>
      {fields.length > 0 ? (
        fields.map((field, index) => (
          <Experience
            showRemove
            showAdd={index === fields.length - 1}
            onAdd={onAdd}
            experience={fields.get(index)}
            field={field}
            key={field}
            onRemove={() => fields.remove(index)}
            change={change}
          />
        ))
      ) : (
        <EmptyMessage
          border={false}
          iconName="work"
          body="Add your first work experience"
          action={<Button onClick={onAdd}>Add a Work Experience</Button>}
        />
      )}
    </div>
  );
}

class ExperiencesForm extends PureComponent {
  handleSubmit = (values) => {
    const { profile, onSubmit } = this.props;
    const experiences = values.experiences.filter(
      (exp) => !emptyExperience(exp)
    );

    const itemsToDelete = profile.experiences.filter(
      (current) => !experiences.find((edited) => edited.id === current.id)
    );

    itemsToDelete.forEach((w) => this.props.removeExperience(profile.id, w.id));

    experiences.forEach((w) => this.props.saveExperience(profile.id, w));

    if (onSubmit) {
      onSubmit({ experiences });
    }
  };

  handleReset = () => {
    const { reset, onReset } = this.props;
    reset();

    if (onReset) {
      onReset();
    }
  };

  render() {
    const {
      component: Container,
      array,
      change,
      handleSubmit,
      profile,
      ...other
    } = this.props;

    return (
      <Container
        {...other}
        onSubmit={handleSubmit(this.handleSubmit)}
        onReset={this.handleReset}
      >
        <FieldArray
          name="experiences"
          component={Experiences}
          onAdd={() => array.push('experiences', {})}
          change={change}
        />
      </Container>
    );
  }
}

ExperiencesForm = reduxForm({
  form: 'expertExperiences',
  validate,
  enableReinitialize: true,
})(ExperiencesForm);

ExperiencesForm = connect(
  (state, ownProps) => {
    const profile = state.profiles.fullProfiles[ownProps.profileId];
    const initialExperiences =
      profile.experiences && profile.experiences.length > 0
        ? profile.experiences
        : [{}];
    const form = state.form.expertExperiences;
    const initialValues = { experiences: initialExperiences };
    const formValues = form ? form.values : initialValues;

    return {
      profile,
      formValues,
      initialValues,
    };
  },
  {
    saveExperience,
    removeExperience,
  }
)(ExperiencesForm);

export default ExperiencesForm;

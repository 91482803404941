import React from 'react';
import Button from './Button';

const labelStyle = {
  letterSpacing: '-0.5px',
};

const overlayStyle = {
  paddingLeft: 8,
  paddingRight: 8,
};

function RequestConsultationButton(props) {
  return <Button {...props} sx={{ ...labelStyle, ...overlayStyle }} />;
}

RequestConsultationButton.defaultProps = {
  size: 'large',
  label: 'Request Call',
};

export default RequestConsultationButton;

import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { TextField } from '../../FormAdapters';
import { updateProfile } from '../../../actions/profile';
import { isValidLinkedin } from '../../../reducers/utils';

function validate(values = {}) {
  const errors = {};

  if (!isValidLinkedin(values.linkedin_url)) {
    errors.linkedin_url = 'Must be a valid https://linkedin.com URL';
  }

  return errors;
}

function LinkedinForm({
  component: Container,
  reset,
  onReset,
  profileId,
  updateProfile,
  onSubmit,
  handleSubmit: handleSubmitProp,
  ...other
}) {
  const handleSubmit = (values) => {
    updateProfile({ id: profileId, ...values });

    if (onSubmit) {
      onSubmit(values);
    }
  };

  const handleReset = () => {
    reset();

    if (onReset) {
      onReset();
    }
  };

  return (
    <Container
      {...other}
      onSubmit={handleSubmitProp(handleSubmit)}
      onReset={handleReset}
    >
      <Field
        fullWidth
        component={TextField}
        name="linkedin_url"
        label="LinkedIn URL"
      />
    </Container>
  );
}

LinkedinForm = reduxForm({
  form: 'expertLinkedin',
  validate,
  enableReinitialize: true,
})(LinkedinForm);

LinkedinForm = connect(
  (state, ownProps) => {
    const profile = state.profiles.fullProfiles[ownProps.profileId];
    return {
      profile,
      initialValues: {
        linkedin_url: profile.linkedin_url,
      },
    };
  },
  {
    updateProfile,
  }
)(LinkedinForm);

export default LinkedinForm;

import React from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { Select } from '../../components/FormAdapters/FormAdapters';
import Dialog from '../../components/Dialog';

function AttachToExpertRequest({
  onCancel,
  open,
  onConfirm,
  consultation,
  initialValues,
  expertRequests,
}) {
  const { expert_request: expertRequest } = consultation;

  const title = !expertRequest
    ? 'Add Consultation to an Expert Request'
    : 'Edit Associated Expert Request';

  const confirmLabel = !expertRequest
    ? 'Add to Expert Request'
    : 'Edit Expert Request';

  return (
    <Form onSubmit={onConfirm} initialValues={initialValues}>
      {({ form, handleSubmit }) => {
        const handleReset = () => {
          form.reset();
          if (onCancel) onCancel();
        };

        return (
          <Dialog
            useForm
            open={open}
            title={title}
            onSubmit={handleSubmit}
            onReset={() => form.reset()}
            onClose={handleReset}
            onCancel={handleReset}
            cancelLabel="Cancel"
            confirmLabel={confirmLabel}
          >
            <Field
              autocomplete
              component={Select}
              label="Select the Expert Request"
              name="expert_request_id"
              options={expertRequests.edges.map((p) => ({
                value: p.node.id,
                label: p.node.name,
              }))}
            />
          </Dialog>
        );
      }}
    </Form>
  );
}

AttachToExpertRequest = connect((state, ownProps) => {
  const expertRequestId =
    ownProps.consultation &&
    ownProps.consultation.expert_request &&
    ownProps.consultation.expert_request.id;
  return {
    expertRequests: state.expertRequests.all,
    initialValues: {
      expert_request_id: expertRequestId,
    },
  };
})(AttachToExpertRequest);

export default AttachToExpertRequest;

import React, { PureComponent } from 'react';
import cx from 'classnames';
import s from './SettingsSection.module.scss';

class SettingsSection extends PureComponent {
  render() {
    const { title, text, children, box, className, boxClassName } = this.props;

    return (
      <div className={cx(s.root, { [className]: !!className })}>
        <h4 className={s.title}>{title}</h4>
        <div
          className={cx({
            [s.box]: !!box,
            [boxClassName]: !!boxClassName,
          })}
        >
          {text && <div className={s.text}>{text}</div>}
          {children}
        </div>
      </div>
    );
  }
}

export default SettingsSection;

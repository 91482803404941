import React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '../LinearProgress';
import Body from '../Body';
import s from './Wizard.module.scss';

function Wizard({ showProgress = true, completed, children }) {
  return (
    <div className={s.root}>
      {showProgress && (
        <LinearProgress variant="determinate" value={100 * completed} />
      )}
      <Body style={{ paddingTop: 30, paddingBottom: 30 }}>{children}</Body>
    </div>
  );
}

Wizard.propTypes = {
  completed: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default Wizard;

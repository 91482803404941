import React, { PureComponent } from 'react';
import moment from 'moment-timezone';
import { IconButton } from '@mui/material';
import ExpertWidgetVertical from '../../components/ExpertWidgetVertical';
import ExpertWidgetHorizontal from '../../components/ExpertWidgetHorizontal';
import Body from '../../components/Body';
import MediaQuery from '../../components/MediaQuery';
import Article from '../../components/Article';
import FAIcon from '../../components/Icon/FAIcon';
import BlogMedia from '../../components/Blog/BlogMedia';
import BlogTag from '../../components/Blog/BlogTag';
import { SCREEN_MD } from '../../constants';
import { absoluteUrl, safeHtml } from '../../core/util';
import s from './BlogPost.module.scss';

class BlogPost extends PureComponent {
  componentDidMount() {
    const { post } = this.props;
    /**
     *  LEARN WHY DEFINING THESE VARIABLES IS IMPORTANT: https://disqus.com/admin/universalcode/#configuration-variables
     */
    window.disqus_config = function () {
      this.page.url = absoluteUrl(post.link); // Replace PAGE_URL with your page's canonical URL variable
      this.page.identifier = post.id; // Replace PAGE_IDENTIFIER with your page's unique identifier variable
    };
    (function () {
      // DON'T EDIT BELOW THIS LINE
      const d = document;
      const s = d.createElement('script');
      s.src = 'https://onfrontiers.disqus.com/embed.js';
      s.setAttribute('data-timestamp', +new Date());
      (d.head || d.body).appendChild(s);
    })();
  }

  share = (link) => {
    const width = 670;
    const height = 440;
    const x = window.screen.width / 2 - width / 2;
    const y = window.screen.height / 2 - height / 2;
    window.open(
      link,
      '_blank',
      `width=${width},height=${height},left=${x},top=${y}`
    );
  };

  shareFacebook = () => {
    const { title_text: title, link: relativeLink } = this.props.post;
    const link = absoluteUrl(relativeLink);
    this.share(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        link
      )}&t=${encodeURIComponent(title)}`
    );
  };

  shareTwitter = () => {
    const { title_text: title, link: relativeLink } = this.props.post;
    const link = absoluteUrl(relativeLink);
    this.share(
      `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        title
      )}:%20${encodeURIComponent(link)}`
    );
  };

  shareLinkedIn = () => {
    const { title_text: title, link: relativeLink } = this.props.post;
    const link = absoluteUrl(relativeLink);
    this.share(
      `http://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
        link
      )}&title=${encodeURIComponent(title)}`
    );
  };

  render() {
    const { post, categories, experts } = this.props;
    const {
      title_text: title,
      content,
      featured_media: featuredMedia,
      author,
    } = post;

    return (
      <MediaQuery maxWidth={SCREEN_MD}>
        {(isMobileVersion) => {
          return (
            <div className={s.root}>
              <div className={s.inset}>
                {post.categories && post.categories.length > 0 && (
                  <div className={s.categories}>
                    {post.categories.map((c, i) => (
                      <a
                        key={c.slug}
                        href={c.link}
                        dangerouslySetInnerHTML={{
                          __html: safeHtml(
                            `${c.name}${i < post.categories.length - 1 ? ', ' : ''
                            }`
                          ),
                        }}
                      />
                    ))}
                  </div>
                )}
                <h1
                  className={s.title}
                  dangerouslySetInnerHTML={{ __html: safeHtml(title) }}
                />
                <div className={s.author}>
                  By <span className={s.authorName}>{author.name}</span> |{' '}
                  {moment(post.date).format('D MMMM YYYY')}
                </div>
              </div>

              {featuredMedia && featuredMedia.media_details && (
                <div className={s.media}>
                  <BlogMedia media={featuredMedia} />
                </div>
              )}

              <Body className={s.content}>
                {
                  <div className={s.left}>
                    <IconButton onClick={this.shareFacebook}>
                      <FAIcon size={24} color="#3b5998" icon="facebook" />
                    </IconButton>
                    <IconButton onClick={this.shareLinkedIn}>
                      <FAIcon size={24} color="#007bb6" icon="linkedin" />
                    </IconButton>
                    <IconButton onClick={this.shareTwitter}>
                      <FAIcon size={24} color="#1da1f2" icon="twitter" />
                    </IconButton>
                  </div>
                }

                {!isMobileVersion && (
                  <div className={s.center}>
                    {experts && experts.length > 0 && (
                      <div className={s.expertWidgetVertical}>
                        <ExpertWidgetVertical
                          dark
                          showSummary
                          showRequest
                          profile={experts[0]}
                          maxSummaryLength={150}
                        />
                      </div>
                    )}

                    <div>
                      <div className={s.categoriesTitle}>
                        Explore Other Topics
                      </div>
                      {categories &&
                        categories.map((c) => (
                          <div className={s.category} key={c.slug}>
                            <BlogTag href={c.link} text={c.name} />
                          </div>
                        ))}
                    </div>
                  </div>
                )}

                <div className={s.right}>
                  <Article html={content.rendered} />

                  {post.tags && post.tags.length > 0 && (
                    <div className={s.tags}>
                      {post.tags.map((t) => (
                        <BlogTag href={t.link} key={t.id} text={t.name} />
                      ))}
                    </div>
                  )}

                  {experts && experts.length > 0 && (
                    <div className={s.expertWidgetHorizontal}>
                      <ExpertWidgetHorizontal
                        dark
                        pictureSize={100}
                        profile={experts[0]}
                      />
                    </div>
                  )}

                  <div className={s.comments} id="disqus_thread" />
                </div>
              </Body>
            </div>
          );
        }}
      </MediaQuery>
    );
  }
}

export default BlogPost;

import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import EditIcon from '../../../components/EditIcon/EditIcon';
import { formatCreditsText } from '../../../core/util';
import { black, borderColor, sand } from '../../../core/colors';
import EditRatesDialog from './EditRatesDialog';

const getStyles = makeStyles((theme) => ({
  root: {
    margin: '15px 0',
    padding: '10px 15px',
    background: sand,
    border: `1px solid ${borderColor}`,
    fontWeight: 500,
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      alignItems: 'flex-end',
    },
  },
  title: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  label: {
    color: black,
    display: 'block',
    [theme.breakpoints.down('md')]: {
      paddingTop: 20,
    },
  },
  value: {
    margin: '5px 0 0',
  },
  edit: {
    textAlign: 'right',
    [theme.breakpoints.down('md')]: {
      fontSize: 26,
      textAlign: 'center',
    },
  },
  agreement: {
    paddingTop: 10,
    fontWeight: 400,
    fontSize: 14,
    [theme.breakpoints.down('md')]: {
      paddingTop: 20,
    },
  },
}));

function CandidateRates({ candidate, expertRequest }) {
  const s = getStyles();

  const [openDialog, setOpenDialog] = useState(false);

  const {
    credit_rate: credits,
    bill_rate: expertRate,
    show_engagement_agreement: showEngagementAgreement,
  } = candidate;

  const creditsText = `${formatCreditsText(credits)}/hr`;
  const expertRateText = `${(expertRate / 100).toFixed(2)} USD/hr`;

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('md'));

  const editIcon = (
    <Grid item md={2} xs={2} className={s.edit}>
      <EditIcon onClick={() => setOpenDialog(true)} />
    </Grid>
  );

  return (
    <>
      <Grid className={s.root} container>
        <Grid item md={3} xs={12} className={s.title}>
          ADMIN ONLY
        </Grid>
        <Grid container item md={9} xs={12}>
          <Grid item md={5} xs={12}>
            <strong className={s.label}>Consumer Rate</strong>
            <p className={s.value}>{creditsText}</p>
          </Grid>
          <Grid item md={5} xs={12}>
            <strong className={s.label}>Expert Rate</strong>
            <p className={s.value}>{expertRateText}</p>
          </Grid>
          {!isXs && editIcon}
          <Grid item md={12} xs={10} className={s.agreement}>
            Engagement agreements {showEngagementAgreement ? 'will' : "won't"}{' '}
            be shown to consumer
          </Grid>
          {isXs && editIcon}
        </Grid>
      </Grid>
      <EditRatesDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        candidate={candidate}
        expertRequest={expertRequest}
      />
    </>
  );
}

export default CandidateRates;

import React from 'react';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import Tidbit from '../Tidbit';
import Picture from '../Picture';
import Button from '../Button';
import Link from '../Link';
import FAIcon from '../Icon/FAIcon';
import {
  borderColor,
  darkBlue,
  darkBrown,
  darkGreen,
  highlightedExpert,
  white,
  black,
} from '../../core/colors';
import KeywordList from '../KeywordList/KeywordList';
import LongText from '../LongText/LongText';

const getStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: 20,
    border: `1px solid ${borderColor}`,
    backgroundColor: white,
    color: darkBrown,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  dark: {
    backgroundColor: darkBlue,
    borderColor: 'transparent',
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
  },
  nameLink: ({ dark }) => ({
    ...(dark ? { color: white } : {}),
  }),
  headline: ({ dark }) => ({
    margin: '10px 0',
    ...(dark ? { color: white } : {}),
  }),
  summary: ({ dark }) => ({
    fontSize: 14,
    margin: '10px 0 0',
    lineHeight: 1.25,
    wordBreak: 'break-word',
    ...(dark ? { color: white } : {}),
  }),
  tidbit: ({ dark }) => ({
    ...(dark ? { color: `${white} !important` } : {}),
  }),
  highlighted: {
    backgroundColor: highlightedExpert,
  },
  badges: {
    display: 'flex',
    alignItems: 'center',
  },
  picture: ({ mobile }) => ({
    marginRight: 20,
    fontSize: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    ...(mobile
      ? {
          marginRight: 0,
          marginBottom: 5,
          textAlign: 'center',
          flex: '0 0 auto',
        }
      : {}),
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
      marginBottom: 5,
      textAlign: 'center',
      flex: '0 0 auto',
    },
  }),
  info: ({ mobile }) => ({
    flex: '1 1 auto',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    ...(mobile
      ? {
          flex: '1 0 auto',
        }
      : {}),
    [theme.breakpoints.down('md')]: {
      flex: '1 0 auto',
    },
  }),
  name: ({ inactive }) => ({
    fontSize: 20,
    fontWeight: 'bold',
    margin: 0,
    wordBreak: 'break-word',
    paddingRight: 10,
    ...(inactive
      ? {
          fontWeight: 500,
          color: black,
          '& a': {
            color: black,
          },
        }
      : {}),
  }),
  actions: {
    marginTop: 15,
  },
  title: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      marginBottom: 10,
    },
  },
  keywords: {
    marginTop: 10,
    marginBottom: 0,
  },
}));

export default function ExpertCard({
  dark,
  expert: {
    highlight,
    badge_text: badgeText,
    name,
    city,
    country,
    experience,
    title,
    picture,
    summary,
    marketing_keywords: marketingKeywords,
    url,
    can_request_consultation: canRequestConsultation,
  },
  pictureSize = 80,
  mobile,
  ...other
}) {
  const htmlUrl = url || undefined;
  const s = getStyles({ dark, inactive: !htmlUrl });

  const className = cx(s.root, {
    [s.dark]: dark,
    [s.highlighted]: highlight,
    [s.mobile]: mobile,
  });

  return (
    <div {...other} className={className}>
      <div className={s.picture}>
        <Picture
          user={{ picture_url: picture, name, html_url: htmlUrl }}
          size={pictureSize}
        />
      </div>

      <div className={s.info}>
        <div className={s.title}>
          <h3 className={s.name}>
            <Link className={s.nameLink} href={htmlUrl}>
              {name}
            </Link>
          </h3>

          <div className={s.badges}>
            {highlight && (
              <FAIcon icon="badge-check" color={darkGreen} size={20} />
            )}
            {badgeText && <div style={{ marginLeft: 8 }}>{badgeText}</div>}
          </div>
        </div>

        <div className={s.headline}>{title}</div>

        <Tidbit
          inline={false}
          className={s.tidbit}
          city={city}
          country={country}
          experience={experience}
        />

        {marketingKeywords && (
          <p className={s.keywords}>
            <KeywordList
              icon={null}
              emptyText={null}
              keywords={marketingKeywords}
              navigable={false}
            />
          </p>
        )}

        <div className={s.summary}>
          <LongText text={summary} />
        </div>

        {canRequestConsultation && (
          <div className={s.actions}>
            <Button href={`${htmlUrl}/request`} style={{ fontSize: 14 }}>
              Request Call
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

import React, { PureComponent } from 'react';

import FAIcon from '../../../components/Icon/FAIcon';
import { highlight } from '../../../core/util';
import { formatExperiencePeriod } from '../../../core/profile';
import { darkGray } from '../../../core/colors';
import s from './Education.module.scss';

class Education extends PureComponent {
  render() {
    const { education } = this.props;
    if (!education || education.length === 0) return null;
    return (
      <div className={s.educations}>
        {education.map((e) => {
          const period = formatExperiencePeriod(
            e.start_date,
            e.end_date,
            false,
            {
              duration: false,
            }
          );
          const description =
            e.description_snippets && e.description_snippets.join(' (…) ');

          const titleSeparator = e.degree && e.field_of_study ? ' · ' : '';
          const title = `${e.degree || ''}${titleSeparator}${e.field_of_study || ''
            }`;

          const schoolSeparator =
            e.school && (period || description) ? ' · ' : '';
          const periodSeparator = period && description ? ' · ' : '';

          return (
            <div key={JSON.stringify(e)} className={s.education}>
              <div className={s.educationIcon}>
                <FAIcon
                  iconSet="fal"
                  icon="graduation-cap"
                  style={{ marginRight: 5 }}
                  color={darkGray}
                  size={14}
                />
              </div>
              <div className={s.educationInfo}>
                <div
                  className={s.educationTitle}
                  dangerouslySetInnerHTML={{ __html: highlight(title) }}
                />
                <div className={s.educationPeriod}>
                  {e.school && (
                    <span
                      dangerouslySetInnerHTML={{ __html: highlight(e.school) }}
                    />
                  )}
                  {schoolSeparator}
                  {period}
                  {periodSeparator}
                  {description && (
                    <span
                      className={s.educationSummary}
                      dangerouslySetInnerHTML={{
                        __html: highlight(`(…) ${description}…`),
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default Education;

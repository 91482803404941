import React from 'react';
import cx from 'classnames';
import Message from '../Message';
import { white, sand, darkGray } from '../../core/colors';
import MaterialIcon from '../Icon/MaterialIcon';
import s from './MessageExample.module.scss';

function MessageExample() {
  return (
    <div className={s.root}>
      <div className={s.instructions}>
        Upgrade to an Advanced or Enterprise account to message Experts.
        <br />
        Ask clarifying questions or request additional documents.
      </div>
      <div className={s.exampleBox}>
        <Message
          pictureSize={40}
          backgroundColor={sand}
          bubbleClassName={cx(s.messageBubble, s.messageBubbleRight)}
          align="top"
          position="right"
          user={{ picture_url: require('./avatar1.png') }}
        >
          <div className={s.message}>
            Hello! Great to meet you, and very informative conversation. Would
            you mind sending the report you referenced during our call so I can
            share with my team?
          </div>
        </Message>
        <Message
          pictureSize={40}
          backgroundColor={white}
          bubbleClassName={cx(s.messageBubble, s.messageBubbleLeft)}
          position="left"
          align="top"
          user={{ picture_url: require('./avatar2.png') }}
          style={{ marginTop: 15 }}
        >
          <div className={s.message}>Yes of course! Will send momentarily.</div>
        </Message>
        <Message
          pictureSize={40}
          backgroundColor={white}
          bubbleClassName={cx(s.messageBubble, s.messageBubbleLeft)}
          position="left"
          align="top"
          user={{ picture_url: require('./avatar2.png') }}
          style={{ marginTop: 15 }}
        >
          <div className={s.message}>
            <MaterialIcon
              className={s.fileDownloadIcon}
              icon="file_download"
              color={darkGray}
            />
            <span className={s.filename}>2017_Q4_Findings.pdf</span>
          </div>
        </Message>
      </div>
    </div>
  );
}

MessageExample = MessageExample;

export default MessageExample;

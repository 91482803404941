import moment from 'moment-timezone';

function toYear(date) {
  return moment(date, 'YYYY-MM-DD').year();
}

function toMoment(date) {
  return moment(date, 'YYYY-MM-DD');
}

function formatExperienceDuration(startMoment, endMoment) {
  if (
    !startMoment ||
    !endMoment ||
    !startMoment.isValid() ||
    !endMoment.isValid()
  ) {
    return '';
  }

  const durationInMonths = endMoment
    .endOf('month')
    .diff(startMoment, 'months', true);

  if (durationInMonths < 0) {
    return '';
  }

  const years = Math.trunc(durationInMonths / 12);
  let months = Math.ceil(durationInMonths % 12);

  if (years === 0 && months === 0) {
    months = 1;
  }

  const pluralizedYear = years === 1 ? 'year' : 'years';
  const pluralizedMonth = months === 1 ? 'month' : 'months';

  if (years === 0) {
    return `${months} ${pluralizedMonth}`;
  }

  if (months === 0) {
    return `${years} ${pluralizedYear}`;
  }

  return `${years} ${pluralizedYear}, ${months} ${pluralizedMonth}`;
}

export function formatExperiencePeriod(
  startDate,
  endDate,
  current,
  { duration = true } = {}
) {
  if (!startDate && !endDate) {
    return '';
  }

  const startYear = startDate ? toYear(startDate) : '?';
  const endYear = current ? 'Present' : endDate ? toYear(endDate) : '?';
  const experiencePeriod = `${startYear} - ${endYear}`;
  const experienceDuration = formatExperienceDuration(
    toMoment(startDate),
    current ? moment() : toMoment(endDate)
  );

  return duration && experienceDuration
    ? `${experiencePeriod} (${experienceDuration})`
    : experiencePeriod;
}

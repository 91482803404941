import React from 'react';
import LayoutPage from '../../components/Layout/LayoutPage';
import ConsultationsStarting from '../../components/ConsultationsStarting';
import ChangePasswordForm from '../../components/Forms/Profile/ChangePasswordForm';
import SecurityForm from '../../components/Forms/Profile/Security';
import Rate from './Rate';
import Privacy from './Privacy';
import Emails from './Emails';
import Location from './Location';
import Communication from './Communication';
import Settings from '../../components/Settings';
import { notify } from '../../actions/ui';
import BillingSettings from '../../components/BillingSettings';
import CreditSettings from '../../components/CreditSettings';
import CreditPurchaseForm from '../../components/CreditPurchaseForm';
import { fetchUser } from '../../actions/user';
import { fetchProfile } from '../../actions/profile';
import { fetchStarting } from '../../actions/consultation';
import { fetchCountries } from '../../actions/country';
import { fetchBillingAccount, fetchPrices } from '../../actions/billing';
import { formatAccountType } from '../../core/billing';
import config from '../../../config';

export default {
  path: '/settings/:section?/:subsection?',

  async action({ store, params, query }) {
    const path = !params.section
      ? null
      : !params.subsection
      ? params.section
      : `${params.section}/${params.subsection}`;

    const { error } = query;

    const { viewer } = store.getState();
    const accountId = viewer.billing_account_id;

    const fetches = [
      fetchStarting(),
      fetchCountries(),
      fetchUser(viewer.username, {
        addresses: true,
        otpAuthEnabled: true,
        expertRequestCandidates: true,
      }),
      fetchProfile(viewer.username, {
        force: true,
        internalNetworks: true,
      }),
      fetchPrices(accountId),
    ];
    if (accountId) {
      fetches.push(fetchBillingAccount(accountId));
    }
    if (error) {
      fetches.push(
        store.dispatch(
          notify(
            'Your country is not supported for automated payouts. Please contact support.',
            'error'
          )
        )
      );
    }
    await Promise.all(fetches.map(store.dispatch));

    const { billing } = store.getState();

    const account = accountId
      ? billing.accounts[accountId]
      : {
          account_type: 'basic',
          entity_type: 'individual',
        };

    const requesterItems = [
      {
        title: 'Credits',
        path: 'credits',
        component: CreditSettings,
        componentProps: {
          account,
          purchasePath: `/settings/credits/purchase`,
          // memberNotOwner: !memberOwner,
          accountType: formatAccountType(account.account_type),
          seatCount: account.seat_count,
        },
      },
      {
        title: 'Purchase Credits',
        path: 'credits/purchase',
        component: CreditPurchaseForm,
        componentProps: {
          account,
          purchaseDonePath: `/settings/credits`,
        },
      },
      {
        title: 'Billing',
        path: 'billing',
        url: true,
        component: BillingSettings,
        componentProps: {
          account,
        },
      },
    ];

    const expertItems = [
      {
        title: 'Rate & Availability',
        path: 'rate',
        component: Rate,
      },
      {
        title: 'Privacy',
        path: 'privacy',
        component: Privacy,
      },
    ];
    if (config.enablePayoutDashboard) {
      expertItems.push({
        title: 'Payout Dashboard',
        path: 'payout',
        url: true,
        component: 'div',
      });
    }

    const nav = [
      {
        title: 'Settings',
        items: [
          {
            title: 'Emails',
            path: 'emails',
            component: Emails,
          },
          {
            title: 'Location',
            path: 'location',
            component: Location,
          },
          {
            title: 'Communication',
            path: 'communication',
            component: Communication,
          },
          {
            title: 'Change Password',
            path: 'password',
            component: ChangePasswordForm,
            id: 'settingChangePassword',
          },
          {
            title: 'Security',
            path: 'security',
            component: SecurityForm,
          },
        ],
      },
      {
        title: 'Requester',
        items: requesterItems,
      },
      {
        title: 'Expert',
        items: expertItems,
      },
    ];

    const sections = nav.map((section) => ({
      ...section,
      items: section.items.map((nav) => ({
        ...nav,
        componentProps: {
          ...nav.componentProps,
          userId: viewer.id,
          profileId: viewer.profile.id,
        },
      })),
    }));

    let section;
    if (!path) {
      section = sections[0].items[0];
    } else {
      for (let i = 0; i < sections.length; i++) {
        const part = sections[i];
        section = part.items.find((e) => e.path === path);
        if (section) {
          break;
        }
      }
    }

    if (!section) {
      return;
    }

    return {
      title: `${section.title} Settings`,
      component: (
        <LayoutPage showNav selected="settings">
          <ConsultationsStarting />
          <Settings pathPrefix="/settings" nav={sections} {...section} />
        </LayoutPage>
      ),
    };
  },
};

import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { Form, Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { TextField, PhoneInput } from '../../FormAdapters';
import SelectTimezone from '../../SelectTimezone';
import Languages from './Languages';
import { isPhoneValid } from '../../../core/util';
import { notify } from '../../../actions/ui';
import { updateProfile } from '../../../actions/profile';
import { setAddress } from '../../../actions/address';
import { ErrAddressAlreadyTaken } from '../../../core/address';

const useStyles = makeStyles({
  contact: {
    maxWidth: 400,
  },
});

function CommunicationForm({
  component: Container,
  profile,
  allCountries,
  editPhone,
  onClose,
  phoneRequired,
  showSuccessMessage,

  profileId,
  notify,
  updateProfile,
  setAddress,

  ...other
}) {
  const validate = useCallback(
    (values) => {
      const errors = {};

      if (phoneRequired && !values.phone) {
        errors.phone = 'Required';
      }

      if (values.phone && !isPhoneValid(values.phone)) {
        errors.phone = 'Phone must be valid.';
      }

      return errors;
    },
    [phoneRequired]
  );

  const handleSubmit = useCallback(async (values) => {
    const { phone, timezone, skype, languages } = values;

    try {
      if (phone) {
        await setAddress(profileId, 'phone', phone, true);
      }

      await updateProfile({
        id: profileId,
        timezone,
        skype,
        languages: languages && languages.filter((l) => l),
      });

      if (showSuccessMessage) notify('Settings updated.');

      if (onClose) onClose(values);
    } catch (err) {
      if (err.message === ErrAddressAlreadyTaken.message) {
        return { phone: 'Phone number is already in use.' };
      }
      notify('An error occurred when saving the profile.', 'error');
    }
  }, []);

  const initialValues = useMemo(() => {
    const phone = profile.phones && profile.phones.find((p) => p.primary);
    return {
      phone: phone && phone.display,
      languages: profile.languages || [],
      skype: profile.skype,
      timezone: profile.timezone,
    };
  }, []);

  const s = useStyles();

  return (
    <Form
      validate={validate}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      mutators={{ ...arrayMutators }}
    >
      {({ form, handleSubmit }) => {
        const handleReset = () => {
          form.reset();
          if (onClose) onClose();
        };

        const { push } = form.mutators;

        return (
          <Container {...other} onConfirm={handleSubmit} onCancel={handleReset}>
            <div className={s.contact}>
              <Field
                component={SelectTimezone}
                name="timezone"
                label="Time Zone"
              />

              <Field component={TextField} name="skype" label="Skype Name" />

              {editPhone && (
                <Field
                  component={PhoneInput}
                  type="tel"
                  name="phone"
                  label="Mobile Phone"
                  allCountries={allCountries}
                  showExampleOnError
                />
              )}
            </div>

            <FieldArray
              component={Languages}
              name="languages"
              label="Languages"
              onAdd={() => push('languages', {})}
            />
          </Container>
        );
      }}
    </Form>
  );
}

CommunicationForm = connect(
  (state, ownProps) => ({
    allCountries: state.countries,
    profile: state.profiles.fullProfiles[ownProps.profileId],
  }),
  {
    updateProfile,
    setAddress,
    notify,
  }
)(CommunicationForm);

export default CommunicationForm;

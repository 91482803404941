import ActionTypes from '../core/ActionTypes';

const { APP_NOTIFICATION__LIST, APP_NOTIFICATION__REMOVE } = ActionTypes;

export function fetchAppNotifications({
  collection = 'default',
  forceReload = false,
} = {}) {
  return async (dispatch, getState, { graphql }) => {
    const col = getState().appNotifications[collection];
    if (!forceReload && col) return col;

    const { appNotifications } = await graphql.query(`query {
      appNotifications {
        id
        service
        code
        created_at
        read_at
        dismissed_at
        payload {
          ... on RawAppNotificationPayload {
            raw
          }
          ... on NetworkExpertAcceptedInvitationAppNotificationPayload {
            profile {
              name
              url_endpoint
              html_url
              picture_url
            }
          }
          ... on GroupMemberAwaitingApprovalAppNotificationPayload {
            member {
              id
              group {
                id
                name
                html_url
              }
              user {
                name
                html_url
                picture_url
              }
            }
          }
        }
      }
    }`);

    dispatch({
      type: APP_NOTIFICATION__LIST,
      collection,
      appNotifications,
    });

    return appNotifications;
  };
}

export function readAppNotifications(ids) {
  return async (dispatch, getState, { graphql }) => {
    await graphql.mutate(
      `(
      $ids: [String]
    ) {
      readAppNotifications(ids: $ids) { id }
    }`,
      { ids }
    );
  };
}

export function dismissAppNotification(id) {
  return async (dispatch, getState, { graphql }) => {
    const { dismissAppNotification } = await graphql.mutate(
      `(
      $id: String!
    ) {
      dismissAppNotification(id: $id) { id }
    }`,
      { id }
    );

    dispatch({
      type: APP_NOTIFICATION__REMOVE,
      notificationId: dismissAppNotification.id,
    });
  };
}

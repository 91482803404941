import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Divider from '../Divider';
import SocialButton from '../SocialButton';
import Tidbit from '../Tidbit';
import ProfileCompleteness from '../ProfileCompleteness';
import Badges from './Badges';
import Sidebar from './Sidebar';
import Headline from './Headline';
import Summary from './Summary';
import Questions from './Questions';
import Expertise from './Expertise';
import Education from './Education';
import Experience from './Experience';
import Dialogs from './Dialogs';
import CvButton from '../CvButton';
import Button from '../Button';
import { updateProfile, updatePicture } from '../../actions/profile';
import { fetchUser } from '../../actions/user';
import { openPictureDialog, openResumeDialog, popup } from '../../actions/ui';
import { track } from '../../actions/tracking';
import { darkGreen } from '../../core/colors';

const getStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
    alignItems: 'flex-start',
  },
  defaultCover: {
    backgroundColor: darkGreen,
    height: 120,
    width: '100%',
  },
  tidbit: {
    [theme.breakpoints.down('md')]: {
      margin: '0 auto',
      textAlign: 'center',
    },
  },
  cvButton: {
    margin: '25px 0',
  },
  footer: {
    marginTop: 30,
    textAlign: 'right',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  paddingMobile: {
    [theme.breakpoints.down('md')]: {
      padding: '0 10px',
    },
  },
}));

function ProfilePreview({
  profileId,
  updateProfile,
  updatePicture,
  editable = true,
  viewer,
  user,
  profile,
  className,
  handleSubmit,
  openPictureDialog,
  openResumeDialog,
  ...other
}) {
  const s = getStyles();

  const theme = useTheme();

  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const [editSections, setEditSections] = useState({});
  const [completenessClosed, setCompletenessClosed] = useState(false);

  const handleUpdatePicture = useCallback(
    (file) => {
      updatePicture(profileId, file.url);
    },
    [profileId, updatePicture]
  );

  const getSectionsInitialState = useCallback(() => {
    const sections = { ...editSections };
    Object.keys(sections).forEach((k) => {
      sections[k] = false;
    });
    return sections;
  }, [editSections]);

  const openDialog = useCallback(
    (key) => {
      setEditSections({
        ...getSectionsInitialState(),
        [key]: true,
      });
    },
    [editSections, setEditSections, getSectionsInitialState]
  );

  const closeDialog = useCallback(() => {
    setEditSections(getSectionsInitialState());
  }, [editSections, setEditSections, getSectionsInitialState]);

  const attachCV = useCallback(
    (file) => {
      if (!file) return;
      updateProfile({ id: profileId, cv_url: file.url });
    },
    [profileId, updateProfile]
  );

  const removeCV = useCallback(() => {
    updateProfile({ id: profileId, cv_url: '' });
  }, [profileId, updateProfile]);

  const handlePictureDialog = () => {
    openPictureDialog().then(handleUpdatePicture);
  };

  const handleResumeDialog = () => {
    openResumeDialog().then(attachCV);
  };

  if (!profile) return null;

  return (
    <form className={className} onSubmit={(e) => handleSubmit(e)}>
      <Grid container spacing={mobile ? 0 : 4}>
        {mobile && <div className={s.defaultCover} />}

        <Grid item md={3} sm={12} xs={12} className={s.paddingMobile}>
          <Sidebar
            preview
            user={user}
            profile={profile}
            pictureEditable
            onPictureChange={handleUpdatePicture}
            onEditName={() => openDialog('name')}
          />
        </Grid>

        <Grid item md={9} sm={12} xs={12} className={s.paddingMobile}>
          {editable && !mobile && !completenessClosed && (
            <ProfileCompleteness
              isSignup
              profile={profile}
              onRequestClose={() => setCompletenessClosed(true)}
              style={{ marginBottom: 20 }}
              nameAction={() => openDialog('name')}
              pictureAction={handlePictureDialog}
              introductionAction={() => openDialog('headline')}
              experiencesAction={() => openDialog('experiences')}
              keywordsAction={() => openDialog('keywords')}
              expertiseAction={() => openDialog('expertise')}
              questionsAction={() => openDialog('questions')}
              locationAction={() => openDialog('location')}
              communicationAction={() => openDialog('communication')}
              phoneAction={() => openDialog('phones')}
              educationsAction={() => openDialog('education')}
              linkedinAction={() => openDialog('linkedin')}
              resumeAction={handleResumeDialog}
            />
          )}

          {!mobile && (
            <Badges
              viewer={viewer}
              user={user}
              profile={profile}
              onEditMarketplace={() => openDialog('available_marketplace')}
            />
          )}

          {!mobile && <Divider />}

          <div className={s.row}>
            <Tidbit
              className={s.tidbit}
              city={profile.city}
              country={profile.country}
              languages={profile.languages}
              editable={editable}
              inline={!mobile}
              onEditLocation={() => openDialog('location')}
              onEditLanguages={() => openDialog('communication')}
            />

            {profile.linkedin_url && !mobile && (
              <SocialButton startIcon="linkedin" href={profile.linkedin_url} />
            )}
          </div>

          <Headline
            editable
            profile={profile}
            onEdit={() => openDialog('headline')}
          />

          {mobile && <Divider />}

          <Summary
            editable
            profile={profile}
            onEdit={() => openDialog('headline')}
          />

          <div className={s.cvButton}>
            <CvButton
              editable={editable}
              url={profile.cv_url}
              onUpload={attachCV}
              onRemove={removeCV}
            />
          </div>

          <Divider />

          <Questions
            profile={profile}
            editable={editable}
            onEdit={() => openDialog('questions')}
          />

          <Divider />

          <Expertise
            profile={profile}
            editable={editable}
            onEdit={() => openDialog('expertise')}
          />

          <Divider />

          <Experience
            experiences={profile.experiences}
            editable={editable}
            onEdit={() => openDialog('experiences')}
          />

          <Divider />

          <Education
            education={profile.education}
            editable={editable}
            onEdit={() => openDialog('education')}
          />

          <Divider />

          <Dialogs
            user={user}
            profile={profile}
            editSections={editSections}
            closeDialog={closeDialog}
          />

          {other.form && (
            <div className={s.footer}>
              <Button size="large" type="submit" fullWidth={mobile}>
                Finish
              </Button>
            </div>
          )}
        </Grid>
      </Grid>
    </form>
  );
}

export default connect(
  (state, ownProps) => ({
    viewer: state.viewer,
    user: ownProps.userId ? state.users[ownProps.userId] : undefined,
    profile: state.profiles.fullProfiles[ownProps.profileId],
  }),
  {
    fetchUser,
    updateProfile,
    updatePicture,
    openPictureDialog,
    openResumeDialog,
    popup,
    track,
  }
)(ProfilePreview);

import React, {
  useState,
  useCallback,
  useRef,
  useMemo,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';
import CountryDropdown from '../CountryDropdown';
import { darkGray } from '../../core/colors';
import { fetchCountries } from '../../actions/country';

const phoneUtil = PhoneNumberUtil.getInstance();

function hasCallingCodeChanged(country1, country2) {
  if (country1 === country2) return false;

  const callingCode1 = phoneUtil.getCountryCodeForRegion(country1);
  const callingCode2 = phoneUtil.getCountryCodeForRegion(country2);

  return callingCode1 !== callingCode2;
}

function getCountryCodeFromNumber(value) {
  try {
    const regionCode = phoneUtil.getRegionCodeForCountryCode(parseInt(value));
    if (regionCode && regionCode !== 'ZZ') return regionCode;
    const phoneNumber = phoneUtil.parse(value);
    return phoneUtil.getRegionCodeForNumber(phoneNumber);
  } catch (err) {
    return '';
  }
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'flex-end',
  },

  callingCode: {
    display: 'inline-block',
    color: darkGray,
    marginLeft: 10,
  },
});

function PhoneInput({
  allCountries,
  fetchCountries,
  input,
  meta,
  errorText,
  showExampleOnError,
  containerStyle,
  helperText,
  inputProps,
  ...props
}) {
  const s = useStyles();
  const inputRef = useRef();

  const defaultCountryCode = getCountryCodeFromNumber(props.value) || 'US';
  const defaultValue = `+${phoneUtil.getCountryCodeForRegion(
    defaultCountryCode
  )}`;

  const [countryCode, setCountryCode] = useState(defaultCountryCode);

  const onChange = input ? input.onChange : props.onChange;
  const value = input ? input.value : props.value;

  useEffect(() => {
    const newCountryCode = getCountryCodeFromNumber(value);
    if (newCountryCode && hasCallingCodeChanged(newCountryCode, countryCode)) {
      setCountryCode(newCountryCode);
    }
  }, [countryCode, value]);

  useEffect(() => {
    fetchCountries();
  }, []);

  useEffect(() => {
    if (!countryInteracted.current) return;

    countryInteracted.current = false;
    const len = value ? value.length : 0;
    const { current } = inputRef;
    const inputNode = current.getInputNode ? current.getInputNode() : current;
    inputNode.focus();
    inputNode.setSelectionRange(len, len);
  }, [countryCode]);

  const countryInteracted = useRef(false);
  const handleCountryCodeChange = useCallback(
    (countryCode) => {
      countryInteracted.current = true;

      const callingCode = phoneUtil.getCountryCodeForRegion(countryCode);

      try {
        const phoneNumber = phoneUtil.parse(value, countryCode);
        phoneNumber.setCountryCode(callingCode);
        onChange(
          phoneUtil.format(phoneNumber, PhoneNumberFormat.INTERNATIONAL)
        );
      } catch (err) {
        onChange(`+${callingCode}`);
      }

      setCountryCode(countryCode);
    },
    [value, onChange]
  );

  const handleChange = useCallback(
    (e) => {
      const { value } = e.target;

      const formattedValue = value
        ? value.startsWith('+')
          ? value
          : `+${value}`
        : '';
      onChange(formattedValue);
    },
    [onChange]
  );

  const renderOption = useCallback(
    (countryCode) => {
      const country = allCountries.find(
        (c) => c.country_iso2_code === countryCode
      );
      if (!country) return;

      const callingCode = phoneUtil.getCountryCodeForRegion(countryCode);
      if (!callingCode) return;

      return (
        <div>
          {country.name}
          <span className={s.callingCode}> +{callingCode}</span>
        </div>
      );
    },
    [allCountries]
  );

  const errorWithExample = useCallback(
    (error) => {
      if (!showExampleOnError || !error) return error;

      const example =
        phoneUtil.getExampleNumber(countryCode) ||
        phoneUtil.getExampleNumber('US');

      const exampleFormat = phoneUtil.format(
        example,
        PhoneNumberFormat.INTERNATIONAL
      );

      return `${error}. Example: ${exampleFormat}`;
    },
    [showExampleOnError, countryCode]
  );

  const options = useMemo(
    () => allCountries.map((c) => c.country_iso2_code).filter(Boolean),
    [allCountries]
  );

  return (
    <TextField
      {...props}
      {...inputProps}
      value={value || defaultValue}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={{
        ...props.InputProps,
        startAdornment: (
          <InputAdornment position="start">
            <CountryDropdown
              value={countryCode}
              onChange={handleCountryCodeChange}
              options={options}
              renderOption={renderOption}
            />
          </InputAdornment>
        ),
      }}
      helperText={errorWithExample(helperText)}
      onChange={handleChange}
      inputRef={inputRef}
      style={containerStyle}
    />
  );
}

PhoneInput = connect(
  (state) => ({
    allCountries: state.countries,
  }),
  {
    fetchCountries,
  }
)(PhoneInput);

export default PhoneInput;

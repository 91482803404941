import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import Register from '../Register';
import EmptyMessage from '../EmptyMessage';
import MaterialIcon from '../Icon/MaterialIcon';
import { red500, darkBlue } from '../../core/colors';
import { requestAddProjectMember } from '../../actions/project';
import { notify } from '../../actions/ui';
import s from './RequestProjectAccess.module.scss';
import Button from '../Button/Button';

const ALREADY_ADDED = 'GraphQL Error: project member already added';

class RequestProjectAccess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accessRequested: props.accessRequested || false,
    };
  }

  handleRequestAdd = () => {
    const { requestAddProjectMember, expertRequestId, projectId, notify } =
      this.props;
    requestAddProjectMember({ expertRequestId, projectId })
      .then(() => {
        this.setState({ accessRequested: true });
      })
      .catch((err) => {
        if (err.message === ALREADY_ADDED) {
          return this.setState({ accessRequested: true });
        }
        notify('An error occurred when requesting access to project.', 'error');
      });
  };

  render() {
    const {
      props: { viewer, expertRequestId, projectId, path, query, ...other },
      state: { accessRequested },
    } = this;

    const isSignedIn = !!viewer.id;

    const queryPart = query ? `?${queryString.stringify(query)}` : '';
    const nextUrl = `/${path}/${expertRequestId || projectId}${queryPart}`;

    const actionText = isSignedIn ? 'Request' : 'Log in or request';

    return (
      <div className={s.root}>
        {accessRequested ? (
          <EmptyMessage
            border={false}
            style={{ padding: '30px 0 15px' }}
            icon={
              <MaterialIcon icon="access_time" color={darkBlue} size={52} />
            }
            title="You have requested access to this project"
            body="We will notify you when the project owner accepts your request."
          />
        ) : (
          <EmptyMessage
            border={false}
            style={{ padding: '30px 0 15px' }}
            icon={<MaterialIcon icon="block" color={red500} size={52} />}
            title="You do not have access to this project"
            body={`${actionText} access from the project owner to view and add experts, receive updates, and get calendar invitations for booked calls.`}
            action={
              isSignedIn && (
                <Button primary size="large" onClick={this.handleRequestAdd}>
                  Request Access
                </Button>
              )
            }
          />
        )}
        {!isSignedIn && <Register {...other} nextUrl={nextUrl} />}
      </div>
    );
  }
}

RequestProjectAccess = RequestProjectAccess;

RequestProjectAccess = connect(
  (state) => ({
    viewer: state.viewer,
  }),
  {
    requestAddProjectMember,
    notify,
  }
)(RequestProjectAccess);

export default RequestProjectAccess;

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';

import { csvImportPreview } from '../../actions/profile';
import Button from '../../components/Button/Button';
import { openFileDialog } from '../../actions/ui';
import { Select, Checkbox } from '../../components/FormAdapters/FormAdapters';
import s from './ProfileFields.module.scss';

class ProfileFields extends PureComponent {
  state = { error: null };

  pickFile = async () => {
    const { openFileDialog, csvImportPreview, form, values, setImporting } =
      this.props;

    const {
      network_id: networkId,
      not_available_marketplace: notAvailableMarketplace,
    } = values;

    try {
      const file = await openFileDialog({
        accept: ['text/csv', '.csv'],
        maxSize: 8 * 1024 * 1024, // 8 MB
        fromSources: ['local_file_system'],
      });
      form.change('csv_ignore_rows', []);
      form.change('csv_filename', file.filename);
      form.change('csv_url', file.url);
      form.change('csv_mimetype', file.mimetype);

      this.setState({ error: null });
      setImporting(true);

      await csvImportPreview({
        file,
        networkId,
        availableMarketplace: !notAvailableMarketplace,
      });
    } catch (error) {
      this.setState({ error });
    } finally {
      setImporting(false);
    }
  };

  networkOptions = () => {
    const { groups } = this.props;
    return groups
      .map((g) => g.internal_network)
      .filter(Boolean)
      .map((n) => ({ label: n.name, value: n.id }));
  };

  render() {
    const { hasFile, disabled, admins, requests } = this.props;
    const { error } = this.state;

    const chooseFileButton = !disabled && (
      <Button color="lightTan" size="medium" onClick={this.pickFile}>
        {hasFile ? 'Change File' : 'Choose File'}
      </Button>
    );

    return (
      <div>
        <div className={s.csvFormRow}>
          <div className={s.csvFormColumn}>
            <Field
              component={Select}
              autocomplete
              disabled={disabled}
              label="Add to Expert Request"
              name="request_id"
              options={(requests || { edges: [] }).edges.map((r) => ({
                value: r.node.id,
                label: `${r.node.name} (${r.node.id})`,
              }))}
            />
          </div>
          <div className={s.csvFormColumn}>
            <Field
              component={Select}
              autocomplete
              label="Uploaded By"
              name="created_by"
              options={(admins || []).map((r) => ({
                value: r.id,
                label: `${r.name} (${r.id})`,
              }))}
            />
          </div>
          <div className={s.csvFormColumn}>
            <Field
              component={Select}
              autocomplete
              disabled={disabled}
              label="Add to network"
              name="network_id"
              options={this.networkOptions()}
            />
            <Field
              component={Checkbox}
              type="checkbox"
              disabled={disabled}
              name="not_available_marketplace"
              label="Do not add to marketplace"
            />
          </div>
        </div>
        <div>
          {error ? (
            <div className={s.errorPanel}>
              <div className={s.errorMessage}>
                {error.toString ? error.toString() : JSON.stringify(error)}
              </div>
              {chooseFileButton}
            </div>
          ) : (
            chooseFileButton
          )}
        </div>
      </div>
    );
  }
}

ProfileFields = connect(
  (state) => ({
    requests: state.expertRequests.open,
    groups: ((state.groups.networks || {}).edges || []).map((g) => g.node),
    admins: Object.values(
      state.groups.internal.edges.reduce((acc, group) => {
        group.node.members.edges.forEach((m) => {
          if (!m.node.user) return;
          acc[m.node.user.id] = m.node.user;
        });
        return acc;
      }, {})
    ),
  }),
  {
    openFileDialog,
    csvImportPreview,
  }
)(ProfileFields);

ProfileFields = ProfileFields;

export default ProfileFields;

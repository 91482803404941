import React from 'react';
import EmptyMessage from '../../components/EmptyMessage';
import AuthPage from '../../components/AuthPage';
import { urlToInternalPath } from '../../core/util';

class ExpertAuthPage extends React.Component {
  render() {
    const { expertRequest, signup, tags } = this.props;

    return (
      <AuthPage
        signupType="expert"
        nextUrl={`${urlToInternalPath(
          expertRequest.public_html_url
        )}/request_add`}
        signup={signup}
        signupLink={`/expert_request/${expertRequest.slug}/request_add/signup`}
        signinLink={`/expert_request/${expertRequest.slug}/request_add/signin`}
        tags={tags}
      >
        <EmptyMessage
          border={false}
          style={{ padding: 15 }}
          title="We’re glad this project seems like a good match."
          body="We just need you to complete our quick application."
        />
      </AuthPage>
    );
  }
}

export default ExpertAuthPage;

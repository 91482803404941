import moment from 'moment-timezone';
import ActionTypes from '../core/ActionTypes';

const {
  MESSAGE_TEMPLATES__UPDATE,
  MESSAGE_TEMPLATES__LOADING,
  MESSAGE_TEMPLATES__LIST,
  MESSAGE_TEMPLATES__LOADED,
} = ActionTypes;

export function saveTemplate(template) {
  return async (dispatch, getState, { graphql }) => {
    const { saveMessageTemplate: saved } = await graphql.mutate(
      `(
      $id: String
      $title: String!
      $body: String!
    ) {
      saveMessageTemplate(
        id: $id,
        title: $title,
        body: $body,
      ) {
        id
        title
        created_at
        body
      }
    }`,
      template
    );

    dispatch({ type: MESSAGE_TEMPLATES__UPDATE, template: saved });
  };
}

export function deleteTemplate(templateId) {
  return async (dispatch, getState, { graphql }) => {
    const { deleteMessageTemplate: deleted } = await graphql.mutate(
      `(
      $templateId: String!
    ) {
      deleteMessageTemplate( id: $templateId ) { id }
    }`,
      { templateId }
    );

    dispatch({
      type: MESSAGE_TEMPLATES__UPDATE,
      templateId: deleted.id,
      template: null,
    });
  };
}

export function fetchTemplates() {
  return async (dispatch, getState, { graphql }) => {
    const alreadyLoaded = cachedTemplates(getState());
    if (alreadyLoaded) return alreadyLoaded;

    // set loading
    const renderArgs = stringifyRenderArgs();
    dispatch({ type: MESSAGE_TEMPLATES__LOADING, renderArgs });
    try {
      // fetch templates
      const { messageTemplates: templates } = await graphql.query(`
        {
          messageTemplates {
            id
            title
            created_at
            body
          }
        }
      `);
      dispatch({
        type: MESSAGE_TEMPLATES__LIST,
        renderArgs,
        templates,
      });
    } finally {
      // done loading
      dispatch({ type: MESSAGE_TEMPLATES__LOADED, renderArgs });
    }
  };
}

export function fetchTemplate(templateId, { senderId, requestId, expertId }) {
  return async (dispatch, getState, { graphql }) => {
    if (
      (senderId || requestId || expertId) &&
      (!senderId || !requestId || !expertId)
    ) {
      throw new Error(
        'all sender, request and candidate ids must be ' +
          'specified to render templates'
      );
    }

    const renderArgs = stringifyRenderArgs(senderId, requestId, expertId);
    const alreadyLoaded = cachedTemplates(getState(), {
      senderId,
      requestId,
      expertId,
    });
    if (alreadyLoaded && (alreadyLoaded.rendered || !renderArgs)) {
      return alreadyLoaded;
    }

    // set loading
    dispatch({ type: MESSAGE_TEMPLATES__LOADING, renderArgs });
    try {
      // fetch templates
      const { messageTemplate: template } = await graphql.query(
        `
      query (
        $templateId: String!
        ${senderId ? '$renderingArgs: [RenderingArgsInput]' : ''}
      )
      {
        messageTemplate (id:$templateId) {
          id
          title
          created_at
          body
          ${senderId ? 'rendered(args: $renderingArgs  )' : ''}
        }
      }
      `,
        {
          templateId,
          renderingArgs: [
            { name: 'sender_id', value: senderId },
            { name: 'request_id', value: requestId },
            { name: 'expert_id', value: expertId },
          ],
        }
      );
      dispatch({
        type: MESSAGE_TEMPLATES__UPDATE,
        renderArgs,
        template,
      });
      return template;
    } finally {
      // done loading
      dispatch({ type: MESSAGE_TEMPLATES__LOADED, renderArgs });
    }
  };
}

export function renderTemplate(body, { senderId, requestId, profileId }) {
  return async (dispatch, getState, { graphql }) => {
    if (!senderId || !requestId || !profileId) {
      throw new Error(
        'all sender, request and candidate ids must be ' +
          'specified to render templates'
      );
    }

    // render template
    const { renderTemplate: rendered } = await graphql.query(
      `
    query (
      $body: String!
      ${senderId ? '$renderingArgs: [RenderingArgsInput]' : ''}
    )
    {
      renderTemplate (body: $body, args: $renderingArgs)
    }
    `,
      {
        body,
        renderingArgs: [
          { name: 'sender_id', value: senderId },
          { name: 'request_id', value: requestId },
          { name: 'profile_id', value: profileId },
        ],
      }
    );
    return rendered;
  };
}

export function cachedTemplates(state, { senderId, requestId, expertId } = {}) {
  const renderArgs = stringifyRenderArgs(senderId, requestId, expertId);
  const { messageTemplates } = state;
  if (
    messageTemplates[renderArgs] &&
    moment().isBefore(messageTemplates[renderArgs].expire)
  ) {
    return messageTemplates[renderArgs];
  }
  return null;
}

function stringifyRenderArgs(senderId, requestId, expertId) {
  if (!senderId || !requestId || !expertId) return '';
  return `${senderId}:${requestId}:${expertId}`;
}

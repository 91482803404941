import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm, FieldArray } from 'redux-form';
import moment from 'moment-timezone';
import { saveEducation, removeEducation } from '../../../actions/profile';
import Education from './Education';
import Button from '../../Button/Button';
import EmptyMessage from '../../EmptyMessage';

const validate = (values) => {
  const errors = {};

  const educationErrors = [];
  values.education.forEach((edu, i) => {
    if (emptyEducation(edu)) {
      // empty education form, treat it as if it was never included
      return;
    }

    const err = {};

    if (!edu.degree) {
      err.degree = 'Required';
    }

    if (!edu.school) {
      err.school = 'Required';
    }

    if (
      edu.start_date &&
      edu.end_date &&
      moment(edu.start_date).isAfter(edu.end_date)
    ) {
      err.end_date = 'End year before start year';
    }

    if (Object.keys(err).length > 0) {
      educationErrors[i] = err;
    }
  });

  if (educationErrors.length) {
    errors.education = educationErrors;
  }

  return errors;
};

function emptyEducation(edu) {
  if (edu.degree) return false;
  if (edu.field_of_study) return false;
  if (edu.school) return false;
  if (edu.start_date) return false;
  if (edu.end_date) return false;
  if (edu.description) return false;
  return true;
}

function Educations({ fields, onAdd, change }) {
  return (
    <div>
      {fields.length > 0 ? (
        fields.map((field, index) => (
          <Education
            showRemove
            showAdd={index === fields.length - 1}
            onAdd={onAdd}
            education={fields.get(index)}
            field={field}
            key={field}
            onRemove={() => fields.remove(index)}
            change={change}
          />
        ))
      ) : (
        <EmptyMessage
          border={false}
          iconName="work"
          body="Add you first education record"
          action={<Button onClick={onAdd}>Add a Education</Button>}
        />
      )}
    </div>
  );
}

class EducationForm extends PureComponent {
  handleSubmit = (values) => {
    const { profile, onSubmit } = this.props;
    const education = values.education.filter((edu) => !emptyEducation(edu));

    const itemsToDelete = profile.education.filter(
      (current) => !education.find((edited) => edited.id === current.id)
    );

    itemsToDelete.forEach((e) => this.props.removeEducation(profile.id, e.id));

    education.forEach((e) => this.props.saveEducation(profile.id, e));

    if (onSubmit) {
      onSubmit({ education });
    }
  };

  handleReset = () => {
    const { reset, onReset } = this.props;
    reset();

    if (onReset) {
      onReset();
    }
  };

  render() {
    const {
      component: Container,
      array,
      change,
      handleSubmit,
      profile,
      ...other
    } = this.props;

    return (
      <Container
        {...other}
        onSubmit={handleSubmit(this.handleSubmit)}
        onReset={this.handleReset}
      >
        <FieldArray
          name="education"
          component={Educations}
          onAdd={() => array.push('education', {})}
          change={change}
        />
      </Container>
    );
  }
}

EducationForm = reduxForm({
  form: 'expertEducation',
  validate,
  enableReinitialize: true,
})(EducationForm);

EducationForm = connect(
  (state, ownProps) => {
    const profile = state.profiles.fullProfiles[ownProps.profileId];
    const initialEducations =
      profile.education && profile.education.length > 0
        ? profile.education
        : [{}];
    const form = state.form.expertEducation;
    const initialValues = { education: initialEducations };
    const formValues = form ? form.values : initialValues;

    return {
      profile,
      formValues,
      initialValues,
    };
  },
  {
    saveEducation,
    removeEducation,
  }
)(EducationForm);

export default EducationForm;

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Picture from '../Picture';
import Button from '../Button';
import Alert from '../Alert';
import {
  readAppNotifications,
  dismissAppNotification,
} from '../../actions/appNotification';
import { approveGroupMember, denyGroupMember } from '../../actions/group';
import { red500 } from '../../core/colors';

const useStyles = makeStyles(() => ({
  expertName: {
    fontWeight: 'bold',
  },
  notificationContents: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  notificationTitle: {
    marginBottom: 20,
  },
  notificationPhoto: {
    marginRight: 20,
  },
  approveButton: {
    marginRight: 10,
  },
}));

function GroupMemberAwaitingApprovalNotification({
  notification,
  readAppNotifications,
  approveGroupMember,
  denyGroupMember,
  dismissAppNotification,
}) {
  const s = useStyles();

  useEffect(() => {
    readAppNotifications(notification.id);
  }, []);

  const handleApprove = async () => {
    await approveGroupMember(notification.payload.member);
    await dismissAppNotification(notification.id);
  };

  const handleDeny = async () => {
    await denyGroupMember(notification.payload.member);
    await dismissAppNotification(notification.id);
  };

  const { payload } = notification;
  const { member } = payload;
  const { user, group } = member || {};
  if (!user) return null;

  return (
    <Alert preset="notification">
      <div className={s.notificationContents}>
        <div className={s.notificationPhoto}>
          <Picture user={user} size={60} />
        </div>
        <div className={s.notificationBody}>
          <div className={s.notificationTitle}>
            <span className={s.expertName}>{user.name}</span>
            &nbsp;would like to join {group.name}
          </div>
          <Button
            onClick={handleApprove}
            classes={{ root: s.approveButton }}
            size="medium"
          >
            Approve
          </Button>
          <Button
            onClick={handleDeny}
            color="white"
            fontColor={red500}
            size="medium"
          >
            Deny
          </Button>
        </div>
      </div>
    </Alert>
  );
}

GroupMemberAwaitingApprovalNotification = connect(undefined, {
  readAppNotifications,
  dismissAppNotification,
  approveGroupMember,
  denyGroupMember,
})(GroupMemberAwaitingApprovalNotification);

GroupMemberAwaitingApprovalNotification.filterNotifications = (
  state,
  notifications
) => {
  // notification must have accessible awaiting member to be accepted
  const accepted = notifications.filter(
    (n) => n.payload.member && n.payload.member.user
  );

  // at most 3 notifications
  return accepted.slice(0, 3);
};

export default GroupMemberAwaitingApprovalNotification;

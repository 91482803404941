import React, { memo, useState, useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import MaterialIcon from '../Icon/MaterialIcon';
import TestSpeaker from './TestSpeaker';
import DeviceSelector from './DeviceSelector';
import { FeatureSupport } from '../../core/telephony/voipCarrier';
import { listOrDefault } from '../../jsext/array';
import useAppContext from '../useAppContext';
import useDetectVoice from './useDetectVoice';
import s from './DeviceSettings.module.scss';
import Meter from './Meter';
import useDetectSpeaker from './useDetectSpeaker';

const defaultMicrophone = {
  deviceId: 'default',
  label: 'Default System Microphone',
};
const defaultSpeaker = {
  deviceId: 'default',
  label: 'Default System Speaker',
};
const defaultSelected = 'default';

function DeviceSettings({ selectInput, selectOutput }) {
  const { voipCarrier } = useAppContext();

  // Input
  const [inputSelectable, setInputSelectable] = useState(
    FeatureSupport.unknown
  );
  const [inputSelected, setInputSelected] = useState(defaultSelected);
  const [inputOptions, setInputOptions] = useState([defaultMicrophone]);

  // Output
  const [outputSelectable, setOutputSelectable] = useState(
    FeatureSupport.unknown
  );
  const [outputSelected, setOutputSelected] = useState(defaultSelected);
  const [outputOptions, setOutputOptions] = useState([defaultSpeaker]);

  // Meters

  const [inputVolume, setInputVolume] = useState(0); // Voip input (mic)
  const [outputVolume, setOutputVolume] = useState(0); // Voip output (speaker)
  const [testVolume, setTestVolume] = useState(0); // Test sound oupout

  const audioRef = useRef();

  const [, volumeVoice] = useDetectVoice();
  const [, volumeSpeaker] = useDetectSpeaker(audioRef);

  // Funcs

  const updateFeatureSupport = useCallback(
    ({ selectInputDevice, selectOutputDevice }) => {
      setInputSelectable(selectInputDevice);
      setOutputSelectable(selectOutputDevice);
    },
    []
  );

  const updateInputSelected = useCallback(
    (deviceId) => setInputSelected(deviceId || defaultSelected),
    []
  );

  const updateOutputSelected = useCallback(
    (deviceId) => setOutputSelected(deviceId || defaultSelected),
    []
  );

  const updateInputOptions = useCallback(
    (devices) => setInputOptions(listOrDefault(devices, [defaultMicrophone])),
    []
  );

  const updateOutputOptions = useCallback(
    (devices) => setOutputOptions(listOrDefault(devices, [defaultSpeaker])),
    []
  );

  const isFeatureDisabled = useCallback(
    (supportStatus) =>
      [FeatureSupport.unsupported, FeatureSupport.unknown].includes(
        supportStatus
      ),
    []
  );

  const syncCarrier = useCallback(async () => {
    updateFeatureSupport(voipCarrier.featureSupport());

    updateInputOptions(await voipCarrier.inputDeviceOptions());
    updateOutputOptions(await voipCarrier.outputDeviceOptions());

    updateInputSelected(voipCarrier.inputDeviceSelected());
    updateOutputSelected(voipCarrier.outputDeviceSelected());
  }, []);

  // Volume detecting

  const checkVolumeMeter = useCallback(({ outputVolume }) => {
    // setInputVolume(Math.floor((inputVolume - 0.1) * 100));
    setOutputVolume(Math.floor(outputVolume * 100));
  }, []);

  useEffect(() => {
    setInputVolume(Math.floor(volumeVoice * 100));
  }, [volumeVoice]);

  useEffect(() => {
    setTestVolume(Math.floor(volumeSpeaker * 100));
  }, [volumeSpeaker]);

  // Setup

  useEffect(() => {
    voipCarrier
      .on('featureSupport', updateFeatureSupport)
      .on('inputDeviceSelected', updateInputSelected)
      .on('inputDeviceOptions', updateInputOptions)
      .on('outputDeviceSelected', updateOutputSelected)
      .on('outputDeviceOptions', updateOutputOptions)
      .on('volumeChange', checkVolumeMeter);

    syncCarrier();

    return () => {
      voipCarrier
        .removeListener('featureSupport', updateFeatureSupport)
        .removeListener('inputDeviceSelected', updateInputSelected)
        .removeListener('inputDeviceOptions', updateInputOptions)
        .removeListener('outputDeviceSelected', updateOutputSelected)
        .removeListener('outputDeviceOptions', updateOutputOptions)
        .removeListener('volumeChange', checkVolumeMeter);
    };
  }, []);

  const inputDisabled = isFeatureDisabled(inputSelectable);
  const outputDisabled = isFeatureDisabled(outputSelectable);

  // Allows to receive update from voip and test simultaneously
  const outputCurrentVolume = Math.max(outputVolume, testVolume);

  return (
    <React.Fragment>
      <div className={s.deviceRow}>
        <div className={s.deviceColumn}>
          <DeviceSelector
            label="Microphone"
            selected={inputSelected}
            options={inputOptions}
            onSelect={({ target: { value } }) => selectInput(value)}
            disabled={inputDisabled}
          />
          <Meter className={s.meter} size={inputVolume} />
        </div>
        <div className={s.deviceOptions}>
          <IconButton color="secondary" disabled={inputVolume <= 0}>
            <MaterialIcon color="inherit" icon="mic" />
          </IconButton>
        </div>
      </div>
      <div className={s.deviceRow}>
        <div className={s.deviceColumn}>
          <DeviceSelector
            label="Speakers"
            selected={outputSelected}
            options={outputOptions}
            onSelect={({ target: { value } }) => selectOutput(value)}
            disabled={outputDisabled}
          />
          <Meter className={s.meter} size={outputCurrentVolume} />
        </div>
        <div className={s.deviceOptions}>
          <IconButton color="secondary" disabled={outputCurrentVolume <= 0}>
            <MaterialIcon color="inherit" icon="volume_up" />
          </IconButton>
          <TestSpeaker ref={audioRef} sinkId={outputSelected} />
        </div>
      </div>
      {inputDisabled && outputDisabled && <Unsupported />}
    </React.Fragment>
  );
}

DeviceSettings = connect((state) => ({
  call: state.call,
}))(DeviceSettings);
DeviceSettings = DeviceSettings;
export default memo(DeviceSettings);

function Unsupported() {
  return (
    <div className={s.unsupported}>
      Device selection not supported by your browser. You may still select the
      appropriate device on your operating system preferences.
    </div>
  );
}

Unsupported = memo(Unsupported);

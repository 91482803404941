import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import makeStyles from '@mui/styles/makeStyles';

const getStyle = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('md')]: {
      marginBottom: 10,
      alignItems: 'center',
    },
  },
  label: (props) => ({
    ...(props.selected ? { fontWeight: 600 } : {}),
  }),
}));

export default function ({
  FormControlProps,
  FormHelperTextProps,
  FormLabelProps,
  options,
  defaultValue,
  value,
  label,
  helperText,
  error,
  ...other
}) {
  return (
    <FormControl error={error} {...FormControlProps}>
      {label && <FormLabel {...FormLabelProps}>{label}</FormLabel>}
      <RadioGroup
        defaultValue={defaultValue}
        aria-label={label}
        {...other}
        onChange={(_, value) => other.onChange(value)}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            checked={value === option.value}
            classes={getStyle({ selected: value === option.value })}
            {...option}
            control={<Radio id={option.id} />}
          />
        ))}
      </RadioGroup>
      {helperText && (
        <FormHelperText {...FormHelperTextProps}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
}

import React from 'react';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  learn: {
    display: 'inline-block',
  },
});

export default function FaqLink({
  className,
  url,
  disable,
  title = 'Need help? Check out our FAQ →',
}) {
  const s = useStyles();

  return (
    !disable && (
      <a
        className={cx(s.learn, className)}
        style={{ marginTop: 10 }}
        target="_blank"
        rel="noreferrer"
        href={url}
      >
        {title}
      </a>
    )
  );
}

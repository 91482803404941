import React, { PureComponent } from 'react';
import cx from 'classnames';
import { Field } from 'react-final-form';
import { TextField } from '../../FormAdapters';
import s from './Companies.module.scss';

class Companies extends PureComponent {
  static parseCompanies(v) {
    return typeof v === 'string' ? v.split('\n') : v || [];
  }

  static formatCompanies(v) {
    return v ? v.join('\n') : '';
  }

  render() {
    const { isMobileVersion, disabled } = this.props;
    return (
      <div className={cx(s.container, { [s.mobile]: isMobileVersion })}>
        <div className={s.columnLeft}>
          <div className={s.listName}>
            Companies to <span className={s.listNameAvoid}>Avoid</span>
          </div>

          <Field
            component={TextField}
            disabled={disabled}
            name="companies_avoid"
            label="Add Companies"
            placeholder="Add 1 Company Per Line"
            variant="outlined"
            multiline
            fullWidth
            minRows={2}
            maxRows={4}
            parse={Companies.parseCompanies}
            format={Companies.formatCompanies}
          />
        </div>

        <div className={s.columnRight}>
          <div className={s.listName}>
            Companies to <span className={s.listNamePursue}>Pursue</span>
          </div>

          <Field
            component={TextField}
            disabled={disabled}
            name="companies_pursue"
            label="Add Companies"
            placeholder="Add 1 Company Per Line"
            variant="outlined"
            multiline
            fullWidth
            minRows={2}
            maxRows={4}
            parse={Companies.parseCompanies}
            format={Companies.formatCompanies}
          />
        </div>
      </div>
    );
  }
}

Companies = Companies;

export default Companies;

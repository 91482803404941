import config from '../../../config';
import { policies } from '../../core/agreements';

export default {
  path: '/legal/:slug?',

  async action({ params }) {
    const { slug } = policies[params.slug || 'terms-of-use'];
    return { redirect: `${config.mainSiteUrl}/${slug}/` };
  },
};

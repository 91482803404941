import React from 'react';
import { connect } from 'react-redux';
import langs from 'langs';
import ExperienceFilter from '../ExperienceFilter';
import EducationFilter from '../EducationFilter';
import GroupKeywordsFilter from '../GroupKeywordsFilter';
import KeywordFilter from '../KeywordFilter';
import ChipFilter from '../ChipFilter';

function RegularFilters({
  query,
  languagesOptions,
  countriesOptions,
  search,
  handleFilterAdd,
  handleFilterRemove,
  handleFilterEvent,
  viewer,
}) {
  const handleKeywordFilterRemove = (opt) => {
    if (opt.id) {
      handleFilterRemove('groupKeywordIds', opt.id);
    } else {
      handleFilterRemove('keywords', opt);
    }
  };

  return (
    <>
      <ChipFilter
        title="Language"
        value={query.languages}
        placeholder="Select languages"
        options={languagesOptions}
        onSubmit={(v) => search({ languages: v })}
        onRemoveValue={(opt) => handleFilterRemove('languages', opt.value)}
        selectInputProps={{ limit: null }}
      />

      <ChipFilter
        title="Country Experience"
        value={query.countries}
        placeholder="Select countries"
        options={countriesOptions}
        onSubmit={(v) => search({ countries: v })}
        onRemoveValue={(opt) => handleFilterRemove('countries', opt.value)}
        selectInputProps={{ limit: null }}
      />

      <GroupKeywordsFilter
        viewer={viewer}
        title="Team Keywords"
        placeholder="Select keywords"
        selected={query.groupKeywordIds}
        onSubmit={(v) => search({ groupKeywordIds: v })}
        onRemoveValue={handleKeywordFilterRemove}
      />

      <KeywordFilter
        viewer={viewer}
        title="Keywords"
        placeholder="Select keywords"
        keywords={query.keywords}
        onSubmit={(v) => search({ keywords: v })}
        onRemoveValue={handleKeywordFilterRemove}
      />

      <EducationFilter
        degrees={query.educationDegrees}
        fieldsOfStudy={query.educationFos}
        onDegreeChange={(e) => handleFilterEvent('educationDegrees', e)}
        onFieldsOfStudyAdd={(v) => handleFilterAdd('educationFos', v)}
        onFieldsOfStudyRemove={(v) => handleFilterRemove('educationFos', v)}
      />

      <ExperienceFilter
        title="Job title"
        placeholder="Enter job title"
        currentRestriction={query.jobTitlesCurrentRestriction}
        values={query.jobTitles}
        onCurrentRestrictionChange={(e) =>
          handleFilterEvent('jobTitlesCurrentRestriction', e)
        }
        onAddValue={(v) => handleFilterAdd('jobTitles', v)}
        onRemoveValue={(v) => handleFilterRemove('jobTitles', v)}
      />

      <ExperienceFilter
        title="Work Location"
        placeholder="Enter location"
        currentRestriction={query.locationsCurrentRestriction}
        values={query.locations}
        onCurrentRestrictionChange={(e) =>
          handleFilterEvent('locationsCurrentRestriction', e)
        }
        onAddValue={(v) => handleFilterAdd('locations', v)}
        onRemoveValue={(v) => handleFilterRemove('locations', v)}
        hideDivider
      />
    </>
  );
}

export default connect((state) => ({
  languagesOptions: langs.all().map((l) => ({ label: l.name, value: l['3'] })),
  countriesOptions: state.countries.map((c) => ({
    label: c.name,
    value: c.name,
  })),
}))(RegularFilters);

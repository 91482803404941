import * as Sentry from '@sentry/browser';
import LokkaTransport from 'lokka/transport';
import fetch from './fetch';

const fetchUrl = fetch;
const PERMISSION_ERROR_MESSAGE =
  "You don't have permission to perform this action.";

export class UnauthorizedError extends Error {
  constructor(message, remainingAttempts) {
    super(message);
    this.name = this.constructor.name;
    this.remainingAttempts = remainingAttempts;
    this.isPermissionError = true;
  }
}

export class TransportError extends Error {
  constructor(message, payload) {
    super(message);
    this.name = 'TransportError';
    this.extra = { payload };
  }
}

export class APIError extends Error {
  constructor(errors, data, query, variables) {
    const payload = { query, variables };
    const { message } = errors[0];
    super(`GraphQL Error: ${message}`);
    this.rawError = errors;
    this.rawData = data;
    this.query = query;
    this.extra = { payload, errors };
    this.variables = variables;
    if (
      errors.find(
        (e) => e.message && e.message.startsWith(PERMISSION_ERROR_MESSAGE)
      )
    ) {
      this.isPermissionError = true;
    }
  }
}

function isJSONContentType(contentType) {
  const parts = contentType.split(';');

  // KT: not sure why but server returns text/plain sometimes. Maybe proxy issue?
  return ['application/json', 'text/plain'].includes(parts[0]);
}

// the default error handler
function handleErrors(errors, data, query, variables) {
  if (errors.find((e) => e.message && e.message.startsWith('otp required'))) {
    throw new UnauthorizedError('otp_required');
  }
  if (
    errors.find(
      (e) => e.message && e.message.startsWith('otp enrollment required')
    )
  ) {
    throw new UnauthorizedError('otp_enrollment_required');
  }

  throw new APIError(errors, data, query, variables);
}

export default class Transport extends LokkaTransport {
  constructor(endpoint, options = {}) {
    if (!endpoint) {
      throw new Error('endpoint is required!');
    }

    super();
    this._httpOptions = {
      auth: options.auth,
      headers: options.headers || {},
      credentials: options.credentials,
    };
    this.endpoint = endpoint;
    this.handleErrors = options.handleErrors || handleErrors;
  }

  _buildOptions(payload) {
    const options = {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
      // To pass cookies to the server. (supports CORS as well)
      credentials: 'include',
    };

    // use delete property for backward compatibility
    if (this._httpOptions.credentials === false) {
      delete options.credentials;
    }

    Object.assign(options.headers, this._httpOptions.headers);
    return options;
  }

  async send(query, variables, operationName) {
    const payload = { query, variables, operationName };

    Sentry.addBreadcrumb({
      message: `Requesting ${this.endpoint}`,
      category: 'graphql',
      level: 'info',
    });

    const options = this._buildOptions(payload);

    const response = await fetchUrl(this.endpoint, options);
    const body = await response.text();

    const contentType =
      body.length > 0
        ? response.headers.get('content-type') || 'application/json'
        : undefined;
    const json = isJSONContentType(contentType || '') ? JSON.parse(body) : {};

    if (response.status === 401) {
      // if (!body) {
      //   const requestData = `${JSON.stringify(payload)} ${response.status}`;
      //   throw new TransportError(`Invalid empty response for: ${requestData}`, payload);
      // }
      throw new UnauthorizedError(json.reason, json.remaining_attempts);
    }

    // 200 is for success
    // 422 is for validation errors
    // 500 is for unexpected server errors
    if (response.status === 500) {
      throw new TransportError('Internal server error', payload);
    }

    if (response.status !== 200 && response.status !== 422) {
      throw new TransportError(
        `Invalid status code: ${response.status}`,
        payload
      );
    }

    if (!body) {
      const requestData = `${JSON.stringify(payload)} ${response.status}`;
      throw new TransportError(
        `Invalid empty response for: ${requestData}`,
        payload
      );
    }

    const { data, errors } = json;

    if (errors) {
      this.handleErrors(errors, data, query, variables);
      return {};
    }

    if (!data) {
      const requestData = `${JSON.stringify(payload)} ${
        response.status
      } ${JSON.stringify(json)}`;
      throw new TransportError(
        `Empty data for succesful request: ${requestData}`,
        payload
      );
    }

    return data;
  }
}

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import makeStyles from '@mui/styles/makeStyles';
import Credentials from './Credentials';
import VerificationCode from './VerificationCode';
import EnrollOtpAuth from './EnrollOtpAuth';
import Link from '../Link';
import { notify } from '../../actions/ui';
import {
  sendOtpSms,
  setBasicAuth,
  sendOtp,
  getOtpConfig,
} from '../../actions/auth';
import login from '../../core/login';
import { white } from '../../core/colors';
import Dialog from '../Dialog/Dialog';

const useStyles = makeStyles({
  loginErrorLink: {
    '&:hover': {
      textDecoration: 'underline',
    },
    color: white,
    textDecoration: 'underline',
  },
  LoginErrorSubText: {
    fontSize: 14,
  },
});

function Login({
  initialErrors,
  notify,
  sendOtpSms,
  setBasicAuth,
  sendOtp,
  getOtpConfig,
  ...props
}) {
  const s = useStyles();
  const [twoFactorStep, setTwoFactorStep] = useState();
  const [enrollTwoFactorStep, setEnrollTwoFactorStep] = useState();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [errors, setErrors] = useState([]);
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [otpConfiguration, setOtpConfiguration] = useState(null);
  const [finalAttemptDialogOpen, setFinalAttemptDialogOpen] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [buttonResendDisable, setButtonResendDisable] = useState(false);

  // AlertId used in the automation tests
  const [alertId, setAlertId] = useState(null);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return Promise.resolve();
    }

    const token = await executeRecaptcha('login');
    setRecaptchaToken(token);
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const handleEmail = useCallback((e) => setEmail(e.target.value), []);
  const handlePassword = useCallback((e) => setPassword(e.target.value), []);
  const handleOtp = useCallback((e) => setOtp(e.target.value), []);

  const handleCancel = useCallback(() => {
    setOtp('');
    setErrors([]);
    setAlertId(null);
    setTwoFactorStep(false);
    setEnrollTwoFactorStep(false);
    setOtpConfiguration(null);
    setButtonDisable(false);
    setButtonResendDisable(false);
  }, []);

  const securityEmail = 'hello@onfrontiers.com';

  const errorMessages = useMemo(
    () => ({
      otp_required: 'Invalid verification code',
      otp_enrollment_required: 'Two-factor authentication is required',
      invalid_credentials: 'Invalid username or password',
      locked_for_interval: (
        <span>
          Your account has been locked for 24 hours
          <br />
          <div className={s.LoginErrorSubText}>
            If you need urgent assistance please email&nbsp;
            <a className={s.loginErrorLink} href={`mailto:${securityEmail}`}>
              {securityEmail}
            </a>
          </div>
        </span>
      ),
    }),
    []
  );

  const handleResend = useCallback(async () => {
    try {
      await setButtonResendDisable(true);
      await sendOtp(email, password);
      notify(`Verification code sent to ${otpConfiguration.maskedAddress}`);
    } catch (err) {
      notify('An error occurred when trying to setup otp.', 'error');
      throw err;
    }
  }, [otpConfiguration]);

  const handleSubmit = useCallback(
    async (e) => {
      if (e) e.preventDefault();
      await setButtonDisable(true);

      const next = props.nextUrl;
      setEnrollTwoFactorStep(false);
      try {
        await login({ email, password, otp, next, recaptchaToken });
      } catch (err) {
        handleReCaptchaVerify();
        let message = errorMessages[err.message];

        if (!message) {
          setErrors(['Sorry, the service is currently unavailable.']);
          setAlertId('serviceUnavailableAlert');
          throw err;
        }

        if (err.message === 'otp_enrollment_required' && !enrollTwoFactorStep) {
          await setBasicAuth(email, password);
          setErrors([]);
          setEnrollTwoFactorStep(true);
          return;
        }

        if (err.message === 'otp_required' && !twoFactorStep) {
          setErrors([]);
          await sendOtp(email, password);
          setOtpConfiguration(await getOtpConfig(email, password));
          setTwoFactorStep(true);
          return;
        }

        if (
          err.message === 'invalid_credentials' &&
          err.remainingAttempts > 0 &&
          err.remainingAttempts < 3
        ) {
          if (err.remainingAttempts === 1) {
            setFinalAttemptDialogOpen(true);
          }
          message = (
            <span>
              {message}. To prevent your account from being locked,&nbsp;
              <Link className={s.loginErrorLink} to="/password_reset">
                recover your password
              </Link>
            </span>
          );
          setAlertId('invalidUserPasswordPreventAlert');
        } else {
          setAlertId('invalidUserPasswordAlert');
        }
        if (err.message === 'locked_for_interval') {
          setAlertId('invalidUserPassword24BlockerAlert');
        }
        await setButtonDisable(false);
        setErrors([message]);
        setOtp('');
      }
    },
    [
      email,
      password,
      otp,
      recaptchaToken,
      enrollTwoFactorStep,
      handleReCaptchaVerify,
      twoFactorStep,
      buttonDisable,
      props.nextUrl,
      props.signupType,
      props.invite,
    ]
  );

  const allErrors = [...errors, ...initialErrors];

  return enrollTwoFactorStep ? (
    <EnrollOtpAuth
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      setOtp={setOtp}
      email={email}
    />
  ) : twoFactorStep ? (
    <VerificationCode
      code={otp}
      onChange={handleOtp}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      onResend={handleResend}
      buttonResendDisable={buttonResendDisable}
      error={errors[0]}
      otpConfiguration={otpConfiguration}
    />
  ) : (
    <>
      <Credentials
        {...props}
        email={email}
        password={password}
        onEmailChange={handleEmail}
        onPasswordChange={handlePassword}
        onSubmit={handleSubmit}
        errors={allErrors}
        buttonDisable={buttonDisable || (!recaptchaToken && executeRecaptcha)}
        alertId={alertId}
      />
      <Dialog
        PaperProps={{
          id: 'invalidUserPasswordPopUp',
        }}
        open={finalAttemptDialogOpen}
        onCancel={() => {
          setFinalAttemptDialogOpen(false);
        }}
      >
        <span>
          You only have one remaining opportunity to input your password before
          you are locked out.&nbsp;
          <Link to="/password_reset">Recover your password</Link>.
        </span>
      </Dialog>
    </>
  );
}

Login.defaultProps = {
  initialErrors: [],
  showTitle: true,
  enrollTwoFactorStep: false,
  twoFactorStep: false,
};

Login = connect(
  (state) => ({
    initialErrors: state.runtime.errors,
  }),
  {
    notify,
    sendOtpSms,
    sendOtp,
    setBasicAuth,
    getOtpConfig,
  }
)(Login);

export default Login;

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { TextField } from '../../FormAdapters';
import { updateProfile } from '../../../actions/profile';
import SelectLocation from '../../SelectLocation';
import Form from '../../Form';

function validate(values = {}) {
  const errors = {};

  if (!values.country) {
    errors.country = 'A valid Country is required';
  }

  return errors;
}

class LocationForm extends PureComponent {
  static defaultProps = {
    component: Form,
  };

  handleSubmit = (values) => {
    const { profileId, updateProfile, onSubmit } = this.props;
    updateProfile({ id: profileId, ...values });

    if (onSubmit) {
      onSubmit(values);
    }
  };

  handleReset = () => {
    const { reset, onReset } = this.props;
    reset();

    if (onReset) {
      onReset();
    }
  };

  render() {
    const {
      component: Container,
      allCountries,
      handleSubmit,
      profile,
      ...other
    } = this.props;

    return (
      <Container
        {...other}
        onSubmit={handleSubmit(this.handleSubmit)}
        onReset={this.handleReset}
      >
        <Field
          id="locationCountry"
          component={SelectLocation}
          multiple={false}
          name="country"
          label="In which country are you located?"
          countries={allCountries}
          format={(country) => allCountries.find((c) => c.name === country)}
          parse={(country) => country && country.name}
        />
        <Field
          id="locationCity"
          fullWidth
          component={TextField}
          name="city"
          label="In which city are you located?"
          placeholder="Start typing the name of a city here"
        />
      </Container>
    );
  }
}

LocationForm = reduxForm({
  form: 'expertLocation',
  enableReinitialize: true,
  validate,
})(LocationForm);

LocationForm = connect(
  (state, ownProps) => {
    const profile = state.profiles.fullProfiles[ownProps.profileId];
    return {
      profile,
      initialValues: {
        country: profile.country,
        city: profile.city,
      },
      allCountries: state.countries,
    };
  },
  {
    updateProfile,
  }
)(LocationForm);

export default LocationForm;

import React from 'react';
import UserRef from './UserRef';
import ConsultationRef from './ConsultationRef';

function ConsultationActivity({
  state,
  activity: {
    context: { expert, requester, consultation },
  },
}) {
  switch (state) {
    case 'completed':
    case 'confirmed':
      // Special renderer for confirmed and completed states
      return (
        <div>
          <UserRef user={expert} /> {state} a{' '}
          <ConsultationRef consultation={consultation} /> requested by{' '}
          <UserRef user={requester} link />
        </div>
      );
    default:
      return (
        <div>
          A <ConsultationRef consultation={consultation} /> requested by{' '}
          <UserRef user={requester} link /> with <UserRef user={expert} /> got
          its state moved to &quot;{state}&quot;
        </div>
      );
  }
}

export default ConsultationActivity;

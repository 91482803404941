export function listOrDefault(list, defaultValue) {
  return list && list.length > 0 ? list : defaultValue;
}

export function iteratorToArray(iterator) {
  const array = [];
  for (const entry of iterator) {
    array.push(entry);
  }
  return array;
}

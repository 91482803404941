import React, { Component } from 'react';
import moment from 'moment-timezone';
import { TextField } from '@mui/material';
import Dialog from '../Dialog';
import SelectProfile from '../SelectProfile';
import FAIcon from '../Icon/FAIcon';
import history from '../../core/history';
import s from './SelectMergeSourceProfileButton.module.scss';
import { teal500 } from '../../core/colors';
import Button from '../Button/Button';

class SelectMergeSourceProfileButton extends Component {
  state = {
    srcProfile: undefined,
    open: false,
  };

  handleChange = (selected) => this.setState({ srcProfile: selected.value });

  handleConfirm = () => {
    const { profile } = this.props;
    const { srcProfile } = this.state;
    if (!srcProfile) return;

    history.push(`/merge/${profile.url_endpoint}/${srcProfile.url_endpoint}`);
  };

  render() {
    const { profile, ...other } = this.props;
    const { srcProfile, open } = this.state;

    const title = (
      <div className={s.header}>
        <FAIcon icon="code-fork" color={teal500} size={40} />
        <div className={s.title}>Profiles to Merge</div>
      </div>
    );

    return (
      <div>
        <Button
          color="lightTan"
          startIcon="code-fork"
          fullWidth
          {...other}
          style={{ fontSize: 14, padding: '15px 22px' }}
          onClick={() => this.setState({ open: true })}
          size="medium"
        >
          Merge Profile
        </Button>

        <Dialog
          {...other}
          open={open}
          onCancel={() => this.setState({ open: false })}
          title={title}
          onConfirm={this.handleConfirm}
          disableSubmit={!srcProfile}
          confirmLabel="Compare Profiles"
          cancelLabel="Cancel"
        >
          <SelectProfile
            autoFocus
            label="Select Source Profile"
            onChange={this.handleChange}
            value={
              srcProfile
                ? {
                  type: 'profile',
                  value: srcProfile,
                }
                : null
            }
            menuStyle={{ maxHeight: 300 }}
            showCreationTimestamp
            profileType="lead"
          />
          <TextField
            label="Compare with"
            value={`${profile.name} (${moment(profile.created_at).format(
              'YYYY-MM-DD LT'
            )})`}
            fullWidth
          />
        </Dialog>
      </div>
    );
  }
}

SelectMergeSourceProfileButton = SelectMergeSourceProfileButton;

export default SelectMergeSourceProfileButton;

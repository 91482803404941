import React from 'react';
import ExpertRequestPublic from './ExpertRequestPublic';
import ExpertAuthPage from './ExpertAuthPage';
import ReferralAuthPage from './ReferralAuthPage';
import ExpertRequestAddRequest from './ExpertRequestAddRequest';
import ExpertRequestAddRequestSubmitted from './ExpertRequestAddRequestSubmitted';
import {
  fetchPublicExpertRequest,
  requestAddExpertRequestCandidate,
} from '../../actions/expertRequest';

const ALREADY_ADDED = 'GraphQL Error: expert request candidate already added';

function findExpertRequest(store, id) {
  const number = Number(id);
  if (Number.isInteger(number) && number >= 0) return;
  return store.dispatch(fetchPublicExpertRequest(id));
}

export default {
  path: '/expert_request/:id',

  children: [
    {
      path: '',

      async action({ store, params, query }) {
        const { id } = params;

        const expertRequest = await findExpertRequest(store, id);
        if (!expertRequest) return;

        return {
          title: expertRequest.name,
          meta: {
            description: expertRequest.description,
            imageUrl: require('./promo.jpg'),
            url: expertRequest.public_html_url,
          },
          disableIndexing: true,
          component: (
            <ExpertRequestPublic
              expertRequestId={expertRequest.id}
              action={query.action}
              tags={query.t}
            />
          ),
        };
      },
    },

    {
      path: '/request_add/:auth?/:signupType?',

      async action({ store, params, query }) {
        const { id, auth } = params;

        const expertRequest = await findExpertRequest(store, id);
        if (!expertRequest) return;

        const { viewer } = store.getState();
        if (!viewer.id && !auth) {
          return {
            redirect: `/expert_request/${expertRequest.slug}/request_add/signup`,
          };
        }

        if (viewer.id && auth) {
          return {
            redirect: `/expert_request/${expertRequest.slug}/request_add`,
          };
        }

        if (!viewer.id && auth) {
          return {
            title: 'Expert Request',
            component: (
              <ExpertAuthPage
                expertRequest={expertRequest}
                signup={auth === 'signup'}
                tags={query.t}
              />
            ),
          };
        }

        try {
          await store.dispatch(
            requestAddExpertRequestCandidate({
              expert_request_id: id,
            })
          );
        } catch (err) {
          if (err.message === ALREADY_ADDED) {
            return {
              title: 'Expert Request',
              component: <ExpertRequestAddRequestSubmitted viewer={viewer} />,
            };
          }
          throw err;
        }

        return {
          title: 'Expert Request',
          component: (
            <ExpertRequestAddRequest
              viewer={viewer}
              expertRequest={expertRequest}
            />
          ),
        };
      },
    },

    {
      path: '/refer/:auth?/:signupType?',

      async action({ store, params, query }) {
        const { id, auth } = params;

        const expertRequest = await findExpertRequest(store, id);
        if (!expertRequest) return;

        const { viewer } = store.getState();
        if (!viewer.id && !auth) {
          return {
            redirect: `/expert_request/${expertRequest.slug}/refer/signup`,
          };
        }

        if (viewer.id) {
          return { redirect: `/expert_request/${expertRequest.slug}` };
        }

        return {
          title: 'Expert Request',
          component: (
            <ReferralAuthPage
              expertRequest={expertRequest}
              signup={auth === 'signup'}
              tags={query.t}
            />
          ),
        };
      },
    },
  ],
};

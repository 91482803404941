import qs from 'query-string';

export function toDateBoundString(value) {
  const values = (value || '').split(':');
  if (values.length === 0) return;

  const startYear = values[1] && values[1].length === 4 && Number(values[1]);
  const endYear = values[2] && values[2].length === 4 && Number(values[2]);

  return {
    text: values[0],
    start_date: Number.isInteger(startYear)
      ? new Date(startYear, 0, 1, 0, 0, 0)
      : undefined, // inclusive
    end_date: Number.isInteger(endYear)
      ? new Date(endYear + 1, 0, 1, 0, 0, 0)
      : undefined, // exclusive
  };
}

export function toQueryString(query) {
  return qs.stringify(
    {
      q: query.text || undefined,
      sort: query.sort || undefined,
      asc: query.ascending,
      email: query.mustHaveEmail,
      profile_type: query.profileType || undefined,
      expert_states: query.expertStates,
      expert_history: query.expertHistory,
      countries: query.countries,
      languages: query.languages,
      industries: query.industries,
      agents: query.agentIds,
      networks: query.networkIds,
      sharing_groups: query.sharingGroupIds,
      organizations_restrict: ignoreNone(query.organizationsCurrentRestriction),
      locations_restrict: ignoreNone(query.locationsCurrentRestriction),
      job_titles_restrict: ignoreNone(query.jobTitlesCurrentRestriction),
      organizations:
        query.organizations && query.organizations.map(encodeDateBoundString),
      locations: query.locations && query.locations.map(encodeDateBoundString),
      job_titles: query.jobTitles && query.jobTitles.map(encodeDateBoundString),
      consultations_with: query.consultationsWith,
      education_degrees: query.educationDegrees,
      education_fos: query.educationFos,
      group_keyword_ids: query.groupKeywordIds,
      keywords: query.keywords,
      marketplace_preference: ignoreNone(query.marketplacePreference),
    },
    {
      arrayFormat: 'bracket',
    }
  );
}

function encodeDateBoundString(obj) {
  const startYear = obj.start_date ? obj.start_date.getFullYear() : '';
  const endYear = obj.end_date ? obj.end_date.getFullYear() - 1 : '';
  return dateBoundStringKey(obj.text, startYear, endYear);
}

function dateBoundStringKey(text, startYear, endYear) {
  if (!startYear && !endYear) return text;
  if (!endYear) return `${text}:${startYear}`;
  return `${text}:${startYear}:${endYear}`;
}

function ignoreNone(value) {
  return value === 'none' ? undefined : value;
}

import React from 'react';
import { darkGreen, red500 } from '../../core/colors';

export default function Meter({ size = 0, className }) {
  // Prevent extra width
  if (size < 0) {
    size = 0;
  } else if (size > 100) {
    size = 100;
  }

  // Detect if too low or too high
  const color = size > 10 && size < 70 ? darkGreen : red500;

  return (
    <div
      className={className}
      style={{
        background: 'linear-gradient(90deg,' + `${color} ${size}%, #EEE 0%)`,
      }}
    />
  );
}

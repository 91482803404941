/* eslint-disable camelcase */
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import EditIcon from '../EditIcon/EditIcon';
import KeywordList from '../KeywordList/KeywordList';
import ColumnSection from '../ColumnSection/ColumnSection';
import SectionItem from './SectionItem';

const getStyles = makeStyles(() => ({
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
    alignItems: 'flex-start',
    marginBottom: -10,
  },
  list: {
    marginBottom: 5,
  },
}));

const SectionKeywords = ({ groupKeywords, keywords, listClassName }) => {
  return (
    <>
      {groupKeywords
        .filter((gk) => gk.keywords?.length)
        .map((gk) => (
          <SectionItem subTitle={gk.name}>
            <KeywordList
              className={listClassName}
              highlightedKeywords={gk.keywords}
              icon={null}
            />
          </SectionItem>
        ))}
      {keywords.length > 0 && (
        <SectionItem subTitle="Other">
          <KeywordList
            className={listClassName}
            keywords={keywords}
            icon={null}
          />
        </SectionItem>
      )}
    </>
  );
};

export default ({ profile, editable, showSections, onEdit }) => {
  const s = getStyles();

  const { keywords, group_keywords } = profile;

  const groupKeywords = (group_keywords || [])
    .filter((gk) => gk.group)
    .map((gk) => ({
      name: gk.group.name,
      keywords: gk.keywords.map((k) => k.name),
    }));

  const allGroupKeywords = groupKeywords.reduce(
    (all, gk) => [...all, ...gk.keywords],
    []
  );

  return (
    <div className={s.row}>
      <ColumnSection title="Keywords">
        <div>
          {showSections ? (
            <SectionKeywords
              listClassName={s.list}
              groupKeywords={groupKeywords}
              keywords={keywords}
            />
          ) : (
            <KeywordList
              className={s.list}
              icon={null}
              keywords={keywords}
              highlightedKeywords={allGroupKeywords}
            />
          )}
        </div>
      </ColumnSection>

      {editable && <EditIcon onClick={onEdit} />}
    </div>
  );
};

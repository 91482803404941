import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { Checkbox } from '../FormAdapters';
import Form from '../Form/Form';
import { updateUser } from '../../actions/user';

class BackgroundCheckForm extends PureComponent {
  static defaultProps = {
    component: Form,
  };

  handleSubmit = async (values) => {
    const { userId, updateUser, onSubmit } = this.props;
    await updateUser({ id: userId, ...values });

    if (onSubmit) {
      onSubmit(values);
    }
  };

  handleReset = () => {
    const { reset, onReset } = this.props;
    reset();

    if (onReset) {
      onReset();
    }
  };

  render() {
    const { component: Container, handleSubmit, ...other } = this.props;

    return (
      <Container
        {...other}
        onSubmit={handleSubmit(this.handleSubmit)}
        onReset={this.handleReset}
      >
        <Field
          type="checkbox"
          component={Checkbox}
          name="background_check"
          label="Background check has been completed"
        />
      </Container>
    );
  }
}

BackgroundCheckForm = reduxForm({
  form: 'expertBackgroundCheck',
})(BackgroundCheckForm);

BackgroundCheckForm = connect(
  (state, ownProps) => {
    const user = state.users[ownProps.userId] || {};
    return {
      initialValues: {
        background_check: user.background_check,
      },
    };
  },
  {
    updateUser,
  }
)(BackgroundCheckForm);

export default BackgroundCheckForm;

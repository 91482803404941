import React from 'react';
import FilterAdaptiveSelect from './FilterAdaptiveSelect';
import Filter from './Filter';

export default function SelectFilter({ title, hideDivider, ...other }) {
  return (
    <Filter title={title} hideDivider={hideDivider}>
      <FilterAdaptiveSelect {...other} />
    </Filter>
  );
}

import React from 'react';
import LayoutPage from '../../components/Layout/LayoutPage';
import ChangePassword from './ChangePassword';

export default {
  path: '/change_password',

  async action({ query }) {
    return {
      title: 'Change Password',
      component: (
        <LayoutPage
          hideSearch
          showNewRequest={false}
          showReviewConsultation={false}
        >
          <ChangePassword next={query.next} />
        </LayoutPage>
      ),
    };
  },
};

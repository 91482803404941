import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Fab as FloatingActionButton } from '@mui/material';
import LongList from '../../components/LongList';
import { fetchProjects } from '../../actions/project';
import ProjectPreview from '../../components/ProjectPreview';
import ConsultationsStarting from '../../components/ConsultationsStarting';
import MaterialIcon from '../../components/Icon/MaterialIcon';
import TeamAccountPromoButton from '../../components/TeamAccountPromo/TeamAccountPromoButton';
import { isGroupContext } from '../../core/user';

class ExpertRequests extends PureComponent {
  static propTypes = {
    edges: PropTypes.array,
    pageInfo: PropTypes.object,
    fetchProjects: PropTypes.func,
  };

  static defaultProps = {
    edges: [],
    pageInfo: {
      hasNextPage: false,
    },
  };

  handleMore = (e) => {
    e.preventDefault();
    const { fetchProjects, pageInfo, edges, userContext } = this.props;
    if (!pageInfo.hasNextPage) return;

    const groupId = isGroupContext(userContext) ? userContext : undefined;

    if (!edges.length) throw new Error('last project edge not found');
    fetchProjects({
      cursor: edges[edges.length - 1].cursor,
      groupId,
      openOnly: false,
      memberOnly: false,
    });
  };

  render() {
    const { edges, pageInfo, loading } = this.props;
    const projects = edges.map((e) => e.node);

    return (
      <div>
        <ConsultationsStarting />

        <LongList
          loading={loading}
          title={
            projects.length > 0 || loading
              ? 'Expert Requests'
              : 'No Expert Requests Found'
          }
          hasNextPage={pageInfo.hasNextPage}
          onMore={this.handleMore}
          action={
            <TeamAccountPromoButton component={FloatingActionButton}>
              <MaterialIcon icon="add" />
            </TeamAccountPromoButton>
          }
        >
          {projects.map((p) => (
            <ProjectPreview key={p.id} project={p} />
          ))}
        </LongList>
      </div>
    );
  }
}

ExpertRequests = connect(
  (state) => {
    const page = state.projects.default;
    return {
      ...page,
      userContext: state.ui.userContext,
    };
  },
  {
    fetchProjects,
  }
)(ExpertRequests);
export default ExpertRequests;

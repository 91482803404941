/* eslint-disable no-console */
import EventEmitter from 'events';
import { fibonacci } from './util';

// Can't use symbols because they're not valid JSON values
const actions = Object.freeze({
  subscribe: 'subscribe',
  unsubscribe: 'unsubscribe',
});

const buildKey = ({ service, queue, resource }) =>
  `${service}:${queue}:${resource}`;

class ApiWebSocket {
  constructor(url, apiToken) {
    this._emitter = new EventEmitter();
    this._url = url;
    this._apiToken = apiToken;
    this._reconnectAttempts = 0;
  }

  // -- EVENT EMITTER

  on(eventPayload, listener) {
    const key = buildKey(eventPayload);

    this._handleEventPayload({
      ...eventPayload,
      action: actions.subscribe,
    });

    this._emitter.on(key, listener);
    return () => this.removeListener(eventPayload, listener);
  }

  removeListener(eventPayload, listener) {
    const key = buildKey(eventPayload);

    this._handleEventPayload({
      ...eventPayload,
      action: actions.unsubscribe,
    });

    this._emitter.removeListener(key, listener);
  }

  // -- HANDLE CONNECTION

  async _handleEventPayload(eventPayload) {
    if (!eventPayload.action) {
      throw new Error('must specify an action');
    }

    return this._loadConnection().then((ws) =>
      ws.send(JSON.stringify(eventPayload))
    );
  }

  async _loadConnection() {
    if (this._conn) return this._conn;

    const self = this;

    this._conn = new Promise((resolve, reject) => {
      if (!this._apiToken) {
        reject(new Error('must specify an apiToken to authorize'));
      }
      if (!this._url) {
        reject(new Error('must specify an url to connect'));
      }

      const ws = new WebSocket(this._url, ['X-Authorization', this._apiToken]);
      ws.onclose = (evt) => {
        console.debug(
          'WebSocket connection closed -',
          'code:',
          evt.code,
          'clean',
          evt.wasClean,
          'reason',
          evt.reason
        );
        this._conn = null;
        if (!evt.wasClean) {
          // if wasn't a clean close, try to reconnect
          const seconds = fibonacci(1, ++self._reconnectAttempts);
          console.debug('Attempting to reconnect in', seconds, 'seconds...');
          setTimeout(() => this._loadConnection(), seconds * 1000);
        }
      };

      ws.onmessage = (evt) => {
        const data = JSON.parse(evt.data);
        const key = buildKey(data);
        this._emitter.emit(key, data.payload);
      };

      ws.onopen = () => {
        self._reconnectAttempts = 0;
        resolve(ws);
      };
    });

    return this._conn;
  }
}

export default ApiWebSocket;

import React from 'react';
import cx from 'classnames';

function FAIcon({
  icon,
  size,
  color,
  className,
  style,
  fixedWidth,
  iconSet = 'fa',
  ...other
}) {
  const iconStyle = { color };
  if (size) iconStyle.fontSize = size;

  const combinedStyle = { ...iconStyle, ...style };
  const classes = cx(iconSet, `fa-${icon}`, className, {
    'fa-fw': fixedWidth,
  });

  return <i className={classes} style={combinedStyle} {...other} />;
}

export default FAIcon;

import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Button from '../Button';
import Divider from '../Divider';
import { borderColor } from '../../core/colors';
import { EnableOtpAuth } from '../Forms/Profile/SecurityEnableOtpAuth';

import { getAboutPageLogoURL } from '../../actions/group';

const useStyles = makeStyles({
  root: {
    textAlign: 'center',
  },
  button: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginTop: 25,
  },
  submitButton: {
    margin: '20px 15px 0 ',
  },
  title: {
    fontSize: 36,
  },
  divider: {
    backgroundColor: borderColor,
    margin: '20px 0 40px',
  },
  logo: {
    maxWidth: 300,
    maxHeight: 100,
    width: 'auto',
    height: 'auto',
  },
});

function EnrollOtpAuth({ getAboutPageLogoURL, onSubmit, email, setOtp }) {
  const s = useStyles();

  const [enableOtpAuthOpen, setEnableOtpAuthOpen] = useState(false);

  const handleEnableOtpAuthOpen = useCallback(() => {
    setEnableOtpAuthOpen(true);
  }, []);

  const handleEnableOtpAuthClose = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  const [groupLogoURL, setGroupLogoURL] = useState(undefined);

  useEffect(() => {
    async function loadGroupLogoURL() {
      const logoURL = await getAboutPageLogoURL();
      setGroupLogoURL(logoURL);
    }
    loadGroupLogoURL();
  }, []);

  const logo = groupLogoURL && (
    <img alt="logo" src={groupLogoURL} className={s.logo} />
  );

  return groupLogoURL !== undefined ? (
    <div className={s.root}>
      {logo}

      {logo && <Divider className={s.divider} />}

      <h1 className={s.title}>Set up two-factor authentication</h1>

      <p>
        To protect your account and your team&apos;s data, each time you sign in
        to <br />
        your OnFrontiers account, you&apos;ll need your password and a
        verification <br />
        code we send to your mobile phone or authentication app or email.
      </p>

      <div className={s.button}>
        <Button
          classes={{ root: s.submitButton }}
          onClick={handleEnableOtpAuthOpen}
          fullWidth={false}
        >
          Get Started
        </Button>
      </div>

      <EnableOtpAuth
        open={enableOtpAuthOpen}
        onClose={handleEnableOtpAuthClose}
        email={email}
        setOtp={setOtp}
      />
    </div>
  ) : null;
}

EnrollOtpAuth = connect(undefined, {
  getAboutPageLogoURL,
})(EnrollOtpAuth);

export default EnrollOtpAuth;

import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import EditIcon from '../EditIcon/EditIcon';
import { darkGray, darkBrown } from '../../core/colors';

const getStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
    alignItems: 'flex-start',
  },
  headline: {
    fontSize: 30,
    color: darkBrown,
    margin: 0,
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: 24,
      textAlign: 'center',
      flex: 1,
    },
  },
  headlineEmpty: {
    fontSize: 30,
    margin: 0,
    lineHeight: '24px',
    fontWeight: 'bold',
    color: darkGray,
    [theme.breakpoints.down('md')]: {
      fontSize: 24,
      textAlign: 'center',
      flex: 1,
    },
  },
}));

export default ({ profile, editable, onEdit }) => {
  const s = getStyles();

  return (
    (profile.title || editable) && (
      <div className={s.row}>
        {profile.title ? (
          <h3 className={s.headline}>{profile.title}</h3>
        ) : (
          editable && (
            /* eslint-disable-next-line */
            <a onClick={onEdit}>
              <h3 className={s.headlineEmpty}>
                Introduce yourself with a headline
              </h3>
            </a>
          )
        )}
        {editable && <EditIcon onClick={onEdit} />}
      </div>
    )
  );
};

import React from 'react';
import { connect } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Logo from '../../components/Logo/Logo';
import PictureNav from '../../components/Layout/PictureNav';
import Link from '../../components/Link/Link';
import { darkGray, grey300 } from '../../core/colors';

const useStyles = makeStyles(() => ({
  nav: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '15px 30px',
    boxSizing: 'border-box',
    margin: '0 auto',
    borderBottom: `1px solid ${grey300}`,
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
  },
  titleLogo: {
    borderLeft: `2px solid ${grey300}`,
    marginLeft: 10,
    display: 'block',
    padding: 10,
    fontSize: 18,
    color: darkGray,
  },
}));

function AdminHeader({ viewer }) {
  const s = useStyles();

  return (
    <div className={s.nav}>
      <Link to="/admin">
        <div className={s.logo}>
          <Logo dark />
          <span className={s.titleLogo}>Admin</span>
        </div>
      </Link>

      <PictureNav size={50} viewer={viewer} />
    </div>
  );
}

export default connect((state) => ({
  viewer: state.viewer,
}))(AdminHeader);

import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import EditDialog from '../../../components/EditDialog';
import { RadioGroup, TextField } from '../../../components/FormAdapters';
import { notify } from '../../../actions/ui';

const useStyles = makeStyles(() => ({
  isGoodMatchField: {
    marginTop: 20,
  },
}));

function EditClientNote({
  onSubmit,
  notify,
  handleSubmit: handleSubmitProp,
  reset,
  noteValue,
  profile,
  showMatchOptions,
  ...props
}) {
  const s = useStyles();

  const handleSubmit = (values) => {
    onSubmit(values)
      .then(() => {
        notify('Note to Research Manager updated.', 'success');
      })
      .catch((err) => {
        notify('Error while updating note to Research Manager.', 'error');
        throw err;
      });
  };

  return (
    <EditDialog
      {...props}
      onSubmit={handleSubmitProp(handleSubmit)}
      onReset={() => reset()}
      // bogus attribute (force dialog position update on text change)
      fieldValue={noteValue}
    >
      <Field
        id="client_note.note"
        component={TextField}
        fullWidth
        multiline
        name="client_note.note"
        label="Note to Research Manager"
        maxRows={15}
      />
      {showMatchOptions && (
        <Field
          name="client_note.is_good_match"
          component={RadioGroup}
          label={`Is ${profile.first_name} a good match?`}
          className={s.isGoodMatchField}
          style={{ flexDirection: 'row' }}
          options={[
            { label: 'No', value: 'no' },
            { label: 'Maybe', value: 'maybe' },
            { label: 'Yes', value: 'yes' },
          ]}
        />
      )}
    </EditDialog>
  );
}

EditClientNote = reduxForm({ enableReinitialize: true })(EditClientNote);
export default connect(
  (state, ownProps) => {
    const formName = `candidateClientNote-${ownProps.initialValues.id}`;
    return {
      form: formName,
      noteValue: formValueSelector(formName)(state, 'client_note.note'),
    };
  },
  {
    notify,
  }
)(EditClientNote);

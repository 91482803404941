import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import MaterialIcon from '../Icon/MaterialIcon';
import s from './EmptyMessage.module.scss';

function EmptyMessage({
  style,
  icon,
  iconName,
  iconColor,
  title,
  body,
  action,
  border = true,
  titleStyle,
  bodyStyle,
}) {
  return (
    <div style={style} className={cx(s.root, { [s.border]: border })}>
      {iconName ? (
        <MaterialIcon className={s.icon} icon={iconName} color={iconColor} />
      ) : (
        icon
      )}
      {title && (
        <h3 className={s.title} style={titleStyle}>
          {title}
        </h3>
      )}
      {body && (
        <div className={s.body} style={bodyStyle}>
          {body}
        </div>
      )}
      {action}
    </div>
  );
}

EmptyMessage.propTypes = {
  body: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  action: PropTypes.element,
  icon: PropTypes.element,
  iconName: PropTypes.string,
  title: PropTypes.string,
  border: PropTypes.bool,
};

EmptyMessage = EmptyMessage;

export default EmptyMessage;

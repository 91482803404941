import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { updateProfile } from '../../actions/profile';
import { updateProfileKeywords } from '../../actions/internalNetwork';
import { selectProfile, clearProfileSelection } from '../../actions/search';
import EditDialog from '../../components/EditDialog';
import Button from '../../components/Button';
import { notify } from '../../actions/ui';
import { fetchUser } from '../../actions/user';
import { fetchAllGroups } from '../../actions/group';
import { KeywordsFields } from '../../components/Forms/Profile/KeywordsForm';

function ProfileKeywordsDialog({
  viewer,
  profiles,
  updateProfile,
  updateProfileKeywords,
  selectProfile,
  clearProfileSelection,
  notify,
  reset,
  onReset,
  array,
  change,
  handleSubmit: handleSubmitProp,
  ...rest
}) {
  const handleSubmit = (values) => {
    const keywords = viewer.admin
      ? values.keywords
      : values.keywords.map((k) => k.id);

    Promise.all(
      profiles.map((p) => {
        const groupKeywords = values.group_keywords.reduce(
          (ks, g) => [...ks, ...g.keywords?.map((k) => k.id)],
          []
        );

        const promise = updateProfileKeywords(p.id, groupKeywords);

        return promise
          .then(() => ({ profile: p, success: true }))
          .catch(() => ({ profile: p, success: false }));
      }),
      profiles
        .map((p) => {
          return (
            viewer.admin &&
            updateProfile({ id: p.id, keywords_to_add: keywords })
          );
        })
        .filter(Boolean)
    ).then((responses) => {
      const success = responses.filter((r) => r.success);
      const failure = responses.filter((r) => !r.success);

      if (success.length > 0 && failure.length === 0) {
        notify('Keywords added to the selected experts.', 'success');
        clearProfileSelection();
      } else if (failure.length > 0 && success.length === 0) {
        notify('Error when adding keywords to the selected experts.', 'error');
      } else if (failure.length > 0 && success.length > 0) {
        const failureText =
          failure.length === 1
            ? failure[0].profile.name
            : `${failure.length} experts`;
        notify(`Keywords could not be added to ${failureText}.`, 'warning');
        success.forEach((r) => selectProfile(r.profile, false));
      }
    });
  };

  const handleReset = () => {
    reset();

    if (onReset) {
      onReset();
    }
  };

  return (
    <KeywordsFields
      {...rest}
      component={EditDialog}
      maxWidth="md"
      title="Add Keywords"
      handleSubmit={handleSubmitProp(handleSubmit)}
      handleReset={handleReset}
      showGeneralKeywords={viewer.admin}
      array={array}
      change={change}
    />
  );
}

ProfileKeywordsDialog = reduxForm({
  form: 'profilesKeywords',
  initialValues: {
    keywords: [],
  },
})(ProfileKeywordsDialog);

ProfileKeywordsDialog = connect(
  (state) => ({
    groups: state.groups.all,
    viewer: state.viewer,
    formValues: state.form.profilesKeywords?.values,
    initialValues: {
      group_keywords: [{ keywords: [] }],
      keywords: [],
    },
  }),
  {
    updateProfile,
    updateProfileKeywords,
    selectProfile,
    clearProfileSelection,
    notify,
  }
)(ProfileKeywordsDialog);

function AddKeywordsButton({
  viewer,
  fetchUser,
  profiles,
  labelStyle,
  fetchAllGroups,
  groups,
  ...other
}) {
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    fetchAllGroups({
      keywordsConfig: true,
    });
  }, []);

  const hasTeamKeywords = useMemo(
    () =>
      groups?.edges?.some(
        ({ node: g }) => g.profile_keywords_definition?.length
      ),
    [groups]
  );

  return (
    <div>
      {hasTeamKeywords && (
        <Button
          variant="contained"
          color="teal"
          size="small"
          {...other}
          onClick={() => setDialogOpen(true)}
        >
          Add Keywords
        </Button>
      )}
      <ProfileKeywordsDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        profiles={profiles}
      />
    </div>
  );
}

export default connect(
  (state) => ({
    groups: state.groups.all,
    viewer: state.viewer,
  }),
  {
    fetchAllGroups,
    fetchUser,
  }
)(AddKeywordsButton);

import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import WizardPage from '../../components/WizardPage/WizardPage';
import Checkbox from '../../components/Checkbox/Checkbox';

const useStyles = makeStyles(() => ({
  label: {
    width: '80%',
  },
}));

function Confirm({ onNext }) {
  const s = useStyles();
  const [checked, setChecked] = useState(false);

  const checkboxLabel =
    'I confirm that I have understood and will abide by the rules ' +
    'and principles of OnFrontiers’ Compliance Training while ' +
    'using the OnFrontiers Platform.';

  return (
    <WizardPage
      title="Expert Compliance Training"
      onNext={onNext}
      nextLabel="Finish"
      nextDisabled={!checked}
    >
      <h3>ALMOST DONE…</h3>
      <p>
        Please confirm that you understand all the rules and principles in this
        training and will follow them.
      </p>

      <Checkbox
        label={checkboxLabel}
        labelClasses={{ label: s.label }}
        onChange={({ target: { checked } }) => setChecked(checked)}
      />
    </WizardPage>
  );
}

export default Confirm;

import React from 'react';
import { connect } from 'react-redux';
import Dialog from '../Dialog';
import Button from '../Button';
import { hidePopup } from '../../actions/ui';
import s from './Popup.module.scss';
import Link from '../Link/Link';
import { darkBrown } from '../../core/colors';

function Popup({
  open,
  layout,
  title,
  contents,
  buttons,
  links,
  hidePopup,
  contentStyle,
  buttonAlignment,
}) {
  const defaultStyle = {
    // default styles
    contents: { fontSize: 16, lineHeight: 1.5, color: darkBrown },
    buttons: {
      display: 'flex',
      justifyContent: buttonAlignment || 'flex-end',
      marginTop: 20,
    },
    links: { textAlign: 'right', marginTop: 16 },
  };

  const style = Object.assign(
    {},
    defaultStyle,
    {
      // layout styles
      promo: {
        title: { textAlign: 'center', fontSize: 24 },
        contents: {
          ...defaultStyle.contents,
          textAlign: 'center',
          marginBottom: 10,
        },
        buttons: {
          ...defaultStyle.buttons,
          justifyContent: 'center',
          alignItems: 'center',
        },
        links: {
          ...defaultStyle.links,
          textAlign: 'center',
        },
      },
    }[layout]
  );

  return (
    <Dialog
      open={open}
      title={title}
      titleStyle={style.title}
      contentStyle={contentStyle}
    >
      <div style={style.contents}>{contents}</div>

      <div style={style.buttons}>
        {(
          buttons || [
            {
              label: 'OK',
              primary: true,
              callback: () => { },
              flat: false,
              size: 'medium',
            },
          ]
        ).map(({ label, callback, flat, close = true, ...opt }) =>
          flat ? (
            <Button
              key={label}
              variant="text"
              size="medium"
              {...opt}
              onClick={() => {
                if (close) hidePopup();
                if (callback) callback();
              }}
            >
              {label}
            </Button>
          ) : (
            <Button
              key={label}
              color="teal"
              style={{ fontSize: 16 }}
              size="medium"
              {...opt}
              onClick={() => {
                if (close) hidePopup();
                if (callback) callback();
              }}
            >
              {label}
            </Button>
          )
        )}
      </div>

      {links && links.length > 0 && (
        <div style={style.links}>
          {links.map(({ label, callback, href, ...opt }) => (
            <Link
              key={label}
              {...opt}
              href={href || undefined}
              className={s.link}
              onClick={
                callback
                  ? () => {
                    hidePopup();
                    callback();
                  }
                  : undefined
              }
            >
              {label}
            </Link>
          ))}
        </div>
      )}
    </Dialog>
  );
}

Popup = connect(undefined, { hidePopup })(Popup);

Popup = Popup;

export default Popup;

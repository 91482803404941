import React from 'react';
import { connect } from 'react-redux';
import BillRateForm from '../../components/Forms/Profile/BillRateForm';
import SaveForm from './DeprecatedSaveForm';

function Rate({ profileId, userId, profile }) {
  // Always allow user to opt back into Marketplace
  let showMarketplace = profile.available_marketplace === false;

  // Only allow user to opt out of Marketplace if connected to internal networks
  if (!showMarketplace && profile.expert_internal_networks) {
    const validStates = ['awaiting_acceptance', 'active'];

    showMarketplace = !!profile.expert_internal_networks.filter((n) =>
      validStates.includes(n.connection_state)
    ).length;
  }

  return (
    <BillRateForm
      showMarketplace={showMarketplace}
      component={SaveForm}
      profileId={profileId}
      userId={userId}
    />
  );
}

Rate = connect((state, ownProps) => ({
  profileId: ownProps.profileId,
  userId: ownProps.userId,
  profile: state.profiles.fullProfiles[ownProps.profileId],
}))(Rate);

export default Rate;

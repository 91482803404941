import ActionTypes from '../core/ActionTypes';

export function fetchCountries() {
  return (dispatch, getState, { graphql }) => {
    const { countries } = getState();
    if (countries.length > 0) {
      return Promise.resolve(countries);
    }

    return graphql
      .query('{ countries { id, name, country_iso2_code } }')
      .then((result) => {
        if (result.countries) {
          dispatch({
            type: ActionTypes.COUNTRIES__FETCH_SUCCESS,
            countries: result.countries,
          });
          return result.countries;
        }
      });
  };
}

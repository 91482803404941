import React from 'react';
import cx from 'classnames';

import Button from '../../components/Button/Button';
import LinearProgress from '../../components/LinearProgress';
import s from './ExpertApplicationPromo.module.scss';

function ExpertApplicationPromo({ style }) {
  return (
    <div className={cx(s.root)} style={style}>
      <h3 className={s.title}>Your application is almost done!</h3>

      <div className={s.progressBar}>
        <LinearProgress
          style={{ height: 10 }}
          variant="determinate"
          value={75}
        />
      </div>

      <p className={s.body}>
        Finish up your application so we can search for projects that match your
        background and you can start exchanging insights with our community!
      </p>

      <Button className={s.action} href="/signup/expert" size="large">
        Finish Application
      </Button>
    </div>
  );
}

ExpertApplicationPromo = ExpertApplicationPromo;

export default ExpertApplicationPromo;

import React from 'react';
import Link from '../Link';
import s from './ActivityLog.module.scss';

function RequestRef({ expertRequest: er, group, optional, loading }) {
  if (!er)
    return optional ? null : loading ? null : (
      <span>for a deleted expert request</span>
    );
  return (
    <span>
      for{' '}
      <Link className={s.requestName} to={er.html_url}>
        {er.name}
      </Link>
      &nbsp;{group && <div className={s.groupTag}>({group.name})</div>}
    </span>
  );
}

RequestRef = RequestRef;

export default RequestRef;

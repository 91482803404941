import React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { Checkbox, FormControlLabel } from '@mui/material';
import s from './FocusAreas.module.scss';

const allFocusAreas = [
  'Competitive Landscape',
  'General Understanding',
  'Industry Trends',
  'Investment Decision',
  'Market Sizing',
  'Market Entry',
  'Partnership Identification',
  'Talent Sourcing',
];

class SelectFocusAreas extends React.Component {
  toggle = (e, focusArea) => {
    const { fields } = this.props;
    if (e.target.checked) {
      fields.push(focusArea);
    } else {
      const values = fields.value || [];
      const index = values.findIndex((v) => v === focusArea);
      fields.remove(index);
    }
  };

  renderCheckbox = (focusArea) => {
    const { fields, disabled } = this.props;
    const values = fields.value || [];

    const checked = values.includes(focusArea);
    return (
      <FormControlLabel
        key={focusArea}
        control={
          <Checkbox
            checked={checked}
            disabled={disabled}
            onChange={(e) => this.toggle(e, focusArea)}
            value={focusArea}
          />
        }
        label={focusArea}
      />
    );
  };

  render() {
    return (
      <div className={s.focusAreas}>
        <div className={s.column}>
          {allFocusAreas.slice(0, 4).map(this.renderCheckbox)}
        </div>
        <div className={s.column}>
          {allFocusAreas.slice(4, 8).map(this.renderCheckbox)}
        </div>
      </div>
    );
  }
}

function FocusAreas({ disabled }) {
  return (
    <FieldArray
      component={SelectFocusAreas}
      disabled={disabled}
      name="focus_areas"
    />
  );
}

FocusAreas = FocusAreas;

export default FocusAreas;

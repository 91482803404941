import React from 'react';
import CommunicationForm from '../../components/Forms/Profile/CommunicationForm';
import SaveForm from './SaveForm';

function Communication({ profileId }) {
  return (
    <CommunicationForm
      editPhone
      phoneRequired
      component={SaveForm}
      profileId={profileId}
      showSuccessMessage
    />
  );
}

export default Communication;

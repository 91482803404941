import React from 'react';
import ExpertRequest from './ExpertRequest';
import InaccessibleArchived from '../../components/InaccessibleArchived';
import RequestProjectAccess from '../../components/RequestProjectAccess';
import LayoutPage from '../../components/Layout/LayoutPage';
import {
  fetchExpertRequest,
  fetchExpertRequestCandidates,
  updateExpertRequestCandidate,
} from '../../actions/expertRequest';
import { awaitingProjectMembershipApproval } from '../../actions/project';
import { fetchStarting } from '../../actions/consultation';
import { track } from '../../actions/tracking';

function requestAdd(query, viewer, id, accessRequested) {
  return {
    title: 'Expert Request',
    component: (
      <LayoutPage showNav selected="expert_requests">
        <RequestProjectAccess
          path="expert_request"
          query={query}
          expertRequestId={id}
          viewer={viewer}
          accessRequested={accessRequested}
        />
      </LayoutPage>
    ),
  };
}

export default {
  path: /^\/expert_request\/([0-9]*)$/,

  async action({ store, query, params: { 0: id } = {} }) {
    const { viewer } = store.getState();

    if (!viewer.id) return requestAdd(query, viewer, id, false);

    let expertRequest;
    try {
      expertRequest = await store.dispatch(fetchExpertRequest(id));
      await Promise.all(
        [
          fetchExpertRequestCandidates(id, 'suggested', true),
          fetchExpertRequestCandidates(id, 'matched', true),
          fetchStarting(),
        ].map(store.dispatch)
      );
    } catch (e) {
      if (!e.isPermissionError) throw e;
      const accessRequested = await store.dispatch(
        awaitingProjectMembershipApproval({ expertRequestId: id })
      );
      return requestAdd(query, viewer, id, accessRequested);
    }

    if (
      !expertRequest ||
      !expertRequest.permissions ||
      !expertRequest.permissions.includes('view_private')
    )
      return;

    if (
      expertRequest.archived &&
      !expertRequest.permissions.includes('view_archived')
    ) {
      store.dispatch(track('promo.show.request.archived'));
      return {
        title: expertRequest.name,
        component: (
          <InaccessibleArchived
            selectedTab="expert_requests"
            entity="request"
            entities="requests"
            trackingCode="promo.chat.request.archived"
          />
        ),
      };
    }

    const {
      section: selectedSection,
      is_good_match: isGoodMatch,
      candidate_id: candidateId,
    } = query;

    if (candidateId && ['no', 'maybe', 'yes'].includes(isGoodMatch)) {
      try {
        const candidateData = {
          id: candidateId,
          client_note: { is_good_match: isGoodMatch },
        };
        if (isGoodMatch === 'yes') {
          candidateData.state = 'matched';
        }
        await store.dispatch(updateExpertRequestCandidate(id, candidateData));
      } catch (err) {
        Promise.reject(err);
      }
    }

    const targetSection = selectedSection || 'experts';

    const fetchMoreCandidates = async (type, pageInfo) => {
      if (pageInfo?.hasNextPage) {
        await store.dispatch(
          fetchExpertRequestCandidates(id, type, false, pageInfo.cursor)
        );
      }
    };

    return {
      title: expertRequest.name,
      component: (
        <ExpertRequest
          section={targetSection}
          expertRequestId={expertRequest.id}
          candidateId={candidateId}
          isGoodMatch={isGoodMatch}
          fetchMoreCandidates={fetchMoreCandidates}
        />
      ),
    };
  },
};

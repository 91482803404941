import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { Trigger, updateConsultation } from '../../actions/consultation';
import { TextField } from '../../components/FormAdapters/FormAdapters';
import Dialog from '../../components/Dialog';
import { notify } from '../../actions/ui';
import { normalizeSpace } from '../../core/util';

function EditTrackingCodeDialog({
  consultation,
  updateConsultation,
  notify,
  onClose,
  ...other
}) {
  const { tracking_code: trackingCode } = consultation;

  const initialValues = useMemo(
    () => ({
      tracking_code: trackingCode,
    }),
    [consultation]
  );

  const handleSubmit = useCallback(async (values) => {
    try {
      await updateConsultation({
        id: consultation.id,
        ...values,
        trigger: Trigger.consultationPage,
      });
      notify('Consultation updated.', 'success');
    } catch (err) {
      notify(
        "An error occurred when trying to update consultation's tracking code.",
        'error'
      );
    }
    onClose();
  }, []);

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      subscription={{ submitting: true }}
    >
      {({ handleSubmit, submitting }) => {
        return (
          <Dialog
            {...other}
            title="Edit Tracking Code"
            maxWidth="xs"
            onConfirm={handleSubmit}
            onClose={onClose}
            onCancel={onClose}
            disableSubmit={submitting}
            cancelLabel="Cancel"
            confirmLabel="Save"
          >
            <form onSubmit={(e) => handleSubmit(e)}>
              <Field
                component={TextField}
                name="tracking_code"
                label="Tracking Code"
                inputProps={{ maxLength: 60 }}
                multiline
                onKeyDown={(e) => e.keyCode === 13 && e.preventDefault()}
                parse={(v) => normalizeSpace(v)}
              />
            </form>
          </Dialog>
        );
      }}
    </Form>
  );
}

EditTrackingCodeDialog = connect(undefined, {
  updateConsultation,
  notify,
})(EditTrackingCodeDialog);

export default EditTrackingCodeDialog;

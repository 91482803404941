import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Form from '../../components/Form/Form';
import Button from '../../components/Button/Button';
import { notify } from '../../actions/ui';

function SaveButton(props) {
  return (
    <Button type="submit" size="small" {...props}>
      Save
    </Button>
  );
}

class SaveForm extends PureComponent {
  static defaultProps = {
    saveMessage: 'Settings updated.',
    saveLabel: 'Save',
  };

  handleSubmit = (values) => {
    const { onSubmit, notify, saveMessage } = this.props;
    Promise.resolve(onSubmit(values)).then((errors) => {
      if (errors) return;
      notify(saveMessage);
    });
  };

  render() {
    const { children, saveLabel, saveButtonStyle, onReset, style, className } =
      this.props;

    return (
      <Form {...{ onReset, style, className }} onSubmit={this.handleSubmit}>
        {children}
        <SaveButton
          style={{ marginTop: 40, ...saveButtonStyle }}
          label={saveLabel}
        />
      </Form>
    );
  }
}

export default connect(undefined, {
  notify,
})(SaveForm);

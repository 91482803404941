import React from 'react';
import cx from 'classnames';
import Button from '../Button';
import Tidbit from '../Tidbit';
import Divider from '../Divider';
import Picture from '../Picture';
import { white, teal500 } from '../../core/colors';
import s from './ExpertWidgetVertical.module.scss';
import { canRequestConsultation } from '../../core/consultation';

function ExpertWidgetVertical({
  profile,
  dark,
  showSummary,
  showRequest,
  maxSummaryLength,
  ...other
}) {
  const sliceSummary = profile.summary.length > maxSummaryLength;

  return (
    <div className={cx(s.root, { [s.dark]: dark })} {...other}>
      <Picture user={profile} size={120} />

      <div className={s.name}>{profile.name}</div>

      <Tidbit
        className={s.tidbit}
        city={profile.city}
        country={profile.country}
      />

      <Divider color={dark ? white : undefined} spacing={15} />

      <div className={s.headline}>{profile.title}</div>

      {showSummary && (
        <p className={s.summary}>
          {profile.summary.slice(0, maxSummaryLength)}
          {sliceSummary ? '...' : ''}
        </p>
      )}

      {sliceSummary && (
        <Button
          variant="text"
          size="small"
          href={profile.html_url}
          style={{ color: teal500, fontWeight: 'bold' }}
        >
          Read More
        </Button>
      )}

      <div className={s.actions}>
        {!showRequest && (
          <Button href={profile.html_url} style={{ fontSize: 14 }}>
            View Profile
          </Button>
        )}
        {showRequest && canRequestConsultation(profile) && (
          <Button href={`${profile.html_url}/request`} style={{ fontSize: 14 }}>
            Request Call
          </Button>
        )}
      </div>
    </div>
  );
}

export default ExpertWidgetVertical;

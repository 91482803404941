import React from 'react';
import { connect } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { openFileDialog } from '../../actions/ui';
import FAIcon from '../Icon/FAIcon';

function FileUpload({
  openFileDialog,
  onUpload,
  InputProps = {},
  FileDialogProps = {},
  ...other
}) {
  const upload = async () => {
    const file = await openFileDialog({
      accept: ['image/*'],
      maxSize: 4 * 1024 * 1024, // 4 MB
      fromSources: [
        'local_file_system',
        'url',
        'googledrive',
        'dropbox',
        'box',
        'onedrive',
        'onedriveforbusiness',
      ],
      ...FileDialogProps,
    });
    onUpload(file);
  };

  return (
    <TextField
      {...other}
      onChange={({ target: { value } }) => onUpload({ url: value })}
      InputProps={{
        ...InputProps,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={upload}>
              <FAIcon icon="upload" />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

FileUpload = connect(undefined, {
  openFileDialog,
})(FileUpload);

export default FileUpload;

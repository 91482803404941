import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import PrivacyForm from '../../components/Forms/Profile/PrivacyForm';
import SaveForm from './DeprecatedSaveForm';

class Privacy extends PureComponent {
  static propTypes = {
    profileId: PropTypes.string,
  };

  render() {
    const { profileId } = this.props;
    return (
      <PrivacyForm
        component={SaveForm}
        saveMessage="Privacy updated."
        profileId={profileId}
      />
    );
  }
}

export default Privacy;

export function isAnonymous(rawChannel, userId) {
  if (!rawChannel || !rawChannel.metadata) return false;
  return rawChannel.metadata[`anonymous_${userId}`] === 'true';
}

export function isNotAnonymous(rawChannel, userId) {
  if (!rawChannel || !rawChannel.metadata) return false;
  return rawChannel.metadata[`anonymous_${userId}`] !== 'true';
}

export function getChannelId(channelUrl) {
  return channelUrl.replace('sendbird_group_channel_', '');
}

export function getChannelUrl(channelId) {
  return `sendbird_group_channel_${channelId}`;
}

import React from 'react';
import { Field } from 'redux-form';
import { TextField } from '../../FormAdapters/FormAdapters';

export default function () {
  return (
    <Field
      id="expertRequestName"
      component={TextField}
      name="name"
      fullWidth
      placeholder="Name this expert request"
    />
  );
}

import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, change } from 'redux-form';

import WizardFooter from '../../components/WizardPage/WizardFooter';
import Name from '../../components/Forms/ExpertRequest/Name';
import EditDialog from '../../components/EditDialog';
import EditIcon from '../../components/EditIcon';
import history from '../../core/history';
import s from './ExpertRequestWizardPage.module.scss';

class ExpertRequestWizardPage extends React.Component {
  static defaultProps = {
    editName: true,
  };

  state = {
    editNameOpen: false,
  };

  render() {
    const {
      expertRequestName,
      title,
      subTitle,
      nextLabel,
      nextUrl,
      children,
      onNext,
      style,
      titleStyle,
      subTitleStyle,
      editName,
      reduxFormChange,
    } = this.props;

    return (
      <div className={s.root} style={style}>
        <header className={s.header}>
          {expertRequestName && (
            <div className={s.expertRequestName}>
              <span className={s.nameContainer}>{expertRequestName}</span>
              {editName && (
                <EditIcon
                  onClick={() => this.setState({ editNameOpen: true })}
                  style={{ marginLeft: 10, verticalAlign: 'middle' }}
                />
              )}
            </div>
          )}
          <h2 style={titleStyle} className={s.title}>
            {title}
          </h2>
          <h3 style={subTitleStyle} className={s.subTitle}>
            {subTitle}
          </h3>
        </header>

        {children}

        {(onNext || nextUrl) && (
          <WizardFooter
            onNext={onNext || (() => history.push(nextUrl))}
            nextLabel={nextLabel}
          />
        )}

        <EditExpertRequestName
          onSubmit={(name) => {
            reduxFormChange('newExpertRequest', 'name', name);
            reduxFormChange('newExpertRequest', 'name_edited', true);
          }}
          value={expertRequestName}
          open={this.state.editNameOpen}
          onClose={() => this.setState({ editNameOpen: false })}
          initialValues={{ name: expertRequestName }}
        />
      </div>
    );
  }
}

ExpertRequestWizardPage = connect(undefined, {
  reduxFormChange: change,
})(ExpertRequestWizardPage);

class EditExpertRequestName extends React.Component {
  handleSubmit = (values) => {
    const { onSubmit, onClose } = this.props;
    onSubmit(values.name);
    onClose(values.name);
  };

  render() {
    const { handleSubmit, reset } = this.props;

    return (
      <EditDialog
        {...this.props}
        onSubmit={handleSubmit(this.handleSubmit)}
        onReset={reset}
        title="Edit Expert Request Name"
      >
        <Name />
      </EditDialog>
    );
  }
}

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Required';
  }

  return errors;
};

EditExpertRequestName = reduxForm({
  form: 'editExpertRequestName',
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate,
})(EditExpertRequestName);

export default ExpertRequestWizardPage;

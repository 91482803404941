import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { TextField } from '../../FormAdapters';
import Form from '../../Form';
import { updateProfile } from '../../../actions/profile';

function AddAdditionalInformationForm({
  userId,
  profileId,
  updateProfile,
  onSubmit,
  reset,
  onReset,
  handleSubmit: handleSubmitProp,
  component: Container = Form,
  ...rest
}) {
  const handleSubmit = async (values) => {
    await updateProfile({ id: profileId, ...values });

    if (onSubmit) {
      onSubmit(values);
    }
  };

  const handleReset = () => {
    reset();

    if (onReset) {
      onReset();
    }
  };

  return (
    <Container
      {...rest}
      onSubmit={handleSubmitProp(handleSubmit)}
      onReset={handleReset}
    >
      <Field
        multiline
        id="additionalInformation"
        component={TextField}
        name="additional_information"
        fullWidth
        autoFocus
        minRows={3}
        maxRows={15}
        inputProps={{ maxLength: 10000 }}
      />
    </Container>
  );
}

AddAdditionalInformationForm = reduxForm({
  form: 'additionalInformation',
  enableReinitialize: true,
})(AddAdditionalInformationForm);

AddAdditionalInformationForm = connect(
  (state, ownProps) => {
    const profile = state.profiles.fullProfiles[ownProps.profileId];
    return {
      profile,
      initialValues: {
        additional_information: profile.additional_information,
      },
    };
  },
  {
    updateProfile,
  }
)(AddAdditionalInformationForm);

export default AddAdditionalInformationForm;

import actionTypes from '../core/ActionTypes';
import { gqlParam, gqlVar } from './util';

const {
  EXPERT_SHOWCASE__UPDATE,
  EXPERT_SHOWCASE__REMOVE,
  EXPERT_SHOWCASE__BATCH_ADD,
  EXPERT_SHOWCASE_PROFILES__UPDATE,
  EXPERT_SHOWCASE_CATEGORY__UPDATE,
  EXPERT_SHOWCASE_SECTION__UPDATE,
  EXPERT_SHOWCASE_CATEGORY__REMOVE,
  EXPERT_SHOWCASE_SECTION__REMOVE,
} = actionTypes;

const expertShowcaseFields = `
  id
  subdomain
  path
  page_title
  background_url
  header_logo_url
  header_logo_link_url
  meta_image_url
  title
  subtitle
  header_note
  ctas {
    id
    label
    url
    order
  }
  categories {
    id
    name
    order
    sections {
      id
      icon
      name
      slug
      order
      cta_label
      cta_url
    }
  }
  google_tag_manager_id
  show_footer
`;

export function fetchExpertShowcase({ subdomain, path, id }) {
  return async (dispatch, getState, { graphql }) => {
    const pages = getState().expertShowcase[subdomain];
    const cachedPage = pages && pages[path];
    if (cachedPage) return cachedPage;

    const result = await graphql.query(
      `query getExpertShowcase(
        $subdomain: String,
        $path: String,
        $id: String
      ) {
        expertShowcase(subdomain: $subdomain, path: $path, id: $id) {
          ${expertShowcaseFields}
        }
      }`,
      { subdomain, path, id }
    );

    const page = result.expertShowcase;

    if (page) {
      dispatch({
        type: EXPERT_SHOWCASE__UPDATE,
        subdomain,
        path,
        ...page,
        page,
      });
    }

    return page;
  };
}

export function updateExpertShowcase(page) {
  return async (dispatch, _, { graphql }) => {
    const result = await graphql.mutate(
      `
      (
        ${gqlVar('id', 'String', page)}
        ${gqlVar('subdomain', 'String!', page)}
        ${gqlVar('path', 'String!', page)}
        ${gqlVar('page_title', 'String', page)}
        ${gqlVar('title', 'String', page)}
        ${gqlVar('subtitle', 'String', page)}
        ${gqlVar('header_note', 'String', page)}
        ${gqlVar('background_url', 'String', page)}
        ${gqlVar('header_logo_url', 'String', page)}
        ${gqlVar('header_logo_link_url', 'String', page)}
        ${gqlVar('meta_image_url', 'String', page)}
        ${gqlVar('google_tag_manager_id', 'String', page)}
        ${gqlVar('show_footer', 'Boolean', page)}
        ${gqlVar('ctas', '[ExpertShowcaseCtaInput]', page)}
        ${gqlVar('categories', '[ExpertShowcaseCategoryInput]', page)}
      ) {
        updateExpertShowcase (
          ${gqlParam('id', page)}
          ${gqlParam('subdomain', page)}
          ${gqlParam('path', page)}
          ${gqlParam('page_title', page)}
          ${gqlParam('title', page)}
          ${gqlParam('subtitle', page)}
          ${gqlParam('header_note', page)}
          ${gqlParam('background_url', page)}
          ${gqlParam('header_logo_url', page)}
          ${gqlParam('header_logo_link_url', page)}
          ${gqlParam('meta_image_url', page)}
          ${gqlParam('google_tag_manager_id', page)}
          ${gqlParam('show_footer', page)}
          ${gqlParam('ctas', page)}
          ${gqlParam('categories', page)}
        ) {
          ${expertShowcaseFields}
        }
      }
    `,
      page
    );

    page = result.updateExpertShowcase;

    if (page) {
      dispatch({ type: EXPERT_SHOWCASE__UPDATE, ...page, page });
    }

    return page;
  };
}

export function removeExpertShowcase(id) {
  return async (dispatch, _, { graphql }) => {
    const { removeExpertShowcase } = await graphql.mutate(
      ` ($id: String!) {
        removeExpertShowcase (id: $id)
      }
    `,
      { id }
    );

    if (removeExpertShowcase) {
      dispatch({ type: EXPERT_SHOWCASE__REMOVE, id });
    }

    return removeExpertShowcase;
  };
}

export function fetchExpertShowcases() {
  return async (dispatch, _, { graphql }) => {
    const result = await graphql.query(`{
      expertShowcases {
        id
        subdomain
        path
        title
      }
    }`);

    const pages = result.expertShowcases;

    if (pages) {
      dispatch({ type: EXPERT_SHOWCASE__BATCH_ADD, pages });
    }

    return pages;
  };
}

export function fetchExperts(subdomain, path, sectionId) {
  return async (dispatch, getState, { graphql }) => {
    const result = await graphql.query(
      `query getProfiles($sectionId: String!) {
      expertShowcaseProfiles(section_id: $sectionId) {
        id
        highlight
        badge_text
        name
        picture
        city
        country
        experience
        title
        summary
        marketing_keywords
        url
        can_request_consultation
      }
    }`,
      { sectionId }
    );

    const experts = result.expertShowcaseProfiles;

    if (experts) {
      dispatch({
        type: EXPERT_SHOWCASE_PROFILES__UPDATE,
        subdomain,
        path,
        sectionId,
        experts,
      });
    }

    return experts;
  };
}

export function fetchExpertShowcaseCategory(id) {
  return async (dispatch, getState, { graphql }) => {
    const result = await graphql.query(
      `query getExpertShowcaseCategory(
        $id: String!
      ) {
        expertShowcaseCategory(id: $id) {
          id
          name
          order
          showcase_id
          sections {
            id
            icon
            name
            slug
            order
            cta_label
            cta_url
          }
        }
      }`,
      { id }
    );

    const category = result.expertShowcaseCategory;

    if (category) {
      dispatch({ type: EXPERT_SHOWCASE_CATEGORY__UPDATE, category });
    }

    return category;
  };
}

export function updateExpertShowcaseCategory(category) {
  return async (dispatch, _, { graphql }) => {
    const result = await graphql.mutate(
      `
      (
        ${gqlVar('id', 'String!', category)}
        ${gqlVar('name', 'String', category)}
        ${gqlVar('order', 'Int', category)}
        ${gqlVar('sections', '[ExpertShowcaseSectionInput]', category)}
      ) {
        updateExpertShowcaseCategory (
          ${gqlParam('id', category)}
          ${gqlParam('name', category)}
          ${gqlParam('order', category)}
          ${gqlParam('sections', category)}
        ) {
          id
          name
          order
          showcase_id
          sections {
            id
            icon
            name
            slug
            order
            cta_label
            cta_url
          }
        }
      }
    `,
      category
    );

    category = result.updateExpertShowcaseCategory;

    if (category) {
      dispatch({ type: EXPERT_SHOWCASE_CATEGORY__UPDATE, category });
    }

    return category;
  };
}

export function removeExpertShowcaseCategory(id) {
  return async (dispatch, _, { graphql }) => {
    const { removeExpertShowcaseCategory } = await graphql.mutate(
      ` ($id: String!) {
        removeExpertShowcaseCategory (id: $id)
      }
    `,
      { id }
    );

    if (removeExpertShowcaseCategory) {
      dispatch({ type: EXPERT_SHOWCASE_CATEGORY__REMOVE, id });
    }

    return removeExpertShowcaseCategory;
  };
}

export function fetchExpertShowcaseSection(id) {
  return async (dispatch, getState, { graphql }) => {
    const result = await graphql.query(
      `query getExpertShowcaseSection(
        $id: String!
      ) {
        expertShowcaseSection(id: $id) {
          id
          icon
          name
          slug
          order
          category_id
          cta_label
          cta_url
          profiles_raw {
            id
            name
            picture
            city
            country
            experience
            title
            summary
            marketing_keywords
            profile_id
            profile_name
            badge_text
            highlight
            order
          }
        }
      }`,
      { id }
    );

    const section = result.expertShowcaseSection;

    if (section) {
      dispatch({ type: EXPERT_SHOWCASE_SECTION__UPDATE, section });
    }

    return section;
  };
}

export function updateExpertShowcaseSection(section) {
  return async (dispatch, _, { graphql }) => {
    const result = await graphql.mutate(
      `
      (
        ${gqlVar('id', 'String!', section)}
        ${gqlVar('icon', 'String', section)}
        ${gqlVar('name', 'String', section)}
        ${gqlVar('slug', 'String', section)}
        ${gqlVar('order', 'Int', section)}
        ${gqlVar('profiles', '[ExpertShowcaseProfileInput]', section)}
        ${gqlVar('cta_label', 'String', section)}
        ${gqlVar('cta_url', 'String', section)}
      ) {
        updateExpertShowcaseSection (
          ${gqlParam('id', section)}
          ${gqlParam('icon', section)}
          ${gqlParam('name', section)}
          ${gqlParam('slug', section)}
          ${gqlParam('order', section)}
          ${gqlParam('profiles', section)}
          ${gqlParam('cta_label', section)}
          ${gqlParam('cta_url', section)}
        ) {
          id
          icon
          name
          slug
          order
          category_id
          cta_label
          cta_url
          profiles_raw {
            id
            name
            picture
            city
            country
            experience
            title
            summary
            marketing_keywords
            profile_id
            profile_name
            badge_text
            highlight
            order
          }
        }
      }
    `,
      section
    );

    section = result.updateExpertShowcaseSection;

    if (section) {
      dispatch({ type: EXPERT_SHOWCASE_SECTION__UPDATE, section });
    }

    return section;
  };
}

export function removeExpertShowcaseSection(id) {
  return async (dispatch, _, { graphql }) => {
    const { removeExpertShowcaseSection } = await graphql.mutate(
      ` ($id: String!) {
        removeExpertShowcaseSection (id: $id)
      }
    `,
      { id }
    );

    if (removeExpertShowcaseSection) {
      dispatch({ type: EXPERT_SHOWCASE_SECTION__REMOVE, id });
    }

    return removeExpertShowcaseSection;
  };
}

import React, { PureComponent, createContext } from 'react';
import PropTypes from 'prop-types';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Provider as ReduxProvider } from 'react-redux';
import moment from 'moment-timezone';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import config from '../config';
import PageLoadingBar from './components/PageLoadingBar/PageLoadingBar';

const ContextType = {
  // Router
  pathname: PropTypes.string.isRequired,
  query: PropTypes.object,

  // Integrate Redux
  // http://redux.js.org/docs/basics/UsageWithReact.html
  store: PropTypes.shape({
    subscribe: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    getState: PropTypes.func.isRequired,
  }).isRequired,

  // GraphQL client (Lokka)
  // https://github.com/kadirahq/lokka
  graphql: PropTypes.object.isRequired,

  // Like window.location but works on server as well
  location: PropTypes.object.isRequired,

  // Device capabilities (only used on server)
  userAgent: PropTypes.object,
  capabilities: PropTypes.object,

  sendBird: PropTypes.object,
  voipCarrier: PropTypes.object,
  permissions: PropTypes.object,
  apiWebSocket: PropTypes.object,
};

/**
 * The top-level React component setting context (global) variables
 * that can be accessed from all the child components.
 *
 * https://facebook.github.io/react/docs/context.html
 *
 * Usage example:
 *
 *   const context = {
 *     history: createBrowserHistory(),
 *     store: createStore(),
 *   };
 *
 *   ReactDOM.render(
 *     <App context={context}>
 *       <Layout>
 *         <LandingPage />
 *       </Layout>
 *     </App>,
 *     container,
 *   );
 */
class App extends PureComponent {
  static propTypes = {
    context: PropTypes.shape(ContextType).isRequired,
    children: PropTypes.element.isRequired,
    onRenderDone: PropTypes.func,
  };

  static childContextTypes = ContextType;

  componentDidMount() {
    const { onRenderDone } = this.props;
    if (onRenderDone) onRenderDone();
  }

  componentDidUpdate() {
    this.componentDidMount();
  }

  getChildContext() {
    return this.props.context;
  }

  render() {
    // NOTE: If you need to add or modify header, footer etc. of the app,
    // please do that inside the Layout component.
    const { children } = this.props;

    const context = this.getChildContext();

    return (
      <GoogleReCaptchaProvider reCaptchaKey={config.recaptchaSiteKey}>
        <LocalizationProvider
          dateAdapter={AdapterMoment}
          dateLibInstance={moment}
        >
          <ReduxProvider store={context.store}>
            <AppContext.Provider value={context}>
              <PageLoadingBar />
              {React.Children.only(children)}
            </AppContext.Provider>
          </ReduxProvider>
        </LocalizationProvider>
      </GoogleReCaptchaProvider>
    );
  }
}

export default App;

/*
 * New API Context, allow access context with functional components/hooks
 * https://medium.com/@emasuriano/understanding-the-new-context-api-7a8d1c3a1ee6
 */
export const AppContext = createContext({});

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { CircularProgress, IconButton, Button } from '@mui/material';
import cx from 'classnames';
import moment from 'moment-timezone';
import Link from '../Link';
import MediaQuery from '../MediaQuery';
import CallAlert from './CallAlert';
import MicButton from './MicButton';
import FAIcon from '../Icon/FAIcon';
import MaterialIcon from '../Icon/MaterialIcon';
import DeviceSettingsDialog from '../DeviceSettings/Dialog';
import { white, red500 } from '../../core/colors';
import { formatSeconds } from '../../core/time';
import { toggleCallMute, leaveCall } from '../../actions/call';
import { dialOutExpert } from '../../actions/consultation';
import { popup, notify, hideMessage } from '../../actions/ui';
import s from './ConferenceCall.module.scss';
import { SCREEN_MD } from '../../constants/index';
import { normalise } from '../../core/util';

class DialOutExpertButton extends React.Component {
  render() {
    const { dialing, onDialOutExpert, allowDialoutIn } = this.props;

    return allowDialoutIn <= 0 ? (
      <Button
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0.15)',
          color: white,
          label: { fontSize: 14 },
        }}
        disabled={dialing}
        onClick={onDialOutExpert}
      >
        {dialing ? 'Dialing expert’s phone…' : 'Dial expert’s phone'}
      </Button>
    ) : (
      <div className={s.dialOutInstructions}>
        <div>Expert not on the line?</div>
        <div className={s.dialOutInstructionsWait}>
          Wait {allowDialoutIn}s to dial out
        </div>
      </div>
    );
  }
}

class ReattemptButton extends React.Component {
  render() {
    return (
      <Button
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0.15)',
          color: white,
          label: { fontSize: 14 },
        }}
        size="small"
        onClick={this.props.onReattempt}
      >
        Reattempt
      </Button>
    );
  }
}

class ConferenceCall extends PureComponent {
  state = {
    allowDialoutIn: 10,
    dialing: false,
    expertDidNotRespond: false,
    settingsOpen: false,
  };

  componentDidMount() {
    if (this.props.call.connected) {
      this.initClock();
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { call } = this.props;

    if (!call.connected && newProps.call.connected) {
      this.initClock();
    }

    if (call.awaitingExpert !== newProps.call.awaitingExpert) {
      this.setState({ dialing: false, expertDidNotRespond: false });
    }

    if (call.connected && !newProps.call.connected) {
      clearTimeout(this.counter);
    }
  }

  initClock = () => {
    if (this.counter) return;
    const updateClock = () => {
      const allowDialoutIn = moment(this.props.call.startTime)
        .add(2, 'minute')
        .diff(moment(), 'second');
      this.setState({ allowDialoutIn });
      if (allowDialoutIn >= 0) this.counter = setTimeout(updateClock, 1000);
    };
    updateClock();
  };

  handleDialOutExpert = () => {
    const { notify, dialOutExpert, call } = this.props;
    this.setState({ dialing: true, expertDidNotRespond: false });
    dialOutExpert(call.consultationId)
      .then((result) => {
        if (result.dialOutExpert) {
          setTimeout(() => {
            if (this.props.call.awaitingExpert) {
              this.setState({ dialing: false, expertDidNotRespond: true });
            }
          }, 60000);
        } else {
          this.setState({ dialing: false });
          notify('Could not dial expert phone.', 'error');
        }
      })
      .catch((err) => {
        this.setState({ dialing: false });
        notify('Error when dialing expert phone.', 'error');
        throw err;
      });
  };

  showSettingsDialog = () => this.setState({ settingsOpen: true });

  closeSettingsDialog = () => this.setState({ settingsOpen: false });

  render() {
    const { call, toggleCallMute, leaveCall } = this.props;
    const { startTime, expectedDuration, userName } = call;
    const { expertDidNotRespond } = this.state;

    const dialOutExpertButton = this.renderDialOutExpertButton();

    return (
      <MediaQuery maxWidth={SCREEN_MD}>
        {(isMobileVersion) => (
          <div
            className={cx(s.root, {
              [s.expertDidNotRespond]: expertDidNotRespond,
            })}
          >
            <div
              className={cx(s.contents, {
                [s.contentsExpanded]: call.connected,
              })}
            >
              {call.connected && (
                <>
                  <Timer
                    startTime={startTime}
                    expectedDuration={expectedDuration}
                  />
                  <Link to={call.mainPage} className={s.title}>
                    {expertDidNotRespond
                      ? `${userName} did not respond.`
                      : userName}
                  </Link>
                  <div className={s.controls}>
                    {!isMobileVersion && dialOutExpertButton}
                    <MicButton
                      mute={call.mute}
                      onClick={toggleCallMute}
                      detectTalking
                    />
                    <IconButton onClick={leaveCall}>
                      <MaterialIcon icon="call_end" color={white} />
                    </IconButton>
                    <IconButton onClick={this.showSettingsDialog}>
                      <FAIcon icon="cog" size={20} color={white} />
                    </IconButton>
                  </div>
                </>
              )}
            </div>

            {call.connected && (
              <>
                {isMobileVersion && !!dialOutExpertButton && (
                  <div className={s.dialOutExpertButton}>
                    {dialOutExpertButton}
                  </div>
                )}

                <CallAlert
                  visible={call.poorConnection}
                  message="Poor network connection"
                  warningName={call.warningName}
                />

                <DeviceSettingsDialog
                  open={this.state.settingsOpen}
                  onClose={this.closeSettingsDialog}
                />
              </>
            )}
          </div>
        )}
      </MediaQuery>
    );
  }

  renderDialOutExpertButton() {
    const { call } = this.props;
    const { allowDialoutIn, dialing, expertDidNotRespond } = this.state;

    if (!call.awaitingExpert && call.connected) return null;

    return expertDidNotRespond ? (
      <ReattemptButton onReattempt={this.handleDialOutExpert} />
    ) : (
      <DialOutExpertButton
        dialing={dialing}
        allowDialoutIn={allowDialoutIn}
        onDialOutExpert={this.handleDialOutExpert}
      />
    );
  }
}

ConferenceCall = connect(
  (state) => ({
    call: state.call,
  }),
  {
    toggleCallMute,
    leaveCall,
    popup,
    notify,
    hideMessage,
    dialOutExpert,
  }
)(ConferenceCall);
ConferenceCall = ConferenceCall;

export default ConferenceCall;

class Timer extends PureComponent {
  state = {
    currentTime: 0,
  };

  UNSAFE_componentWillMount() {
    const startTime = moment(this.props.startTime);

    const updateClock = () => {
      this.setState({ currentTime: offset(startTime) });
    };
    updateClock();
    this.counter = setInterval(updateClock, 1000);
  }

  componentWillUnmount() {
    if (!this.counter) return;
    clearInterval(this.counter);
  }

  render() {
    const { expectedDuration } = this.props;
    const { currentTime } = this.state;

    const color = currentTime > expectedDuration ? red500 : white;
    return (
      <span className={s.timer}>
        <CircularProgress
          style={{
            transform: 'rotate(-90deg)',
            marginRight: 10,
            color,
          }}
          variant="determinate"
          value={normalise(currentTime, expectedDuration)}
          thickness={2}
          size={28}
        />
        <span className={s.timerText}>{formatSeconds(currentTime)}</span>
      </span>
    );
  }
}

function offset(start, initialNow) {
  const now = initialNow || moment();
  return now.diff(start, 'seconds');
}

import React from 'react';
import s from './Wallpaper.module.scss';
import { white } from '../../core/colors';

function Wallpaper({ color, tint, videoSrc, imgSrc, vignette, imgPosition }) {
  const style = {};
  if (imgSrc) {
    style.backgroundImage = `url('${imgSrc}')`;
    if (tint) {
      style.backgroundImage = `linear-gradient(${tint}, ${tint}), url('${imgSrc}')`;
      style.backgroundBlendMode = 'multiply, luminosity';
      style.backgroundColor = white;
    }
    if (imgPosition) style.backgroundPosition = imgPosition;
  }

  return (
    <div className={s.root}>
      {color && <div className={s.color} style={{ backgroundColor: color }} />}
      {videoSrc && (
        <div className={s.video}>
          <video loop muted autoPlay className={s.videoPlayer}>
            <source src={`${videoSrc}.webm`} type="video/webm" />
            <source src={`${videoSrc}.mp4`} type="video/mp4" />
            <source src={`${videoSrc}.ogv`} type="video/ogg" />
          </video>
          {vignette && <div className={s.vignette} />}
        </div>
      )}
      {imgSrc && (
        <div className={s.image} style={style}>
          {vignette && <div className={s.vignette} />}
        </div>
      )}
    </div>
  );
}

export default Wallpaper;

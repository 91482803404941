import React from 'react';
import PropTypes from 'prop-types';
import MaterialIcon from '../Icon/MaterialIcon';
import history from '../../core/history';
import IconButton from '../IconButton/IconButton';

const EditIcon = ({ onClick, backToLocation, ...props }) => {
  return (
    <IconButton
      onClick={onClick || (() => history.push(backToLocation))}
      style={{ ...props.style, opacity: 0.3 }}
    >
      <MaterialIcon icon="mode_edit" />
    </IconButton>
  );
};

EditIcon.propTypes = {
  onClick: PropTypes.func,
  backToLocation: PropTypes.string,
};

export default EditIcon;

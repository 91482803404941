import React from 'react';
import {
  clearCollection,
  fetchAwaiting,
  fetchCanceled,
  fetchConfirmed,
  fetchCompleted,
  fetchStarting,
} from '../../actions/consultation';
import LayoutPage from '../../components/Layout/LayoutPage';
import ConsultationsStarting from '../../components/ConsultationsStarting';
import Consultations from './Consultations';

export default {
  path: '/consultations',

  async action({ store }) {
    const cursor = '';

    store.dispatch(fetchStarting());
    store.dispatch(fetchAwaiting(cursor, 10, true));
    store.dispatch(fetchConfirmed(cursor, 10, true));
    store.dispatch(fetchCanceled(cursor, 10, true));
    store.dispatch(fetchCompleted(cursor, 10, true));
    store.dispatch(clearCollection('default'));

    return {
      title: 'Consultations',

      component: (
        <LayoutPage showNav selected="consultations">
          <ConsultationsStarting />
          <Consultations />
        </LayoutPage>
      ),
    };
  },
};

class ZoomCarrier {
  /*
   * ZoomCarrier is a simplified version of the other telephony carriers that
   * opens a new tab for joining a call and does not directly implement calling
   * functionality.
   */
  register(events) {
    this._events = events;
  }

  // -- METHODS
  async connect(token, identifier, joinURL) {
    this._meetingWindow = window.open(joinURL);
    this._events.onDisconnected();
  }

  disconnect() {
    if (this._meetingWindow) {
      this._meetingWindow.close();
    }
  }
}

export default new ZoomCarrier();

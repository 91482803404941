import React from 'react';
import cx from 'classnames';
import s from './FlagIcon.module.scss';

function FlagIcon({ country, ...other }) {
  const classes = cx(s.flag, s[`flag-${country.toLowerCase()}`]);

  return <span className={classes} {...other} />;
}

export default FlagIcon;

import actionTypes from '../core/ActionTypes';

const { SUGGEST, SUGGEST_LOADING } = actionTypes;

const inFlight = {};

function suggest(scope, queryName, query, params) {
  return async (dispatch, getState, { graphql }) => {
    inFlight[scope] = inFlight[scope] || 0;

    const { text } = params;

    if (text.trim() === '') {
      return dispatch({
        type: SUGGEST,
        scope,
        text,
        loading: !!inFlight[scope],
        suggestions: null,
      });
    }

    if (getState().suggestions[scope].cache[text]) {
      // cache HIT
      return dispatch({
        type: SUGGEST,
        scope,
        text,
        loading: !!inFlight[scope],
        suggestions: getState().suggestions[scope].cache[text],
      });
    }

    dispatch({
      type: SUGGEST_LOADING,
      scope,
    });

    inFlight[scope] += 1;

    const result = await graphql.query(query, params);

    inFlight[scope] -= 1;

    dispatch({
      type: SUGGEST,
      scope,
      text,
      loading: !!inFlight[scope],
      suggestions: result[queryName],
    });

    return result;
  };
}

export function suggestUsers(scope, text, maxResults) {
  const query = `query suggestUsers($text: String!) {
    suggest (text: $text) {
      id
      first_name
      last_name
      name
      html_url
      picture_url
      city
      country
      emails { address }
    }
  }`;

  return suggest(scope, 'suggest', query, { text, maxResults });
}

export function suggestProfiles(scope, text, maxResults, profileType) {
  const query = `query suggestProfiles($text: String!, $profileType: ProfileType) {
    suggestProfiles (text: $text, type: $profileType) {
      id
      user {
        id
        expert_state
      }
      first_name
      last_name
      name
      html_url
      url_endpoint
      picture_url
      created_at
    }
  }`;

  return suggest(scope, 'suggestProfiles', query, {
    text,
    maxResults,
    profileType,
  });
}

import React, { PureComponent } from 'react';
import SettingsNav from './SettingsNav';
import Breadcrumbs from '../Breadcrumbs';
import s from './Settings.module.scss';

class Settings extends PureComponent {
  render() {
    const {
      nav,
      path,
      pathPrefix,
      title,
      component: Page,
      componentProps,
      breadcrumbs,
      subTitle,
    } = this.props;

    return (
      <div className={s.root}>
        {breadcrumbs && <Breadcrumbs crumbs={breadcrumbs} />}
        <div className={s.left}>
          <SettingsNav nav={nav} pathPrefix={pathPrefix} selected={path} />
        </div>
        <div className={s.right}>
          {subTitle}
          <h2 className={s.title}>{title}</h2>
          <Page {...componentProps} />
        </div>
      </div>
    );
  }
}

export default Settings;

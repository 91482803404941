import React, { useCallback } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { red500, darkBrown, darkGreen } from '../../core/colors';
import Link from '../Link';
import FAIcon from '../Icon/FAIcon';
import InternalNetworksBadge from '../InternalNetworksBadge';

const getStyles = makeStyles((theme) => ({
  badges: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'baseline',
    fontSize: 16,
    fontWeight: 'normal',
    margin: '20px 0',
  },
  ratingBadge: {
    marginRight: 15,
    [theme.breakpoints.down('md')]: {
      marginBottom: 10,
    },
  },
  networksBadge: {
    marginRight: 15,
    [theme.breakpoints.down('md')]: {
      marginBottom: 10,
    },
  },
  notInMarketplaceBadge: {
    cursor: 'pointer',
    color: darkBrown,
  },
}));

export default ({ user, profile, viewer, onEditMarketplace }) => {
  const s = getStyles();

  const handleMarketplaceBadgeClick = useCallback(
    (e) => {
      if (viewer.admin) {
        e.preventDefault();
        onEditMarketplace();
      }
    },
    [viewer.admin, onEditMarketplace]
  );

  const expertNetworks = profile.expert_internal_networks || [];
  const networks = expertNetworks.map((e) => e.network) || [];

  const showRating = user && user.rating > 0;
  const showNetworks = networks.length > 0;
  const showMarketplaceBadge = profile.available_marketplace === false;

  if (!showRating && !showNetworks && !showMarketplaceBadge) {
    return null;
  }

  return (
    <div className={s.badges}>
      {showRating && (
        <div className={s.ratingBadge}>
          <FAIcon icon="star" size={16} color={darkGreen} />{' '}
          {user.rating.toFixed(1)}
        </div>
      )}

      <InternalNetworksBadge
        className={s.networksBadge}
        networks={networks}
        fontSize={16}
        tooltipPlacement="right"
      />

      {showMarketplaceBadge && (
        <Link
          className={s.notInMarketplaceBadge}
          onClick={handleMarketplaceBadgeClick}
          to="/settings/rate"
        >
          <FAIcon icon="user-slash" size={16} color={red500} /> Not in
          Marketplace
        </Link>
      )}
    </div>
  );
};

import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import MaterialBadge from '@mui/material/Badge';
import { teal500, white } from '../../core/colors';

const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: 15,
    fontSize: 10,
  },
  badge: {
    color: white,
    backgroundColor: teal500,
  },
}));

export default function Badge({ children }) {
  const s = useStyles();

  return <MaterialBadge classes={s} badgeContent={children} />;
}

import React from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import cx from 'classnames';
import moment from 'moment-timezone';
import DateTimePicker from '../DateTimePicker';
import Link from '../Link';
import {
  getUserTime,
  isRedZone,
  getTimezoneDescription,
} from '../../core/time';
import s from './ConsultationDates.module.scss';

const ConsultationDates = ({
  name,
  userName,
  userTimezone,
  viewer,
  label,
  displayWarning,
  style,
  dateOnly,
}) => {
  const minDate = moment().startOf('day').toDate();
  return (
    <div style={style}>
      <div className={s.label}>{label}</div>

      <FieldArray name={name}>
        {({ fields }) =>
          fields.map((name, index) => {
            const value = fields.value[index];
            const timezoneClassName = cx(s.timezone, {
              [s.timezoneRed]: isRedZone(value, userTimezone),
            });

            return (
              <div key={name} className={s.picker}>
                <Field
                  component={DateTimePicker}
                  minDate={minDate}
                  name={name}
                  index={index}
                  placeholder={`Option ${index + 1}`}
                  style={{ flex: '1' }}
                  inline
                  timezone={viewer.timezone}
                  displayWarning={displayWarning}
                  dateOnly={dateOnly}
                />
                {!dateOnly && (
                  <div className={timezoneClassName}>
                    {value && getUserTime(value, userTimezone, userName)}
                  </div>
                )}
              </div>
            );
          })
        }
      </FieldArray>

      {!dateOnly && (
        <div className={s.changeTimezone}>
          <Link
            newTab
            className={s.changeTimezoneLink}
            to="/settings/communication"
          >
            Change My Timezone {getTimezoneDescription(viewer.timezone)}
          </Link>
        </div>
      )}
    </div>
  );
};

export default ConsultationDates;

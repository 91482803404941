import React from 'react';
import { Form, Field } from 'react-final-form';
import Dialog from '../../components/Dialog';
import { Checkbox, TextField } from '../../components/FormAdapters';
import { isEmailValid } from '../../core/util';

function InviteParticipant({
  viewer,
  consultation,
  open,
  onConfirm,
  onCancel,
  title = 'Invite Participant',
}) {
  const isRequester = consultation?.requester?.id === viewer?.id;
  const group =
    consultation?.group || consultation?.expert_request?.project?.group;
  const groupName = group?.name;

  const validate = (values) => {
    const errors = {};

    if (!values.name || !values.name.trim()) {
      errors.name = 'Required';
    } else if (values.name.trim().length > 100) {
      errors.name = 'Value too long';
    }
    if (!values.email) {
      errors.email = 'Required';
    } else if (!isEmailValid(values.email)) {
      errors.email = 'Email must be valid.';
    }

    return errors;
  };

  return (
    <Form validate={validate} onSubmit={onConfirm} initialValues={{}}>
      {({ form, handleSubmit }) => {
        const handleReset = () => {
          form.reset();
          onCancel();
        };

        const onSubmit = async (e) => {
          e.preventDefault();
          const error = await handleSubmit(e);
          if (error || form.getState().hasValidationErrors) return error;
          form.reset();
        };

        return (
          <Dialog
            useForm
            open={open}
            title={title}
            confirmLabel="Yes, Invite"
            onClose={handleReset}
            onCancel={handleReset}
            onSubmit={onSubmit}
          >
            <Field component={TextField} fullWidth name="name" label="Name" />
            <Field component={TextField} fullWidth name="email" label="Email" />
            {isRequester && group && (
              <Field
                type="checkbox"
                component={Checkbox}
                name="inviteJoinTeam"
                label={`Invite to Join Team: ${groupName}`}
              />
            )}
          </Dialog>
        );
      }}
    </Form>
  );
}

export default InviteParticipant;

import React from 'react';
import { Select } from '../Select/Select';

const DeviceSelector = ({ label, selected, options, disabled, onSelect }) => {
  const selectedValue = disabled ? options[0].deviceId : selected;

  return (
    <Select
      label={label}
      value={selectedValue}
      disabled={disabled}
      options={options.map((o) => ({
        label: o.label,
        value: o.deviceId,
      }))}
      onChange={onSelect}
    />
  );
};

export default DeviceSelector;

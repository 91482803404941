import React from 'react';
import BecomeAnExpert from './BecomeAnExpert';
import { fetchProfile } from '../../actions/profile';

const featuredExpertIds = ['lmccarthy', 'afataki', 'fliu', 'mfischetti'];

export default {
  path: '/become-an-expert',

  async action({ store, query }) {
    const profiles = await Promise.all(
      featuredExpertIds.map((urlEndpoint) =>
        store.dispatch(fetchProfile(urlEndpoint))
      )
    );

    const domainPart = query.domain ? `/${query.domain}` : '';

    return {
      title: 'Become an Expert',
      component: (
        <BecomeAnExpert
          profiles={profiles.filter(Boolean)}
          signupUrl={`${domainPart}/signup/expert`}
        />
      ),
    };
  },
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';
import { Autocomplete } from '../Select';
import { debounce } from '../../core/util';

const defaultGetOptionValue = (o) => ({ type: o.type, value: o.value });
const defaultGetOptionLabel = (o) => o.label || '';
const defaultIsOptionEqualToValue = (o, v) =>
  isEqual(defaultGetOptionValue(o), defaultGetOptionValue(v));

class SelectWithSuggestions extends PureComponent {
  /*
   * SelectWithSuggestions is a hybrid select component that allows
   * raw text input as well as selection from a list of autopopulated
   * suggestions.
   *
   * To differentiate between raw input and a suggestion the resulting value
   * is wrapped with the following structure:
   * {
   *   type: 'type',
   *   value: {}
   * }
   *
   * Where type is either the "rawType" or "suggestionType" string provided by
   * these properties.
   *
   * Likewise the value object is either the suggestion item in full or a
   * single key object with the given "rawKey" property. This allows for inputs
   * that take the same form as the suggestion object, i.e. an email or id key
   * that can be used to uniquely identify.
   */
  static propTypes = {
    allowRaw: PropTypes.bool,
    allowSuggestion: PropTypes.bool,
    suggest: PropTypes.func,
    suggestBufferSize: PropTypes.number,
    suggestScope: PropTypes.string,
    suggestArgs: PropTypes.arrayOf(PropTypes.any),
    suggestionsFilter: PropTypes.func,
    suggestions: PropTypes.object,
    loadingSuggestions: PropTypes.bool,
    errorText: PropTypes.string,
    multiple: PropTypes.bool,
    disabled: PropTypes.bool,
    underlineShow: PropTypes.bool,
    renderTags: PropTypes.func,
    renderOption: PropTypes.func,
    formatOption: PropTypes.func,
    isValidEntry: PropTypes.func,
    isValidRaw: PropTypes.func,
    isOptionEqualToValue: PropTypes.func,
    getOptionValue: PropTypes.func,
    getOptionLabel: PropTypes.func,
    rawType: PropTypes.string,
    rawKey: PropTypes.string,
    suggestionType: PropTypes.string,
  };

  static defaultProps = {
    suggestBufferSize: 10,
    suggestArgs: [],
    suggestionsFilter: () => true,
    errorText: undefined,
    underlineShow: true,
    formatOption: (o) => o,
    isValidEntry: () => true,
    isValidRaw: () => true,
    isOptionEqualToValue: defaultIsOptionEqualToValue,
    getOptionValue: defaultGetOptionValue,
    getOptionLabel: defaultGetOptionLabel,
    rawType: 'raw',
    rawKey: 'text',
    suggestionType: 'suggestion',
  };

  constructor(props) {
    super(props);
    this.state = {
      inputValue: '',
      suggestionText: '',
    };
  }

  // Populate redux state and suggestions prop
  suggest = debounce((value) => {
    const { suggest, suggestBufferSize, suggestScope, suggestArgs } =
      this.props;
    suggest(suggestScope, value, suggestBufferSize, ...suggestArgs).then(() => {
      this.setState({ suggestionText: value });
    });
  }, 500);

  handleChange = async (value) => {
    // value array contains entries that are either the text entered on the
    // Autocomplete component or an object if selected from the dropdown
    const { onChange, multiple, isValidEntry } = this.props;

    const currentValue = this.props.value
      ? multiple
        ? this.props.value
        : [this.props.value]
      : [];

    if (!onChange) {
      return;
    }

    let onChangeValue = value;
    if (!multiple) {
      onChangeValue = value.length > 0 ? value[0] : null;
    }

    // No checks to perform on removal
    if (value.length < currentValue.length) {
      return onChange(onChangeValue);
    }

    // Check newest entry in value array is valid
    const newEntry = value[value.length - 1];
    if (!isValidEntry(newEntry)) {
      return;
    }

    await onChange(onChangeValue);
    this.setState({ inputValue: '' });
  };

  handleInputChange = (event, value, reason) => {
    const { allowSuggestion } = this.props;
    if (reason === 'input') {
      this.setState({ inputValue: value });
      if (allowSuggestion) {
        this.suggest(value);
      }
    }
  };

  render() {
    const {
      allowRaw,
      allowSuggestion,
      suggestionsFilter,
      suggestions,
      loadingSuggestions,
      errorText,
      multiple,
      disabled,
      underlineShow,
      renderTags,
      renderOption,
      formatOption,
      isValidRaw,
      isOptionEqualToValue,
      getOptionValue,
      getOptionLabel,
      rawType,
      rawKey,
      suggestionType,
      ...rest
    } = this.props;
    const value = this.props.value
      ? multiple
        ? this.props.value
        : [this.props.value]
      : [];
    const { inputValue, suggestionText } = this.state;

    // don't wipe the chips on each suggestion change by merging with existing
    // values
    const options = [...value];
    const results = allowSuggestion
      ? [
          ...(suggestions[suggestionText] || []).map((v) => ({
            type: suggestionType,
            value: v,
          })),
        ].filter(suggestionsFilter)
      : [];

    // de-dupe
    for (let i = 0; i < results.length; ++i) {
      if (options.find((s) => isOptionEqualToValue(s, results[i]))) {
        continue;
      }
      options.push(results[i]);
    }

    // allow raw input
    const rawOption = { type: rawType, value: { [rawKey]: inputValue } };
    if (
      allowRaw &&
      isValidRaw(inputValue) &&
      suggestionsFilter(rawOption) &&
      !options.find((s) => isOptionEqualToValue(s, rawOption))
    )
      options.unshift(rawOption);

    return (
      <Autocomplete
        {...rest}
        multiple
        filterOptions={(x) => x}
        filterSelectedOptions
        options={options.map(formatOption)}
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={isOptionEqualToValue}
        disabled={disabled || !!(!multiple && value.length > 0)}
        renderOption={renderOption}
        renderTags={renderTags}
        rawValueOnChange
        onChange={this.handleChange}
        onInputChange={this.handleInputChange}
        inputValue={inputValue}
        value={value}
        loading={loadingSuggestions}
        TextFieldProps={{
          InputProps: { disableUnderline: !underlineShow },
          inputProps: { maxLength: 254 },
          disabled: !multiple && value.length > 0,
          error: !!errorText,
          helperText: errorText || '',
        }}
        fullWidth
      />
    );
  }
}

export default SelectWithSuggestions;

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import EditMembers from './Dialog';
import IconMenu from '../IconMenu';
import DeleteProjectDialog from '../DeleteDialog/DeleteProjectDialog';
import FAIcon from '../Icon/FAIcon';
import { black } from '../../core/colors';
import history from '../../core/history';

class SettingsMenu extends PureComponent {
  state = {
    dialogs: {},
    deleteConfirmationOpen: false,
  };

  openDialog = (dialog) => this.setState({ dialogs: { [dialog]: true } });

  closeDialog = (dialog) => this.setState({ dialogs: { [dialog]: false } });

  confirmDelete = () => this.setState({ deleteConfirmationOpen: true });

  closeConfirmDelete = () => this.setState({ deleteConfirmationOpen: false });

  render() {
    const { viewer, project, showDelete } = this.props;
    const members = project.members
      .filter((m) => ['active', 'denied'].includes(m.state))
      .sort((a, b) => {
        const roles = ['manager', 'associate', 'owner', 'member', 'viewer'];
        return roles.indexOf(a.role) - roles.indexOf(b.role);
      });

    return (
      <div>
        <IconMenu
          iconElement={
            <IconButton>
              <FAIcon icon="cog" color={black} />
            </IconButton>
          }
        >
          <MenuItem
            key="managerMembers"
            onClick={() => this.openDialog('members')}
          >
            Manage Members
          </MenuItem>
          <MenuItem
            key="addExpertRequest"
            onClick={() =>
              history.push(`/request_expert?project_id=${project.id}`)
            }
          >
            Add Expert Request
          </MenuItem>
          {showDelete && (
            <MenuItem
              key="deleteProject"
              onClick={this.confirmDelete}
              disabled={project.expert_requests.some(
                (e) => e.stats && e.stats.calls > 0
              )}
            >
              Delete Project
            </MenuItem>
          )}
        </IconMenu>
        <EditMembers
          viewer={viewer}
          open={this.state.dialogs.members}
          onClose={() => this.closeDialog('members')}
          project={project}
          initialValues={{ members }}
        />
        <DeleteProjectDialog
          projectId={project.id}
          onConfirm={this.closeConfirmDelete}
          onCancel={this.closeConfirmDelete}
          open={this.state.deleteConfirmationOpen}
          returnTo="/expert_requests"
        />
      </div>
    );
  }
}

SettingsMenu = connect((state) => ({
  viewer: state.viewer,
}))(SettingsMenu);

export default SettingsMenu;

import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import Link from '../Link';
import Body from '../Body';
import Facebook from './icons/footer-icon-facebook.svg';
import Twitter from './icons/footer-icon-twitter.svg';
import LinkedIn from './icons/footer-icon-linkedin.svg';
import Techstars from './icons/footer-icon-techstars.svg';
import Soc from './icons/footer-icon-soc.png';
import config from '../../../config';
import Divider from '../Divider/Divider';
import { darkGray } from '../../core/colors';
import { policies } from '../../core/agreements';
import s from './Footer.module.scss';
import PoweredByOnFrontiers from './PoweredByOnFrontiers';

const { mainSiteUrl } = config;

function Footer({ style, contactEmail = 'hello@onfrontiers.com' }) {
  const touPol = policies['terms-of-use'];
  const privacyPol = policies.privacy;

  return (
    <div className={s.root}>
      <footer className={s.footer} style={style}>
        <Body>
          <PoweredByOnFrontiers />

          <div className={s.top}>
            <ul className={s.linksColumn}>
              {/* <li>
                <Link className={s.link} to={`${mainSiteUrl}/about-us/`}>
                  About Us
                </Link>
              </li> */}
              {/* <li>
                <Link className={s.link} to={`${mainSiteUrl}/insights/`}>
                  Insights
                </Link>
              </li> */}
              <li>
                <Link className={s.link} to={`${mainSiteUrl}/blog`} force>
                  Blog
                </Link>
              </li>
              {/* <li>
                <Link className={s.link} to={`${mainSiteUrl}/company/careers/`}>
                  Careers
                </Link>
              </li> */}
            </ul>
            <ul className={s.linksColumn}>
              <li>
                <Link
                  className={s.link}
                  to="https://try.onfrontiers.com/knowledge"
                >
                  Support
                </Link>
              </li>
              {/* <li>
                <Link
                  className={s.link}
                  onClick={() => window.Intercom('show')}
                >
                  Contact Us
                </Link>
              </li> */}
              <li>
                <Link className={s.link} to={touPol.url}>
                  {touPol.name}
                </Link>
              </li>
              <li>
                <Link className={s.link} to={privacyPol.url}>
                  {privacyPol.name}
                </Link>
              </li>
            </ul>
            <ul className={cx(s.social, s.linksColumn)}>
              <li className={s.icon}>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.facebook.com/onfrontiers"
                >
                  <img src={Facebook} alt="Facebook" />
                </a>
              </li>
              <li className={s.icon}>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://twitter.com/Onfrontiers"
                >
                  <img src={Twitter} alt="Twitter" />
                </a>
              </li>
              <li className={s.icon}>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.linkedin.com/company/onfrontiers"
                >
                  <img src={LinkedIn} alt="LinkedIn" />
                </a>
              </li>
            </ul>
          </div>
          <Divider color={darkGray} spacing={25} />
          <div className={s.address}>
            <div className={s.addressColumn}>
              43 W 23rd St, 6th Floor&nbsp;
              <br />
              New York, NY 10010
              <br />
              +1 212 203 9005 •&nbsp;
              <a className={s.email} href={`mailto:${contactEmail}`}>
                {contactEmail}
              </a>
              <br />© OnFrontiers, Inc.
            </div>

            <div className={s.externalLogos}>
              <img className={s.techstars} src={Techstars} alt="Techstars" />

              <a
                href="https://www.aicpa.org/soc4so"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className={s.soc}
                  src={Soc}
                  alt="SOC for Service Organizations"
                />
              </a>
            </div>
          </div>
        </Body>
      </footer>
    </div>
  );
}

export default connect((state) => ({
  user: state.viewer,
}))(Footer);

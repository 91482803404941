import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { Field, Form } from 'react-final-form';
import Dialog from '../../Dialog';
import { removeOtpAuth } from '../../../actions/auth';
import { fetchUser } from '../../../actions/user';
import { notify } from '../../../actions/ui';
import { PasswordText } from '../../FormAdapters/FormAdapters';

const ERROR_INVALID_PASSWORD = 'GraphQL Error: invalid password';

const useStyles = makeStyles({
  title: {
    marginTop: 0,
  },
  password: {
    marginTop: 10,
  },
});

function DisableOtpAuth({
  user,
  removeOtpAuth,
  fetchUser,
  notify,
  onClose,
  onDisable,
  ...other
}) {
  const s = useStyles();

  const handleSubmit = useCallback(async (values) => {
    try {
      await removeOtpAuth(user.id, values.password);
      await fetchUser(user.username, { force: true, otpAuthEnabled: true });
      onClose();
      if (onDisable) onDisable();
    } catch (err) {
      if (err.message === ERROR_INVALID_PASSWORD) {
        return { password: 'Password is incorrect' };
      }
      notify(
        'An error occurred when trying to remove two-factor authentication.',
        'error'
      );
      throw err;
    }
  }, []);

  const validate = useCallback((values) => {
    const errors = {};

    if (!(values.password || '').trim()) {
      errors.password = 'Required';
    }

    return errors;
  }, []);

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      subscription={{ submitting: true }}
    >
      {({ handleSubmit, submitting }) => {
        return (
          <Dialog
            {...other}
            onClose={onClose}
            onCancel={onClose}
            onConfirm={handleSubmit}
            disableSubmit={submitting}
            cancelLabel="Cancel"
            confirmLabel="Yes, disable"
            confirmButtonProps={{ color: 'red' }}
            maxWidth="xs"
          >
            <form onSubmit={(e) => handleSubmit(e)}>
              <h4 className={s.title}>
                Are you sure you want to disable two-factor authentication?
              </h4>
              <Field
                component={PasswordText}
                name="password"
                label="Re-enter Password"
                variant="outlined"
                classes={{ root: s.password }}
              />
            </form>
          </Dialog>
        );
      }}
    </Form>
  );
}

DisableOtpAuth = connect(undefined, {
  removeOtpAuth,
  fetchUser,
  notify,
})(DisableOtpAuth);

export default DisableOtpAuth;

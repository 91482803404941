import React from 'react';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import s from './Newsletter.module.scss';
import Body from '../Body';
import { white, teal500 } from '../../core/colors';
import Button from '../Button/Button';

const useInputStyles = makeStyles(() => ({
  input: {
    color: white,
    '&::placeholder': {
      color: white,
      opacity: 1,
    },
  },
  underline: {
    '&:before, &:after': {
      borderColor: teal500,
    },
    '&:hover:not(.Mui-disabled):before, &:focus:not(.Mui-disabled):before': {
      borderColor: teal500,
    },
  },
}));

function Newsletter({
  title = 'Sign up for periodic updates to stay in the know',
  subTitle = 'We promise not to bombard you with emails',
  newsletterId = '431e152f78',
  ...other
}) {
  const inputClasses = useInputStyles();

  return (
    <section className={cx('newsletter', s.newsletter)} {...other}>
      <Body>
        <div className={s.newsletterRow}>
          <div className={s.newsletterText}>
            <div className={s.newsletterTitle}>{title}</div>
            <div className={s.newsletterSubtitle}>{subTitle}</div>
          </div>

          <form
            className={s.newsletterForm}
            action={
              '//onfrontiers.us13.list-manage.com/subscribe/post?' +
              `u=471a3a1033837d137634bc0fa&amp;id=${newsletterId}`
            }
            method="post"
          >
            <div className={s.newsletterInput}>
              <TextField
                InputProps={{
                  classes: inputClasses,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        fontColor={white}
                        style={{ padding: 5 }}
                        variant="text"
                        type="submit"
                      >
                        Subscribe
                      </Button>
                    </InputAdornment>
                  ),
                }}
                fullWidth
                id="newsletterEmail"
                name="EMAIL"
                type="email"
                margin="none"
                placeholder="Enter your email"
              />
            </div>
          </form>
        </div>
      </Body>
    </section>
  );
}

export default Newsletter;

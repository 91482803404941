import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { reduxForm, Field } from 'redux-form';
import { Checkbox } from '../../components/FormAdapters';
import history from '../../core/history';
import Article from '../../components/Article';
import Button from '../../components/Button/Button';
import MediaQuery from '../../components/MediaQuery';
import { SCREEN_SM } from '../../constants';
import { updateUser } from '../../actions/user';
import agreements, { policies, matchAgreement } from '../../core/agreements';
import s from './LegalAcknowledgement.module.scss';

class LegalAcknowledgement extends PureComponent {
  state = {
    accepted: null,
  };

  accepted = () => {
    const { accepted } = this.state;
    if (accepted !== null) return accepted;

    const { user, policyKeys } = this.props;
    return agreements(user.agreements).hasAccepted(...policyKeys);
  };

  handleAgree = (e, accepted) => {
    this.setState({ accepted });
  };

  handleSubmit = async () => {
    const { nextUrl, updateUser } = this.props;
    const selPolicies = this.selPolicies();

    if (!this.accepted()) throw new Error('legal should have been accepted');

    const { user } = this.props;
    const agreements = user.agreements || [];
    const now = new Date();
    selPolicies.forEach((policy) => {
      let agreement = agreements.find((a) => matchAgreement(a.policy, policy));
      if (!agreement) {
        agreement = {};
        agreements.push(agreement);
      }
      agreement.policy = policy.apiKey;
      agreement.accepted = true;
      agreement.updated_at = now;
    });

    await updateUser({ id: user.id, agreements });
    this.setState({ accepted: null });
    history.push(nextUrl);
  };

  selPolicies = () => {
    return (this.props.policyKeys || []).map((key) => policies[key]);
  };

  render() {
    const { pages, aggregateAgreementName, note } = this.props;
    const selPolicies = this.selPolicies();
    if (!selPolicies) {
      throw new Error('no policy to require acceptance');
    }

    const policyPages = selPolicies.map((p) => {
      const page = pages.find((x) => x.id === p.id);
      if (!page) throw new Error(`unable to find /${p.slug} blog entry`);
      return page;
    });

    const accepted = this.accepted();

    return (
      <div>
        <MediaQuery maxWidth={SCREEN_SM}>
          {(smallScreen) => (
            <div>
              <div className={s.title}>
                Please take a moment to review and agree to our updated{' '}
                {aggregateAgreementName}.
              </div>
              <div className={s.legalContents}>
                <div key={policyPages[0].id}>
                  <h1>{policyPages[0].title_text}</h1>
                  <Article html={policyPages[0].content.rendered} />
                </div>
                {policyPages.slice(1).map((page) => (
                  <div key={page.id}>
                    <hr />
                    <h1>{page.title_text}</h1>,
                    <Article html={page.content.rendered} />
                  </div>
                ))}
              </div>
              {note && <div className={s.note}>{note}</div>}
              <Field
                type="checkbox"
                name="user"
                component={Checkbox}
                checked={accepted}
                label={`I have read and agreed to the above ${aggregateAgreementName}.`}
                labelStyles={{ fontWeight: 'bold' }}
                onChange={(e) => this.handleAgree(e, !accepted)}
              />
              <Button
                onClick={this.handleSubmit}
                size={smallScreen ? 'normal' : 'large'}
                type="submit"
                disabled={!accepted}
                style={{ marginTop: 10 }}
              >
                Accept
              </Button>
            </div>
          )}
        </MediaQuery>
      </div>
    );
  }
}

LegalAcknowledgement = reduxForm({
  enableReinitialize: true,
  form: 'acceptedAgreements',
  validate: (values) => {
    const errors = {};
    if (!values.user) {
      errors.user = 'You must accept the privacy policy to proceed';
    }
  },
})(LegalAcknowledgement);

LegalAcknowledgement = connect(
  (state, ownProps) => ({
    user: state.users[ownProps.userId],
    pages: state.legal.pages,
  }),
  {
    updateUser,
  }
)(LegalAcknowledgement);

LegalAcknowledgement = LegalAcknowledgement;

export default LegalAcknowledgement;

import React from 'react';
import Platform from './Platform';
import { fetchProfile } from '../../actions/profile';

const featuredExpertIds = ['lmccarthy', 'afataki', 'fliu', 'mfischetti'];

export default {
  path: '/platform',

  async action({ store }) {
    const profiles = await Promise.all(
      featuredExpertIds.map((urlEndpoint) =>
        store.dispatch(fetchProfile(urlEndpoint))
      )
    );

    return {
      title: 'OnFrontiers',
      component: <Platform profiles={profiles.filter(Boolean)} />,
    };
  },
};

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { IconButton } from '@mui/material';
import { reduxForm, Field } from 'redux-form';
import Button from '../Button/Button';
import Form from '../Form';
import PhoneInput from '../PhoneInput';
import { setAddress, removeAddress } from '../../actions/address';
import {
  ErrAddressAlreadyTaken,
  ErrPhoneAlreadyExists,
} from '../../core/address';
import { red500 } from '../../core/colors';
import { notify } from '../../actions/ui';
import s from './EditPhones.module.scss';
import FAIcon from '../Icon/FAIcon';

function validate(values) {
  const errors = {};

  if (!values.phone) {
    errors.phone = 'Required';
  }

  return errors;
}

class Phone extends PureComponent {
  handleRemove = () => {
    const { address, onRemove } = this.props;
    if (onRemove) {
      onRemove(address);
    }
  };

  render() {
    const { address } = this.props;

    return (
      <div className={s.phoneRoot}>
        <div className={s.phone}>
          <span className={s.address} title={address}>
            {address}
          </span>
        </div>
        <div className={s.actions}>
          <IconButton onClick={this.handleRemove}>
            <FAIcon
              icon="trash-o"
              size={24}
              color={red500}
              className={s.controlButton}
            />
          </IconButton>
        </div>
      </div>
    );
  }
}

class EditPhones extends PureComponent {
  handleSubmit = (values) => {
    const { profileId, setAddress, reset, notify, profile } = this.props;
    const { phones = [] } = profile;
    return setAddress(profileId, 'phone', values.phone, !phones.length)
      .then(() => {
        reset();
      })
      .catch((e) => {
        switch (e.message) {
          case ErrAddressAlreadyTaken.message:
          case ErrPhoneAlreadyExists.message:
            notify(
              'Phone already in use, please add a different one.',
              'error'
            );
            break;
          default:
            notify('An error occurred when adding the phone number.', 'error');
            Promise.reject(e);
            break;
        }
      });
  };

  handleRemove = (phone) => {
    const { profileId, removeAddress } = this.props;
    removeAddress(profileId, 'phone', phone);
  };

  render() {
    const { profile, allCountries, handleSubmit } = this.props;

    const { phones } = profile;

    return (
      <div>
        <div className={s.root}>
          {phones.map((e) => (
            <Phone
              key={e.address}
              profileId={profile.id}
              {...e}
              onRemove={this.handleRemove}
            />
          ))}
        </div>

        <Form onSubmit={handleSubmit(this.handleSubmit)}>
          <div className={s.add}>
            <Field
              component={PhoneInput}
              type="tel"
              name="phone"
              label="Mobile Phone"
              className={s.phone}
              allCountries={allCountries}
              showExampleOnError
            />
            <Button type="submit" size="small">
              Add
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

EditPhones = reduxForm({
  form: 'phones',
  validate,
})(EditPhones);

export default connect(
  (state, ownProps) => ({
    allCountries: state.countries,
    profile: state.profiles.fullProfiles[ownProps.profileId],
  }),
  {
    setAddress,
    removeAddress,
    notify,
  }
)(EditPhones);

/* eslint-disable camelcase */
import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import RateAndPreferenceForm from '../../components/Forms/Profile/RateAndPreferenceForm';
import { updateProfile } from '../../actions/profile';
import history from '../../core/history';

const getStyles = makeStyles((theme) => ({
  title: {
    fontSize: 30,
    fontWeight: 'bold',
    marginTop: 30,
    marginBottom: 0,
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
      marginTop: 15,
    },
  },
  subtitle: {
    fontSize: 16,
    marginBottom: 30,
    color: '#3F4952',
  },
}));

function RateAndPreference({
  nextUrl,
  profileId,
  viewer,
  domain,
  profile,
  phoneRequired,
  updateProfile,
  autofillProfile,
  ...other
}) {
  const s = getStyles();
  const {
    bill_rate,
    available_long_term,
    available_marketplace,
    hide_profile,
  } = profile;

  const showHourlyRate = !domain || domain.signup_prompt_hourly_rate;
  const showAvailableMarketplace = domain && domain.signup_prompt_marketplace;
  const showProfilePublicity =
    !domain || domain.signup_prompt_profile_publicity;

  const initialValues = useMemo(
    () => ({
      bill_rate: showHourlyRate ? bill_rate : 0,
      available_marketplace: showAvailableMarketplace
        ? available_marketplace
        : !domain,
      public_profile: showProfilePublicity ? !hide_profile : false,
      available_long_term,
    }),
    []
  );

  const handleSubmit = useCallback(async (values) => {
    await updateProfile({
      id: profileId,
      ...values,
      hide_profile: !values.public_profile,
      bill_rate: values.bill_rate,
      public_profile: undefined,
    });

    history.push(nextUrl);
  }, []);

  const validate = useCallback((values) => {
    const errors = {};

    if (values.bill_rate === '' || values.bill_rate === undefined) {
      errors.bill_rate = 'Required';
    }

    return errors;
  }, []);

  return (
    <Grid container justifyContent="center">
      <Grid item md={8} sm={12}>
        <p className={s.title}>
          {showHourlyRate
            ? 'Set your rate and preferences'
            : 'Set your preferences'}
        </p>
        <p className={s.subtitle}>You can change these at any time.</p>

        <Form
          component={RateAndPreferenceForm}
          showHourlyRate={showHourlyRate}
          showAvailableMarketplace={showAvailableMarketplace}
          showProfilePublicity={showProfilePublicity}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validate={validate}
          {...other}
        />
      </Grid>
    </Grid>
  );
}

RateAndPreference.propTypes = {
  nextUrl: PropTypes.string.isRequired,
  profileId: PropTypes.string.isRequired,
};

RateAndPreference = connect(
  (state, ownProps) => {
    const profile = state.profiles.fullProfiles[ownProps.profileId] || {};
    return {
      profile,
    };
  },
  {
    updateProfile,
  }
)(RateAndPreference);

export default RateAndPreference;

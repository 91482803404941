import React from 'react';
import Tooltip from 'rc-tooltip';
import { Chip } from '@mui/material';
import Picture from '../Picture';
import { red500, orange500, white } from '../../core/colors';

const errorMessages = Object.freeze({
  out_of_marketplace: 'Not in the OnFrontiers marketplace',
});

export default function ({ profile, onRequestDelete, ...props }) {
  const result = props.result || {};

  const backgroundColor = result.error_code
    ? red500
    : result.warning_code
    ? orange500
    : undefined;

  const labelColor =
    result.error_code || result.warning_code ? white : undefined;

  const chip = (
    <Chip
      color={labelColor}
      onRequestDelete={onRequestDelete}
      style={{ marginRight: 5, marginTop: 5, backgroundColor }}
      label={profile.name}
      avatar={<Picture user={profile} link={false} size={32} />}
    />
  );

  const errorMessage = errorMessages[result.error_code || result.warning_code];

  if (errorMessage) {
    return (
      <Tooltip
        overlay={errorMessage}
        trigger={['hover', 'click']}
        overlayStyle={{ zIndex: 2000 }}
        placement="top"
      >
        {chip}
      </Tooltip>
    );
  }

  return chip;
}

export function isAdvancedUser(user) {
  return (
    user.admin ||
    (user.groups || []).some((g) =>
      ['advanced', 'enterprise'].includes(g.account_type)
    )
  );
}

export function isEnterpriseUser(user) {
  return (
    user.admin ||
    (user.groups || []).some((g) => g.account_type === 'enterprise')
  );
}

import React from 'react';

import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import Button from '@mui/material/Button';
import IconButton from '../../components/IconButton';
import Divider from '../../components/Divider';
import Picture from '../../components/Picture';
import FilterChip from './Filters/FilterChip';
import { white } from '../../core/colors';
import s from './SelectedProfilesBar.module.scss';
import FAIcon from '../../components/Icon/FAIcon';

class SelectedProfiles extends React.Component {
  state = {
    showSelected: false,
  };

  toggleSelected = () =>
    this.setState((prevState) => ({
      showSelected: !prevState.showSelected,
    }));

  render() {
    const { profiles, onClear, onRemove, children } = this.props;
    const { showSelected } = this.state;
    if (!profiles || profiles.length === 0) return null;
    return (
      <div>
        <div className={s.selectedProfilesTop}>
          <div className={s.selectedProfilesText}>
            {profiles.length} {profiles.length === 1 ? 'expert' : 'experts'}{' '}
            selected
            <Button
              onClick={onClear}
              style={{
                textTransform: 'none',
                color: '#C2C2C2',
                marginRight: 5,
              }}
            >
              Clear
            </Button>
          </div>
          <div className={s.selectedProfilesAction}>
            {children}
            <IconButton onClick={this.toggleSelected}>
              <FAIcon
                icon={`chevron-${showSelected ? 'up' : 'down'}`}
                size={18}
                color={white}
              />
            </IconButton>
          </div>
        </div>
        {showSelected && (
          <div>
            <Divider color={white} style={{ opacity: 0.2 }} />
            <div className={s.selectedProfilesChips}>
              {profiles.map((p) => (
                <FilterChip
                  key={p.id}
                  onDelete={() => onRemove(p)}
                  avatar={<Picture link={false} size={24} user={p} />}
                  label={p.name}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }
}

class SelectedProfilesBar extends React.Component {
  render() {
    const { profiles, children, onClear, onRemove } = this.props;
    return (
      <Snackbar open={profiles.length > 0} className={s.selectedProfilesBar}>
        <SnackbarContent
          className={s.selectedProfilesContent}
          classes={{ message: s.selectedProfilesContentMessage }}
          message={
            <SelectedProfiles
              profiles={profiles}
              onClear={onClear}
              onRemove={onRemove}
            >
              {children}
            </SelectedProfiles>
          }
        />
      </Snackbar>
    );
  }
}

export default SelectedProfilesBar;

import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import LongText from '../LongText/LongText';
import { darkGray } from '../../core/colors';

const getStyles = makeStyles(() => ({
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
    alignItems: 'flex-start',
  },
  emptyText: {
    fontSize: 16,
    lineHeight: '24px',
    color: darkGray,
  },
}));

export default ({ profile, editable, onEdit }) => {
  const s = getStyles();

  return (
    (profile.summary || editable) && (
      <div className={s.row}>
        {profile.summary ? (
          <LongText text={profile.summary} />
        ) : (
          editable && (
            /* eslint-disable-next-line */
            <a onClick={onEdit}>
              <LongText
                className={s.emptyText}
                text="Describe yourself a little bit"
              />
            </a>
          )
        )}
      </div>
    )
  );
};

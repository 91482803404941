import React from 'react';
import Link from '../Link';
import { groupBy } from '../../core/util';
import s from './InvitationSummary.module.scss';

function InvitationSummary({ profiles, onInvitationExampleClick }) {
  const registered = profiles.filter((p) => p.user);
  const unregistered = profiles.filter(unregisteredUserNotFromIntegration);
  const unregisteredFromIntegration = profiles.filter(
    unregisteredUserFromIntegration
  );
  const unregisteredByAgent = groupBy(
    unregisteredFromIntegration,
    (p) => p.sources[0].agent.name
  );

  const pluralizeExpert = (list) => (list.length === 1 ? 'expert' : 'experts');

  return (
    <div className={s.profileSummary}>
      <div>Invitation will be sent to:</div>
      {registered.length > 0 && (
        <div className={s.profileSummaryType}>
          {registered.length} registered {pluralizeExpert(registered)}
          &nbsp;
          <Link onClick={() => onInvitationExampleClick(registered[0])}>
            (show example)
          </Link>
        </div>
      )}
      {unregistered.length > 0 && (
        <div className={s.profileSummaryType}>
          {unregistered.length} unregistered {pluralizeExpert(unregistered)}
          &nbsp;
          <Link onClick={() => onInvitationExampleClick(unregistered[0])}>
            (show example)
          </Link>
        </div>
      )}
      {Object.keys(unregisteredByAgent).map((agent) => (
        <div className={s.profileSummaryType}>
          {unregisteredByAgent[agent].length} unregistered
          {pluralizeExpert(unregisteredByAgent[agent])} from {agent}
          &nbsp;
          <Link
            onClick={() =>
              onInvitationExampleClick(unregisteredByAgent[agent][0])
            }
          >
            (show example)
          </Link>
        </div>
      ))}
    </div>
  );
}

function unregisteredUserNotFromIntegration(p) {
  return !p.user && !fromIntegration(p);
}

function unregisteredUserFromIntegration(p) {
  return !p.user && fromIntegration(p);
}

function fromIntegration(p) {
  return (
    p.sources &&
    p.sources[0] &&
    p.sources[0].agent &&
    !!p.sources[0].agent.custom_candidate_invitation_message
  );
}

InvitationSummary = InvitationSummary;

export default InvitationSummary;

import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Tooltip from 'rc-tooltip';
import { formatCredits } from '../../core/util';
import history from '../../core/history';
import Divider from '../Divider';
import RequestConsultationButton from '../Button/RequestConsultationButton';
import AddToExpertRequestButton from '../AddToExpertRequestButton';
import SendMessageButton from '../SendMessageButton';
import { darkGray } from '../../core/colors';

const getStyles = makeStyles(() => ({
  sendMessageButton: {
    marginTop: 15,
    textAlign: 'center',
  },
}));

const getSharingStyles = makeStyles(() => ({
  sharing: {
    marginTop: 10,
    textAlign: 'center',
    fontSize: 12,
  },
  sharingGroup: {
    fontWeight: 'bold',
  },
}));

const getBookingStyles = makeStyles(() => ({
  credits: {
    textAlign: 'center',
  },
  creditsLabel: {
    textTransform: 'uppercase',
    fontSize: 10,
  },
  creditsValue: {
    fontWeight: 'bold',
    fontSize: 14,
  },
  expertStateButton: {
    marginTop: 10,
  },
  buttonInfo: {
    color: darkGray,
    fontSize: 12,
    textAlign: 'center',
    marginTop: 8,
  },
}));

function Booking({ profile, requestUrl, canRequestConsultation }) {
  const s = getBookingStyles();
  const credits = formatCredits(profile.credit_rate);

  return (
    <div>
      <Divider spacing={10} />

      <div className={s.credits}>
        <span className={s.creditsLabel}>From</span>
        <span className={s.creditsValue}>
          &nbsp;{credits} {Number(credits) === 1 ? 'credit' : 'credits'}/hr
        </span>
      </div>

      {canRequestConsultation && (
        <>
          <div className={s.expertStateButton}>
            <RequestConsultationButton
              fullWidth
              onClick={() => history.push(requestUrl)}
            />
          </div>

          <div className={s.buttonInfo}>
            You won&apos;t be charged until confirming
          </div>
        </>
      )}
    </div>
  );
}

function Sharing({ profile }) {
  const s = getSharingStyles();

  const { expert_internal_networks: inetexps } = profile;
  const groupNames = (inetexps || [])
    .map((ine) => ine.from_group && ine.from_group.name)
    .filter(Boolean);

  if (!groupNames.length) return null;
  if (groupNames.length === 1)
    return (
      <div className={s.sharing}>
        Shared by <span className={s.sharingGroup}>{groupNames[0]}</span>
      </div>
    );

  const tooltip = (
    <div>
      {groupNames.slice(1).map((gn) => (
        <div>• {gn}</div>
      ))}
    </div>
  );

  const otherPluralized = groupNames.length === 2 ? 'other' : 'others';

  return (
    <div className={s.sharing}>
      Shared by <span className={s.sharingGroup}>{groupNames[0]}</span>
      &nbsp;and{' '}
      <Tooltip
        overlay={tooltip}
        trigger={['hover']}
        overlayStyle={{ zIndex: 2000 }}
        placement="bottom"
      >
        <span>
          {groupNames.length - 1} {otherPluralized}
        </span>
      </Tooltip>
    </div>
  );
}

function Actions({
  canViewBooking,
  canAddToRequest,
  canRequestConsultation,
  user,
  profile,
  requestUrl,
  showMessageButton,
}) {
  const s = getStyles();

  const theme = useTheme();

  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  if (mobile) return null;

  return (
    <>
      {canViewBooking && (
        <Booking
          canRequestConsultation={canRequestConsultation}
          profile={profile}
          requestUrl={requestUrl}
        />
      )}

      {canAddToRequest && (
        <AddToExpertRequestButton
          fullWidth
          profile={profile}
          style={{ marginTop: 15 }}
          form="addToExpertRequestFromProfile"
        />
      )}

      {showMessageButton && (
        <div className={s.sendMessageButton}>
          <SendMessageButton userId={user.id} />
        </div>
      )}

      <Sharing profile={profile} />
    </>
  );
}

export default Actions;

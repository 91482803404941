import React from 'react';
import Admin from './Admin';

export default {
  path: '/admin/:model?/:id?',
  async action({ store, params }) {
    const { viewer } = store.getState();
    if (!viewer.admin) return;

    const { model, id } = params;

    if (!model) {
      return { redirect: '/admin/landing_pages' };
    }

    return {
      title: 'Admin',
      component: <Admin model={model} id={id} />,
    };
  },
};

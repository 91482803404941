import React from 'react';

import Link from '../../../components/Link';
import FAIcon from '../../../components/Icon/FAIcon';
import Message from '../../../components/Message';
import QueryResponses from '../../../components/QueryResponses';
import { highlight } from '../../../core/util';
import { darkGreen } from '../../../core/colors';
import s from './Candidates.module.scss';

function ExpertRequestEvent({
  user,
  action,
  request,
  project,
  highlightedResponses,
  highlightedNotes,
  highlightedRequestName,
}) {
  const requestName = highlightedRequestName || request.name;
  return (
    <div className={s.projectEvent}>
      <div>
        <FAIcon
          size={16}
          icon="clipboard-list"
          style={{ color: darkGreen, marginRight: 5 }}
        />
      </div>
      <div>
        <div>
          <Link className={s.authorLink} to={user && user.html_url}>
            {user ? user.name : 'User'}
          </Link>{' '}
          {action}{' '}
          {requestName && (
            <span>
              for{' '}
              <Link
                className={s.requestName}
                to={request.html_url}
                dangerouslySetInnerHTML={{
                  __html: highlight(requestName),
                }}
              />{' '}
              {project.group && (
                <span className={s.groupTag}>({project.group.name})</span>
              )}
            </span>
          )}
        </div>
        {highlightedNotes && (
          <Message className={s.message} bubbleClassName={s.bubble} user={user}>
            <span
              dangerouslySetInnerHTML={{
                __html: highlight(`(…) ${highlightedNotes.join(' (…) ')}…`, {
                  multiline: true,
                }),
              }}
            />
          </Message>
        )}
        {highlightedResponses?.length > 0 && (
          <Message className={s.message} bubbleClassName={s.bubble} user={user}>
            <QueryResponses
              queryType={action?.split(' ').pop()}
              values={highlightedResponses}
            />
          </Message>
        )}
      </div>
    </div>
  );
}

const stateToHuman = {
  suggested_by_platform: 'Suggested By Platform',
  suggested_by_research: 'Suggested',
  contacted: 'Contacted',
  interested: 'Interested',
  polishing: 'Polishing',
  rejected_suggestion: 'Rejected',
  vetting: 'Vetting',
  verified: 'Verified',
  matched: 'Matched',
  rejected_by_client: 'Rejected',
  rejected_by_research: 'Rejected',
};

function Candidates({ expert, candidates }) {
  if (!candidates || !candidates.length) return null;

  return (
    <div className={s.candidates}>
      {candidates.map((c) => (
        <div key={c.id}>
          {c.request_name_highlight && (
            <ExpertRequestEvent
              user={expert}
              request={c.request}
              project={c.project}
              action={`moved to "${stateToHuman[c.state] || ''}"`}
              highlightedRequestName={c.request_name_highlight}
            />
          )}
          {c.notes &&
            c.notes.map((note) => (
              <ExpertRequestEvent
                key={note.id}
                user={note.author}
                request={c.request}
                project={c.project}
                action={
                  expert.id === (note.author && note.author.id)
                    ? 'responded as "Interested"'
                    : `commented on ${expert.name}`
                }
                highlightedNotes={note.text_snippets}
              />
            ))}
          {c.answerable_questions?.length > 0 && (
            <ExpertRequestEvent
              user={expert}
              request={c.request}
              project={c.project}
              action="responded to questions"
              highlightedResponses={c.answerable_questions}
            />
          )}
          {c.qualification_responses?.length > 0 && (
            <ExpertRequestEvent
              user={expert}
              request={c.request}
              project={c.project}
              action="responded to qualifications"
              highlightedResponses={c.qualification_responses}
            />
          )}
        </div>
      ))}
    </div>
  );
}

export default Candidates;

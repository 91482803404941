/* eslint-disable camelcase */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import MenuItem from '@mui/material/MenuItem';
import { Form } from 'react-final-form';
import Grid from '@mui/material/Grid';
import arrayMutators from 'final-form-arrays';
import FAIcon from '../../../components/Icon/FAIcon';
import IconMenu from '../../../components/IconMenu';
import IconButton from '../../../components/IconButton';
import { darkBrown } from '../../../core/colors';
import RequestDetails from '../../../components/Forms/ExpertRequest/DetailsNew';
import SubmitContainer from '../../../components/Forms/ExpertRequest/SubmitContainer';
import {
  fetchExpertRequests,
  saveExpertRequest,
} from '../../../actions/expertRequest';
import { notify } from '../../../actions/ui';
import { updateConsultation, Trigger } from '../../../actions/consultation';
import AttachToExpertRequestBanner from '../AttachToExpertRequestBanner';
import AttachToExpertRequest from '../AttachToExpertRequest';

const useStyles = makeStyles((theme) => ({
  promo: {
    flex: '0 0 360px',
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      width: '100%',
    },
  },
  details: {
    flex: '0 0 360px',
    [theme.breakpoints.down('lg')]: {
      flex: '0 0 320px',
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      width: '100%',
    },
  },
}));

function ExpertRequestDetails({
  consultation,
  isViewerExpert,
  fetchExpertRequests,
  updateConsultation,
  notify,
  isMobileVersion,
}) {
  const s = useStyles();
  const { expert_request: expertRequest } = consultation;
  const canAttachToExpertRequest = !isViewerExpert;

  const [openAttachToExpertRequest, setOpenAttachToExpertRequest] =
    useState(false);
  const [editRequest, setEditRequest] = useState(false);

  const handleRequestExpertRequestAttach = () => {
    const { expert_request: expertRequest } = consultation;

    fetchExpertRequests({ withGroupOnly: !expertRequest }).then(() =>
      setOpenAttachToExpertRequest(true)
    );
  };

  const handleAttachToExpertRequest = ({ expert_request_id } = {}) => {
    return updateConsultation({
      id: consultation.id,
      expert_request_id,
      trigger: Trigger.consultationPage,
    })
      .then(() => {
        const msg =
          expert_request_id === ''
            ? 'Successfully removed Expert Request from Consultation'
            : 'Successfully added Expert Request to Consultation';
        notify(msg, 'success');
        setOpenAttachToExpertRequest(false);
      })
      .catch((ex) => {
        notify(ex.message, 'error');
      });
  };

  const handleExpertRequestRemove = () =>
    handleAttachToExpertRequest({ expert_request_id: '' });

  const handleExpertRequestEdit = () => {
    setEditRequest(true);
  };

  const handleExpertRequestGo = () => {
    if (expertRequest) {
      const win = window.open(`/expert_request/${expertRequest.id}`, '_blank');
      win.focus();
    }
  };

  const handleExpertRequestSave = async (values) => {
    setEditRequest(false);
    const { expert_request: expertRequest } = consultation;
    const permissions = expertRequest.permissions || [];
    const canEditQueries = permissions.includes('update_queries');

    try {
      await saveExpertRequest(values, { includeQueries: canEditQueries });
    } catch (err) {
      notify('An error occurred when updating the expert request.', 'error');
    }
    notify('Saved successfully.', 'success');
  };

  let showRequestFields;
  let hideRequestFields;
  if (isViewerExpert) {
    // experts can only see these fields
    showRequestFields = [
      'name',
      'description',
      'opportunity_location',
      'job_scope',
      'qualifications',
      'questions',
      'sectors',
      'regions',
      'focusAreas',
      'attachments',
    ];
  } else {
    // exclude these fields from ER details via Consultation page
    hideRequestFields = ['project'];
  }

  const requestContextActions = [
    ...(handleExpertRequestEdit
      ? [
          {
            text: 'Go To Expert Request',
            onClick: handleExpertRequestGo,
          },
        ]
      : []),
    ...(handleExpertRequestEdit
      ? [
          {
            text: 'Edit Associated Expert Request',
            onClick: handleExpertRequestEdit,
          },
        ]
      : []),
    ...(handleExpertRequestRemove
      ? [
          {
            text: 'Remove Expert Request',
            onClick: handleExpertRequestRemove,
          },
        ]
      : []),
  ];

  let content = null;
  if (!expertRequest) {
    content = canAttachToExpertRequest && (
      <div className={s.promo}>
        <AttachToExpertRequestBanner
          className={s.details}
          consultation={consultation}
          onRequestExpertRequestAttach={handleRequestExpertRequestAttach}
        />
      </div>
    );
  } else {
    content = (
      <div style={{ flexDirection: 'row' }}>
        {!isViewerExpert && (
          <div style={{ textAlign: 'right', margin: '0 20px -40px' }}>
            <IconMenu
              iconElement={
                <IconButton>
                  <FAIcon icon="cog" color={darkBrown} />
                </IconButton>
              }
            >
              {requestContextActions.map((a) => (
                <MenuItem
                  key={a.text}
                  onClick={a.onClick}
                  disabled={a.disabled}
                >
                  {a.text}
                </MenuItem>
              ))}
            </IconMenu>
          </div>
        )}
        <Form
          onSubmit={handleExpertRequestSave}
          initialValues={expertRequest}
          mutators={{
            ...arrayMutators,
          }}
        >
          {({ handleSubmit, form, values }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={4}>
                <Grid item md={isViewerExpert ? 12 : 8} sm={12}>
                  <RequestDetails
                    isViewerExpert={isViewerExpert}
                    showFields={showRequestFields}
                    hideFields={hideRequestFields}
                    canEdit={editRequest}
                    change={form.change}
                    values={values}
                    isMobileVersion={isMobileVersion}
                  />
                </Grid>
                {!isViewerExpert && (
                  <Grid
                    item
                    md={4}
                    sm={12}
                    style={{
                      position: 'sticky',
                      top: 0,
                      height: '100%',
                      marginTop: '150px',
                    }}
                  >
                    <SubmitContainer disabled={!editRequest} />
                  </Grid>
                )}
              </Grid>
            </form>
          )}
        </Form>
      </div>
    );
  }

  return (
    <>
      {content}

      <AttachToExpertRequest
        open={openAttachToExpertRequest}
        consultation={consultation}
        onConfirm={handleAttachToExpertRequest}
        onCancel={() => setOpenAttachToExpertRequest(false)}
      />
    </>
  );
}

export default connect(undefined, {
  notify,
  fetchExpertRequests,
  saveExpertRequest,
  updateConsultation,
})(ExpertRequestDetails);

import React, { useEffect, useMemo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { connect } from 'react-redux';
import { reduxForm, Field, FieldArray } from 'redux-form';
import { updateProfile } from '../../../actions/profile';
import { fetchAllGroups } from '../../../actions/group';
import KeywordInput from '../../KeywordInput';
import Form from '../../Form';
import { Select } from '../../FormAdapters/FormAdapters';
import Divider from '../../Divider/Divider';
import RemoveButton from './buttons/RemoveButton';
import AddButton from './buttons/AddButton';
import { updateProfileKeywords } from '../../../actions/internalNetwork';
import { darkGreen } from '../../../core/colors';
import useAppContext from '../../useAppContext';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    marginBottom: 15,
  },
  field: {
    flex: 1,
  },
  remove: {
    marginRight: 10,
  },
  add: {
    paddingTop: 10,
    color: darkGreen,
    fontWeight: 'bold',
    cursor: 'pointer',
    display: 'inline-block',
  },
}));

const GroupField = ({ field, options, selected, onRemoveGroup, change }) => {
  const s = useStyles();

  return (
    <div className={s.container} key={field}>
      <div className={s.remove}>
        <RemoveButton onClick={onRemoveGroup} />
      </div>
      <div className={s.field}>
        <Field
          name={`${field}.group.id`}
          component={Select}
          label="Select Team"
          margin="none"
          options={options}
          disabled={!options.length}
          onChange={(_, value) => {
            change(field, {
              group: { id: value },
              keywords: [],
            });
          }}
        />

        <Field
          component={KeywordInput}
          openOnFocus
          primary
          disabled={!selected}
          options={selected?.keywords || []}
          getOptionLabel={(o) => o.name}
          name={`${field}.keywords`}
          placeholder="Team-Specific Keywords"
          style={{ marginBottom: 24 }}
        />
      </div>
    </div>
  );
};

const GroupsKeyword = (props) => {
  const { fields, groups = [], options, array } = props;

  const ids = groups.map((g) => g.group?.id);

  // check if all available group fields are selected
  const allFilled = ids.length === ids.filter(Boolean).length;
  const allSelected = ids.length === options.length;

  const handleAddKeyword = (field, keywords, value) => {
    const keyword = keywords.find(
      (k) => k.id === value.id || k.name === value.name
    );

    if (keyword) array.push(`${field}.keywords`, keyword);
  };

  const handleRemoveKeyword = (field, index) => {
    array.remove(`${field}.keywords`, index);
  };

  const handleAddGroup = () => {
    fields.push({ keywords: [] });
  };

  const addButton = !allSelected && (
    <AddButton label="Team" onClick={handleAddGroup} />
  );

  return (
    <>
      {fields?.map((field, idx) => {
        const value = ids[idx];
        const selected = options.find((op) => op.value === value);

        return (
          <GroupField
            {...props}
            field={field}
            options={options.filter(
              (op) => op.value === value || !ids.includes(op.value)
            )}
            idx={idx}
            key={field}
            ids={ids}
            selected={selected}
            onAddKeyword={handleAddKeyword}
            onRemoveKeyword={handleRemoveKeyword}
            onAddGroup={handleAddGroup}
            onRemoveGroup={() => fields.remove(idx)}
          />
        );
      })}

      {allFilled && !allSelected && addButton}
    </>
  );
};

export function KeywordsFields({
  showGeneralKeywords = true,
  showGroupKeywords = true,
  component: Container = Form,
  groups,
  formValues,
  handleSubmit,
  handleReset,
  array,
  change,
  ...other
}) {
  const options = useMemo(
    () =>
      groups?.edges
        ?.filter(({ node: g }) => g.profile_keywords_definition?.length)
        .map(({ node: g }) => ({
          label: g.name,
          value: g.id,
          keywords: g.profile_keywords_definition,
        })),
    [groups]
  );

  return (
    <Container {...other} onSubmit={handleSubmit} onReset={handleReset}>
      {showGroupKeywords && (
        <FieldArray
          name="group_keywords"
          groups={formValues?.group_keywords}
          component={GroupsKeyword}
          options={options}
          array={array}
          change={change}
        />
      )}

      {showGroupKeywords && showGeneralKeywords && <Divider />}

      {showGeneralKeywords && (
        <Field
          component={KeywordInput}
          freeSolo
          getOptionLabel={(o) => o}
          autoSelect
          options={[]}
          id="keywords"
          name="keywords"
          label="Other Keywords"
        />
      )}
    </Container>
  );
}

function KeywordsForm({
  profileId,
  profile,
  self,
  onSubmit,
  viewer,
  fetchAllGroups,
  updateProfile,
  updateProfileKeywords,
  reset,
  onReset,
  handleSubmit: handleSubmitProp,
  ...rest
}) {
  const { permissions } = useAppContext();

  useEffect(() => {
    fetchAllGroups({
      keywordsConfig: true,
    });
  }, []);

  const canEditGroupKeywords = useMemo(
    () =>
      permissions.allowed('group_member', 'update_group_keywords', profileId),
    [profileId]
  );

  const canEditProfileKeywords = useMemo(
    () => self || viewer.admin,
    [self, viewer.admin]
  );

  const handleSubmit = (values) => {
    if (canEditProfileKeywords) {
      updateProfile({ id: profileId, ...values });
    }

    const reduceKeywords = (groupKeywords) =>
      (groupKeywords || []).reduce((ks, g) => [...ks, ...g.keywords], []);

    const keywords = reduceKeywords(profile.group_keywords);
    const valuesKeywords = reduceKeywords(values.group_keywords);

    const toAdd = valuesKeywords
      .map((k) => k.id)
      .filter((id) => !keywords.some((k) => k.id === id));

    const toDelete = keywords
      .map((k) => k.id)
      .filter((id) => !valuesKeywords.some((k) => k.id === id));

    if (toAdd.length > 0 || toDelete.length > 0) {
      updateProfileKeywords(profile.id, toAdd, toDelete);
    }

    if (onSubmit) {
      onSubmit(values);
    }
  };

  const handleReset = () => {
    reset();

    if (onReset) {
      onReset();
    }
  };

  return (
    <KeywordsFields
      {...rest}
      showGroupKeywords={canEditGroupKeywords}
      showGeneralKeywords={canEditProfileKeywords}
      handleSubmit={handleSubmitProp(handleSubmit)}
      handleReset={handleReset}
    />
  );
}

KeywordsForm = reduxForm({
  form: 'expertKeywords',
  enableReinitialize: true,
})(KeywordsForm);

KeywordsForm = connect(
  (state, ownProps) => {
    const profile = state.profiles.fullProfiles[ownProps.profileId];
    return {
      self: state.viewer.id === ownProps.userId,
      viewer: state.viewer,
      profile,
      groups: state.groups.all || [],
      formValues: state.form.expertKeywords?.values,
      initialValues: {
        group_keywords: profile.group_keywords?.length
          ? profile.group_keywords
          : [{ keywords: [] }],
        keywords: profile.keywords,
      },
    };
  },
  {
    updateProfile,
    updateProfileKeywords,
    fetchAllGroups,
  }
)(KeywordsForm);

export default KeywordsForm;

import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import cx from 'classnames';
import MuiTextField from '@mui/material/TextField';
import { TextField } from '../FormAdapters';
import Dialog from '../Dialog';
import FieldContainer from '../FieldContainer';
import Link from '../Link';
import CircularProgress from '../CircularProgress';
import CandidateChip from '../AddToExpertRequestButton/CandidateChip';
import MessageTemplatesDialog from './MessageTemplatesDialog';
import MessageTemplateVariablesDialog from './MessageTemplateVariablesDialog';
import { renderTemplate } from '../../actions/messageTemplate';
import {
  createChannel,
  fetchChannel,
  sendMessage,
} from '../../actions/messaging';
import { getChannelUrl } from '../../core/messaging';
import { notify } from '../../actions/ui';
import s from './SendMessageDialog.module.scss';

function MessagePreview({ loading, message }) {
  return (
    <div className={cx({ [s.messageLoading]: loading })}>
      {loading && (
        <div className={s.messageProgress}>
          <CircularProgress />
        </div>
      )}

      <div className={s.messageFields}>
        <FieldContainer className={s.messageField} label="Message">
          <MuiTextField
            InputProps={{ disableUnderline: true }}
            multiline
            fullWidth
            rows={10}
            maxRows={10}
            value={message || ''}
          />
        </FieldContainer>
      </div>
    </div>
  );
}

function SendMessageForm({
  // Props
  open,
  profiles = [],
  onClose,
  onSend,
  expertRequestId,

  // Redux State
  viewer,

  // Redux Actions
  notify,
  renderTemplate,
  createChannel,
  fetchChannel,
  sendMessage,
}) {
  const validate = useCallback((values) => {
    const errors = {};
    if (!values.message) {
      errors.message = 'Need to specify a message';
    }
    return errors;
  }, []);

  const sendMessageToUser = useCallback(
    async (profile, messageTemplate) => {
      const message = await renderTemplate(messageTemplate, {
        senderId: viewer.id,
        requestId: expertRequestId,
        profileId: profile.id,
      });

      const channelId = await createChannel([viewer.id, profile.user.id]);
      const channelUrl = getChannelUrl(channelId);
      await fetchChannel(channelUrl);
      await sendMessage(channelUrl, message);
      return channelId;
    },
    [viewer, expertRequestId]
  );

  const handleSubmit = useCallback(
    async (values) => {
      const { message } = values;

      try {
        if (profiles.length === 1) {
          const channelId = await sendMessageToUser(profiles[0], message);
          window.open(`/messaging/${channelId}`, '_blank');
        } else {
          for (let i = 0; i < profiles.length; i++) {
            await sendMessageToUser(profiles[i], message);
            // stagger message sending to prevent potential rate limiting
            // errors from the sendbird API
            await new Promise((r) => setTimeout(r, 300));
          }
          notify(`Message sent to ${profiles.length} experts.`);
        }
        onSend();
        onClose();
      } catch (err) {
        notify(`Message sending to one or more users failed.`, 'error');
        throw err;
      }
    },
    [profiles, expertRequestId]
  );

  const initialValues = useMemo(
    () => ({
      message: '',
    }),
    []
  );

  return (
    <Form
      validate={validate}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      component={SendMessageDialog}
      open={open}
      viewer={viewer}
      profiles={profiles}
      onClose={onClose}
      expertRequestId={expertRequestId}
    />
  );
}

SendMessageForm = connect(
  (state) => {
    return {
      viewer: state.viewer,
    };
  },
  {
    notify,
    renderTemplate,
    createChannel,
    fetchChannel,
    sendMessage,
  }
)(SendMessageForm);

function SendMessageDialog({
  // Props
  profiles,
  open,
  onClose,
  expertRequestId,
  viewer,

  // Final Form
  form,
  values,
  submitting,
  handleSubmit,

  // Redux Actions
  notify,
  renderTemplate,
}) {
  const [renderingExampleMessage, setRenderingExampleMessage] = useState(false);
  const [exampleMessage, setExampleMessage] = useState(null);
  const [exampleProfile, setExampleProfile] = useState(null);
  const [chooseTemplateDialogOpen, setChooseTemplateDialogOpen] =
    useState(false);
  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
  const [variablesDialogOpen, setVariablesDialogOpen] = useState(false);

  const handleProfileRemove = (profile) => {
    if (exampleProfile && profile.id === exampleProfile.id) {
      const newExample = profiles.find((p) => p.id !== profile.id);
      setExampleProfile(newExample);
    }
  };

  const handleSelectTemplate = async (template) => {
    if (template) {
      form.change('message', template.body);
    }
    setChooseTemplateDialogOpen(false);
  };

  useEffect(() => {
    renderExampleMessage();
  }, [
    expertRequestId,
    profiles,
    exampleProfile,
    values.message,
    previewDialogOpen,
  ]);

  const handleExampleClick = () => {
    setExampleProfile(profiles[0]);
    setPreviewDialogOpen(true);
  };

  const renderExampleMessage = () => {
    const profile = exampleProfile || profiles[0];
    const canRenderExampleMessage =
      previewDialogOpen && expertRequestId && values.message !== '';

    if (!canRenderExampleMessage) {
      return;
    }

    setExampleProfile(profile);
    setRenderingExampleMessage(true);
    renderTemplate(values.message, {
      senderId: viewer.id,
      requestId: expertRequestId,
      profileId: profile.id,
    })
      .then((result) => {
        setRenderingExampleMessage(false);
        setExampleMessage(result);
      })
      .catch(() => {
        notify('An error occurred when rendering the template.', 'error');
        setRenderingExampleMessage(false);
        setExampleMessage(null);
      });
  };

  const title =
    profiles.length === 1
      ? `Send Message to ${profiles[0].first_name}`
      : 'Send Message to Multiple Experts';

  return (
    <Dialog
      useForm
      user={profiles.length === 1 ? profiles[0] : undefined}
      open={open}
      confirmLabel="Send"
      title={title}
      onSubmit={handleSubmit}
      onReset={() => form.reset()}
      onClose={onClose}
      onCancel={onClose}
      disableSubmit={submitting}
      submitting={submitting}
    >
      {profiles.length > 1 && (
        <FieldContainer
          label="Experts to message"
          className={s.expertsToAdd}
          containerClassName={s.expertsToAddContainer}
        >
          {profiles.map((p) => (
            <CandidateChip
              key={p.id}
              profile={p}
              onRequestDelete={() => handleProfileRemove(p)}
            />
          ))}
        </FieldContainer>
      )}

      <div className={s.messageField}>
        <Link onClick={() => setChooseTemplateDialogOpen(true)}>
          Select From Template
        </Link>
      </div>
      <MessageTemplatesDialog
        open={chooseTemplateDialogOpen}
        onClose={() => setChooseTemplateDialogOpen(false)}
        onSelectTemplate={handleSelectTemplate}
      />
      <div className={s.messageField}>
        <Link onClick={() => setVariablesDialogOpen(true)}>
          View Available Template Variables
        </Link>
      </div>
      <MessageTemplateVariablesDialog
        open={variablesDialogOpen}
        title="Available Template Variables"
        onClose={() => setVariablesDialogOpen(false)}
      />
      <FieldContainer className={s.messageField} label="Message">
        <Field
          component={TextField}
          name="message"
          InputProps={{ disableUnderline: true }}
          fullWidth
          multiline
          rows={10}
          maxRows={10}
        />
      </FieldContainer>
      {profiles.length > 0 && (
        <div className={s.messageField}>
          <Link onClick={handleExampleClick}>Show Message Example</Link>
        </div>
      )}
      <Dialog
        open={previewDialogOpen}
        title="Preview"
        onClose={() => setPreviewDialogOpen(false)}
        onCancel={() => setPreviewDialogOpen(false)}
      >
        <MessagePreview
          loading={renderingExampleMessage}
          message={exampleMessage}
        />
      </Dialog>
    </Dialog>
  );
}

SendMessageDialog = connect(undefined, {
  notify,
  renderTemplate,
})(SendMessageDialog);

SendMessageDialog = SendMessageDialog;

export default SendMessageForm;

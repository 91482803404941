import React, { useCallback } from 'react';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Button from '../../Button/Button';
import { setPassword, resetPassword } from '../../../actions/auth';
import { notify } from '../../../actions/ui';
import { isValidPassword } from '../../../core/auth';
import { PasswordText } from '../../FormAdapters/FormAdapters';

const ERROR_INCORRECT_PASSWORD =
  'GraphQL Error: current_password:current password is incorrect';

const ERROR_USED_PASSWORD =
  'GraphQL Error: new_password:cannot use the same password';

const useStyles = makeStyles({
  form: {
    maxWidth: 350,
  },
  actions: {
    marginTop: 25,
  },
});

function ChangePassword({
  token,
  hasPassword,
  userId,
  setPassword,
  resetPassword,
  onSubmit,
  successNotification,
  notify,
}) {
  const s = useStyles();

  const handleSubmit = useCallback(async (values) => {
    try {
      if (token) {
        await resetPassword(token, values.newPassword);
      } else {
        await setPassword(userId, values.currentPassword, values.newPassword);
      }

      if (successNotification) notify('Password updated.');

      if (onSubmit) onSubmit(values);
    } catch (e) {
      if (e.message === ERROR_INCORRECT_PASSWORD) {
        return { currentPassword: 'Current password is incorrect.' };
      }

      if (e.message === ERROR_USED_PASSWORD) {
        return { newPassword: 'Cannot reuse the last 5 passwords.' };
      }

      if (e.isPermissionError) {
        notify('Reset password link has expired.', 'error');
      } else {
        notify('An error occurred when trying to set the password.', 'error');
      }
    }
  }, []);

  const validate = useCallback((values) => {
    const errors = {};

    if (hasPassword && !values.currentPassword) {
      errors.currentPassword = 'Required';
    }

    if (!isValidPassword(values.newPassword)) {
      errors.newPassword = 'Password does not meet the requirements';
    }

    if (values.newPassword && values.newPassword === values.currentPassword) {
      errors.newPassword = 'New password must be different from the current';
    }

    return errors;
  }, []);

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      subscription={{ submitting: true }}
    >
      {({ handleSubmit, form }) => {
        const onSubmit = async (e) => {
          e.preventDefault();
          const error = await handleSubmit(e);
          if (error || form.getState().hasValidationErrors) return error;
          form.restart();
        };

        return (
          <form className={s.form} onSubmit={onSubmit}>
            {hasPassword && (
              <Field
                id="currentPassword"
                component={PasswordText}
                name="currentPassword"
                label="Current Password"
                variant="outlined"
              />
            )}

            <Field
              id="newPassword"
              component={PasswordText}
              name="newPassword"
              label="New Password"
              showHelp
              variant="outlined"
              inputProps={{ autoComplete: 'new-password' }}
            />

            <div className={s.actions}>
              <Button
                type="submit"
                classes={{ root: s.button }}
                size="medium"
                id="updatePasswordButton"
              >
                Update Password
              </Button>
            </div>
          </form>
        );
      }}
    </Form>
  );
}

ChangePassword = connect(
  (state, ownProps) => {
    const user = state.users[ownProps.userId] || {};
    return {
      user,
      hasPassword: user.has_password,
    };
  },
  {
    setPassword,
    resetPassword,
    notify,
  }
)(ChangePassword);

ChangePassword.defaultProps = {
  successNotification: true,
};

export default ChangePassword;

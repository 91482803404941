import React from 'react';
import { fetchUser } from '../../actions/user';
import { fetchBlogPostBySlug } from '../../actions/blog';
import { fetchCaseStudy } from '../../actions/caseStudy';
import Browse from './Browse';

const expertsPerSection = 4;

// TODO: use iterators, variable length arrays
function zip(...arrays) {
  const results = [];
  for (let i = 0; i < arrays[0].length; i++) {
    results.push(arrays.map((a) => a[i]));
  }
  return results;
}

function flatten(list) {
  return list.reduce((a, b) => a.concat(Array.isArray(b) ? flatten(b) : b), []);
}

export default {
  path: /^\/[a-z-]+/,

  async action({ path, store }) {
    const [data, bgImage] = await new Promise((resolve, reject) => {
      require.ensure(
        [],
        (require) => {
          let yaml;

          try {
            yaml = require(`./pages${path}.yml`);
          } catch (e) {
            if (e.message.startsWith('Cannot find module')) {
              return resolve([null, null]);
            }
            return reject(e);
          }

          try {
            resolve([yaml, require(`./pages${path}_bg.jpg`)]);
          } catch (e) {
            reject(e);
          }
        },
        'browse'
      );
    });

    // KT: On second require webpack caches data in node. Smells like a bug
    if (!data || Object.keys(data).length === 0) {
      return undefined;
    }

    const expertIds = flatten(
      data.sections.map((s) => s.expertIds.slice(0, expertsPerSection))
    );
    const experts = await Promise.all(
      expertIds.map((id) =>
        store.dispatch(fetchUser(id, { experiences: true }))
      )
    );

    const expertMap = new Map(zip(expertIds, experts));
    const getExpert = expertMap.get.bind(expertMap);
    data.sections.forEach((section) => {
      section.experts = section.expertIds.map(getExpert).filter(Boolean);
    });

    const blogPosts = await Promise.all(
      data.blogIds
        .slice(0, 2)
        .map((slug) => store.dispatch(fetchBlogPostBySlug(slug)))
    );

    const caseStudies = await Promise.all(
      data.caseStudyIds
        .slice(0, 3)
        .map((slug) => store.dispatch(fetchCaseStudy(slug)))
    );

    return {
      title: `${data.title} Experts at OnFrontiers`,
      meta: {
        imageUrl: bgImage,
        description: data.description,
      },
      component: (
        <Browse
          icon={data.icon}
          title={data.title}
          description={data.description}
          imgSrc={bgImage}
          industry={data.industry}
          sections={data.sections}
          blogPosts={blogPosts.filter(Boolean)}
          caseStudies={caseStudies.filter(Boolean)}
        />
      ),
    };
  },
};

import ActionTypes from '../core/ActionTypes';

const initialState = {
  accounts: {},
  prices: [],
};

export default function billingReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.BILLING__FETCH_ACCOUNT:
      return {
        ...state,
        accounts: {
          ...state.accounts,
          [action.billingAccount.id]: action.billingAccount,
        },
      };
    case ActionTypes.BILLING__FETCH_PRICES:
      return { ...state, prices: action.prices };
    default:
      return state;
  }
}

import React from 'react';
import LayoutPage from '../../components/Layout/LayoutPage';
import DomainSelector from './DomainSelector';

export default {
  path: '/select_domain',
  async action() {
    return {
      title: 'Select team',
      component: (
        <LayoutPage headerBorder={false}>
          <DomainSelector />
        </LayoutPage>
      ),
    };
  },
};

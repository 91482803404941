import React from 'react';
import cx from 'classnames';
import s from './Article.module.scss';
import { safeHtml } from '../../core/util';

function Article({ className, html, ...other }) {
  return (
    <article
      className={cx(s.article, className)}
      dangerouslySetInnerHTML={{ __html: safeHtml(html) }}
      {...other}
    />
  );
}

export default Article;

import React, { Component } from 'react';
import { IconButton } from '@mui/material';
import MaterialIcon from '../Icon/MaterialIcon';
import CircularProgress from '../CircularProgress/CircularProgress';
import { teal500 } from '../../core/colors';
import s from './AudioPlayer.module.scss';

class AudioPlayer extends Component {
  componentDidMount() {
    this.drawAudio();
  }

  componentWillUnmount() {
    this.pause();
  }

  state = {
    playerState: undefined,
  };

  play = () => {
    this.wavesurfer.play();
    this.setState({ playerState: 'playing' });
  };

  pause = () => {
    this.wavesurfer.pause();
    this.setState({ playerState: 'paused' });
  };

  drawAudio = async () => {
    const { recordingUrl } = this.props;

    this.setState({ playerState: 'loading' });

    const WaveSurfer = (
      await import(
        /* webpackChunkName: "wavesurfer" */
        'wavesurfer.js'
      )
    ).default;

    this.wavesurfer = WaveSurfer.create({
      container: '#waveform',
      waveColor: teal500,
      progressColor: teal500,
      // barWidth: 1,
      // barHeight: 1,
    });

    this.wavesurfer.on('ready', () => {
      this.setState({ playerState: 'paused' });
    });

    this.wavesurfer.on('error', () => {
      this.setState({ playerState: 'error' });
    });

    this.wavesurfer.load(recordingUrl);
  };

  render() {
    const { playerState } = this.state;
    return (
      <div>
        <h3>Recording</h3>

        {playerState !== 'error' && <div id="waveform" />}

        <PlayerControls
          playerState={playerState}
          onPlay={this.play}
          onPause={this.pause}
        />
      </div>
    );
  }
}

function PlayerControls({ playerState, onPlay, onPause }) {
  return (
    <div className={s.playerControls}>
      {playerState === 'error' && 'Recording could not be loaded.'}

      {playerState === 'loading' && <CircularProgress />}

      {playerState === 'playing' && (
        <IconButton style={{ width: 'auto', height: 'auto' }} onClick={onPause}>
          <MaterialIcon icon="pause_circle_outline" color={teal500} size={44} />
        </IconButton>
      )}
      {playerState === 'paused' && (
        <IconButton style={{ width: 'auto', height: 'auto' }} onClick={onPlay}>
          <MaterialIcon icon="play_circle_outline" color={teal500} size={44} />
        </IconButton>
      )}
    </div>
  );
}

AudioPlayer = AudioPlayer;

export default AudioPlayer;

import React, { useState, memo, useEffect, useCallback } from 'react';
import TextField from '@mui/material/TextField';
import { connect } from 'react-redux';
import TeamPreview from '../../components/TeamPreview';
import LongList from '../../components/LongList';
import { fetchGroups } from '../../actions/group';
import { debounce, normalizeSpace } from '../../core/util';

const TeamList = memo(({ fetchGroups, viewer, pageInfo, edges, loading }) => {
  const [name, setName] = useState('');
  const [enableSearch, setEnableSearch] = useState(false);

  useEffect(() => {
    if (!enableSearch) return;
    handleSearch(name);
  }, [name]);

  useEffect(() => {
    if (enableSearch) return;

    setEnableSearch(!!edges.length && edges.length > 5);
  }, [edges.length]);

  const filterGroups = useCallback(
    ({ force, cursor, name }) => {
      fetchGroups({
        force,
        collection: 'default',
        cursor,
        memberOnly: !viewer.admin,
        memberLimit: 5,
        billingAccount: true,
        stats: true,
        name,
      });
    },
    [viewer]
  );

  const handleSearch = useCallback(
    debounce((name) => {
      filterGroups({
        force: true,
        name,
      });
    }, 500),
    [filterGroups]
  );

  const handleMore = (e) => {
    e.preventDefault();
    if (!pageInfo.hasNextPage) return;

    if (!edges.length) throw new Error('last group edge not found');

    filterGroups({
      cursor: edges[edges.length - 1].cursor,
      name,
    });
  };

  return (
    <div>
      <LongList
        loading={loading}
        title="Teams"
        hasNextPage={pageInfo.hasNextPage}
        onMore={handleMore}
        action={
          enableSearch && (
            <TextField
              value={name}
              fullWidth={false}
              onChange={({ target: { value } }) =>
                setName(normalizeSpace(value))
              }
              placeholder="Filter by name"
            />
          )
        }
      >
        {edges.map((g) => (
          <TeamPreview key={g.node.id} team={g.node} />
        ))}
      </LongList>
    </div>
  );
});

export default connect(
  (state) => {
    const page = state.groups.default;
    return {
      ...page,
      viewer: state.viewer,
    };
  },
  {
    fetchGroups,
  }
)(TeamList);

import React from 'react';
import PropTypes from 'prop-types';

import Register from '../../components/Register';
import s from './Account.module.scss';

function Account(props) {
  return (
    <div className={s.root}>
      <h2>Ready to find your experts?</h2>
      <Register nextUrl={props.nextUrl} />
    </div>
  );
}

Account.propTypes = {
  nextUrl: PropTypes.string,
};

const Component = Account;

Component.title = 'Register';

export default Component;

import React, { PureComponent } from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Picture from '../Picture';
import Link from '../Link';
import { red500, lightTan } from '../../core/colors';
import s from './ProjectMember.module.scss';
import { notify } from '../../actions/ui';
import { inviteUser } from '../../actions/invitation';
import { Select } from '../Select/Select';
import FAIcon from '../Icon/FAIcon';

const roleText = {
  manager: 'Research Manager',
  associate: 'Recruiting Associate',
  owner: 'Owner',
  member: 'Member',
};

function rolesFor(role) {
  if (!role) return [];
  switch (role) {
    case 'super-user':
    case 'manager':
    case 'associate':
      return ['manager', 'associate', 'owner', 'member'];
    case 'owner':
      return ['owner', 'member'];
    case 'member':
      return ['member'];
    default:
      throw new Error(`unsupported role ${role}`);
  }
}

function isInternal(role) {
  return role === 'manager' || role === 'associate';
}

const inviteButtonStyle = {
  style: {
    lineHeight: '16px',
    height: '16px',
    minWidth: 0,
    textTransform: 'capitalize',
    paddingLeft: 0,
    paddingRight: 0,
  },
};

class ProjectMember extends PureComponent {
  state = {
    resendInviteAvailable: true,
  };

  handleRemoveClick = () => {
    const { removeMember, groupId, member } = this.props;
    removeMember(groupId, member.id);
  };

  handleRoleChange = ({ target: { value: role } }) => {
    const { updateMember, groupId, member } = this.props;

    updateMember(groupId, member.id, { role, state: 'active' });
  };

  handleReinvite = () => {
    const { member, inviteUser, projectId, notify } = this.props;
    if (member.new) {
      throw new Error('cannot re-invite members not yet added');
    }
    this.setState({ sendingInvite: true });
    return inviteUser(
      {
        collectionType: 'project',
        collectionId: projectId,
        email: member.email,
        role: member.role,
      },
      { reinvite: true }
    ).then(
      () => {
        this.setState({ sendingInvite: false, resendInviteAvailable: false });
        notify('Invitation sent.');
      },
      () => {
        this.setState({ sendingInvite: false, resendInviteAvailable: false });
        notify('Unable to resend invite', 'error');
      }
    );
  };

  render() {
    const { viewerRole, member, key } = this.props;
    const { email, user, role, state } = member;
    const { html_url: htmlUrl, name } = user || {};

    const isInvitation = user == null;
    const isInvitationSent = isInvitation && !member.new;
    const isDenied = state === 'denied';

    const { sendingInvite, resendInviteAvailable } = this.state;

    const roles = rolesFor(viewerRole);
    const canRemove = roles.includes(role);

    let roleOptions = [];
    if (isDenied) {
      roleOptions.push({ value: 'denied', label: 'Denied' });
    }
    roleOptions.push({ value: role, label: roleText[role] });
    roleOptions = [
      ...roleOptions,
      ...roles
        .filter((r) => r !== role)
        .map((r) => ({ value: r, label: roleText[r] })),
    ];

    return (
      <div key={key} className={cx(s.root, { [s.denied]: isDenied })}>
        <Picture user={user} ring={isInternal(role)} size={40} />
        <div className={s.body}>
          <div className={s.header}>
            {isInvitation ? (
              <div>
                <h3 className={s.name}>{email}</h3>
                {isInvitationSent && (
                  <div className={s.invited}>
                    Invite Sent.{' '}
                    {resendInviteAvailable && (
                      <Button
                        secondary
                        label={sendingInvite ? 'Sending...' : 'Resend'}
                        disabled={sendingInvite}
                        {...inviteButtonStyle}
                        onClick={this.handleReinvite}
                      />
                    )}
                  </div>
                )}
              </div>
            ) : (
              <Link to={htmlUrl}>
                <h3 className={s.name}>{name}</h3>
              </Link>
            )}
          </div>
        </div>
        <div className={s.actions}>
          {roles.length > 1 ? (
            <Select
              sort={false}
              margin="dense"
              value={isDenied ? state : role}
              style={{ width: 200 }}
              onChange={this.handleRoleChange}
              options={roleOptions}
            />
          ) : (
            <div className={s.unchangeableRole}>
              {isDenied ? 'Member denied' : roleText[role]}
            </div>
          )}
          <IconButton
            style={{ marginRight: -12 }}
            disabled={!canRemove}
            onClick={this.handleRemoveClick}
          >
            <FAIcon
              icon="trash-o"
              style={{
                color: canRemove ? red500 : lightTan,
                fontSize: 20,
              }}
            />
          </IconButton>
        </div>
      </div>
    );
  }
}

ProjectMember = connect(
  (state) => ({
    viewer: state.viewer,
  }),
  {
    notify,
    inviteUser,
  }
)(ProjectMember);

export default ProjectMember;

import React from 'react';
import Message from '../Message';
import LongText from '../LongText';
import UserRef from './UserRef';
import RequestRef from './RequestRef';
import { capitalize } from '../../core/util';
import s from './ActivityLog.module.scss';

function CandidateActivity({
  activity: {
    action,
    user,
    context: {
      expert,
      client,
      research_mananager: researchManager,
      group,
      expert_request: expertRequest,
      raw: {
        candidate_state: state,
        actor_role: role,
        client_note: { note: clientNote, good_match: goodMatch } = {},
        expert_note: { note: expertNote } = {},
        rm_note: { note: rmNote } = {},
      },
    } = {},
    loading,
  },
}) {
  const title = (() => {
    // expert added himself
    // assume expressing interest in the expert request
    if (action === 'candidate_add' && role === 'candidate') {
      return (
        <span>
          <UserRef user={user} /> responded as &quot;Interested&quot;{' '}
          <RequestRef
            expertRequest={expertRequest}
            group={group}
            loading={loading}
          />
        </span>
      );
    }

    // expert updated himself
    // assume he updated his answers
    if (action === 'candidate_update' && role === 'candidate') {
      return (
        <span>
          <UserRef user={user} /> set experience{' '}
          <RequestRef
            expertRequest={expertRequest}
            group={group}
            loading={loading}
          />
        </span>
      );
    }

    // client updated candidate and provided a good_match response
    // assume client answered to good match question
    if (action === 'candidate_update' && role === 'client' && goodMatch) {
      return (
        <span>
          <UserRef user={user} link /> responded &quot;{capitalize(goodMatch)}
          &quot; to <UserRef user={expert} />{' '}
          <RequestRef
            expertRequest={expertRequest}
            group={group}
            loading={loading}
          />
        </span>
      );
    }

    // client updated candidate and didn't provide a good_match response
    // assume client only clicked on a Yes/No/Maybe so far
    if (action === 'candidate_update' && role === 'client' && !goodMatch) {
      return (
        <span>
          <UserRef user={user} link /> moved <UserRef user={expert} /> to &quot;
          {capitalize(state)}&quot;{' '}
          <RequestRef
            expertRequest={expertRequest}
            group={group}
            loading={loading}
          />
        </span>
      );
    }

    // rm updated candidate and define state
    // assume state got changed
    if (action === 'candidate_update' && role === 'rm' && state) {
      return (
        <span>
          <UserRef user={user} link /> moved <UserRef user={expert} /> to &quot;
          {capitalize(state)}&quot;{' '}
          <RequestRef
            expertRequest={expertRequest}
            group={group}
            loading={loading}
          />
        </span>
      );
    }

    // no case found, fallback to generic message
    return (
      <span>
        <UserRef user={user} link />{' '}
        {action === 'candidate_add' ? 'added' : 'updated'} candidate{' '}
        <UserRef user={expert} />{' '}
        <RequestRef
          expertRequest={expertRequest}
          group={group}
          loading={loading}
        />
      </span>
    );
  })();
  return (
    <div>
      <div>{title}</div>
      {clientNote && client && (
        <Message className={s.message} bubbleClassName={s.bubble} user={client}>
          <LongText text={clientNote} />
        </Message>
      )}
      {expertNote && expert && (
        <Message className={s.message} bubbleClassName={s.bubble} user={expert}>
          <LongText text={expertNote} />
        </Message>
      )}
      {rmNote && researchManager && (
        <Message
          className={s.message}
          bubbleClassName={s.bubble}
          user={researchManager}
        >
          <LongText text={rmNote} />
        </Message>
      )}
    </div>
  );
}

CandidateActivity = CandidateActivity;

export default CandidateActivity;

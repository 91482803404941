import React from 'react';
import UserRef from './UserRef';

function ExpertRequestActivity({ activity }) {
  const { user, action } = activity;

  const actionText = action === 'open' ? 'reopened' : action;

  return (
    <div>
      <UserRef user={user} link /> {actionText} this expert request.
    </div>
  );
}

ExpertRequestActivity.fetchActions = () => [];

export default ExpertRequestActivity;

import React from 'react';
import { Field } from 'redux-form';
import cx from 'classnames';
import { TextField } from '../FormAdapters/FormAdapters';
import AddButton from '../Forms/Profile/buttons/AddButton';
import RemoveButton from '../Forms/Profile/buttons/RemoveButton';
import s from './EditableList.module.scss';

function Input({
  showRemove,
  name,
  index,
  onAdd,
  onRemove,
  inputProps,
  inputComponent,
  autoFocus,
  className,
  removeClassName,
  field,
  value,
}) {
  const InputComponent = inputComponent;
  const FieldComponent = field || Field;

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onAdd();
    }
  };

  return (
    <div className={cx(s.container, className)}>
      <div className={cx(s.removeAction, removeClassName)}>
        {showRemove && (
          <RemoveButton className={s.removeButton} onClick={onRemove} />
        )}
      </div>

      {InputComponent ? (
        <InputComponent
          name={name}
          index={index}
          onAdd={onAdd}
          value={value}
          {...inputProps}
        />
      ) : (
        <FieldComponent
          id={name}
          name={name}
          index={index}
          component={TextField}
          className={s.input}
          autoFocus={autoFocus}
          onKeyPress={handleKeyPress}
          {...inputProps}
        />
      )}
    </div>
  );
}

function EditableList({
  fields,
  inputProps,
  minLength = 0,
  label,
  addButtonLabel,
  multi,
  inputComponent,
  style,
  className,
  itemClassName,
  removeClassName,
  disabled,
  showRemoveForField = () => true,
}) {
  // patch for react-final-form
  if (fields.get === undefined) {
    fields.get = (index) => fields.value[index];
  }
  if (fields.getAll === undefined) {
    fields.getAll = () => fields.value;
  }

  const handleAdd = () => {
    // fields.getAll() doesn't update properly
    const values = Array.from({ length: fields.length }, (v, i) =>
      fields.get(i)
    );
    const hasEmptyValue = values.some((x) =>
      multi ? !Object.keys(x).length : !x
    );
    if (!hasEmptyValue) {
      fields.push(multi ? {} : '');
      // https://github.com/callemall/material-ui/issues/5793
      setTimeout(() => {
        if (Event.prototype.initEvent) {
          const evt = window.document.createEvent('UIEvents');
          evt.initUIEvent('resize', true, false, window, 0);
          window.dispatchEvent(evt);
        } else {
          window.dispatchEvent(new Event('resize'));
        }
      }, 0);
    }
  };

  const showRemove = fields.length > minLength;

  const inputPropsFunction =
    typeof inputProps === 'function' ? inputProps : () => inputProps;

  return (
    <>
      <div style={style} className={className}>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        {label && <label className={s.label}>{label}</label>}

        {fields.map((name, index) => (
          <Input
            key={name}
            inputComponent={inputComponent}
            name={name}
            index={index}
            inputProps={inputPropsFunction(
              fields.get(index),
              index,
              fields.getAll()
            )}
            onAdd={handleAdd}
            onRemove={() => fields.remove(index)}
            autoFocus={index === fields.length - 1}
            showRemove={
              !disabled && showRemove && showRemoveForField(fields.get(index))
            }
            className={itemClassName}
            removeClassName={removeClassName}
            value={fields.get(index)}
          />
        ))}
      </div>
      {!disabled && (
        <div className={s.addAction}>
          <AddButton label={addButtonLabel} onClick={handleAdd} />
        </div>
      )}
    </>
  );
}

export default EditableList;

import React from 'react';

import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { addAttachments } from '../../../../actions/consultation';
import { notify } from '../../../../actions/ui';
import Attachments from '../../../../components/Forms/ExpertRequest/Attachments';
import Button from '../../../../components/Button';
import s from './SubmitAttachments.module.scss';

const maxAttachmentFiles = 5;
const maxAttachmentSize = 10;
const attachmentsAddStyle = { textAlign: 'right', marginRight: 40 };

class SubmitAttachments extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      allowSubmit: false,
    };
  }

  handleAddFiles = () => {
    this.setState({ allowSubmit: true });
  };

  handleSubmitFiles = async (values) => {
    const { attachments } = values;
    const { addAttachments, consultation, notify } = this.props;

    try {
      await addAttachments({
        consultation,
        attachments: attachments.filter((a) => !a.id),
      });
      notify('Successfully submitted written response files.', 'success');
    } catch (ex) {
      notify('Error submitting written response files.', 'error');
      throw ex;
    }

    this.setState({ allowSubmit: false });
  };

  render() {
    const { isViewerExpert, consultation, isMobileVersion } = this.props;

    const canUpload = isViewerExpert;

    const title = isViewerExpert
      ? 'File(s) uploaded for written response to client'
      : consultation.attachments.length
        ? 'File(s) uploaded by expert for written response'
        : 'No files have been uploaded by expert';

    return (
      <Form
        onSubmit={this.handleSubmitFiles}
        mutators={{
          ...arrayMutators,
        }}
        initialValues={consultation}
      >
        {({ handleSubmit }) => (
          <div className={s.attachmentSection}>
            <div className={s.attachmentHeader}>
              <p className={s.attachmentTitle}> {title}</p>
              {canUpload && (
                <p className={s.attachmentSubtitle}>
                  {`Upload files below (File limit: ${maxAttachmentFiles}, File size limit: ${maxAttachmentSize}MB)`}
                </p>
              )}
            </div>
            <Attachments
              name="attachments"
              disabled={!canUpload}
              label="Upload Files"
              addButtonLabel="Files"
              isMobileVersion={isMobileVersion}
              maxAttachmentFiles={maxAttachmentFiles}
              maxAttachmentSize={maxAttachmentSize}
              onAddedFiles={this.handleAddFiles}
              addActionStyle={attachmentsAddStyle}
            />
            {this.state.allowSubmit && (
              <div className={s.submitButton}>
                <Button onClick={handleSubmit} color="teal" size="large">
                  Submit Files
                </Button>
              </div>
            )}
          </div>
        )}
      </Form>
    );
  }
}

SubmitAttachments = connect(undefined, {
  notify,
  addAttachments,
})(SubmitAttachments);

export default SubmitAttachments;

class PermissionService {
  constructor(userId, store) {
    this._userId = userId;
    this._store = store;
  }

  allowed(service, action, resource) {
    const userId = this.userId();

    const key = renderPermKey(service, action, resource);
    const cache = (this._store.getState().permissions || {})[userId];
    const cached = cache && cache[key];
    return cached ? cached.allowed : false;
  }

  forUser(userId) {
    return new PermissionService(userId, this._store);
  }

  userId() {
    if (this._userId) return this._userId;
    const { viewer } = this._store.getState();
    return viewer && viewer.id;
  }
}

export default function createPermissionService(store) {
  return new PermissionService(null, store);
}

export function renderPermKey(service, action, resource) {
  return `${service}:${action}:${resource}`;
}

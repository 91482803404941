import React from 'react';
import { connect } from 'react-redux';
import FAIcon from '../Icon/FAIcon';
import Button from '../Button';
import AddToExpertRequestDialog from './AddToExpertRequestDialog';
import { fetchExpertRequests } from '../../actions/expertRequest';

class AddToExpertRequestButton extends React.Component {
  static defaultProps = {
    icon: true,
  };

  state = {
    dialogOpened: false,
  };

  handleOpen = () => {
    const { fetchExpertRequests } = this.props;

    fetchExpertRequests({ state: 'open' }).then(() =>
      this.setState({ dialogOpened: true })
    );
  };

  handleClose = () => this.setState({ dialogOpened: false });

  render() {
    const {
      expertRequests,
      profile,
      dispatch,
      label = 'Add to Request',
      disabled,
      icon,
      onExpertAdd,
      form,
      fetchExpertRequests,
      ...other
    } = this.props;

    const profiles = profile ? [profile] : this.props.profiles || [];
    if (profiles.length === 0) return null;

    return (
      <React.Fragment>
        <Button
          variant="contained"
          color="lightTan"
          size="small"
          {...other}
          startIcon={icon && <FAIcon size={14} icon="plus" />}
          onClick={this.handleOpen}
        >
          {label}
        </Button>
        {!disabled && (
          <AddToExpertRequestDialog
            open={this.state.dialogOpened}
            profiles={profiles}
            onClose={this.handleClose}
            onExpertAdd={onExpertAdd}
            form={form}
          />
        )}
      </React.Fragment>
    );
  }
}

AddToExpertRequestButton = connect(undefined, {
  fetchExpertRequests,
})(AddToExpertRequestButton);

export default AddToExpertRequestButton;

import { createTheme } from '@mui/material/styles';
import createCache from '@emotion/cache';
import * as colors from './colors';

const isBrowser = typeof document !== 'undefined';

// On the client side, Create a meta tag at the top of the <head> and set it as insertionPoint.
// This assures that Material UI styles are loaded first.
// It allows developers to easily override Material UI styles with other styling solutions, like CSS modules.
export const cacheEmotionKey = 'mui-style';
export function createEmotionCache() {
  let insertionPoint;

  if (isBrowser) {
    const emotionInsertionPoint = document.querySelector(
      'meta[name="emotion-insertion-point"]'
    );
    insertionPoint = emotionInsertionPoint ?? undefined;
  }

  return createCache({
    key: cacheEmotionKey,
    insertionPoint,
    prepend: true,
  });
}

export function createV5Theme(props = {}) {
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 480,
        md: 768,
        lg: 992,
        xl: 1200,
      },
    },
    typography: {
      allVariants: {
        fontFamily: 'Campton, sans-serif',
      },
    },
    palette: {
      primary: {
        main: colors.darkBlue,
      },
      secondary: {
        main: colors.darkGreen,
      },
      error: {
        main: colors.red500,
      },
      warning: {
        main: colors.orange500,
      },
      info: {
        main: colors.darkGray,
      },
      success: {
        main: colors.teal500,
      },
    },
    components: {
      MuiTabs: {
        styleOverrides: {
          root: {
            borderBottom: `1px solid ${colors.borderColor}`,
          },
          indicator: {
            backgroundColor: colors.darkGreen,
            height: 5,
          },
        },
      },
      MuiTab: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            minWidth: 'initial',
            textTransform: 'none',
          },
        },
      },
      MuiIconButton: {
        defaultProps: {
          size: 'large',
        },
      },
      MuiInput: {
        styleOverrides: {
          underline: {
            '&:before': {
              borderBottomColor: colors.borderColor,
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            backgroundColor: colors.lightTan,
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: 'standard', // https://mui.com/material-ui/migration/migration-v4/#variant-prop
          margin: 'normal',
          fullWidth: true,
        },
      },
      MuiFormControl: {
        defaultProps: {
          margin: 'normal',
          fullWidth: true,
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            '&:disabled': {
              opacity: 0.5,
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            color: colors.darkGray,
            fontSize: 12,
          },
          head: {
            fontWeight: 400,
          },
        },
      },
      MuiFab: {
        styleOverrides: {
          root: {
            color: colors.white,
            backgroundColor: colors.teal500,
            '&:hover': {
              backgroundColor: colors.teal500,
              opacity: 0.6,
            },
          },
        },
      },
      MuiSelect: {
        defaultProps: {
          variant: 'standard',
        },
      },
      MuiSlider: {
        styleOverrides: {
          thumb: {
            color: colors.teal500,
            '&$disabled': {
              color: colors.darkGray,
            },
          },
          track: {
            color: colors.teal500,
          },
        },
      },
      MuiCircularProgress: {
        styleOverrides: {
          root: {
            color: colors.darkGreen,
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            color: colors.darkBrown,
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: colors.darkBrown,
            paddingTop: 12,
            paddingBottom: 12,
          },
        },
      },
      MuiPickersToolbar: {
        styleOverrides: {
          toolbar: {
            backgroundColor: colors.blue,
          },
        },
      },
      MuiPickersYear: {
        styleOverrides: {
          yearSelected: {
            color: colors.teal500,
          },
        },
      },
      MuiPickersMonth: {
        styleOverrides: {
          monthSelected: {
            color: colors.teal500,
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          daySelected: {
            backgroundColor: colors.darkGreen,
          },
        },
      },
      MuiPickersModal: {
        styleOverrides: {
          dialogAction: {
            color: colors.teal500,
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            color: colors.teal500,
          },
        },
      },
      MuiSnackbar: {
        defaultProps: {
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
        },
      },
      MuiCheckbox: {
        defaultProps: {
          color: 'secondary',
        },
      },
      MuiRadio: {
        defaultProps: {
          color: 'secondary',
        },
      },
      MuiPickersDay: {
        defaultProps: {
          sx: {
            '&.MuiPickersDay-root.Mui-selected': {
              backgroundColor: colors.darkGreen,
              '&:hover': {
                backgroundColor: colors.darkBlue,
              },
            },
            '&.MuiPickersDay-today': {
              border: 'none',
            },
          },
        },
      },
      ...props,
    },
  });

  theme.components.MuiTab.styleOverrides.root[theme.breakpoints.up('md')] = {
    fontSize: '1rem',
    minWidth: 'initial',
  };

  return theme;
}

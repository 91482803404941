import React, { memo } from 'react';
import MarkdownIt from 'markdown-it';
import withStyles from '@mui/styles/withStyles';
import s from './Markdown.module.scss';

const md = new MarkdownIt();

type MarkdownProps = {
  body?: string;
};

const Markdown = memo(({ body }: MarkdownProps) => (
  <div
    className={s.root}
    dangerouslySetInnerHTML={{
      __html: md.render(body || ''),
    }}
  />
));

export default withStyles({
  root: {
    '& h2': {
      fontSize: '1.4em',
    },
    '& h3': {
      fontSize: '1.2em',
    },
  },
})(Markdown);

import React, { PureComponent } from 'react';

import s from './Emails.module.scss';
import EditEmails from '../../components/EditEmails/EditEmails';

class Emails extends PureComponent {
  render() {
    return (
      <div>
        <div className={s.subTitle}>
          Let us know how you would like to hear from us.
        </div>
        <EditEmails
          userId={this.props.userId}
          profileId={this.props.profileId}
          showSuccessMessage
        />
      </div>
    );
  }
}

export default Emails;

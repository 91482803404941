import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Body from '../../components/Body';

const getStyles = makeStyles((theme) => ({
  root: ({ noMobilePadding }) => ({
    paddingTop: 30,
    paddingBottom: 30,
    [theme.breakpoints.down('md')]: {
      paddingRight: noMobilePadding ? 0 : 15,
      paddingLeft: noMobilePadding ? 0 : 15,
    },
  }),
}));

export default ({ children, noMobilePadding, ...other }) => {
  const s = getStyles({ noMobilePadding });
  return (
    <Body className={s.root} {...other}>
      {children}
    </Body>
  );
};

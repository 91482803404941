import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Drawer } from '@mui/material';
import MaterialIcon from '../Icon/MaterialIcon';
import IconButton from '../IconButton';
import Logo from '../Logo';
import { SCREEN_MD } from '../../constants';
import { white } from '../../core/colors';
import s from './MobileNavigation.module.scss';

class MobileNavigation extends Component {
  static contextTypes = {
    capabilities: PropTypes.object,
  };

  static defaultProps = {
    drawerColor: white,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      width: props.guessedScreenWidth || this.getWidth(),
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  getWidth() {
    if (typeof window !== 'undefined') {
      return window.document.body.clientWidth;
    }

    const { screen } = this.context.capabilities;
    if (screen && screen.width) {
      return screen.width;
    }

    return SCREEN_MD + 1;
  }

  handleResize = () => {
    this.setState({ width: this.getWidth() });
  };

  render() {
    const {
      open,
      theme,
      onRequestClose,
      drawerColor,
      children,
      closeButtonColor,
    } = this.props;
    return (
      <Drawer
        open={open}
        PaperProps={{
          style: { background: drawerColor },
        }}
      >
        <div className={s.header}>
          <a href="/" style={{ lineHeight: 0 }}>
            <Logo dark={theme === 'light'} />
          </a>

          <IconButton onClick={onRequestClose}>
            <MaterialIcon icon="close" color={closeButtonColor} size={30} />
          </IconButton>
        </div>

        <div className={s.content} style={{ width: this.state.width - 30 }}>
          {children}
        </div>
      </Drawer>
    );
  }
}

MobileNavigation = connect((state) => ({
  guessedScreenWidth: state.ui.guessedScreenWidth,
}))(MobileNavigation);

export default MobileNavigation;

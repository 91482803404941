import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Layout from '../../components/MarketingLayout';
import Hero from '../../components/MarketingLayout/MarketingHero';
import CaseStudyList from '../../components/CaseStudyList';
import Body from '../../components/Body';
import Testimonial from '../../components/Testimonial';
import Divider from '../../components/Divider';
import Button from '../../components/Button';
import Link from '../../components/Link';
import FAIcon from '../../components/Icon/FAIcon';
import MarketingSection from '../../components/MarketingSection';
import BookCallCta from '../../components/BookCallCta';
import ExpertWidgetHorizontal from '../../components/ExpertWidgetHorizontal';
import { teal500, white, darkGreen, lightTan, black } from '../../core/colors';
import s from './Case.module.scss';

function Section({ title, children }) {
  return (
    <section className={s.section}>
      <div className={s.sectionTitle}>{title}</div>
      <div className={s.sectionContent}>{children}</div>
    </section>
  );
}

function TestimonialSection({ text, name, ...other }) {
  return (
    <Testimonial
      title={name}
      {...other}
      iconSize={60}
      iconColor={lightTan}
      quoteStyle={{ fontSize: 30, lineHeight: 1.15, color: black }}
      style={{ margin: '0 auto', padding: '0 0 60px' }}
    >
      {text}
    </Testimonial>
  );
}

class Case extends PureComponent {
  share = (link) => {
    const width = 670;
    const height = 440;
    const x = window.screen.width / 2 - width / 2;
    const y = window.screen.height / 2 - height / 2;
    window.open(
      link,
      '_blank',
      `width=${width},height=${height},left=${x},top=${y}`
    );
  };

  shareFacebook = () => {
    const { subtitle, id } = this.props;
    const link = `https://onfrontiers.com/case/${id}`;
    this.share(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        link
      )}&t=${encodeURIComponent(subtitle)}`
    );
  };

  shareTwitter = () => {
    const { subtitle, id } = this.props;
    const link = `https://onfrontiers.com/case/${id}`;
    this.share(
      `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        subtitle
      )}:%20${encodeURIComponent(link)}`
    );
  };

  shareLinkedIn = () => {
    const { subtitle, id } = this.props;
    const link = `https://onfrontiers.com/case/${id}`;
    this.share(
      `http://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
        link
      )}&title=${encodeURIComponent(subtitle)}`
    );
  };

  render() {
    const {
      subtitle,
      image,
      advisors,
      testimonials,
      customer,
      objectives,
      caseStudies,
      experts,
    } = this.props;

    return (
      <Layout>
        <Hero tint={darkGreen} backgroundImage={`/images/studies/${image}`}>
          <h3 className={s.subTitle}>Case Study</h3>
          <h1 className={s.title}>{subtitle}</h1>
        </Hero>

        <Body className={s.sections}>
          <Section title="About the Client">{customer}</Section>

          {objectives && <Divider spacing={0} />}

          {objectives && (
            <Section title="The Objectives">
              {objectives.map((o) => (
                <div key={o} className={s.objective}>
                  <FAIcon
                    iconSet="far"
                    icon="check-circle"
                    color={teal500}
                    style={{ marginRight: 10, marginTop: 5, flex: '0 0 auto' }}
                  />
                  <span>{o}</span>
                </div>
              ))}
            </Section>
          )}
        </Body>

        {experts && (
          <MarketingSection
            darkBlue
            padding={60}
            title="The Experts"
            titleStyle={{
              color: white,
              textAlign: 'center',
              fontSize: 30,
              fontWeight: 'bold',
            }}
          >
            <Grid container spacing={4}>
              {experts.map((e) => (
                <Grid container item md={12} lg>
                  <ExpertWidgetHorizontal
                    pictureSize={80}
                    profile={e.profile}
                  />
                </Grid>
              ))}
            </Grid>
          </MarketingSection>
        )}

        {advisors && (
          <Body className={s.advisors}>
            {advisors.map((advisor) => (
              <div key={advisor.image} className={s.advisor}>
                <div>
                  <img
                    className={s.advisorImage}
                    src={require(`./advisors/${advisor.image}`)}
                    alt={advisor.imageAlt}
                  />
                </div>
                <div className={s.advisorContent}>
                  <h4 className={s.advisorTitle}>{advisor.title}</h4>
                  <ul className={s.advisorPoints}>
                    {advisor.points.map((point) => (
                      <li key={point}>{point}</li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </Body>
        )}

        {testimonials && (
          <MarketingSection style={{ padding: '60px 0 0' }}>
            {testimonials.map((t) => (
              <TestimonialSection key={t.text} {...t} />
            ))}
          </MarketingSection>
        )}

        <Body>
          <Divider />

          <div className={s.share}>
            <Button
              style={{ width: 160, marginRight: 20 }}
              backgroundColor="#3b5998"
              fontColor={white}
              size="normal"
              onClick={this.shareFacebook}
              startIcon={
                <FAIcon
                  color="white"
                  style={{ verticalAlign: 'initial' }}
                  size={18}
                  icon="facebook"
                />
              }
            >
              Share
            </Button>
            <Button
              style={{ width: 160, marginRight: 20 }}
              backgroundColor="#007bb6"
              fontColor={white}
              size="normal"
              onClick={this.shareLinkedIn}
              startIcon={
                <FAIcon
                  color="white"
                  style={{ verticalAlign: 'initial' }}
                  size={18}
                  icon="linkedin"
                />
              }
            >
              Share
            </Button>
            <Button
              style={{ width: 160 }}
              backgroundColor="#1da1f2"
              fontColor={white}
              size="normal"
              onClick={this.shareTwitter}
              startIcon={
                <FAIcon
                  color="white"
                  style={{ verticalAlign: 'initial' }}
                  size={18}
                  icon="twitter"
                />
              }
            >
              Tweet
            </Button>
          </div>

          <Divider />
        </Body>

        <MarketingSection padding={50}>
          <CaseStudyList cases={caseStudies} />

          <div className={s.moreCases}>
            <Link href="/success-stories">
              <Button fontColor={teal500} variant="text">
                More Case Studies →
              </Button>
            </Link>
          </div>
        </MarketingSection>

        <Body>
          <Divider spacing={0} />
        </Body>

        <BookCallCta />
      </Layout>
    );
  }
}

Case.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  html: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.string,
  advisors: PropTypes.array,
  testimonials: PropTypes.array,
};

export default Case;

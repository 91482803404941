import React, { PureComponent } from 'react';
import Link from '../Link';
import Message from '../Message';
import LongText from '../LongText';
import UserRef from './UserRef';
import s from './ActivityLog.module.scss';

class NetworkAddActivity extends PureComponent {
  render() {
    const { profile } = this.props.activity.context;
    return (
      <div>
        <div>
          <UserRef user={profile} /> was added to {this.renderNetworkLink()}
          {this.renderAddMethod()}.
        </div>
        {this.renderNote()}
      </div>
    );
  }

  renderNetworkLink() {
    const { network } = this.props.activity.context;
    if (!network) return null;
    return (
      <span>
        <Link className={s.userName} to={`/search?networks[]=${network.id}`}>
          {network.name}&rsquo;s network
        </Link>
      </span>
    );
  }

  renderAddMethod() {
    const { add_method: addMethod } = this.props.activity.context;

    const addMethodTextFn = {
      consultation: () => 'by doing a consultation',
      invitation: () => <span>{this.renderCreatedBy()} by invitation</span>,
      manual: () => null,
      integration: () => null,
      domain_signup: () => 'by approving application to join',
      group_share: () => this.renderGroupShareAddMethod(),
    }[addMethod];
    if (!addMethodTextFn) return null;

    const addMethodText = addMethodTextFn();
    if (!addMethodText) return null;

    return <span>&nbsp;{addMethodText}</span>;
  }

  renderGroupShareAddMethod() {
    const { from_group: fromGroup } = this.props.activity.context;
    if (!fromGroup) return <span>by sharing from another group</span>;

    return (
      <span>
        {this.renderCreatedBy()} by sharing from&nbsp;
        <Link className={s.requestName} to={fromGroup.html_url}>
          {fromGroup.name}
        </Link>
        &rsquo;s network
      </span>
    );
  }

  renderCreatedBy() {
    const { created_by: createdBy } = this.props.activity.context;
    if (!createdBy) return null;
    return (
      <span>
        by <UserRef user={createdBy} />
      </span>
    );
  }

  renderNote() {
    const { created_by: noteAuthor, team_note: teamNote } =
      this.props.activity.context;
    if (!teamNote) return null;
    return (
      <Message
        className={s.message}
        bubbleClassName={s.bubble}
        user={noteAuthor}
      >
        <LongText text={teamNote} />
      </Message>
    );
  }
}

NetworkAddActivity = NetworkAddActivity;

export default NetworkAddActivity;

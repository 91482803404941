import React from 'react';
import { connect } from 'react-redux';
import { Button as MaterialButton } from '@mui/material';

import Dialog from '../../components/Dialog';
import Link from '../../components/Link';
import Button from '../../components/Button/Button';
import MaterialIcon from '../../components/Icon/MaterialIcon';
import Duration from '../../core/duration';
import {
  shouldAllowJoinCall,
  shouldShowJoinCall,
  isTooEarlyToJoinCall,
} from '../../core/consultation';
import { dialOutExpert } from '../../actions/consultation';
import {
  getJoinInfo,
  joinCall,
  leaveCall,
  addRegistrant,
} from '../../actions/call';
import s from './JoinCall.module.scss';

const getRequesterName = (c) =>
  c.requester ? c.requester.name : 'Confidential client';
const getExpertName = (c) => (c.expert ? c.expert.name : 'Expert');
function getUserName(viewer, consultation) {
  const { expert } = consultation;
  const isViewerExpert = expert && viewer.id === expert.id;
  return isViewerExpert
    ? getRequesterName(consultation)
    : getExpertName(consultation);
}

class DialOutExpertButton extends React.PureComponent {
  state = {
    status: undefined,
  };

  handleDialOutExpert = () => {
    this.setState({ status: 'dialing' });
    this.props.onDialOutExpert();
  };

  render() {
    const { status } = this.state;
    const { phone } = this.props;

    return (
      <div>
        <MaterialButton
          disabled={status === 'dialing'}
          onClick={this.handleDialOutExpert}
        >
          {' '}
          {status === 'dialing'
            ? `Dialing ${phone}...`
            : `Dial expert at ${phone}`}
        </MaterialButton>
      </div>
    );
  }
}

function CallInstruction({ title, children }) {
  return (
    <div className={s.joinCall}>
      <div className={s.joinCallInstructionsTitle}>{title}</div>
      <div className={s.joinCallInstructionsText}>{children}</div>
    </div>
  );
}

function isIOS(ua) {
  return ua && ua.os && ua.os.family && ua.os.family.toLowerCase() === 'ios';
}

function isSafari(ua) {
  return (
    ua &&
    ua.ua &&
    ua.ua.family &&
    ua.ua.family.toLowerCase() === 'mobile safari'
  );
}

class JoinCall extends React.PureComponent {
  state = {
    unsupportedErrorOpen: false,
    joinURL: null,
  };

  async componentDidMount() {
    await this.getJoinURL();
  }

  getJoinURL = async () => {
    const {
      consultation,
      getJoinInfo,
      joinInfo,
      userAgentParsed: ua,
    } = this.props;

    if (
      consultation?.conference?.carrier === 'zoom' &&
      consultation.conference?.id &&
      isIOS(ua) &&
      isSafari(ua)
    ) {
      const info = joinInfo || (await getJoinInfo(consultation.conference.id));
      this.setState({ joinURL: info.join_url });
    }
  };

  handleJoinCall = async () => {
    const {
      viewer,
      joinCall,
      addRegistrant,
      consultation,
      joinInfo,
      getJoinInfo,
    } = this.props;

    const {
      expert,
      expert_identifier: expertIdentifier,
      requester_identifier: requesterIdentifier,
      starts_at: startsAt,
      expected_duration: expectedDuration,
      conference,
    } = consultation;

    const isViewerExpert = expert && viewer.id === expert.id;
    let identifier = isViewerExpert ? expertIdentifier : requesterIdentifier;
    let joinURL = null;

    if (conference.carrier === 'zoom') {
      let registrant = conference.registrants.find(
        (r) => r.email === viewer.email.address
      );
      // User must be added as a registrant before joining the call, expert
      // and requester are added by default
      if (!registrant) {
        registrant = await addRegistrant(consultation, {
          conferenceId: conference.id,
          name: `${viewer.first_name} ${viewer.last_name}`,
          email: viewer.email.address,
          phoneNumber: viewer.phone,
        });
      }

      // refresh join info if not available yet
      const info = joinInfo || (await getJoinInfo(conference.id));
      identifier = info.identifier;
      joinURL = info.join_url;
    }

    try {
      await joinCall(
        {
          carrier: conference.carrier,
          userName: getUserName(viewer, consultation),
          identifier,
          joinURL,
          startTime: startsAt,
          expectedDuration: Duration.parse(expectedDuration).seconds(),
          consultationId: consultation.id,
          mainPage: `/consultation/${consultation.id}`,
          awaitingExpert: false,
        },
        {
          receiveEvents: !isViewerExpert,
        }
      );
    } catch (e) {
      if (e.unsupportedBrowser) {
        this.setState({ unsupportedErrorOpen: true });
        return;
      }
      throw e;
    }
  };

  render() {
    const {
      viewer,
      consultation,
      dialOutExpert,
      call,
      userAgentParsed: ua,
    } = this.props;
    const {
      conference,
      expert,
      expert_identifier: expertIdentifier,
    } = consultation;

    if (!shouldShowJoinCall(viewer, consultation)) return null;

    const ios = isIOS(ua);
    const safari = isSafari(ua);
    const isZoomCall = conference?.carrier === 'zoom';
    const iosUnsupported = ios && !safari && !isZoomCall;

    return (
      <div>
        {call.connected && call.consultationId === consultation.id && (
          <div>
            <span className={s.ongoingCallOuter}>
              <div className={s.ongoingCallInner} />
            </span>
            <span className={s.ongoingCall}>Recording</span>
          </div>
        )}
        {call.connected && call.consultationId !== consultation.id && (
          <CallInstruction title="Another conference active.">
            Hang up current call before joining.
          </CallInstruction>
        )}
        {!call.connected && (
          <div className={s.joinCall}>
            {safari && ios && isZoomCall ? (
              <Link target="_blank" href={this.state.joinURL}>
                <Button
                  startIcon={
                    <MaterialIcon icon="phone" style={{ fontSize: 24 }} />
                  }
                  size="large"
                  disabled={!shouldAllowJoinCall(consultation)}
                >
                  Join Call
                </Button>
              </Link>
            ) : (
              <Button
                startIcon={
                  <MaterialIcon icon="phone" style={{ fontSize: 24 }} />
                }
                size="large"
                onClick={this.handleJoinCall}
                disabled={
                  !shouldAllowJoinCall(consultation) ||
                  iosUnsupported ||
                  call.connecting
                }
              >
                {call.connecting ? 'Joining...' : 'Join Call'}
              </Button>
            )}
            {iosUnsupported ? (
              <CallInstruction>
                <div className={s.notSupportedText}>
                  This browser is not supported on iOS.
                  <br />
                  Please switch to Safari.
                </div>
              </CallInstruction>
            ) : isTooEarlyToJoinCall(consultation) ? (
              <CallInstruction title="This call is not scheduled to begin yet.">
                Your web conference link will be accessible 1 hour before your
                scheduled call start time.
              </CallInstruction>
            ) : (
              viewer.admin &&
              !isZoomCall && (
                <DialOutExpertButton
                  phone={expert.phone}
                  identifier={expertIdentifier}
                  onDialOutExpert={() => dialOutExpert(consultation.id)}
                />
              )
            )}
            <Dialog
              warning
              open={this.state.unsupportedErrorOpen}
              title="Unsupported Browser"
              subTitle={
                'Please switch to another browser, ' +
                'such as Firefox or Chrome.'
              }
              onConfirm={() => this.setState({ unsupportedErrorOpen: false })}
              confirmLabel="Ok"
              confirmButtonProps={{ color: 'red' }}
            />
          </div>
        )}
      </div>
    );
  }
}

JoinCall = connect(
  (state) => ({
    viewer: state.viewer,
    call: state.call,
    userAgentParsed: state.ui.userAgentParsed,
  }),
  {
    addRegistrant,
    dialOutExpert,
    getJoinInfo,
    joinCall,
    leaveCall,
  }
)(JoinCall);

export default JoinCall;

import config from '../../../config';

export default {
  subdomain: '*',
  path: '/settings/payout',

  async action({ store, graphql, query, path }, params, { subdomain }) {
    if (!config.enablePayoutDashboard) {
      return;
    }

    const { viewer } = store.getState();
    const userId = viewer.id;
    let result;
    let payoutsEnabled = false;

    try {
      result = await graphql.query(
        `query getPayoutAccount($userId: String!) {
        payoutAccountByUserID(id: $userId) {
          payouts_enabled
        }
      }`,
        { userId }
      );

      const account = result.payoutAccountByUserID;
      payoutsEnabled = account.payouts_enabled;
    } catch (err) {
      if (err.message !== 'GraphQL Error: payout account not found') {
        throw err;
      }
      return;
    }

    let redirectUrl;
    try {
      if (payoutsEnabled) {
        result = await graphql.mutate(
          `(
            $userId: String!
          ) {
            createPayoutLoginURL(
              user_id: $userId
            )
          }`,
          { userId }
        );
        redirectUrl = result.createPayoutLoginURL;
      } else {
        result = await graphql.mutate(
          `(
              $userId: String!
              $type: PayoutAccountURLType!
            ) {
              createPayoutAccountURL(
                user_id: $userId
                type: $type
              )
            }`,
          { userId, type: 'account_onboarding' }
        );
        redirectUrl = result.createPayoutAccountURL;
      }
    } catch (err) {
      if (!(err instanceof APIError)) {
        throw err;
      }
      redirectUrl = '/settings?error=country_unsupported';
    }

    return {
      redirect: redirectUrl,
    };
  },
};

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchLandingPages } from '../../../actions/landing';
import LandingPageModel from './LandingPageModel';
import ListItems from '../ListItems';
import history from '../../../core/history';

export default function LandingPageModels({
  fetchLandingPages,
  landingPages,
  model,
  id,
}) {
  useEffect(() => {
    fetchLandingPages();
  }, []);

  const items = landingPages.map((page) => ({
    id: page.id,
    name: `${page.id} ${page.subdomain} - ${page.title}`,
  }));

  return id ? (
    <LandingPageModel model={model} id={id !== 'new' ? id : undefined} />
  ) : (
    <ListItems
      model={model}
      items={items}
      onCreate={() => history.push(`/admin/${model.path}/new`)}
    />
  );
}

LandingPageModels = connect(
  (state) => {
    return {
      landingPages: state.landing.all || [],
    };
  },
  {
    fetchLandingPages,
  }
)(LandingPageModels);

export function initSegmentIdentifier(user = {}) {
  const { analytics } = window;
  if (!analytics || !analytics.identify) return;

  const { user: userFn } = analytics;

  const analyticsTraits = {
    name: user.name,
    email: user.email?.address,
    userId: user.id,
  };

  // Helpful for GTM triggers/variables
  window._analyticsTraits = analyticsTraits;

  if (user.id && (!userFn || userFn().traits().userId !== user.id)) {
    analytics.identify(user.id, analyticsTraits);
  }
}

export function segmentTracking(eventName, properties) {
  const { analytics } = window;
  if (!analytics || !analytics.identify) return;

  analytics.track(eventName, properties);
}

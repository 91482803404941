import React, { useMemo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import Grid from '@mui/material/Grid';
import Link from '../../components/Link';
import { borderColor } from '../../core/colors';
import LandingPageModels from './landingPage/LandingPageModels';
import SiteSettings from './siteSettings/SiteSettings';
import ExpertShowcaseModels from './expertShowcase/ExpertShowcaseModels';
import SectorModels from './sector/SectorModels';
import SnackbarNotification from '../../components/Snackbar/SnackbarNotification';
import AdminHeader from './AdminHeader';
import ExpertShowcaseCategoryModel from './expertShowcase/ExpertShowcaseCategoryModel';
import ExpertShowcaseSectionModel from './expertShowcase/ExpertShowcaseSectionModel';

const useStyles = makeStyles(() => ({
  root: {
    margin: 30,
    display: 'flex',
    flexDirection: 'column',
  },
  empty: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    height: 200,
  },
  menu: {
    border: `1px solid ${borderColor}`,
  },
  content: {
    border: `1px solid ${borderColor}`,
  },
  section: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
export default function Admin({ model, id }) {
  const s = useStyles();

  const sections = useMemo(
    () => [
      {
        name: 'Marketing',
        models: [
          {
            path: 'landing_pages',
            name: 'Landing Page',
            component: LandingPageModels,
          },
          {
            path: 'expert_showcases',
            name: 'Expert Showcase',
            component: ExpertShowcaseModels,
          },
          {
            path: 'expert_showcase_categories',
            name: 'Expert Showcase Category',
            component: ExpertShowcaseCategoryModel,
            hidden: true,
          },
          {
            path: 'expert_showcase_sections',
            name: 'Expert Showcase Section',
            component: ExpertShowcaseSectionModel,
            hidden: true,
          },
        ],
      },
      {
        name: 'Operations',
        models: [
          {
            path: 'sectors',
            name: 'Sector Management',
            component: SectorModels,
          },
          {
            path: 'site_settings',
            name: 'Site Settings',
            component: SiteSettings,
          },
        ],
      },
    ],
    []
  );

  const models = sections.reduce((a, s) => a.concat(s.models), []);
  const modelSelected = models.find((m) => m.path === model);

  return (
    <>
      <AdminHeader />
      <div className={s.root}>
        <Grid container spacing={4}>
          <Grid item md={2}>
            <div className={s.menu}>
              <List>
                {sections.map((s) => {
                  const links = s.models
                    .filter((m) => !m.hidden)
                    .map((m) => (
                      <Link key={m.path} to={`/admin/${m.path}`}>
                        <ListItem
                          selected={m.path === model}
                          classes={{
                            root: s.section,
                            selected: s.sectionSelected,
                          }}
                        >
                          {m.name}
                        </ListItem>
                      </Link>
                    ));
                  return (
                    <>
                      <ListSubheader key={s.name} disableSticky>
                        {s.name}
                      </ListSubheader>
                      {links}
                    </>
                  );
                })}
              </List>
            </div>
          </Grid>

          <Grid item md={9}>
            <div className={s.content}>
              {modelSelected ? (
                React.createElement(modelSelected.component, {
                  model: modelSelected,
                  id,
                })
              ) : (
                <div className={s.empty}>Empty</div>
              )}
            </div>
          </Grid>
        </Grid>

        <SnackbarNotification />
      </div>
    </>
  );
}

import React from 'react';
import { connect } from 'react-redux';
import LayoutPage from '../../components/Layout/LayoutPage';
import EmptyMessage from '../../components/EmptyMessage';
import Button from '../../components/Button';
import { teal500 } from '../../core/colors';
import { requestEmailValidation } from '../../actions/address';
import { notify } from '../../actions/ui';

class AwaitingEmailValidation extends React.Component {
  handleResendVerification = () => {
    const { viewer, requestEmailValidation, notify } = this.props;
    requestEmailValidation(
      viewer.profile.id,
      viewer.email.address,
      'verify'
    ).then(() => notify('Verification email sent.'));
  };

  render() {
    const { viewer } = this.props;

    return (
      <LayoutPage
        hideSearch
        showReviewConsultation={false}
        showNewRequest={false}
      >
        <EmptyMessage
          border={false}
          iconName="mail"
          iconColor={teal500}
          title="Please check your inbox for a verification email"
          body={
            <div>
              <p>
                We need to verify ownership of your email address before
                continuing.
              </p>
              <p>{viewer.email.address}</p>
            </div>
          }
          action={
            <Button onClick={this.handleResendVerification}>Re-Send</Button>
          }
        />
      </LayoutPage>
    );
  }
}

AwaitingEmailValidation = connect(
  (state) => ({
    viewer: state.viewer,
  }),
  {
    requestEmailValidation,
    notify,
  }
)(AwaitingEmailValidation);

export default AwaitingEmailValidation;

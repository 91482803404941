import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Chip, MenuItem } from '@mui/material';
import Picture from '../Picture';
import { isEmailValid } from '../../core/util';
import { suggestUsers } from '../../actions/suggestion';
import SelectWithSuggestions from '../SelectWithSuggestions/SelectWithSuggestions';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  picture: {
    marginRight: 10,
  },
};

class UserChip extends PureComponent {
  render() {
    const { user, ...other } = this.props;
    return (
      <Chip
        {...other}
        avatar={<Picture user={user} link={false} size={24} />}
        label={user.name}
      />
    );
  }
}

class UserMenuItem extends PureComponent {
  // Needed for autocomplete check
  static muiName = 'MenuItem';

  render() {
    const { user, ...other } = this.props;

    return (
      <MenuItem {...other}>
        <span style={styles.root}>
          <Picture style={styles.picture} size={40} user={user} link={false} />
          <span>{user.name}</span>
        </span>
      </MenuItem>
    );
  }
}

class InviteMenuItem extends PureComponent {
  static muiName = 'MenuItem';

  render() {
    const { email, ...other } = this.props;

    return (
      <MenuItem {...other}>
        <span style={styles.root}>
          <Picture style={styles.picture} size={40} link={false} />
          <span>Invite &quot;{email}&quot;</span>
        </span>
      </MenuItem>
    );
  }
}

class SelectUser extends PureComponent {
  static defaultProps = {
    underlineShow: true,
    errorText: undefined,
    allowEmail: false,
    userFilter: () => true,
  };

  renderOption = (props, { type, value }) => {
    if (type === 'email') {
      return <InviteMenuItem {...props} email={value.email} />;
    }

    // user
    return <UserMenuItem {...props} user={value} />;
  };

  renderTags = (value, getTagProps) => {
    const { disabled } = this.props;

    const props = {
      style: {
        margin: '5px',
        float: 'left',
        pointerEvents: disabled ? 'none' : undefined,
      },
    };
    return value.map(({ type, value }, index) => {
      if (type === 'email') {
        return (
          <Chip
            label={value.email}
            {...getTagProps({ index })}
            {...props}
            disabled={disabled}
          />
        );
      }
      return (
        <UserChip
          user={value}
          {...getTagProps({ index })}
          {...props}
          disabled={disabled}
        />
      );
    });
  };

  formatOption = ({ type, value }) => {
    if (type === 'email') {
      // email
      return {
        type,
        label: value.email,
        value,
      };
    }

    // user
    return {
      type,
      label: value.name,
      value,
    };
  };

  isValidEntry = (value) => {
    const { allowEmail, userFilter } = this.props;

    if (!userFilter(value)) return false;

    const type = value.type || 'email';
    const rawValue = value.value || value;
    if (
      type === 'email' && // not expecting emails or invalid email
      (!allowEmail || !isEmailValid(rawValue.email))
    )
      return false;

    return true;
  };

  render() {
    const {
      allowEmail,
      suggestUsers,
      suggestBufferSize,
      userType,
      userFilter,
      userSuggestions,
      loadingSuggestions,
      errorText,
      multiple,
      disabled,
      underlineShow,
      ...rest
    } = this.props;

    return (
      <SelectWithSuggestions
        label="Find or invite users"
        {...rest}
        allowRaw={allowEmail}
        allowSuggestion
        suggest={suggestUsers}
        suggestBufferSize={suggestBufferSize}
        suggestScope="users"
        suggestionsFilter={userFilter}
        suggestions={userSuggestions}
        loadingSuggestions={loadingSuggestions}
        errorText={errorText}
        multiple={multiple}
        disabled={disabled}
        underlineShow={underlineShow}
        renderTags={this.renderTags}
        renderOption={this.renderOption}
        formatOption={this.formatOption}
        isValidEntry={this.isValidEntry}
        isValidRaw={isEmailValid}
        rawType="email"
        rawKey="email"
        suggestionType="user"
      />
    );
  }
}

SelectUser = connect(
  (state) => {
    const { loading: loadingSuggestions, cache: userSuggestions } =
      state.suggestions.users;

    return {
      userSuggestions,
      loadingSuggestions,
    };
  },
  {
    suggestUsers,
  }
)(SelectUser);

export default SelectUser;

import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import langs from 'langs';
import InputAdornment from '@mui/material/InputAdornment';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { TextField, Select, RadioGroup } from '../../FormAdapters';
import Image from '../../Image';
import CvButton from '../../CvButton/CvButton';
import Button from '../../Button/Button';
import { borderColor } from '../../../core/colors';
import { presignedFilestackURL } from '../../../actions/filestack';
import { normalizeSpace } from '../../../core/util';
import CircularProgress from '../../CircularProgress/CircularProgress';
import SelectLocation from '../../SelectLocation';

const useStyles = makeStyles((theme) => ({
  divider: {
    height: 1,
    backgroundColor: borderColor,
    margin: '20px 0',
    [theme.breakpoints.down('md')]: {
      margin: '10px 0',
    },
  },
  image: {
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      height: '100%',
    },
  },
  imageUpload: {
    marginTop: 16,
    marginBottom: 8,
    [theme.breakpoints.down('md')]: {
      marginBottom: 0,
    },
  },
  upload: {
    background: 'red',
  },
  footer: {
    marginTop: 30,
  },
  paragraph: {
    fontWeight: 'bold',
    marginTop: 10,
    marginBottom: 5,
  },
}));

function BasicInformationForm({
  allCountries,
  canAutofill,
  presignedFilestackURL,
  sending,
  handleSubmit,
  form,
  values,
}) {
  const s = useStyles();

  const theme = useTheme();

  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const languagesOptions = langs
    .all()
    .map((l) => ({ label: l.name, value: l['3'] }));

  if (sending) {
    return (
      <div className={s.loading}>
        <CircularProgress
          size={52}
          style={{ marginLeft: 10, marginBottom: 20 }}
        />
        <div>Processing...</div>
      </div>
    );
  }

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <Grid container spacing={4}>
        <Grid item md={6} sm={12} xs={12}>
          <div className={s.imageUpload}>
            <Image
              src={values.picture}
              onChange={async (img) => {
                const url = await presignedFilestackURL(img.url);
                form.change('picture_url', img.url);
                form.change('picture', url);
              }}
              emptyText="Upload a photo here"
              editable
              label="Profile Photo"
              keepOutline
              className={s.image}
              dimensions={{ width: 220, height: 220 }}
            />
          </div>
        </Grid>

        <Grid item md={6} sm={12} xs={12}>
          <Grid container spacing={1}>
            <Grid item md={6} sm={12} xs={12}>
              <Field
                component={TextField}
                name="first_name"
                label="First Name"
                variant="outlined"
                autoComplete="nope"
                changeOnBlur={false}
                format={normalizeSpace}
                inputProps={{ maxLength: 255 }}
              />
            </Grid>

            <Grid item md={6} sm={12} xs={12}>
              <Field
                component={TextField}
                name="last_name"
                label="Last Name"
                variant="outlined"
                autoComplete="nope"
                changeOnBlur={false}
                format={normalizeSpace}
                inputProps={{ maxLength: 255 }}
              />
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <Field
                component={TextField}
                name="city"
                label="City"
                variant="outlined"
                autoComplete="nope"
                inputProps={{ maxLength: 255 }}
              />
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <Field
                component={SelectLocation}
                multiple={false}
                name="country"
                countries={allCountries}
                format={(country) =>
                  allCountries.find((c) => c.name === country)
                }
                parse={(country) => (country && country.name) || ''}
                label="Country"
                TextFieldProps={{
                  variant: 'outlined',
                  inputProps: { autoComplete: 'nope' },
                }}
              />
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <Field
                component={TextField}
                name="linkedin_url"
                label="LinkedIn URL"
                variant="outlined"
                inputProps={{ maxLength: 255 }}
              />
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <Field
                component={TextField}
                type="upload"
                name="resume"
                label="Upload Resume (PDF, DOC)"
                variant="outlined"
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <CvButton
                        label="Upload"
                        onUpload={(file) => {
                          form.change('autofill', true);
                          form.change('cv_url', file.url);
                          form.change('resume', file.filename);
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <Field
                component={Select}
                name="languages"
                label="Proficient Languages"
                options={languagesOptions}
                autocomplete
                limit={null}
                TextFieldProps={{ variant: 'outlined' }}
                multiple
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {!mobile && <Divider className={s.divider} />}

      <Grid container>
        <Grid item md={12} sm={12} xs={12}>
          <Field
            component={TextField}
            name="title"
            placeholder="Write a short headline about your experience"
            label="Headline"
            variant="outlined"
            multiline={mobile}
            InputLabelProps={{
              shrink: true,
            }}
            minRows={2}
            maxRows={6}
            inputProps={{ maxLength: 120 }}
          />
        </Grid>

        <Grid item md={12} sm={12} xs={12}>
          <Field
            component={TextField}
            name="summary"
            placeholder="Add a brief bio"
            label="Short Bio"
            variant="outlined"
            multiline
            InputLabelProps={{
              shrink: true,
            }}
            minRows={mobile ? 4 : 2}
            maxRows={4}
            inputProps={{ maxLength: 2000 }}
          />
        </Grid>
      </Grid>

      {(!!canAutofill || values.cv_url) && (
        <React.Fragment>
          <Divider className={s.divider} />

          <Grid container>
            <p className={s.paragraph}>Profile Optimization</p>

            <Field
              component={RadioGroup}
              defaultValue="true"
              name="autofill"
              options={[
                {
                  value: 'true',
                  label:
                    'Yes, use my CV and other info that may be on file to improve my profile',
                },
                {
                  value: 'false',
                  label: 'No thanks, I will manually complete all fields',
                },
              ]}
            />
          </Grid>
        </React.Fragment>
      )}

      <div className={s.footer}>
        <Button size="large" type="submit" fullWidth={mobile}>
          Continue
        </Button>
      </div>
    </form>
  );
}

BasicInformationForm = connect(
  (state, ownProps) => {
    const profile = state.profiles.fullProfiles[ownProps.profileId];
    const languages = profile.languages || [];

    return {
      allCountries: state.countries,
      languages,
    };
  },
  {
    presignedFilestackURL,
  }
)(BasicInformationForm);

export default BasicInformationForm;

import React from 'react';
import cx from 'classnames';
import Grid from '@mui/material/Grid';
import FAIcon from '../Icon/FAIcon';
import Divider from '../Divider';
import { darkGreen } from '../../core/colors';
import s from './Engagements.module.scss';

function Engagement({ border, icon, title, children, ...other }) {
  return (
    <Grid lg={3} md={6} xs={12} item container {...other}>
      <div className={cx(s.engagement, { [s.border]: border })}>
        <div className={s.engagementIcon}>
          <FAIcon iconSet="fal" icon={icon} color={darkGreen} size={30} />
        </div>
        <h4 className={s.engagementTitle}>{title}</h4>
        <Divider />
        <div className={s.engagementText}>{children}</div>
      </div>
    </Grid>
  );
}

function EngagementsClient({ border = true }) {
  return (
    <Grid container spacing={4}>
      <Engagement border={border} icon="phone" title="Consultation">
        Connect with an expert for a phone call, meet-up, or written Q&A
      </Engagement>
      <Engagement border={border} icon="briefcase" title="Contract engagement">
        Hire an expert for a short or long-term consulting engagement
      </Engagement>
      <Engagement border={border} icon="user-tie" title="Advisory">
        Find an expert to join your team or proposal for ongoing advisory
      </Engagement>
      <Engagement border={border} icon="chair-office" title="Permanent hire">
        Hire an expert for a full or part-time position on your team or proposal
      </Engagement>
    </Grid>
  );
}

EngagementsClient = EngagementsClient;

function EngagementsExpert({ border = true }) {
  return (
    <Grid container spacing={4}>
      <Engagement border={border} icon="phone" title="Consultation">
        Connect with a client for a phone call, meet-up, or written Q&A
      </Engagement>
      <Engagement border={border} icon="briefcase" title="Contract engagement">
        Get hired for a short or long-term consulting engagement
      </Engagement>
      <Engagement border={border} icon="user-tie" title="Advisory">
        Serve as an ongoing advisor or in a board capacity
      </Engagement>
      <Engagement border={border} icon="chair-office" title="Permanent hire">
        Sign on for a full or part time role for a company or major project
        opportunity
      </Engagement>
    </Grid>
  );
}

EngagementsExpert = EngagementsExpert;

export { EngagementsClient, EngagementsExpert };

import React, { useEffect, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import {
  fetchExpertShowcaseSection,
  updateExpertShowcaseSection,
  removeExpertShowcaseSection,
} from '../../../actions/expertShowcase';
import { notify } from '../../../actions/ui';
import history from '../../../core/history';
import ModelForm from '../ModelForm';
import { SelectProfile } from '../../../components/FormAdapters';

function ExpertShowcaseSectionModel({
  id,
  model,
  fetchExpertShowcaseSection,
  updateExpertShowcaseSection,
  notify,
  section,
}) {
  useEffect(() => {
    if (id) {
      fetchExpertShowcaseSection(id).then((page) => {
        if (!page) {
          notify('Not found', 'warning');
          history.push(`/admin/expert_showcases`);
        }
      });
    }
  }, [id]);

  const validateProfiles = useCallback((profiles) => {
    const profileIds = profiles?.map((p) => p.profile_id);
    return profiles?.map((p) => {
      const errors = {
        profile_id: required(p.profile_id),
        order: required(p.order),
      };
      if (
        profileIds.indexOf(p.profile_id) !==
        profileIds.lastIndexOf(p.profile_id)
      ) {
        errors.profile_id = `Profile is duplicated`;
      }
      return errors;
    });
  }, []);

  const validateCtaLabel = useCallback((ctaLabel, values) => {
    if (!ctaLabel && values.cta_url) {
      return 'Required (CTA URL is informed)';
    }

    if (ctaLabel && !ctaLabel.trim()) {
      return 'Invalid value';
    }

    return undefined;
  }, []);

  const validateCtaUrl = useCallback((ctaUrl, values) => {
    if (!ctaUrl && values.cta_label) {
      return 'Required (CTA Label is informed)';
    }

    if (ctaUrl && !ctaUrl.trim()) {
      return 'Invalid value';
    }

    return undefined;
  }, []);

  const editableFields = useMemo(
    () => [
      { name: 'icon', type: 'text', required: true },
      { name: 'name', type: 'text', required: true },
      { name: 'slug', type: 'text', required: true },
      { name: 'order', type: 'number', required: true },
      {
        name: 'cta_label',
        label: 'CTA Label',
        type: 'text',
        maxLength: 40,
        validate: validateCtaLabel,
      },
      {
        name: 'cta_url',
        label: 'CTA URL',
        type: 'text',
        maxLength: 256,
        validate: validateCtaUrl,
      },
      {
        name: 'profiles',
        validate: validateProfiles,
        fields: [
          {
            name: 'profile_id',
            component: (props) => {
              const { value } = props.input;
              // TODO: Figure out how to get profile info other than ID
              const profile = section.profiles_raw?.find(
                (p) => p.profile_id === value
              );
              return (
                <SelectProfile
                  {...props}
                  value={
                    profile
                      ? {
                          type: 'profile',
                          value: {
                            id: value,
                            name: profile.profile_name,
                          },
                        }
                      : null
                  }
                  label="Profile"
                  trapEnter
                />
              );
            },
          },
          { name: 'name', label: 'Name (override)', type: 'text' },
          { name: 'picture', label: 'Picture (override)', type: 'file' },
          { name: 'city', label: 'City (override)', type: 'text' },
          { name: 'country', label: 'Country (override)', type: 'text' },
          { name: 'experience', label: 'Experience (override)', type: 'text' },
          { name: 'title', label: 'Headline (override)', type: 'text' },
          {
            name: 'summary',
            label: 'Short Bio (override)',
            type: 'textarea',
            maxLength: 2000,
          },
          {
            name: 'marketing_keywords',
            type: 'chips',
            maxLength: 40,
            maxChips: 30,
          },
          { name: 'order', type: 'number' },
          { name: 'highlight', type: 'checkbox' },
          { name: 'badge_text', type: 'text', maxLength: 40 },
        ],
      },
    ],
    [section]
  );

  const handleSubmit = useCallback(async (values) => {
    values.profiles.forEach((p) => {
      delete p.profile_name;
      delete p.file_picture;
    });
    const section = await updateExpertShowcaseSection(values);
    history.push(`/admin/${model.path}/${section.id}`);
  }, []);

  const handleDelete = useCallback(() => removeExpertShowcaseSection(id), []);

  const required = useCallback((value) => (value ? undefined : 'Required'), []);

  return (
    <ModelForm
      title={`Section ${id} - ${section.name}`}
      id={id}
      model={model}
      onSubmit={handleSubmit}
      onDelete={handleDelete}
      initialModel={{
        ...section,
        profiles: section.profiles_raw,
      }}
      fields={editableFields}
      returnPath={`expert_showcase_categories/${section.category_id}`}
    />
  );
}

ExpertShowcaseSectionModel = connect(
  (state, ownProps) => {
    return {
      section: state.expertShowcase.sections[ownProps.id] || {},
    };
  },
  {
    fetchExpertShowcaseSection,
    updateExpertShowcaseSection,
    removeExpertShowcaseSection,
    notify,
  }
)(ExpertShowcaseSectionModel);

export default ExpertShowcaseSectionModel;

import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm, Field } from 'redux-form';
import makeStyles from '@mui/styles/makeStyles';
import cx from 'classnames';
import { TextField } from '../FormAdapters';
import Dialog from '../Dialog';
import Link from '../Link';
import StarRating from './StarRating';
import { notify, hideMessage } from '../../actions/ui';
import {
  getEngagementTimestamp,
  reviewConsultation,
} from '../../actions/consultation';
import { formatDateTime } from '../../core/util';
import { getTimezoneDescription } from '../../core/time';

const useStyles = makeStyles(() => ({
  callDetail: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  detailTitle: {
    color: 'darkBrown',
    marginBottom: '5px',
  },
  detailContent: {
    color: 'black',
    fontSize: '18px',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  detailContentInline: {
    flexDirection: 'row',
    alignItems: 'initial',
  },
  timezone: {
    color: 'darkGray',
  },
  detailLink: {
    flexDirection: 'row',
    textAlign: 'flex-start',
  },
}));

function ReviewConsultation({
  open,
  user,
  viewer,
  reviewConsultation,
  isViewerExpert,
  consultation,
  consultationLink,
  hideMessage,
  match_experience_rating: matchExperienceRating,
  uselfulness_rating: uselfulnessRating,
  communication_rating: communicationRating,
  connection_quality_rating: connectionQualityRating,

  onConfirm,
  onCancel,

  notify,
  change,
  reset,
  handleSubmit: handleSubmitProp,
}) {
  const s = useStyles();

  const handleRatingChange = (nextValue, _, name) => {
    change(name, nextValue);
  };

  const resetRatings = () => {
    change('match_experience_rating', 0);
    change('uselfulness_rating', 0);
    change('communication_rating', 0);
    change('connection_quality_rating', 0);
  };

  const handleSubmit = (values) => {
    hideMessage();

    return reviewConsultation({
      ...values,
      consultation_id: consultation.id,
    }).then(
      () => {
        notify('Thanks for your feedback!', 'success');
        reset();
        resetRatings();
        if (onConfirm) {
          onConfirm();
        }
      },
      () => {
        notify('An error occurred when saving review.', 'error');
      }
    );
  };

  const engagementTime = getEngagementTimestamp(consultation);
  const subTitle = (
    <div>
      {engagementTime && (
        <div className={s.callDetail}>
          <div className={s.detailTitle}>Date & Time</div>
          <div
            className={cx(s.detailContent, { [s.detailContentInline]: true })}
          >
            {formatDateTime(consultation.starts_at, viewer.timezone)}&nbsp;
            <span className={s.timezone}>
              {getTimezoneDescription(viewer.timezone)}
            </span>
          </div>
        </div>
      )}
      {consultationLink && (
        <div className={s.detailLink}>
          <Link to={consultation.html_url}>Show details</Link>
        </div>
      )}
    </div>
  );

  return (
    <Dialog
      open={open}
      title={`How was your call${user ? ` with ${user.name}?` : '?'}`}
      subTitle={subTitle}
      subTitleStyle={{ marginTop: 15 }}
      confirmLabel="Submit"
      onClose={onCancel}
      onCancel={onCancel}
      onConfirm={handleSubmitProp(handleSubmit)}
    >
      {isViewerExpert ? (
        <StarRating
          style={{ marginTop: 30 }}
          title="Match for my Experience"
          onStarClick={handleRatingChange}
          name="match_experience_rating"
          value={matchExperienceRating}
        />
      ) : (
        <StarRating
          style={{ marginTop: 30 }}
          title="Match for my Request"
          onStarClick={handleRatingChange}
          name="uselfulness_rating"
          value={uselfulnessRating}
        />
      )}

      <StarRating
        title="Communication"
        onStarClick={handleRatingChange}
        name="communication_rating"
        value={communicationRating}
      />

      <StarRating
        title="Connection Quality"
        onStarClick={handleRatingChange}
        name="connection_quality_rating"
        value={connectionQualityRating}
      />

      {!isViewerExpert && (
        <Field
          component={TextField}
          multiline
          fullWidth
          name="review"
          placeholder="This will be posted publicly on the Expert’s profile"
          label="Write a Review"
        />
      )}

      <Field
        component={TextField}
        multiline
        fullWidth
        name="target_feedback"
        placeholder={`This will only be seen by ${
          isViewerExpert ? ' the Client' : ' your Expert'
        }`}
        label={`Private Feedback to ${isViewerExpert ? 'Client' : 'Expert'}`}
      />

      <Field
        component={TextField}
        multiline
        fullWidth
        name="onfrontiers_feedback"
        placeholder="This will only be seen by OnFrontiers"
        label="Private Feedback"
      />
    </Dialog>
  );
}

ReviewConsultation = reduxForm()(ReviewConsultation);

ReviewConsultation = connect(
  (state, ownProps) => {
    const form = ownProps.form || 'reviewConsultation';
    const selector = formValueSelector(form);
    return {
      viewer: state.viewer,
      form,
      initialValues: {
        uselfulness_rating: ownProps.initialUsefulnessRating,
        match_experience_rating: ownProps.initialMatchExperienceRating,
      },
      match_experience_rating: selector(state, 'match_experience_rating'),
      uselfulness_rating: selector(state, 'uselfulness_rating'),
      communication_rating: selector(state, 'communication_rating'),
      connection_quality_rating: selector(state, 'connection_quality_rating'),
    };
  },
  {
    reviewConsultation,
    notify,
    hideMessage,
  }
)(ReviewConsultation);

export default ReviewConsultation;

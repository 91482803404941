import React from 'react';
import s from './BlogTag.module.scss';
import { safeHtml } from '../../../core/util';

function BlogTag({ href, text }) {
  return (
    <a
      href={href}
      className={s.tag}
      dangerouslySetInnerHTML={{ __html: `#${safeHtml(text)}` }}
    />
  );
}

export default BlogTag;

import React from 'react';
import isEqual from 'lodash.isequal';
import { Select } from '../FormAdapters/FormAdapters';

export default function KeywordInput({
  input,
  meta,
  primary,
  label,
  placeholder,
  ChipProps = {},
  ...other
}) {
  const chipColor = primary ? 'primary' : 'default';

  return (
    <Select
      autocomplete
      multiple
      input={input}
      meta={meta}
      label={label}
      placeholder={placeholder}
      rawValueOnChange
      getOptionValue={(o) => o}
      isOptionEqualToValue={isEqual}
      TextFieldProps={{
        inputProps: {
          maxLength: 64,
        },
      }}
      ChipProps={{
        color: chipColor,
        ...ChipProps,
      }}
      {...other}
    />
  );
}

import React, { PureComponent } from 'react';
import cx from 'classnames';
import Wallpaper from '../Wallpaper';
import Body from '../Body';
import MarketingHeader from './MarketingHeader';
import { darkBrown } from '../../core/colors';
import s from './MarketingHero.module.scss';

class Hero extends PureComponent {
  static defaultProps = {
    vignette: false,
    backgroundColor: darkBrown,
    backgroundPosition: 'center',
    dark: true,
  };

  render() {
    const {
      children,
      className,
      backgroundImage,
      backgroundVideo,
      backgroundColor,
      backgroundPosition,
      theme,
      vignette,
      contentClassName,
      contentStyle,
      headerProps,
      dark,
      tint,
      ...other
    } = this.props;
    const classNames = cx(s.root, {
      [className]: !!className,
      [s.dark]: !!dark,
    });
    return (
      <div className={classNames} {...other}>
        <Wallpaper
          color={backgroundColor}
          imgSrc={backgroundImage}
          videoSrc={backgroundVideo}
          vignette={vignette}
          imgPosition={backgroundPosition}
          tint={tint}
        />

        <MarketingHeader
          theme={theme}
          backgroundColor={backgroundColor}
          {...headerProps}
        />

        <Body className={cx(s.content, contentClassName)} style={contentStyle}>
          {children}
        </Body>
      </div>
    );
  }
}

export default Hero;

import React, { PureComponent } from 'react';

import { Chip } from '@mui/material';
import Message from '../../components/Message';
import s from './Transcript.module.scss';
import { white, sand, darkBrown, darkGreen } from '../../core/colors';

function createSpeakerUser(speaker, speakerUnknownMap) {
  let name = speaker && (speaker.name || speakerUnknownMap[speaker.id]);
  if (!name) {
    name = String(Object.keys(speakerUnknownMap).length + 1);
    speakerUnknownMap[speaker.id] = name;
  }
  const nameParts = name.split(' ');
  return { first_name: nameParts[0], last_name: nameParts[1] };
}

class Transcript extends PureComponent {
  render() {
    const { transcription, expertId } = this.props;

    const speakers = transcription.speakers || [];
    const monologues = transcription.monologues || [];

    const leftSpeaker =
      speakers.find((x) => x.user && x.user.id === expertId) ||
      speakers.find((x) => !x.user) ||
      speakers[0] ||
      {};

    const speakerUnknownMap = {};

    return (
      <div className={s.root}>
        {monologues.map((m) => {
          const elements = m.elements || [];
          const speaker = m.speaker || {};
          const { user } = speaker;

          const text = elements.map((e) => {
            if (e.type === 'tag')
              return (
                <Chip
                  key={`${e.position}${e.value}`}
                  style={{
                    display: 'inline',
                    verticalAlign: 'baseline',
                    marginLeft: 3,
                    marginRight: 3,
                  }}
                  label={e.value}
                />
              );
            return e.value;
          });

          const isLeft = leftSpeaker.id === speaker.id;

          return (
            <Message
              key={text}
              className={s.message}
              color={isLeft ? darkBrown : white}
              borderColor={isLeft ? sand : darkGreen}
              backgroundColor={isLeft ? sand : darkGreen}
              position={isLeft ? 'left' : 'right'}
              align="top"
              user={user || createSpeakerUser(speaker, speakerUnknownMap)}
            >
              {text}
            </Message>
          );
        })}
      </div>
    );
  }
}

export default Transcript;

import React from 'react';
import CircularProgressMaterial from '@mui/material/CircularProgress';

function CircularProgress(props) {
  return (
    <CircularProgressMaterial {...props} color={props.color || 'secondary'} />
  );
}

export default CircularProgress;

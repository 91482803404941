import React, { PureComponent } from 'react';
import cx from 'classnames';

import Article from '../../components/Article';
import MediaQuery from '../../components/MediaQuery/MediaQuery';
import Layout from '../../components/MarketingLayout/MarketingLayout';
import Link from '../../components/Link';
import MarketingHeader from '../../components/MarketingLayout/MarketingHeader';
import Newsletter from '../../components/Newsletter';
import { SCREEN_XS } from '../../constants/index';
import logo from './logo-org-black.png';
import { white } from '../../core/colors';
import s from './Landing.module.scss';
import OrgClientIntake from '../../components/ClientIntake/Org';

class Landing extends PureComponent {
  state = {
    intakeOpen: false,
  };

  openIntakeDialog = () => this.setState({ intakeOpen: true });

  closeIntakeDialog = () => this.setState({ intakeOpen: false });

  render() {
    const { article } = this.props;
    return (
      <MediaQuery maxWidth={SCREEN_XS}>
        {(mobile) => (
          <Layout
            footerProps={{ contactEmail: 'info@onfrontiers.org' }}
            className={s.root}
          >
            <MarketingHeader
              theme="light"
              backgroundColor={white}
              logo={<Logo mobile={mobile} />}
              links={[]}
            />
            <div className={s.body}>
              <Article
                className={cx(s.article, { [s.articleMobile]: mobile })}
                html={article.content.rendered}
              />
              <Link
                className={s.requestAccessButton}
                onClick={this.openIntakeDialog}
              >
                Request Access
              </Link>
              {this.state.intakeOpen && (
                <OrgClientIntake open onClose={this.closeIntakeDialog} />
              )}
            </div>
            <Newsletter newsletterId="20576bc0cb" />
          </Layout>
        )}
      </MediaQuery>
    );
  }
}

function Logo({ mobile }) {
  return (
    <img
      src={logo}
      alt="OnFrontiers"
      style={{
        width: mobile ? 233 : 275,
        height: mobile ? 17 : 20,
      }}
    />
  );
}

Landing = Landing;

export default Landing;

import fetch from '../core/fetch';

export default function createHelpers(config) {
  return {
    fetch: fetch,
    graphql: config.graphql,
    history: config.history,
    sendBird: config.sendBird,
    voipCarrier: config.voipCarrier,
    apiWebSocket: config.apiWebSocket,
  };
}

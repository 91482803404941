import React, { useCallback, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import MaterialIcon from '../Icon/MaterialIcon';
import Menu from '../Menu';

function IconMenu({
  children,
  buttonStyle,
  iconElement,
  iconSize,
  placement = 'bottom-end',
  ...rest
}) {
  const [anchorEl, setAnchorEl] = useState();

  const handleOpen = useCallback(
    (event) => setAnchorEl(event.currentTarget),
    []
  );

  const handleClose = useCallback(() => setAnchorEl(null), []);

  return (
    <>
      <IconButton style={{ padding: 0, ...buttonStyle }} onClick={handleOpen}>
        {iconElement || (
          <MaterialIcon
            size={iconSize || 36}
            icon="more_horiz"
            color="silver"
          />
        )}
      </IconButton>
      <Menu
        {...rest}
        anchorEl={anchorEl}
        placement={placement}
        onClose={handleClose}
      >
        {children}
      </Menu>
    </>
  );
}

export default IconMenu;

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import Button from '@mui/material/Button';
import stringify from 'json-stringify-deterministic';
import LongList from '../../components/LongList';
import SearchPromo from '../../components/SearchPromo';
import FAIcon from '../../components/Icon/FAIcon';
import AddToNetworkDialog from '../../components/AddToNetworkDialog';
import Alert from '../../components/Alert';
import ProfileSearchResult from './ProfileSearchResult/ProfileSearchResult';
import Filters from './Filters/Filters';
import InternalNetworkShareSingleProfile from '../../components/InternalNetworkShareSingleProfile';
import AddToExpertRequestButton from '../../components/AddToExpertRequestButton';
import InternalNetworkShareButton from '../../components/InternalNetworkShareButton';
import AddProfileKeywordsButton from './AddProfileKeywordsButton';
import {
  Query,
  search,
  selectProfile,
  clearProfileSelection,
} from '../../actions/search';
import { addNetworkExperts } from '../../actions/internalNetwork';
import { inviteUser } from '../../actions/invitation';
import { fetchUser } from '../../actions/user';
import { fetchProfile } from '../../actions/profile';
import { notify } from '../../actions/ui';
import s from './Experts.module.scss';
import SelectedProfilesBar from './SelectedProfilesBar';

class Experts extends PureComponent {
  static defaultProps = {
    edges: [],
    pageInfo: {
      hasNextPage: false,
      total: 0,
    },
  };

  state = {
    openInviteDialog: false,
    profileShare: undefined,
  };

  handleMore = () => {
    const { search, query, edges } = this.props;
    search(new Query(query), edges[edges.length - 1].cursor);
  };

  showInviteDialog = () => this.setState({ openInviteDialog: true });

  hideInviteDialog = () => this.setState({ openInviteDialog: false });

  handleAddToNetwork = async ({
    networkId,
    value: { id, firstName, lastName, email },
    teamNote,
    invitationMessage,
  }) => {
    const { inviteUser, notify } = this.props;

    try {
      await inviteUser({
        collectionType: 'internal_network_experts',
        collectionId: networkId,
        email,
        profileId: id,
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        role: 'expert',
        teamNote,
        invitationMessage,
      });

      notify(!id ? 'Invite sent!' : 'Add sent');
    } catch (e) {
      notify('Error while adding/inviting experts', 'error');
      Promise.reject(e);
    }
  };

  handleSelectProfile = (profile, selected) =>
    this.props.selectProfile(profile, selected);

  handleProfileShare = async (urlEndpoint) => {
    const { viewer, fetchUser, fetchProfile } = this.props;

    if (!viewer.admin) {
      await fetchUser(viewer.username, {
        groups: true,
        sharedInternalNetworks: true,
      });
    }

    const profileShare = await fetchProfile(urlEndpoint, {
      force: true,
      internalNetworks: true,
    });

    this.setState({ profileShare });
  };

  handleProfileShareClose = () => this.setState({ profileShare: null });

  render() {
    const {
      viewer,
      internalNetworks,
      edges,
      pageInfo,
      fetching,
      error,
      selectedProfiles,
      totalFiltersApplied,
    } = this.props;

    const { profileShare } = this.state;

    const results = edges.map((e) => e.node);

    const hasInternalNetworks = internalNetworks && internalNetworks.length > 0;

    return (
      <div className={s.root}>
        <div className={s.filters}>
          <Filters totalFiltersApplied={totalFiltersApplied} />
        </div>

        <div className={s.results}>
          {error && viewer.admin && <Alert type="error">{error}</Alert>}

          <LongList
            infiniteScroll
            entityName={viewer.admin ? 'Profile' : 'Expert'}
            entityNamePlural={viewer.admin ? 'Profiles' : 'Experts'}
            hasNextPage={pageInfo.hasNextPage}
            onMore={this.handleMore}
            total={pageInfo.total}
            loading={fetching}
            action={
              hasInternalNetworks && (
                <Button
                  color="secondary"
                  startIcon={<FAIcon icon="user-plus" size={16} />}
                  onClick={this.showInviteDialog}
                >
                  Invite Experts
                </Button>
              )
            }
          >
            {results.map((r) => (
              <ProfileSearchResult
                selectable
                key={`result-${r.profile.id}`}
                result={r}
                style={{ marginBottom: 20 }}
                selected={selectedProfiles.some((p) => p.id === r.profile.id)}
                onSelect={this.handleSelectProfile}
                showSource={!viewer.admin}
                showNetworks={viewer.admin}
                onProfileShare={() =>
                  this.handleProfileShare(r.profile.url_endpoint)
                }
                showAdditionalInformation={viewer.admin}
              />
            ))}
          </LongList>
          <SearchPromo
            style={{ marginTop: 40 }}
            title="Don’t see who you’re looking for?"
            label="Find Experts"
            href="/request_expert"
          >
            <p>
              Access the OnFrontiers expert marketplace to grow your
              organization’s knowledge network
            </p>
          </SearchPromo>
          {hasInternalNetworks && (
            <AddToNetworkDialog
              open={this.state.openInviteDialog}
              networks={internalNetworks}
              onAdd={this.handleAddToNetwork}
              onDone={this.hideInviteDialog}
              onlyInvites={!viewer.admin}
            />
          )}
        </div>
        <SelectedProfilesBar
          onRemove={(profile) => this.props.selectProfile(profile, false)}
          onClear={() => this.props.clearProfileSelection()}
          profiles={selectedProfiles}
        >
          <AddToExpertRequestButton
            color="teal"
            icon={false}
            profiles={selectedProfiles}
            style={{ marginRight: 10, textTransform: 'initial' }}
            onExpertAdd={() => this.props.clearProfileSelection()}
            form="addExpertsToExpertRequest"
            size="medium"
          />
          <AddProfileKeywordsButton
            profiles={selectedProfiles}
            size="medium"
            style={{ marginRight: 10, textTransform: 'initial' }}
          />
          <InternalNetworkShareButton
            profiles={selectedProfiles}
            size="medium"
            style={{ marginRight: 10, textTransform: 'initial' }}
          />
        </SelectedProfilesBar>
        {profileShare && (
          <InternalNetworkShareSingleProfile
            open
            onClose={this.handleProfileShareClose}
            profile={profileShare}
          />
        )}
      </div>
    );
  }
}

export default connect(
  (state) => {
    const { viewer, groups } = state;
    const { hash } = state.search.query;
    const search = state.search.queries[hash] || {};

    const networkGroups =
      groups.networks &&
      groups.networks.edges &&
      groups.networks.edges.map((e) => e.node);

    const internalNetworks =
      networkGroups &&
      networkGroups.map((g) => g.internal_network).filter(Boolean);

    return {
      viewer,
      edges: search.edges,
      pageInfo: search.pageInfo,
      fetching: search.fetching,
      query: state.search.query,
      error: state.search.error,
      selectedProfiles: state.search.selectedProfiles,
      expertRequests: state.expertRequests.open,
      internalNetworks,
    };
  },
  {
    search,
    selectProfile,
    clearProfileSelection,
    addNetworkExperts,
    inviteUser,
    notify,
    fetchUser,
    fetchProfile,
  }
)(Experts);

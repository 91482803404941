import React, { useMemo } from 'react';
import Table from '@mui/material/Table';
import TableHeader from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import makeStyles from '@mui/styles/makeStyles';
import Tooltip from 'rc-tooltip';
import moment from 'moment-timezone';
import { money } from '../../core/money';

const useStyles = makeStyles(() => ({
  tooltip: {
    wordBreak: 'break-all',
    maxWidth: 250,
  },
}));

function TransactionList({ transactions = [], amountColumn }) {
  const s = useStyles();

  const showTrackingCode = useMemo(
    () =>
      transactions.reduce(
        (previous, val) => previous || val.tracking_code,
        false
      ),
    [transactions]
  );

  return (
    <Table>
      <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
        <TableRow>
          <TableCell style={{ textTransform: 'none', width: '80px' }}>
            Date
          </TableCell>
          <TableCell style={{ textTransform: 'none' }}>Description</TableCell>
          {showTrackingCode && (
            <TableCell style={{ textTransform: 'none', width: '60px' }}>
              Code
            </TableCell>
          )}
          <TableCell style={{ textTransform: 'none', width: '60px' }}>
            State
          </TableCell>
          <TableCell
            style={{
              textTransform: 'none',
              width: '70px',
              textAlign: 'right',
            }}
          >
            {amountColumn.header}
          </TableCell>
        </TableRow>
      </TableHeader>
      <TableBody displayRowCheckbox={false} showRowHover>
        {transactions.map((tx) => (
          <TableRow key={tx.id}>
            <TableCell style={{ width: '80px' }}>
              {moment(tx.created_at).format('DD MMM YYYY')}
            </TableCell>
            <TableCell>{tx.description}</TableCell>
            {showTrackingCode && (
              <TableCell style={{ width: '60px' }}>
                <Tooltip
                  overlay={<div className={s.tooltip}>{tx.tracking_code}</div>}
                  trigger={['hover', 'click']}
                  placement="left"
                >
                  <div>{tx.tracking_code}</div>
                </Tooltip>
              </TableCell>
            )}
            <TableCell style={{ width: '70px' }}>
              {formatTransactionState(tx.state)}
            </TableCell>
            <TableCell style={{ textAlign: 'right', width: '70px' }}>
              {money(tx[amountColumn.field]).formatted()}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default TransactionList;

function formatTransactionState(status) {
  switch (status) {
    case 'on_hold':
      return 'On Hold';
    case 'completed':
      return 'Complete';
    case 'canceled':
      return 'Canceled';
    default:
      return status;
  }
}

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Divider from '../../components/Divider';
import LayoutPage from '../../components/Layout/LayoutPage';
import MediaQuery from '../../components/MediaQuery';
import ProjectBar from '../../components/ProjectBar';
import RequestDetails from '../../components/Forms/ExpertRequest/DetailsNew';
import JoinCall from './JoinCall';
import CallDetails from './sections/CallDetails';
import FeedbackDetails from './sections/FeedbackDetails';
import WrittenResponse from './sections/WrittenResponse';
import Header from './Header/Header';
import Dialogs from './Dialogs';
import ExpertRequestDetails from './sections/ExpertRequestDetails';
import Transcript from './Transcript';
import Metrics from './metrics/Metrics';
import { formatDateTime } from '../../core/util';
import { addRegistrant, getJoinInfo } from '../../actions/call';
import {
  deleteConsultation,
  dismissConsultationReview,
  fetchConsultation,
  orderTranscript,
  updateConsultation,
  Trigger,
  OUTDATED_ERROR,
  ALREADY_CONFIRMED,
  ALREADY_CANCELED,
  ALREADY_STARTED,
  NOT_ENOUGH_CREDITS,
  engagementTypes,
} from '../../actions/consultation';
import { inviteUser } from '../../actions/invitation';
import history from '../../core/history';
import { openFileDialog, notify, hideMessage } from '../../actions/ui';
import { SCREEN_SM } from '../../constants';
import s from './Consultation.module.scss';

const userNotAvailable = '(User not available)';

const getExpertName = (c) =>
  c.expert ? c.expert.name : c.expert_name || userNotAvailable;

function getUserName(viewer, consultation) {
  const { expert } = consultation;
  const isViewerExpert = expert && viewer.id === expert.id;
  return isViewerExpert
    ? consultation.requester_name
    : getExpertName(consultation);
}

class Consultation extends PureComponent {
  static propTypes = {
    viewer: PropTypes.object,
  };

  static contextTypes = {
    store: PropTypes.shape({
      dispatch: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      openDenyDialog: false,
      openConfirmDialog: false,
      openCancelDialog: false,
      openSuggestTime: false,
      openCompleteTraining: !!props.openCompleteTraining,
      openReviewCall: !!props.openReviewCall,
      openRequestTranscript: !!props.openRequestTranscript,
      openAttachToExpertRequest: false,
      openInviteParticipant: false,
      date: props.timeChosen !== 'undefined' ? props.timeChosen : undefined,
      submittingTranscript: false,
      joinInfo: undefined,
    };
  }

  async componentDidMount() {
    const { viewer, openSuggestTime } = this.props;
    const { store } = this.context;
    if (openSuggestTime) this.handleOpenSuggestTime();
    this.handleUpdateJoinInfo();

    await Promise.all(
      RequestDetails.fetch.map((f) => store.dispatch(f({ viewer })))
    );
  }

  handleUpdateJoinInfo = async () => {
    const { consultation } = this.props;
    if (
      consultation?.conference?.carrier === 'zoom' &&
      consultation.conference?.id
    ) {
      this.setState({
        joinInfo: await this.props.getJoinInfo(consultation.conference.id),
      });
    }
  };

  handleAccept = () => {
    const { consultation, viewer } = this.props;
    const { expert } = consultation;
    const isViewerExpert = expert && viewer.id === expert.id;
    const hasCompletedComplianceTraining =
      expert && !!expert.compliance_completed_at;

    if (isViewerExpert) {
      if (!hasCompletedComplianceTraining) {
        return this.setState({ openCompleteTraining: true });
      }
      if (consultation.engagement_type === 'opportunity') {
        return this.setState({ openConfirmDialog: true });
      }
    }

    return this.handleConfirm();
  };

  showCompleteTraining = (emptyDate) => {
    const { consultation } = this.props;
    const { date } = this.state;
    const dateSelected = date || consultation.proposed_times[0];

    return this.setState({
      openCompleteTraining: true,
      date: emptyDate ? undefined : dateSelected,
    });
  };

  handleConfirm = () => {
    const { consultation, viewer } = this.props;
    const { date } = this.state;

    this.setState({ openConfirmDialog: false });
    this.props.hideMessage();

    if (!date) {
      return this.props.notify('You need to select a suggested time.', 'error');
    }

    const userName = getUserName(viewer, consultation);

    this.props
      .updateConsultation({
        id: consultation.id,
        starts_at: this.state.date,
        state: 'confirmed',
        trigger: Trigger.consultationPage,
      })
      .then(
        () => {
          this.props.notify(
            `You accepted ${userName}'s suggested call time of ${formatDateTime(
              date,
              viewer.timezone
            )}.`,
            'success'
          );
          this.handleUpdateJoinInfo();
        },
        (err) => {
          if (err.message === OUTDATED_ERROR) {
            this.props.notify(
              'Cannot schedule the event for a past date.',
              'error'
            );
            this.props.fetchConsultation(consultation.id);
          } else if (err.message === ALREADY_CONFIRMED) {
            this.props.notify('Consultation already confirmed.', 'error');
            this.props.fetchConsultation(consultation.id);
          } else if (err.message === ALREADY_CANCELED) {
            this.props.notify('Consultation already canceled.', 'error');
            this.props.fetchConsultation(consultation.id);
          } else {
            this.props.notify(
              'An error occurred when accepting the consultation.',
              'error'
            );
          }
        }
      );
  };

  handleDeny = (values) => {
    this.props.hideMessage();

    this.setState({
      openDenyDialog: false,
      date: null,
    });

    this.props
      .updateConsultation({
        id: this.props.consultation.id,
        state: 'denied',
        cancel_reason: values.cancel_reason || '',
        trigger: Trigger.consultationPage,
      })
      .catch(() => {
        this.props.notify(
          'An error occurred when canceling consultation.',
          'error'
        );
      });
  };

  handleDelete = () => {
    const { consultation, deleteConsultation } = this.props;
    deleteConsultation(consultation.id)
      .then(() => {
        history.push('/consultations');
      })
      .catch((err) => {
        this.props.notify('Error when deleting consultation', 'error');
        throw err;
      });
  };

  handleCancel = (values) => {
    this.props.hideMessage();

    this.setState({
      openCancelDialog: false,
      date: null,
    });

    this.props
      .updateConsultation({
        id: this.props.consultation.id,
        state: 'canceled',
        cancel_reason: values.cancel_reason || '',
        trigger: Trigger.consultationPage,
      })
      .catch((err) => {
        const message =
          err && err.message === ALREADY_STARTED
            ? 'Cannot cancel ongoing consultation.'
            : 'An error occurred when canceling consultation.';
        this.props.notify(message, 'error');
      });
  };

  handleInviteParticipant = async (values) => {
    const { consultation, viewer } = this.props;

    this.setState({
      openInviteParticipant: false,
    });

    const conferenceId = consultation.conference?.id;
    if (!conferenceId) {
      this.props.notify('Error inviting participant. No conference', 'error');
      return;
    }

    try {
      await this.props.addRegistrant(consultation, {
        conferenceId,
        name: values.name,
        email: values.email,
        invited_by: viewer.name,
      });

      if (values.inviteJoinTeam) {
        const groupId =
          consultation.group?.id ||
          consultation.expert_request?.project?.group?.id;
        if (!groupId) {
          this.props.notify(
            'Invited participant but error inviting to group',
            'error'
          );
          return;
        }
        await this.props.inviteUser({
          email: values.email,
          collectionType: 'group',
          collectionId: groupId,
          role: 'member',
        });
      }
      this.props.notify('Successfully invited participant');
    } catch (ex) {
      this.props.notify('Error inviting participant', 'error');
      throw ex;
    }
  };

  handleSuggestTime = (values) => {
    if (!values || !values.dates) return;

    this.setState({
      openSuggestTime: false,
      date: null,
    });

    const { viewer, consultation } = this.props;
    const isExpert = viewer.id === consultation.expert.id;
    const userName = getUserName(viewer, consultation);

    this.props
      .updateConsultation({
        id: consultation.id,
        proposed_times: values.dates.filter(Boolean),
        duration: values.duration,
        state: isExpert ? 'negotiating_client_time' : 'negotiating_expert_time',
        trigger: Trigger.consultationPage,
      })
      .then(() => {
        this.props.notify(`Sent time suggestion to ${userName}.`, 'success');
      })
      .catch((err) => {
        const message =
          err && err.message === ALREADY_STARTED
            ? 'Cannot reschedule ongoing consultation.'
            : err && err.message === NOT_ENOUGH_CREDITS
              ? 'Not enough credits to reschedule the consultation.'
              : 'An error occurred when rescheduling consultation.';
        this.props.notify(message, 'error');
      });
  };

  handleRequestTranscript = () => {
    this.props.hideMessage();

    const { consultation } = this.props;
    const { id, transcription_price: transcriptionPrice } = consultation;

    this.setState({ submittingTranscript: true });

    this.props
      .orderTranscript(id, transcriptionPrice)
      .then(() => {
        this.props.notify('Transcript requested.', 'success');
        this.setState({
          openRequestTranscript: false,
          submittingTranscript: false,
        });
      })
      .catch((e) => {
        this.setState({ submittingTranscript: false });

        if (
          e.message === 'GraphQL Error: maximum amount of credits not provided'
        ) {
          this.props.notify(
            'This consultation has an invalid duration and ' +
            'cannot be transcribed at this time. ' +
            'A member of the engineering team has been notified.',
            'error'
          );
          return;
        }

        if (
          e.message ===
          'GraphQL Error: not enough credits to order transcription'
        ) {
          this.props.notify('Not enough credits to order transcript', 'error');
          return;
        }

        this.props.notify(
          'An error occurred when requesting the transcript.',
          'error'
        );
      });
  };

  handleOpenSuggestTime = () => {
    const { consultation, viewer } = this.props;
    const { expert } = consultation;
    const isViewerExpert = expert && viewer.id === expert.id;
    const hasCompletedComplianceTraining =
      expert && !!expert.compliance_completed_at;

    if (isViewerExpert && !hasCompletedComplianceTraining) {
      this.setState({ openCompleteTraining: true });
    } else {
      this.setState({ openSuggestTime: true });
    }
  };

  handleOpenTranscript = () => {
    this.setState({ openRequestTranscript: true });
  };

  dismissReview = () => {
    this.props.dismissConsultationReview(this.props.consultation.id, true);
    this.setState({ openReviewCall: false });
  };

  closeDialogs = () => {
    this.setState({
      openDenyDialog: false,
      openCompleteTraining: false,
      openConfirmDialog: false,
      openCancelDialog: false,
      openSuggestTime: false,
      openReviewCall: false,
      openRequestTranscript: false,
      openAttachToExpertRequest: false,
      openInviteParticipant: false,
    });
  };

  render() {
    const {
      consultation,
      viewer,
      userContext,
      section,
      initialUsefulnessRating,
      initialMatchExperienceRating,
    } = this.props;

    if (!consultation) return null;

    const {
      id,
      expert_request: expertRequest,
      requester = {},
      expert = {},
      state,
      transcription,
      client_review: clientReview,
      expert_review: expertReview,
      external,
    } = consultation;

    const requesterName = consultation.requester_name;
    const expertName = getExpertName(consultation);

    const isViewerExpert = expert && viewer.id === expert.id;
    const isExpertActive = expert && expert.expert_state === 'active';
    const isViewerRequester = requester && viewer.id === requester.id;
    const isViewerAdmin = viewer.admin && !isViewerExpert && !isViewerRequester;
    const user = isViewerExpert ? requester : expert;

    const isExpired = state === 'expired';
    const isCompleted = state === 'completed';
    const isConfirmed = state === 'confirmed';
    const isCanceled = state === 'canceled';
    const isDenied = state === 'denied';
    const isIncomplete = state === 'incomplete';

    const isWaitingExpertConfirmation = state === 'negotiating_expert_time';
    const isWaitingRequesterConfirmation = state === 'negotiating_client_time';
    const isWaitingConfirmation =
      isWaitingExpertConfirmation || isWaitingRequesterConfirmation;
    const isWaitingViewerConfirmation =
      (isViewerExpert && isWaitingExpertConfirmation) ||
      (!isViewerExpert && isWaitingRequesterConfirmation);

    const isWrittenConsultation =
      consultation.engagement_type === engagementTypes.writtenResponse;
    const showDetails =
      isConfirmed || isCompleted || isCanceled || isIncomplete;
    const canEdit = isViewerRequester || isViewerExpert || isViewerAdmin;
    const canConfirm = isExpertActive && isWaitingViewerConfirmation && canEdit;
    const canCancel =
      isExpertActive &&
      (isConfirmed || (!isViewerExpert && isWaitingConfirmation)) &&
      canEdit;
    const canDeny = isExpertActive && isViewerExpert && isWaitingConfirmation;
    const canReschedule =
      isExpertActive && (isConfirmed || isExpired || isIncomplete);

    const hasReview = !!(isViewerExpert
      ? clientReview && clientReview.id
      : expertReview && expertReview.id);
    const shouldReview =
      !external && !isWrittenConsultation && isCompleted && !hasReview;

    const baseProps = {
      consultation,
      expertRequest,
      requesterName,
      expertName,
      isViewerExpert,
      isExpertActive,
      isViewerRequester,
      isViewerAdmin,
      user,
      isExpired,
      isCompleted,
      isConfirmed,
      isDenied,
      isIncomplete,
      isWaitingConfirmation,
      isWaitingExpertConfirmation,
      isWaitingRequesterConfirmation,
      isWaitingViewerConfirmation,
      isWrittenConsultation,
      canEdit,
      canConfirm,
      canCancel,
      canDeny,
      canReschedule,
      shouldReview,
      hasReview,
    };

    const showRequest = !!expertRequest;
    const showTranscription =
      !isWrittenConsultation &&
      !isViewerExpert &&
      transcription &&
      transcription.monologues &&
      transcription.monologues.length > 0;
    const showFeedback = hasReview;

    const showMetrics =
      !isWrittenConsultation &&
      consultation.conference &&
      consultation.conference.carrier &&
      viewer.admin &&
      [
        'confirmed',
        'in_progress',
        'finalizing',
        'completed',
        'incomplete',
      ].includes(state);
    const showTabs =
      showRequest || showTranscription || showFeedback || showMetrics;

    return (
      <MediaQuery maxWidth={SCREEN_SM}>
        {(isMobileVersion) => {
          const CallActions = !isWrittenConsultation && (
            <JoinCall
              consultation={consultation}
              joinInfo={this.state.joinInfo}
            />
          );

          return (
            <LayoutPage
              showNav
              selected="consultations"
              footerStyle={{
                paddingBottom: isMobileVersion && CallActions ? 110 : 0,
              }}
              showReviewConsultation={!this.props.openReviewCall}
            >
              {viewer.admin && expertRequest && (
                <ProjectBar viewer={viewer} project={expertRequest.project} />
              )}

              <Header
                {...baseProps}
                isMobileVersion={isMobileVersion}
                callActions={CallActions}
                onReviewCall={() => this.setState({ openReviewCall: true })}
                onDelete={consultation.external && this.handleDelete}
                shouldReview={shouldReview}
              />

              <Divider spacing={30} />

              {showTabs && (
                <Tabs value={section} style={{ marginBottom: 30 }}>
                  <Tab
                    onClick={() => history.push(`/consultation/${id}`)}
                    value={
                      isWrittenConsultation
                        ? 'response_details'
                        : 'call_details'
                    }
                    label={
                      isWrittenConsultation
                        ? 'Response Details'
                        : 'Call Details'
                    }
                  />
                  {showRequest && (
                    <Tab
                      onClick={() =>
                        history.push(`/consultation/${id}/request`)
                      }
                      value="request"
                      label="Request Details"
                    />
                  )}
                  {showTranscription && (
                    <Tab
                      onClick={() =>
                        history.push(`/consultation/${id}/transcript`)
                      }
                      value="transcript"
                      label="Transcript"
                    />
                  )}
                  {showFeedback && (
                    <Tab
                      onClick={() =>
                        history.push(`/consultation/${id}/feedback`)
                      }
                      value="feedback"
                      label="Feedback"
                    />
                  )}
                  {showMetrics && (
                    <Tab
                      onClick={() =>
                        history.push(`/consultation/${id}/metrics`)
                      }
                      value="metrics"
                      label="Metrics"
                    />
                  )}
                </Tabs>
              )}

              <div className={s.consultationContent}>
                {section === 'call_details' && (
                  <CallDetails
                    {...baseProps}
                    viewer={viewer}
                    userContext={userContext}
                    joinInfo={this.state.joinInfo}
                    showDetails={showDetails}
                    isMobileVersion={isMobileVersion}
                    ongoingCall={this.props.call.connected}
                    openReviewCall={this.state.openReviewCall}
                    onRequestTranscript={this.handleOpenTranscript}
                    selectedDate={this.state.date}
                    onOpenSuggestTime={this.handleOpenSuggestTime}
                    onDateSelect={(date) => this.setState({ date })}
                    onConfirm={this.handleAccept}
                    onCancel={() => this.setState({ openCancelDialog: true })}
                    onDeny={() => this.setState({ openDenyDialog: true })}
                    onReschedule={() =>
                      this.setState({ openSuggestTime: true })
                    }
                    onInviteParticipant={() =>
                      this.setState({ openInviteParticipant: true })
                    }
                  />
                )}
                {section === 'response_details' && (
                  <WrittenResponse
                    {...baseProps}
                    viewer={viewer}
                    isMobileVersion={isMobileVersion}
                    showCompleteTraining={this.showCompleteTraining}
                  />
                )}
                {section === 'request' && (
                  <ExpertRequestDetails
                    consultation={consultation}
                    isViewerExpert={isViewerExpert}
                    isMobileVersion={isMobileVersion}
                  />
                )}
                {!isViewerExpert && section === 'transcript' && (
                  <Transcript
                    transcription={transcription}
                    expertId={expert && expert.id}
                  />
                )}
                {showFeedback && section === 'feedback' && (
                  <FeedbackDetails
                    clientReview={clientReview}
                    expertReview={expertReview}
                    isViewerExpert={isViewerExpert}
                    isViewerRequester={isViewerRequester}
                    requester={requester}
                    expert={expert}
                  />
                )}
                {showMetrics && section === 'metrics' && (
                  <Metrics consultation={consultation} />
                )}
              </div>

              {isMobileVersion && CallActions && (
                <div className={s.stickyCallActions}>{CallActions}</div>
              )}

              <Dialogs
                user={user}
                consultation={consultation}
                date={this.state.date}
                viewer={viewer}
                isViewerExpert={isViewerExpert}
                onDismissReview={this.dismissReview}
                onCloseDialog={this.closeDialogs}
                onDeny={this.handleDeny}
                onCancel={this.handleCancel}
                onConfirm={this.handleConfirm}
                onReview={this.handleReview}
                onSuggestTime={this.handleSuggestTime}
                onRequestTranscript={this.handleRequestTranscript}
                onInviteParticipant={this.handleInviteParticipant}
                openDenyDialog={this.state.openDenyDialog}
                openConfirmDialog={this.state.openConfirmDialog}
                openCancelDialog={this.state.openCancelDialog}
                openCompleteTraining={this.state.openCompleteTraining}
                openReviewCall={this.state.openReviewCall}
                openSuggestTime={this.state.openSuggestTime}
                openRequestTranscript={this.state.openRequestTranscript}
                openInviteParticipant={this.state.openInviteParticipant}
                submittingTranscript={this.state.submittingTranscript}
                openAttachToExpertRequest={this.state.openAttachToExpertRequest}
                onAttachToExpertRequest={this.handleAttachToExpertRequest}
                initialUsefulnessRating={initialUsefulnessRating}
                initialMatchExperienceRating={initialMatchExperienceRating}
                shouldReview={shouldReview}
                isWrittenConsultation={isWrittenConsultation}
              />
            </LayoutPage>
          );
        }}
      </MediaQuery>
    );
  }
}

Consultation = Consultation;

Consultation = connect(
  (state, ownProps) => {
    const consultation =
      state.consultations.default &&
      state.consultations.default.edges &&
      state.consultations.default.edges.find(
        (c) => c.node.id === ownProps.consultationId
      );
    return {
      viewer: state.viewer,
      call: state.call,
      userContext: state.ui.userContext,
      consultation: consultation && consultation.node,
    };
  },
  {
    addRegistrant,
    deleteConsultation,
    dismissConsultationReview,
    fetchConsultation,
    hideMessage,
    inviteUser,
    getJoinInfo,
    notify,
    openFileDialog,
    orderTranscript,
    updateConsultation,
  }
)(Consultation);

export default Consultation;

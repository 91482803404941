import config from '../../../config';

export default {
  subdomain: '*',
  path: '/team/:groupId/settings/billing',

  async action({ store, graphql, query, path }, params, { subdomain }) {
    if (!config.enableBillingPortal) {
      return;
    }

    const { groupId } = params;

    let result = await graphql.query(
      `query getGroup($id: String!) {
          group(id: $id) {
            billing_account {
              id
            }
          }
        }`,
      { id: groupId }
    );

    const accountId = result.group.billing_account.id;

    result = await graphql.mutate(
      `(
          $accountId: String!
          $path: String!
        ) {
          createPortalURL(
            account_id: $accountId
            path: $path
          )
        }`,
      { accountId, path: `/team/${groupId}/settings` }
    );

    return {
      redirect: result.createPortalURL,
    };
  },
};

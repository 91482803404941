import React from 'react';
import Link from '../Link';
import s from './ActivityLog.module.scss';

function UserRef({ user, link }) {
  if (!user) return null;
  if (link)
    return (
      <Link className={s.userName} to={user.html_url}>
        {user.name}
      </Link>
    );
  return <span className={s.userName}>{user.name}</span>;
}

UserRef = UserRef;

export default UserRef;

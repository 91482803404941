import moment from 'moment-timezone';
import { sprintf } from 'sprintf-js';
import Duration from './duration';
import timezones from '../components/SelectTimezone/timezones';

export const dateFormat = 'D MMM YYYY';

function getMomentTimezone(timezone) {
  return moment.tz(moment.tz.zone(timezone) ? timezone : moment.tz.guess());
}

export function getTimezoneDescription(timezone) {
  return `(${getGMTOffset(timezone)}) ${getTimezoneName(timezone)}`;
}

function getTimezoneName(timezone) {
  const momentTimezone = getMomentTimezone(timezone);
  const timezoneObject = timezones.find((t) => t[0] === timezone);
  const timezoneDescription = timezoneObject
    ? timezoneObject[1]
    : momentTimezone.format('z');
  return `${timezoneDescription}`;
}

export function getGMTOffset(timezone) {
  const momentTimezone = getMomentTimezone(timezone);
  const timezoneOffset = momentTimezone.format('Z');
  return `GMT${timezoneOffset}`;
}

export function isRedZone(time, timezone) {
  try {
    const hours = moment.tz(time, timezone).hours();
    return hours >= 22 || hours < 8;
  } catch (e) {
    return false;
  }
}

export function getUserTime(time, timezone, firtName) {
  try {
    return `${moment
      .tz(time, timezone)
      .format('h:mma')
      .toUpperCase()} for ${firtName}`;
  } catch (e) {
    return '';
  }
}

export function formatSeconds(seconds) {
  const hr = parseInt(seconds / 3600);
  if (hr > 0) {
    let sec = seconds % 3600;
    const min = sec / 60;
    sec = Math.abs(sec % 60);
    return sprintf('%02d:%02d:%02d', hr, min, sec);
  }

  const min = seconds / 60;
  const sec = Math.abs(seconds % 60);
  return sprintf('%02d:%02d', min, sec);
}

export class TimeRange {
  // Using moment instead of Date
  constructor(start, end) {
    if (start.isAfter(end))
      throw new Error('TimeRange start must be before or same as end');
    this.start = start;
    this.end = end;
  }

  overlap(r2) {
    let { start } = this;
    let { end } = this;

    if (r2.start.isAfter(this.start)) {
      start = r2.start;
    }

    if (r2.end.isBefore(this.end)) {
      end = r2.end;
    }

    return start && end && start.isBefore(end)
      ? new TimeRange(start, end)
      : undefined;
  }

  duration() {
    return new Duration(this.end.diff(this.start));
  }

  overlaps(r2) {
    return !!this.overlap(r2);
  }

  isBefore(range) {
    return this.start.isBefore(range.start);
  }

  add(quantity, unit) {
    this.start.add(quantity, unit);
    this.end.add(quantity, unit);
  }
}

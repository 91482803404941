import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { MenuItem } from '@mui/material';
import IconMenu from '../IconMenu';
import MemberList from '../MemberList';
import MediaQuery from '../MediaQuery';
import Link from '../Link';
import CountBox from '../CountBox';
import { formatCreditsText } from '../../core/util';
import { SCREEN_SM } from '../../constants';
import s from './TeamPreview.module.scss';

class TeamNav extends PureComponent {
  render() {
    const { team } = this.props;
    const { billing_account: billingAccount } = team;

    return (
      <IconMenu>
        <Link href={team.html_url}>
          <MenuItem>View Team</MenuItem>
        </Link>
        {billingAccount && (
          <Link href={`${team.html_url}/settings/billing`}>
            <MenuItem>Billing</MenuItem>
          </Link>
        )}
      </IconMenu>
    );
  }
}

function TeamPreview({ team }) {
  const {
    name,
    html_url: htmlUrl,
    stats,
    billing_account: billingAccount,
  } = team;
  const balance = billingAccount && billingAccount.credit_balance;
  const credits = (balance && balance.cents) || 0;

  const total =
    team.members && team.members.pageInfo ? team.members.pageInfo.total : 0;
  const expertRequestCount = stats ? stats.expert_request_count : 0;
  const consultationCount = stats ? stats.consultation_count : 0;

  return (
    <MediaQuery maxWidth={SCREEN_SM}>
      {(isMobileVersion) => {
        const members =
          team.members && !isMobileVersion
            ? team.members.edges.map((e) => e.node)
            : [];

        return (
          <div className={s.root}>
            <div className={s.header}>
              <h3 className={s.name}>
                <Link href={htmlUrl}>{name}</Link>
              </h3>
              <MemberList
                members={members}
                membersDisplayLimit={isMobileVersion ? 0 : 4}
                total={total}
                htmlUrl={htmlUrl}
              />
            </div>

            <div className={s.subHeader}>{formatCreditsText(credits)}</div>

            <div className={s.footer}>
              <div className={s.counters}>
                <CountBox
                  grayOutZero
                  className={s.countBox}
                  count={expertRequestCount}
                  label="Requests"
                  linkTo={htmlUrl}
                />
                <CountBox
                  grayOutZero
                  className={s.countBox}
                  count={consultationCount}
                  label="Calls"
                  linkTo={htmlUrl}
                />
              </div>

              <TeamNav team={team} />
            </div>
          </div>
        );
      }}
    </MediaQuery>
  );
}

TeamPreview = TeamPreview;

export default connect((state) => ({
  viewer: state.viewer,
}))(TeamPreview);

import React from 'react';
import cx from 'classnames';
import Body from '../Body';
import SectionTitle from './SectionTitle';
import s from './MarketingSection.module.scss';

function MarketingSection({
  style,
  className,
  darkBlue = false,
  darkGreen = false,
  black = false,
  children,
  bodyStyle,
  bodyClassName,
  preTitle,
  title,
  subTitle,
  preTitleStyle,
  titleStyle,
  subTitleStyle,
  padding = 80,
  titleMargin,
  ...other
}) {
  const classNames = cx(s.root, {
    [className]: !!className,
    [s.darkBlue]: darkBlue,
    [s.darkGreen]: darkGreen,
    [s.black]: black,
  });

  const sectionStyle = {
    padding: `${padding}px 0`,
    ...style,
  };

  return (
    <section className={classNames} style={sectionStyle} {...other}>
      {/*
        Using overflow: hidden by default due to grid system limitations
        https://material-ui.com/layout/grid/#negative-margin
      */}
      <Body
        className={bodyClassName}
        style={{ overflow: 'hidden', paddingBottom: 4, ...bodyStyle }}
      >
        <SectionTitle
          preTitle={preTitle}
          title={title}
          subTitle={subTitle}
          preTitleStyle={preTitleStyle}
          titleStyle={titleStyle}
          subTitleStyle={subTitleStyle}
          titleMargin={titleMargin}
        />
        {children}
      </Body>
    </section>
  );
}

export default MarketingSection;

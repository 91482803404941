import createDOMPurify from 'dompurify';
import queryString from 'query-string';
import moment from 'moment-timezone';
import { DOMParser } from 'xmldom';
import { PhoneNumberUtil } from 'google-libphonenumber';
import keycode from 'keycode';
import { URL } from './url';
import { SCREEN_XS, SCREEN_SM, SCREEN_MD, SCREEN_LG } from '../constants';
import { hiddenConfig } from '../../config';
import window from './window';

const DOMPurify = createDOMPurify(window);

// From backbone
let idCounter = 0;
export function uniqueId(prefix) {
  const id = `${++idCounter}`;
  return prefix ? prefix + id : id;
}

const takeRate = 0.2;
const moneyPerCredit = 200;
const roundRate = 25;
const roundCredit = 5;

export function formatCredits(amount) {
  return (amount / 100.0)
    .toFixed(2)
    .replace(/(\..*?)(0+)$/, '$1')
    .replace(/\.?$/, '');
}

export function formatCreditsText(amount) {
  return `${formatCredits(amount)} ${
    amount / 100 === 1 ? 'credit' : 'credits'
  }`;
}

export function capitalize(str) {
  if (!str) return str;
  return str[0].toUpperCase() + str.slice(1);
}

export function absoluteUrl(path) {
  return new URL(path, window.location.href).href;
}

// same rule used to create transactions
export function roundOffCredits(creditRate = 0) {
  return roundCredit * Math.ceil(creditRate / roundCredit);
}

/**
 * Computes the number of credits from a bill rate
 * @param {int} bill rate in cents
 */
export function rateToCredits(rate = 0) {
  const totalRate = rate / (1.0 - takeRate);
  const n = Math.trunc(totalRate / moneyPerCredit);
  let d = Math.floor(n / roundRate);

  if (n % roundRate > 0) {
    d++;
  }

  return d * roundRate;
}

export function formatBillRate(rate = 0) {
  return `$${(rate / 100).toFixed(2)} per hour`;
}

export function formatLocation(city, country) {
  return [city, country].filter((e) => e).join(', ');
}

export const dateFormat = 'dddd D MMM YYYY';

export function formatDateTime(date, timezone) {
  const momentTimezone = moment.tz(
    date,
    moment.tz.zone(timezone) ? timezone : moment.tz.guess()
  );
  return `${momentTimezone.format(dateFormat)} • ${momentTimezone.format(
    'h:mm a'
  )}`;
}

export function formatDate(date, timezone) {
  const momentTimezone = moment.tz(
    date,
    moment.tz.zone(timezone) ? timezone : moment.tz.guess()
  );
  return momentTimezone.format(dateFormat);
}

let _localStorage;
function localStorage() {
  if (typeof _localStorage !== 'undefined') {
    return _localStorage;
  }

  _localStorage = typeof window !== 'undefined' && window.localStorage;

  if (_localStorage) {
    try {
      _localStorage.setItem('localStorage', 1);
      _localStorage.removeItem('localStorage');
    } catch (e) {
      console.warn('browser does not support local storage');
      _localStorage = undefined;
    }
  }

  if (!_localStorage) {
    _localStorage = null;
  }

  return _localStorage;
}

export function getCache(key) {
  const storage = localStorage();
  if (storage && storage[key]) {
    return JSON.parse(storage[key]);
  }
}

export function setCache(key, obj) {
  const storage = localStorage();
  if (storage) {
    if (obj) {
      storage[key] = JSON.stringify(obj);
    } else {
      delete storage[key];
    }
  }
}

export function clearCache(key) {
  setCache(key, undefined);
}

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export function isEmailValid(email) {
  return emailRegex.test(email);
}

export function isPhoneValid(phone) {
  const phoneUtil = PhoneNumberUtil.getInstance();
  try {
    return phoneUtil.isValidNumber(phoneUtil.parse(phone));
  } catch (err) {
    return false;
  }
}

export const normalizePhone = (value = '') => value.replace(/[^\d-\s+()]/g, '');

// Replace multiple spaces with a single space
// also remove a single space at the beginning
export const normalizeSpace = (value = '') =>
  value.replace(/^\s+|\s+(?=\s)/g, '');

// Replace new lines with an empty string
export const normalizeNewLines = (v = '') => v.replace(/\n/g, '');

// KT: From Java -- for deterministic ids
export function hashCode(s) {
  let hash = 0;
  for (let i = 0; i < s.length; i++) {
    const chr = s.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // convert to 32bit integer
  }
  return hash;
}

export function urljoin(base, input) {
  try {
    new URL(input);
    return input;
  } catch (err) {
    // ignore
  }

  const url = new URL(base);
  const { pathname } = url;
  const path = input.startsWith('/') ? input : `${pathname}/${input}`;

  return url.origin + path;
}

export function urlToInternalPath(fullUrl) {
  const url = new URL(fullUrl);
  return url.pathname;
}

export async function getFilestackClient(options = {}) {
  const filestack = await import(
    /* webpackChunkName: "filestack" */
    'filestack-js'
  );
  return filestack.init(hiddenConfig.filestackApiKey, options);
}

export function debounce(f, wait) {
  let timeout;
  let toCall;
  return function (...args) {
    toCall = () => {
      f.apply(this, args);
      toCall = null;
    };
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      if (!toCall) return;
      toCall();
    }, wait);
  };
}

export function htmlToText(html) {
  const parser = new DOMParser();
  const node = parser.parseFromString(`<div>${html}</div>`, 'text/html');
  return node && node.firstChild && node.firstChild.textContent;
}

export function slugify(string) {
  return (string || '')
    .toString()
    .trim()
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');
}

export function getScreenWidth(width) {
  if (!width) return SCREEN_LG;
  if (width >= SCREEN_LG) return SCREEN_LG;
  if (width >= SCREEN_MD) return SCREEN_MD;
  if (width >= SCREEN_SM) return SCREEN_SM;
  return SCREEN_XS;
}

export function shouldResetCollection(
  col = {
    loading: false,
    edges: [],
    pageInfo: { hasNextPage: true },
    resetAt: undefined,
  },
  pageSize,
  expiryInMinutes
) {
  const shouldReset = col.edges.length < pageSize && col.pageInfo.hasNextPage;
  const expired = moment(col.resetAt)
    .add(expiryInMinutes || 5, 'minutes')
    .isBefore(moment());
  return shouldReset || expired;
}

const withinAppRoutes = [
  '/select_domain',
  '/validate_email',
  '/dashboard',
  '/profile',
  '/consultations',
  '/expert_requests',
  '/teams',
  '/team',
  '/settings',
  '/compliance_training',
  '/legal_ack',
  '/password_reset',
  '/blog',
  '/messaging',
  '/change_password',
  '/admin',
  /^\/?.*\/login\/?.*/,
  /^\/?.*\/signup\/?.*/,
  /^\/search\??.*/,
  /^\/messaging\/.*/,
  /^\/profile\/.*/,
  /^\/unregistered_expert\/.*/,
  /^\/consultation\/.*/,
  /^\/project\/.*$/,
  /^\/expert_request\/.*$/,
  /^\/expert_requests\/.*/,
  /^\/profile\/.*$/,
  /^\/team\/.*/,
  /^\/compliance_training\/.*/,
  /^\/legal_ack\/.*/,
  /^\/settings\/.*/,
  /^\/network\/?.*/,
  /^\/admin\/.*/,
];

function matchRoute(pattern, path) {
  if (typeof pattern === 'string') {
    return pattern === path;
  }

  return path.match(pattern);
}

export function isWithinAppRoute(path) {
  return withinAppRoutes.findIndex((pattern) => matchRoute(pattern, path)) >= 0;
}

const UNITS = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

export function prettyBytes(num) {
  if (!Number.isFinite(num)) {
    throw new TypeError(`Expected a finite number, got ${typeof num}: ${num}`);
  }

  const neg = num < 0;

  if (neg) {
    num = -num;
  }

  if (num < 1) {
    return `${(neg ? '-' : '') + num} B`;
  }

  const exponent = Math.min(
    Math.floor(Math.log(num) / Math.log(1000)),
    UNITS.length - 1
  );
  const numStr = Number((num / 1000 ** exponent).toPrecision(3));
  const unit = UNITS[exponent];

  return `${(neg ? '-' : '') + numStr} ${unit}`;
}

export function pathAndQuery(location) {
  return encodeURIComponent(`${location.pathname}${location.search}`);
}

export function getSameOriginPath(urlStr) {
  try {
    const url = new URL(urlStr);
    if (window.location.origin === url.origin) {
      return url.pathname;
    }
  } catch (err) {
    return '';
  }
  return '';
}

export function queryPart(opts) {
  const qstr = queryString.stringify(opts);
  return qstr ? `?${qstr}` : '';
}

export function safeHtml(str, opts) {
  return DOMPurify.sanitize(unescape(str), opts);
}

// A utility function to safely escape JSON for embedding in a <script> tag
// Copied from https://github.com/mhart/react-server-example
export function safeStringify(obj) {
  if (!obj) return '';
  return JSON.stringify(obj)
    .replace(/<\/(script)/gi, '<\\/$1')
    .replace(/<!--/g, '<\\!--')
    .replace(/\u2028/g, '\\u2028') // Only necessary if interpreting as JS, which we do
    .replace(/\u2029/g, '\\u2029'); // Ditto
}

export function safeUrl(str) {
  try {
    const url = new URL(str);
    if (url.protocol === 'http:' || url.protocol === 'https:') {
      return str;
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
  return '';
}

export function highlight(str, { multiline, ...opts } = {}) {
  if (multiline) {
    str = str.split('\n').join('<br />');
  }
  return safeHtml(str, {
    ALLOWED_TAGS: ['em', 'br'],
    ALLOWED_ATTR: [],
    ...opts,
  });
}

export function groupBy(list, keyGetter) {
  const obj = {};
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = obj[key];
    if (collection) {
      collection.push(item);
    } else {
      obj[key] = [item];
    }
  });
  return obj;
}

export function unique(list, keyGetter) {
  return Object.values(groupBy(list, keyGetter)).map((l) => l[0]);
}

export function sortBy(field) {
  return function (p1, p2) {
    const n1 = (p1[field] || '').trim().toLowerCase();
    const n2 = (p2[field] || '').trim().toLowerCase();
    if (n1 < n2) return -1;
    if (n1 > n2) return +1;
    return 0;
  };
}

export function fibonacci(start, count) {
  let lastButOne = 0;
  let last = 0;
  let current = start;

  for (let i = 1; i < count; i++) {
    lastButOne = last;
    last = current;
    current = last + lastButOne;
  }

  return current;
}

// based on https://stackoverflow.com/a/48218209
// but for makeStyles
export function concatDeep(...objects) {
  const isObject = (obj) => obj && typeof obj === 'object';

  return objects.reduce((prev, obj) => {
    Object.keys(obj).forEach((key) => {
      const pVal = prev[key];
      const oVal = obj[key];

      if (isObject(pVal) && isObject(oVal)) {
        prev[key] = concatDeep(pVal, oVal);
      } else {
        prev[key] = `${pVal} ${oVal}`;
      }
    });

    return prev;
  }, {});
}

export function formatDuration(duration, separator = ':') {
  if (!duration) return '';
  const hours = `${duration.hours()}`.padStart(2, '0');
  const minutes = `${duration.minutes() % 60}`.padStart(2, '0');
  return `${hours}${separator}${minutes}`;
}

export function isArrayNotEmpty(a) {
  return Array.isArray(a) && !!a.length;
}

export function prettyName(name) {
  if (!name) return '';
  return name
    .split('.')
    .pop()
    .split('_')
    .reduce(
      (text, t) => `${text} ${t.charAt(0).toUpperCase()}${t.slice(1)}`,
      ''
    )
    .trim();
}

export function isBot(userAgentParsed) {
  return ['app', 'bot', 'headless'].includes(userAgentParsed?.ua?.type);
}

export function isUserApplying(user) {
  return !user.expert_state || user.expert_state === 'applying';
}

export function interceptEnter(e) {
  if (keycode(e) !== 'enter' || e.target.type === 'textarea') return;
  e.preventDefault();
}

export function isEmpty(value) {
  // Always trim and zeros are valid
  value = String(value ?? '').trim();
  return !value;
}

export function normalise(value, max) {
  return value > max ? 100 : (value * 100) / max;
}

// Get the id property for a list of input values, useful for redux-form
// field parse property
export function parseId(values) {
  return values && values.map((value) => value.id).filter(Boolean);
}

// Common Field validation helpers
export function required(value) {
  return value ? undefined : 'Required';
}

// Error message helper
export function errorMessage(value) {
  return value.replace('GraphQL Error: ', '');
}

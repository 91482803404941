import React from 'react';

// --- ITEMS TEMPLATE

const itemDashboard = Object.freeze({
  key: 'dashboard',
  text: 'Dashboard',
  to: '/dashboard',
  icon: 'list',
});

const itemProfile = Object.freeze({
  key: 'profile',
  text: 'Profile',
  to: '/profile',
  icon: 'person',
});

const itemMessaging = Object.freeze({
  key: 'messaging',
  text: 'Messages',
  to: '/messaging',
  icon: 'chat_bubble_outline',
});

const itemCalls = Object.freeze({
  key: 'consultations',
  text: 'Consultations',
  to: '/consultations',
  icon: 'phone',
});

const itemExpertRequest = Object.freeze({
  key: 'expert_requests',
  text: 'Expert Requests',
  to: '/expert_requests',
  icon: 'assignment',
  id: 'expertRequestsAdmin',
});

const itemApplications = Object.freeze({
  key: 'applications',
  text: 'Applications',
  to: '/search?expert_states[]=applied&expert_states[]=applying&sort=created.at&asc=false',
  icon: 'person_add',
  id: 'applicationAdmin',
});

const itemTeam = Object.freeze({
  key: 'teams',
  text: 'Teams',
  to: '/teams',
  icon: 'business',
});

const itemMyClients = Object.freeze({
  key: 'my-network',
  text: 'My Clients',
  to: '/network',
  icon: 'star',
});

const itemMyNetwork = Object.freeze({
  key: 'my-network',
  text: 'My Network',
  to: '/search',
  icon: 'star',
});

const itemBecomeAnExpert = Object.freeze({
  key: 'become-an-expert',
  text: 'Become an Expert',
  to: '/become-an-expert',
  promo: true,
});

// --- BUILDER

const buildItems = ({
  profileUrl,
  unreadMessages,
  userContext,
  isAdmin,
  hasGroups,
  isAccountEnterprise,
  hasExpertState,
}) => {
  const newItemProfile = {
    ...itemProfile,
    to: profileUrl,
  };

  const newItemMessaging = {
    ...itemMessaging,
    text: <span>Messages{unreadMessages}</span>,
    icon: unreadMessages || 'chat_bubble_outline',
  };

  const items = [itemDashboard, newItemProfile, newItemMessaging, itemCalls];

  if (userContext !== 'expert') {
    items.push(itemExpertRequest);
  }

  if (userContext === 'admin' && isAdmin) {
    items.push(itemApplications);
  }

  if (hasGroups) {
    items.push(itemTeam);
  }

  if (userContext === 'expert') {
    items.push(itemMyClients);
  } else if (!isAdmin && isAccountEnterprise) {
    items.push(itemMyNetwork);
  }

  if (!hasExpertState) {
    items.push(itemBecomeAnExpert);
  }

  return items;
};

export default buildItems;

import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Alert from '../Alert';
import AuthLayout from '../AuthLayout';
import { formatAuthPath } from '../../core/auth';
import history from '../../core/history';
import { popup } from '../../actions/ui';
import Link from '../Link';
import Button from '../Button';
import { darkGray, borderColor } from '../../core/colors';
import { getJoinTitle } from '../../reducers/utils';
import { PasswordTextField } from '../FormAdapters/FormAdapters';

const useStyles = makeStyles({
  form: {
    textAlign: 'center',
    maxWidth: 400,
    margin: '0 auto',
  },
  title: {
    marginTop: 0,
  },
  linkedin: {
    cursor: 'pointer',
  },
  button: {
    fontWeight: 600,
    marginTop: 30,
    marginBottom: 30,
    maxWidth: 200,
    padding: '8px 22px',
    fontSize: 15,
  },
  signup: {
    color: darkGray,
  },
  loginDivider: {
    backgroundColor: borderColor,
    margin: '40px 0 30px',
  },
});

function Credentials({
  nextUrl,
  signupType,
  invite,
  domain,
  signupLink,
  onSubmit,
  onEmailChange,
  onPasswordChange,
  email,
  password,
  errors,
  popup,
  buttonDisable,
  alertId,
}) {
  const s = useStyles();

  const handleLinkedInClick = useCallback(() => {
    popup({
      title: 'LinkedIn authentication is no longer available',
      contents: 'Please use your email and password to login.',
      buttonAlignment: 'space-between',
      buttons: [
        {
          label: 'Create new password',
          primary: true,
          callback: () => {
            history.push('/password_reset');
          },
        },
        { flat: true, label: 'Cancel' },
      ],
    });
  });

  const errorBlock =
    errors.length > 0 ? (
      <Alert type="error" center id={alertId}>
        {errors[0]}
      </Alert>
    ) : undefined;

  const form = (
    <form className={s.form} onSubmit={onSubmit}>
      {!domain && <h2 className={s.title}>Log In</h2>}

      <TextField
        id="loginEmail"
        variant="outlined"
        fullWidth
        type="email"
        label="Email address"
        value={email}
        onChange={onEmailChange}
      />
      <PasswordTextField
        id="loginPassword"
        endAdornmentId="loginEye"
        variant="outlined"
        fullWidth
        label="Password"
        value={password}
        onChange={onPasswordChange}
      />

      <Button
        id="loginButton"
        disabled={buttonDisable}
        type="submit"
        size="medium"
        classes={{ root: s.button }}
        fullWidth
      >
        Log in
      </Button>

      <div className={s.links}>
        <Link id="loginForgotPassword" to={`/password_reset?next=${nextUrl}`}>
          Forgot your password?
        </Link>

        <br />
        <br />

        <Link
          id="loginLinkedInLogin"
          className={s.linkedin}
          onClick={handleLinkedInClick}
          onKeyPress={handleLinkedInClick}
        >
          Missing LinkedIn login?
        </Link>
      </div>

      <Divider className={s.loginDivider} />

      <div className={s.signup}>
        Do not have an account?
        <Link
          id="loginSignUp"
          to={formatAuthPath({
            to: signupLink || '/signup',
            domain,
            signupType,
            invite,
            next: nextUrl,
          })}
        >
          {' '}
          Sign up
        </Link>
      </div>
    </form>
  );

  const title = useMemo(() => getJoinTitle(domain), [domain]);

  return (
    <div>
      {errorBlock}

      {domain ? (
        <AuthLayout
          domain={domain}
          title={title}
          shortAboutText={domain.expert_signup_about_mobile}
          longAboutText={domain.expert_signup_about}
        >
          {form}
        </AuthLayout>
      ) : (
        form
      )}
    </div>
  );
}

Credentials = connect(undefined, { popup })(Credentials);

export default Credentials;

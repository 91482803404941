import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import DeleteDialog from '.';
import { deleteExpertRequest } from '../../actions/expertRequest';
import history from '../../core/history';
import { notify } from '../../actions/ui';

const CONSULTATIONS_ERROR =
  'GraphQL Error: cannot delete request with pending/upcoming/completed consultations';

class DeleteExpertRequestDialog extends PureComponent {
  handleDelete = () => {
    const { expertRequestId, deleteExpertRequest, notify, onCancel, returnTo } =
      this.props;
    deleteExpertRequest(expertRequestId)
      .then(() => {
        if (returnTo) history.push(returnTo);
      })
      .catch((err) => {
        if (err.message === CONSULTATIONS_ERROR) {
          notify(
            'Cannot delete request with pending, upcoming, or completed consultations.',
            'error'
          );
        } else {
          notify(
            'An unexpected error occurred when trying ' +
              'to delete the expert request.',
            'error'
          );
        }
        onCancel();
      });
  };

  render() {
    return (
      <DeleteDialog
        {...this.props}
        title="Are you sure you want to delete this request?"
        onConfirm={this.handleDelete}
      >
        This action is permanent and will result in the loss of expert matches.
      </DeleteDialog>
    );
  }
}

DeleteExpertRequestDialog = connect(undefined, {
  deleteExpertRequest,
  notify,
})(DeleteExpertRequestDialog);

export default DeleteExpertRequestDialog;

import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { Checkbox, Select, RadioGroup, TextField } from '../FormAdapters';
import Dialog from '../Dialog';
import FieldContainer from '../FieldContainer';
import Link from '../Link';
import InvitationEmailPreview from './InvitationEmailPreview';
import InvitationSummary from './InvitationSummary';
import CandidateChip from './CandidateChip';
import {
  MessageTemplatesDialog,
  MessageTemplateVariablesDialog,
} from '../MessageTemplate';
import { addExpertRequestCandidates } from '../../actions/expertRequest';
import { renderTemplate } from '../../actions/messageTemplate';
import { selectProfile } from '../../actions/search';
import { notify } from '../../actions/ui';
import { sortBy } from '../../core/util';
import s from './AddToExpertRequestDialog.module.scss';

const defaultSubject =
  '[field: expert.first_name], paid consultation on [field: request.name]';
const defaultBody = `Dear [field: expert.first_name],

This is [field: sender.first_name] from OnFrontiers, a New York-based online Expert platform.

I saw your profile and was wondering if you would be willing to assist our client on a project in your area of expertise.

The project is “[field: request.name]”.

Discussion would be around:
[field: request.questions]

You seem like a match for this. If relevant and interesting to you, could you check the details and indicate your interest and expertise through this link: [field: request.short_public_url]?

Feel free to check our Q&A to know more about being an expert at OnFrontiers: https://try.onfrontiers.com/knowledge/onfrontiers-expert-engagement-faq
Please let us know if you have any questions or concerns and we will be in touch soon.

All the best,
[field: sender.full_name]

[field: sender.private_url]
OnFrontiers.com - Find experts in any local market`;

function AddToExpertRequestForm({
  // Props
  open,
  profiles = [],
  onExpertAdd,
  onClose,

  // Redux State
  viewer,
  expertRequests,

  // Redux Actions
  addExpertRequestCandidates,
  notify,
}) {
  const validate = useCallback((values) => {
    const errors = {};
    if (!values.expert_request_id) {
      errors.expert_request_id = 'Need to specify an Expert Request';
    }
    if (!values.candidate_state) {
      errors.candidate_state = 'Need to select a state';
    }
    return errors;
  }, []);

  const handleSubmit = useCallback(
    async (values) => {
      const expertRequestId = values.expert_request_id;
      const sendInvitationEmail = values.send_invitation_email;
      const invitationEmailSubject = values.invitation_email_subject;
      const invitationEmailBody = values.invitation_email_body;
      const candidateState = values.candidate_state;

      const expertRequest = expertRequests.find(
        (p) => p.id === expertRequestId
      );

      const expertsText =
        profiles.length === 1 ? profiles[0].name : `${profiles.length} experts`;

      try {
        const data = await addExpertRequestCandidates({
          expertRequestId,
          dryRun: false,
          sendInvitationEmail,
          invitationEmailSubject,
          invitationEmailBody,
          state: candidateState,
          profileIds: profiles.map((p) => p.id),
        });

        notify(`${expertsText} added to ${expertRequest.name}.`);
        if (onExpertAdd) onExpertAdd(data);

        onClose();
      } catch (err) {
        notify(
          `${expertsText} could not be added to ${expertRequest.name}.`,
          'error'
        );
        return err;
      }
    },
    [expertRequests, profiles]
  );

  const initialValues = useMemo(
    () => ({
      candidate_state: viewer.admin ? 'contacted' : 'matched',
      send_invitation_email: viewer.admin,
    }),
    []
  );

  return (
    <Form
      validate={validate}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      component={AddToExpertRequestDialog}
      open={open}
      viewer={viewer}
      expertRequests={expertRequests}
      profiles={profiles}
      onClose={onClose}
    />
  );
}

AddToExpertRequestForm = connect(
  (state) => {
    return {
      viewer: state.viewer,
      expertRequests: state.expertRequests.open?.edges.map((e) => e.node) || [],
    };
  },
  {
    notify,
    addExpertRequestCandidates,
  }
)(AddToExpertRequestForm);

function AddToExpertRequestDialog({
  // Props
  profiles,
  open,
  onClose,
  expertRequests,
  viewer,

  // Final Form
  form,
  values,
  submitting,
  handleSubmit,

  // Redux Actions
  notify,
  selectProfile,
  addExpertRequestCandidates,
  renderTemplate,
}) {
  const expertRequestId = values.expert_request_id;
  const sendInvitationEmail = values.send_invitation_email;
  const candidateState = values.candidate_state;

  const [renderingInvitationEmail, setRenderingInvitationEmail] =
    useState(false);
  const [invitationEmail, setInvitationEmail] = useState(null);
  const [profileInvitationExample, setProfileInvitationExample] =
    useState(null);
  const [validationResult, setValidationResult] = useState(null);
  const [chooseTemplateOpenDialog, setChooseTemplateOpenDialog] =
    useState(false);
  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
  const [variablesDialogOpen, setVariablesDialogOpen] = useState(false);

  const handleProfileRemove = (selectedProfile) => {
    selectProfile(selectedProfile, false);

    if (
      profileInvitationExample &&
      selectedProfile.id === profileInvitationExample.id
    ) {
      const newExample = profiles.find((p) => p.id !== selectedProfile.id);
      setProfileInvitationExample(newExample);
    }
  };

  const handleSelectTemplate = async (template) => {
    if (template) {
      form.change('invitation_email_body', template.body);
    }
    setChooseTemplateOpenDialog(false);
  };

  useEffect(() => {
    validateExperts();
  }, [expertRequestId, sendInvitationEmail, profiles]);

  useEffect(() => {
    renderInvitationEmail();
  }, [
    expertRequestId,
    sendInvitationEmail,
    profiles,
    profileInvitationExample,
    values.invitation_email_body,
    values.invitation_email_subject,
    previewDialogOpen,
  ]);

  const handleInvitationExampleClick = (profile) => {
    setProfileInvitationExample(profile);
    setPreviewDialogOpen(true);
  };

  const renderInvitationEmail = () => {
    const profile = profileInvitationExample || profiles[0];
    const canRenderInvitationEmail =
      previewDialogOpen &&
      expertRequestId &&
      sendInvitationEmail &&
      (values.invitation_email_subject !== '' ||
        values.invitation_email_body !== '');

    if (!canRenderInvitationEmail) {
      return;
    }

    setProfileInvitationExample(profile);
    setRenderingInvitationEmail(true);
    const renderTemplateArgs = {
      senderId: viewer.id,
      requestId: expertRequestId,
      profileId: profile.id,
    };
    Promise.all([
      renderTemplate(values.invitation_email_subject, renderTemplateArgs),
      renderTemplate(values.invitation_email_body, renderTemplateArgs),
    ])
      .then((results) => {
        setRenderingInvitationEmail(false);
        setInvitationEmail({
          subject: results[0],
          body: results[1],
        });
      })
      .catch(() => {
        notify('An error occurred when rendering the template.', 'error');
        setRenderingInvitationEmail(false);
        setInvitationEmail(null);
      });
  };

  const validateExperts = () => {
    const shouldValidateCandidates = open && expertRequestId;

    if (!shouldValidateCandidates) {
      return;
    }

    addExpertRequestCandidates({
      expertRequestId,
      dryRun: true,
      sendInvitationEmail,
      invitationEmailSubject: values.invitation_email_subject,
      invitationEmailBody: values.invitation_email_body,
      state: candidateState,
      profileIds: profiles.map((p) => p.id),
    })
      .then((validationResult) => {
        setValidationResult(validationResult);
      })
      .catch((err) => {
        setValidationResult([]);
        notify('An error occurred when validating candidates.', 'error');
        throw err;
      });
  };

  const title = 'Add Experts to Request';

  const validationResultsByProfile = () => {
    const profiles = {};
    ((validationResult && validationResult.results) || []).forEach((r) => {
      profiles[r.profile_id] = {
        error_code: r.error_code,
        warning_code: r.warning_code,
      };
    });
    return profiles;
  };

  const validationText = (level) => {
    const prop = `${level}_code`;

    const getMessageCount = (error) =>
      ((validationResult && validationResult.results) || []).filter(
        (r) => r[prop] === error
      ).length;

    const messages = [];

    const marketplaceErrors = getMessageCount('out_of_marketplace');
    if (marketplaceErrors >= 1) {
      messages.push(
        `The request does not match the marketplace preferences of ${marketplaceErrors} expert(s) (highlighted)`
      );
    }

    const selfServiceErrors = getMessageCount('self_service_not_available');
    if (selfServiceErrors > 1) {
      messages.push(
        `${selfServiceErrors} experts are not available for self service (highlighted)`
      );
    }
    if (selfServiceErrors > 0) {
      messages.push('The expert is not available for self service');
    }

    if (messages.length > 0) {
      return messages.map((m) => <div key={m}>{m}</div>);
    }
  };

  const resultsByProfile = validationResultsByProfile();
  const warningText = validationText('warning');
  const errorText = validationText('error');

  const erOptions = useMemo(
    () =>
      expertRequests
        .sort(sortBy('name'))
        .map((r) => ({ value: r.id, label: r.name })),
    [expertRequests]
  );

  return (
    <Dialog
      useForm
      user={profiles.length === 1 ? profiles[0] : undefined}
      open={open}
      saveLabel="Save"
      title={title}
      onSubmit={handleSubmit}
      onReset={() => form.reset()}
      onClose={onClose}
      onCancel={onClose}
      disableSubmit={
        submitting || !validationResult || !validationResult.success
      }
    >
      <Field
        autocomplete
        name="expert_request_id"
        component={Select}
        label="Expert Request"
        options={erOptions}
      />

      <FieldContainer
        label="Experts to add"
        className={s.expertsToAdd}
        containerClassName={s.expertsToAddContainer}
        warningText={warningText}
        errorText={errorText}
      >
        {profiles.map((p) => (
          <CandidateChip
            key={p.id}
            profile={p}
            result={resultsByProfile[p.id]}
            onRequestDelete={() => handleProfileRemove(p)}
          />
        ))}
      </FieldContainer>

      {viewer.admin && (
        <Field
          name="candidate_state"
          component={RadioGroup}
          label="Add to Stage"
          FormControlProps={{ style: { marginTop: 30 } }}
          style={{ flexDirection: 'row' }}
          FormCon
          options={[
            { label: 'Contacted', value: 'contacted' },
            { label: 'Vetting', value: 'vetting' },
          ]}
        />
      )}

      {viewer.admin && (
        <Field
          type="checkbox"
          component={Checkbox}
          name="send_invitation_email"
          label="Include invitation email"
          checked={values.send_invitation_email}
        />
      )}

      {viewer.admin && sendInvitationEmail && expertRequestId && (
        <>
          <Link onClick={() => setChooseTemplateOpenDialog(true)}>
            Select From Template
          </Link>
          <MessageTemplatesDialog
            open={chooseTemplateOpenDialog}
            onClose={() => setChooseTemplateOpenDialog(false)}
            onSelectTemplate={handleSelectTemplate}
          />
          <div className={s.emailField}>
            <Link onClick={() => setVariablesDialogOpen(true)}>
              View Available Template Variables
            </Link>
          </div>
          <MessageTemplateVariablesDialog
            open={variablesDialogOpen}
            title="Available Template Variables"
            onClose={() => setVariablesDialogOpen(false)}
          />
          <FieldContainer className={s.emailField} label="Subject">
            <Field
              component={TextField}
              name="invitation_email_subject"
              initialValue={defaultSubject}
              InputProps={{ disableUnderline: true }}
              fullWidth
            />
          </FieldContainer>
          <FieldContainer className={s.emailField} label="Body">
            <Field
              component={TextField}
              name="invitation_email_body"
              initialValue={defaultBody}
              InputProps={{ disableUnderline: true }}
              fullWidth
              multiline
              rows={10}
              maxRows={10}
            />
          </FieldContainer>
          <InvitationSummary
            profiles={profiles}
            onInvitationExampleClick={handleInvitationExampleClick}
          />
          <Dialog
            open={previewDialogOpen}
            title="Preview"
            onClose={() => setPreviewDialogOpen(false)}
            onCancel={() => setPreviewDialogOpen(false)}
          >
            <InvitationEmailPreview
              loading={renderingInvitationEmail}
              invitationEmail={invitationEmail}
            />
          </Dialog>
        </>
      )}
    </Dialog>
  );
}

AddToExpertRequestDialog = connect(undefined, {
  notify,
  selectProfile,
  addExpertRequestCandidates,
  renderTemplate,
})(AddToExpertRequestDialog);

AddToExpertRequestDialog = AddToExpertRequestDialog;

export default AddToExpertRequestForm;

import ActionTypes from '../core/ActionTypes';
import { mergeAt, removeAt } from './utils';

const {
  CONSULTATION__ADD_REGISTRANT,
  CONSULTATION__UPDATE,
  CONSULTATION__DELETE,
  CONSULTATION__BATCH_ADD,
  CONSULTATION__LIST_LOADING,
  CONSULTATION__LIST_LOADED,
  CONSULTATION__CLEAR,
  CONSULTATION__RESET_COLLECTION,
  UI__SET_USER_CONTEXT,
} = ActionTypes;

const initialCollectionState = {
  loading: false,
  edges: [],
  pageInfo: { hasNextPage: true },
  resetAt: undefined,
};
const initialState = {
  unreviewed: initialCollectionState,
  starting: initialCollectionState,
  awaiting: initialCollectionState,
  canceled: initialCollectionState,
  confirmed: initialCollectionState,
  default: initialCollectionState,
};

function collectionReducer(state = initialCollectionState, action) {
  switch (action.type) {
    case CONSULTATION__LIST_LOADING:
      return { ...state, loading: true };
    case CONSULTATION__LIST_LOADED:
      return { ...state, loading: false };
    case CONSULTATION__BATCH_ADD:
      const { reset, edges, pageInfo } = action;
      if (reset) {
        return { edges, pageInfo, resetAt: new Date() };
      }
      return { edges: [...state.edges, ...edges], pageInfo };
    case CONSULTATION__DELETE:
      return {
        ...state,
        edges: removeAt(
          state.edges,
          state.edges.findIndex((c) => c.node.id === action.id)
        ),
      };
    case CONSULTATION__UPDATE:
      const index = state.edges.findIndex(
        (c) => c.node.id === action.consultation.id
      );
      if (index < 0) {
        return action.canAdd
          ? {
              ...state,
              edges: [{ node: action.consultation }, ...state.edges],
            }
          : state;
      }
      const updated = {
        node: {
          ...state.edges[index].node,
          ...action.consultation,
        },
      };
      return {
        ...state,
        edges: mergeAt(state.edges, index, updated),
      };
    case CONSULTATION__ADD_REGISTRANT:
      const { conference } = action.consultation;
      const registrants = conference?.registrants || [];
      const { registrant } = action;
      if (!registrants.find((r) => r.identifier === registrant.identifier)) {
        registrants.push(registrant);
      }
      const consultation = {
        ...action.consultation,
        conference: {
          ...conference,
          registrants,
        },
      };
      return collectionReducer(state, {
        type: CONSULTATION__UPDATE,
        consultation,
      });
    default:
      return state;
  }
}

export default function consultationReducer(state = initialState, action) {
  switch (action.type) {
    case CONSULTATION__LIST_LOADING:
    case CONSULTATION__LIST_LOADED:
    case CONSULTATION__BATCH_ADD:
      return (() => {
        const collection = action.collection || 'default';
        return {
          ...state,
          [collection]: collectionReducer(state[collection], action),
        };
      })();
    case CONSULTATION__UPDATE:
    case CONSULTATION__ADD_REGISTRANT:
      return (() => {
        const newState = {};
        Object.keys(state).forEach((col) => {
          newState[col] = collectionReducer(state[col], {
            ...action,
            canAdd: col === action.collection,
          });
        });
        return newState;
      })();
    case CONSULTATION__DELETE:
      return (() => {
        const newState = {};
        if (action.collection) {
          const col = action.collection;
          newState[col] = collectionReducer(state[col], action);
        } else {
          Object.keys(state).forEach((col) => {
            newState[col] = collectionReducer(state[col], action);
          });
        }
        return {
          ...state,
          ...newState,
        };
      })();
    case CONSULTATION__RESET_COLLECTION:
      return (() => {
        const collection = action.collection || 'default';
        return {
          ...state,
          [collection]: initialCollectionState,
        };
      })();
    case CONSULTATION__CLEAR:
    case UI__SET_USER_CONTEXT:
      return initialState;
    default:
      return state;
  }
}

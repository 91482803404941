import React, { PureComponent } from 'react';
import StarRating from './StarRating';
import EditReviewDialog from './EditReviewDialog';
import Button from '../Button/Button';
import MaterialIcon from '../Icon/MaterialIcon';
import s from './ReviewCard.module.scss';

function Field({ title, value }) {
  return (
    <div className={s.field}>
      <div className={s.label}>{title}</div>
      <div className={s.value}>{value}</div>
    </div>
  );
}

class ReviewCard extends PureComponent {
  state = {
    dialogOpened: false,
  };

  handleOpen = () => this.setState({ dialogOpened: true });

  handleClose = () => this.setState({ dialogOpened: false });

  render() {
    const { title, review, reviewFromExpert, targetName, updateReviewEnabled } =
      this.props;

    const {
      match_experience_rating: matchExperienceRating,
      uselfulness_rating: uselfulnessRating,
      communication_rating: communicationRating,
      connection_quality_rating: connectionQualityRating,
      target_feedback: targetFeedback,
      onfrontiers_feedback: onfrontiersFeedback,
    } = review;

    return (
      <div className={s.root}>
        <div className={s.title}>{title}</div>

        {review.review ? (
          <p className={s.review}>“{review.review}”</p>
        ) : (
          updateReviewEnabled && (
            <React.Fragment>
              <Button
                color="secondary"
                startIcon={<MaterialIcon icon="post_add" />}
                size="small"
                onClick={this.handleOpen}
              >
                Add Review Content
              </Button>
              <EditReviewDialog
                open={this.state.dialogOpened}
                review={review}
                onClose={this.handleClose}
              />
            </React.Fragment>
          )
        )}

        {reviewFromExpert ? (
          <StarRating
            title="Match for my Experience"
            editing={false}
            name="match_experience_rating"
            value={matchExperienceRating}
          />
        ) : (
          <StarRating
            title="Match for my Request"
            editing={false}
            name="uselfulness_rating"
            value={uselfulnessRating}
          />
        )}

        <StarRating
          title="Communication"
          editing={false}
          name="communication_rating"
          value={communicationRating}
        />

        <StarRating
          title="Connection Quality"
          editing={false}
          name="connection_quality_rating"
          value={connectionQualityRating}
        />

        {targetFeedback && (
          <Field
            title={`Private Feedback to ${targetName}`}
            value={targetFeedback}
          />
        )}

        {onfrontiersFeedback && (
          <Field
            title="Private Feedback to OnFrontiers"
            value={onfrontiersFeedback}
          />
        )}
      </div>
    );
  }
}

ReviewCard = ReviewCard;

export default ReviewCard;

import { logout } from '../../core/login';
export default {
  subdomain: '*',
  path: '/logout',

  async action({ store, query, path }, params, { subdomain }) {
    const next = query.next || '/login';

    logout();

    window.location = next;
  },
};

import moment from 'moment-timezone';

class Participant {
  constructor(participant) {
    for (const key in participant) {
      this[key] = participant[key];
    }
  }

  events = () => null;

  summary = () => null;

  callTypeLabel = () => callTypeLabel[this.inferCallType()];
}

const callTypeLabel = {
  dialout: 'Dial Out',
  dialin: 'Dial In',
  web: 'Web',
};

class TwilioParticipant extends Participant {
  hasCall = () => !!this.call;

  hasMetrics = () => !!this.metrics;

  callId = () => this.call?.sid;

  callStart = () => this.call?.start_time;

  callEnd = () => this.call?.end_time;

  callDuration = () => parseInt(this.call?.duration);

  callFrom = () => this.call?.from;

  callTo = () => this.call?.to;

  events = () => this.metrics && this.metrics.events;

  summary = () => this.metrics && this.metrics.summary;

  url = () =>
    `https://www.twilio.com/console/voice/calls/logs/${this.callId()}${
      this.inferCallType() === 'web' ? '/metrics' : ''
    }`;

  getGrouper = () => {
    return '';
  };

  getCallOriginDescription = () => {
    const callType = this.inferCallType();

    if (callType === 'web') {
      const summary = this.summary();
      const ipAddress = summary && summary.from && summary.from.ip_address;
      return `IP: ${ipAddress || 'Unknown'}`;
    }

    if (callType === 'dialin') return `Phone: ${this.callFrom() || 'Unknown'}`;

    if (callType === 'dialout') return `Phone: ${this.callTo() || 'Unknown'}`;

    return 'Unknown';
  };

  inferCallType = () => {
    const { call } = this;
    if (call?.direction === 'outbound-api') return 'dialout';
    if (call?.direction === 'inbound') return call?.to ? 'dialin' : 'web';
    return call?.direction;
  };
}

class PlivoParticipant extends Participant {
  hasCall = () => !!this.call;

  // Plivo doesn't have support for summary/events
  hasMetrics = () => !!this.call;

  callId = () => this.call?.call_uuid;

  // answer_time can be null when `Destination Out Of Service`
  callStart = () => this.call?.answer_time ?? this.call?.initiation_time;

  callEnd = () => this.call?.end_time;

  callDuration = () => this.call?.call_duration;

  callFrom = () => {
    const number = this.call?.from_number || '';
    return number.startsWith('sip') ? '' : number;
  };

  callTo = () => {
    const number = this.call?.to_number || '';
    return number.startsWith('sip') ? '' : number;
  };

  // Plivo console currently has a bug where we have to specify the end date
  // filter as a query param in onder to load the call metrics
  url = () => {
    const endDate = this.callEnd();
    const formattedEndDate =
      endDate && moment(endDate).add(1, 'd').format('MM/DD/YYYY');
    return `https://console.plivo.com/voice/logs/calls/stats/${this.callId()}?end_date=${formattedEndDate}`;
  };

  getGrouper = () => {
    return '';
  };

  getCallOriginDescription = () => {
    const callType = this.inferCallType();

    if (callType === 'web') return 'Web';

    if (callType === 'dialin') return `Phone: ${this.callFrom() || 'Unknown'}`;

    if (callType === 'dialout') return `Phone: ${this.callTo() || 'Unknown'}`;

    return 'Unknown';
  };

  inferCallType = () => {
    const { call } = this;
    if (call?.call_direction === 'outbound') return 'dialout';
    return this.callTo() ? 'dialin' : 'web';
  };
}

class ZoomParticipant extends Participant {
  hasCall = () => !!this.call;

  // Zoom metrics is out of scope for now
  hasMetrics = () => !!this.call;

  callId = () => this.call?.uuid;

  callStart = () => this.call?.started_time;

  callEnd = () => this.call?.ended_time;

  callDuration = () => this.call?.duration;

  callFrom = () => {
    return '';
  };

  callTo = () => {
    return this.call?.username;
  };

  // Zoom doesn't support
  url = () => {
    return '';
  };

  getGrouper = () => {
    return this.call?.grouper;
  };

  // Usually we return Web/Phone, but for Zoom we can identify the user,
  // so we can just show which username is in call
  // (for unknown dial-in it shows the phone number)
  getCallOriginDescription = () => {
    return `${this.callTo()} (${this.inferCallType()})`;
  };

  inferCallType = () => {
    return this.call?.origin;
  };
}

export function getParticipants(conference) {
  if (!conference.participants) return [];

  if (conference.carrier === 'twilio') {
    return conference.participants.map((p) => new TwilioParticipant(p));
  }

  if (conference.carrier === 'plivo') {
    return conference.participants.map((p) => new PlivoParticipant(p));
  }

  if (conference.carrier === 'zoom') {
    const participants = [];
    // for context: on plivo/twilio each join creates a new carrier call id,
    // but for zoom it keeps the instance id, it only changes when all
    // participants leave and then join again.

    // split ranges from same instance into single participants
    conference.participants.forEach((p) =>
      participants.push(
        ...(p.call?.ranges.map((r, idx) => ({
          ...p,
          id: `${p.id}_${idx}`,
          call: {
            ...p.call,
            ...r,
          },
        })) || [])
      )
    );

    return participants.filter(Boolean).map((p) => new ZoomParticipant(p));
  }

  throw new Error('unsupported carrier');
}

import React from 'react';
import Dialog from '../Dialog';
import Form from '../Form';

const EditDialog = ({
  children,
  disabled,
  disableSubmitOnEnter,
  onSubmit,
  onReset,
  onClose,
  ...other
}) => {
  const handleClose = (c) => {
    if (onReset) {
      onReset();
    }

    if (onClose) {
      onClose(c);
    }
  };

  const handleSubmit = (values) => {
    if (onSubmit) {
      const errors = onSubmit(values);
      if (errors) return;
    }

    if (onClose) {
      onClose(true);
    }
  };

  return (
    <Dialog
      {...other}
      onClose={handleClose}
      onCancel={handleClose}
      onConfirm={handleSubmit}
    >
      <Form
        onSubmit={handleSubmit}
        onReset={handleClose}
        disableSubmitOnEnter={disableSubmitOnEnter}
      >
        {children}
      </Form>
    </Dialog>
  );
};

export default EditDialog;

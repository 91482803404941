import React, { memo } from 'react';
import Tooltip from 'rc-tooltip';
import Button from '../Button';
import s from './TooltipUnmute.module.scss';

const TooltipOverlay = memo(({ onUnmute }) => (
  <div className={s.overlay}>
    <span className={s.text}>Speaking to the group?</span>
    <Button onClick={onUnmute} style={{ width: 80 }} size="small" color="white">
      Unmute
    </Button>
  </div>
));

const TooltipUnMute = memo(
  ({ visible, children, onUnmute, TooltipProps = {} }) => (
    <Tooltip
      prefixCls="rc-tooltip-darkgreen rc-tooltip-unmute rc-tooltip"
      overlay={<TooltipOverlay onUnmute={onUnmute} />}
      visible={visible}
      overlayStyle={{ zIndex: 2000 }}
      placement="bottomRight"
      {...TooltipProps}
    >
      {children}
    </Tooltip>
  )
);

export default TooltipUnMute;

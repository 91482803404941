import React from 'react';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { lighten } from '@mui/material/styles';
import { teal500 } from '../../../../core/colors';

const useStyles = makeStyles(() => ({
  root: {
    margin: '20px 0',
    color: teal500,
    fontWeight: 'bold',
    cursor: 'pointer',
    display: 'inline-block',
    '&:hover': {
      color: lighten(teal500, 0.2),
    },
  },
}));

export default function AddButton({ onClick, className, label, ...rest }) {
  const s = useStyles();

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      {...rest}
      className={cx(s.root, className)}
      onClick={onClick}
      id={`expertRequestsAdd${label}Button`}
    >
      Add {label}
    </div>
  );
}

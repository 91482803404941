import React, { Component } from 'react';
import { IconButton as IconButtonMaterial } from '@mui/material';

class IconButton extends Component {
  render() {
    const { style, ...other } = this.props;
    const buttonStyle = {
      width: 'auto',
      height: 'auto',
      padding: 0,
      ...style,
    };

    return (
      <IconButtonMaterial {...other} style={buttonStyle}>
        {this.props.children}
      </IconButtonMaterial>
    );
  }
}

export default IconButton;

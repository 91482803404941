import React from 'react';
import cx from 'classnames';
import MenuItem from '@mui/material/MenuItem';
import IconMenu from '../IconMenu';
import IconButton from '../IconButton';
import { white, darkBrown, sand, teal500 } from '../../core/colors';
import s from './Module.module.scss';
import FAIcon from '../Icon/FAIcon';

function Module({
  title,
  primary,
  secondary,
  contextActionsElement,
  contextActions = [],
  className,
  children,
  headerStyle,
  bodyStyle,
  ...other
}) {
  const backgroundColor = primary ? teal500 : secondary ? sand : darkBrown;
  const color = secondary ? darkBrown : white;

  if (contextActions.length && !title) {
    throw new Error('context actions defined but no title');
  }

  return (
    <div className={cx(s.root, { [className]: !!className })} {...other}>
      {title && (
        <div
          className={s.header}
          style={{ backgroundColor, color, ...headerStyle }}
        >
          <div className={s.headerText}>{title}</div>
          {contextActions.length > 0 && renderContextActions(contextActions)}
          {contextActionsElement}
        </div>
      )}
      <div className={s.body} style={bodyStyle}>
        {children}
      </div>
    </div>
  );
}

function renderContextActions(actions) {
  return (
    <IconMenu
      iconElement={
        <IconButton>
          <FAIcon icon="cog" color={white} />
        </IconButton>
      }
    >
      {actions.map((a) => (
        <MenuItem key={a.text} onClick={a.onClick} disabled={a.disabled}>
          {a.text}
        </MenuItem>
      ))}
    </IconMenu>
  );
}

export default Module;

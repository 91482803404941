import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import ChangePasswordForm from '../../components/Forms/Profile/ChangePasswordForm';
import history from '../../core/history';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontSize: 28,
    margin: '25px 0',
  },
});

function ChangePassword({ viewer, next }) {
  const s = useStyles();

  const handleUpdate = useCallback(() => {
    history.push(next || '/dashboard');
  }, [next]);

  return (
    <div className={s.root}>
      <h1 className={s.title}>
        To increase security, we ask users to update their password periodically
      </h1>
      <ChangePasswordForm userId={viewer.id} onSubmit={handleUpdate} />
    </div>
  );
}

ChangePassword = connect((state) => ({ viewer: state.viewer }))(ChangePassword);

export default ChangePassword;

import React from 'react';
import Tooltip from 'rc-tooltip';
import Picture from '../../../components/Picture';
import MaterialIcon from '../../../components/Icon/MaterialIcon';

export default function MatchStateDescription({
  stateConfig,
  editable,
  candidate,
  suggestedByResearch,
  onOpenSelectMatchState,
}) {
  return (
    <Tooltip
      overlay={<div style={{ maxWidth: 300 }}>{stateConfig.description}</div>}
      placement="topRight"
      trigger={['hover', 'click']}
    >
      <div
        onClick={onOpenSelectMatchState}
        onKeyUp={onOpenSelectMatchState}
        style={{ cursor: editable ? 'pointer' : 'normal' }}
      >
        {suggestedByResearch && candidate.created_by ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ display: 'inline-block' }}>
              Suggested by {candidate.created_by.name}
            </div>
            <Picture
              user={candidate.created_by}
              size={30}
              style={{ marginLeft: 5, display: 'inline-block' }}
              link={false}
            />
          </div>
        ) : (
          <div>
            {stateConfig.label}{' '}
            <MaterialIcon
              icon={stateConfig.icon}
              color={stateConfig.color}
              size={16}
              style={{
                verticalAlign: 'bottom',
                width: 16,
                marginLeft: 3,
              }}
            />
          </div>
        )}
      </div>
    </Tooltip>
  );
}

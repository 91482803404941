import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { connect } from 'react-redux';
import Logo from '../Logo/Logo';
import { darkBrown, white } from '../../core/colors';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
  },
  label: {
    color: white,
    backgroundColor: darkBrown,
    fontSize: 12,
    marginRight: '5px',
  },
}));

function PoweredByOnFrontiers({ user }) {
  const s = useStyles();

  const groupEnabled =
    user && user.groups && user.groups.find((g) => g.branding_show_poweredbyof);

  if (!groupEnabled) {
    return null;
  }

  return (
    <div className={s.container}>
      <div className={s.label}>Powered by</div>
      <Logo style={{ backgroundColor: darkBrown }} size="small" />
    </div>
  );
}

export default connect((state) => ({
  user: state.viewer,
}))(PoweredByOnFrontiers);

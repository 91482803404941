import React, { Component } from 'react';
import { lighten, darken } from '@mui/material/styles';
import cx from 'classnames';
import Logo from '../Logo';
import Nav from './MarketingNav';
import { darkBrown } from '../../core/colors';
import s from './MarketingHeader.module.scss';

class Header extends Component {
  static defaultProps = {
    theme: 'dark',
    backgroundColor: darkBrown,
    enableStickyHeader: true,
  };

  state = {
    sticky: false,
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleScroll);
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleScroll);
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const { sticky } = this.state;
    const shouldStick = window.pageYOffset > 200;
    if (sticky !== shouldStick) {
      this.setState({ sticky: shouldStick });
    }
  };

  render() {
    const {
      theme,
      selectedPath,
      links,
      logo,
      enableMobile,
      rootPath,
      style,
      backgroundColor,
      enableStickyHeader,
      showLogo,
    } = this.props;

    const { sticky } = this.state;

    const colorFn = theme === 'dark' ? lighten : darken;

    const HeaderNav = (
      <div className={cx(s.nav, { [s.navWithLogo]: showLogo })}>
        {showLogo && (
          <a href={rootPath || '/'} style={{ lineHeight: 0 }}>
            {logo || <Logo dark={theme === 'light'} />}
          </a>
        )}

        <Nav
          theme={theme}
          links={links}
          selectedPath={selectedPath}
          enableMobile={enableMobile}
        />
      </div>
    );

    return (
      <div style={style}>
        {HeaderNav}

        {enableStickyHeader && (
          <div
            className={cx(s.sticky, { [s.stickyVisible]: sticky })}
            style={{
              backgroundColor,
              borderBottomColor: colorFn(backgroundColor, 0.1),
            }}
          >
            {HeaderNav}
          </div>
        )}
      </div>
    );
  }
}

Header.defaultProps = {
  showLogo: true,
};

export default Header;

import React, { Fragment, useState, useCallback, useMemo } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ButtonBase from '@mui/material/ButtonBase';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import FlagIcon from '../FlagIcon';

export default function ({
  options,
  value,
  renderOption,
  getCountryCode,
  onChange,
  style,
}) {
  const [anchorEl, setAnchorEl] = useState();

  const handleClick = useCallback(
    (event) => setAnchorEl(event.currentTarget),
    []
  );

  const handleClose = useCallback(() => setAnchorEl(null), []);

  const handleChange = useCallback(
    (value) => {
      onChange(value);
      handleClose();
    },
    [onChange]
  );

  const items = useMemo(
    () =>
      options
        .map((option) => {
          const text = renderOption(option);
          if (!text) return;

          return (
            <MenuItem
              key={JSON.stringify(option)}
              selected={option === value}
              onClick={() => handleChange(option)}
            >
              <FlagIcon
                style={{ marginRight: 10 }}
                country={getCountryCode ? getCountryCode(option) : option}
              />
              {text}
            </MenuItem>
          );
        })
        .filter(Boolean),
    [value, options]
  );

  return (
    <Fragment>
      <ButtonBase style={style} onClick={handleClick} tabIndex={-1}>
        <FlagIcon country={getCountryCode ? getCountryCode(value) : value} />
        <ArrowDropDown />
      </ButtonBase>

      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
        {items}
      </Menu>
    </Fragment>
  );
}

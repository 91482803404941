import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Button as MaterialButton } from '@mui/material';
import MaterialIcon from '../Icon/MaterialIcon';
import { notify, popup } from '../../actions/ui';
import { track } from '../../actions/tracking';
import { createChannel } from '../../actions/messaging';
import history from '../../core/history';
import { promoPopup } from './promo';

const CREATE_CHANNEL_AUTH_ERROR =
  'GraphQL Error: basic accounts cannot create messaging channels';

class SendMessageButton extends PureComponent {
  state = {
    creating: false,
  };

  handleSendMessage = () => {
    const { viewer, userId, notify, createChannel, track, popup } = this.props;

    this.setState({ creating: true });
    createChannel([viewer.id, userId])
      .then((channelId) => {
        history.push(`/messaging/${channelId}?channel=true`);
      })
      .catch((err) => {
        this.setState({ creating: false });

        if (err.message === CREATE_CHANNEL_AUTH_ERROR) {
          return promoPopup(popup, track);
        }

        notify('An error occurred when starting chat with user.', 'error');
        throw err;
      });
  };

  render() {
    const { viewer, userId, notify, createChannel, popup, track, ...other } =
      this.props;

    return (
      <MaterialButton
        size="small"
        startIcon={<MaterialIcon icon="chat" />}
        onClick={this.handleSendMessage}
        disabled={this.state.creating}
        {...other}
      >
        Send Message
      </MaterialButton>
    );
  }
}

export default connect(
  (state) => ({
    viewer: state.viewer,
  }),
  {
    popup,
    notify,
    track,
    createChannel,
  }
)(SendMessageButton);

import React, { Fragment, useCallback, forwardRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import { darkGray, darkGreen } from '../../core/colors';
import outgoing from './outgoing.mp3';

const TestSpeaker = forwardRef(({ disabled, sinkId }, ref) => {
  useEffect(() => {
    const audio = new window.Audio(outgoing);
    if (audio.setSinkId) {
      audio.setSinkId(sinkId); // Change device output (experimental)
    } else {
      audio.sinkId = sinkId; // Not supported, but trigger effect
    }
    ref.current = audio;
  }, [sinkId]);

  const onPlay = useCallback(() => {
    ref.current.currentTime = 0;
    ref.current.play();
  }, []);

  return (
    <Fragment>
      <Button
        style={{
          minWidth: 0,
          padding: '0px 10px',
          color: disabled ? darkGray : darkGreen,
        }}
        onClick={onPlay}
        disabled={disabled}
      >
        Test
      </Button>
    </Fragment>
  );
});

export default TestSpeaker;

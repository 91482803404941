import React from 'react';
import Jobs from './Jobs';

export default {
  path: '/careers',

  async action() {
    return {
      title: 'Careers',
      chunk: 'jobs',
      component: <Jobs />,
    };
  },
};

import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';

const useStyles = makeStyles(() => ({
  paper: {
    marginTop: 10,
    marginBottom: 10,
    minWidth: 168,
  },
}));

function Menu({
  children,
  anchorEl,
  onClose,
  paperStyles = {},
  placement = 'bottom-end',
}) {
  const s = useStyles();

  return (
    <Popper
      open={!!anchorEl}
      anchorEl={anchorEl}
      transition
      placement={placement}
      style={{ zIndex: 2 }}
    >
      {({ TransitionProps, placement }) => (
        <Fade
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === 'bottom' ? 'right top' : 'right bottom',
          }}
        >
          <Paper style={paperStyles} classes={{ root: s.paper }} elevation={8}>
            <ClickAwayListener onClickAway={onClose}>
              <MenuList autoFocusItem>{children}</MenuList>
            </ClickAwayListener>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
}

export default Menu;

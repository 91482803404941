import React from 'react';
import About from './About';
import { fetchAboutPageSections } from '../../actions/about';

export default {
  path: '/about',

  async action({ store }) {
    const sections = await store.dispatch(fetchAboutPageSections());

    return {
      title: 'About Us',
      component: <About sections={sections} />,
    };
  },
};

import React, { useCallback, useMemo, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { updateExpertRequestCandidate } from '../../../actions/expertRequest';
import { Checkbox } from '../../../components/FormAdapters/FormAdapters';
import Currency from '../../../components/Currency/Currency';
import Dialog from '../../../components/Dialog';
import { notify } from '../../../actions/ui';
import { formatCreditsText, rateToCredits } from '../../../core/util';
import { darkGray } from '../../../core/colors';

const useStyles = makeStyles(() => ({
  calculated: {
    fontSize: 14,
    marginTop: 0,
    color: darkGray,
  },
}));

function EditRatesDialog({
  candidate,
  expertRequest,
  updateExpertRequestCandidate,
  notify,
  onClose,
  ...other
}) {
  const s = useStyles();

  const { bill_rate: billRate, credit_rate: creditRate } = candidate;
  const [expectedConsumerRate, setExpectedConsumerRate] = useState(
    rateToCredits(billRate)
  );

  const initialValues = useMemo(
    () => ({
      bill_rate: billRate,
      credit_rate: creditRate,
      show_engagement_agreement: candidate.show_engagement_agreement,
    }),
    [candidate]
  );

  const handleSubmit = useCallback(async (values) => {
    try {
      const candidateData = {
        id: candidate.id,
        bill_rate: values.bill_rate,
        credit_rate: values.credit_rate,
        show_engagement_agreement: values.show_engagement_agreement,
      };
      await updateExpertRequestCandidate(expertRequest.id, candidateData);
    } catch (err) {
      notify('An error occurred when trying to update the candidate.', 'error');
    }
    onClose();
  }, []);

  const validate = useCallback((values) => {
    const errors = {};

    ['bill_rate', 'credit_rate'].forEach((f) => {
      const value = values[f];
      if (value === '' || value === undefined) {
        errors[f] = 'Required';
      }

      if (value < 0) {
        errors[f] = 'Must be a positive number';
      }
    });

    return errors;
  }, []);

  const consumerHelperText = `Calculated Consumer Rate: ${formatCreditsText(
    expectedConsumerRate
  )}/hr`;

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validate={validate}
      subscription={{ submitting: true }}
    >
      {({ handleSubmit, submitting }) => {
        return (
          <Dialog
            {...other}
            title="Edit Rates"
            maxWidth="xs"
            onConfirm={handleSubmit}
            onClose={onClose}
            onCancel={onClose}
            disableSubmit={submitting}
            cancelLabel="Cancel"
            confirmLabel="Save"
          >
            <form onSubmit={(e) => handleSubmit(e)}>
              <Field
                component={Currency}
                name="credit_rate"
                label="Consumer Rate"
                variant="outlined"
                position="end"
                adornment="credits/hr"
              />
              <p className={s.calculated}>{consumerHelperText}</p>

              <Field
                component={Currency}
                name="bill_rate"
                label="Expert Rate"
                variant="outlined"
                position="end"
                parse={(value) => {
                  setExpectedConsumerRate(rateToCredits(value));
                  return value;
                }}
                adornment="USD/hr"
              />
              <Field
                type="checkbox"
                component={Checkbox}
                name="show_engagement_agreement"
                label="Show engagement agreement on consultation request"
              />
            </form>
          </Dialog>
        );
      }}
    </Form>
  );
}

EditRatesDialog = connect(undefined, {
  updateExpertRequestCandidate,
  notify,
})(EditRatesDialog);

export default EditRatesDialog;

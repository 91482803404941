import React from 'react';
import ExpertNetwork from './ExpertNetwork';
import LayoutPage from '../../components/Layout/LayoutPage';
import {
  fetchNetworkExpert,
  updateNetworkExpert,
} from '../../actions/internalNetwork';
import { fetchProfile } from '../../actions/profile';
import AcceptNetwork from './AcceptNetwork';

export default [
  {
    path: '/network',

    async action({ store }) {
      const { viewer } = store.getState();

      const profile = await store.dispatch(
        fetchProfile(viewer.username, {
          force: true,
          internalNetworks: true,
          groupAboutPage: true,
          internalNetworkConsultations: true,
          connectionStates: ['active', 'awaiting_acceptance'],

          // for completeness
          experiences: true,
          education: true,
          addresses: true,
          expertise: true,
        })
      );

      return {
        title: 'My Clients',
        component: (
          <LayoutPage showNav selected="my-network">
            <ExpertNetwork profileId={profile.id} />
          </LayoutPage>
        ),
      };
    },
  },
  {
    path: '/network/:networkExpertId/:connectionState',

    async action({ store, params }) {
      const { networkExpertId, connectionState } = params;

      if (!['rejected', 'removed', 'active'].includes(connectionState)) return;

      const networkExpert = await store.dispatch(
        fetchNetworkExpert(networkExpertId)
      );

      const { domain } = networkExpert.network.group;

      const canAccept =
        !domain || !domain.agreements || domain.agreements.length === 0;

      if (connectionState !== 'active' || canAccept) {
        await store.dispatch(
          updateNetworkExpert({
            id: networkExpertId,
            connection_state: connectionState,
          })
        );
        return { redirect: '/network' };
      }

      return {
        title: 'My Clients',
        component: (
          <LayoutPage showNav selected="my-network">
            <AcceptNetwork networkExpert={networkExpert} />
          </LayoutPage>
        ),
      };
    },
  },
];

import React from 'react';
import { fetchCaseStudy } from '../../actions/caseStudy';
import { fetchUser } from '../../actions/user';
import Case from './Case';

const featuredCases = [
  'solar-energy',
  'payroll-lending',
  'animal-feed-in-nigeria',
];

export default {
  path: '/case/:slug',

  async action({ params, store }) {
    const { slug } = params;
    if (slug === 'refugee-education') return;

    let caseStudy;
    try {
      caseStudy = await store.dispatch(fetchCaseStudy(slug));
    } catch (err) {
      Promise.reject(err);
    }

    if (!caseStudy) return;

    const caseStudies = await Promise.all(
      featuredCases.map((id) => store.dispatch(fetchCaseStudy(id)))
    );

    const experts =
      caseStudy.experts &&
      (
        await Promise.all(
          caseStudy.experts.map((username) =>
            store.dispatch(fetchUser(username))
          )
        )
      ).filter(Boolean);

    return {
      title: `OnFrontiers - ${caseStudy.title}`,
      meta: {
        description: caseStudy.subtitle,
        imageUrl: `/images/studies/${caseStudy.image}`,
        type: 'article',
      },
      component: (
        <Case
          {...caseStudy}
          id={params.slug}
          caseStudies={caseStudies}
          experts={experts}
        />
      ),
    };
  },
};

import React, { useMemo } from 'react';
import useAppContext from '../../../components/useAppContext';
import Review from '../../../components/ReviewConsultation/ReviewCard';

const FeedbackDetails = ({
  isViewerExpert,
  isViewerRequester,
  clientReview,
  expertReview,
  requester,
  expert,
}) => {
  const { permissions } = useAppContext();

  const canUpdateExpertReview = useMemo(() => {
    if (!expertReview) {
      return false;
    }
    return permissions.allowed(
      'consultation_review',
      'update',
      expertReview.id
    );
  }, [expertReview]);

  const canUpdateClientReview = useMemo(() => {
    if (!clientReview) {
      return false;
    }
    return permissions.allowed(
      'consultation_review',
      'update',
      clientReview.id
    );
  }, [clientReview]);

  return (
    <div>
      {expertReview && (
        <Review
          review={expertReview}
          title={
            isViewerRequester
              ? 'Your Feedback'
              : `Feedback from ${requester ? requester.first_name : 'Client'}`
          }
          targetName={`${expert ? expert.first_name : 'Expert'}`}
          reviewFromExpert={false}
          updateReviewEnabled={canUpdateExpertReview}
        />
      )}
      {clientReview && (
        <Review
          review={clientReview}
          title={
            isViewerExpert
              ? 'Your Feedback'
              : `Feedback from ${expert ? expert.first_name : 'Expert'}`
          }
          targetName={`${requester ? requester.first_name : 'Client'}`}
          reviewFromExpert
          updateReviewEnabled={canUpdateClientReview}
        />
      )}
    </div>
  );
};

export default FeedbackDetails;

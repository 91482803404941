import { useState, useEffect, useRef } from 'react';
import { createAudioMeter } from './audioMeter';

export default function useDetectSpeaker(audioRef) {
  const [playing, setPlaying] = useState(false);
  const [volume, setVolume] = useState(0);
  const meter = useRef();
  const rafID = useRef();
  const streamRef = useRef();

  const shutdownIfRunning = () => {
    if (meter.current) {
      meter.current.shutdown();
    }

    if (streamRef.current) {
      streamRef.current.disconnect();
    }
  };

  // Clone fixes a bug when `.createMediaElementSource()` after `setSinkId()`
  // More information:
  //   https://stackoverflow.com/a/46002828
  //   https://bugs.chromium.org/p/webrtc/issues/detail?id=5525
  const cloneAudio = (audio) => {
    const clone = new window.Audio(audio.src);

    // sync play
    audio.onplay = () => {
      clone.currentTime = 0;
      clone.play();
    };

    // reset playback
    clone.onended = () => {
      setVolume((meter.current.volume = 0));
    };

    return clone;
  };

  useEffect(() => {
    if (!audioRef.current) return;
    const AudioContext = window.AudioContext || window.webkitAudioContext;

    const audio = cloneAudio(audioRef.current);

    // Always shutdown previous mediaStream
    shutdownIfRunning();

    const audioContext = new AudioContext();

    // Limit on audioContext running
    if (!audioContext) return;

    // Create an AudioNode from the stream.
    const streamMediaSource = audioContext.createMediaElementSource(audio);

    // Create a new volume meter and connect it.
    meter.current = createAudioMeter(audioContext, 0.9, 0.99, 500);
    streamMediaSource.connect(meter.current);

    // Trigger callback that shows the level of the "Volume Meter"
    onLevelChange();
  }, [audioRef.current && audioRef.current.sinkId]);

  const onLevelChange = () => {
    // 6.9 allows to have a good reference between 0.0 and 1.0 volume.
    const newVolume = meter.current.volume * 6.9;
    if (newVolume !== volume) {
      setVolume(newVolume);
    }
    setPlaying(newVolume > 0);

    // set up the next visual callback
    rafID.current = window.requestAnimationFrame(onLevelChange);
  };

  return [playing, volume];
}

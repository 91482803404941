import React, { PureComponent } from 'react';
import cx from 'classnames';
import s from './PictureList.module.scss';

class PictureList extends PureComponent {
  render() {
    const { children, className } = this.props;
    return (
      <div className={cx(s.root, { [className]: !!className })}>
        {children.map((p) => (
          <div key={p.props.id} className={s.item}>
            {p}
          </div>
        ))}
      </div>
    );
  }
}

export default PictureList;

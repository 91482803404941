import React, { useCallback, useState, useMemo } from 'react';
import qs from 'query-string';
import { connect } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { Form, Field } from 'react-final-form';
import Link from '../../Link';
import FaqLink from './FaqLink';
import Button from '../../Button';
import Dialog from '../../Dialog';
import {
  TextField,
  PhoneInput,
  RadioGroup,
} from '../../FormAdapters/FormAdapters';
import {
  createOtpAuthConfiguration,
  authenticateOtp,
  otpQrCodeBase64,
  otpTypes,
} from '../../../actions/auth';
import { fetchUser } from '../../../actions/user';
import { capitalize, errorMessage } from '../../../core/util';
import { notify } from '../../../actions/ui';
import { fetchAppNotifications } from '../../../actions/appNotification';
import { darkGreen } from '../../../core/colors';

export const FAQ_URL =
  'https://support.onfrontiers.com/en/articles/4662547-two-factor-authentication';

const useStyles = makeStyles({
  qrCode: {
    width: 200,
    height: 200,
    margin: '0 auto',
  },
  title: {
    margin: '0 0 15px',
  },
  titleSuccess: {
    margin: 0,
    color: darkGreen,
  },
  paper: {
    fontSize: 14,
    textAlign: 'center',
    maxWidth: 410,
    boxSizing: 'border-box',
  },
  verificationCode: {
    maxWidth: 200,
    '& input': {
      fontSize: 20,
      textAlign: 'center',

      MozAppearance: 'textfield',
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
      },
    },
  },
  resend: {
    fontSize: 14,
    color: darkGreen,
    textTransform: 'none',
    fontWeight: 400,
    margin: '30px 0 0 5px',
    padding: '8px 11px',
  },
  text: {
    lineHeight: 1.5,
  },
});

function SuccessStep({ onClose, user, ...other }) {
  const s = useStyles();
  return (
    <Dialog fullWidth classes={{ paper: s.paper }} onClose={onClose} {...other}>
      <h4 className={s.titleSuccess}>All set!</h4>

      <p className={s.text}>
        Successfully set up two-factor authentication.
        {`${user ? '' : ' A new verification code may be required for login.'}`}
      </p>

      <Button onClick={onClose} fullWidth={false}>
        Done
      </Button>
    </Dialog>
  );
}

function SecurityDialog(props) {
  const s = useStyles();
  return (
    <Dialog
      fullWidth
      cancelLabel="Cancel"
      confirmLabel="Continue"
      cancelButtonProps={{ size: 'sm' }}
      confirmButtonProps={{ size: 'sm' }}
      onCancel={props.onClose}
      classes={{ paper: s.paper }}
      {...props}
    />
  );
}

function AuthenticatorStep({ otpAuthUri, qrCodeBase64, showFAQ }) {
  const s = useStyles();

  const secret = useMemo(() => qs.parse(otpAuthUri).secret, [otpAuthUri]);

  return (
    <>
      <h4 className={s.title}>Set up two-factor authentication</h4>

      <p className={s.text}>
        Scan the QR code below with an authentication app, such as Google
        Authenticator, on your phone.
      </p>

      <Link to={otpAuthUri}>
        <img
          className={s.qrCode}
          src={qrCodeBase64}
          alt="Two factor authentication key"
        />
      </Link>

      <p className={s.text}>
        If you are unable to see the code, type this key on your authentication
        app (time based password):&nbsp;
        <Link to={otpAuthUri}>
          <b>{secret}</b>
        </Link>
      </p>

      <Field
        component={TextField}
        changeOnBlur={false}
        autoFocus
        name="otp"
        label="Verification Code"
        variant="outlined"
        classes={{ root: s.verificationCode }}
        inputType="number"
      />

      {showFAQ && <FaqLink url={FAQ_URL} className={s.text} />}
    </>
  );
}

function VerificationStep({
  address,
  method,
  createOtpAuthConfiguration,
  authenticateOtp,
  notify,
  onSuccess,
  fetchUser,
  fetchAppNotifications,
  user,
  setOtp,
  ...other
}) {
  const s = useStyles();

  const handleSubmit = useCallback(async (values) => {
    try {
      const success = await authenticateOtp(values.otp);

      if (!success) {
        return { otp: 'Invalid verification code' };
      }

      if (user) {
        await fetchUser(user.username, { force: true, otpAuthEnabled: true });
        fetchAppNotifications({ forceReload: true });
      } else {
        await setOtp(values.otp);
      }

      onSuccess();
    } catch (err) {
      notify(
        'An error occurred when trying to setup two-factor authentication.',
        'error'
      );
      throw err;
    }
  }, []);

  const handleResend = useCallback(async () => {
    try {
      await createOtpAuthConfiguration(method, address);
      notify(`Verification code sent to ${address}`);
    } catch (err) {
      notify('An error occurred when trying to setup otp.', 'error');
      throw err;
    }
  }, [address]);

  const validate = useCallback((values) => {
    const errors = {};
    if (!(values.otp || '').trim()) {
      errors.otp = 'Required';
    }
    return errors;
  }, []);

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      subscription={{ submitting: true }}
    >
      {({ handleSubmit, submitting }) => {
        return (
          <SecurityDialog
            {...other}
            disableSubmit={submitting}
            onConfirm={handleSubmit}
          >
            <form onSubmit={(e) => handleSubmit(e)}>
              <h4 className={s.title}>Enter the code we sent you</h4>

              <div>
                <Field
                  component={TextField}
                  changeOnBlur={false}
                  autoFocus
                  name="otp"
                  label="Verification Code"
                  variant="outlined"
                  classes={{ root: s.verificationCode }}
                  inputType="number"
                />

                <Button
                  variant="text"
                  size="medium"
                  classes={{ root: s.resend }}
                  onClick={handleResend}
                >
                  Resend
                </Button>
              </div>
            </form>
          </SecurityDialog>
        );
      }}
    </Form>
  );
}

VerificationStep = connect(undefined, {
  createOtpAuthConfiguration,
  authenticateOtp,
  notify,
  fetchUser,
  fetchAppNotifications,
})(VerificationStep);

function MethodSelection({
  user,
  email,
  viewer,
  method,
  setMethod,
  createOtpAuthConfiguration,
  otpAuthUri,
  authenticateOtp,
  otpQrCodeBase64,
  fetchUser,
  fetchAppNotifications,
  onSuccess,
  handleVerificationSuccess,
  notify,
  handleExit,
  setOtp,
  ...other
}) {
  const s = useStyles();

  const [showFAQ, setShowFAQ] = useState(true);
  const [qrCodeBase64, setQRCodeBase64] = useState(undefined);

  const handleSubmit = useCallback(async (values) => {
    try {
      switch (values.method_state) {
        case 'email':
          await createOtpAuthConfiguration(values.method_state, values.email);
          onSuccess(values.email);
          break;
        case 'app':
          setShowFAQ(false);
          const success = await authenticateOtp(values.otp);
          if (!success) {
            setShowFAQ(true);
            return { otp: 'Invalid verification code' };
          }
          if (user) {
            await fetchUser(user.username, {
              force: true,
              otpAuthEnabled: true,
            });
            fetchAppNotifications({ forceReload: true });
          } else {
            setOtp(values.otp);
          }
          handleVerificationSuccess();
          break;
        default:
          await createOtpAuthConfiguration(values.method_state, values.phone);
          onSuccess(values.phone);
      }
    } catch (err) {
      if (err.message && err.message.startsWith('GraphQL Error')) {
        notify(errorMessage(err.message), 'error');
      } else {
        notify('An error occurred when trying to setup.', 'error');
        throw err;
      }
    }
  }, []);

  const validate = useCallback((values) => {
    const errors = {};
    if (
      values.method_state === otpTypes.email &&
      !(values.email || '').trim()
    ) {
      errors.email = 'Required';
    }
    if (values.method_state === otpTypes.sms && !(values.phone || '').trim()) {
      errors.phone = 'Required';
    }
    if (values.method_state === otpTypes.app && !(values.otp || '').trim()) {
      errors.otp = 'Required';
    }

    return errors;
  }, []);

  const handleChange = useCallback(async (value) => {
    try {
      await setMethod(value);
      if (value === otpTypes.app) {
        await createOtpAuthConfiguration(otpTypes.app, '');
        const res = await otpQrCodeBase64();
        setQRCodeBase64(res);
      }
    } catch (err) {
      notify(
        'An error occurred when trying to select the two-factor authenticator method.',
        'error'
      );
      throw err;
    }
  }, []);

  const initialValues = useMemo(() => ({ phone: viewer.phone }), []);

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      subscription={{ submitting: true }}
      initialValues={initialValues}
    >
      {({ handleSubmit, submitting }) => {
        return (
          <SecurityDialog
            {...other}
            disableSubmit={submitting}
            onConfirm={handleSubmit}
          >
            <form onSubmit={(e) => handleSubmit(e)}>
              <h4 className={s.title}>Set up two-factor authentication</h4>
              <Field
                name="method_state"
                component={RadioGroup}
                onChange={handleChange}
                label="Select the authentication method"
                FormControlProps={{ style: { marginTop: 20 } }}
                style={{ flexDirection: 'row', justifyContent: 'center' }}
                defaultValue={method}
                options={[
                  {
                    label: capitalize(otpTypes.email),
                    value: otpTypes.email,
                  },
                  // {
                  //   label: capitalize(otpTypes.sms),
                  //   value: otpTypes.sms,
                  // },
                ]}
              />
              {method === otpTypes.sms && (
                <Field
                  component={PhoneInput}
                  name="phone"
                  label="Phone"
                  variant="outlined"
                  showExampleOnError
                />
              )}
              {method === otpTypes.email && (
                <Field
                  name="email"
                  component={TextField}
                  defaultValue={email}
                  autoFocus
                  label="Email"
                  variant="outlined"
                  disabled={!user}
                />
              )}
              {method === otpTypes.app && (
                <AuthenticatorStep
                  otpAuthUri={otpAuthUri}
                  qrCodeBase64={qrCodeBase64}
                  showFAQ={showFAQ}
                />
              )}
            </form>
          </SecurityDialog>
        );
      }}
    </Form>
  );
}

MethodSelection = connect(
  (state) => ({
    viewer: state.viewer,
    otpAuthUri: state.auth.otpAuthUri,
  }),
  {
    notify,
    fetchUser,
    fetchAppNotifications,
    authenticateOtp,
    otpQrCodeBase64,
    createOtpAuthConfiguration,
  }
)(MethodSelection);

export function EnableOtpAuth({ user, email, setOtp, ...other }) {
  const initialStep = 'select';
  const initialMethod = otpTypes.email;

  const [step, setStep] = useState(initialStep);
  const [method, setMethod] = useState(initialMethod);
  const [address, setAddress] = useState('');

  const handleExit = useCallback(() => {
    setStep(initialStep);
    setMethod(initialMethod);
  }, []);

  const handleSelectionSuccess = useCallback((address) => {
    setStep('verification');
    setAddress(address);
  }, []);

  const handleVerificationSuccess = useCallback(() => setStep('success'), []);

  return (
    <>
      {step === 'select' && (
        <MethodSelection
          {...other}
          onExited={handleExit}
          onSuccess={handleSelectionSuccess}
          handleVerificationSuccess={handleVerificationSuccess}
          method={method}
          email={email}
          user={user}
          setMethod={setMethod}
          setOtp={setOtp}
        />
      )}
      {step === 'verification' && (
        <VerificationStep
          {...other}
          address={address}
          method={method}
          user={user}
          onExited={handleExit}
          onSuccess={handleVerificationSuccess}
          setOtp={setOtp}
        />
      )}
      {step === 'success' && (
        <SuccessStep {...other} user={user} onExited={handleExit} />
      )}
    </>
  );
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { white } from '../../core/colors';
import FAIcon from '../Icon/FAIcon';
import Link from '../Link';
import ImageOutline from './ImageOutline';
import { openImageDialog } from '../../actions/ui';
import s from './Image.module.scss';

class Image extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    editable: PropTypes.bool,
    src: PropTypes.string,
    imageStyle: PropTypes.object,
    style: PropTypes.object,
    input: PropTypes.object,
    dimensions: PropTypes.object,
    alt: PropTypes.string,
    href: PropTypes.string,
    accept: PropTypes.string,
    noStretch: PropTypes.bool,
  };

  state = {
    error: false,
  };

  handleUpload = () => {
    const { dimensions, onChange, accept, openImageDialog } = this.props;
    openImageDialog({ dimensions, accept }).then(onChange);
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    const oldSrc = this.props.input ? this.props.input.value : this.props.src;
    const newSrc = newProps.input ? newProps.input.value : newProps.src;
    if (oldSrc !== newSrc) this.clearError();
  }

  setError = () => {
    this.setState({ error: true });
  };

  clearError = () => {
    this.setState({ error: false });
  };

  render() {
    const {
      openImageDialog,
      input,
      editable,
      style,
      imageStyle,
      overlayStyle,
      href,
      alt,
      className,
      dimensions,
      emptyText = 'Upload a photo',
      accept,
      label,
      keepOutline,
      noStretch,
      ...other
    } = this.props;

    const { error } = this.state;

    const src = input ? input.value : this.props.src;

    if (!src && editable) {
      return keepOutline ? (
        <ImageOutline onClick={this.handleUpload} label={label}>
          <FAIcon
            iconSet="fal"
            icon="camera"
            size={24}
            style={{ marginBottom: 10 }}
          />
          {emptyText}
        </ImageOutline>
      ) : (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <div
          style={style}
          className={cx(s.root, s.noimage, className)}
          onClick={this.handleUpload}
        >
          <span>
            <FAIcon iconSet="fal" icon="camera" /> {emptyText}
          </span>
        </div>
      );
    }

    const editNav = editable ? (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <div
        className={s.overlay}
        style={overlayStyle}
        onClick={this.handleUpload}
      >
        <FAIcon
          iconSet="fal"
          icon="camera"
          style={{ marginRight: 0 }}
          color={white}
        />
      </div>
    ) : undefined;

    let img =
      !error && src ? (
        <img
          alt={alt}
          src={src}
          style={imageStyle}
          className={cx(s.img, {
            [s.nostretch]: noStretch,
          })}
          onError={this.setError}
          {...other}
        />
      ) : undefined;

    if (img && href && !editable) {
      img = <Link href={href}>{img}</Link>;
    }

    const component = (
      <div style={style} className={cx(s.root, className)}>
        {img}
        {editNav}
      </div>
    );

    return keepOutline ? (
      <ImageOutline label={label}>{component}</ImageOutline>
    ) : (
      component
    );
  }
}

Image = connect(undefined, {
  openImageDialog,
})(Image);

Image = Image;

export default Image;

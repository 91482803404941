import React from 'react';
import Grid from '@mui/material/Grid';
import ExpertWidgetVertical from '../ExpertWidgetVertical';
import MarketingSection from '../MarketingSection';

export default function FeaturedExperts({ profiles, children, ...other }) {
  profiles = profiles && profiles.filter(Boolean);
  if (!profiles || !profiles.length) return null;

  return (
    <MarketingSection
      titleStyle={{ textAlign: 'center' }}
      title="Featured Experts"
      {...other}
    >
      <Grid container justifyContent="center" spacing={4}>
        {profiles.map((p) => (
          <Grid key={p.id} item container xs={12} md={6} lg={3}>
            <ExpertWidgetVertical style={{ width: '100%' }} profile={p} />
          </Grid>
        ))}
      </Grid>

      {children}
    </MarketingSection>
  );
}

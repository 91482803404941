/* eslint-disable camelcase */
import React, { useMemo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import BasicInformationForm from '../../components/Forms/Profile/BasicInformationForm';
import { updateProfile, autofillProfile } from '../../actions/profile';
import history from '../../core/history';
import { hasOnlyLetters, isValidLinkedin } from '../../reducers/utils';

const getStyles = makeStyles((theme) => ({
  title: {
    fontSize: 30,
    fontWeight: 'bold',
    marginTop: 30,
    marginBottom: 0,
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
      marginTop: 15,
    },
  },
  subtitle: {
    fontSize: 16,
    marginBottom: 30,
  },
  loading: {
    marginTop: 100,
    textAlign: 'center',
  },
}));

function BasicInformation({
  nextUrl,
  profileId,
  viewer,
  profile,
  phoneRequired,
  updateProfile,
  autofillProfile,
  ...other
}) {
  const s = getStyles();
  const {
    first_name,
    last_name,
    picture_url,
    country,
    city,
    languages,
    linkedin_url,
    cv_url,
    title,
    summary,
  } = profile;
  const { can_autofill_profile: canAutofill } = viewer;
  const [sending, setSending] = useState(false);

  const initialValues = useMemo(
    () => ({
      first_name,
      last_name,
      picture: picture_url,
      country,
      city,
      languages: (languages || []).map((l) => l.code),
      linkedin_url,
      resume: cv_url,
      title,
      summary,
    }),
    []
  );

  const handleSubmit = useCallback(async (values) => {
    setSending(true);
    const { autofill, cv_url, first_name, last_name, languages } = values;

    if (canAutofill || cv_url) {
      await autofillProfile({
        autofill: autofill === 'true',
        cvUrl: cv_url,
      });
    }

    const result = await updateProfile({
      id: profileId,
      ...values,
      languages: languages.map((l) => ({
        code: l,
        fluency: 'professional_working',
      })),
      first_name: first_name.trim(),
      last_name: last_name.trim(),
    }).catch((err) => {
      const errors = {};

      if (err && err.message) {
        if (err.message.includes('first name')) {
          errors.first_name = 'Invalid name';
        } else if (err.message.includes('last name')) {
          errors.last_name = 'Invalid name';
        }
      }

      return { errors };
    });

    if (result?.errors) {
      setSending(false);
      return result?.errors;
    }

    history.push(nextUrl);
  }, []);

  const validate = useCallback((values) => {
    const errors = {};

    const fields = ['first_name', 'last_name', 'country', 'title', 'summary'];

    const { languages, linkedin_url: linkedinUrl } = values;

    if (canAutofill || values.cv_url) {
      fields.push('autofill');
    }

    if (!languages || !languages.length) {
      errors.languages = 'Required';
    }

    if (linkedinUrl && !isValidLinkedin(linkedinUrl)) {
      errors.linkedin_url = 'Must be a valid https://linkedin.com URL';
    }

    if (!(values.first_name || '').trim()) {
      errors.first_name = 'Required';
    }

    if (values.first_name && !hasOnlyLetters(values.first_name)) {
      errors.first_name = 'Only letters allowed';
    }

    if (!(values.last_name || '').trim()) {
      errors.last_name = 'Required';
    }

    if (values.last_name && !hasOnlyLetters(values.last_name)) {
      errors.last_name = 'Only letters allowed';
    }

    fields.forEach((f) => {
      if (!values[f]) errors[f] = 'Required';
    });

    return errors;
  }, []);

  return (
    <Grid container justifyContent="center">
      <Grid item md={8} sm={12}>
        <p className={s.title}>Enter your basic information</p>
        <p className={s.subtitle}>
          Start off by adding your basic profile information and hourly rate.
        </p>

        <Form
          component={BasicInformationForm}
          profileId={profileId}
          nextUrl={nextUrl}
          canAutofill={canAutofill}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validate={validate}
          sending={sending}
          {...other}
        />
      </Grid>
    </Grid>
  );
}

BasicInformation.propTypes = {
  nextUrl: PropTypes.string.isRequired,
  profileId: PropTypes.string.isRequired,
};

BasicInformation = connect(
  (state, ownProps) => {
    const profile = state.profiles.fullProfiles[ownProps.profileId] || {};
    return {
      allCountries: state.countries,
      profile,
    };
  },
  {
    updateProfile,
    autofillProfile,
  }
)(BasicInformation);

export default BasicInformation;

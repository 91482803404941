import React from 'react';

import Layout from '../../components/MarketingLayout';
import Hero from '../../components/MarketingLayout/MarketingHero';
import Customers from '../../components/Customers';
import MarketingSection from '../../components/MarketingSection';
import Testimonial from '../../components/Testimonial';
import CaseStudyList from '../../components/CaseStudyList';
import { teal500 } from '../../core/colors';
import SplashTitle from '../../components/SplashTitle/SplashTitle';
import BookCallCta from '../../components/BookCallCta/BookCallCta';
import s from './SuccessStories.module.scss';

function SuccessStories({ caseStudies }) {
  return (
    <Layout>
      <Hero backgroundImage={require('./hero.jpg')}>
        <SplashTitle>Success Stories</SplashTitle>
        <p className={s.subTitle}>
          We have connected professionals across 100+ markets globally. Whether
          you are a business manager, investor or consultant, our network helps
          you make faster and better decisions in a cost effective way.
        </p>
      </Hero>

      <MarketingSection>
        <CaseStudyList cases={caseStudies} />
      </MarketingSection>

      <MarketingSection darkGreen padding={50}>
        <Testimonial
          picture={require('./testimonial.png')}
          name="Busiswa Vilakazi"
          title="Investment Analyst at CrossBoundary"
          iconSize={40}
          pictureSize={84}
          iconColor={teal500}
        >
          I was really impressed that OnFrontiers found an expert for my project
          at all, but they also found someone within a couple of hours. There’s
          really no public data or anything available for this project. I was in
          some hot water and OnFrontiers really saved me.
        </Testimonial>
      </MarketingSection>

      <BookCallCta />

      <Customers />
    </Layout>
  );
}

export default SuccessStories;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import CopyToClipboard from 'react-copy-to-clipboard';
import { TextField } from '@mui/material';
import Button from '../Button';
import { shortenUrl } from '../../actions/url';
import { white, darkGreen } from '../../core/colors';

class ShareableLink extends Component {
  static defaultProps = {
    enableShorten: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      url: props.url,
      shortened: false,
    };
  }

  shorten = async () => {
    const { url } = this.props;

    this.setState({
      shortened: 'loading',
    });

    const newUrl = await this.props.shortenUrl(url);
    this.setState({
      url: `https://${newUrl}`,
      shortened: true,
    });
  };

  render() {
    const { enableShorten, inputStyle, buttonStyle } = this.props;
    const { url, shortened } = this.state;

    return (
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <TextField
          label="LinkedIn referral URL"
          value={shortened === 'loading' ? 'Shortening...' : url}
          style={{ flex: 1, marginRight: 10, ...inputStyle }}
        />
        <CopyToClipboard text={url}>
          <Button size="small" backgroundColor={white} fontColor={darkGreen}>
            Copy Link
          </Button>
        </CopyToClipboard>
        {enableShorten && !shortened && (
          <Button
            size="small"
            onClick={this.shorten}
            style={{ marginLeft: 10, ...buttonStyle }}
            backgroundColor={white}
            fontColor={darkGreen}
          >
            Shorten
          </Button>
        )}
      </div>
    );
  }
}

ShareableLink = connect(undefined, {
  shortenUrl,
})(ShareableLink);

export default ShareableLink;

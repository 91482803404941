import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { fetchAllGroups } from '../../actions/group';
import CustomSelect from '../Select';

class SelectGroup extends PureComponent {
  static defaultProps = {
    includeEmpty: true,
    limit: 5,
  };

  fetchGroups = () => {
    const { fetchAllGroups, groups } = this.props;

    if (groups.edges.length === 0) {
      fetchAllGroups();
    }
  };

  render() {
    const {
      autoComplete,
      groups,
      fetchAllGroups,
      includeEmpty,
      autoFocus,
      input = {},
      ...other
    } = this.props;

    return (
      <CustomSelect
        placeholder="Search team..."
        {...input}
        {...other}
        TextFieldProps={{ autoFocus }}
        rawValueOnChange
        id="autoCompleteTeam"
        clearOnBlur
        disableClearable
        autocomplete
        options={groups.edges.map(({ node: g }) => ({
          value: g.id,
          label: g.name,
        }))}
        onFocus={this.fetchGroups}
      />
    );
  }
}

SelectGroup = connect(
  (state, ownProps) => ({
    viewer: state.viewer,
    groups: ownProps.groups || state.groups.all,
  }),
  {
    fetchAllGroups,
  }
)(SelectGroup);

SelectGroup.fetch = fetchAllGroups;

export default SelectGroup;

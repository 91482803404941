import React from 'react';
import TeamAboutPage from './TeamAboutPage';
import { fetchGroup } from '../../actions/group';
import { fetchSiteSettings } from '../../actions/siteSettings';

export default {
  path: '/team/:slug/about',

  async action({ store, params }) {
    const group = await store.dispatch(
      fetchGroup(params.slug, {
        aboutPage: true,
        publicData: true,
      })
    );

    if (!group || !group.about_page) {
      return;
    }

    await store.dispatch(fetchSiteSettings());

    return {
      disableIndexing: !group.about_page.search_engine_index,
      title: `Join ${group.name}`,
      component: <TeamAboutPage group={group} />,
    };
  },
};

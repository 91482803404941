import React from 'react';
import ClientIntake from './ClientIntake';

export default function Default({ ...other }) {
  return (
    <ClientIntake
      title="The best investment in your organization’s knowledge"
      subtitle={
        <div>
          Experts on any topic, in any market, in under 48 hours.
          <br />
          Fill out the form below and we will respond promptly.
        </div>
      }
      fields={{
        name: { required: true },
        organization: { required: true },
        email: { required: true },
        phone: { required: true },
        use_case: { required: false },
        questions: { required: false },
      }}
      source="default"
      {...other}
    />
  );
}

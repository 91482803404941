import React, { Component } from 'react';
import { connect } from 'react-redux';
import BlogCover from './BlogCover';
import MediaQuery from '../../components/MediaQuery';
import Divider from '../../components/Divider';
import Body from '../../components/Body';
import Newsletter from '../../components/Newsletter';
import Button from '../../components/Button';
import BlogPostPreview, {
  postToProps,
} from '../../components/Blog/BlogPostPreview';
import BlogPostPreviewList from '../../components/Blog/BlogPostPreviewList';
import BlogTag from '../../components/Blog/BlogTag/BlogTag';
import Layout from '../../components/MarketingLayout';
import { lightTan } from '../../core/colors';
import { fetchPosts } from '../../actions/blog';
import s from './Blog.module.scss';

function Topics({ topics }) {
  return (
    <div className={s.topics}>
      <Divider
        height={2}
        style={{ marginTop: 0, marginBottom: 15 }}
        color={lightTan}
      />
      <div className={s.topicsTitle}>Popular Topics</div>
      {topics.map((c) => (
        <div className={s.topic} key={c.slug}>
          <BlogTag href={c.link} text={c.name} />
        </div>
      ))}
    </div>
  );
}

class Blog extends Component {
  loadPosts = () => {
    this.props.fetchPosts('landing', {
      offset: this.props.posts.length,
      per_page: 6,
      _embed: 1,
    });
  };

  render() {
    const { categories, posts, fetching, hasMorePosts } = this.props;

    const coverPost = posts[0];
    const highlightedPost = postToProps(posts[1], 'large');
    const topPosts = [posts[2], posts[3]].map(postToProps);
    const otherPosts = posts.slice(4, posts.length).map(postToProps);

    return (
      <Layout className={s.root}>
        <BlogCover post={coverPost} />

        <Body>
          <MediaQuery maxWidth={700}>
            {(isMobileVersion) => (
              <BlogPostPreview
                highlight={!isMobileVersion}
                post={highlightedPost}
                className={s.highlightedPost}
              />
            )}
          </MediaQuery>

          <BlogPostPreviewList posts={topPosts}>
            <Topics topics={categories} />
          </BlogPostPreviewList>
        </Body>

        <Newsletter
          style={{ marginTop: 25, marginBottom: 10 }}
          title="Q&As with Experts"
          subTitle="Subscribe to our digest for interviews you won’t find anywhere else."
        />

        <Body>
          <BlogPostPreviewList posts={otherPosts} />
        </Body>

        <div className={s.action}>
          {hasMorePosts ? (
            <Button onClick={this.loadPosts} disabled={fetching}>
              Load More Posts
            </Button>
          ) : (
            <h3>No more posts to read.</h3>
          )}
        </div>
      </Layout>
    );
  }
}

export default connect((state) => state.blog.posts.landing || {}, {
  fetchPosts,
})(Blog);

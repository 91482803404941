import actionTypes from '../core/ActionTypes';

const { BLOG__FETCH, BLOG__BATCH_ADD } = actionTypes;

const initialState = {
  posts: {},
};

export default function blogReducer(state = initialState, action) {
  switch (action.type) {
    case BLOG__FETCH:
    case BLOG__BATCH_ADD:
      return {
        ...state,
        posts: postsReducer(state.posts, action),
        fetching: true,
      };
    default:
      return state;
  }
}

function postsReducer(state = {}, action) {
  switch (action.type) {
    case BLOG__FETCH:
    case BLOG__BATCH_ADD:
      const { collection } = action;
      return {
        ...state,
        [collection]: postsCollectionReducer(state[collection], action),
      };
    default:
      return state;
  }
}

function postsCollectionReducer(state = { posts: [] }, action) {
  switch (action.type) {
    case BLOG__FETCH:
      return {
        ...state,
        fetching: true,
      };
    case BLOG__BATCH_ADD:
      const { posts, hasMorePosts } = action;
      return {
        ...state,
        fetching: false,
        posts: [...state.posts, ...posts],
        hasMorePosts,
      };
    default:
      return state;
  }
}

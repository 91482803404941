import React from 'react';
import { fetchLandingPage } from '../../actions/landing';
import {
  fetchExpertShowcase,
  fetchExperts,
} from '../../actions/expertShowcase';
import { fetchDomain } from '../../actions/domain';

export default {
  children: [
    {
      subdomain: '*',
      path: '/',
      async action({ store }, _, { subdomain }) {
        if (!subdomain) return;

        const domain = await store.dispatch(fetchDomain(subdomain));
        const { root_subdomain_redirect: urlRedirect } = domain || {};

        if (urlRedirect) {
          return { redirect: urlRedirect };
        }
      },
    },
    {
      subdomain: '*',
      path: '/:path',

      async action({ store }, params, { subdomain }) {
        if (!subdomain) return;

        const ClientLanding = await new Promise((resolve) => {
          require.ensure(
            [],
            (require) => resolve(require('./ClientLanding').default),
            'clientLanding'
          );
        });

        const { path } = params;
        if (!path) return;

        const page = await store.dispatch(
          fetchLandingPage({ subdomain, path })
        );
        if (!page) return;

        return {
          title: page.page_title,
          googleTagManagerId: page.google_tag_manager_id,
          disableIndexing: true,
          meta: {
            imageUrl: page.meta_image_url,
          },
          component: <ClientLanding page={page} />,
        };
      },
    },

    {
      subdomain: '*',
      path: '/:path/:sectionPath?',

      async action({ store }, params, { subdomain }) {
        if (!subdomain) return;

        const ClientExpertShowcase = await new Promise((resolve) => {
          require.ensure(
            [],
            (require) => resolve(require('./ClientExpertShowcase').default),
            'clientLanding'
          );
        });

        const { path, sectionPath } = params;
        if (!path && !sectionPath) return;

        const page = await store.dispatch(
          fetchExpertShowcase({ subdomain, path })
        );
        if (!page || page.categories.length === 0) return;

        if (!sectionPath) {
          const section = page.categories[0].sections[0];
          if (!section) return;
          return { redirect: `/${page.path}/${section.slug}` };
        }

        let section;
        for (let i = 0; i < page.categories.length; i++) {
          const c = page.categories[i];
          section = c.sections.find((s) => s.slug === sectionPath);
          if (section) break;
        }
        if (!section) return;

        let { experts } = section;
        if (!experts) {
          experts = await store.dispatch(
            fetchExperts(subdomain, path, section.id)
          );
        }

        return {
          id: 'expert-showcase',
          title: page.page_title,
          googleTagManagerId: page.google_tag_manager_id,
          disableSamePathScrollRestoration: true,
          disableIndexing: true,
          meta: {
            imageUrl: page.meta_image_url,
          },
          component: (
            <ClientExpertShowcase
              page={page}
              section={section}
              experts={experts}
            />
          ),
        };
      },
    },
  ],
};

import React, { useMemo } from 'react';
import Duration from '../../core/duration';
import { Select } from '../FormAdapters';
import { formatCredits, roundOffCredits } from '../../core/util';

export default function ({ showCredits, creditRate, ...other }) {
  const options = useMemo(
    () =>
      [30, 45, 60].map((d) => {
        // same rule used to create transactions
        const ratePerMinute = creditRate / 60;
        const creditCents = ratePerMinute * d;
        const credits = roundOffCredits(creditCents);

        const creditsLabel = credits / 100 === 1 ? 'credit' : 'credits';
        const creditsText = ` (${formatCredits(credits)} ${creditsLabel})`;
        return {
          value: Duration.parse(`${d}m`).toString(),
          label: `${d} minutes ${showCredits ? creditsText : ''}`,
        };
      }),
    [showCredits, creditRate]
  );

  return (
    <Select
      label="How long do you expect the call to last?"
      options={options}
      {...other}
    />
  );
}

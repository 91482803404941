import React from 'react';
import ClientIntake from './ClientIntake';

export default function Default({ ...other }) {
  return (
    <ClientIntake
      title="Request Access"
      subtitle={
        <div>
          Access to OnFrontiers.org is currently by invitation only.
          <br />
          Fill out the form below and we will respond promptly.
        </div>
      }
      fields={{
        name: { required: true },
        title: { required: true },
        organization: { required: true },
        email: { required: true, label: 'Work Email' },
      }}
      source="org"
      {...other}
    />
  );
}

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import moment from 'moment-timezone';
import { MenuItem } from '@mui/material';
import Tooltip from 'rc-tooltip';
import FAIcon from '../Icon/FAIcon';
import Link from '../Link';
import CountBox from '../CountBox';
import IconMenu from '../IconMenu';
import DeleteExpertRequestDialog from '../DeleteDialog/DeleteExpertRequestDialog';
import { teal500, red500 } from '../../core/colors';
import { formatDateTime } from '../../core/util';
import {
  updateExpertRequestState,
  deleteExpertRequest,
} from '../../actions/expertRequest';
import { popup } from '../../actions/ui';
import { track } from '../../actions/tracking';
import s from './ExpertRequestPreview.module.scss';
import CloseExpertRequestDialog from '../CloseExpertRequestDialog';

class ExpertRequestPreview extends PureComponent {
  state = {
    deleteConfirmationOpen: false,
    closeExpertRequestDialogOpen: false,
  };

  handleUpdateState = (closeReason) => {
    const { request, updateExpertRequestState } = this.props;
    const state = request.state === 'open' ? 'closed' : 'open';
    updateExpertRequestState(request.id, state, closeReason);
  };

  setCloseExpertRequestDialogOpen = () => {
    this.setState({ closeExpertRequestDialogOpen: true });
  };

  setCloseExpertRequestDialogClosed = () => {
    this.setState({ closeExpertRequestDialogOpen: false });
  };

  setCloseExpertRequestDialogOpen = () => {
    this.setState({ closeExpertRequestDialogOpen: true });
  };

  setCloseExpertRequestDialogClosed = () => {
    this.setState({ closeExpertRequestDialogOpen: false });
  };

  confirmDelete = () => this.setState({ deleteConfirmationOpen: true });

  closeConfirmDelete = () => this.setState({ deleteConfirmationOpen: false });

  showPremiumFeaturePromo = () => {
    const { popup, track } = this.props;
    track('promo.show.request.archived');
    popup({
      layout: 'promo',
      title: 'Request archival is a premium feature',
      contents:
        'Trying to access a request over 90 days old? ' +
        'Upgrade to our Advanced or Enterprise account ' +
        'to view your full project history.',
      buttons: [
        {
          label: 'Start a chat',
          primary: true,
          callback: () => {
            track('promo.chat.request.archived');
            window.Intercom('show');
          },
        },
      ],
      links: [{ label: 'No thanks.', callback: () => { } }],
    });
  };

  render() {
    const {
      viewer,
      className,
      request,
      project,
      showState,
      showContextActions,
      showAddAction,
      showDeleteAction,
      showCloseAction,
    } = this.props;

    const state = stateAppearance(request.state);

    const blocked =
      request.archived && !request.permissions.includes('view_archived');

    return (
      <div className={cx(s.request, className)}>
        <div className={s.requestCard}>
          {blocked && (
            <div
              className={s.blockedArchived}
              onClick={this.showPremiumFeaturePromo}
              onKeyPress={this.showPremiumFeaturePromo}
            />
          )}
          <div className={s.requestTitle}>
            <Link
              to={request.html_url}
              className={s.requestTitleLink}
              id={`expertRequest-${request.id}`}
            >
              <FAIcon
                icon="user-circle-o"
                style={{ width: 20, height: 20, marginRight: 7 }}
              />
              <span>{request.name}</span>
            </Link>

            <Tooltip
              overlay={formatDateTime(request.created_at, viewer.timezone)}
              trigger={['hover', 'click']}
              placement="top"
            >
              <div className={s.requestTitleAge}>
                Created {moment(request.created_at).fromNow()}
              </div>
            </Tooltip>
          </div>
          <div className={s.requestDetails}>
            <div className={s.requestStats}>
              <CountBox
                grayOutZero
                className={s.countBox}
                count={request.stats.candidates_count}
                label="Matches"
                linkTo={`${request.html_url}?section=experts`}
              />
              <CountBox
                grayOutZero
                className={s.countBox}
                count={request.stats.calls}
                label="Calls"
                linkTo={`${request.html_url}?section=consultations`}
              />
            </div>
            <div className={s.requestControls}>
              {showState && (
                <div className={s.requestControlsState}>
                  <FAIcon
                    size={20}
                    icon={state.icon.glyph}
                    color={state.icon.color}
                    style={{ verticalAlign: 'middle' }}
                  />
                  <span className={s.requestControlsStateLabel}>
                    {state.label}
                  </span>
                </div>
              )}
              {showContextActions && request.permissions && (
                <IconMenu buttonStyle={{ marginLeft: 20 }}>
                  {showCloseAction && (
                    <MenuItem
                      onClick={() => {
                        if (request.state === 'open') {
                          this.setCloseExpertRequestDialogOpen();
                        } else {
                          this.handleUpdateState();
                        }
                      }}
                      disabled={!request.permissions.includes('update')}
                    >
                      {request.state === 'open' ? 'Close' : 'Reopen'}
                    </MenuItem>
                  )}
                  {project && showAddAction && (
                    <Link to={`/request_expert?project_id=${project.id}`} menu>
                      <MenuItem key="addExpertRequest">
                        Add Expert Request
                      </MenuItem>
                    </Link>
                  )}
                  <Link
                    to={`/request_expert/details?copy_from=${request.id}`}
                    menu
                  >
                    <MenuItem key="makeACopy">Make a Copy</MenuItem>
                  </Link>
                  {showDeleteAction && (
                    <MenuItem
                      onClick={this.confirmDelete}
                      disabled={
                        !request.permissions.includes('delete') ||
                        (request.stats && request.stats.calls > 0)
                      }
                    >
                      Delete Request
                    </MenuItem>
                  )}
                </IconMenu>
              )}
            </div>
          </div>
        </div>
        <CloseExpertRequestDialog
          expertRequestId={request.id}
          onConfirm={this.handleUpdateState}
          onReset={this.setCloseExpertRequestDialogClosed}
          onCancel={this.setCloseExpertRequestDialogClosed}
          onClose={this.setCloseExpertRequestDialogClosed}
          open={this.state.closeExpertRequestDialogOpen}
        />
        <DeleteExpertRequestDialog
          expertRequestId={request.id}
          onConfirm={this.closeConfirmDelete}
          onCancel={this.closeConfirmDelete}
          open={this.state.deleteConfirmationOpen}
        />
      </div>
    );
  }
}

ExpertRequestPreview = connect(
  (state) => ({
    viewer: state.viewer,
  }),
  {
    updateExpertRequestState,
    deleteExpertRequest,
    popup,
    track,
  }
)(ExpertRequestPreview);

ExpertRequestPreview = ExpertRequestPreview;

export default ExpertRequestPreview;

function stateAppearance(state) {
  if (!state || state === '' || state === 'open') {
    return {
      label: 'Active',
      icon: { glyph: 'check-circle', color: teal500 },
    };
  }
  if (state === 'closed') {
    return {
      label: 'Closed',
      icon: { glyph: 'times-circle', color: red500 },
    };
  }
  throw new Error(`unknown expert request state ${state}`);
}

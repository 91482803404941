import React, { useMemo, useCallback } from 'react';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Tooltip from 'rc-tooltip';
import FAIcon from '../../components/Icon/FAIcon';
import Link from '../../components/Link/Link';
import Button from '../../components/Button';
import Logo from './Logo';
import {
  Checkbox,
  TextField,
} from '../../components/FormAdapters/FormAdapters';
import { notify } from '../../actions/ui';
import { updateGroupAboutPage } from '../../actions/group';
import { presignedFilestackURL } from '../../actions/filestack';
import { darkGreen } from '../../core/colors';
import { safeUrl } from '../../core/util';

const useStyles = makeStyles(() => ({
  textField: {
    display: 'block',
    maxWidth: 350,
  },
}));

function AboutPage({
  aboutPage,
  group,
  updateGroupAboutPage,
  presignedFilestackURL,
  notify,
  isViewerAdmin,
}) {
  const s = useStyles();

  const initialValues = useMemo(() => {
    return {
      enabled: aboutPage.enabled,
      public: aboutPage.public,
      search_engine_index: aboutPage.search_engine_index,
      logo_link_url: aboutPage.logo_link_url,
      externalBodyLogoUrl: aboutPage.body_logo_url,
    };
  }, [aboutPage]);

  const validate = useCallback(({ logo_link_url: logoLinkUrl }) => {
    const errors = {};
    if (logoLinkUrl && !safeUrl(logoLinkUrl)) {
      errors.logo_link_url = 'Please provide a valid web page URL';
    }
    return errors;
  }, []);

  const handleSubmit = async (values) => {
    try {
      await updateGroupAboutPage({ group_id: group.id, ...values });

      notify('Team settings updated.', 'success');
    } catch (err) {
      notify('Error when updating team settings.', 'error');
    }
  };

  const helpIcon = useMemo(
    () => (
      <FAIcon
        iconSet="far"
        icon="question-circle"
        size={14}
        color={darkGreen}
        style={{ marginLeft: 8 }}
      />
    ),
    []
  );

  const labelEnabled = useMemo(
    () => (
      <>
        Enabled
        {aboutPage.html_url && (
          <Link newTab to={aboutPage.html_url}>
            <FAIcon
              iconSet="far"
              icon="external-link"
              size={14}
              color={darkGreen}
              style={{ marginLeft: 8 }}
            />
          </Link>
        )}
      </>
    ),
    [aboutPage.html_url]
  );

  const labelPublic = useMemo(
    () => (
      <>
        Public
        <Tooltip
          overlay={
            <div style={{ maxWidth: 300 }}>
              When not public, only team members and experts in your network are
              able to view this page.
            </div>
          }
          trigger={['hover', 'click']}
          placement="right"
        >
          {helpIcon}
        </Tooltip>
      </>
    ),
    []
  );

  const labelSearchIndex = useMemo(
    () => (
      <>
        Allow indexing by search engines
        <Tooltip
          overlay={
            <div style={{ maxWidth: 300 }}>
              When enabled, the page will appear on search results. The
              &quot;Public&quot; setting must be enabled.
            </div>
          }
          trigger={['hover', 'click']}
          placement="right"
        >
          {helpIcon}
        </Tooltip>
      </>
    ),
    []
  );

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      initialValues={initialValues}
    >
      {({ form, handleSubmit, values }) => {
        return (
          <form onSubmit={(e) => handleSubmit(e)}>
            <Field
              type="checkbox"
              component={Checkbox}
              label={labelEnabled}
              name="enabled"
              FormControlProps={{ margin: 'dense' }}
            />

            <Field
              type="checkbox"
              component={Checkbox}
              label={labelPublic}
              name="public"
              FormControlProps={{ margin: 'dense' }}
              onChange={(_, checked) => {
                form.change('public', checked);
                if (!checked) {
                  form.change('search_engine_index', false);
                }
              }}
            />

            <Field
              type="checkbox"
              component={Checkbox}
              disabled={!values.public}
              label={labelSearchIndex}
              name="search_engine_index"
              FormControlProps={{ margin: 'dense' }}
            />

            <Logo
              label="Logo"
              src={values.externalBodyLogoUrl}
              onChange={async (file) => {
                const bodyLogoUrl = await presignedFilestackURL(file.url);
                form.change('body_logo_url', file.url);
                form.change('externalBodyLogoUrl', bodyLogoUrl);
              }}
              alternateSvgUpload={isViewerAdmin}
            />

            <Field
              component={TextField}
              name="logo_link_url"
              label="Logo Link URL"
              placeholder="https://yourorganization.com"
              classes={{ root: s.textField }}
              parse={(v) => v}
            />

            <Button type="submit" style={{ marginTop: 20 }} size="medium">
              Save
            </Button>
          </form>
        );
      }}
    </Form>
  );
}

AboutPage = connect(
  (state, { groupId }) => {
    const group = state.groups.default.edges.find(
      (e) => e.node.id === groupId
    ).node;

    return {
      group,
      aboutPage: group.about_page || {},
    };
  },
  {
    updateGroupAboutPage,
    presignedFilestackURL,
    notify,
  }
)(AboutPage);

export default AboutPage;

import React from 'react';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import RemoveIconMaterial from '@mui/icons-material/RemoveCircleRounded';
import IconButton from '@mui/material/IconButton';
import { red500 } from '../../../../core/colors';

const useStyles = makeStyles(() => ({
  root: {
    color: red500,
    padding: 0,
    fontSize: 36,
  },
}));

export default function RemoveButton({
  onClick,
  size = 'small',
  className,
  ...rest
}) {
  const s = useStyles();

  return (
    <IconButton
      {...rest}
      className={cx(s.root, className)}
      size={size}
      onClick={onClick}
    >
      <RemoveIconMaterial fontSize="inherit" />
    </IconButton>
  );
}

import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import LongText from '../LongText';
import { darkGray, darkBrown } from '../../core/colors';

const getStyles = makeStyles((theme) => ({
  sectionItem: {
    marginBottom: 30,
    '&:last-child': {
      marginBottom: 0,
    },
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
    },
  },
  sectionItemTitle: {
    display: 'inline-block',
    margin: '0 15px 0 0',
    fontWeight: 'bold',
    fontSize: 20,
    color: darkBrown,
    [theme.breakpoints.down('lg')]: {
      fontSize: 20,
    },
  },
  sectionItemSubTitle: {
    fontWeight: 500,
    color: darkGray,
    marginBottom: 5,
    [theme.breakpoints.down('lg')]: {
      fontSize: 14,
    },
  },
  sectionItemDescription: {
    marginTop: 10,
    fontSize: 16,
    lineHeight: '24px',
  },
  sectionItemPeriod: {
    fontSize: 16,
    color: darkGray,
    display: 'inline-block',
    [theme.breakpoints.down('lg')]: {
      fontSize: 14,
      display: 'block',
      marginTop: 5,
    },
  },
}));

export default ({ subTitle, title, description, period, children }) => {
  const s = getStyles();

  return (
    <div className={s.sectionItem}>
      <div className={s.sectionItemSubTitle}>{subTitle}</div>
      {(title || period) && (
        <div>
          <h3 className={s.sectionItemTitle}>{title}</h3>
          <div className={s.sectionItemPeriod}>{period}</div>
        </div>
      )}
      {description && (
        <LongText className={s.sectionItemDescription} text={description} />
      )}
      {children}
    </div>
  );
};

import ActionTypes from '../core/ActionTypes';
import { getCache, setCache } from '../core/util';

const initialState = {
  dismissed: [],
  dismissedPermanently: [],
};

export default function ui(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.LOAD_FROM_LOCAL_STORAGE:
      return {
        ...state,
        dismissed: getCache('dismissedConsultationReviews') || [],
        dismissedPermanently:
          getCache('permanentlyDismissedConsultationReviews') || [],
      };
    case ActionTypes.CONSULTATION__DISMISS_REVIEW:
      const dismissed = [...state.dismissed, action.id];
      setCache('dismissedConsultationReviews', dismissed);

      let { dismissedPermanently } = state;
      if (action.permanent) {
        dismissedPermanently = [...state.dismissedPermanently, action.id];
        setCache(
          'permanentlyDismissedConsultationReviews',
          dismissedPermanently
        );
      }

      return {
        ...state,
        dismissed,
        dismissedPermanently,
      };
    default:
      return state;
  }
}

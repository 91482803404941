import ActionTypes from '../core/ActionTypes';

const { SECTOR__REMOVE, SECTOR__BATCH_ADD } = ActionTypes;

const initialState = {
  all: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SECTOR__REMOVE:
      return {
        ...state,
        all: state.all.filter((p) => p.id !== action.id),
      };
    case SECTOR__BATCH_ADD:
      return {
        ...state,
        all: action.sectors,
      };
    default:
      return state;
  }
}

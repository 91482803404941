import React from 'react';
import UserRef from './UserRef';
import ConsultationRef from './ConsultationRef';
import RequestRef from './RequestRef';

function ConsultationRequestedActivity({
  activity: {
    context: {
      expert,
      requester,
      consultation,
      group,
      expert_request: expertRequest,
    },
  },
}) {
  return (
    <div>
      <UserRef user={requester} link /> requested a{' '}
      <ConsultationRef consultation={consultation} /> with{' '}
      <UserRef user={expert} />{' '}
      <RequestRef expertRequest={expertRequest} group={group} optional />
    </div>
  );
}

export default ConsultationRequestedActivity;

import React from 'react';

import s from './TypingIndicator.module.scss';

function TypingIndicator() {
  return (
    <div className={s.typingIndicator}>
      <span className={s.dot} />
      <span className={s.dot} />
      <span className={s.dot} />
    </div>
  );
}

export default TypingIndicator;

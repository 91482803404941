/* eslint-disable camelcase */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import { saveExpertRequest } from '../../actions/expertRequest';
import { saveProject } from '../../actions/project';
import EditDialog from '../../components/EditDialog';
import MediaQuery from '../../components/MediaQuery';
import { SCREEN_XS } from '../../constants';

class EditExpertRequest extends PureComponent {
  static propTypes = {
    children: PropTypes.element,
    component: PropTypes.func,
    reset: PropTypes.func,
    array: PropTypes.object,
    change: PropTypes.func,
    handleSubmit: PropTypes.func,
    saveExpertRequest: PropTypes.func,
    formValues: PropTypes.object,
    submitOnClick: PropTypes.bool,
    onClose: PropTypes.func,
    canEditQueries: PropTypes.bool,
  };

  static contextTypes = {
    store: PropTypes.shape({
      dispatch: PropTypes.func.isRequired,
    }).isRequired,
  };

  handleSubmit = async () => {
    const {
      formValues,
      onClose,
      onSubmit,
      reset,
      saveExpertRequest,
      saveProject,
      initialValues,
      canEditQueries,
    } = this.props;

    const {
      project_name: projectName,
      group_id: groupId,
      tracking_code: trackingCode,

      project_add_new,
      project,
      tags,
      ...expertRequestValues
    } = formValues;

    let projectId = formValues.project_id;

    try {
      if (!projectId) {
        const createdProject = await saveProject({
          name: projectName || formValues.name,
          tracking_code: trackingCode,
          group_id: groupId,
        });
        projectId = createdProject.id;
      }

      await saveExpertRequest(
        {
          ...expertRequestValues,
          project_id: projectId,
        },
        {
          fetchProject: initialValues.project_id !== projectId,
          includeQueries: canEditQueries,
        }
      );

      reset();

      onClose('Expert Request updated.', false);

      if (onSubmit) {
        const { store } = this.context;
        return onSubmit({ store });
      }
    } catch (err) {
      onClose('An error occurred when updating the expert request.', true);
    }
  };

  render() {
    const {
      formValues,
      children,
      component,
      array,
      change,
      handleSubmit,
      submitOnClick,
      reset,
      ...props
    } = this.props;

    return (
      <EditDialog
        {...props}
        onClose={() => this.props.onClose(undefined, false)}
        onSubmit={handleSubmit(this.handleSubmit)}
        onReset={() => reset()}
        showSubmit={!submitOnClick}
      >
        <MediaQuery maxWidth={SCREEN_XS}>
          {(isMobileVersion) =>
            component
              ? React.createElement(component, {
                  formValues,
                  array,
                  change,
                  isMobileVersion,
                  onClick: submitOnClick
                    ? handleSubmit(this.handleSubmit)
                    : undefined,
                })
              : children
          }
        </MediaQuery>
      </EditDialog>
    );
  }
}

EditExpertRequest = reduxForm({
  form: 'editExpertRequest',
  enableReinitialize: true,
  touchOnChange: true,
})(EditExpertRequest);

EditExpertRequest = connect(
  (state, ownProps) => {
    return {
      formValues: getFormValues('editExpertRequest')(state) || {},
      validate: ownProps.component.validate,
      projects: state.projects.names,
      groups: state.groups.all,
    };
  },
  {
    saveExpertRequest,
    saveProject,
  }
)(EditExpertRequest);

export default EditExpertRequest;

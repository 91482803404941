import * as Sentry from '@sentry/browser';
import { hiddenConfig } from '../../config';

const MESSAGING_DISABLED_ERROR =
  'GraphQL Error: SendBird token not provided, messaging is disabled';

let promise;
let userId;

export function sendBirdConnect(user, graphql) {
  // Setup a lazy connect function for the given user, so
  // we don't increase application load time by connecting
  // to SendBird on initialization. (~2s).
  return () => sendBirdSession(user, graphql);
}

export function sendBirdSession(user, graphql) {
  if (!user) {
    return Promise.reject(
      new Error('User is required to start a SendBird session')
    );
  }

  if (!graphql) {
    return Promise.reject(
      new Error('GraphQL is required to start a SendBird session')
    );
  }

  if (userId && userId !== user.id) {
    return Promise.reject(new Error('SendBird user cannot be changed'));
  }

  if (promise) return promise;

  userId = user.id;

  promise = createSession(user, graphql).catch((err) => {
    // AC: we should return an empty session without
    // error only when sendbird is disabled to avoid
    // unnecessary errors in development
    if (err && err.message === MESSAGING_DISABLED_ERROR) return;
    throw err;
  });
  return promise;
}

async function createSessionWithToken(user, accessToken) {
  const SendBird = (
    await import(
      /* webpackChunkName: "sendbird" */
      'sendbird'
    )
  ).default;

  if (!hiddenConfig.sendBirdAppId) {
    console.warn('SendBird AppId is not set, messaging is disabled');
    return null;
  }

  const sb = await new Promise((resolve, reject) => {
    const sb = new SendBird({ appId: hiddenConfig.sendBirdAppId });
    sb.setErrorFirstCallback(true);
    sb.connect(user.id, accessToken, (err) => {
      if (err) return reject(err);
      resolve(sb);
    });
  });

  return sb;
}

async function getUserAccessTokenData(graphql) {
  const data = await graphql.mutate(
    '{ accessToken: generateMessagingAccessToken { access_token, last_seen_at} }'
  );
  return {
    accessToken: data.accessToken.access_token,
    lastSeenAt: new Date(parseInt(data.accessToken.last_seen_at)),
  };
}

async function createSession(user, graphql) {
  const data = await getUserAccessTokenData(graphql);
  return createSessionWithToken(user, data.accessToken);
}

class SendBirdHelper {
  constructor(user, graphql) {
    this._user = user;
    this._graphql = graphql;
    this._accessTokenData = null;
  }

  async getAccessTokenData() {
    if (this._accessTokenData == null) {
      try {
        this._accessTokenData = await getUserAccessTokenData(this._graphql);
      } catch (err) {
        Sentry.captureException(
          new Error('Error acquiring SendBird AccessToken', err)
        );
      }
    }
    return this._accessTokenData;
  }

  async createSession() {
    const tokenData = await this.getAccessTokenData();
    if (!tokenData) return null;
    return createSessionWithToken(this._user, tokenData.accessToken);
  }

  async isActiveUser() {
    // Consider user active if has connected to SendBird within 7 days
    const tokenData = await this.getAccessTokenData();
    if (!tokenData) return false;
    const threshold = 1000 * 60 * 60 * 24 * 7;
    return Date.now() - tokenData.lastSeenAt.getTime() < threshold;
  }
}

export function createSendBirdHelper(user, graphql) {
  return new SendBirdHelper(user, graphql);
}

import React, { PureComponent } from 'react';
import moment from 'moment-timezone';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Button from '../Button/Button';
import { darkBlue } from '../../core/colors';

moment.updateLocale('en', {
  week: {
    dow: 1,
  },
});

function getValue(props) {
  const { value, input } = props;
  if (value) {
    return value;
  }
  if (input && input.value) {
    const viewerOffsetToLocal =
      moment().utcOffset() - moment(input.value).utcOffset();
    return new Date(moment(input.value).subtract(viewerOffsetToLocal, 'm'));
  }
}

function getOnChange(props) {
  const { onChange, input } = props;
  return onChange || (input && input.onChange);
}

function getError(props) {
  const { errorText, meta } = props;
  return errorText || (meta && (meta.touched || meta.modified) && meta.error);
}

function getWarning(props) {
  const { warningText, meta, displayWarning } = props;
  return warningText || (displayWarning && meta && meta.data.warning);
}

class DateTimePicker extends PureComponent {
  constructor(props) {
    super(props);

    const value = getValue(props);
    this.state = {
      timestamp: value,
      haveDate: !!value,
      haveTime: !!value,
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    const value = getValue(props);
    this.setState({
      timestamp: value,
      haveDate: !!value,
      haveTime: !!value,
    });
  }

  handleOnChangeDate = (e) => {
    const date = e?.toDate();
    const onChange = getOnChange(this.props);
    const { haveTime } = this.state;
    const { minDate, dateOnly } = this.props;

    if (!date) {
      this.handleClear();
      return;
    }

    // Workaround bug that does not validate min date when navigating to the next month
    if (minDate && moment(date).isBefore(minDate)) return;

    // The time selected by the user is on the system timezone, but we make it
    // on the timezone the user specified (e.g the user is on a shared computer
    // with a different system setting).
    const timezone = this.props.timezone || moment.tz.guess();
    const updatedTimeOnViewerTimezone = moment
      .tz(date, timezone)
      .startOf('day')
      .hour(dateOnly ? 0 : date.getHours())
      .minute(dateOnly ? 0 : date.getMinutes());

    this.setState({
      haveDate: true,
      timestamp: updatedTimeOnViewerTimezone,
    });

    if ((haveTime || dateOnly) && onChange) {
      onChange(updatedTimeOnViewerTimezone);
    }
  };

  handleOnChangeTime = (e) => {
    const date = e?.toDate();
    const onChange = getOnChange(this.props);
    const { timestamp, haveDate } = this.state;

    // The time selected by the user is on the system timezone, but we make it
    // on the timezone the user specified (e.g the user is on a shared computer
    // with a different system setting).
    const timezone = this.props.timezone || moment.tz.guess();
    const currentTimeOnViewerTimezone = moment.tz(timestamp || date, timezone);
    const updatedTimeOnViewerTimezone = moment
      .tz(timestamp || undefined, timezone)
      .year(currentTimeOnViewerTimezone.year())
      .month(currentTimeOnViewerTimezone.month())
      .date(currentTimeOnViewerTimezone.date())
      .hour(date.getHours())
      .minute(date.getMinutes())
      .second(0)
      .millisecond(0);

    this.setState({
      haveTime: true,
      timestamp: updatedTimeOnViewerTimezone,
    });

    if (haveDate && onChange) {
      onChange(updatedTimeOnViewerTimezone);
    }
  };

  handleClear = () => {
    const onChange = getOnChange(this.props);
    this.setState({
      haveTime: false,
      haveDate: false,
      timestamp: '',
    });
    if (onChange) onChange(null);
  };

  formatLabel = (e, invalid, pattern) => {
    const date = moment(e);
    return date.isValid() ? date.format(pattern) : invalid ?? '';
  };

  render() {
    const {
      name,
      label,
      placeholder,
      buttonClear = true,
      fullWidth = false,
      clearable,
      minDate,
      maxDate,
      dateOnly,
      required,
      labelDateFormat = 'MMM DD YYYY',
    } = this.props;
    const { timestamp, haveTime, haveDate } = this.state;

    const errorText = getError(this.props);
    const warningText = getWarning(this.props);

    const style = {
      display: 'flex',
      alignItems: 'center',
      ...this.props.style,
    };

    // Hack to display the time selected by the user,
    // not the actual time on the specified timezone.
    const timezone = this.props.timezone || moment.tz.guess();
    let displayTimestamp;
    if (timestamp) {
      const timeOnViewerTimezone = moment.tz(timestamp, timezone);
      displayTimestamp = timeOnViewerTimezone.toDate();
    }

    const hasError = !!(errorText || warningText);

    const textFieldsProps = {
      fullWidth,
      error: hasError,
      placeholder,
      helperText: hasError ? errorText || warningText : '',
      required,
    };

    const slotProps = {
      textField: textFieldsProps,
      field: { clearable },
      inputAdornment: { position: 'start' },
    };

    return (
      <div style={style}>
        <DatePicker
          name={name}
          value={haveDate ? moment(timestamp) : null}
          label={label}
          minDate={minDate ? moment(minDate) : undefined}
          maxDate={maxDate ? moment(maxDate) : undefined}
          onChange={this.handleOnChangeDate}
          format={labelDateFormat}
          required={required}
          slotProps={slotProps}
        />
        {!dateOnly && (
          <TimePicker
            name={name}
            value={haveTime ? moment(displayTimestamp) : null}
            label={label}
            onChange={this.handleOnChangeTime}
            minutesStep={5}
            required={required}
            sx={{ marginLeft: 2 }}
            format="h:mm a"
            slotProps={slotProps}
          />
        )}
        {buttonClear && (
          <Button
            variant="text"
            size="tiny"
            fontColor={darkBlue}
            onClick={this.handleClear}
          >
            Clear
          </Button>
        )}
      </div>
    );
  }
}

export default DateTimePicker;

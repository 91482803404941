import actionTypes from '../core/ActionTypes';

const { SUGGEST, SUGGEST_LOADING } = actionTypes;

const initialScopeState = {
  cache: {},
};

const initialState = {
  users: initialScopeState,
  profiles: initialScopeState,
};

export default function suggestions(state = initialState, action) {
  switch (action.type) {
    case SUGGEST:
    case SUGGEST_LOADING:
      // scopes allow multiple suggestion use cases to coexist
      return {
        ...state,
        [action.scope]: reduceScope(state[action.scope], action),
      };
    default:
      return state;
  }
}

function reduceScope(state = initialScopeState, action) {
  switch (action.type) {
    case SUGGEST:
      // each different text suggestion is cached in a separate key in the state
      return {
        loading: action.loading,
        cache: {
          ...state.cache,
          [action.text]: action.suggestions,
        },
      };
    case SUGGEST_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}

import React, { PureComponent } from 'react';
import Link from '../Link';
import s from './Breadcrumbs.module.scss';

class Breadcrumbs extends PureComponent {
  render() {
    const { crumbs } = this.props;
    const parts = [];
    for (const crumb of crumbs) {
      if (parts.length)
        parts.push(
          <div key={`separator${crumb.title}`} className={s.crumb}>
            &gt;
          </div>
        );
      if (!crumb.href) {
        parts.push(
          <div key={crumb.title} className={s.crumb}>
            {crumb.title}
          </div>
        );
      } else {
        parts.push(
          <Link key={crumb.title} to={crumb.href} className={s.crumb}>
            {crumb.title}
          </Link>
        );
      }
    }

    return <div className={s.root}> {parts} </div>;
  }
}

export default Breadcrumbs;

import React from 'react';
import StarRatingComponent from 'react-star-rating-component';
import FAIcon from '../Icon/FAIcon';
import { darkGreen, borderColor } from '../../core/colors';
import s from './StarRating.module.scss';

function StarRating({ title, style, ...other }) {
  return (
    <div className={s.rating} style={style}>
      <div className={s.label}>{title}</div>
      <StarRatingComponent
        editing
        starColor={darkGreen}
        emptyStarColor={borderColor}
        starCount={5}
        renderStarIcon={(index, value) => (
          <FAIcon
            icon={index <= value ? 'star' : 'star-o'}
            size={24}
            style={{ marginRight: 3 }}
          />
        )}
        {...other}
      />
    </div>
  );
}

StarRating = StarRating;

export default StarRating;

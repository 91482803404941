import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import TextFieldStatic from '@mui/material/TextField';
import { TextField } from '../FormAdapters';
import MediaPlayer from '../MediaPlayer';
import Dialog from '../Dialog';
import Button from '../Button/Button';
import DateTimePicker from '../DateTimePicker';
import MaterialIcon from '../Icon/MaterialIcon';
import FAIcon from '../Icon/FAIcon';
import { teal500 } from '../../core/colors';
import history from '../../core/history';
import { openFileDialog, notify } from '../../actions/ui';
import {
  addConsultation,
  updateConsultation,
  Trigger,
} from '../../actions/consultation';
import { presignedFilestackURL } from '../../actions/filestack';
import SelectNameable from '../SelectNameable';
import s from './EditExternalConsultation.module.scss';

const ChooseFile = ({ label, onClick, disabled, meta: { touched, error } }) => (
  <div className={s.upload}>
    <Button size="small" label={label} onClick={onClick} disabled={disabled} />
    {error && touched && <div className={s.error}>{error}</div>}
  </div>
);

function validate(values) {
  const errors = {};

  if (!values.recording_url) {
    errors.recording_url = 'Required';
  }

  if (!values.started_at) {
    errors.started_at = 'Required';
  }

  if (!values.requester_name) {
    errors.requester_name = 'Required';
  }

  if (!values.expert_name) {
    errors.expert_name = 'Required';
  }

  if (!values.group_id && !values.expert_request_id) {
    const errorMessage = 'Either Expert Request or Group is Required';
    errors.expert_request_id = errorMessage;
    errors.group_id = errorMessage;
  }

  return errors;
}

class EditExternalConsultation extends PureComponent {
  state = {
    submitting: false,
    recordingUrl: '',
  };

  setDuration = (audio) => {
    const duration = audio && audio.duration && `${audio.duration}s`;
    if (this.props.recordingDuration === duration) {
      this.props.change('recording_duration', duration);
    }
  };

  handleSubmit = (values) => {
    const {
      adding,
      consultation,
      onSubmit,
      addConsultation,
      updateConsultation,
      notify,
    } = this.props;

    this.setState({ submitting: true });

    if (adding) {
      addConsultation(values)
        .then((consultation) => {
          this.setState({ submitting: false });
          history.push(`/consultation/${consultation.id}`);
        })
        .catch((err) => {
          this.setState({ submitting: false });
          if (
            err &&
            err.message &&
            err.message.startsWith(
              'GraphQL Error: no billing account associated with group or expert request'
            )
          ) {
            notify('Unable to find billing account for consultation.', 'error');
            return;
          }
          notify('Error when adding external consultation.', 'error');
          throw err;
        });
    } else {
      updateConsultation({
        id: consultation.id,
        trigger: Trigger.consultationPage,
        ...values,
      })
        .then(() => {
          this.setState({ submitting: false });
          return onSubmit();
        })
        .catch(() => {
          this.setState({ submitting: false });
        });
    }
  };

  pickFile = async () => {
    const { openFileDialog, change, presignedFilestackURL } = this.props;
    openFileDialog({
      accept: ['.mp3', '.mp4', '.m4a'],
      maxSize: 2 * 1024 * 1024 * 1024, // 2 GB
    }).then(async (file) => {
      change('recording_filename', file.filename);
      change('recording_url', file.url);
      change('recording_file_size', file.size);

      const recordingUrl = await presignedFilestackURL(file.url);
      this.setState({ recordingUrl });
    });
  };

  handleReset = () => {
    const { onClose, reset } = this.props;
    this.setState({ submitting: false });
    reset();
    if (onClose) onClose();
  };

  render() {
    const {
      viewer,
      adding,
      handleSubmit,
      recordingFilename,
      expertRequests,
      expertRequest,
      consultation,
      initialValues,
      ...other
    } = this.props;
    const { recordingUrl, submitting } = this.state;

    const enterpriseExpertRequests =
      expertRequests.edges
        ?.filter(
          ({ node: er }) => er.project?.group?.account_type === 'enterprise'
        )
        .map((e) => e.node) || [];

    const enterpriseGroups =
      viewer.groups?.filter((g) => g.account_type === 'enterprise') || [];
    const expertRequestGroup = expertRequest?.node?.project?.group;
    const singleGroup =
      expertRequestGroup ||
      (enterpriseGroups.length === 1 && enterpriseGroups[0]);

    if (singleGroup) {
      // ER, if selected, will override this single group default
      initialValues.group_id = singleGroup.id;
    }

    const transcribing = !!(
      consultation &&
      !consultation.transcription &&
      consultation.transcription_order
    );
    const transcribed = !!(consultation && consultation.transcription);

    return (
      <Dialog
        onCancel={this.handleReset}
        onConfirm={handleSubmit(this.handleSubmit)}
        cancelLabel="Cancel"
        confirmLabel={
          submitting
            ? adding
              ? 'Adding...'
              : 'Saving...'
            : adding
              ? 'Add Consultation'
              : 'Save Consultation'
        }
        disableSubmit={submitting || recordingUrl === ''}
        {...other}
        onClose={this.handleReset}
      >
        <div className={s.header}>
          <MaterialIcon
            icon={adding ? 'file_upload' : 'mode_edit'}
            color={teal500}
            size={40}
          />

          <h4 className={s.title}>
            {adding ? 'Add' : 'Edit'} External Consultation
          </h4>

          {adding && (
            <p className={s.text}>
              Upload an audio or video file from a call made outside of
              OnFrontiers to get a full transcript and search through it
              alongside your other calls. Allowed file types MP3, MP4, and M4A.
            </p>
          )}
        </div>

        <Field
          component={ChooseFile}
          label={adding ? 'Choose File' : 'Change File'}
          name="recording_url"
          onClick={this.pickFile}
          disabled={transcribing || transcribed}
        />

        {recordingUrl && !transcribed && (
          <MediaPlayer
            enabled
            src={recordingUrl}
            onAudioSync={this.setDuration}
          />
        )}

        {(recordingFilename || transcribed || transcribing) && (
          <div className={s.uploadInstructions}>
            {recordingFilename && (
              <div className={s.uploaded}>
                <FAIcon icon="check" size={16} /> Uploaded {recordingFilename}
              </div>
            )}
            {transcribing && (
              <div className={s.instruction}>
                Audio files cannot be changed while transcription is in process
              </div>
            )}
            {transcribed && (
              <div className={s.instruction}>
                Audio files cannot be changed after they have been transcribed
              </div>
            )}
          </div>
        )}

        <Field
          component={DateTimePicker}
          name="started_at"
          placeholder="Date"
          maxDate={new Date()}
          dateOnly
          buttonClear={false}
          fullWidth={false}
          style={{ marginLeft: -3, maxWidth: 200, marginTop: 20 }}
          timezone={viewer.timezone}
        />

        <div className={s.users}>
          <Field
            component={TextField}
            fullWidth
            name="requester_name"
            label="Interviewer"
            style={{ marginRight: 5 }}
          />

          <Field
            component={TextField}
            fullWidth
            name="expert_name"
            label="Expert"
            style={{ marginLeft: 5 }}
          />
        </div>

        {enterpriseExpertRequests.length > 0 && (
          <Field
            component={SelectNameable}
            label="Add to Expert Request"
            name="expert_request_id"
            options={enterpriseExpertRequests}
          />
        )}
        {singleGroup ? (
          <TextFieldStatic
            label="Add to Group"
            name="group_id"
            value={singleGroup.name}
            disabled
          />
        ) : (
          <Field
            id="selectGroup"
            component={SelectNameable}
            label="Add to Group"
            name="group_id"
            options={enterpriseGroups}
          />
        )}
      </Dialog>
    );
  }
}

EditExternalConsultation = reduxForm({
  validate,
  enableReinitialize: true,
  initialValues: { engagement_type: 'consultation' },
})(EditExternalConsultation);

EditExternalConsultation = connect(
  (state, ownProps) => {
    const adding = !ownProps.consultation;
    // AC: do not conflict form names
    // When going from consultation list to consultation details the form is not
    // reinitialized for some reason, so the edit dialog shows empty values
    const form = adding
      ? 'addExternalConsultation'
      : 'editExternalConsultation';
    const formValue = formValueSelector(form);
    const props = {
      form,
      adding,
      viewer: state.viewer,
      userContext: state.ui.userContext,
      expertRequests: state.expertRequests.open,
      recordingUrl: formValue(state, 'recording_url'),
      recordingFilename: formValue(state, 'recording_filename'),
      recordingDuration: formValue(state, 'recording_duration'),
      recordingFileSize: formValue(state, 'recording_file_size'),
      expertRequest: state.expertRequests.open?.edges?.find(
        (e) =>
          e.node.id ===
          state.form?.addExternalConsultation?.values?.expert_request_id
      ),
    };
    return props;
  },
  {
    openFileDialog,
    notify,
    addConsultation,
    updateConsultation,
    presignedFilestackURL,
  }
)(EditExternalConsultation);

export default EditExternalConsultation;

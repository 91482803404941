import stringify from 'json-stringify-deterministic';
import actionTypes from '../core/ActionTypes';

const { SEARCH, SEARCH_SELECT_PROFILE, SEARCH_CLEAR_PROFILE_SELECTION } =
  actionTypes;

export function Query(options = {}) {
  const query = this;

  for (const key in options) {
    if (options.hasOwnProperty(key)) {
      query[key] = options[key];
    }
  }
  delete query.hash;

  query.text = (query.text || '').trim();
  query.sort = query.sort || '';
  query.ascending = query.sort ? query.ascending || false : undefined;
  query.highlight = query.highlight === undefined ? true : query.highlight;
  query.mustHaveEmail =
    query.mustHaveEmail === undefined ? true : query.mustHaveEmail;
  query.profileType = query.profileType || '';
  query.expertStates = query.expertStates || [];
  query.expertHistory = query.expertHistory || [];
  query.languages = query.languages || [];
  query.countries = query.countries || [];
  query.industries = query.industries || [];
  query.agentIds = query.agentIds || [];
  query.networkIds = query.networkIds || [];
  query.sharingGroupIds = query.sharingGroupIds || [];
  query.organizationsCurrentRestriction =
    query.organizationsCurrentRestriction || 'none';
  query.organizations = query.organizations || [];
  query.locationsCurrentRestriction =
    query.locationsCurrentRestriction || 'none';
  query.locations = query.locations || [];
  query.jobTitlesCurrentRestriction =
    query.jobTitlesCurrentRestriction || 'none';
  query.jobTitles = query.jobTitles || [];
  query.consultationsWith = query.consultationsWith || [];
  query.educationDegrees = query.educationDegrees || [];
  query.educationFos = query.educationFos || [];
  query.groupKeywordIds = query.groupKeywordIds || [];
  query.keywords = query.keywords || [];
  query.marketplacePreference = query.marketplacePreference || 'none';

  query.hash = stringify(query);
}

export function search(query, cursor) {
  return async (dispatch, getState, { graphql }) => {
    // Prevent refetching results
    const lastPage = getState().search.queries[query.hash];
    if (!cursor && lastPage.pageInfo.total !== undefined) {
      dispatch(
        setSearchResults({
          query,
          fetching: false,
        })
      );
      return lastPage;
    }

    dispatch(
      setSearchResults({
        query,
        fetching: true,
      })
    );

    try {
      const result = await graphql.query(
        `query search(
        $cursor: String
        $text: String
        $sort: String
        $ascending: Boolean
        $highlight: Boolean
        $countries: [String!]
        $languages: [String!]
        $industries: [String!]
        $mustHaveEmail: Boolean
        $profileTypes: [ProfileType]
        $expertStates: [ExpertState]
        $expertHistory: [ExpertHistory]
        $agentIds: [String!]
        $networkIds: [String!]
        $sharingGroupIds: [String!]
        $experienceOrganizations: ExperienceFieldInput
        $experienceLocations: ExperienceFieldInput
        $experienceJobTitles: ExperienceFieldInput
        $consultationsWith: ConsultationsWithInput
        $educationDegrees: [String!]
        $educationFos: [String!]
        $groupKeywordIds: [String!]
        $keywords: [String!]
        $marketplacePreference: MarketplacePreference
      ) {
        search(
          after: $cursor
          query: $text
          sort: $sort
          ascending: $ascending
          highlight: $highlight
          countries: $countries
          languages: $languages
          industries: $industries
          must_have_email: $mustHaveEmail
          profile_types: $profileTypes
          expert_states: $expertStates
          expert_history: $expertHistory
          agent_ids: $agentIds
          network_ids: $networkIds
          sharing_group_ids: $sharingGroupIds
          experience_organizations: $experienceOrganizations
          experience_locations: $experienceLocations
          experience_job_titles: $experienceJobTitles
          consultations_with: $consultationsWith
          education_degrees: $educationDegrees
          education_fos: $educationFos
          group_keyword_ids: $groupKeywordIds
          keywords: $keywords
          marketplace_preference: $marketplacePreference
        ) {
          edges {
            cursor
            node {
              first_name
              last_name
              name
              title
              city
              country
              languages
              keywords
              group_keywords
              sectors
              regions
              summary_snippets
              cv_snippets
              additional_information_snippets
              experiences {
                title
                organization
                location
                role
                start_date
                end_date
                description_snippets
                current
              }
              education {
                degree
                field_of_study
                school
                start_date
                end_date
                description_snippets
              }
              candidates {
                id
                state
                request {
                  name
                  html_url
                }
                project {
                  id
                  name
                  html_url
                  group { name }
                }
                request_name_highlight
                answerable_questions {
                  query
                  query_snippets
                  response
                  response_snippets
                  response_type
                }
                qualification_responses {
                  query
                  query_snippets
                  response
                  response_snippets
                  response_type
                }
                notes {
                  id
                  author {
                    id
                    name
                    first_name
                    last_name
                    picture_url
                    html_url
                  }
                  author_role
                  text_snippets
                  is_good_match
                }
              }
              network_experts {
                id
                network {
                  id
                  group { name }
                }
                created_by {
                  id
                  name
                  first_name
                  last_name
                  picture_url
                  html_url
                }
                add_note_snippets
              }
              profile {
                id
                name
                user {
                  id
                }
                sources(only_last: true, has_custom_candidate_invitation_message: true) {
                  agent {
                    name
                    custom_candidate_invitation_message
                  }
                }
                html_url
                picture_url
                first_name
                last_name
                name
                credit_rate
                languages {
                  code
                  fluency
                }
                expert_internal_networks(connection_states: [active]) {
                  id
                  add_method
                  participation_level
                  from_group { id name }
                  network { id name }
                }
                available_marketplace
                url_endpoint
              }
            }
          }
          pageInfo {
            total
            hasNextPage
          }
        }
      }`,
        {
          cursor,
          text: query.text,
          sort: query.sort,
          ascending: query.ascending,
          highlight: query.highlight,
          countries: query.countries,
          languages: query.languages,
          industries: query.industries,
          mustHaveEmail: query.mustHaveEmail,
          profileTypes: query.profileType ? [query.profileType] : [],
          expertStates: query.expertStates,
          expertHistory: query.expertHistory,
          agentIds: query.agentIds,
          networkIds: query.networkIds,
          sharingGroupIds: query.sharingGroupIds,
          experienceOrganizations: {
            current_restriction: query.organizationsCurrentRestriction,
            accepted_values: query.organizations,
          },
          experienceLocations: {
            current_restriction: query.locationsCurrentRestriction,
            accepted_values: query.locations,
          },
          experienceJobTitles: {
            current_restriction: query.jobTitlesCurrentRestriction,
            accepted_values: query.jobTitles,
          },
          consultationsWith: {
            me: (query.consultationsWith || []).includes('me'),
            group_ids: (query.consultationsWith || []).filter(
              (x) => x !== 'me'
            ),
          },
          educationDegrees: query.educationDegrees,
          educationFos: query.educationFos,
          groupKeywordIds: query.groupKeywordIds,
          keywords: query.keywords,
          marketplacePreference: query.marketplacePreference,
        }
      );

      const page = result.search;

      // temp hack
      if (typeof cursor === 'undefined') {
        const { pageInfo } = page;
        pageInfo.total =
          pageInfo.total ||
          Number(page.edges.length > 0 || pageInfo.hasNextPage);
      }

      dispatch(
        setSearchResults({
          query,
          fetching: false,
          ...page,
        })
      );

      return page;
    } catch (err) {
      dispatch(
        setSearchResults({
          query,
          fetching: false,
          error: err.message,
        })
      );
    }
  };
}

export function setSearchResults({ query, fetching, error, ...page }) {
  return {
    type: SEARCH,
    query,
    hash: query.hash,
    fetching,
    error,
    ...page,
  };
}

export function selectProfile(profile, selected) {
  return {
    type: SEARCH_SELECT_PROFILE,
    profile,
    selected,
  };
}

export function clearProfileSelection() {
  return {
    type: SEARCH_CLEAR_PROFILE_SELECTION,
  };
}

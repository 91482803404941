import React, { PureComponent } from 'react';
import { Checkbox, TextField, FormControlLabel } from '@mui/material';
import { connect } from 'react-redux';
import EditDialog from '../EditDialog/EditDialog';
import FieldContainer from '../FieldContainer/FieldContainer';
import InternalNetworkSharePromoDialog from '../InternalNetworkSharePromoDialog';
import {
  addNetworkExperts,
  removeNetworkExperts,
} from '../../actions/internalNetwork';
import { notify } from '../../actions/ui';
import { unique } from '../../core/util';
import s from './InternalNetworkShareSingleProfile.module.scss';

class InternalNetworkShareSingleProfile extends PureComponent {
  constructor(props) {
    super(props);

    const { expert_internal_networks: expertNetworks } = props.profile;

    const selectedNetworks = {};

    if (expertNetworks) {
      expertNetworks.forEach((n) => {
        if (['active', 'awaiting_acceptance'].includes(n.connection_state)) {
          selectedNetworks[n.network.id] = true;
        }
      });
    }

    this.state = {
      selectedNetworks,
      addNote: '',
      removalMessage: '',
    };
  }

  getAddedNetworks = () => {
    const { profile } = this.props;
    const { selectedNetworks } = this.state;
    const { expert_internal_networks: expertNetworks } = profile;

    const sharedNetworks = this.getSharedNetworks();

    const addedNetworks = Object.keys(selectedNetworks)
      .filter((n) => selectedNetworks[n])
      .filter(
        (n) =>
          !expertNetworks.some(
            (e) =>
              e.network.id === n &&
              ['active', 'awaiting_acceptance'].includes(e.connection_state)
          )
      );

    return sharedNetworks.filter((n) => addedNetworks.includes(n.id));
  };

  getRemovedNetworks = () => {
    const { profile } = this.props;
    const { selectedNetworks } = this.state;
    const { expert_internal_networks: expertNetworks } = profile;

    const sharedNetworks = this.getSharedNetworks();

    const removedNetworks = Object.keys(selectedNetworks)
      .filter((n) => !selectedNetworks[n])
      .filter((n) => expertNetworks.some((e) => e.network.id === n));

    return sharedNetworks.filter((n) => removedNetworks.includes(n.id));
  };

  getRemovedNetworkExperts = () => {
    const { profile } = this.props;
    const { selectedNetworks } = this.state;
    const { expert_internal_networks: expertNetworks } = profile;

    const uncheckedNetworks = Object.keys(selectedNetworks).filter(
      (n) => !selectedNetworks[n]
    );

    return expertNetworks.filter((e) =>
      uncheckedNetworks.includes(e.network.id)
    );
  };

  getSharedNetworks = () => {
    const { viewer, groups } = this.props;

    if (viewer.admin) {
      return groups.edges.map((e) => e.node.internal_network).filter(Boolean);
    }

    const sharedNetworks = viewer.groups
      ? viewer.groups
        .map((g) => g.shared_internal_networks || [])
        .filter(Boolean)
        .reduce((acc, n) => [...acc, ...n])
      : [];

    return unique(sharedNetworks, (n) => n.id);
  };

  handleNetworkSelect = (id, checked) => {
    this.setState((prevState) => ({
      selectedNetworks: {
        ...prevState.selectedNetworks,
        [id]: checked,
      },
    }));
  };

  handleAddNoteChange = (e, addNote) => this.setState({ addNote });

  handleRemovalMessageChange = (e, removalMessage) =>
    this.setState({ removalMessage });

  handleSubmit = async () => {
    const {
      profile,
      addNetworkExperts,
      removeNetworkExperts,
      onClose,
      notify,
    } = this.props;

    const { addNote, removalMessage } = this.state;

    try {
      const removedNetworkExpert = this.getRemovedNetworkExperts();
      if (removedNetworkExpert.length > 0) {
        await removeNetworkExperts({
          networkExpertIds: removedNetworkExpert.map((e) => e.id),
          message: removalMessage,
          unshare: true,
        });
      }

      const addedNetworks = this.getAddedNetworks();
      if (addedNetworks.length > 0) {
        await addNetworkExperts({
          profileIds: [profile.id],
          networkIds: addedNetworks.map((n) => n.id),
          addMethod: 'group_share',
          teamNote: addNote,
        });
      }

      onClose();

      notify('Sharing settings updated.', 'success');
    } catch (err) {
      if (err.isPermission) {
        return notify('Not authorized to remove expert from network.', 'error');
      }
      notify('An error occurred when updating sharing settings.', 'error');
      throw err;
    }
  };

  shouldDisableNetwork = (network) => {
    const { viewer, profile } = this.props;

    if (viewer.admin) return false;

    const { expert_internal_networks: expertNetworks } = profile;

    const expertNetwork = expertNetworks.find(
      (e) => e.network.id === network.id
    );

    if (!expertNetwork) return false;

    // Expert was shared by one of the viewer's groups
    if (
      expertNetwork.participation_level === 'shared' &&
      viewer.groups.some(
        (g) =>
          g.id === (expertNetwork.from_group && expertNetwork.from_group.id)
      )
    ) {
      return false;
    }

    // Expert was shared by another group, access can't be revoked
    return true;
  };

  render() {
    const { open, profile, onClose } = this.props;

    const { addNote, removalMessage, selectedNetworks } = this.state;

    const sharedNetworks = this.getSharedNetworks();

    if (sharedNetworks.length === 0) {
      return <InternalNetworkSharePromoDialog open={open} onClose={onClose} />;
    }

    const addedNetworks = this.getAddedNetworks();

    const removedNetworks = this.getRemovedNetworks();

    return (
      <EditDialog
        open={open}
        user={profile}
        title={profile.name}
        subTitle="Manage Access"
        saveLabel="Done"
        onSubmit={this.handleSubmit}
        onReset={onClose}
        onClose={onClose}
      >
        <FieldContainer
          label="Shared groups"
          className={s.sharedGroups}
          containerClassName={s.sharedGroupsContainer}
        >
          <div className={s.sharedGroupsScroll}>
            {sharedNetworks.map((n) => {
              const disabled = this.shouldDisableNetwork(n);

              const name = (
                <span>
                  {n.name}
                  {disabled ? (
                    <small>
                      {' '}
                      <i>(already part of this network)</i>
                    </small>
                  ) : (
                    ''
                  )}
                </span>
              );

              return (
                <FormControlLabel
                  key={n.id}
                  control={
                    <Checkbox
                      disabled={disabled}
                      checked={selectedNetworks[n.id]}
                      onChange={() => {
                        this.handleNetworkSelect(n.id, !selectedNetworks[n.id]);
                      }}
                      style={{ paddingTop: 0, paddingBottom: 0 }}
                      value={n.name}
                    />
                  }
                  label={name}
                  style={{ width: '100%', marginBottom: 5 }}
                />
              );
            })}
          </div>
        </FieldContainer>

        {addedNetworks.length > 0 && (
          <FieldContainer
            className={s.message}
            label={`Message to ${addedNetworks[0].name}${addedNetworks.length > 1 ? ' and others' : ''
              }`}
            title={addedNetworks.map((n) => n.name).join(', ')}
          >
            <TextField
              id="networkShareMessage"
              placeholder="Optional share message"
              multiline
              InputProps={{ disableUnderline: true }}
              fullWidth
              value={addNote}
              onChange={this.handleAddNoteChange}
            />
          </FieldContainer>
        )}

        {removedNetworks.length > 0 && (
          <FieldContainer
            className={s.message}
            label={`Message to ${removedNetworks[0].name}${removedNetworks.length > 1 ? ' and others' : ''
              }`}
            title={removedNetworks.map((n) => n.name).join(', ')}
          >
            <TextField
              id="networkRemovalMessage"
              placeholder="Optional removal message"
              multiline
              InputProps={{ disableUnderline: true }}
              fullWidth
              value={removalMessage}
              onChange={this.handleRemovalMessageChange}
            />
          </FieldContainer>
        )}
      </EditDialog>
    );
  }
}

InternalNetworkShareSingleProfile = connect(
  (state) => ({
    groups: state.groups.networks,
    viewer: state.viewer,
  }),
  {
    addNetworkExperts,
    removeNetworkExperts,
    notify,
  }
)(InternalNetworkShareSingleProfile);

InternalNetworkShareSingleProfile = InternalNetworkShareSingleProfile;

export default InternalNetworkShareSingleProfile;

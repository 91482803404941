import React, { PureComponent } from 'react';
import Button from '../Button';
import { darkGray, sand } from '../../core/colors';
import FAIcon from '../Icon/FAIcon';

const styles = {
  style: {
    minWidth: 0,
    color: darkGray,
    height: 35,
    width: 35,
    minHeight: 'initial',
    padding: 0,
  },
  iconStyle: {
    marginLeft: 10,
    fontSize: 25,
  },
};

class SocialButton extends PureComponent {
  render() {
    const startIconElement =
      typeof this.props.startIcon === 'string' ? (
        <FAIcon style={styles.iconStyle} icon={this.props.startIcon} />
      ) : (
        this.props.startIcon
      );
    return (
      <Button
        backgroundColor={sand}
        target="_blank"
        color="primary"
        {...styles}
        {...this.props}
        startIcon={startIconElement}
      />
    );
  }
}

export default SocialButton;

import React from 'react';
import LayoutPage from '../../components/Layout/LayoutPage';
import SetPassword from './SetPassword';
import RequestPasswordReset from './RequestPasswordReset';
import { validateToken } from '../../actions/token';

export default {
  path: '/password_reset',

  async action({ store, query }) {
    const { password_token: token } = query;

    let isInvalid = false;
    try {
      if (token) {
        await store.dispatch(validateToken(token));
      }
    } catch (e) {
      isInvalid = true;
    }

    return {
      title: 'Reset Password | OnFrontiers',
      component: (
        <LayoutPage>
          {token ? (
            <SetPassword token={token} isInvalid={isInvalid} />
          ) : (
            <RequestPasswordReset />
          )}
        </LayoutPage>
      ),
    };
  },
};

import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import MediaPlayer from '../../components/MediaPlayer';
import { presignedFilestackURL } from '../../actions/filestack';
import {
  fileTypes,
  recordingTypes,
  isAudioType,
  isVideoType,
} from '../../core/consultation';

// TODO: Make fallbackURL a recording type {location, file type, recording type, etc }
function RecordingPlayer({
  conference,
  enabled,
  fallbackURL,
  presignedFilestackURL,
}) {
  const [recording, setRecording] = useState(undefined);
  const presignedRecording = useCallback(
    async (recording) => {
      if (recording) {
        recording.location = await presignedFilestackURL(recording.location);
        setRecording(recording);
      } else setRecording(undefined);
    },
    [presignedFilestackURL]
  );

  useEffect(() => {
    let internalRecording = conference?.attachments?.find(
      (a) => a.recording_type === recordingTypes.sharedScreenWithSpeakerView
    );
    if (!internalRecording) {
      internalRecording = conference?.attachments?.find((a) =>
        isVideoType(a.file_type)
      );
    }
    if (!internalRecording) {
      internalRecording = conference?.attachments?.find((a) =>
        isAudioType(a.file_type)
      );
    }
    if (!internalRecording && fallbackURL) {
      internalRecording = {
        location: fallbackURL,
        file_type: fileTypes.mp3,
      };
    }
    presignedRecording(internalRecording);
  }, [conference, presignedFilestackURL]);

  return (
    <div>
      {recording && recording.location && (
        <MediaPlayer
          src={recording.location}
          isAudioOnly={isAudioType(recording.file_type)}
          enabled={enabled}
        />
      )}
    </div>
  );
}

RecordingPlayer = connect(undefined, {
  presignedFilestackURL,
})(RecordingPlayer);

export default RecordingPlayer;

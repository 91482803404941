import React from 'react';

import Grid from '@mui/material/Grid';
import Layout from '../../components/MarketingLayout';
import Hero from '../../components/MarketingLayout/MarketingHero';
import MarketingSection from '../../components/MarketingSection';
import Testimonial from '../../components/Testimonial';
import { teal500, darkGreen, sand, black } from '../../core/colors';
import SplashTitle from '../../components/SplashTitle';
import FeatureTile from '../../components/FeatureTile';
import Button from '../../components/Button/Button';
import Link from '../../components/Link';
import s from './Jobs.module.scss';

function Areas() {
  return (
    <MarketingSection padding={60}>
      <Grid container spacing={4}>
        <Grid item xs={12} lg={4}>
          <div className={s.areas}>
            <h2 className={s.areasTitle}>Where do you fit in?</h2>

            <p className={s.areasContent}>
              We encourage you to review our open roles&nbsp;
              <a
                className={s.greenhouseLink}
                href="https://boards.greenhouse.io/onfrontiers"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
              &nbsp;and submit your LinkedIn profile URL, CV, and cover letter
              letting us know what interests you in joining OnFrontiers.
            </p>
          </div>
        </Grid>
        <Grid container item md={12} lg={8} spacing={4}>
          <Area title="Research">
            Be on the front lines of connecting clients with experts on any
            topic in any geography within 48 hours. Research teammates have
            insatiable curiosity, and are tech savvy, performance-oriented, and
            customer-focused.
          </Area>
          <Area title="Sales & marketing">
            Identify and engage potential enterprise clients and help interested
            prospects evaluate and adopt OnFrontiers.
          </Area>
          <Area title="Ops, finance & compliance">
            Help develop and apply appropriate controls and processes that
            ensure business sustainability and foster a safe, secure, and
            efficient platform environment.
          </Area>
          <Area title="Client success">
            Help clients achieve the results they are seeking using OnFrontiers.
          </Area>
          <Area title="Product">
            Designs features and functionality to improve the platform
            experience of our clients and experts.
          </Area>
          <Area title="Engineering">
            Our engineering team applies cutting-edge software development
            technologies and practices to achieve our business objectives.
          </Area>
        </Grid>
      </Grid>
    </MarketingSection>
  );
}

function Area(props) {
  return (
    <FeatureTile
      xs={12}
      sm={6}
      md={4}
      lg={6}
      titleStyle={{ fontSize: 24, color: black }}
      {...props}
    />
  );
}

function Jobs() {
  return (
    <Layout>
      <Hero contentClassName={s.splashBody} backgroundColor={darkGreen}>
        <SplashTitle style={{ maxWidth: 700 }}>
          Help us automate knowledge sharing
        </SplashTitle>
      </Hero>

      <MarketingSection padding={60} style={{ backgroundColor: sand }}>
        <div className={s.join}>
          <div className={s.joinImage}>
            <img alt="" src={require('./connections.svg')} />
          </div>
          <div className={s.joinContent}>
            <h2 className={s.joinTitle}>Join our team!</h2>
            We&apos;re a cohesive, entrepreneurial team of 15+ people living and
            working remotely in 4 continents. We look for the best people to
            help us solve hard problems and build a company we are proud to be a
            part of, no matter where they live.
            <div className={s.openRoles}>
              <Link
                href="https://boards.greenhouse.io/onfrontiers"
                target="_blank"
                rel="noreferrer"
              >
                <Button size="large">See Open Roles</Button>
              </Link>
            </div>
          </div>
        </div>
      </MarketingSection>

      <Areas />

      <MarketingSection darkGreen padding={60}>
        <Testimonial
          picture={require('./testimonial.jpg')}
          name="Joanne Lim"
          title="Research Manager (APAC)"
          iconSize={40}
          pictureSize={84}
          iconColor={teal500}
        >
          The mission and vision of OnFrontiers made me choose to work here over
          other companies. What keeps me here is the feeling that my work is
          valued and that I&apos;m continuously growing and developing my skills
          as the company grows and learns. Others important aspects I like — You
          get the chance to take part in building the company and its culture.
          You experience the real meaning of teamwork with teammates all over
          the world. Finally, you get to do real work, beyond blindly following
          what you are told to do.
        </Testimonial>
      </MarketingSection>
    </Layout>
  );
}

export default Jobs;
